import { useState, useEffect } from 'react';
import secureRequest from '../helpers/secure-request';

const requestStates = {
  loading: 'loading',
  failed: 'failed',
  success: 'success',
};

const parseBody = (response) => {
  const { headers } = response;
  if (headers.get('Content-Type').includes('application/json')) {
    return response.json();
  }
  // TOOD: non-json response
  return null;
};

const useSecureRequest = (path, options = {}) => {
  const [requestState, setRequestState] = useState({
    requestState: requestStates.loading,
    statusText: null,
    parsedBody: null,
  });

  useEffect(() => {
    // TODO: consider useCallback here
    const makeSecureRequest = async () => {
      if (!path) {
        console.warn('path is not defined');
        return;
      }
      setRequestState({
        requestState: requestStates.loading,
        statusText: null,
        parsedBody: null,
      });
      const rawResponse = await secureRequest(path, options);
      const { statusText } = rawResponse;
      let newState = requestStates.success;
      if (!rawResponse.ok) {
        console.error(`request to ${path} failed with status: ${statusText}`);
        newState = requestStates.failed;
      }
      const parsedBody = await parseBody(rawResponse);
      setRequestState({
        requestState: newState,
        statusText,
        parsedBody,
      });
    };
    makeSecureRequest();
  }, [path, JSON.stringify(options)]);

  return requestState;
};

export default useSecureRequest;
