/* eslint-disable react/destructuring-assignment */
import React from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  addFormError,
  clearAddFormErrors,
  clearEditObject,
  createCaseWithUser,
  handleFormInputChange,
} from '@app/actions/common';

// eslint-disable-next-line import/no-named-as-default
import BrandedCopy from '@app/components/Form/BrandedCopy/BrandedCopy';
import Button from '@app/components/Form/Button/Button';
import CaseVisibilityDropdown from '@app/components/Form/CaseVisibilityDropdown/CaseVisibilityDropdown';
import ConfigurableOptInWidget from '@app/components/Form/ConfigurableOptInWidget/ConfigurableOptInWidget';
import FormInputField from '@app/components/FormInput/FormInputField';
import LinesOfBusinessList from '@app/components/Form/LinesOfBusinessList/LinesOfBusinessList';
import MultiStateButton from '@app/components/Form/MultiStateButton/MultiStateButton';
import PermissionVerifier from '@app/components/PermissionVerifier/PermissionVerifier';
// eslint-disable-next-line import/no-named-as-default
import PrimaryContactInput from '@app/components/PrimaryContactInput/PrimaryContactInput';
// eslint-disable-next-line import/no-named-as-default
import UserForm from '@app/components/Chats/RightBar/AddUser/AddUserForm/UserForm';
import CaseMatchAlert from '@app/components/elements/CaseMatchAlert/case-match-alert';

import { CASE_VISIBILITY, LINES_OF_BUSINESS_ENABLE } from '@app/constants/permissions';

import { formatBrandLobs } from '@app/helpers/format';
import { UserFormValidation } from '@app/helpers/formValidation/userForm/userFormValidation';

import BriefcaseIcon from '@images/icons/briefcase.svg';
import PersonalIcon from '@images/icons/policy@2x.svg';

import { caseType, userType } from '@app/models/marleyTypes';

import './GeneralPurposeCaseForm.less';

class GeneralPurposeCaseForm extends React.Component {
  constructor(props) {
    super(props);

    this.mapNameToMetaDataId = {
      companyName: '19de7971-8555-4cfb-91ed-f45011ec04d1',
      reference: 'a54f88b3-fee1-4216-8c6e-8b8aa5e83a4e',
    };

    this.IdToMetadataLocation = (key) => `metaData.${this.mapNameToMetaDataId[key]}`;

    this.state = {
      privacy: 'public',
      companyName: '',
      reference: '',
      caseLobData: {},
      caseTypeId: '281443b2-b94d-4958-afe3-45e9b2799439',
      existingCases: [],
      showCaseMatchAlert: false,
    };

    this.props.handleFormInputChange({
      inputId: 'caseTypeId',
      tableId: caseType.id(),
      value: this.state.caseTypeId,
    });

    this.props.handleFormInputChange({
      inputId: 'metadataReferenceId',
      tableId: caseType.id(),
      value: 'a54f88b3-fee1-4216-8c6e-8b8aa5e83a4e',
    });
  }

  componentWillUnmount() {
    this.props.clearAddFormErrors(caseType.reducerCase());
    this.props.clearAddFormErrors(userType.reducerCase());
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    if (this.props.submitIsDisabled) {
      this.props.toggleDisableFormSubmit(false);
    }
    this.props.handleFormInputChange({
      inputId: this.IdToMetadataLocation(name),
      tableId: caseType.id(),
      value,
    });
    this.setState({ [name]: value });
  };

  handleBlur = (e) => {
    const { name, value } = e.target;
    const { userCases } = this.props;
    if (name === 'reference' && value) {
      const existingCases = userCases.filter((c) => c.referenceId === value && c.type === 'CASE');
      this.setState({ existingCases, showCaseMatchAlert: existingCases.length > 0 });
    }
  };

  handleCaseVisibilityChange = (id) => {
    this.setState({ privacy: id });
  };

  handleLobChange = (item) => {
    this.setState({ caseLobData: item[0] });
  };

  toggleCaseType = (value) => {
    const caseTypeId = value === 'personal' ? '281443b2-b94d-4958-afe3-45e9b2799439' : '037438df-84f7-4ef0-83e0-7e6c60ef02fd';
    this.props.handleFormInputChange({
      inputId: 'caseTypeId',
      tableId: caseType.id(),
      value: caseTypeId,
    });
    this.setState({ caseTypeId });
  };

  getFormErrorText = () => {
    const errorLocations = [
      'jobs.errorMessage',
      'users.error',
      'requests.error',
    ];

    let returnError = '';
    errorLocations.forEach((el) => {
      const error = _.get(this.props, el);
      if (error && !returnError) {
        returnError = error;
      } else {
        return returnError;
      }
    });

    return returnError;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let canSubmit = true;

    const {
      branding,
      editObject,
      userEditObject,
      toggleDisableFormSubmit,
    } = this.props;

    const caseData = { ...editObject };
    const metaDataExists = !!caseData.metaData;

    // CASE VALIDATION
    // operator validation
    if (!editObject.operatorId) {
      this.props.addFormError(caseType.reducerCase(), 'primaryContactId');
      canSubmit = false;
      toggleDisableFormSubmit(true);
    }

    // caseType validation
    if (editObject.caseTypeId === '281443b2-b94d-4958-afe3-45e9b2799439' && metaDataExists) {
      delete caseData.metaData['19de7971-8555-4cfb-91ed-f45011ec04d1'];
    }

    // branding validation
    if (metaDataExists) {
      if (!editObject.metaData['c54f88b2-fee1-4216-8c6e-7a7aa5e83a3e'] && branding.length) {
        this.props.addFormError(caseType.reducerCase(), 'branding');
        canSubmit = false;
        toggleDisableFormSubmit(true);
      }
    }
    caseData.privacy = this.state.privacy;
    caseData.lineOfBusiness = this.state.caseLobData;
    const preppedCaseData = { ...caseData };

    // USER VALIDATION -------------------------------------------------------------------------------------
    const userFormFields = ['phoneNumber', 'firstName', 'lastName', 'email'];
    userFormFields.forEach((field) => {
      const invalidity = UserFormValidation.userFormValidation({
        fieldName: field,
        userFormData: userEditObject,
      });

      if (!invalidity.isValid) {
        this.props.addFormError(userType.reducerCase(), field);
      }
    });

    const invalidUserField = userFormFields.find((field) => UserFormValidation.userFormValidation({
      fieldName: field,
      userFormData: userEditObject,
    }).isValid === false);

    if (userFormFields.includes(invalidUserField)) {
      canSubmit = false;
      toggleDisableFormSubmit(true);
    }

    // SUBMIT ----------------------------------------------------------------------------------------------
    if (canSubmit) {
      toggleDisableFormSubmit(true);
      const phoneNumber = UserFormValidation.cleanPhone(_.get(userEditObject, 'phoneNumber'));
      const userData = {
        ...userEditObject,
        phoneNumber,
        companyName: _.get(editObject, this.IdToMetadataLocation('companyName')),
      };

      this.props.createCaseWithUser({
        type: caseType.reducerCase(),
        userData,
        data: preppedCaseData,
        userIdName: 'customerId',
        overrideType: 'CASE',
      });
    }
  };

  render() {
    const {
      submitIsDisabled,
      isEdit,
      userIsEdit,
      requests,
      userEditObject,
      jobErrors,
      branding,
      linesOfBusiness,
      hideUserForm,
      onBack,
    } = this.props;

    const {
      companyName,
      reference,
      caseTypeId,
      existingCases,
      showCaseMatchAlert,
    } = this.state;

    let currentStateId = 'preRequest';
    if (requests && requests.requesting) {
      currentStateId = 'requesting';
    } else if (requests && requests.success) {
      currentStateId = 'success';
    } else if (requests && requests.error) {
      currentStateId = 'error';
    }

    const submitButtonStates = {
      preRequest: {
        type: 'primary',
        text: 'Create Case',
      },
      requesting: {
        type: 'secondary',
        text: 'Saving...',
        noClick: true,
      },
      success: {
        type: 'primary',
        text: 'Saved',
        noClick: true,
      },
      error: {
        type: 'primary',
        text: 'Create Case',
      },
    };

    const phoneNumber = _.get(userEditObject, 'phoneNumber', '');

    let displayError = this.getFormErrorText();
    /**
     * FIXME: hack to fix white screen crash when trying to display errors that are objects
     * The backend has a whole file that uses objects for errors so it's probably worth rethinking
     * having a React component render a value in Redux
     *
     * Path to the error file where the one causing this crash existed (chatIntentError):
     * /Users/user/Development/marley-chat/server/helpers/errors/jobErrors.js
     */
    if (typeof displayError === 'object') {
      displayError = displayError.message || 'System Error';
    }
    const brandLobs = linesOfBusiness?.length && formatBrandLobs(linesOfBusiness);
    const brandData = this?.props?.editObject?.metaData;
    const selectedBrand = brandData && brandData['c54f88b2-fee1-4216-8c6e-7a7aa5e83a3e'];

    return (
      <div className="case-form-and-user-form-container">
        {!hideUserForm && (
          <div className="user-form-container">
            <UserForm
              toggleDisableParentFormSubmit={
                this.props.toggleDisableFormSubmit
                && this.props.toggleDisableFormSubmit
              }
              parentFormContext="jobs"
              parentIsEdit={isEdit}
            />
          </div>
        )}
        {/* case form */}
        {(phoneNumber.length >= 14 || userIsEdit) && (
          <div data-test="case-form" className="case-view case-form-container">
            <div className="case-form-title">Case Type</div>
            <div className="case-form-inputs-container">
              <div className="case-case-form-item case-form-item-full case-type-selections">
                <div
                  className={`${caseTypeId === '281443b2-b94d-4958-afe3-45e9b2799439' ? 'selected' : ''} case-type-selection`}
                  onClick={() => this.toggleCaseType('personal')}
                  onKeyDown={() => this.toggleCaseType('personal')}
                  role="button"
                  tabIndex={0}
                >
                  <span>
                    <PersonalIcon />
                  </span>
                  Personal
                </div>
                <div
                  className={`${caseTypeId === '037438df-84f7-4ef0-83e0-7e6c60ef02fd' ? 'selected' : ''} case-type-selection`}
                  onClick={() => this.toggleCaseType('business')}
                  onKeyDown={() => this.toggleCaseType('business')}
                  role="button"
                  tabIndex={0}
                >
                  <span>
                    <BriefcaseIcon />
                  </span>
                  Business
                </div>
              </div>
              {caseTypeId === '037438df-84f7-4ef0-83e0-7e6c60ef02fd' && (
                <div className="case-form-item case-form-item-full business-name">
                  <FormInputField
                    label="Business Name"
                    onChange={this.handleChange}
                    name="companyName"
                    type="text"
                    value={companyName}
                    required
                  />
                </div>
              )}
              {(branding && branding?.length > 0) && (
                <div className="case-form-item branded-copy">
                  <BrandedCopy
                    key="bc"
                    id="job"
                    tableId="cases"
                    type={caseType}
                    toggleDisableParentFormSubmit={this.props.toggleDisableFormSubmit}
                    required
                    errorMessage="You must pick an Insurance Brand"
                  />
                </div>
              )}
              <div className="case-form-item">
                <div className={`poc point-of-contact ${jobErrors.includes('primaryContactId') ? 'has-error' : ''}`}>
                  <PrimaryContactInput
                    key="pc"
                    id="job"
                    tableId="cases"
                    type={caseType}
                    toggleDisableParentFormSubmit={this.props.toggleDisableFormSubmit}
                    required
                    errorMessage="You must assign Point of Contact"
                    editObject={{ ...this.props.editObject }}
                  />
                </div>
              </div>
              <PermissionVerifier neededPermissions={[CASE_VISIBILITY]}>
                <div className="case-form-item">
                  <CaseVisibilityDropdown
                    label="Case Visibility"
                    required
                    onChange={this.handleCaseVisibilityChange}
                    caseAssignment={this?.props?.editObject?.operatorId}
                  />
                </div>
              </PermissionVerifier>
              <div className="case-form-item">
                <FormInputField
                  label="Reference"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="reference"
                  type="text"
                  value={reference}
                />
              </div>
              {(branding && branding.length > 0) && (
                <PermissionVerifier neededPermissions={[LINES_OF_BUSINESS_ENABLE]}>
                  <div className="case-form-item">
                    <div className="case-form-item-label">
                      Line of Business
                    </div>
                    <LinesOfBusinessList
                      onChange={this.handleLobChange}
                      selectedBrand={selectedBrand}
                      listOfLob={brandLobs && brandLobs[selectedBrand]}
                      className="general-purpose-lob"
                    />
                  </div>
                </PermissionVerifier>
              )}
              <div className="case-form-item case-form-item-full case-error">{displayError}</div>
              <div className="case-form-item case-form-item-full">
                <CaseMatchAlert
                  cases={existingCases}
                  defaultShowAlert={showCaseMatchAlert}
                  onClose={() => this.setState({ showCaseMatchAlert: false })}
                  isSummary
                />
              </div>
              <div className="form-footer">
                <ConfigurableOptInWidget userId={userEditObject.id} />
                <div className="case-form-item case-form-item-full case-submit submit-btn">
                  { onBack && (
                  <Button
                    type="outline"
                    className="case-back-btn"
                    onClick={onBack}
                    disabled={false}
                  >
                    Back
                  </Button>
                  )}
                  <MultiStateButton
                    data-button="case-form-submit"
                    className="case-submit-btn general-submit-btn"
                    onClick={this.handleSubmit}
                    currentStateId={currentStateId}
                    states={submitButtonStates}
                    disabled={submitIsDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

GeneralPurposeCaseForm.propTypes = {
  hideUserForm: PropTypes.bool,
  handleFormInputChange: PropTypes.func,
  createCaseWithUser: PropTypes.func,
  addFormError: PropTypes.func,
  clearAddFormErrors: PropTypes.func,
  toggleDisableFormSubmit: PropTypes.func,
  submitIsDisabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  userEditObject: PropTypes.object,
  userIsEdit: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  users: PropTypes.object,
  onBack: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  jobs: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  editObject: PropTypes.object,
  isEdit: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  jobErrors: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  userErrors: PropTypes.array,
  userCases: PropTypes.arrayOf(Object),
  // eslint-disable-next-line react/forbid-prop-types
  requests: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  branding: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  linesOfBusiness: PropTypes.array,
};

GeneralPurposeCaseForm.defaultProps = {
  hideUserForm: false,
  handleFormInputChange: () => {},
  createCaseWithUser: () => {},
  addFormError: () => {},
  clearAddFormErrors: () => {},
  toggleDisableFormSubmit: () => {},
  submitIsDisabled: () => {},
  userEditObject: null,
  userIsEdit: false,
  userCases: [],
  users: null,
  onBack: () => {},
  jobs: null,
  editObject: null,
  isEdit: false,
  jobErrors: [],
  userErrors: [],
  requests: null,
  branding: [],
  linesOfBusiness: [],
};

const mapDispatchToProps = {
  handleFormInputChange,
  createCaseWithUser,
  addFormError,
  clearAddFormErrors,
  clearEditObject,
};

const mapStateToProps = (state) => ({
  jobs: _.get(state, 'jobs', {}),
  editObject: _.get(state, 'jobs.form.editObject'),
  isEdit: _.get(state, 'jobs.form.isEdit', {}),
  jobErrors: _.get(state, 'jobs.errors', []),
  userErrors: _.get(state, 'users.errors', []),
  requests: _.get(state, 'ui.requests.addCase', {}),
  branding: _.get(state, 'organizations.branding', []),
  linesOfBusiness: _.get(state, 'organizations.linesOfBusiness'),
});

export { GeneralPurposeCaseForm };
export default connect(mapStateToProps, mapDispatchToProps)(GeneralPurposeCaseForm);
