import React from 'react'
import './IconBar.less'

const IconBar = ({ className, disabled, children, icons }) => (
    <div className={`icon-bar-container${disabled ? '-disabled' : ''} ${className || ''}`}>
        {children}
        {icons && !disabled && <div className="icon-bar">{icons}</div>}
    </div>
)

export default IconBar