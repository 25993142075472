import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types'
import _ from 'lodash';
import { jobPutRequest } from '../../../../../actions/job';
import './CaseVisibility.less';
import RadioButton from '../../../../elements/RadioButton/RadioButton';
import LockOpenIcon from '../../../../../../images/icons/lock-open.svg';
import LockClosedIcon from '../../../../../../images/icons/lock-closed.svg';
import { useBuildCaseVisibilityList, isCaseVisibilityDisabled } from '../../../../../helpers/cases';

const CaseVisibility = ({ participants }) => {
  const dispatch = useDispatch();
  const { activeCase, currentPermissions, currentUserId } = useSelector(
    (state) => ({
      activeCase: _.get(state, 'jobs.activeJob', {}),
      currentPermissions: _.get(state, 'auth.permissions', []),
      currentUserId: _.get(state, 'auth.user._id', []),
    }),
    shallowEqual,
  );
  const radioButtonValues = useBuildCaseVisibilityList()
  const [visibilityToggle, setVisibilityToggle] = useState(_.get(activeCase, 'privacy', 'public'));
  const [disabled, setDisabled] = useState(
    isCaseVisibilityDisabled(
      participants,
      activeCase,
      currentPermissions,
      currentUserId,
    ),
  );

  useEffect(() => {
    // If the visibilityToggle doesnt match what's on the activeCase, make the request.
    // Prevents potential unnecessary API calls
    if (_.has(activeCase, 'privacy') && activeCase.privacy !== visibilityToggle) {
      dispatch(jobPutRequest({ ...activeCase, privacy: visibilityToggle }));
    }
  }, [visibilityToggle])

  useEffect(() => {
    // live update to ensure privacy switches if other user viewing case
    setVisibilityToggle(activeCase.privacy)
  }, [activeCase.privacy])

  useEffect(() => {
    // TO DO: consider creating array of participant id's if participants array grows in size
    setDisabled(isCaseVisibilityDisabled(
      participants,
      activeCase,
      currentPermissions,
      currentUserId,
    ))
  }, [participants])

  let description = `Currently, this case is set to ${visibilityToggle?.includes('private') ? 'private' : visibilityToggle}.`;
  if (disabled) {
    description += ' You must be a participant of the chat to change the visibility of this case.';
  } else if (visibilityToggle === 'public') {
    description += ' All operators can view and access this case.';
  } else if (visibilityToggle === 'private') {
    description += ' Only operators who are assigned or invited will be able to access this case.';
  }

  return (
    <div className="case-visibility-toggle">
      <div className="title-and-icon">
        <div className="title">
          Case Visibility
          <span className="lock-icon">
            {visibilityToggle === 'public' ? (
              <LockOpenIcon />
            ) : (
              <LockClosedIcon />
            )}
          </span>
        </div>
      </div>
      <RadioButton
        disabled={disabled}
        currentValue={visibilityToggle}
        values={radioButtonValues}
        setCheckedValue={setVisibilityToggle}
      />
      <div className="description">{description}</div>
    </div>
  );
};

CaseVisibility.propTypes = {
  participants: PropTypes.arrayOf(Object),
}

CaseVisibility.defaultProps = {
  participants: [],
}

export default CaseVisibility;
