import { s3toPipedUrl } from '../helpers/urls';
import Message from './message';

class Chat {
  constructor(chat) {
    this.id = chat._id;
    this.messages = chat.messages.map((message) => new Message(message));
    this.recipientId = chat.recipientId;
    this.senderId = chat.senderId;
    this.jobId = chat.jobId;
    this.media = (
      chat?.media || []
    ).map((mediaItem) => ({
      ...mediaItem,
      fileUrl: s3toPipedUrl(mediaItem.fileUrl),
    }));
  }
}

export default Chat;
