/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import { Button, ButtonRow } from '@himarley/unity';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import TooltipInfo from '../../../../elements/Tootip/TooltipInfo';
import UserPhoto2 from '../../../../UserPhoto2/UserPhoto2';
import { GET_USERS } from '../../../../../constants/endpoints';
import { doRequest } from '../../../../../actions/api';
import { displayTransform } from './Note.helpers';

const NoteInput = ({
  users,
  isPrivate,
  createNote,
  activeCaseId,
  setShowCreateNote,
  isReply,
  note,
  addReply,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [usersMentioned, setUsersMentioned] = useState([]);
  const noteTextarea = useRef(null);

  const allMentionedUsers = useMemo(() => {
    if (!isReply) {
      const parsedUserMentioned = usersMentioned.map((mention) => mention?.id) || [];
      return [...new Set(parsedUserMentioned)];
    }
    const parentNoteMentions = note?.mentionedUserIds || [];
    const parsedUserMentioned = usersMentioned.map((mention) => mention?.id);
    let mentionedUsers = [...parentNoteMentions, ...parsedUserMentioned];

    // other replies mentions
    const { replies = [] } = note;
    for (const reply of replies) {
      const replyMentions = reply?.mentionedUserIds || [];
      mentionedUsers = [...mentionedUsers, ...replyMentions];
    }
    return [...new Set(mentionedUsers)];
  }, [isReply, note, usersMentioned]);

  useEffect(() => {
    noteTextarea?.current.focus();
  }, [noteTextarea]);

  const fetchUsers = (searchText, callback) => {
    setIsLoading(true);
    doRequest({
      method: 'GET',
      endpoint: `${GET_USERS}?role=operator&offset=0&limit=20&searchText=${searchText}`,
    })
      .then((res) => {
        const parseUsers = res.users.map((user) => ({
          display: `${user?.profile?.firstName} ${user?.profile?.lastName}`,
          id: user?._id,
        }));
        parseUsers.sort((a, b) => a.display.localeCompare(b.display));
        return callback(parseUsers);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderSuggestion = (suggestion) => (
    <div className="suggestionWrap">
      <UserPhoto2
        type="thumb"
        id={suggestion?.id}
        imageUrl={suggestion?.imageUrl}
        name={suggestion?.display}
      />
      <div className="user-info">
        <div className="user-name">
          {' '}
          {suggestion?.display}
        </div>
        <div className="user-role">Operator</div>
      </div>
    </div>
  );

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setNoteText(event?.target?.value);
    setUsersMentioned(mentions);
  };

  const handleMentionClick = () => {
    const newNote = noteText.trim().length > 1 ? `${noteText} @` : '@';
    setNoteText(newNote);
    noteTextarea.current.focus();
  };

  const saveNote = () => {
    if (isReply) {
      addReply
        && addReply({
          caseId: activeCaseId,
          noteId: note?._id,
          replyText: noteText,
          unreadNoteMentions: usersMentioned,
          plainText: noteTextarea.current.value,
        });
    } else {
      createNote
        && createNote({
          caseId: activeCaseId,
          noteText,
          unreadNoteMentions: usersMentioned,
          plainText: noteTextarea.current.value,
        });
    }
  };

  return (
    <div
      data-testid="create-note-box"
      data-test="create-note-box"
      className="marley-note-create"
    >
      <MentionsInput
        inputRef={noteTextarea}
        value={noteText}
        onChange={handleChange}
        placeholder="Start writing here..."
        className="mentionsInput replyTextInput"
        allowSpaceInQuery
      >
        <Mention
          className="noteSuggestion"
          trigger="@"
          data={isPrivate ? users : fetchUsers}
          renderSuggestion={renderSuggestion}
          displayTransform={displayTransform}
          isLoading={isLoading}
        />
      </MentionsInput>
      <div className="marley-note-create-button-row">
        <div className="mentionedUsersWrap">
          <TooltipInfo
            icon={(
              <div
                data-testid="mentionBtn"
                className="mentionInfoTrigger"
                onClick={handleMentionClick}
                onKeyDown={handleMentionClick}
                role="button"
                tabIndex={0}
              >
                @
              </div>
            )}
            message="Type @ to mention someone."
          />
          <span>
            {allMentionedUsers.length > 0
              && `${allMentionedUsers.length} ${
                allMentionedUsers.length > 1 ? 'people' : 'person'
              } will be notified.`}
          </span>
        </div>
        <ButtonRow type="positive">
          <Button
            type="outline"
            className="cancelSaveBtn"
            data-testid="cancel-add-note-button"
            onClick={() => {
              setShowCreateNote(false);
              setNoteText('');
              setUsersMentioned([]);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={noteText.trim().length === 0}
            type="neutral"
            data-testid="create-note-button"
            className="cancelSaveBtn"
            onClick={() => {
              saveNote();
              setShowCreateNote(false);
              setNoteText('');
              setUsersMentioned([]);
            }}
          >
            {isReply ? 'Reply' : 'Save'}
          </Button>
        </ButtonRow>
      </div>
    </div>
  );
};

NoteInput.propTypes = {
  users: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isPrivate: PropTypes.bool,
  isReply: PropTypes.bool,
  createNote: PropTypes.func,
  activeCaseId: PropTypes.string,
  setShowCreateNote: PropTypes.func,
  note: PropTypes.shape({
    mentionedUserIds: PropTypes.shape([]),
    replies: PropTypes.shape([]),
    _id: PropTypes.string,
  }),
  addReply: PropTypes.func,
};

NoteInput.defaultProps = {
  isReply: false,
  users: null,
  isPrivate: false,
  createNote: () => {},
  activeCaseId: null,
  setShowCreateNote: () => {},
  note: null,
  addReply: () => {},
};

export default NoteInput;
