import { Button, Box, Tooltip } from '@chakra-ui/react';
import React from 'react';
import './ChatParticipantAddButton.less';

interface ChatParticipantAddButtonProps {
  onClick: () => void;
}

const ChatParticipantAddButton: React.FC<ChatParticipantAddButtonProps> = ({ onClick }) => (
  <Button
    variant="ghost"
    className="chat-participant-add-button"
    data-testid="chat-participant-add-button"
    onClick={onClick}
    size="sm"
    pl={1}
    color="gray.500"
  >
    <Tooltip hasArrow label="Add Participant"><Box className="chat-participant-add-circle">+</Box></Tooltip>
  </Button>
);

export default ChatParticipantAddButton;
