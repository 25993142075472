/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TimeDropdown from '../TimeDropdown/TimeDropdown';

const MultiTimeDropdown = ({ reply, setReply }) => {
  const [isMondayValid, setIsMondayValid] = useState(true);
  const [isTuesdayValid, setIsTuesdayValid] = useState(true);
  const [isWednesdayValid, setIsWednesdayValid] = useState(true);
  const [isThursdayValid, setIsThursdayValid] = useState(true);
  const [isFridayValid, setIsFridayValid] = useState(true);
  const [isSaturdayValid, setIsSaturdayValid] = useState(true);
  const [isSundayValid, setIsSundayValid] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      isMondayValid
      && isTuesdayValid
      && isWednesdayValid
      && isThursdayValid
      && isFridayValid
      && isSaturdayValid
      && isSundayValid
    ) {
      dispatch({ type: 'DISABLE_NEXT_BTN', payload: false });
    } else {
      dispatch({ type: 'DISABLE_NEXT_BTN', payload: true });
    }
  }, [
    dispatch,
    isMondayValid,
    isTuesdayValid,
    isWednesdayValid,
    isThursdayValid,
    isFridayValid,
    isSaturdayValid,
    isSundayValid,
  ]);

  return (
    <div>
      <TimeDropdown
        reply={reply}
        setReply={setReply}
        weekday="monday"
        showAllDayCheckBox={false}
        setIsTimeValid={setIsMondayValid}
      />
      <TimeDropdown
        reply={reply}
        setReply={setReply}
        weekday="tuesday"
        showAllDayCheckBox={false}
        setIsTimeValid={setIsTuesdayValid}
      />
      <TimeDropdown
        reply={reply}
        setReply={setReply}
        weekday="wednesday"
        showAllDayCheckBox={false}
        setIsTimeValid={setIsWednesdayValid}
      />
      <TimeDropdown
        reply={reply}
        setReply={setReply}
        weekday="thursday"
        showAllDayCheckBox={false}
        setIsTimeValid={setIsThursdayValid}
      />
      <TimeDropdown
        reply={reply}
        setReply={setReply}
        weekday="friday"
        showAllDayCheckBox={false}
        setIsTimeValid={setIsFridayValid}
      />
      <TimeDropdown
        reply={reply}
        setReply={setReply}
        weekday="saturday"
        showAllDayCheckBox={false}
        setIsTimeValid={setIsSaturdayValid}
      />
      <TimeDropdown
        reply={reply}
        setReply={setReply}
        weekday="sunday"
        showAllDayCheckBox={false}
        setIsTimeValid={setIsSundayValid}
      />
    </div>
  );
};

MultiTimeDropdown.propTypes = {
  setReply: PropTypes.func.isRequired,
  reply: PropTypes.shape({}).isRequired,
};

export default MultiTimeDropdown;
