import React, {
  useState, useRef, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { capitalizeEachWord } from '../../../../helpers/common';
import List from '../../List/List'
import DropdownIconDown from '../../../../../images/icons/dropdown-arrow--down.svg'
import FormField from '../../../Form/FormField/FormField';
import { handleFormInputChange } from '../../../../actions/common'
import Divider from '../../Divider/Divider'
import './TypeAheadDropdown.less'

const TypeAheadDropdown = ({
  list,
  onSelect,
  className,
  testId,
  placeholder,
  type,
  existingValue,
  handleFormInputChange,
  operatorData,
  allowEdit,
}) => {
  const [suggestions, setSuggestions] = useState(list);
  const [newSuggestion, setNewSuggestion] = useState([]);
  const [selectedValue, setSelectedValue] = useState(existingValue);
  const [open, setOpen] = useState(false)
  const componentRef = useRef(null)

  const handleClick = (e) => {
    if (componentRef.current && componentRef.current.contains(e.target)) return
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClick, true);
    } else {
      document.removeEventListener('click', handleClick, true);
    }
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [open]);

  const onTextChange = useCallback(({ target: { value } }) => {
    let textSuggestions = [];

    setSelectedValue(value)

    if (value.length > 0) {
      const regexValue = new RegExp(value, 'i');
      textSuggestions = list.sort().filter((val) => regexValue.test(val._id || val.id));
      if (value.length > 2) {
        const newValue = capitalizeEachWord(value)
        setNewSuggestion([{ id: newValue, label: `Add a new ${type}: ${newValue}` }])
      } else {
        setNewSuggestion([])
      }
    } else {
      textSuggestions = [...list]
      setNewSuggestion([])
    }
    setSuggestions(textSuggestions)
    setOpen(true)
  }, [list, type])

  const onInputFocus = (e) => {
    if (selectedValue) {
      setSuggestions([{
        id: selectedValue,
        label: selectedValue,
      }])
    }
    if (e.target.value) {
      onTextChange(e)
    }
    setOpen(true)
  }

  const onClickIcon = () => {
    setOpen(!open)
  }

  const suggestionSelected = useCallback((value) => {
    setSelectedValue(value)
    setOpen(false)
    setSuggestions([])
    if (onSelect) {
      onSelect(value)
    }
    if (operatorData) {
      handleFormInputChange({
        inputId: 'title',
        tableId: 'user',
        value,
      })
    }
  }, [operatorData, handleFormInputChange, onSelect])

  const renderSuggestions = useMemo(() => (
    <div className="type-ahead-list">
      {
      suggestions.length > 0
        && (
          <List
            items={suggestions}
            onToggle={suggestionSelected}
            focused
            maxDisplay={suggestions.length > 10 ? 10 : suggestions.length}
            adjustable
            focusedValue={selectedValue}
          />
        )
      }

      {
        (suggestions.length > 0 && newSuggestion.length > 0)
        && (<Divider />)
      }
      {
        newSuggestion.length > 0
        && (
          <List
            items={newSuggestion}
            onToggle={suggestionSelected}
            focused
            focusedValue={selectedValue}
          />
        )
      }

    </div>
  ), [suggestions, selectedValue, newSuggestion, suggestionSelected])

  return (
    <div
      className={`${className || ''} type-ahead-container`}
      data-testid={testId}
      ref={componentRef}
    >
      <button type="button" className="type-ahead-input">
        <FormField
          id="operatorTitle"
          data-testid="profile-edit-operator-title"
          className="type-ahead-form"
          label="Title"
          placeholder={placeholder}
          value={selectedValue}
          disabled={!allowEdit}
          onChange={(evt) => allowEdit && onTextChange(evt)}
          onFocus={(evt) => allowEdit && onInputFocus(evt)}
        />
        <span
          role="button"
          tabIndex="0"
          className={`${open && 'marley-dropdown-open'} type-ahead-dropdown`}
          onClick={(evt) => onClickIcon(evt)}
          onKeyDown={(evt) => onClickIcon(evt)}
        >
          {
            (suggestions.length > 0 || selectedValue) && <DropdownIconDown />
          }
        </span>
      </button>

      {(open && allowEdit
        && (suggestions.length > 0 || newSuggestion.length > 0)) && renderSuggestions}

    </div>
  )
}

const mapDispatchToProps = {
  handleFormInputChange,
}

TypeAheadDropdown.propTypes = {
  list: PropTypes.arrayOf(Object),
  onSelect: PropTypes.func,
  className: PropTypes.string,
  testId: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  existingValue: PropTypes.string,
  operatorData: PropTypes.bool,
  allowEdit: PropTypes.bool,
};

TypeAheadDropdown.defaultProps = {
  list: [],
  onSelect: () => {},
  className: '',
  testId: '',
  placeholder: '',
  type: 'item',
  existingValue: '',
  operatorData: false,
  allowEdit: true,
}

export default connect(null, mapDispatchToProps)(TypeAheadDropdown)
