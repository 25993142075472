/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon } from '@chakra-ui/react';
import {
  Card, TrayContainer, Checkbox, InfoCircleIcon,
} from '@himarley/unity';
import { updateOrgSetting } from '../../../actions/organization';
import { LINES_OF_BUSINESS } from './helpers';
import LoBIcon from './lob-icon';
import styles from './LoBTrayContainer.module.less';

const LoBTrayContainer = ({
  onCancel,
  orgId,
  brandName,
  linesOfBusiness,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const isUpdatingOrgSetting = useSelector(
    (state) => state?.organizations?.isUpdatingOrgSetting,
  );

  const isLoBAlreadySelected = useCallback(
    ({ type, subType }) => linesOfBusiness.find(
      (lob) => lob.type === type && lob.subType === subType,
    ) !== undefined,
    [linesOfBusiness],
  );

  const [isPersonalAutoSelected, setIsPersonalAutoSelected] = useState(
    isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_AUTO),
  );
  const [isPersonalHomeSelected, setIsPersonalHomeSelected] = useState(
    isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_HOME),
  );
  const [isPersonalOtherSelected, setIsPersonalOtherSelected] = useState(
    isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_OTHER),
  );
  const [isCommercialAutoSelected, setIsCommercialAutoSelected] = useState(
    isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_AUTO),
  );
  const [isCommercialLiabilitySelected, setIsCommercialLiabilitySelected] = useState(
    isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_GENERAL_LIABILITY),
  );
  const [isCommercialPropertySelected, setIsCommercialPropertySelected] = useState(
    isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_PROPERTY),
  );
  const [isCommercialWorkersCompSelected, setIsCommercialWorkersCompSelected] = useState(
    isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP),
  );
  const [isCommercialOtherSelected, setIsCommercialOtherSelected] = useState(
    isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_OTHER),
  );
  const [isMiscCropSelected, setIsMiscCropSelected] = useState(
    isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_CROP),
  );
  const [isMiscLifeSelected, setIsMiscLifeSelected] = useState(
    isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_LIFE),
  );
  const [isMiscOtherSelected, setIsMiscOtherSelected] = useState(
    isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_OTHER),
  );

  const numberOfLinesSelected = [
    isPersonalAutoSelected,
    isPersonalHomeSelected,
    isPersonalOtherSelected,
    isCommercialAutoSelected,
    isCommercialLiabilitySelected,
    isCommercialPropertySelected,
    isCommercialWorkersCompSelected,
    isCommercialOtherSelected,
    isMiscCropSelected,
    isMiscLifeSelected,
    isMiscOtherSelected,
  ].filter(Boolean).length;

  useEffect(() => {
    setIsPersonalAutoSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_AUTO),
    );
    setIsPersonalHomeSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_HOME),
    );
    setIsPersonalOtherSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_OTHER),
    );
    setIsCommercialAutoSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_AUTO),
    );
    setIsCommercialLiabilitySelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_GENERAL_LIABILITY),
    );
    setIsCommercialPropertySelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_PROPERTY),
    );
    setIsCommercialWorkersCompSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP),
    );
    setIsCommercialOtherSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_OTHER),
    );
    setIsMiscCropSelected(isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_CROP));
    setIsMiscLifeSelected(isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_LIFE));
    setIsMiscOtherSelected(isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_OTHER));
  }, [isLoBAlreadySelected, linesOfBusiness]);

  const handleCancel = () => {
    setIsPersonalAutoSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_AUTO),
    );
    setIsPersonalHomeSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_HOME),
    );
    setIsPersonalOtherSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_OTHER),
    );
    setIsCommercialAutoSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_AUTO),
    );
    setIsCommercialLiabilitySelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_GENERAL_LIABILITY),
    );
    setIsCommercialPropertySelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_PROPERTY),
    );
    setIsCommercialWorkersCompSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP),
    );
    setIsCommercialOtherSelected(
      isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_OTHER),
    );
    setIsMiscCropSelected(isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_CROP));
    setIsMiscLifeSelected(isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_LIFE));
    setIsMiscOtherSelected(isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_OTHER));
    onCancel();
  };

  const handleSave = () => {
    if (numberOfLinesSelected === 0) {
      handleCancel();
      return;
    }
    const linesOfBusiness = [];
    isPersonalAutoSelected
      && !isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_AUTO)
      && linesOfBusiness.push(LINES_OF_BUSINESS.PERSONAL_AUTO);
    isPersonalHomeSelected
      && !isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_HOME)
      && linesOfBusiness.push(LINES_OF_BUSINESS.PERSONAL_HOME);
    isPersonalOtherSelected
      && !isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_OTHER)
      && linesOfBusiness.push(LINES_OF_BUSINESS.PERSONAL_OTHER);
    isCommercialAutoSelected
      && !isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_AUTO)
      && linesOfBusiness.push(LINES_OF_BUSINESS.COMMERCIAL_AUTO);
    isCommercialLiabilitySelected
      && !isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_GENERAL_LIABILITY)
      && linesOfBusiness.push(LINES_OF_BUSINESS.COMMERCIAL_GENERAL_LIABILITY);
    isCommercialPropertySelected
      && !isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_PROPERTY)
      && linesOfBusiness.push(LINES_OF_BUSINESS.COMMERCIAL_PROPERTY);
    isCommercialWorkersCompSelected
      && !isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP)
      && linesOfBusiness.push(LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP);
    isCommercialOtherSelected
      && !isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_OTHER)
      && linesOfBusiness.push(LINES_OF_BUSINESS.COMMERCIAL_OTHER);
    isMiscCropSelected
      && !isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_CROP)
      && linesOfBusiness.push(LINES_OF_BUSINESS.OTHER_CROP);
    isMiscLifeSelected
      && !isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_LIFE)
      && linesOfBusiness.push(LINES_OF_BUSINESS.OTHER_LIFE);
    isMiscOtherSelected
      && !isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_OTHER)
      && linesOfBusiness.push(LINES_OF_BUSINESS.OTHER_OTHER);
    // attach brand name to each LoB
    linesOfBusiness.forEach((lob) => {
      lob.branding = brandName;
    });

    if (linesOfBusiness.length === 0) {
      handleCancel();
      return;
    }

    dispatch(
      updateOrgSetting(
        {
          organizationId: orgId,
          linesOfBusiness,
        },
        'Lines of Business saved.',
        'Error Saving Lines of Business. Please Try Again.',
      ),
    );
  };

  return (
    isOpen !== null && (
    <TrayContainer
      className={`${
        isOpen === null
          ? styles.lobTrayContainer
          : isOpen !== ''
            ? 'animation-slide-left'
            : 'animation-slide-dismiss'
      } `}
      onCancel={handleCancel}
      disabled={isUpdatingOrgSetting}
      onSave={handleSave}
      saveButtonText={`${isUpdatingOrgSetting ? 'Saving' : 'Save Lines'}`}
      title="Lines of Business"
      infoLabel={(
        <div className={styles.infoLabel}>
          <Icon mr="5px" as={InfoCircleIcon} />
          Select lines of business for this brand.
        </div>
          )}
    >
      <section>
        <div data-testid="personalLines">
          <label>Personal Lines</label>
          <Card
            disabled={isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_AUTO)}
            onClick={(event) => {
              if (event.target.type === 'checkbox') {
                return;
              }
              !isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_AUTO)
                    && setIsPersonalAutoSelected(!isPersonalAutoSelected);
            }}
            className={`${
              isPersonalAutoSelected
                ? styles.selectedLoBCard
                : styles.unselectedLoBCard
            } ${styles.lobCard}`}
          >
            <div className={styles.nameWrap}>
              <LoBIcon name="auto" />
              <span>Auto</span>
            </div>
            <Checkbox
              disabled={isLoBAlreadySelected(
                LINES_OF_BUSINESS.PERSONAL_AUTO,
              )}
              checked={isPersonalAutoSelected}
            />
          </Card>
          <Card
            disabled={isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_HOME)}
            onClick={(event) => {
              if (event.target.type === 'checkbox') {
                return;
              }
              !isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_HOME)
                    && setIsPersonalHomeSelected(!isPersonalHomeSelected);
            }}
            className={`${
              isPersonalHomeSelected
                ? styles.selectedLoBCard
                : styles.unselectedLoBCard
            } ${styles.lobCard}`}
          >
            <div className={styles.nameWrap}>
              <LoBIcon name="home" />
              <span>Home</span>
            </div>
            <Checkbox
              disabled={isLoBAlreadySelected(
                LINES_OF_BUSINESS.PERSONAL_HOME,
              )}
              checked={isPersonalHomeSelected}
            />
          </Card>
          <Card
            disabled={isLoBAlreadySelected(
              LINES_OF_BUSINESS.PERSONAL_OTHER,
            )}
            onClick={(event) => {
              if (event.target.type === 'checkbox') {
                return;
              }
              !isLoBAlreadySelected(LINES_OF_BUSINESS.PERSONAL_OTHER)
                    && setIsPersonalOtherSelected(!isPersonalOtherSelected);
            }}
            className={`${
              isPersonalOtherSelected
                ? styles.selectedLoBCard
                : styles.unselectedLoBCard
            } ${styles.lobCard}`}
          >
            <div className={styles.nameWrap}>
              <LoBIcon name="other" />
              <span>Other</span>
            </div>
            <Checkbox
              disabled={isLoBAlreadySelected(
                LINES_OF_BUSINESS.PERSONAL_OTHER,
              )}
              checked={isPersonalOtherSelected}
            />
          </Card>
        </div>
        <div data-testid="commercialLines">
          <label>Commercial Lines</label>
          <Card
            disabled={isLoBAlreadySelected(
              LINES_OF_BUSINESS.COMMERCIAL_AUTO,
            )}
            onClick={(event) => {
              if (event.target.type === 'checkbox') {
                return;
              }
              !isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_AUTO)
                    && setIsCommercialAutoSelected(!isCommercialAutoSelected);
            }}
            className={`${
              isCommercialAutoSelected
                ? styles.selectedLoBCard
                : styles.unselectedLoBCard
            } ${styles.lobCard}`}
          >
            <div className={styles.nameWrap}>
              <LoBIcon name="auto" />
              <span>Auto</span>
            </div>
            <Checkbox
              disabled={isLoBAlreadySelected(
                LINES_OF_BUSINESS.COMMERCIAL_AUTO,
              )}
              checked={isCommercialAutoSelected}
            />
          </Card>
          <Card
            disabled={isLoBAlreadySelected(
              LINES_OF_BUSINESS.COMMERCIAL_GENERAL_LIABILITY,
            )}
            onClick={(event) => {
              if (event.target.type === 'checkbox') {
                return;
              }
              !isLoBAlreadySelected(
                LINES_OF_BUSINESS.COMMERCIAL_GENERAL_LIABILITY,
              )
                    && setIsCommercialLiabilitySelected(
                      !isCommercialLiabilitySelected,
                    );
            }}
            className={`${
              isCommercialLiabilitySelected
                ? styles.selectedLoBCard
                : styles.unselectedLoBCard
            } ${styles.lobCard}`}
          >
            <div className={styles.nameWrap}>
              <LoBIcon name="general-liability" />
              <span>General Liability</span>
            </div>
            <Checkbox
              disabled={isLoBAlreadySelected(
                LINES_OF_BUSINESS.COMMERCIAL_GENERAL_LIABILITY,
              )}
              checked={isCommercialLiabilitySelected}
            />
          </Card>
          <Card
            disabled={isLoBAlreadySelected(
              LINES_OF_BUSINESS.COMMERCIAL_PROPERTY,
            )}
            onClick={(event) => {
              if (event.target.type === 'checkbox') {
                return;
              }
              !isLoBAlreadySelected(
                LINES_OF_BUSINESS.COMMERCIAL_PROPERTY,
              )
                    && setIsCommercialPropertySelected(
                      !isCommercialPropertySelected,
                    );
            }}
            className={`${
              isCommercialPropertySelected
                ? styles.selectedLoBCard
                : styles.unselectedLoBCard
            } ${styles.lobCard}`}
          >
            <div className={styles.nameWrap}>
              <LoBIcon name="property" />
              <span>Property</span>
            </div>
            <Checkbox
              disabled={isLoBAlreadySelected(
                LINES_OF_BUSINESS.COMMERCIAL_PROPERTY,
              )}
              checked={isCommercialPropertySelected}
            />
          </Card>
          <Card
            disabled={isLoBAlreadySelected(
              LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP,
            )}
            onClick={(event) => {
              if (event.target.type === 'checkbox') {
                return;
              }
              !isLoBAlreadySelected(
                LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP,
              )
                    && setIsCommercialWorkersCompSelected(
                      !isCommercialWorkersCompSelected,
                    );
            }}
            className={`${
              isCommercialWorkersCompSelected
                ? styles.selectedLoBCard
                : styles.unselectedLoBCard
            } ${styles.lobCard}`}
          >
            <div className={styles.nameWrap}>
              <LoBIcon name="workers-comp" />
              <span>Workers Comp.</span>
            </div>
            <Checkbox
              disabled={isLoBAlreadySelected(
                LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP,
              )}
              checked={isCommercialWorkersCompSelected}
            />
          </Card>
          <Card
            disabled={isLoBAlreadySelected(
              LINES_OF_BUSINESS.COMMERCIAL_OTHER,
            )}
            onClick={(event) => {
              if (event.target.type === 'checkbox') {
                return;
              }
              !isLoBAlreadySelected(LINES_OF_BUSINESS.COMMERCIAL_OTHER)
                    && setIsCommercialOtherSelected(!isCommercialOtherSelected);
            }}
            className={`${
              isCommercialOtherSelected
                ? styles.selectedLoBCard
                : styles.unselectedLoBCard
            } ${styles.lobCard}`}
          >
            <div className={styles.nameWrap}>
              <LoBIcon name="other" />
              <span>Other</span>
            </div>
            <Checkbox
              disabled={isLoBAlreadySelected(
                LINES_OF_BUSINESS.COMMERCIAL_OTHER,
              )}
              checked={isCommercialOtherSelected}
            />
          </Card>
        </div>
        <div data-testid="otherLines">
          <label>Other/Misc. Lines</label>
          <Card
            disabled={isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_CROP)}
            onClick={(event) => {
              if (event.target.type === 'checkbox') {
                return;
              }
              !isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_CROP)
                    && setIsMiscCropSelected(!isMiscCropSelected);
            }}
            className={`${
              isMiscCropSelected
                ? styles.selectedLoBCard
                : styles.unselectedLoBCard
            } ${styles.lobCard}`}
          >
            <div className={styles.nameWrap}>
              <LoBIcon name="crop" />
              <span>Crop</span>
            </div>
            <Checkbox
              disabled={isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_CROP)}
              checked={isMiscCropSelected}
            />
          </Card>
          <Card
            disabled={isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_LIFE)}
            onClick={(event) => {
              if (event.target.type === 'checkbox') {
                return;
              }
              !isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_LIFE)
                    && setIsMiscLifeSelected(!isMiscLifeSelected);
            }}
            className={`${
              isMiscLifeSelected
                ? styles.selectedLoBCard
                : styles.unselectedLoBCard
            } ${styles.lobCard}`}
          >
            <div className={styles.nameWrap}>
              <LoBIcon name="life" />
              <span>Life</span>
            </div>
            <Checkbox
              disabled={isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_LIFE)}
              checked={isMiscLifeSelected}
            />
          </Card>
          <Card
            disabled={isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_OTHER)}
            onClick={(event) => {
              if (event.target.type === 'checkbox') {
                return;
              }
              !isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_OTHER)
                    && setIsMiscOtherSelected(!isMiscOtherSelected);
            }}
            className={`${
              isMiscOtherSelected
                ? styles.selectedLoBCard
                : styles.unselectedLoBCard
            } ${styles.lobCard}`}
          >
            <div className={styles.nameWrap}>
              <LoBIcon name="other" />
              <span>Other</span>
            </div>
            <Checkbox
              disabled={isLoBAlreadySelected(LINES_OF_BUSINESS.OTHER_OTHER)}
              checked={isMiscOtherSelected}
            />
          </Card>
        </div>
      </section>
    </TrayContainer>
    )
  );
};

LoBTrayContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  linesOfBusiness: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpen: PropTypes.bool,
};

LoBTrayContainer.defaultProps = {
  isOpen: false,
};

export default LoBTrayContainer;
