/* eslint-disable import/prefer-default-export */
import {
  UPDATE_ORG_SETTING,
  UPDATE_LINE_OF_BUSINESS,
  GET_FULL_ORG_DATA,
  UPDATE_ORGANIZATION_BRANDING as ENDPOINT_UPDATE_ORGANIZATION_BRANDING,
} from '../constants/endpoints';
import {
  UPDATE_ORGANIZATION_SETTING,
  UPDATE_LINE_OF_BUSINESS_ACTION,
  SNACKBAR_SHOW,
  GET_FULL_ORGANIZATION_DATA,
  SET_IS_UPDATING_ORG_SETTING,
  SET_IS_UPDATING_LINE_OF_BUSINESS,
  IS_GETTING_FULL_ORGANIZATION_DATA,
  UPDATE_ORGANIZATION_BRANDING,
  SET_IS_UPDATING_BRANDING,
} from '../constants/actions';
import { capitalizeEachWord } from '../helpers/format';

import { cancelableRequest } from './api';

export const updateOrgSetting =
  (data, successText, errorText) => (dispatch) => {
    dispatch({ type: SET_IS_UPDATING_ORG_SETTING, payload: true });
    const args = {
      endpoint: UPDATE_ORG_SETTING(data?.organizationId),
      action: UPDATE_ORGANIZATION_SETTING,
      method: 'PUT',
      data,
      process: ({ body }) => body,
      callback: ({ body }) => {
        dispatch({ type: SET_IS_UPDATING_ORG_SETTING, payload: false });
        dispatch({ type: UPDATE_ORGANIZATION_SETTING, payload: body });
        dispatch({
          type: SNACKBAR_SHOW,
          payload: { text: successText, isError: false },
        });
      },
      errorCallback: () => {
        dispatch({ type: SET_IS_UPDATING_ORG_SETTING, payload: 'error' });
        dispatch({
          type: SNACKBAR_SHOW,
          payload: { text: errorText, isError: true },
        });
      },
    };
    return dispatch(
      cancelableRequest({
        ...args,
        cancelToken: (c) => {
        },
      }),
    );
  };
export const updateLineOfBusiness =
  (data, successText, errorText) => (dispatch) => {
    dispatch({ type: SET_IS_UPDATING_LINE_OF_BUSINESS, payload: true });
    const args = {
      endpoint: UPDATE_LINE_OF_BUSINESS(
        data?.organizationId,
        data?.lineOfBusinessId,
      ),
      action: UPDATE_LINE_OF_BUSINESS_ACTION,
      method: 'PUT',
      data,
      process: ({ body }) => body,
      callback: ({ body }) => {
        dispatch({ type: SET_IS_UPDATING_LINE_OF_BUSINESS, payload: false });
        dispatch({ type: UPDATE_LINE_OF_BUSINESS_ACTION, payload: body });
        dispatch({
          type: SNACKBAR_SHOW,
          payload: {
            text: body.archived
              ? `${capitalizeEachWord(body.type)} ${capitalizeEachWord(
                  body?.subType,
                  body?.subType?.includes('-') ? '-' : ' ',
                )}${
                  body?.subType === 'workers-comp' ? '.' : ''
                } removed from ${capitalizeEachWord(body.branding)}.`
              : successText,
            isError: false,
          },
        });
      },
      errorCallback: () => {
        dispatch({ type: SET_IS_UPDATING_LINE_OF_BUSINESS, payload: 'error' });
        dispatch({
          type: SNACKBAR_SHOW,
          payload: { text: errorText, isError: true },
        });
      },
    };
    return dispatch(
      cancelableRequest({
        ...args,
        cancelToken: (c) => {
        },
      }),
    );
  };

export const setIsUpdatingOrgSetting = (isUpdating) => (dispatch) => {
  dispatch({ type: SET_IS_UPDATING_ORG_SETTING, payload: isUpdating });
};

export const setIsUpdatingBrand = (isUpdating) => (dispatch) => {
  dispatch({ type: SET_IS_UPDATING_BRANDING, payload: isUpdating });
};

export const getFullOrganizationData = (orgId) => (dispatch) => {
  dispatch({ type: IS_GETTING_FULL_ORGANIZATION_DATA, payload: true });
  const args = {
    endpoint: GET_FULL_ORG_DATA(orgId),
    method: 'GET',
    action: GET_FULL_ORGANIZATION_DATA,
    process: ({ body }) => body,
    callback: ({ body }) => {
      dispatch({ type: IS_GETTING_FULL_ORGANIZATION_DATA, payload: false });
      dispatch({ type: GET_FULL_ORGANIZATION_DATA, payload: body });
    },
    errorCallback: () => {
      dispatch({ type: IS_GETTING_FULL_ORGANIZATION_DATA, payload: false });
      dispatch({
        type: SNACKBAR_SHOW,
        payload: {
          text: 'Error getting Opt-In messages. Please try again.',
          isError: true,
        },
      });
    },
  };

  return dispatch(
    cancelableRequest({
      ...args,
      cancelToken: (c) => {
      },
    }),
  );
};

export const updateBranding = (data, successText, errorText) => (dispatch) => {
  const { organizationId } = data;
  dispatch({ type: SET_IS_UPDATING_BRANDING, payload: true });
  try {
    const args = {
      endpoint: ENDPOINT_UPDATE_ORGANIZATION_BRANDING(organizationId),
      method: 'PUT',
      data,
      action: UPDATE_ORGANIZATION_BRANDING,
      process: ({ body }) => body,
      callback: ({ body }) => {
        dispatch({ type: SET_IS_UPDATING_BRANDING, payload: false });
        dispatch({ type: UPDATE_ORGANIZATION_BRANDING, payload: body });
        dispatch({
          type: SNACKBAR_SHOW,
          payload: { text: successText, isError: false },
        });
      },
      errorCallback: () => {
        dispatch({ type: SET_IS_UPDATING_BRANDING, payload: 'error' });
        dispatch({
          type: SNACKBAR_SHOW,
          payload: { isError: true, text: errorText },
        });
      },
    };
    return dispatch(
      cancelableRequest({
        ...args,
        cancelToken: (c) => {
        },
      }),
    );
  } catch (error) {
    console.log(error);
  }
};
