/* eslint-disable object-shorthand */
import phone from "phone";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";

export const UserFormValidation = {
  cleanPhone: function (phone) {
    // no need to sanitize, we'll be able to pass the exact phone number format to "phone" library
    return (phone || "").replace("+1", "").replace(/\D/g, "");
  },

  formatPhoneNumber: function (phone) {
    const numbers = this.cleanPhone(phone);
    const char = { 0: "(", 3: ") ", 6: "-" };
    let formatted = "";
    for (let i = 0; i < numbers.length; i++) {
      formatted += (char[i] || "") + numbers[i];
    }
    return formatted.slice(0, 14);
  },

  phoneToE164Format: function (phoneNumber) {
    return phone(phoneNumber, "USA");
  },

  validatePhone: function (phone) {
    return phone && (

      isMobilePhone(phone, "en-US")
      || phone.slice(0, 3) === '555'
      || phone.slice(1, 4) === '555'
    )
  },

  validateName: function (name) {
    return !!name;
  },

  validateEmail: function (email) {
    // email is not required on case forms. So return true if null
    return email ? isEmail(email) : true;
  },

  validatePassword: function (password) {
    return password && password.length > 0;
  },

  formatName: function ({ firstName, lastName }) {
    return `${lastName}, ${firstName}`;
  },

  // may just make this two params instead of an object. 🤔
  userFormValidation: function ({ fieldName, userFormData }) {
    const validator = {
      phoneNumber: this.validatePhone,
      firstName: this.validateName,
      lastName: this.validateName,
      email: this.validateEmail,
    };

    const value = userFormData[fieldName];
    const validationMethod = validator[fieldName];

    const validation = {
      isValid: true,
    };

    if (validationMethod && !validationMethod(value)) {
      validation.isValid = false;
      return validation;
    }

    return validation;
  },

  noop: () => { },
};
