/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Button from '../elements/buttons/Button/Button';

// Display button with "Home", used for oauth error screen and splash screen
export const Logout = () => {
  const { oktaAuth } = useOktaAuth();
  const logout = async () => oktaAuth.signOut('/');
  return (
    <Button className="home-button" onClick={logout}>
      {' '}
      Back to Login
      {' '}
    </Button>
  );
};
