import React from 'react';
import OpenWindow from '../../../images/icons/openWindow-gray-icon.svg';
import styles from './VehicleData.module.less';
import buildCopartData from './helper';
import { totalLossDataPropTypes } from '../../helpers/copart-utils';

const VehicleData = ({ totalLossData }) => {
  const {
    ownerInfo,
    vehicleInfo,
    vehicleLocationInfo,
    yardLocationInfo,
    sellerPortalLink,
    titleExpressLink,
  } = buildCopartData(totalLossData);
  const copartDataSet = [
    {
      title: 'Seller Portal',
      key: 'sellerPortalLink',
      data: sellerPortalLink,
      show: !!sellerPortalLink,
    },
    {
      title: 'Title Express',
      key: 'titleExpressLink',
      data: titleExpressLink,
      show: !!titleExpressLink,
    },
    {
      title: 'Vehicle Owner',
      key: 'ownerInfo',
      data: ownerInfo,
      show: !!ownerInfo,
    },
    {
      title: 'Vehicle Info',
      key: 'vehicleInfo',
      data: vehicleInfo,
      show: !!vehicleInfo,
    },
    {
      title: 'Vehicle Location',
      key: 'vehicleLocation',
      data: vehicleLocationInfo,
      show: !!vehicleLocationInfo,
    },
    {
      title: 'Yard Info',
      key: 'yardLocation',
      data: yardLocationInfo,
      show: !!yardLocationInfo,
    },
  ];

  const renderLink = (link) => {
    const { url, text } = link;
    return (
      <a className={styles.vehicleDataItemLink} data-testid="vehicleDataItemLink" href={url} target="_blank" rel="noreferrer">
        {text}
        {' '}
        <OpenWindow />
      </a>
    );
  };

  return (
    <div className={styles.vehicleDataContainer}>
      {
        copartDataSet
          .filter((item) => item.show)
          .map((item) => (
            <div key={item.key} className={styles.vehicleDataItem}>
              <p className={styles.vehicleDataItemTitle}>{item.title}</p>
              {
                Object.entries(item.data).map(([key, value]) => (
                  key === 'link'
                    ? (renderLink(value))
                    : <p key={key} className={styles.vehicleDataItemList}>{value}</p>
                ))
              }
            </div>
          ))
      }
    </div>
  );
};

VehicleData.propTypes = {
  totalLossData: totalLossDataPropTypes.isRequired,
};

export default VehicleData;
