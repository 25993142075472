import { defineStyleConfig, ButtonProps } from '@chakra-ui/react';

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    borderRadius: 'base',
    fontWeight: 'normal',
  },
  defaultProps: {
    colorScheme: 'navy',
    size: 'md',
    variant: 'solid',
  },
  variants: {
    ghost: {
      svg: {
        color: 'gray.500',
      },
    },
    outline: {
      svg: {
        color: 'navy.600',
      },
    },
    solid: (props: ButtonProps) => ({
      svg: {
        color: props.color || 'white',
      },
    }),
    link: {
      svg: {
        color: 'blue.500',
      },
    },
  },
});

export default buttonTheme;
