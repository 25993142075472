import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SearchBar from '../SearchBar/SearchBar';
import { filterItems } from '../../actions/common';
import { clearFilter } from '../../actions/ui';
import './SearchBarWrap.less';
import { FILTER_DEBOUNCE } from '../../constants/config';

class SearchBarWrapDis extends Component {
  constructor(props) {
    super(props);
    this.state = { filter: '', bufferTimeout: null };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    const {
      id, type, value, clearFilter: clearFilterProp,
    } = this.props;
    const newId = (!type) ? id : type.id();
    if (value) this.handleFilterChange({ target: { value } });
    if (clearFilterProp) clearFilterProp(newId);
  }

  componentWillUnmount() {
    const {
      id, type, clearFilter: clearFilterProp,
    } = this.props;
    const newId = (!type) ? id : type.id();
    if (clearFilterProp) clearFilterProp(newId);
  }

  handleFilterChange(event) {
    const { type: typeProp, id, filterItems } = this.props;
    const filter = event.target.value;
    const type = typeProp ? typeProp.id() : id;
    // buffer users input to reduce traffic
    const { bufferTimeout } = this.state;
    clearTimeout(bufferTimeout);
    this.setState((prevState) => ({
      ...prevState,
      bufferTimeout: setTimeout(() => {
        this.setState({ filter });
        filterItems({ filter, type });
      }, FILTER_DEBOUNCE),
    }));
  }

  render() {
    const { type, label: labelProp, left } = this.props;
    const label = type ? type.lowercasePlural() : labelProp;
    const { filter } = this.state;

    return (
      <div className="search-bar-wrap">
        <div className="search-bar-button">
          {left}
        </div>
        <SearchBar
          type={type}
          placeholder={`Search ${label}`}
          value={filter}
          onChange={this.handleFilterChange}
        />
      </div>
    );
  }
}

SearchBarWrapDis.propTypes = {
  clearFilter: PropTypes.func,
  filterItems: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  left: PropTypes.node,
  type: PropTypes.shape({
    id: PropTypes.func,
    lowercasePlural: PropTypes.func,
  }),
  value: PropTypes.string,
};

SearchBarWrapDis.defaultProps = {
  clearFilter: null,
  filterItems: null,
  id: '',
  label: '',
  left: null,
  type: null,
  value: '',
};

export { SearchBarWrapDis };

const mapDispatchToProps = { filterItems, clearFilter };

export default connect(null, mapDispatchToProps)(SearchBarWrapDis);
