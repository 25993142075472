import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import stripTags from 'striptags'
import '../MassNotificationVerificationModal/MassNotificationVerificationModal.less'
import '../MassNotificationModal/MassNotificationModal.less'
import './MassNotificationViewModal.less'
import CloseIcon from '../../../../images/icons/close.svg'
import CopyIcon from '../../../../images/icons/document.svg'


const copyToClipboard = (currentNotification, setMessageCopied) => {
  navigator.clipboard.writeText(currentNotification.Message)
    .then(() => {
      setMessageCopied(true)
    })
}

const formatMessage = (message) => {
  const dollarRegex = /(\s|^)(\$(\S)+)\b/gm
  const newMessage = stripTags(message)
  return newMessage.replace(dollarRegex, (match, g1, g2) => `<span class="boldFormattedWord">${g1}${g2}</span>`)
}

const MassNotificationViewModal = ({
  show,
  hide,
  activeMassNotification,
  setMessageCopied,
  copied,
}) => (
  <Modal
    show={show}
    onHide={hide}
    className="mass-notification-view-modal"
  >
    <Modal.Header>
      <div
        role="link"
        tabIndex="0"
        onClick={hide}
        className="mass-notification-minimize-button"
      ><CloseIcon /></div>
    </Modal.Header>
    <Modal.Body>
      <div className="mass-notification-main-title">View Notification</div>
      <div className="textDisplay">
        <div className="inputTitle">Title</div>
        <div className="mass-notification-secondary-title">
          {activeMassNotification && activeMassNotification.Title}
        </div>
        <div className="inputTitle">Message</div>
        {
                    activeMassNotification &&
                    <div className="mass-notification-secondary-title" dangerouslySetInnerHTML={{ __html: formatMessage(activeMassNotification.Message) }} />
                }
      </div>
      {
          !copied &&
          <div className="copyBtn flexCenter" onClick={() => copyToClipboard(activeMassNotification, setMessageCopied)}>
                        Copy to clipboard <CopyIcon />
          </div>
      }
      {copied &&
      <div className="copyBtn flexCenter">
                    Copied to clipboard!
      </div>
             }
    </Modal.Body>
  </Modal>
)

MassNotificationViewModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  activeMassNotification: PropTypes.object,
  setMessageCopied: PropTypes.func.isRequired,
  copied: PropTypes.bool,
}

const mapStateToProps = state => ({
  copied: get(state, 'massNotifications.messageCopied'),
})


export default connect(mapStateToProps)(MassNotificationViewModal)
