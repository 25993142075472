import React from 'react';
import PropTypes, { bool } from 'prop-types';
import _ from 'lodash';
import Ghosts from '../../../elements/Ghosts/Ghosts';
import UserRow from '../UserRow/UserRow';
import { capitalizeEachWord, sortOperatorsByLabel, getShortenedEmail } from '../../../../helpers/common';
import TootipInfo from '../../../elements/Tootip/TooltipInfo';

const UserList = ({
  items, onToggle, isLoading, displayEmail,
}) => (
  <div>
    {isLoading ? <Ghosts className="user-row" /> : sortOperatorsByLabel(_.unionBy(items, 'id')).map((item) => (
      <UserRow
        onClick={() => onToggle(item.id)}
        key={`ADD_OPERATORS_SEARCH_${item.id}`}
        user={
        {
          imageUrl: item.imageUrl,
          name: item.label,
          role: item.role,
          id: item.id,
        }
      }
      >
        <div className="operator-details">
          <div>
            <div className="user-name">{item.label}</div>
            <div className="user-role">{capitalizeEachWord(item.title || item.role)}</div>
          </div>
          {displayEmail && (
            <div className="user-email">
              <TootipInfo message={item.email} icon={getShortenedEmail(item.email)} />
            </div>
          )}
        </div>
      </UserRow>
    ))}
  </div>
);

UserList.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
  onToggle: PropTypes.func.isRequired,
  isLoading: bool.isRequired,
  displayEmail: bool,
};

UserList.defaultProps = {
  displayEmail: false,
};

export default UserList;
