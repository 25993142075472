import _ from 'lodash'
import { buildIdList } from '../reducers/helpers'

const selector = (state) => {
    const generalAutoRepliesList = _.get(state, 'profile.userAutoReply.generalAutoReplies.list', [])
    const oooRepliesList = _.get(state, 'profile.userAutoReply.oooReplies.list', [])
    return {
        id: state.profile._id,
        generalAutoReplies: { ...buildIdList(generalAutoRepliesList), list: [...generalAutoRepliesList]},
        oooReplies: { ...buildIdList(oooRepliesList), list: [...oooRepliesList]},
        initializeAutoReply: state.profile.initializeAutoReply,
        error: state.profile.error
    }
}

export default selector