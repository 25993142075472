import { Divider } from '@himarley/unity';
import React from 'react';

import IntegrationRule from './integration-rule';
import { ToggleTemplateModalArgs, RuleMap, RuleData } from './integration-settings-types';
import styles from './IntegrationSettingsContainer.module.less';

interface IntegrationRulesListProps {
  ruleSettings: RuleMap;
  toggleTemplateModal: (args: ToggleTemplateModalArgs) => void;
  toggleRuleModal: (args: RuleData) => void;
}

const IntegrationRulesList = ({
  ruleSettings,
  toggleTemplateModal,
  toggleRuleModal,
}: IntegrationRulesListProps) => {
  const ruleIds = Object.keys(ruleSettings);
  const result = ruleIds.map((key, index) => {
    const {
      id,
      enabled,
      messageTemplate,
      rule,
      ruleName,
    } = ruleSettings[key];

    return (
      <div key={id} data-testid={`integration-rules-list-item-${index}`}>
        <IntegrationRule
          ruleName={ruleName || messageTemplate.displayName}
          messageTemplateText={messageTemplate.body}
          messageTemplateTokens={messageTemplate.tokens}
          messageTemplateDisplayName={messageTemplate.displayName}
          toggleTemplateModal={toggleTemplateModal}
          toggleRuleModal={toggleRuleModal}
          isEnabled={enabled}
          templateId={messageTemplate.id}
          ruleId={id}
          rule={rule}
        />
        <div className={styles.customDivider}>{index < ruleIds.length - 1 && <Divider />}</div>
      </div>
    );
  });
  return result;
};

export default IntegrationRulesList;
