import { TextAreaInput } from '@himarley/unity';
import filter from 'lodash/filter';
import React, { useState, useEffect, useRef } from 'react';

import useDynamicValue from '@app/components/elements/hooks/useDynamicValue';
import useKeyNav from '@app/components/elements/hooks/useKeyNav';
import useKeyTrigger from '@app/components/elements/hooks/useKeyTrigger';
import TokenSelector from '@app/components/panels/UserMgmt/MessageTemplates/TokenSelector/TokenSelector';
import { isDollar, isEnter, isESC } from '@app/helpers/keyboard';

import { TOKENS_MAP } from '../OptInMessages/constants';

const tokens = Object.keys(TOKENS_MAP);

interface MessageInputProps {
  label?: string;
  maxLength?: number;
  required?: boolean;
  value?: string;
  setValue: (value: string) => void;
  testId?: string;
  allowResize?: boolean;
}

const MessageInput: React.FC<MessageInputProps> = ({
  label,
  maxLength,
  required,
  value,
  setValue,
  testId,
  allowResize,
}) => {
  const templateInput = useRef(null);
  // handling the filter for the token popup
  const [filterToken, setFilterToken] = useState('');
  const [filteredTokens, setFilteredTokens] = useState(tokens);

  const [enterPressed, setEnterPressed] = useState(true);

  const filterTokens = () => {
    const newFilteredTokens = filterToken.length > 0
      ? filter(tokens, (item) => item.toLowerCase().includes(filterToken.toLowerCase()))
      : tokens;
    setFilteredTokens(newFilteredTokens);
  };
  useEffect(filterTokens, [filterToken]);

  const {
    startRecord,
    makeSelection,
    replaceSelection,
  } = useDynamicValue({
    input: templateInput,
  });
  const {
    handleKeyNav,
    currentIndex,
    resetIndex,
  } = useKeyNav({
    options: filteredTokens || tokens,
  });

  const {
    handleKeyTrigger,
    isTriggered,
    resetTrigger,
  } = useKeyTrigger({
    triggerActions: [
      {
        trigger: isDollar,
        replacementAction: () => startRecord(),
      },
      {
        trigger: isDollar,
      },
      {
        trigger: isEnter,
        replacementAction: () => {
          if (isTriggered) {
            const token = filteredTokens[currentIndex];
            const template = replaceSelection(token);
            setValue(template);
            setEnterPressed(false);
          } else {
            setEnterPressed(true);
          }
          resetTrigger();
        },
        preventDefault: !enterPressed,
      },
      {
        trigger: isESC,
        replacementAction: () => {
          resetTrigger();
        },
      },
    ],
  });

  // handling triggering keys in text input
  const pickItem = (index: number) => {
    if (isTriggered) {
      const token = filteredTokens[index];
      const template = replaceSelection(token);
      setValue(template);
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const template = e.target.value;
    setValue(template);
    if (isTriggered) {
      const newFilterToken = makeSelection();
      if (newFilterToken.includes(' ')) {
        resetTrigger();
      } else {
        setFilterToken(newFilterToken);
        resetIndex();
      }
    }
  };

  const handleTextAreaKeyDown = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isTriggered) handleKeyNav(e);
    handleKeyTrigger(e);
  };

  return (
    <>
      <TextAreaInput
        ref={templateInput}
        className="autoReplyInput"
        autoFocus
        label={label}
        maxLength={maxLength}
        value={value}
        onChange={handleTextAreaChange}
        onKeyDown={handleTextAreaKeyDown}
        required={required}
        showMaxLengthOnTopRight
        testId={testId}
        allowResize={allowResize}
      />
      {isTriggered && (
        <TokenSelector
          list={filteredTokens}
          selectedTokenIndex={currentIndex}
          pickItem={pickItem}
        />
      )}
    </>
  );
};

export default MessageInput;
