/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import path from 'path';
import { CHAT_SEND_FILE_CLEAR, UPDATE_MEDIA, UPDATE_VIRUS_SCAN_STATUS } from '../constants/actions';
import { doRequest } from './api';
import { GET_SIGNED_URL_FOR_MEDIA_UPLOAD } from '../constants/endpoints';

export const updateMediaItem = (mediaItem) => ({
  type: UPDATE_MEDIA,
  payload: mediaItem,
});

export function clearFileUploadStatus() {
  return { type: CHAT_SEND_FILE_CLEAR };
}

let uploadCanceler = null;

export const cancelUpload = () => (dispatch) => {
  if (uploadCanceler) {
    uploadCanceler();
    uploadCanceler = null;
  }
  dispatch(clearFileUploadStatus());
};

export const uploadMedia = async (
  file,
  validFileTypes = [],
  fileSizeLimitInMegaBytes = 25,
) => {
  const filename = file?.name || '';
  const ext = path.extname(filename);
  const cancelToken = (c) => { uploadCanceler = c; };

  // Check file type before attempting to create signed url
  if (!validFileTypes.includes(file?.type)) {
    throw new Error('Invalid file type');
  }

  // Get a signed url from the server
  let signedPOSTUrl;
  try {
    signedPOSTUrl = await doRequest({
      endpoint: `${GET_SIGNED_URL_FOR_MEDIA_UPLOAD(ext, file?.type, fileSizeLimitInMegaBytes)}`,
      method: 'GET',
    });
  } catch (e) {
    throw new Error(e);
  }

  // Format file and signature into a form to POST
  const s3url = signedPOSTUrl?.url;
  const fields = signedPOSTUrl?.fields || {};
  const form = new FormData();
  Object.entries(fields).forEach(([field, value]) => {
    form.append(field, value);
  });
  form.append('Content-Type', file?.type);
  form.append('file', file);

  // Upload file
  const res = await axios.post(s3url, form, {
    cancelToken: new axios.CancelToken(cancelToken),
    validateStatus: (status) => status < 500,
  });
  if (res?.status === 400) {
    const parser = new DOMParser();
    const xmlDOM = parser.parseFromString(res?.data, 'text/xml');
    throw new Error(xmlDOM?.getElementsByTagName('Code')?.[0]?.innerHTML);
  }
  return { key: fields.key, url: s3url };
};

export const updateVirusScanStatus = (fileUrl, virusScanStatus) => async (dispatch) => {
  dispatch({
    type: UPDATE_VIRUS_SCAN_STATUS,
    payload: { fileUrl, virusScanStatus },
  });
};
