/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
import { ROLE_ENDUSER, ROLE_OPERATOR } from '../../../../constants/general'

export const getUserRelationship = ({
  jobId,
  jobOperatorId,
  jobCustomerId,
  message,
}) => {
  if (message.isAI) {
    return null
  }

  const { author } = message
  if (!author) {
    return null
  }

  if (author.role === ROLE_ENDUSER) {
    const relationship = author && author.relationships ? author.relationships[jobId] : null
    if (relationship) { return relationship }

    // Primary endusers always have a relationship of 'Primary Contact'
    const primaryEndUser = (jobCustomerId && jobCustomerId._id) ? jobCustomerId._id : jobCustomerId
    if (author.role === ROLE_ENDUSER && author.id === primaryEndUser) {
      return 'Primary Contact'
    }
  }

  if (author.role === ROLE_OPERATOR) {
    const primaryOperator = jobOperatorId._id || jobOperatorId
    return author.id === primaryOperator ? 'Primary Operator' : 'Secondary Operator'
  }

  return null
}
