/* eslint-disable react/jsx-filename-extension */
import { Button, ButtonRow } from '@himarley/unity';
import React from 'react';

import Download from '..././../../../../images/icons/download.svg';

interface ChangeUniqueIdErrorsProps {
  onClose: () => void;
  download: () => void;
}

const ChangeUniqueIdErrors: React.FC<ChangeUniqueIdErrorsProps> = ({ download, onClose }: ChangeUniqueIdErrorsProps) => (
  <div className="importErrorsWrap">
    <p>
      There were some errors with changing the unique ids.
    </p>
    <ButtonRow>
      <Button onClick={onClose} type="outline">
        Cancel
      </Button>
      <Button
        className="downloadIcon"
        onClick={download}
        type="neutral"
        Icon={Download}
      >
        Download Results
      </Button>
    </ButtonRow>
  </div>
);

export default ChangeUniqueIdErrors;
