import {
  Button,
  Icon,
  Flex,
  Spacer,
  Textarea,
} from '@chakra-ui/react';
import {
  PaperAirplaneIcon,
} from '@himarley/unity';
import React, {
  useEffect, useState, useRef, useMemo, useCallback,
} from 'react';
import {
  connect, useDispatch, useSelector, ConnectedProps,
} from 'react-redux';

import { newTyping as newTypingAction, cancelScheduledMessage as cancelScheduledMessageAction } from '@app/actions/chat';
import {
  sendMessage as sendMessageAction,
  setCaseLanguage as setCaseLanguageAction,
} from '@app/actions/job';
import { cancelUpload as cancelUploadAction } from '@app/actions/media';
import { setTemplateTrayIsOpen as setTemplateTrayIsOpenAction } from '@app/actions/ui';
import { ENTER_CODE, ESC_CODE } from '@app/constants/keycodes';
import {
  encodeEmoji,
  getEmojis,
  formatMessageWithEmojis,
} from '@app/helpers/emoji';
import { languagesById } from '@app/helpers/languages';
import { saveChatBoxInputDraft, getChatBoxInputDraft } from '@app/helpers/localStorage';
import { templatesSlice } from '@app/slices/templates';
import { StateType } from '@app/types/reducer-state';

import ChatInputButtons from './chat-input-buttons';
import Emojis from './Emojis/emojis';
import FileUploadIndicator from './FileUploadIndicator/file-upload-indicator';
import LanguageMenu from './LanguageMenu/language-menu';
import ScheduleMessage from './scheduled-message';
import selector from './selector';
import Typing from './Typing/Typing';
import './ChatInput.less';
import useDynamicValue from '../../../elements/hooks/useDynamicValue';
import useKeyNav from '../../../elements/hooks/useKeyNav';
import useKeyTrigger from '../../../elements/hooks/useKeyTrigger';
import { usePrevious } from '../../../Hooks/usePrevious';

const emojis = getEmojis();
const EMOJI = 'EMOJI';
const TEMPLATE = 'TEMPLATE';
export const CHARACTER_INPUT_LIMIT = 1400;
export const MIN_HEIGHT = 38;
export const LINE_HEIGHT = 0;

export const mapDispatchToProps = {
  newTyping: newTypingAction,
  sendMessage: sendMessageAction,
  cancelUpload: cancelUploadAction,
  setCaseLanguage: setCaseLanguageAction,
  cancelScheduledMessage: cancelScheduledMessageAction,
  setTemplateTrayIsOpen: setTemplateTrayIsOpenAction,
};

const mapStateToProps = (state: StateType, ownProps: unknown) => selector(state, ownProps);

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ChatInputProps = PropsFromRedux & {
  loadedTemplateError: boolean;
  previewFile: string;
  handleFileSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  queueMessage: (message: unknown) => void;
  disabled: boolean;
};

interface AdditionalTriggerAction {
  replacementAction: () => void;
  trigger: (params: { keyCode: number; isShift?: boolean }) => boolean;
  preventDefault: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({
  loadedTemplateError = false,
  chatId,
  newTyping,
  sendMessage,
  jobId,
  previewFile,
  uploadFileUrl,
  verifiedUser,
  uploadFileIsInProgress,
  handleFileSelectChange,
  disabled,
  queueMessage = () => { },
  setCaseLanguage,
  inTranslationMode,
  languagePreference,
  typingUser = null,
  setTemplateTrayIsOpen,
  cancelUpload,
}) => {
  const { clearLoadedTemplate } = templatesSlice.actions;
  const textInput = useRef<HTMLTextAreaElement>(null);
  const textValueRef = useRef<string | null>(null);
  const [value, setValue] = useState('');
  const [height, setHeight] = useState(MIN_HEIGHT);
  const [activePopup, setActivePopup] = useState<string | null>(null);
  const [
    additionalTriggerActions,
    setAdditionalTriggerActions] = useState<AdditionalTriggerAction[]>([]);
  const [showScheduleMessageModal, setShowScheduleMessageModal] = useState(false);
  const dispatch = useDispatch();
  const {
    templateModalContext,
    loadedTemplateText,
    templateTrayIsOpen,
    authId,
  } = useSelector(
    ({
      ui,
      templates,
      auth,
    }) => ({
      templateModalContext: ui.context,
      loadedTemplateText: templates.loadedTemplateText,
      templateTrayIsOpen: ui.templateTrayIsOpen,
      authId: auth?.user?._id,
    }),
  );

  const { startRecord, replaceSelection } = useDynamicValue({
    input: textInput,
    options: { noToken: true },
  });

  const {
    handleKeyNav,
    currentIndex,
    resetIndex,
  } = useKeyNav({
    options: { length: emojis.length, dimensions: 2, perRow: 15 },
    disabled: activePopup !== EMOJI,
  });

  const handleSubmit = () => {
    const message = formatMessageWithEmojis(
      value.replace(/<br\s*\/?>/gm, '\n').trim(),
    );
    if (message) {
      if (verifiedUser) {
        sendMessage({ chatId, jobId, message });
        setValue('');
      }
    }
  };

  const triggerActions = [
    {
      replacementAction: () => {
        handleSubmit();
      },
      trigger: (
        { keyCode, isShift }: { keyCode: number; isShift: boolean },
      ) => keyCode === ENTER_CODE && isShift,
      preventDefault: true,
    },
    {
      replacementAction: () => setActivePopup(null),
      trigger: ({ keyCode }: { keyCode: number }) => keyCode === ESC_CODE,
    },
  ];

  const { handleKeyTrigger } = useKeyTrigger({
    triggerActions: [...triggerActions, ...additionalTriggerActions],
  });

  useEffect(() => {
    if (uploadFileUrl) setValue(uploadFileUrl);
  }, [uploadFileUrl]);

  useEffect(() => {
    if (!activePopup) resetIndex();
  }, [activePopup, resetIndex]);

  useEffect(() => {
    textValueRef.current = value;
  }, [value]);

  useEffect(() => () => {
    saveChatBoxInputDraft(authId, chatId, textValueRef.current);
  }, [authId, chatId]);

  const prevJobId = usePrevious(jobId);
  const clearOnJobSwitch = () => {
    const text = getChatBoxInputDraft(authId, chatId);
    setValue(text);
    if (prevJobId !== jobId) {
      setValue(text ?? '');
    }
    if (textInput?.current?.focus) {
      setTimeout(() => {
        textInput.current?.focus();
      }, 300);
    }
  };
  useEffect(clearOnJobSwitch, [authId, chatId, jobId, prevJobId]);

  const injectTemplate = () => {
    if (templateModalContext === 'CHAT_INPUT' && loadedTemplateText && !loadedTemplateError) {
      setValue(loadedTemplateText.substring(0, CHARACTER_INPUT_LIMIT));
      dispatch(clearLoadedTemplate());
    }
  };
  useEffect(
    injectTemplate,
    [loadedTemplateText, templateModalContext, loadedTemplateError, clearLoadedTemplate, dispatch],
  );

  const setInputBoxHeight = () => {
    const currentInput = textInput?.current;
    if (currentInput) {
      currentInput.style.height = 'auto';

      const newHeight = Math.min(currentInput.scrollHeight, 400);

      currentInput.style.height = `${newHeight}px`;
      setHeight(newHeight);
    }
  };

  useEffect(setInputBoxHeight, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const v = event?.target?.value || '';
    setValue(v);
    newTyping();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    handleKeyNav(e);
    handleKeyTrigger(e);
  };

  const selectEmoji = useCallback((i: number) => {
    const newValue = replaceSelection(encodeEmoji(emojis[i]));
    setActivePopup(null);
    setValue(newValue);
    textInput.current?.focus();
  }, [replaceSelection]);

  useEffect(() => {
    const replacementPopups = [EMOJI];
    if (replacementPopups.includes(activePopup || '')) {
      startRecord();
    }

    setAdditionalTriggerActions(
      activePopup === EMOJI
        ? [
          {
            replacementAction: () => selectEmoji(currentIndex),
            trigger: ({ keyCode }: { keyCode: number }) => keyCode === ENTER_CODE,
            preventDefault: true,
          },
        ]
        : [],
    );
  }, [activePopup, currentIndex]);

  const clearAndFocusTokenSelection = () => {
    textInput.current?.focus();
  };

  const togglePanel = (type: string) => {
    setActivePopup(type);
    if (type === TEMPLATE) {
      setTemplateTrayIsOpen(!templateTrayIsOpen, 'CHAT_INPUT');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      templateTrayIsOpen && setTemplateTrayIsOpen(false);
    }
    textInput.current?.focus();
  };

  const renderLanguageSelectDropDown = useMemo(() => {
    const selectLanguage = (selectedLanguagePreference: string) => {
      setCaseLanguage({ caseId: jobId, languagePreference: selectedLanguagePreference });
    };
    return (
      <LanguageMenu
        languagePreference={languagePreference}
        selectLanguage={selectLanguage}
      />
    );
  }, [jobId, languagePreference, setCaseLanguage]);

  return (
    <>
      <Emojis
        className="emoji-panel"
        showEmoji={activePopup === EMOJI}
        setShowEmoji={(o) => setActivePopup(o ? EMOJI : null)}
        emojis={emojis}
        selectEmoji={selectEmoji}
        currentIndex={currentIndex}
        style={{ bottom: height + 100 + (inTranslationMode ? 24 : 0) }}
      />
      <div className="chat-input-component">
        {inTranslationMode && (
          <div
            className="chat-input-translate-bar"
            data-testid={`chat-input-translate-bar-${jobId}`}
          >
            Translation On (
            {languagesById[languagePreference] || 'en'}
            )
          </div>
        )}
        {uploadFileIsInProgress && (
          <div className="chat-input-panel">
            <FileUploadIndicator
              previewFile={previewFile}
              onCancel={cancelUpload}
            />
          </div>
        )}
        {!uploadFileIsInProgress && (
          <Textarea
            className="chat-input-textarea"
            data-testid="chat-input-textarea"
            height={height}
            maxH="400px"
            resize="none"
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Write text message"
            ref={textInput}
            autoFocus
            isDisabled={disabled}
            maxLength={CHARACTER_INPUT_LIMIT}
            my={4}
            borderRadius={inTranslationMode ? '0 0 4px 4px' : 'md'}
          />
        )}
      </div>
      <Flex alignItems="center">
        <ChatInputButtons
          togglePanel={togglePanel}
          clearAndFocusTokenSelection={clearAndFocusTokenSelection}
          handleFileSelectChange={handleFileSelectChange}
          jobId={jobId}
          verifiedUser={!disabled}
          uploadFileIsInProgress={uploadFileIsInProgress}
          chatId={chatId}
          queueMessage={queueMessage}
          messageValue={value}
          setMessageValue={() => {
            setValue('');
            saveChatBoxInputDraft(authId, chatId, '');
          }}
          setShowScheduleMessageModal={setShowScheduleMessageModal}
        />
        {renderLanguageSelectDropDown}
        <Spacer />
        <Flex gap="4" alignItems="center">
          {CHARACTER_INPUT_LIMIT && (
            <div data-test="textarea-input-char-count" className={`textarea-input-char-count ${value ? 'textarea-input-char-count-active' : ''}`}>
              {(value || '').length}
              {' '}
              /
              {' '}
              {CHARACTER_INPUT_LIMIT}
            </div>
          )}
          <Button
            className="chat-input-message-send-button"
            data-jest="chat-input-send-button"
            onClick={handleSubmit}
            isDisabled={disabled || !value}
            rightIcon={<Icon as={PaperAirplaneIcon} />}
          >
            Send
          </Button>
          {showScheduleMessageModal && (
            <ScheduleMessage
              queueMessage={queueMessage}
              chatId={chatId}
              jobId={jobId}
              show={showScheduleMessageModal}
              toggleShow={setShowScheduleMessageModal}
              scheduledMessageText={value}
              setMessageValue={() => {
                setValue('');
                saveChatBoxInputDraft(authId, chatId, '');
              }}
            />
          )}
        </Flex>
      </Flex>
      <div className="chat-input-bottom-left-area">
        {typingUser ? (
          <Typing
            firstName={typingUser?.firstName ?? 'test'}
            lastName={typingUser?.lastName ?? 'test'}
          />
        ) : null}
      </div>
    </>
  );
};

export default connector(ChatInput);
