/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import _ from 'lodash';
import Dropdown from '../Dropdown/Dropdown';
import SearchBar from '../../../SearchBar/SearchBar';
import './SearchDropdown.less';

/**
 * Dropdown menu from a search bar
 * @param   {function} onSearchChange triggered on search change
 * @param   {string} placeholder placeholder for the search bar
 * @return  {Component}
 */

const DropItem = ({
  onSearchChange,
  placeholder,
  clearFilter,
  toggleFilterCleared,
  testId,
}) => (
  <div
    className="search-dropdown-link"
    data-test="search-dropdown-link"
  >
    <SearchBar
      placeholder={placeholder}
      handleFilterChange={onSearchChange}
      clearFilter={clearFilter}
      toggleFilterCleared={toggleFilterCleared}
      testId={testId}
    />
  </div>
);
const EMPTY_VALUE = '';
const SearchDropdown = ({
  children, placeholder, clearFilter, toggleFilterCleared, testId,
}) => {
  const [filter, setFilter] = useState(EMPTY_VALUE);

  const onSearchChange = (e) => {
    const searchText = _.get(e, 'target.value', '');
    setFilter(searchText);
  };

  const handleToggleFilterCleared = () => {
    setFilter(EMPTY_VALUE);
    toggleFilterCleared();
  };

  return (
    <Dropdown
      className="search-dropdown"
      openAction="focus"
      id={testId}
      displayDropDownIcon={false}
      DropItem={(
        <DropItem
          placeholder={placeholder}
          onSearchChange={onSearchChange}
          clearFilter={clearFilter}
          toggleFilterCleared={handleToggleFilterCleared}
          testId={testId}
        />
      )}
    >
      {React.Children.map(children, (child) => React.cloneElement(child, { searchText: filter }))}
    </Dropdown>
  );
};

export default SearchDropdown;
