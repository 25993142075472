import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import _ from 'lodash'

import SortAsc from '../../../icons/SortAsc'
import SortDesc from '../../../icons/SortDesc'
import { sortItems } from '../../../../actions/ui'

import './SortColumn.less'

const SortColumn = ({
  label, numericSort, className, sortItems, sortedColumn, order, location, id,
}) => {
  const AscIcon = numericSort ? <SortAsc numeric /> : <SortAsc />
  const DescIcon = numericSort ? <SortDesc numeric /> : <SortDesc />
  return (
    <div
      role="button"
      tabIndex="0"
      className={`${className} sort-column table-column`}
      onClick={() => sortItems(id, location, order === 'ASC' ? 'DESC' : 'ASC')}
      onKeyDown={() => sortItems(id, location, order === 'ASC' ? 'DESC' : 'ASC')}
    >
      {label}
      {sortedColumn === location && order === 'ASC' && AscIcon}
      {sortedColumn === location && order === 'DESC' && DescIcon}
    </div>
  )
}

SortColumn.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  order: PropTypes.string,
  location: PropTypes.string,
  sortItems: PropTypes.func,
  sortedColumn: PropTypes.string,
  numericSort: PropTypes.bool,
}
SortColumn.defaultProps = {
  className: '',
  id: '',
  order: 'DESC',
  location: '',
  sortItems: () => {},
  sortedColumn: '',
  numericSort: false,
}

const mapStateToProps = (state, ownProps) => {
  const tableId = ownProps.type ? ownProps.type.id() : ownProps.tableId
  return {
    id: tableId,
    sortedColumn: _.get(state, `ui.sort.${tableId}.column`),
    order: _.get(state, `ui.sort.${tableId}.order`),
  }
}

export default connect(mapStateToProps, { sortItems })(SortColumn)
