import React from 'react';
import { connect } from 'react-redux';

import { sendWelcomeMail } from '@app/actions/job';

// eslint-disable-next-line import/no-named-as-default
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import './SendEmailConfirmation.less';

interface SendEmailConfirmationProps {
  id: string;
  sendWelcomeMail: (arg0: string, arg1: string) => void;
}

const SendEmailConfirmation: React.FC<SendEmailConfirmationProps> = (
  { id, sendWelcomeMail: propsSendWelcomeMail },
) => {
  const sendButtonStates = {
    preRequest: {
      type: 'primary',
      text: 'Send',
    },
    requesting: {
      type: 'secondary',
      text: 'Sending...',
    },
  };

  const cancelButtonStates = {
    preRequest: {
      type: 'default',
      text: 'Cancel',
    },
    requesting: {
      type: 'default',
      text: 'Cancel',
    },
    success: {
      type: 'primary',
      text: 'Ok',
    },
    error: {
      type: 'default',
      text: 'Ok',
    },
  };

  return (
    <ConfirmationModal
      className="send-email-confirmation"
      id="resendwelcome"
      requestId="sendWelcomeEmail"
      title="Do you want to resend the opt-in text?"
      successText="Opt-in text resent!"
      open={(userId: string) => {
        const typeId = id === 'case' ? 'job' : id;
        propsSendWelcomeMail(userId, typeId);
      }}
      close={() => {}}
      openButtonStates={sendButtonStates}
      cancelButtonStates={cancelButtonStates}
    >
      <div style={{ width: 300, margin: '0 auto' }}>
        {'This will send a text to the phone number associated with the user\'s account.'}
      </div>
    </ConfirmationModal>
  );
};

export { SendEmailConfirmation };
export default connect(null, { sendWelcomeMail })(SendEmailConfirmation);
