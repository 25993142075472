/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../../../../Form/Button/Button';
import styles from './TotalLossPicker.module.less';
import {
  CONFIRMED_TOTAL_LOSS_STATUS, DENIED_TOTAL_LOSS_STATUS,
} from '../../../../../constants/jobState';
import InfoIcon from '../../../../../../images/icons/info.svg';
import MarleyAssistantMessage from '../MarleyAssistantMessage/MarleyAssistantMessage';

const TotalLossPicker = ({ onDismiss, activeJobId, patchJob }) => {
  const [totalLossStatus, setTotalLossStatus] = useState()

  const updateTotalLossStatus = () => {
    patchJob(activeJobId, { totalLossStatus })
  };

  const tooltip = (
    <Tooltip id="how-do-we-know-this-tooltip">
      <div>Our prediction model identified:</div>
      <div>Towed Away</div>
      <div>Airbags</div>
      <div>Auto Collision</div>
      <div>Flat Tires</div>
      <div>Ditch</div>
      <div>T-boned</div>
    </Tooltip>
  );

  return (
    <MarleyAssistantMessage>
      <div className={styles.box}>
        <div>
          <div className={styles.title}>Predicted Total Loss</div>
          <div className={styles.body}>
            Based on the information so far this claim might be a total loss.
          </div>
        </div>
      </div>
      <div className={styles['radio-buttons']}>
        <label>
          <input
            type="radio"
            name="isTotalLossSelection"
            value="totalLoss"
            className={styles['total-loss-button']}
            onClick={() => setTotalLossStatus(CONFIRMED_TOTAL_LOSS_STATUS)}
          />
          Total Loss
        </label>
        <label>
          <input
            type="radio"
            name="isTotalLossSelection"
            value="notTotalLoss"
            className={styles['not-total-loss-button']}
            onClick={() => setTotalLossStatus(DENIED_TOTAL_LOSS_STATUS)}
          />
          Not a Total Loss
        </label>
      </div>
      <div className={styles['buttons-and-tooltip-row']}>
        <div>
          <Button
            className={styles['left-message-button']}
            type="outline"
            onClick={onDismiss}
          >
            Hide
          </Button>
          <Button
            type="black"
            onClick={updateTotalLossStatus}
          >
            Update Claim
          </Button>
        </div>
        <div className={styles.tooltip}>
          <p>How do we predict this?</p>
        </div>
        <OverlayTrigger placement="top" overlay={tooltip}>
          <InfoIcon className={styles['info-icon']} data-testid="prediction-tooltip" />
        </OverlayTrigger>
      </div>
    </MarleyAssistantMessage>
  );
};

TotalLossPicker.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  patchJob: PropTypes.func.isRequired,
  activeJobId: PropTypes.string.isRequired,
};

export default TotalLossPicker;
