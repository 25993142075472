import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { StateType } from '@app/types/reducer-state';

import styles from './InboxFooter.module.less';
import CancelSearchButton from '../CancelSearchButton/CancelSearchButton';

interface InboxFooterProps {
  index: number;
  setSize: (index: number, size?: number) => void;
  windowWidth?: number | string;
  style: React.CSSProperties;
}

const InboxFooter: React.FC<InboxFooterProps> = ({
  index,
  setSize,
  windowWidth,
  style,
}) => {
  const {
    search,
    expandInboxSearchBar,
    searchResultsCount,
  } = useSelector(
    (state: StateType) => ({
      search: state.jobs?.search,
      expandInboxSearchBar: state?.jobs?.expandInboxSearchBar,
      searchResultsCount: state.jobs?.searchResultsCount,
    }),
  );

  const rowRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (rowRef.current?.firstChild) {
      const firstChild = rowRef.current.firstChild as HTMLElement;
      const { height } = firstChild.getBoundingClientRect();
      setSize(index, height + 1);
    }
  }, [setSize, index, windowWidth]);

  const endMessage = useMemo(() => {
    if (expandInboxSearchBar && !search) {
      return 'Try searching by Claim Number, Phone Number, Name, etc.';
    }
    if (search) {
      return searchResultsCount === 0 ? `There are no results for ${search}` : 'End of Search Results';
    }
    return 'There are no more chats';
  }, [expandInboxSearchBar, search, searchResultsCount]);

  return (
    <section
      key={index}
      className={`${styles.chatScrollableContainer} ${styles.inboxFooter}`}
      data-testid="inbox-end-of-list"
      style={style}
      ref={rowRef}
    >
      <div className={styles.h3}>{endMessage}</div>
      {search ? (
        <CancelSearchButton testId="chat-inbox-cancel-search-button-end-of-list" />
      ) : null}
    </section>
  );
};

export default InboxFooter;
