/* eslint-disable @typescript-eslint/no-unused-expressions */
import { MultiSelectDropdownMenu, Avatar, Tooltip } from '@himarley/unity';
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { lazyLoad } from '@app/actions/common';
import { persistUserProperties } from '@app/actions/profile';
import { REFINE_BY } from '@app/constants/inboxFilters';
import { FOCUSED_OPERATOR } from '@app/constants/permissions';
import { useCheckPermissions } from '@app/helpers/common';
import { sortByAlphabetical } from '@app/helpers/sorting';
import { operatorType, groupType } from '@app/models/marleyTypes';
import type { InboxFilters, StateType } from '@app/types/reducer-state';

import {
  QUICK_FILTER_MENU_IDS,
  QUICK_FILTER_MENU_ITEMS,
  MY_CHATS_INDEX,
  GROUPS_INDEX,
  USERS_INDEX,
  LOAD_LIMIT,
  DEFAULT_SELECTED,
  QUICK_FILTER_MENU_LABELS,
  UNASSIGNED_INDEX,
} from './constants';
import { populateMissingNameLabels } from './helpers';
import styles from './quick-filter.module.less';
import type { QuickFilterMenuItem } from './types';

interface QuickFilterProps {
  container?: React.ReactNode;
  isResettingAssignees?: boolean;
  setIsResettingAssignees?: React.Dispatch<React.SetStateAction<boolean>>
  dropDownContentClass?: string;
  triggerClassName?: string;
  onCloseFilter?: (filters: Record<string, unknown>) => void;
  setIsCancelling?: React.Dispatch<React.SetStateAction<boolean>>;
  isCancelling?: boolean;
  testId?: string;
  disabled?: boolean;
}

const QuickFilter: React.FC<QuickFilterProps> = ({
  container,
  isResettingAssignees,
  setIsResettingAssignees = () => { },
  dropDownContentClass,
  triggerClassName,
  onCloseFilter,
  isCancelling,
  setIsCancelling = () => { },
  testId,
  disabled,
}) => {
  const [selectedItems, setSelectedItems] = useState(new Map());
  const [menuItems, setMenuItems] = useState(QUICK_FILTER_MENU_ITEMS);
  const dispatch = useDispatch();
  const {
    auth,
    users,
    groups,
    inboxFilters,
    groupMap,
  } = useSelector(
    (state: StateType) => ({
      auth: state?.auth,
      users:
        state?.operators?.list?.sort((a, b) => sortByAlphabetical(a?.name, b?.name)) || [],
      groups:
        state?.groups?.list?.sort((a, b) => sortByAlphabetical(a?.name, b?.name)) || [],
      inboxFilters: state?.profile?.properties?.inboxFilters || {},
      groupMap: state?.groups?.listById || {},
    }),
  );
  const [selectedUsers, setSelectedUsers] = useState(new Map());
  const [selectedGroups, setSelectedGroups] = useState(new Map());

  const hasFocusedOperatorPermission = useCheckPermissions([FOCUSED_OPERATOR]);

  interface ParamsType {
    offset?: number;
    limit?: number;
    searchText?: string;
  }

  const loadUsers = useCallback(
    (params?: ParamsType) => {
      dispatch(
        lazyLoad(
          operatorType,
          { ...params, offset: 0, limit: LOAD_LIMIT },
          'users',
        ),
      );
    },
    [dispatch],
  );

  const loadGroups = useCallback(
    (params?: ParamsType) => {
      dispatch(
        lazyLoad(
          groupType,
          { ...params, offset: 0, limit: LOAD_LIMIT },
          'groups',
        ),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(loadUsers());
    dispatch(loadGroups());
  }, [dispatch, loadGroups, loadUsers]);

  const handleSearchUsers = useCallback(
    (searchText: string) => {
      const userQuery: ParamsType = {};

      userQuery.searchText = searchText || '';

      loadUsers(userQuery);
    },
    [loadUsers],
  );

  const handleSearchGroups = useCallback(
    (searchText: string) => {
      const userQuery: ParamsType = {};

      userQuery.searchText = searchText || '';

      loadGroups(userQuery);
    },
    [loadGroups],
  );

  const handleSelectGroups = useCallback(
    (group: {
      id: string;
      label: string
    }) => {
      const selectedItemsMap = new Map(selectedItems);
      selectedItemsMap.delete(QUICK_FILTER_MENU_IDS.allChats); // deselect All Chats option
      setSelectedItems(selectedItemsMap);

      const selectedGroupsNew = new Map(selectedGroups);
      selectedGroupsNew.has(group?.id)
        ? selectedGroupsNew.delete(group?.id)
        : selectedGroupsNew.set(group?.id, group?.label);

      if (
        selectedGroupsNew.size === 0
        && selectedUsers.size === 0
        && selectedItems.size === 0
      ) {
        const newSelectedItems = new Map();
        newSelectedItems.set(group?.id, group?.label);
        setSelectedItems(newSelectedItems); // cannot have every option deselected
      }
      setSelectedGroups(new Map(selectedGroupsNew));
    },
    [selectedGroups, selectedItems, selectedUsers.size],
  );

  const handleClearSelectedGroups = useCallback(() => {
    if (selectedItems.size === 0 && selectedUsers.size === 0) {
      setSelectedItems(DEFAULT_SELECTED);
    }
    setSelectedGroups(new Map());
  }, [selectedItems.size, selectedUsers.size]);

  const handleSelectUsers = useCallback(
    (user: {
      id: string;
      label: string;
    }) => {
      const selectedItemsMap = new Map(selectedItems);
      selectedItemsMap.delete(QUICK_FILTER_MENU_IDS.allChats); // deselect All Chats option
      setSelectedItems(selectedItemsMap);

      const selectedUsersNew = new Map(selectedUsers);
      selectedUsersNew.has(user?.id)
        ? selectedUsersNew.delete(user?.id)
        : selectedUsersNew.set(user?.id, user?.label);

      if (
        selectedUsersNew.size === 0
        && selectedGroups.size === 0
        && selectedItems.size === 0
      ) {
        selectedUsersNew.set(user?.id, user?.label); // cannot have every option deselected
      }
      setSelectedUsers(new Map(selectedUsersNew));
    },
    [selectedGroups.size, selectedItems, selectedUsers],
  );

  const handleClearSelectedUsers = useCallback(() => {
    if (selectedItems.size === 0 && selectedGroups.size === 0) {
      setSelectedItems(DEFAULT_SELECTED);
    }
    setSelectedUsers(new Map());
  }, [selectedGroups.size, selectedItems.size]);

  const handleSelectMyCases = useCallback(
    (selection: {
      id: string;
      label: string;
    }) => {
      const newSelectedItemsMap = new Map(selectedItems);
      newSelectedItemsMap.has(selection?.id)
        ? newSelectedItemsMap.delete(selection?.id)
        : newSelectedItemsMap.set(selection?.id, selection?.label);
      if (
        newSelectedItemsMap.size === 0
        && selectedGroups.size === 0
        && selectedUsers.size === 0
      ) {
        return;
      }
      setSelectedItems(new Map(newSelectedItemsMap));
    },
    [selectedGroups.size, selectedItems, selectedUsers.size],
  );

  const isMentionsSelected = useMemo(
    () => (disabled === null
      ? inboxFilters?.refineBy === REFINE_BY.MENTIONS
      : disabled),
    [inboxFilters?.refineBy, disabled],
  );

  useEffect(() => {
    const timestamp = Date.now();
    let parsedUsers = users?.filter((user) => user?.id !== auth?.user?._id);
    parsedUsers = parsedUsers?.map((user) => ({
      label: user?.name,
      avatar: (
        <Avatar
          circularDisplay
          className={styles.avatar}
          imageUrl={
            user?.imageUrl
              ? `/api/user/${user?.id}/avatarImage?timestamp=${timestamp}`
              : ''
          }
          name={user?.name}
        />
      ),
      id: user?.id || user?._id,
    }));
    const parsedGroups = groups?.map((group) => ({
      avatar: (
        <Avatar circularDisplay className={styles.avatar} name={group?.name} />
      ),
      label: group?.name,
      id: group?._id || group?.id,
    }));
    QUICK_FILTER_MENU_ITEMS[GROUPS_INDEX].onSelectSubItem = handleSelectGroups;
    QUICK_FILTER_MENU_ITEMS[GROUPS_INDEX].onSearch = handleSearchGroups;
    QUICK_FILTER_MENU_ITEMS[GROUPS_INDEX].clearSelectedSubItems = handleClearSelectedGroups;

    QUICK_FILTER_MENU_ITEMS[USERS_INDEX].onSelectSubItem = handleSelectUsers;
    QUICK_FILTER_MENU_ITEMS[USERS_INDEX].onSearch = handleSearchUsers;
    QUICK_FILTER_MENU_ITEMS[USERS_INDEX].clearSelectedSubItems = handleClearSelectedUsers;

    const loggedInAvatar = (
      <Avatar
        circularDisplay
        className={styles.avatar}
        imageUrl={
          auth?.user?.imageUrl
            ? `/api/user/${auth.user._id}/avatarImage?timestamp=${timestamp}`
            : ''
        }
        name={`${auth?.user?.firstName} ${auth?.user?.lastName}`}
      />
    );

    QUICK_FILTER_MENU_ITEMS[MY_CHATS_INDEX].avatar = loggedInAvatar;
    QUICK_FILTER_MENU_ITEMS[MY_CHATS_INDEX].subItems?.forEach(
      (subItem, index) => {
        subItem.avatar = loggedInAvatar;
        if (index === 0) {
          subItem.label = `${auth?.user?.firstName} ${auth?.user?.lastName}`;
        }
      },
    );

    QUICK_FILTER_MENU_ITEMS[MY_CHATS_INDEX].onSelectSubItem = handleSelectMyCases;

    const newItems = QUICK_FILTER_MENU_ITEMS?.map((item, index) => {
      if (index === USERS_INDEX) {
        return {
          ...item,
          display: !hasFocusedOperatorPermission,
          selectedSubItemsMap: selectedUsers,
          subItems: parsedUsers,
        };
      }
      if (index === GROUPS_INDEX) {
        return {
          ...item,
          display: !hasFocusedOperatorPermission,
          selectedSubItemsMap: selectedGroups,
          subItems: parsedGroups,
        };
      }
      if (index === MY_CHATS_INDEX) {
        return {
          ...item,
          selectedSubItemsMap: selectedItems,
        };
      }
      if (index === UNASSIGNED_INDEX) {
        return {
          ...item,
          display: !hasFocusedOperatorPermission,
        };
      }

      return item;
    });
    setMenuItems(newItems as QuickFilterMenuItem[]);
  }, [
    hasFocusedOperatorPermission,
    auth,
    groups,
    handleClearSelectedGroups,
    handleClearSelectedUsers,
    handleSearchGroups,
    handleSearchUsers,
    handleSelectGroups,
    handleSelectUsers,
    selectedGroups,
    selectedUsers,
    users,
    handleSelectMyCases,
    selectedItems,
  ]);

  const handleSelectItem = useCallback(
    (item?: { id: string, label: string; }) => {
      const selectedItemsMap = new Map(selectedItems);
      if (item?.id === QUICK_FILTER_MENU_IDS.allChats) {
        // selecting All Chats clears everything else
        menuItems[GROUPS_INDEX].clearSelectedSubItems();
        menuItems[USERS_INDEX].clearSelectedSubItems();
        const allChatsMap = new Map();
        allChatsMap.set(item?.id, item?.label);
        setSelectedItems(allChatsMap);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        selectedItemsMap.delete('allChats'); // deselect All Chats option
        selectedItemsMap.has(item?.id)
          ? selectedItemsMap.delete(item?.id)
          : selectedItemsMap.set(item?.id, item?.label);

        if (
          selectedItemsMap?.size === 0
          && menuItems[GROUPS_INDEX]?.selectedSubItemsMap?.size === 0
          && menuItems[USERS_INDEX]?.selectedSubItemsMap?.size === 0
        ) {
          selectedItemsMap.set(item?.id, item?.label); // cannot have every option deselected
        }
        setSelectedItems(selectedItemsMap);
      }
    },
    [selectedItems, menuItems],
  );

  /**
   * Helps handle pulling operator and groups from query parameters,
   * given there is no context of those operators/groups names
   */
  const selectedItemsLabel = useMemo(() => {
    // Look up group names are missing from the selected map,
    // check the operators in Redux for matches
    const fetchedSelectedGroupIds = populateMissingNameLabels({
      selected: selectedGroups,
      entityMap: groupMap,
    });

    // handle My Cases select label
    let itemsLabel = [];
    if (
      selectedItems.has(QUICK_FILTER_MENU_IDS.myChats)
      && selectedItems.has(QUICK_FILTER_MENU_IDS.secondaryOperatorIds)
    ) {
      itemsLabel.push('All My Cases');
      selectedItems.get(QUICK_FILTER_MENU_IDS.unassigned)
        && itemsLabel.push(selectedItems.get(QUICK_FILTER_MENU_IDS.unassigned));
    } else {
      const selectedItemsCopy = new Map(selectedItems);
      if (selectedItems.has(QUICK_FILTER_MENU_IDS.myChats)) {
        // append assigned to me text when only primary operator is selected
        selectedItemsCopy.set(
          QUICK_FILTER_MENU_IDS.myChats,
          `${`${auth?.user?.firstName} ${auth?.user?.lastName?.[0]}.`} (Assigned to Me)`,
        );
      }
      itemsLabel = [...selectedItemsCopy.values()];
    }

    return `${[
      ...new Set([
        ...itemsLabel,
        ...[...selectedUsers.values(), ...fetchedSelectedGroupIds.values()],
      ]),
    ].join(', ')}`;
  }, [
    auth?.user?.firstName,
    auth?.user?.lastName,
    groupMap,
    selectedGroups,
    selectedItems,
    selectedUsers,
  ]);

  const handleClose = useCallback(() => {
    const allSelectedUsers = Object.fromEntries(selectedUsers);
    const allSelections = {
      operatorIds: allSelectedUsers,
      groups: Object.fromEntries(selectedGroups),
      items: Object.fromEntries(selectedItems),
    };
    if (typeof onCloseFilter === 'function') {
      onCloseFilter(allSelections);
    } else {
      // persist in redux - make api call
      dispatch(
        persistUserProperties({
          hasUsedNewInboxFilters: true,
          inboxFilters: {
            ...inboxFilters,
            ...allSelections,
          },
        }),
      );
    }
  }, [
    selectedUsers,
    selectedItems,
    selectedGroups,
    onCloseFilter,
    dispatch,
    inboxFilters,
  ]);

  // handle resetting to default values
  useEffect(() => {
    if (isResettingAssignees) {
      setSelectedItems(new Map(DEFAULT_SELECTED));
      setSelectedGroups(new Map());
      setSelectedUsers(new Map());
      setIsResettingAssignees(false);
      onCloseFilter && onCloseFilter({
        groups: {},
        operatorIds: {},
        items: {
          [QUICK_FILTER_MENU_IDS.myChats]:
            QUICK_FILTER_MENU_ITEMS[MY_CHATS_INDEX].label,
          [QUICK_FILTER_MENU_IDS.secondaryOperatorIds]:
            QUICK_FILTER_MENU_LABELS.secondaryOpsLabel,
        },
      });
    }
  }, [isResettingAssignees, onCloseFilter, setIsResettingAssignees]);

  const prePopulateSelections = useCallback((filters: InboxFilters) => {
    if (filters?.items) {
      const prePopulatedItems = new Map();
      for (const [id, label] of Object.entries(filters.items)) {
        prePopulatedItems.set(id, label);
      }
      setSelectedItems(prePopulatedItems);
    }
    if (filters?.operatorIds) {
      const prePopulatedUsers = new Map();
      for (const [id, label] of Object.entries(filters.operatorIds)) {
        prePopulatedUsers.set(id, label);
      }
      setSelectedUsers(prePopulatedUsers);
    }
    if (filters?.groups) {
      const prePopulatedGroups = new Map();
      for (const [id, label] of Object.entries(filters.groups)) {
        prePopulatedGroups.set(id, label);
      }
      setSelectedGroups(prePopulatedGroups);
    }
  }, []);

  // handle cancelling - revert to values in redux state
  useEffect(() => {
    if (isCancelling) {
      prePopulateSelections(inboxFilters);
      setIsCancelling(false);
    }
  }, [inboxFilters, isCancelling, prePopulateSelections, setIsCancelling]);

  useEffect(() => {
    // pre-populate selections with redux state data
    prePopulateSelections(inboxFilters);
  }, [inboxFilters, prePopulateSelections]);

  return isMentionsSelected ? (
    <Tooltip
      side="right"
      className={styles.mentionsToolTip}
      tooltipContentClassName={styles.mentionsToolTipContent}
      trigger={(
        <MultiSelectDropdownMenu
          menuItems={Array.isArray(menuItems) ? menuItems.filter((item) => item.display) : []}
          selectedItemLabel={selectedItemsLabel}
          selectedItemsMap={selectedItems}
          onSelectItem={handleSelectItem}
          container={container}
          triggerClassName={triggerClassName || styles.trigger}
          contentClassName={dropDownContentClass || styles.dropContent}
          onClose={handleClose}
          triggerDataTestId={testId || 'assigneeTrigger'}
          disabled
        />
      )}
      tooltip={(
        <section>
          <h1>Only My Mentions/Replies</h1>
          This message type will only show you @ mentions on cases. You will
          need to change your message type before you can apply other filters
          again.
        </section>
      )}
    />
  ) : (
    <MultiSelectDropdownMenu
      menuItems={Array.isArray(menuItems) ? menuItems.filter((item) => item.display) : []}
      selectedItemLabel={selectedItemsLabel}
      selectedItemsMap={selectedItems}
      onSelectItem={handleSelectItem}
      container={container}
      triggerClassName={triggerClassName || styles.trigger}
      contentClassName={dropDownContentClass || styles.dropContent}
      onClose={handleClose}
      triggerDataTestId={testId || 'assigneeTrigger'}
    />
  );
};

export default QuickFilter;
