/* eslint-disable react/destructuring-assignment */
import React from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import DatePicker from 'react-bootstrap-date-picker';
import PropTypes from 'prop-types';

import ConfigurableOptInWidget from '@app/components/Form/ConfigurableOptInWidget/ConfigurableOptInWidget';

import {
  addFormError,
  clearAddFormErrors,
  clearEditObject,
  createCaseWithUser,
  handleFormInputChange,
  updateItem,
} from '@app/actions/common';
import { closeForm } from '@app/actions/ui';
import { jobPutRequest } from '@app/actions/job';

// eslint-disable-next-line import/no-named-as-default
import BrandedCopy from '@app/components/Form/BrandedCopy/BrandedCopy';
import Button from '@app/components/Form/Button/Button';
import CaseVisibilityDropdown from '@app/components/Form/CaseVisibilityDropdown/CaseVisibilityDropdown';
import FormInputField from '@app/components/FormInput/FormInputField';
import LinesOfBusinessList from '@app/components/Form/LinesOfBusinessList/LinesOfBusinessList';
import MultiStateButton from '@app/components/Form/MultiStateButton/MultiStateButton';
import PermissionVerifier from '@app/components/PermissionVerifier/PermissionVerifier';
// eslint-disable-next-line import/no-named-as-default
import PrimaryContactInput from '@app/components/PrimaryContactInput/PrimaryContactInput';
import Required from '@app/components/icons/Required';
// eslint-disable-next-line import/no-named-as-default
import UserForm from '@app/components/Chats/RightBar/AddUser/AddUserForm/UserForm';

import {
  CASE_VISIBILITY,
  LINES_OF_BUSINESS_ENABLE,
} from '@app/constants/permissions';

import { ClaimsFormValidation } from '@app/helpers/formValidation/claimsForm/claimsFormValidation';
import { formatBrandLobs } from '@app/helpers/format';
import { UserFormValidation } from '@app/helpers/formValidation/userForm/userFormValidation';

import BriefcaseIcon from '@images/icons/briefcase.svg';
import PersonalIcon from '@images/icons/policy@2x.svg';

import { caseType, userType } from '@app/models/marleyTypes';

import './ClaimsForm.less';
import CaseMatchAlert from '../elements/CaseMatchAlert/case-match-alert';

class ClaimsForm extends React.Component {
  constructor(props) {
    super(props);

    this.mapNameToMetaDataId = {
      companyName: '19de7971-8555-4cfb-91ed-f45011ec04d1',
      claimNumber: 'f11560d8-0c09-11ea-8d71-362b9e155667',
      policyNumber: 'e969484e-0c0a-11ea-8d71-362b9e155667',
      deductible: '4c7a89a2-8ea1-4984-b5d4-43a05c1a4983',
      status: '6f96f665-9c13-45a9-95a0-11e81088f85e',
      dateOfLoss: '91135525-ec31-45b8-84cf-3529a594f780',
    };

    this.IdToMetadataLocation = (key) => `metaData.${this.mapNameToMetaDataId[key]}`;

    this.state = {
      userClaims: [],
      privacy: 'public',
      submitIsDisabled: false,
      caseLobData: {},

      companyName: props.isEdit
        ? _.get(props, `editObject.${this.IdToMetadataLocation('companyName')}`)
        : '',
      claimNumber: props.isEdit
        ? _.get(props, `editObject.${this.IdToMetadataLocation('claimNumber')}`)
        : '',
      claimNumberIsValid: true,
      claimNumberErrorMessage: 'You must enter a claim number',
      policyNumber: props.isEdit
        ? _.get(
          props,
          `editObject.${this.IdToMetadataLocation('policyNumber')}`,
        )
        : '',
      deductible: props.isEdit
        ? _.get(props, `editObject.${this.IdToMetadataLocation('deductible')}`)
        : '',
      deductibleIsValid: true,
      deductibleErrorMessage:
        'Please only submit numeric values for deductible',
      status: props.isEdit
        ? _.get(props, `editObject.${this.IdToMetadataLocation('status')}`)
        : '',
      dateOfLoss: props.isEdit
        ? _.get(props, `editObject.${this.IdToMetadataLocation('dateOfLoss')}`)
        : '',
      dateOfLossIsValid: true,
      dateOfLossErrorMessage: 'You must select a date',
      caseTypeId: props.isEdit
        ? _.get(props, 'editObject.caseTypeId')
        : '75dd685b-b04b-4113-83e1-8c23f1149c11',
      existingCases: [],
      showClaimMatchAlert: false,
    };

    this.props.handleFormInputChange({
      inputId: 'caseTypeId',
      tableId: caseType.id(),
      value: this.state.caseTypeId,
    });

    this.props.handleFormInputChange({
      inputId: 'metadataReferenceId',
      tableId: caseType.id(),
      value: 'f11560d8-0c09-11ea-8d71-362b9e155667',
    });
  }

  componentWillUnmount() {
    this.props.clearAddFormErrors(caseType.reducerCase());
  }

  // used when claim is being created outside of case view
  toggleLocalSubmitIsDisabled = (value) => this.setState({ submitIsDisabled: value });

  handleChange = (e) => {
    const name = e.target.name || e.target.dataset.name;
    const { value } = e.target;
    if (this.state.submitIsDisabled) {
      this.toggleLocalSubmitIsDisabled(false);
    }
    if (this.props.submitIsDisabled) {
      this.props.toggleDisableFormSubmit(false);
    }
    this.props.handleFormInputChange({
      inputId: this.IdToMetadataLocation(name),
      tableId: caseType.id(),
      value,
    });
    this.setState({ [name]: value });
  };

  handleBlur = (e) => {
    const { name, value } = e.target;
    const { editObject, userCases, view } = this.props;

    const validation = ClaimsFormValidation.claimsFormValidation({
      fieldName: name,
      claimFormData: editObject,
      map: this.IdToMetadataLocation,
    });
    this.setState({ [`${name}IsValid`]: validation.isValid });

    if (name === 'claimNumber' && !value) {
      this.setState({ claimNumberIsValid: false });
    }
    if (name === 'claimNumber' && value) {
      this.setState((state) => {
        const claims = view === 'case' ? userCases : state.userClaims;
        const existingCases = claims.filter((c) => c.referenceId === value && c.type === 'CLAIM');
        return {
          existingCases,
          showClaimSummaryAlert: existingCases.length > 0,
          showClaimMatchAlert: false,
        };
      });
    }
  };

  handleCaseVisibilityChange = (id) => {
    this.setState({ privacy: id });
  };

  handleLobChange = (item) => {
    this.setState({ caseLobData: item[0] });
  };

  toggleClaimType = (value) => {
    const caseTypeId = value === 'personal'
      ? '75dd685b-b04b-4113-83e1-8c23f1149c11'
      : '36ad685a-bc4b-5213-c3e1-8c28e4549c13';
    this.props.handleFormInputChange({
      inputId: 'caseTypeId',
      tableId: caseType.id(),
      value: caseTypeId,
    });
    this.setState({ caseTypeId });
  };

  getSaveButtonText = () => {
    const isEdit = _.get(this.props, 'isEdit', false);
    const userEditObjectHasId = _.get(this.props, 'userEditObject.id', null);
    if (isEdit) {
      return 'Update Claim';
    }
    if (userEditObjectHasId) {
      return this.props.createButtonText
        ? this.props.createButtonText
        : 'Create Claim';
    }
    return 'Create Claim';
  };

  getFormErrorText = () => {
    const errorLocations = [
      'jobs.errorMessage',
      'users.error',
      'claims.error',
      'requests.error',
    ];

    let returnError = '';
    errorLocations.forEach((el) => {
      const error = _.get(this.props, el);
      if (error && !returnError) {
        returnError = error;
      } else {
        return returnError;
      }
    });

    return returnError;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let canSubmit = true;

    const {
      isEdit, requests, branding, editObject, userEditObject,
    } = this.props;

    const claimData = {
      metaData: editObject.metaData || [],
      ...editObject,
    };
    delete claimData.primaryContactId;

    // do validation for Point of Contact
    if (!editObject.operatorId) {
      this.props.addFormError(caseType.reducerCase(), 'primaryContactId');
      canSubmit = false;
    }

    // remove companyName from metaData if caseTypeId is personal
    if (editObject.caseTypeId === '75dd685b-b04b-4113-83e1-8c23f1149c11') {
      delete claimData.metaData['19de7971-8555-4cfb-91ed-f45011ec04d1'];
    }
    // branding validation
    if (
      !claimData.metaData['c54f88b2-fee1-4216-8c6e-7a7aa5e83a3e']
      && branding.length
    ) {
      this.props.addFormError(caseType.reducerCase(), 'branding');
      canSubmit = false;
      if (this.props.toggleDisableFormSubmit) {
        this.props.toggleDisableFormSubmit(true);
      } else {
        this.toggleLocalSubmitIsDisabled(true);
      }
    }

    // do regex validation and formatting for deductible.
    const deductible = _.get(
      editObject,
      this.IdToMetadataLocation('deductible'),
    );
    if (
      (deductible && ClaimsFormValidation.validateDeductible(deductible))
      || !deductible
    ) {
      _.set(
        claimData,
        this.IdToMetadataLocation('deductible'),
        ClaimsFormValidation.formatDeductible(deductible),
      );
    } else {
      this.setState({ deductibleIsValid: false });
      delete claimData.deductible;
      canSubmit = false;
    }

    // do validation for claimNumber
    const claimNumber = _.get(
      editObject,
      this.IdToMetadataLocation('claimNumber'),
    );
    if (
      (claimNumber && !ClaimsFormValidation.validateClaimNumber(claimNumber))
      || !claimNumber
    ) {
      this.setState({ claimNumberIsValid: false });
      canSubmit = false;
    }

    // do validation for dateOfLoss
    const dateOfLoss = _.get(
      editObject,
      this.IdToMetadataLocation('dateOfLoss'),
    );
    if (!dateOfLoss) {
      this.setState({ dateOfLossIsValid: false });
      canSubmit = false;
    }

    claimData.privacy = this.state.privacy;
    claimData.lineOfBusiness = this.state.caseLobData;
    const preppedClaimData = {
      ...claimData,
    };
    // USER VALIDATION -------------------------------------------------------------------------------------
    // test the functionality of the form when this variable, and the below loop are removed, this may be dead
    const userFormFields = ['phoneNumber', 'firstName', 'lastName', 'email'];
    userFormFields.forEach((field) => {
      const invalidity = UserFormValidation.userFormValidation({
        fieldName: field,
        userFormData: userEditObject,
      });

      if (!invalidity.isValid) {
        this.props.addFormError(userType.reducerCase(), field);
      }
    });

    const invalidUserField = userFormFields.find(
      (field) => UserFormValidation.userFormValidation({
        fieldName: field,
        userFormData: userEditObject,
      }).isValid === false,
    );

    if (userFormFields.includes(invalidUserField)) {
      canSubmit = false;
      this.toggleLocalSubmitIsDisabled(true);
    }

    // SUBMIT ----------------------------------------------------------------------------------------------
    if (isEdit && canSubmit) {
      if (this.props.toggleDisableFormSubmit) {
        this.props.toggleDisableFormSubmit(true);
      } else {
        this.toggleLocalSubmitIsDisabled(true);
      }
      this.props.jobPutRequest(claimData);
    } else if (!isEdit && canSubmit) {
      if (this.props.toggleDisableFormSubmit) {
        this.props.toggleDisableFormSubmit(true);
      } else {
        this.toggleLocalSubmitIsDisabled(true);
      }
      const phoneNumber = UserFormValidation.cleanPhone(
        _.get(userEditObject, 'phoneNumber', ''),
      ); // replace this with "phone" formatter
      // const phoneNumber = UserFormValidation.phoneToE164Format(userEditObject.phoneNumber)
      const preppedClaimAndUserData = {
        preppedClaimData,
        userData: {
          ...userEditObject,
          phoneNumber,
          companyName: _.get(
            editObject,
            this.IdToMetadataLocation('companyName'),
          ),
        },
      };
      this.props.createCaseWithUser({
        type: caseType.reducerCase(),
        userData: preppedClaimAndUserData.userData,
        data: preppedClaimAndUserData.preppedClaimData,
        userIdName: 'customerId',
        overrideType: 'CLAIM',
      });

      if (requests && requests.requesting) {
        this.toggleLocalSubmitIsDisabled(true);
      } else if (requests && requests.error) {
        this.toggleLocalSubmitIsDisabled(false);
      }
    }
  };

  render() {
    const {
      isEdit,
      userIsEdit,
      requests,
      userEditObject,
      claimErrors,
      branding,
      linesOfBusiness,
      hideUserForm,
      onBack,
      view,
    } = this.props;

    const {
      submitIsDisabled,
      caseTypeId,
      companyName,
      claimNumber,
      claimNumberIsValid,
      claimNumberErrorMessage,
      policyNumber,
      deductible,
      deductibleIsValid,
      deductibleErrorMessage,
      status,
      dateOfLoss,
      dateOfLossIsValid,
      dateOfLossErrorMessage,
      existingCases,
      showClaimMatchAlert,
      showClaimSummaryAlert,
      userClaims,
    } = this.state;

    let currentStateId = 'preRequest';
    if (requests && requests.requesting) {
      currentStateId = 'requesting';
    } else if (requests && requests.success) {
      currentStateId = 'success';
    } else if (requests && requests.error) {
      currentStateId = 'error';
    }

    const submitButtonStates = {
      preRequest: {
        type: 'primary',
        text: this.getSaveButtonText(),
      },
      requesting: {
        type: 'secondary',
        text: 'Saving...',
        noClick: true,
      },
      success: {
        type: 'primary',
        text: 'Saved',
        noClick: true,
      },
      error: {
        type: 'primary',
        text: this.getSaveButtonText(),
      },
    };

    const phoneNumber = userEditObject && userEditObject.phoneNumber
      ? userEditObject.phoneNumber
      : '';
    let formError = this.getFormErrorText();

    if (typeof formError === 'object') {
      formError = formError.message;
    }
    const brandLobs = linesOfBusiness?.length && formatBrandLobs(linesOfBusiness);
    const brandData = this?.props?.editObject?.metaData;
    const selectedBrand = brandData && brandData['c54f88b2-fee1-4216-8c6e-7a7aa5e83a3e'];

    return (
      <form className="case-form-and-user-form-container">
        {!hideUserForm && (
          <div className="user-form-container">
            <div className="case-form-user-status">
              {phoneNumber.length >= 14 && !isEdit ? 'New' : null}
              {' '}
              Client
            </div>

            {/* user form */}
            <UserForm
              toggleDisableParentFormSubmit={
                this.props.toggleDisableFormSubmit
                  ? this.props.toggleDisableFormSubmit
                  : this.toggleLocalSubmitIsDisabled
              }
              parentFormContext="jobs"
              parentIsEdit={isEdit}
              handleParentJobs={(cases) => this.setState(
                { userClaims: cases, showClaimMatchAlert: cases.length > 0 },
              )}
              view="CLAIM"
            />
            <CaseMatchAlert
              cases={userClaims}
              defaultShowAlert={showClaimMatchAlert}
              onClose={() => this.setState({ showClaimMatchAlert: false })}
            />
          </div>
        )}
        {/* claim form */}
        {(phoneNumber.length >= 14 || isEdit || userIsEdit) && (
          <div
            data-test="claim-form"
            className={`${
              view === 'case' ? 'case-view' : ''
            } case-form-container`}
          >
            <div className="case-form-title">Claim Type</div>
            <div className="case-form-inputs-container">
              <div className="case-form-item case-form-item-full case-type-selections">
                <div
                  className={`${
                    caseTypeId === '75dd685b-b04b-4113-83e1-8c23f1149c11'
                      ? 'selected'
                      : ''
                  } case-type-selection`}
                  onClick={() => this.toggleClaimType('personal')}
                  onKeyDown={() => this.toggleClaimType('personal')}
                  role="button"
                  tabIndex={0}
                >
                  <span>
                    <PersonalIcon />
                  </span>
                  Personal
                </div>
                <div
                  className={`${
                    caseTypeId === '36ad685a-bc4b-5213-c3e1-8c28e4549c13'
                      ? 'selected'
                      : ''
                  } case-type-selection`}
                  onClick={() => this.toggleClaimType('business')}
                  onKeyDown={() => this.toggleClaimType('business')}
                  role="button"
                  tabIndex={0}
                >
                  <span className="case-type-business-icon">
                    <BriefcaseIcon />
                  </span>
                  Business
                </div>
              </div>
              {caseTypeId === '36ad685a-bc4b-5213-c3e1-8c28e4549c13' && (
                <div className="case-form-item case-form-item-full business-name">
                  <FormInputField
                    label="Business Name"
                    onChange={this.handleChange}
                    name="companyName"
                    type="text"
                    value={companyName}
                    required
                  />
                </div>
              )}
              {branding && branding.length > 0 && !isEdit && (
                <div className="case-form-item branded-copy">
                  <BrandedCopy
                    key="bc"
                    id="claim"
                    tableId="claims"
                    type={caseType}
                    toggleDisableParentFormSubmit={
                      this.props.toggleDisableFormSubmit
                        ? this.props.toggleDisableFormSubmit
                        : this.toggleLocalSubmitIsDisabled
                    }
                    required
                    errorMessage="You must pick an Insurance Brand"
                  />
                </div>
              )}
              <div className="case-form-item">
                {/* temporary class for style difference in cases tab V.S. claims tab (remove when claims and policies tabs have been removed) */}
                <div
                  className={`poc ${
                    view === 'case'
                      ? 'point-of-contact'
                      : 'point-of-contact-claims-tab'
                  } ${
                    claimErrors.includes('primaryContactId') ? 'has-error' : ''
                  }`}
                >
                  <PrimaryContactInput
                    key="pc"
                    id="claim"
                    tableId="cases"
                    type={caseType}
                    toggleDisableParentFormSubmit={
                      this.props.toggleDisableFormSubmit
                        ? this.props.toggleDisableFormSubmit
                        : this.toggleLocalSubmitIsDisabled
                    }
                    required
                    errorMessage="You must assign Point of Contact"
                    editObject={{ ...this.props.editObject }}
                  />
                </div>
              </div>
              <PermissionVerifier neededPermissions={[CASE_VISIBILITY]}>
                <div className="case-form-item">
                  <CaseVisibilityDropdown
                    label="Case Visibility"
                    required
                    onChange={this.handleCaseVisibilityChange}
                    caseAssignment={this.props.editObject.operatorId}
                  />
                </div>
              </PermissionVerifier>
              <div className="case-form-item">
                <FormInputField
                  label="Claim Number"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="claimNumber"
                  type="text"
                  value={claimNumber}
                  isValid={
                    claimErrors.indexOf('claimNumber') > -1
                      ? false
                      : claimNumberIsValid
                  }
                  errorMessage={claimNumberErrorMessage}
                  required
                />
              </div>
              {branding && branding?.length > 0 && (
                <PermissionVerifier
                  neededPermissions={[LINES_OF_BUSINESS_ENABLE]}
                >
                  <div className="case-form-item">
                    <div className="case-form-item-label">Line of Business</div>
                    <LinesOfBusinessList
                      onChange={this.handleLobChange}
                      selectedBrand={selectedBrand}
                      listOfLob={brandLobs && brandLobs[selectedBrand]}
                    />
                  </div>
                </PermissionVerifier>
              )}
              <div className="case-form-item">
                <FormInputField
                  label="Policy Number"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="policyNumber"
                  type="text"
                  value={policyNumber}
                />
              </div>
              <div className="case-form-item">
                <FormInputField
                  prefixAddOn="$"
                  label="Deductible"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="deductible"
                  type="text"
                  value={deductible}
                  isValid={
                    claimErrors.indexOf('deductible') > -1
                      ? false
                      : deductibleIsValid
                  }
                  errorMessage={deductibleErrorMessage}
                />
              </div>
              <div className="case-form-item claim-status-and-date-of-loss">
                <FormInputField
                  label="Claim Status"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="status"
                  type="text"
                  value={status}
                  fieldSpecificMessage={`The status of your claim is ${status}`}
                />
              </div>
              <div className="case-form-item">
                <div
                  className={`date-container ${
                    !dateOfLossIsValid ? 'has-error' : ''
                  }`}
                >
                  <div className="claim-form-date-of-loss">
                    Date of Loss
                    <Required />
                  </div>
                  <DatePicker
                    style={{ width: 180 }}
                    className="date-of-loss"
                    maxDate={new Date().toString()}
                    onChange={(value) => {
                      this.props.handleFormInputChange({
                        inputId: this.IdToMetadataLocation('dateOfLoss'),
                        tableId: caseType.id(),
                        value,
                      });
                      if (value && !dateOfLossIsValid) {
                        this.setState({ dateOfLossIsValid: true });
                      }
                      this.setState({ dateOfLoss: value });
                    }}
                    onBlur={this.onBlur}
                    showClearButton={false}
                    disabled={isEdit}
                    required
                    value={dateOfLoss}
                  />
                  {!dateOfLossIsValid && (
                    <span className="error-message">
                      {dateOfLossErrorMessage}
                    </span>
                  )}
                </div>
              </div>
              <div className="case-form-item case-form-item-full case-error">
                {formError}
              </div>
              <div className="case-form-item case-form-item-full">
                <CaseMatchAlert
                  cases={existingCases}
                  defaultShowAlert={showClaimSummaryAlert}
                  onClose={() => this.setState({ showClaimSummaryAlert: false })}
                  isSummary
                />
              </div>
              <div className="form-footer">
                <ConfigurableOptInWidget userId={userEditObject.id} />
                <div
                  className={`${
                    view === 'case' ? 'case-submit' : ''
                  } case-form-item case-form-item-full submit-btn`}
                >
                  {onBack && (
                    <Button
                      type="outline"
                      className="claim-back-btn"
                      onClick={onBack}
                      disabled={false}
                    >
                      Back
                    </Button>
                  )}
                  <MultiStateButton
                    data-button="claims-form-submit"
                    className="claim-submit-btn general-submit-btn"
                    onClick={this.handleSubmit}
                    currentStateId={currentStateId}
                    states={submitButtonStates}
                    disabled={
                      view === 'case'
                        ? this.props.submitIsDisabled
                        : submitIsDisabled
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    );
  }
}

ClaimsForm.propTypes = {
  hideUserForm: PropTypes.bool,
  handleFormInputChange: PropTypes.func,
  clearAddFormErrors: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  editObject: PropTypes.object,
  isEdit: PropTypes.bool,
  toggleDisableFormSubmit: PropTypes.func,
  submitIsDisabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  userEditObject: PropTypes.object,
  userIsEdit: PropTypes.bool,
  userCases: PropTypes.arrayOf(Object),
  onBack: PropTypes.func,
  view: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  linesOfBusiness: PropTypes.array,
  createButtonText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  requests: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  branding: PropTypes.array,
  addFormError: PropTypes.func,
  jobPutRequest: PropTypes.func,
  createCaseWithUser: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  claimErrors: PropTypes.array,
};

ClaimsForm.defaultProps = {
  hideUserForm: false,
  clearAddFormErrors: () => {},
  handleFormInputChange: () => {},
  isEdit: false,
  editObject: null,
  toggleDisableFormSubmit: () => {},
  submitIsDisabled: false,
  userEditObject: null,
  userIsEdit: false,
  userCases: [],
  onBack: () => {},
  view: null,
  linesOfBusiness: [],
  createButtonText: null,
  requests: null,
  branding: null,
  addFormError: () => {},
  jobPutRequest: () => {},
  createCaseWithUser: () => {},
  claimErrors: null,
};

const mapDispatchToProps = {
  handleFormInputChange,
  createCaseWithUser,
  updateItem,
  addFormError,
  clearAddFormErrors,
  clearEditObject,
  jobPutRequest,
  closeForm,
};

const mapStateToProps = (state) => ({
  jobs: _.get(state, 'jobs', {}),
  users: _.get(state, 'users', {}),
  claims: _.get(state, 'jobs', {}),
  userEditObject: _.get(state, 'users.form.editObject', {}),
  isEdit: _.get(state, 'jobs.form.isEdit', false),
  userIsEdit: _.get(state, 'users.form.isEdit', false),
  editObject: _.get(state, 'jobs.form.editObject', null),
  claimErrors: _.get(state, 'jobs.errors', []),
  userErrors: _.get(state, 'users.errors', []),
  requests: _.get(state, 'ui.requests.addCase', {}),
  branding: _.get(state, 'organizations.branding', []),
  linesOfBusiness: _.get(state, 'organizations.linesOfBusiness'),
});

export { ClaimsForm };
export default connect(mapStateToProps, mapDispatchToProps)(ClaimsForm);
