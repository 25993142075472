import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonRow, Modal } from '@himarley/unity';
import styles from './RedactionModal.module.less';

const RedactionModal = ({
  title,
  show,
  hideModal,
  description,
  confirmButtonText,
  onConfirm,
  isImage,
  cancelBtnText,
  className,
}) => (
  <Modal
    className={styles.modal}
    show={show}
    showCloseButton
    title={title}
    toggleModal={hideModal}
    backdrop
    size="auto"
  >
    <div className={`${isImage ? styles.image : ''} ${styles.redactText} ${className}`}>
      {description}
    </div>
    <div>
      <ButtonRow>
        <Button onClick={hideModal} type="outline">
          {cancelBtnText}
        </Button>
        {confirmButtonText && (
          <Button
            data-testid="redact-image-confirm"
            onClick={onConfirm}
            type="neutral"
          >
            {confirmButtonText}
          </Button>
        )}
      </ButtonRow>
    </div>
  </Modal>
);

RedactionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  title: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  isImage: PropTypes.bool,
  cancelBtnText: PropTypes.string,
  className: PropTypes.string,
};

RedactionModal.defaultProps = {
  onConfirm: () => {},
  confirmButtonText: '',
  isImage: false,
  cancelBtnText: 'Cancel',
  className: null,
};

export default RedactionModal;
