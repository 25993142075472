import React, { useMemo } from 'react';
import PropTypes from 'prop-types'
import { ignoreSpecialCharacters } from '../../../helpers/common';
import './FocusedList.less'

const FocusedList = (props) => {
  const { focusedValue, label, className } = props

  const sanitizedText = useMemo(() => {
    const formattedText = ignoreSpecialCharacters(focusedValue);
    const regText = new RegExp(formattedText, 'ig')
    const newText = label?.replace(regText, (match) => `<>${match}<>`)
    const splittedText = newText?.split('<>')
    return (
      splittedText?.map((txt) => (
        txt?.toLowerCase() === focusedValue?.toLowerCase()
          ? <span className={className}>{txt}</span>
          : <>{txt}</>
      ))
    )
  }, [focusedValue, label, className]);

  return (
    <>
      {(focusedValue && label) && sanitizedText}
    </>
  )
}

FocusedList.propTypes = {
  label: PropTypes.string,
  focusedValue: PropTypes.string,
  className: PropTypes.string,
};

FocusedList.defaultProps = {
  label: '',
  focusedValue: '',
  className: '',
}

export default FocusedList
