import React from 'react';
import PropTypes from 'prop-types';
import MarleyLogo from '../../../images/icons/hi-marley_logo_photo.svg';
import CopartLogo from '../../../images/icons/avatar-vendor-copart.svg';

import Unassigned from '../../../images/icons/unassigned.svg';

import './UserPhoto2.less';

let timestamp = Date.now();

const UserPhoto2 = (props) => {
  const {
    isAI,
    isVendor,
    id,
    imageUrl,
    name,
    className,
    isCustomer,
    isRecipient,
    isFinishedLoading,
    isRemoved,
    testId,
    ...others
  } = props;

  let initials; let url; let
    pipedUrl;
  if (id) {
    // image url stays the same even if the image changes. timestamp needed to refresh
    if (isFinishedLoading) timestamp = Date.now();
    pipedUrl = `/api/user/${id}/avatarImage?timestamp=${timestamp}`;
  }

  if (name && name.length > 0) {
    initials = name.split(' ').map((n) => n.slice(0, 1));
  }

  if (pipedUrl && pipedUrl.length > 0) {
    url = pipedUrl;
  } else if (imageUrl && imageUrl.length > 0) {
    url = imageUrl;
  }

  /**
   * TODO: as part of the work to obfuscate s3 urls from the front-end we will need to create
   *    a hasPhoto or hasAvatar boolean value on the user object in the database and set it
   *    while uploading the user profile image so we can remove this logic from the front-end
   */
  const hasPhoto = !!(imageUrl && imageUrl.length > 0);

  let ThingToRender;
  if (isAI) {
    // eslint-disable-next-line react/jsx-filename-extension
    ThingToRender = <MarleyLogo />;
  } else if (isVendor) {
    // eslint-disable-next-line react/jsx-filename-extension
    ThingToRender = <div data-testid="copart-logo"><CopartLogo /></div>;
  } else if (id === 'unassigned') {
    ThingToRender = <Unassigned />;
  } else if (hasPhoto) {
    ThingToRender = (
      <img
        className="user-photo2-img"
        alt="usr"
        src={url}
        data-testId={`${testId}-avatar-image`}
      />
    );
  } else {
    let styling;
    if (isRemoved) {
      styling = 'removed-color';
    } else if (isRecipient) {
      styling = 'user-color';
    } else if (isCustomer) {
      styling = 'user-color';
    } else {
      styling = 'operator-color';
    }
    ThingToRender = (
      <div
        className={`user-photo2-initials ${styling}`}
        data-testId={`${testId}-avatar-initials`}
      >
        {initials}
      </div>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={`user-photo2 ${className}`} {...others}>
      {ThingToRender}
    </div>
  );
};

UserPhoto2.propTypes = {
  id: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  isAI: PropTypes.bool,
  isVendor: PropTypes.bool,
  isRecipient: PropTypes.bool,
  isCustomer: PropTypes.bool.isRequired,
  isFinishedLoading: PropTypes.bool.isRequired,
  isRemoved: PropTypes.bool,
  testId: PropTypes.string,
};

UserPhoto2.defaultProps = {
  id: undefined,
  imageUrl: undefined,
  isVendor: false,
  name: '',
  className: '',
  isAI: false,
  isRecipient: false,
  isRemoved: false,
  testId: null,
};

export default UserPhoto2;
