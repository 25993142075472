import _isEqual from 'lodash/isEqual';
import { NEW_MESSAGE, EVENT_TAG_MAP } from '../constants/websocket';

const handleTagMatching = (job, filteredTags, eventName, cachedJob) => {
  if (!job) {
    return false;
  }
  const eventJobTags = job.inboxJob?.tags || [];
  const cachedJobTags = cachedJob?.tags || [];

  // 1. Handles the insertion of the job on message update events
  if (filteredTags?.includes(EVENT_TAG_MAP[eventName]) && !cachedJob) {
    return true;
  }
  // 2. Handles the removal of the job when tags change between event and cached job
  let isCachedIntersection = false;
  if (cachedJobTags.length > 0 && !_isEqual(eventJobTags, cachedJobTags)) {
    const cachedJobCaseTagIds = cachedJobTags?.map((t) => t.replaceAll(' ', '-').toLowerCase());
    const cachedIntersection = cachedJobCaseTagIds.filter((t) => filteredTags.includes(t)) || [];
    isCachedIntersection = cachedIntersection.length > 0;
  }
  // 3. Handles the standard instance where the event job contains any of the filtered tags
  const eventJobTagIds = eventJobTags?.map((t) => t.replaceAll(' ', '-').toLowerCase()) || [];
  const intersection = eventJobTagIds.filter((t) => filteredTags?.includes(t)) || [];

  return intersection.length > 0 || isCachedIntersection;
};

// There is probably some work to do as part of HMB-6751 to productionize this code so we can
// rely upon the selected/filtered groups to always exist in redux store
// this implementation assumes and needs the groupMemberOperatorIds to ALWAYS have values
export const jobMatchesWithAtLeastOneGroupMember = (job, filteredGroupIds, groups) => {
  if (!job
      || !job?.operatorId
      || !filteredGroupIds
      || filteredGroupIds?.length === 0
      || !groups
      || Object.keys(groups || {}).length === 0
  ) {
    return false;
  }
  const primaryOperatorId = job?.operatorId?._id || job?.operatorId;
  const allJobOperatorIds = [
    primaryOperatorId,
    ...(job.secondaryOperatorIds ? [...job.secondaryOperatorIds] : []),
  ];

  let groupIdsToVisit = filteredGroupIds;
  const visitedGroupIds = new Set();
  while (groupIdsToVisit.length > 0) {
    const nextGroupIds = []
    for (let i = 0; i < groupIdsToVisit.length; i += 1) {
      const groupId = groupIdsToVisit[i];
      const group = groups[groupId];
      const operatorsFromGroups = new Set();
      group?.members?.forEach((member) => operatorsFromGroups.add(member?.id));
      group?.leads?.map((lead) => operatorsFromGroups.add(lead?.id));
      const intersection = allJobOperatorIds?.filter((o) => operatorsFromGroups.has(o));
      if (intersection.length > 0) {
        return true;
      }

      // Mark current group as being visited
      visitedGroupIds.add(groupId);

      // Generate next layer of groups to visit that have yet to be checked
      group?.subgroups?.forEach((subgroup) => {
        const subgroupId = subgroup?._id;
        if (!visitedGroupIds.has(subgroupId)) {
          nextGroupIds.push(subgroupId)
        }
      });
    }

    // Set the next layer of groups to visit
    groupIdsToVisit = nextGroupIds;
  }
  return false;
};

/**
 * Return true on first match where one of the operators in the list
 * is a primary or additional operator on the job.
 *
 * This should work with "unassigned" since the inbox puts this string
 * in the operatorId list
 */
export const jobMatchesWithAtLeastOneOperator = (job, operatorIds = []) => {
  const jobId = job?._id || job?.id;
  if (!jobId) {
    return false;
  }
  for (let i = 0; i < operatorIds.length; i += 1) {
    const operator = operatorIds[i];
    // eslint-disable-next-line no-underscore-dangle
    const isPrimaryOperator = job?.operatorId === operator || job?.operatorId?._id === operator;
    if (operator === 'unassigned' && !job.operatorId) {
      return true;
    }
    const isAdditionalOperator = (job?.secondaryOperatorIds || []).includes(operator);
    if (isPrimaryOperator || isAdditionalOperator) {
      return true;
    }
  }
  return false;
};

const isJobCustomerActivated = (user) => user.verified && !user.blocked && !user.locked;

/**
 * Only return true if the job meets all active filters
 * Return false on the first mismatch
 */
export const shouldInboxConsumeUpdate = ({
  filters, job, groups, eventName, cachedJob, viewedCaseIds,
}) => {
  const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;

  if (filters?.isOpen && !job?.isOpen) {
    return false;
  }
  if (!filters?.isOpen && job?.isOpen) {
    return false;
  }
  if (filters?.onlyActive && !job.isActive) {
    return false;
  }
  if (eventName !== NEW_MESSAGE
    && filters?.hasUnreadMessages
    && (isUnreadStateRefactorEnabled ? job.unreadMessageCount > 0 : viewedCaseIds.has(job._id))
  ) {
    return false;
  }
  if (filters?.unansweredOptIns
    && job.customerId.verified !== undefined
    && isJobCustomerActivated(job.customerId)) {
    return false;
  }
  const jobIsNotPinned = ((typeof job?.isPinned === 'undefined' && !cachedJob?.isPinned) || (typeof job?.isPinned !== 'undefined' && !job?.isPinned));
  if (
    jobIsNotPinned
    && filters?.operatorIds?.length > 0
    && !filters?.groupIds?.length > 0
    && !jobMatchesWithAtLeastOneOperator(job, filters.operatorIds)) {
    return false;
  }
  if (
    jobIsNotPinned
    && filters?.groupIds?.length > 0
    && !filters?.operatorIds?.length > 0
    && !jobMatchesWithAtLeastOneGroupMember(job, filters?.groupIds, groups)) {
    return false;
  }
  if (
    jobIsNotPinned
    && filters?.groupIds?.length > 0
    && filters?.operatorIds?.length > 0
    && !jobMatchesWithAtLeastOneOperator(job, filters.operatorIds)
    && !jobMatchesWithAtLeastOneGroupMember(job, filters?.groupIds, groups)
  ) {
    return false;
  }
  if (filters?.tags?.length > 0
    && !handleTagMatching(job, filters?.tags, eventName, cachedJob)
  ) {
    return false;
  }
  return true;
};
