/* eslint-disable no-control-regex */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ContactIcon from '../../../../images/icons/contact-icon.svg';
import CellphoneIcon from '../../../../images/icons/cellphone-icon.svg';
import EmailIcon from '../../../../images/icons/email-address-icon.svg';
import ContactCardIcon from '../../../../images/icons/icon-file-vcf.svg';

import './MediaContactCard.less';
import { mediaItemPropTypes, parseContactCard } from '../media-helpers';

const VCF_ICON_WIDTH_THRESHOLD = 200;
const VCF_FULL_DETAIL_THRESHOLD = 300;

const ContactCardInfoRow = ({ icon, value }) => (
  (
    value
        && (
        <div className="contact-card-row">
          {icon}
          {value}
        </div>
        )
  )
);

ContactCardInfoRow.propTypes = {
  icon: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

const nameToRowComponent = (name, entry, idx, width) => {
  const normalizedName = name.toLowerCase();
  switch (normalizedName) {
    case ('tel'): {
      return (
        <ContactCardInfoRow
          icon={<CellphoneIcon title="contact phone number" />}
          value={entry.value}
          key={idx}
        />
      );
    }
    case ('email'): {
      return (
        width > VCF_FULL_DETAIL_THRESHOLD
        && (
        <ContactCardInfoRow
          icon={<EmailIcon title="contact email address" />}
          value={entry.value}
          key={idx}
        />
        )
      );
    }
    default:
      return null;
  }
};

const ContactCard = ({ mediaItem, containerDimensions, fileUrl }) => {
  const { providerId } = mediaItem;
  const { width, height } = containerDimensions;
  const [formattedFields, setFormattedFields] = useState([]);
  const [header, setHeader] = useState('');
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    if (width < VCF_ICON_WIDTH_THRESHOLD) {
      return () => {};
    }
    fetch(fileUrl)
      .then((response) => response.text())
      .then((rawContactCard) => {
        const { FN, PHOTO, ...dataFields } = parseContactCard(rawContactCard);
        if (FN) {
          const [{ value }] = FN;
          setHeader(value);
        }
        if (PHOTO) {
          const [{ value, meta }] = PHOTO;
          const [encoding, contentType] = meta;
          if (encoding && contentType) {
            const [,encodingType] = encoding.split('=');
            setPhoto(`data:image/${contentType};${encodingType}, ${value}`);
          }
        }
        setFormattedFields(
          Object
            .entries(dataFields)
            .flatMap(
              ([name, entries]) => entries.map((entry) => [name, entry]),
            ),
        );
      });
    return function cleanup() {
      setPhoto(null);
    };
  }, [fileUrl, width]);
  if (width < VCF_ICON_WIDTH_THRESHOLD) {
    return (
      <ContactCardIcon
        title="contact-card-file"
        style={{ width, height }}
      />
    );
  }
  if (formattedFields.length === 0) return null;
  return (
    <div className="contact-card-container" data-testid={`contact-card-container-${providerId}`}>
      <div className="contact-card-icon">
        {
        photo
          ? (
            <img
              alt="contact"
              src={photo}
              data-testid={`contact-card-profile-image-${providerId}`}
            />
          )
          : <ContactIcon title="contact-card-icon" />
        }

      </div>
      <div className="contact-card-details">
        <div className="contact-card-header">{header}</div>
        {
          formattedFields.map(
            ([name, entry], idx) => (nameToRowComponent(name, entry, idx, width)),
          )
        }
      </div>
    </div>
  );
};

ContactCard.propTypes = {
  mediaItem: mediaItemPropTypes.isRequired,
  containerDimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }).isRequired,
  fileUrl: PropTypes.string.isRequired,
};

export default ContactCard;
