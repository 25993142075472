/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import PropTypes from 'prop-types'
import './WithDot.less'

const WithDot = ({
  className,
  show,
  glow,
  position,
  type,
  children,
  isBackgroundLight,
  size,
  withBorder,
  Icon,
  jobId,
}) => {
  const glowBackground = !isBackgroundLight ? 'glow-radius-dark-background' : 'glow-radius-light-background'
  const glowColor = `glow-radius-${type === 'red' ? 'red' : 'blue'}-point`
  const pointClasses = [
    `notification-point-${size || 'sm'}`,
    'notification-point',
    `notification-${type || 'blue'}-point`,
    `notification-point-${position || 'default'}`,
  ]
  if (withBorder) {
    pointClasses.push('notification-point-border')
  }
  return (
    <div data-testid="withDot" className={className} style={{ position: 'relative' }}>
      {show && <div data-testid={`${jobId ? `new-message-dot-${jobId}` : 'dot'}`} className={pointClasses.join(' ')}>{Icon && <Icon />}</div>}
      {show && glow && <div className={`glow-radius ${glowBackground} ${glowColor}`} />}
      {children}
    </div>
  )
}

WithDot.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  glow: PropTypes.bool,
  isBackgroundLight: PropTypes.bool,
  position: PropTypes.string,
  show: PropTypes.bool,
  withBorder: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  jobId: PropTypes.string,
};

WithDot.defaultProps = {
  className: '',
  glow: false,
  isBackgroundLight: true,
  position: 'left',
  show: false,
  type: 'blue',
  withBorder: false,
  size: 'sm',
  jobId: '',
};

export default WithDot;
