/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable multiline-ternary */
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Accordion, List, Divider,
} from '@himarley/unity';
import SearchBar from '../../../SearchBar/SearchBar';
import LineDropdown from '../../../elements/dropdowns/LineDropdown/LineDropdown';
import Title from '../../../elements/text/Title/Title';
import BinocularsIcon from '../../../../../images/icons/binoculars.svg';
import InfoIcon from '../../../../../images/icons/info.svg';
import TooltipInfo from '../../../elements/Tootip/TooltipInfo';
import LinesOfBusinessRows from './LinesOfBusinessRows';
import styles from './LinesOfBusiness.module.less';

const sortDropdownList = [
  {
    id: 'az',
    label: 'A-Z',
    placeholderLabel: 'A-Z',
    query: true,
    sort: (brands) => brands.sort((a, b) => a.localeCompare(b)),
  },
  {
    id: 'za',
    label: 'Z-A',
    placeholderLabel: 'za',
    query: false,
    sort: (brands) => brands.sort((a, b) => b.localeCompare(a)),
  },
];

const LinesOfBusiness = ({ organization, setSelectedLob }) => {
  const [sortType, setSortType] = useState(sortDropdownList[0]);
  const [searchText, setSearchText] = useState('');
  const [isAllExpanded, setIsAllExpanded] = useState(true);

  const brandToLinesOfBusinessMap = new Map(
    organization?.branding?.map((brand) => [brand, []]),
  );

  organization?.linesOfBusiness?.forEach((lineOfBusiness) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    brandToLinesOfBusinessMap.has(lineOfBusiness?.branding)
      && brandToLinesOfBusinessMap.set(lineOfBusiness.branding, [
        ...brandToLinesOfBusinessMap.get(lineOfBusiness?.branding),
        lineOfBusiness,
      ]);
  });

  const statusToggle = (id) => {
    const item = sortDropdownList.find((i) => i.id === id) || {};
    setSortType(item);
  };

  const onSearchTextChange = (e) => {
    setSearchText(e?.target?.value || '');
  };

  const renderRightAccordionContent = useCallback(
    (brandName) => {
      if (brandToLinesOfBusinessMap.get(brandName).length > 0) {
        return `${
          brandToLinesOfBusinessMap.get(brandName).length
        } Lines of Business`;
      }
      return (
        <div className={styles.noLobWrap}>
          <span>No Lines Added</span>
          <TooltipInfo
            icon={<InfoIcon />}
            message="This brand doesn’t have any lines of business. System Admins must add lines of business in your Org. Settings first."
          />
        </div>
      );
    },
    [brandToLinesOfBusinessMap],
  );

  const brands = useMemo(
    () => sortType?.sort(
      // eslint-disable-next-line no-unsafe-optional-chaining
      [...brandToLinesOfBusinessMap?.keys()]?.filter((brand) => brand?.toUpperCase()?.includes(searchText?.toUpperCase())),
    ),
    [brandToLinesOfBusinessMap, searchText, sortType],
  );

  const isWelcomeFlowRefactorEnabled = process.env.WELCOME_FLOW_REFACTOR_ENABLED;
  const infoMessage = isWelcomeFlowRefactorEnabled ? 'Configure welcome messages per line of business.' : 'Configure opt-in message flows per line of business.';

  return (
    <section className="brandsWrap" data-testId="linesOfBusinessPage">
      <p>{infoMessage}</p>
      {organization?.branding?.length > 0 ? (
        <>
          <div className="topBar">
            <h3>
              {`${brands.length} ${brands.length === 1 ? 'Brand' : 'Brands'}`}
            </h3>
            <div className="searchSort">
              <SearchBar
                placeholder="Search Brands..."
                onChange={onSearchTextChange}
              />
              <LineDropdown label={`Sort: ${sortType?.label}`}>
                <List
                  items={sortDropdownList}
                  onToggle={statusToggle}
                  selectedIds={sortType?.id}
                />
              </LineDropdown>
              <button
                type="button"
                onClick={() => setIsAllExpanded(!isAllExpanded)}
                className={styles.expandCollapseBtn}
              >
                {isAllExpanded ? 'Collapse All' : 'Expand All'}
              </button>
            </div>
          </div>
          <div className={styles.brandListWrap}>
            {brands.length === 0 && (
              <div className="none-found-notification">
                <div className="none-found-icon">
                  <BinocularsIcon />
                </div>
                <div>We're looking, but no Brands found.</div>
              </div>
            )}
            {brands?.map((brandName) => (
              <Accordion
                isExpanded={
                  brandToLinesOfBusinessMap.get(brandName).length > 0
                  && isAllExpanded
                }
                showExpandCollapseButton={
                  brandToLinesOfBusinessMap.get(brandName).length > 0
                }
                disabled={brandToLinesOfBusinessMap.get(brandName).length === 0}
                key={brandName}
                leftContent={(
                  <div className={styles.brandName}>
                    <Avatar
                      name={brandName}
                      isCustomer
                      className={styles.brandAvatar}
                    />
                    <Title type="subheader">
                      <b>{brandName}</b>
                    </Title>
                  </div>
                )}
                rightContent={renderRightAccordionContent(brandName)}
              >
                <LinesOfBusinessRows
                  lobs={brandToLinesOfBusinessMap?.get(brandName)}
                  setSelectedLob={setSelectedLob}
                />
              </Accordion>
            ))}
          </div>
        </>
      ) : (
        <div className="noBrandsWrap">
          <p>Your organization doesn’t have any brands yet.</p>
        </div>
      )}
      <Divider />
    </section>
  );
};

LinesOfBusiness.prototypes = {
  organizations: PropTypes.shape({}).isRequired,
  setSelectedLob: PropTypes.func.isRequired,
};

export default LinesOfBusiness;
