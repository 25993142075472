import {
  CHANGE_FIRST_NAME,
  CHANGE_LAST_NAME,
  CHANGE_PHONE_NUMBER,
  CHANGE_RELATIONSHIP,
  SET_PUT_CHAT_PARTICIPANT_STATUS,
  REQUEST_PUT_CHAT_PARTICIPANT_INIT,
  REQUEST_PUT_CHAT_PARTICIPANT_SUCCESS,
  REQUEST_PUT_CHAT_PARTICIPANT_ERROR,
  REQUEST_CHAT_REMOVE_USER_ERROR,
  REQUEST_CHAT_REMOVE_USER_INIT,
  REQUEST_CHAT_REMOVE_USER_SUCCESS,
  SET_CHAT_REMOVE_USER_STATUS,
} from '../constants/participants'
import { LOAD_ALL_CHAT_PARTICIPANTS } from '../constants/actions'

const initialState = {
  participantForm: {
    phoneNumber: '',
    firstName: '',
    lastName: '',
    relationship: '',
    error: null,
  },
  chatParticipants: {
    activeParticipants: [],
    pendingParticipants: [],
    inactiveParticipants: [],
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PHONE_NUMBER:
      return {
        ...state,
        participantForm: {
          ...state.participantForm,
          phoneNumber: action.phoneNumber,
        },
      }
    case CHANGE_FIRST_NAME:
      return {
        ...state,
        participantForm: {
          ...state.participantForm,
          firstName: action.firstName,
        },
      }
    case CHANGE_LAST_NAME:
      return {
        ...state,
        participantForm: {
          ...state.participantForm,
          lastName: action.lastName,
        },
      }
    case CHANGE_RELATIONSHIP:
      return {
        ...state,
        participantForm: {
          ...state.participantForm,
          relationship: action.relationship,
        },
      }
    case REQUEST_PUT_CHAT_PARTICIPANT_INIT:
      return {
        ...state,
        putChatParticipantStatus: 'PENDING',
        participantForm: {
          ...state.participantForm,
          error: null,
        },
      }
    case REQUEST_PUT_CHAT_PARTICIPANT_SUCCESS:
      return {
        ...state,
        putChatParticipantStatus: 'SUCCESS',
      }
    case REQUEST_PUT_CHAT_PARTICIPANT_ERROR:
      return {
        ...state,
        putChatParticipantStatus: 'ERROR',
        participantForm: {
          ...state.participantForm,
          error: action?.payload?.error?.message,
        },
      }
    case SET_PUT_CHAT_PARTICIPANT_STATUS:
      return {
        ...state,
        putChatParticipantStatus: action.status,
      }
    case REQUEST_CHAT_REMOVE_USER_INIT:
      return {
        ...state,
        removeChatParticipantStatus: 'PENDING',
      }
    case REQUEST_CHAT_REMOVE_USER_SUCCESS:
      return {
        ...state,
        removeChatParticipantStatus: 'SUCCESS',
      }
    case REQUEST_CHAT_REMOVE_USER_ERROR:
      return {
        ...state,
        removeChatParticipantStatus: 'ERROR',
      }
    case SET_CHAT_REMOVE_USER_STATUS:
      return {
        ...state,
        removeChatParticipantStatus: action.status,
      }
    case LOAD_ALL_CHAT_PARTICIPANTS:
      return {
        ...state,
        chatParticipants: action.payload,
      }
    default:
      return state
  }
}

export default reducer
