import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import StarRate from '../../../StarRate/StarRate'
import './FeedbackItem.less'

const FeedbackItem = ({templateKey, rate, userName, review, metricAnswerTimestamp }) => {
  const time = metricAnswerTimestamp ? moment(metricAnswerTimestamp).format('MM/DD/YYYY') : ''

  return (<div className="customer-feedback-item" key={templateKey}>
    <div className="customer-feedback-item-rate-wrap">
      <div className="customer-feedback-item-timestamp">{time}</div>
      <div className="customer-feedback-item-rate-num">
        {rate.toFixed(1)}
      </div>
      <StarRate className="customer-feedback-item-rate-stars" score={rate} type="small" />
    </div>
    <div className="customer-feedback-user">
      User
      <div className="customer-feedback-user-value">
        {userName}
      </div>
    </div>
    <div className="customer-feedback-review">
      Review
      <div className="customer-feedback-review-value">
        {review}
      </div>
    </div>
  </div>)
}

FeedbackItem.propTypes = {
  templateKey: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  metricAnswerTimestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default FeedbackItem
