/* eslint-disable no-control-regex */
import PropTypes from 'prop-types';

export const mediaItemPropTypes = PropTypes.shape({
  providerId: PropTypes.string,
  messageId: PropTypes.string,
  fileUrl: PropTypes.string,
  body: PropTypes.string,
  mediaInfo: PropTypes.shape({
    dateTaken: PropTypes.string,
    exif: PropTypes.shape({
      Make: PropTypes.string,
      Model: PropTypes.string,
    }),
    geolocation: PropTypes.shape({
      Neighborhood: PropTypes.string,
      Region: PropTypes.string,
    }),
  }),
});

// https://stackoverflow.com/questions/5582248
function splitWithTail(str, delim, count) {
  const parts = str.split(delim);
  const tail = parts.slice(count).join(delim);
  const result = parts.slice(0, count);
  result.push(tail);
  return result;
}

export const parseContactCard = (contactCardText) => {
  const formatted = contactCardText
  // Trim whitespace
    .replace(/^[\s\r\n]+|[\s\r\n]+$/g, '')
  // Trim blank lines
    .replace(/(\r\n)[\r\x09\x20]?(\r\n)|$/g, '$1')
  // Unfold folded lines
    .replace(/\r\n[\r\x20\x09]/g, '');
  return formatted
    .split(/\r\n|\r|\n/)
    .map((line) => splitWithTail(line, ':', 1))
    .reduce((accum, curr) => {
      const [params, value] = curr;
      const [name, ...meta] = params.split(';');
      const entry = {
        value,
        meta,
      };

      const existingField = accum[name];
      const updatedField = existingField
        ? [...existingField, entry]
        : [entry];
      return ({
        ...accum,
        [name]: updatedField,
      });
    }, {});
};

/**
 * Limits rotation to 90 degrees clockwise/counter-clockwise to restrict image rotation
 * in the full page media modal view. The final rotation amount will always be within the bounds
 * of 0 and 270 degrees
 */
export const determineRotation = (direction, currentRotation) => {
  const rotationWithinBounds = currentRotation % 360;
  if (direction === 'left') {
    const rotationChange = rotationWithinBounds - 90;
    return rotationWithinBounds < 90 ? 360 + rotationChange : rotationChange;
  }
  if (direction === 'right') {
    const rotationChange = rotationWithinBounds + 90;
    return rotationWithinBounds > 270 ? 360 - rotationChange : rotationChange;
  }
  throw new Error('Invalid direction provided to rotate. Use "left" or "right" as inputs');
};
