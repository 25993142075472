import React from 'react'
import { connect } from 'react-redux'
import usePermissionVerify from '../elements/hooks/usePermissionVerify'

const usePermissionCheck = (WrappedComponent, config) => {
  const ComponentWithPermissionsCheck = (props) => {
    const { hasAccess } = usePermissionVerify({ neededPermissions: props?.neededPermissions || config?.neededPermissions })
    return (
      <WrappedComponent hasAccess={hasAccess} {...props}/>
    )
  }

  return connect(null, {})(ComponentWithPermissionsCheck)
}

export default usePermissionCheck
