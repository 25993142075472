import * as commonReducer from './common'
import _ from 'lodash'

export const initialState = {
  list: [],
  listById: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'EDIT_GROUP': {
      const groupId = _.get(action, 'payload.body.group._id', {})
      const name = _.get(action, 'payload.body.group.name')
      const description = _.get(action, 'payload.body.group.description')
      const archived = _.get(action, 'payload.body.group.archived')
      let list = _.get(state, 'list', []).slice()

      list = list.map((g) => {
        const isModifiedGroup = g.id === groupId
        const doModify = isModifiedGroup || archived
        if (doModify) {
          return ({
            ...g,
            members: archived ? g.members.map((m) => ({
              ...m,
              groups: _.difference(m.groups, [groupId]),
            })) : g.members,
            name: isModifiedGroup ? name : g.name,
            description: isModifiedGroup ? description : g.description,
          })
        }
        return g
      })

      return {
        ...state,
        list,
      }
    }
    case 'GET_USER_ROLES': {
      let userRoles = _.get(action, 'payload', [])
      let list = _.get(state, 'list', []).slice()
      userRoles = userRoles.map(user => (user.Item !== undefined ? user.Item : {}))
      list = list.map(group => ({
        ...group,
        members: _.get(group, 'members', []).map(member => ({
          ...member,
          roles: _.get(_.find(userRoles, r => r.identityId === member.id), 'groups', []),
        })),
        leads: _.get(group, 'leads', []).map(lead => ({
          ...lead,
          roles: _.get(_.find(userRoles, r => r.identityId === lead.id), 'groups', []),
        })),
      }))
      return { ...state, list }
    }
    default:
      return state
  }
}

export default commonReducer.reducer('GROUP', reducer)
