import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './HomeHeader.less'

const HomeHeader = ({ profile }) => {
  if (profile?.firstName) {
    return (
      <div className="personal-welcome-container" data-testid="homeHeader">
        <div className="personal-welcome-message">
          Welcome back,
          <div className="personal-welcome-message-bold">
            &nbsp;
            {profile.firstName}
            !
          </div>
        </div>
        <div className="personal-secondary-message">
          Here are some quick stats and a positive note to start on…
        </div>
      </div>
    )
  }
  return (
    <div data-testid="blankHomeHeader" />
  )
}

HomeHeader.propTypes = {
  profile: PropTypes.shape({
    firstName: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
})

export default connect(mapStateToProps)(HomeHeader)
