/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Panel from '../../Panel/Panel';
import TootipInfo from '../../elements/Tootip/TooltipInfo';
import Button from '../../Form/Button/Button';
import ButtonRow from '../../elements/buttons/ButtonRow/ButtonRow';
import './AdminNotificationConfigurations.less';
import LastEdited from '../../elements/LastEdited/LastEdited';
import {
  SettingsTableWrapper,
  SettingsTableIntro,
  SettingsTable,
  SettingsTableInputTitle,
  SettingsTableRow,
  SettingsTableData,
} from '../../elements/table/SettingsTable/SettingsTable';
import GhostRows from '../../elements/Ghosts/Ghosts';
import { useGetAdminAssistByOrgIdQuery, useUpdateAdminAssistRuleMutation } from '../../../services/assist';
import { IN_TESTING_MODE, TEST_ORGANIZATION_ID } from '../../../tests/constants';
import { showSnackbar } from '../../../actions/notification';
import usePermissionVerify from '../../elements/hooks/usePermissionVerify';
import { FEATURE_FLAG_TOTAL_LOSS_ASSIST } from '../../../constants/permissions';

const AdminNotificationConfigurations = () => {
  const {
    orgId, userId, firstName, lastName,
  } = useSelector(
    (state) => ({
      orgId: IN_TESTING_MODE ? TEST_ORGANIZATION_ID : _.get(state, 'auth.user.organizationId'),
      userId: _.get(state, 'auth.user._id'),
      firstName: _.get(state, 'auth.user.firstName'),
      lastName: _.get(state, 'auth.user.lastName'),
    }),
    shallowEqual,
  );
  const { hasAccess: hasTotalLossEnabled } = usePermissionVerify({
    neededPermissions: [FEATURE_FLAG_TOTAL_LOSS_ASSIST],
  });
  const { data, error, isLoading } = useGetAdminAssistByOrgIdQuery({ orgId, hasTotalLossEnabled });
  const [modifiedSettings, setModifiedSettings] = useState([]);
  const [isModified, setIsModified] = useState(false);
  const [putAdminAssistRule, { isLoading: isPutLoading }] = useUpdateAdminAssistRuleMutation();
  const dispatch = useDispatch();
  const customChromeTooltipText = 'Configurability coming soon. Please note that users will only receive these notifications while they are logged in to Hi Marley and if they have chrome notifications turned on.';

  const snakeToCamelCase = (s) => s.toLowerCase().replace(/(_\w)/g, (w) => w.toUpperCase().substr(1));

  const handleSave = async () => {
    try {
      await putAdminAssistRule({ eventTypeArray: modifiedSettings, orgId }).unwrap();
      dispatch(showSnackbar({ text: 'Settings updated successfully' }));
      setIsModified(false);
    } catch (putError) {
      // eslint-disable-next-line no-console
      console.error('Failed to save changes: ', putError);
      dispatch(showSnackbar({ isError: true, text: 'An error occurred while updating admin settings' }));
    }
  };

  const handleCancel = () => {
    setIsModified(false);
    const inputs = document.querySelectorAll('.form-body-reset');
    inputs.forEach((el) => el.reset());
  };

  const handleChange = (changedEventType, changedChannels, event) => {
    setIsModified(true);
    const channelState = event.target.value;
    const updatedModifiedSettings = modifiedSettings.map((item) => {
      if (item.eventType === changedEventType) {
        const updatedAt = Date.now();
        const updatedByName = `${firstName} ${lastName}`;
        const ch = item.channels.map((c) => {
          if (c.type === changedChannels) {
            return {
              ...c,
              state: channelState,
            };
          }
          return c;
        });
        return {
          ...item,
          updatedAt,
          updatedByName,
          updatedById: userId,
          channels: ch,
        };
      }
      return item;
    });
    setModifiedSettings(updatedModifiedSettings);
  };

  const lastEditedFormatter = (eventArray) => {
    if (!eventArray || !eventArray.length) return {};

    const sortedUpdatedAt = [...eventArray].sort((a, b) => b.updatedAt - a.updatedAt) || [];// sorting the array by the latest update

    const lastUpdated = sortedUpdatedAt[0];
    const newDate = new Date(parseInt(lastUpdated.updatedAt, 10)).toLocaleString('en-US');
    if (newDate === 'Invalid Date') return {};
    return { fullName: lastUpdated.updatedByName, updatedAt: newDate };
  };

  const isSelected = (configIndex, channelIndex, state) => {
    if (data.data[configIndex].channels[channelIndex].state === state) {
      return 'checked';
    }
    return false;
  };

  useEffect(() => {
    if (data && !modifiedSettings.length) {
      setModifiedSettings(data.data);
    }
  }, [data, modifiedSettings, setModifiedSettings]);

  useEffect(() => {
    if (error) {
      dispatch(showSnackbar({ isError: true, text: 'An error occurred while retrieving admin settings' }));
    }
  }, [error]);

  return (
    <Panel
      className="admin-notifications-page"
      title="Notifications"
      header={(
        <div>
          <div data-testid="admin-notifications-intro-text" className="admin-notifications-intro-text">
            <p>Configure the settings of notifications for users within the organization. For each setting, select whether to set it as Required </p>
            <TootipInfo message="Turns notification on for all users (can not be turn off)." />
            , Optional
            <TootipInfo message="Users can choose to turn notification on or off." />
            , Or Disabled
            <TootipInfo message="Turns off and deactivates notification for users." />
          </div>
          {error && (
            <div data-testid="admin-notifications-server-error-container" />
          )}
          {isLoading && (
            <div data-testid="ghost-rows-container">
              <GhostRows type="card" />
            </div>
          )}
          {data && (
            <div data-testid="admin-notifications-container" className="admin-notifications-container">
              {lastEditedFormatter(data.data).updatedAt && <LastEdited fullName={lastEditedFormatter(data.data).fullName} dateAndTime={lastEditedFormatter(data.data).updatedAt} />}
              {data.data.map((config, configIndex) => (
                <form key={config.eventType} data-testid={`admin-notification-${snakeToCamelCase(config.eventType)}`} className="form-body-reset">
                  <SettingsTableWrapper>
                    <SettingsTableIntro title={config.eventTypeHeader} intro={config.eventTypeDescription} />
                    <SettingsTable>
                      <SettingsTableInputTitle inputTitle={['Required', 'Optional', 'Disabled']} />
                      {config.channels.map((channel, channelIndex) => (
                        <SettingsTableRow key={channel.type}>
                          <SettingsTableData width="large">
                            {channel.displayName}
                            {channel.state === 'notConfigurable' && <TootipInfo message={channel.type === 'chrome' ? customChromeTooltipText : 'Configurability coming soon.'} />}
                          </SettingsTableData>
                          {['required', 'optional', 'disabled'].map((channelOption) => (
                            <SettingsTableData key={`${channelOption}-${snakeToCamelCase(config.eventType)}`} textAlign="center">
                              <input
                                aria-label={channelOption}
                                type="radio"
                                value={channelOption}
                                name={`${channel.type}-${snakeToCamelCase(config.eventType)}`}
                                data-testid={`radio-${snakeToCamelCase(config.eventType)}-${channel.type}-${channelOption}`}
                                disabled={channel.state === 'notConfigurable'}
                                onChange={(event) => handleChange(config.eventType, channel.type, event)}
                                defaultChecked={channel.state === 'notConfigurable' && channelOption === 'required' && channel.type === 'chrome' ? 'checked' : isSelected(configIndex, channelIndex, channelOption)}
                              />
                            </SettingsTableData>
                          ))}
                        </SettingsTableRow>
                      ))}
                    </SettingsTable>
                  </SettingsTableWrapper>
                </form>
              ))}
              {isModified && (
                <div data-testid="admin-notifications-modify-buttons">
                  <ButtonRow>
                    <Button type="whiteRounded" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button disabled={isPutLoading} type="darkBlueRounded" onClick={handleSave}>
                      {isPutLoading ? 'Loading' : 'Save'}
                    </Button>
                  </ButtonRow>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default AdminNotificationConfigurations;
