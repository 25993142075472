/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { DropdownButton, MenuItem } from 'react-bootstrap';

import './AssignDropdown.less';

const AssignDropdown = ({
  header,
  footer,
  dropsRight,
  disabled,
  error,
  open,
  onToggle,
  className,
  selectedId,
  options,
  rowId,
  id,
  defaultLabel,
  handleSelect,
  searchBar,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedItem = options.find((option) => option.id === selectedId);
  const additionalProps = {};
  if (open) additionalProps.open = open;
  if (onToggle) additionalProps.onToggle = onToggle;

  const componentRef = useRef(null);

  // Adds listeners to detect clicks outside of the component
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClick, true);
    } else {
      document.removeEventListener('click', handleClick, true);
    }
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [isOpen]);

  const handleClick = (e) => {
    if (componentRef.current && componentRef.current.contains(e.target)) return; // click inside the component
    setIsOpen(false); // click outside the component
  };
  return (
    <div ref={componentRef} data-testid={`${type}-dropdown-menu`}>
      <DropdownButton
        id={id}
        data-testid={type ? `${type}-dropdown` : 'btn-dropdown-action'}
        data-toggle="other"
        className={`${className} ${
          error && 'has-error'
        } btn-dropdown btn-dropdown-action`}
        disabled={disabled}
        pullRight={!dropsRight}
        title={selectedItem ? selectedItem.label : defaultLabel}
        onSelect={(e) => {
          handleSelect(e, rowId, id);
          if (!searchBar) setIsOpen(!isOpen);
        }}
        {...additionalProps}
        open={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {header}
        {options
          && options.map(
            (queue) => queue.id
              && queue.id !== selectedId && (
                <MenuItem
                  data-dropdown-item={`item-${queue.id}`}
                  key={queue.id}
                  eventKey={queue.id}
                  className={`dropdown-item-${queue.id} ${queue?.label}`}
                  disabled={queue.disabled}
                  onSelect={(e) => {
                    if (queue.onSelect) {
                      if (e.preventDefault) e.preventDefault();
                      queue.onSelect(e);
                    }
                  }}
                >
                  {queue.icon}
                  {queue.label}
                </MenuItem>
            ),
          )}
        {footer}
      </DropdownButton>
    </div>
  );
};

AssignDropdown.propTypes = {
  options: PropTypes.arrayOf(Object),
  rowId: PropTypes.string,
  id: PropTypes.string,
  defaultLabel: PropTypes.string,
  disabled: PropTypes.bool,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSelect: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.bool,
};

AssignDropdown.defaultProps = {
  options: [],
  error: false,
  id: '',
  className: '',
  disabled: false,
  handleSelect: () => {},
  rowId: undefined,
  defaultLabel: '',
  selectedId: undefined,
};

export default AssignDropdown;
