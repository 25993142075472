import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { changePassword, setAuthError } from '../../../actions/auth'
import FormField from '../../Form/FormField/FormField'
import Button from '../../Form/Button/Button'
import { PASSWORD_MIN_LENGTH } from '../../../constants/general'
import {
  CHANGE_PASSWORD_TYPED_ERROR, CHANGE_PASSWORD_TOO_SHORT_ERROR,
  CHANGE_PASSWORD_OLD_NEW_MATCH_ERROR, CHANGE_PASSWORD_REPEAT_MATCH_ERROR,
} from '../../../constants/apierrors'

import './ChangePassword.less'

class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.changePassword = this.changePassword.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getSavePasswordMessage = this.getSavePasswordMessage.bind(this)
    this.getSavePasswordButton = this.getSavePasswordButton.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.setState({})
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.passwordIsChanged !== false || nextProps.error !== false) {
      this.cleanStatusTimer = setTimeout(() => this.props.cleanSavePasswordMessage(), 5000)
    }
  }

  componentWillUnmount() {
    if (this.cleanStatusTimer) {
      clearTimeout(this.cleanStatusTimer)
    }
  }

  getSavePasswordButton() {
    if (this.props.pendingRequest) {
      return (
        <Button
          className="change-password-button"
          type="pending"
        ><Translate value="common.saving" />
        </Button>)
    } else {
      return (
        <Button
          className="change-password-button"
          onClick={this.changePassword}
        ><Translate value="common.changePass" />
        </Button>)
    }
  }

  getSavePasswordMessage() {
    if (this.props.passwordIsChanged) {
      return (
        <div className="sucsess-change-password-saved">
          <Translate value="changePass.changeSuccess" />
        </div>)
    } else if (this.props.error) {
      return (
        // eslint-disable-next-line react/no-unknown-property
        <div className="error-change-password-saved" bsStyle="danger">
          <Translate value={this.props.error} />
        </div>)
    } else {
      return null
    }
  }

  handleChange(update) {
    this.setState(update)
    this.props.setAuthError(false)
  }

  changePassword() {
    const { oldPassword, newPassword, repeatPassword } = this.state
    if (!oldPassword || !newPassword || !repeatPassword) {
      this.props.setAuthError(CHANGE_PASSWORD_TYPED_ERROR)
    } else if (newPassword.length < PASSWORD_MIN_LENGTH) {
      this.props.setAuthError(CHANGE_PASSWORD_TOO_SHORT_ERROR)
    } else if (newPassword === oldPassword) {
      this.props.setAuthError(CHANGE_PASSWORD_OLD_NEW_MATCH_ERROR)
    } else if (newPassword !== repeatPassword) {
      this.props.setAuthError(CHANGE_PASSWORD_REPEAT_MATCH_ERROR)
    } else {
      this.props.changePassword({ oldPassword, newPassword })
    }
  }

  render() {
    return (
      <div className="change-password">
        <div className="change-password-header">
          <Translate value="common.changePass" />
        </div>
        {/* this is hack to make Chrome disable autocomplete for password */}
        <form autoComplete="off">
          <input id="username" style={{ display: 'none' }} type="text" name="fakeusernameremembered" />
          <input id="password" style={{ display: 'none' }} type="password" name="fakepasswordremembered" />
          <div className="change-password-form">
            <FormField
              label="Old password"
              value={this.state.oldPassword}
              onChange={e => this.handleChange({ oldPassword: e.target.value })}
              type="password"
              required
              autoComplete="nope"
            />
            <FormField
              label="New password"
              value={this.state.newPassword}
              onChange={e => this.handleChange({ newPassword: e.target.value })}
              type="password"
              required
              autoComplete="nope"
            />
            <FormField
              label="Repeat password"
              value={this.state.repeatPassword}
              onChange={e => this.handleChange({ repeatPassword: e.target.value })}
              type="password"
              required
              autoComplete="nope"
            />
          </div>
        </form>
        <div className="change-password-message-button">
          {this.getSavePasswordMessage(this.props)}
          {this.getSavePasswordButton(this.props)}
        </div>
      </div >
    )
  }
}

ChangePassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  setAuthError: PropTypes.func.isRequired,
  cleanSavePasswordMessage: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  passwordIsChanged: PropTypes.bool.isRequired,
  pendingRequest: PropTypes.bool.isRequired,
}

const mapDispatchToProps = {
  changePassword,
  setAuthError,
  cleanSavePasswordMessage:undefined,
}

const mapStateToProps = state => state.auth

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
