import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { BROADCAST_CHANNEL } from '../../constants/general';

const useCloseBroadcastChannel = () => {
  const location = useLocation();

  useEffect(() => {
    const channel = new BroadcastChannel(BROADCAST_CHANNEL);
    const onChatsPage = location?.pathname.split('/').includes('chats');
    if (!onChatsPage) {
      channel.close();
    }
  }, [location?.pathname]);
};

export default useCloseBroadcastChannel;
