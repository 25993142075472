// eslint-disable-next-line import/prefer-default-export
export const REFINE_BY = {
  NONE: null,
  UNREAD_MESSAGES: 'hasUnreadMessages',
  UNANSWERED_OPT_INS: 'unansweredOptIns',
  MENTIONS: 'mentions',
};

export const REFINE_BY_ITEMS = [
  {
    label: 'All Messages',
    value: REFINE_BY.NONE,
  },
  {
    label: 'Only Unread Messages',
    value: REFINE_BY.UNREAD_MESSAGES,
  },
  {
    label: 'Only Unanswered Opt-Ins',
    value: REFINE_BY.UNANSWERED_OPT_INS,
  },
  {
    label: 'Only My Mentions/Replies',
    value: REFINE_BY.MENTIONS,
  },
];
