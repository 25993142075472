import { getAccessToken } from './localStorage';

const secureRequest = async (url, options) => fetch(url, {
  ...options,
  headers: {
    ...options.headers,
    Authorization: (await getAccessToken()),
  },
});

export default secureRequest;
