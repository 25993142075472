import { apiCall } from './api'
import { PUT_CHAT_PARTICIPANT } from '../constants/endpoints'
import {
  CHANGE_FIRST_NAME,
  CHANGE_LAST_NAME,
  CHANGE_PHONE_NUMBER,
  CHANGE_RELATIONSHIP,
  SET_PUT_CHAT_PARTICIPANT_STATUS,
  FRAGMENT_PUT_CHAT_PARTICIPANT,
  SET_CHAT_REMOVE_USER_STATUS,
} from '../constants/participants'

export const changePhoneNumber = (phoneNumber) => (dispatch) => dispatch({
  type: CHANGE_PHONE_NUMBER,
  phoneNumber,
})

export const changeFirstName = (firstName) => (dispatch) => dispatch({
  type: CHANGE_FIRST_NAME,
  firstName,
})

export const changeLastName = (lastName) => (dispatch) => dispatch({
  type: CHANGE_LAST_NAME,
  lastName,
})

export const changeRelationship = (relationship) => (dispatch) => dispatch({
  type: CHANGE_RELATIONSHIP,
  relationship,
})

export const putChatParticipant = ({
  chatId,
  endUserId,
  relationship,
  firstName,
  lastName,
  phoneNumber,
}) => {
  const args = {
    endpoint: PUT_CHAT_PARTICIPANT(chatId),
    action: FRAGMENT_PUT_CHAT_PARTICIPANT,
    method: 'PUT',
    body: {
      endUserId,
      relationship,
      firstName,
      lastName,
      phoneNumber,
    },
  }
  return apiCall({ args })
}

export const setPutChatParticipantStatus = (status) => (dispatch) => dispatch({
  type: SET_PUT_CHAT_PARTICIPANT_STATUS,
  status,
})

export const setChatRemoveUserStatus = (status) => (dispatch) => dispatch({
  type: SET_CHAT_REMOVE_USER_STATUS,
  status,
})
