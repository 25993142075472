import { useState } from 'react'

/**
 * useDynamicValue
 * hook to allow dynamic insertion into text input
 * @param {ref} input ref of input
* */
const useDynamicValue = (args) => {
  const { input } = args || {}
  const [position, setPosition] = useState(false)

  const startRecord = () => {
    setPosition(input.current.selectionStart)
  }

  const makeSelection = () => {
    const { value } = input.current
    const selection = value.substr(position + 1,
      input.current.selectionStart - position - 1)
    return selection
  }

  const replaceSelection = (replaceString) => {
    const { value } = input.current
    const returnValue = value.substring(0, position) + replaceString
          + value.substring(input.current.selectionStart)
    setTimeout(() => {
      input.current.selectionStart = returnValue.length
      input.current.selectionEnd = returnValue.length
    })
    return returnValue
  }

  return { startRecord, makeSelection, replaceSelection }
}

export default useDynamicValue
