/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */

import { GET_SIGNED_URL_FOR_MEDIA_GET } from '../constants/endpoints';
import { uploadMedia } from './media';
import { updateProfile } from './profile';
import { doRequest } from './api';
import { FEATURE_FLAG_VIRUS_SCANNING } from '@app/constants/permissions';

export const VALID_AVATAR_IMAGE_FILE_TYPES = [
  'image/jpeg',
  'image/png',
];

export const AVATAR_IMAGE_MEGABYTE_LIMIT = 5;

const triggerGeneralProfileUploadFailureToast = (toast, toastIdRef) => {
  if (toastIdRef?.current) {
    toast.update(toastIdRef.current, {
      title: 'Profile Image Upload Failed',
      description: 'Failed to upload file. Please try again.',
      status: 'error',
      duration: 5000,
    });
  }
}

const saveProfileImage = async (store, url, key, toast, toastIdRef, dispatch) => {
  const imageUrl = `${url}${key}`;
  const profile = {
    _id: store.profile?._id,
    email: store.profile?.email,
    oldEmail: store.profile?.email, // required to use route
    ...{
      firstName: store.profile?.firstName,
      lastName: store.profile?.lastName,
      phoneNumber: store.profile?.phoneNumber,
      contactNumber: store.profile?.contactNumber,
    },
    imageUrl,
  };
  await updateProfile(profile)(dispatch);

  // Succes Toast
  if (toastIdRef?.current) {
    toast.update(toastIdRef.current, {
      description: 'Upload 100% · Avatar Photo Saved.',
      status: 'success',
      duration: 5000,
    });
  }

  // Update Redux so current browser session gets new image
  dispatch({
    type: 'REQUEST_UPLOAD_PROFILE_IMAGE_SUCCESS',
    payload: {
      imageUrl,
    },
  });
}

const DELAY_IN_SECONDS = 5;

const pollForVirusScanningStatus = (store, url, key, toast, toastIdRef, dispatch) => {
  let intervalId = null;
  const maxAttempts = 3;
  let attempts = 1;
  intervalId = setInterval(async () => {
    try {
      // Call shortcode endpoint to get virus scanning status
      const response = await doRequest({
        endpoint: GET_SIGNED_URL_FOR_MEDIA_GET(key),
        method: 'GET',
      });
      if (response?.virusScanStatus === 'VIRUS_SCAN_QUEUED') {
        return null;
      }
      await saveProfileImage(store, url, key, toast, toastIdRef, dispatch);
      clearInterval(intervalId);
    } catch (e) {
      console?.error(e);
      if (e?.virusScanStatus === 'VIRUS_SCAN_INFECTED') {
        if (toastIdRef?.current) {
          toast.update(toastIdRef.current, {
            title: 'File Blocked',
            description: 'Our system detected a virus and blocked the file.',
            status: 'error',
            duration: 5000,
          });
        }
      } else {
        triggerGeneralProfileUploadFailureToast(toast, toastIdRef);
      }
      clearInterval(intervalId);
      throw new Error('Failed fetching the virus scanning status for the profile image upload.');
    } finally {
      attempts += 1;
      if (attempts > maxAttempts) {
        toast.update(toastIdRef.current, {
          title: 'Unable to get virus scan status',
          description: `No virus status was found after ${maxAttempts * DELAY_IN_SECONDS} seconds, cancelling upload.`,
          status: 'warning',
          duration: 5000,
        });
        clearInterval(intervalId);
      }
    }
  }, DELAY_IN_SECONDS * 1000);
}

export const uploadProfileImage = (file, toast, toastIdRef) => async (dispatch, getStore) => {
  dispatch({ type: 'REQUEST_UPLOAD_PROFILE_IMAGE_INIT' });
  let uploadResult = {}
  try {
    uploadResult = await uploadMedia(
      file,
      VALID_AVATAR_IMAGE_FILE_TYPES,
      AVATAR_IMAGE_MEGABYTE_LIMIT,
    );
  } catch (e) {
    const error = {
      title: 'File Not Allowed',
      description: 'For more information on the files we support, please visit the help doc: https://himarley.zendesk.com/hc/en-us/articles/360050427254-Outbound-Media',
      status: 'error',
      duration: 5000,
    };
    if (e.message === 'EntityTooLarge') {
      error.title = 'File Too Large';
      error.description = `File must be under ${AVATAR_IMAGE_MEGABYTE_LIMIT} MB.`;
    } else if (e.message === 'Invalid file type') {
      error.title = 'Format Not Allowed';
      error.description = 'File must be JPG or PNG format.';
    }
    if (toastIdRef?.current) {
      toast.update(toastIdRef.current, error);
    }
    return;
  }
  const { key, url } = uploadResult;
  if (!key || !url) {
    triggerGeneralProfileUploadFailureToast(toast, toastIdRef);
    return;
  }

  const store = getStore();
  const permissions = store?.auth?.permissions || [];
  const hasVirusScanningEnabled = permissions.includes(FEATURE_FLAG_VIRUS_SCANNING);

  if (hasVirusScanningEnabled) {
    toast.update(toastIdRef.current, {
      description: 'Running virus scan ...',
      status: 'loading',
    });
    pollForVirusScanningStatus(store, url, key, toast, toastIdRef, dispatch);
  } else {
    // Regular flow to save new image url to mongo and update the browser session
    await saveProfileImage(store, url, key, toast, toastIdRef, dispatch);
  }
};
