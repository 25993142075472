import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { closeForm } from '../../../actions/ui'

const EnterModal = ({ types, title, children, showModal, closeForm, dialogClassName }) => (
  <Modal
    show={showModal}
    onHide={() => { types.forEach(t => closeForm(t)) }}
    dialogClassName={dialogClassName}
  >
    <div className="header-modal">
      <div className="header">{title}</div>
      <button
          className="cls-btn"
          onClick={
          () => {
            types.forEach(t => closeForm(t))
          }
        }
        />
    </div>
    <div className="body-modal">
        {children}
      </div>
  </Modal>
)


export default connect(null, { closeForm })(EnterModal)
