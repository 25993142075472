import React from 'react';
import LogoDuckCreek from '../../../../../images/logos/logoDuckCreek.svg';
import LogoGuidewire from '../../../../../images/logos/logoGuidewire.svg';
import LogoSapiens from '../../../../../images/logos/logoSapiens.svg';

import Panel from '../../../Panel/Panel';
import './AppIntegrations.less';
import Integration from './IntegrationRow'

const AppIntegrations = () => (
  <Panel className="group-mgmt-page" title="App Integrations">
    <Integration
      Logo={LogoDuckCreek}
      title="Duck Creek Technologies"
      description="appIntegrations.duckCreekDescription"
      link="https://himarley.zendesk.com/hc/en-us/articles/9701957604627-Duck-Creek-Integration"
    />
    <Integration
      Logo={LogoGuidewire}
      title="Guidewire"
      description="appIntegrations.guidewireDescription"
      link="https://himarley.zendesk.com/hc/en-us/articles/9377706096403-Guidewire-Integrations-Overview9:06"
    />
    <Integration
      Logo={LogoSapiens}
      title="Sapiens"
      description="appIntegrations.sapiensDescription"
      link="https://himarley.zendesk.com/hc/en-us/articles/9701933326739-Sapiens-Integration-Overview9:07"
    />
  </Panel>
)

export default AppIntegrations;
