export const LOB_TYPES = ['personal', 'commercial', 'other'];
export const LOB_SUB_TYPES = ['auto', 'home', 'property', 'general-liability', 'workers-comp', 'crop', 'life', 'other'];
export const CASE_TYPES = ['general case', 'claim', 'policy'];
export const PRIVATE = 'private';

export const PRIVATE_REDACTED_ALLOWED_TAGS = [
  PRIVATE,
  ...CASE_TYPES,
  ...LOB_TYPES,
  ...LOB_SUB_TYPES,
];
