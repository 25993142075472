/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { GET_OPERATORS_INFO_BY_ID } from '../constants/endpoints';
import secureRequest from './secure-request';

export const getJsonFromUrl = (search) => {
  const result = {};
  if (search) {
    const query = search.slice(1);
    query.split('&').forEach((part) => {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
  }
  return result;
};

export const localToRemoteUrl = (url, host = window.location.origin) => {
  if (!url || (url && url.indexOf('http') === 0)) {
    return url;
  }
    return (host || '') + url // eslint-disable-line

};

export const s3toPipedUrl = (url) => {
  let mediaUrl = '';
  if (url?.includes('/')) {
    const fileName = url.substring(url.lastIndexOf('/') + 1, url.length);
    mediaUrl = fileName ? `/api/newmedia/${fileName}` : url;
  } else {
    mediaUrl = url;
  }
  return mediaUrl;
};

export const encodedUri = (endpointFunction, item) => {
  const encodedUrlString = endpointFunction(encodeURIComponent(item));
  return encodedUrlString;
};

export const queryParameters = {
  operatorIds: 'operatorIds[]',
  groupIds: 'groupIds[]',
  tags: 'tags[]',
  myChats: 'myChats',
  unassigned: 'unassigned',
  search: 'search',
  secondaryOperatorIds: 'My Secondary Assignments',
  refineBy: 'refineBy',
  sort: 'sort',
  order: 'order',
};

const getOperatorsInfo = async (operatorIds) => {
  let operators = [];
  const endpoint = `${GET_OPERATORS_INFO_BY_ID}?operatorIds=${operatorIds}`;
  try {
    const response = await secureRequest(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    operators = await response?.json() || [];
  } catch (err) {
    console.log(err);
  }
  return operators;
};

const getOperatorNames = async (urlOperatorIds, currentOperatorIds) => {
  const operators = {};
  const nullOperators = [];
  for (const operatorId of urlOperatorIds) {
    if (currentOperatorIds[operatorId]) {
      operators[operatorId] = currentOperatorIds[operatorId];
    } else {
      nullOperators.push(operatorId);
    }
  }
  const filledOperatorData = {};
  if (nullOperators.length > 0) {
    const operatorData = await getOperatorsInfo(nullOperators);
    for (const { _id, profile } of operatorData) {
      filledOperatorData[_id] = `${profile?.firstName} ${profile?.lastName}`;
    }
  }
  return { ...operators, ...filledOperatorData };
};

export const generateFiltersFromQueryParams = async ({
  operatorIds: operators = [],
  groupIds = [],
  tags = [],
  myChats,
  unassigned,
  secondaryOperatorIds,
  refineBy = null,
  sort,
  order,
}, inboxFilters) => {
  const operatorIds = await getOperatorNames(operators, inboxFilters?.operatorIds || {});

  const groups = {};
  groupIds.forEach((id) => {
    groups[id] = null;
  });

  const items = {};
  if (myChats) {
    items.myChats = myChats;
  }

  if (unassigned) {
    items.unassigned = unassigned;
  }
  if (secondaryOperatorIds) {
    items.secondaryOperatorIds = secondaryOperatorIds;
  }

  return {
    operatorIds,
    groups,
    items,
    tags,
    refineBy,
    sort,
    order,
  };
};
