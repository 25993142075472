import classnames from 'classnames';
import React from 'react';

import LoadingIcon from '@app/components/icons/LoadingIcon';
import type { MessageTemplate } from '@app/types/message-template';

import styles from './styles/preview-messages.module.scss';

interface PreviewMessagesProps {
  messages: MessageTemplate[];
  previewLabel: string | JSX.Element;
  className?: string;
  isLoading?: boolean;
  previewBanner?: JSX.Element | null;
}

/**
 * Component to display preview messages with a loading state and optional banner.
 *
 * @param {PreviewMessagesProps} props - The props for the component.
 * @param {MessageTemplate[]} props.messages - Array of message templates to display.
 * @param {string | JSX.Element} props.previewLabel - Label to display above the messages.
 * @param {string} [props.className] - Additional class name for custom styling.
 * @param {boolean} [props.isLoading] - Flag to show loading state.
 * @param {JSX.Element} [props.previewBanner] - Optional banner to display above the messages.
 *
 * @returns {JSX.Element} - The rendered preview messages component.
 */
const PreviewMessages = ({
  messages,
  previewLabel,
  className = undefined,
  isLoading = false,
  previewBanner = undefined,
}: PreviewMessagesProps): JSX.Element => (
  <div className={classnames(styles.previewWrap, className)} data-testid="previewMessages">
    {previewLabel && <div className={styles.previewLabel}>{previewLabel}</div>}
    <div className={`previewMessagesBox ${styles.previewMessagesBox}`}>
      {isLoading ? (
        <div className={styles.loadingNotification}>
          <div className="loading none-found-icon">
            <LoadingIcon size="lg" />
          </div>
          <div>Loading Preview</div>
        </div>
      ) : (
        <>
          {previewBanner}
          {messages.map((message, index) => (
            message.rawMessage !== '' && (
              <div
                className={
                  message.isSent ? 'sentTextWrap' : styles.receivedTextWrap
                }
                key={message.displayName}
              >
                <div
                  className={
                    message.isSent ? styles.sentText : styles.receivedText
                  }
                >
                  <strong>{message.name}</strong>
                  <div data-testid={`text-message-${index}`}>
                    {message.previewMessage}
                  </div>
                </div>
              </div>
            )))}
        </>
      )}
    </div>
  </div>
);

export default PreviewMessages;
