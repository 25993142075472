import React from 'react';
import MarleyIcon from '../icons/MarleyIcon';
import Button from '../elements/buttons/Button/Button';
import styles from './ErrorSplashScreen.module.less';

const ErrorSplashScreen = () => {
  const handleClickBackToHome = (event) => {
    event.preventDefault();
    window.location.href = '/';
  };

  return (
    <div className={styles['splash-screen']}>
      <div className={styles.logo}><MarleyIcon /></div>
      <div className={styles.body}>
        <div className={`${styles.message} ${styles.error}`}>
          Unexpected Error - Please contact support@himarley.com
        </div>
        <Button onClick={handleClickBackToHome}>Back to Home</Button>
      </div>
    </div>
  );
};

export default ErrorSplashScreen;
