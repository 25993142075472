/* eslint-disable no-underscore-dangle */
import _ from 'lodash'
import { OVERRIDE_CASE_VISIBILITY } from '../../constants/permissions'

export const buildList = (idList) => {
  const list = []
  Object.keys(idList).forEach((id) => list.push(idList[id]));
  return list
}

export const buildIdList = (list) => ({
  listById: list.reduce((acc, item) => {
    acc[item.id] = item
    return acc
  }, {}),
})

export const updateListItemById = (list, newItem) => _.map(list, (item) => (
  (item.id === newItem.id) ? newItem : item));

export const isJobPrivacy = (job, privacyValue) => {
  if (!job || !_.has(job, 'privacy')) return false
  const privacy = _.get(job, 'privacy')
  return !!privacy.includes(privacyValue)
}

export const isVisibleToOperator = (job, operatorId, permissions) => {
  const isVisible = job.visibleTo.some((visObj) => (visObj.type === 'user') && (visObj.id === operatorId));
  const isAdmin = permissions.includes(OVERRIDE_CASE_VISIBILITY)

  return (isVisible || isAdmin)
}

export const incrementJobNeedsAttention = (job, increment) => {
  const needsAttentionMessageCount = _.get(job, 'needsAttentionMessageCount')
    ? job.needsAttentionMessageCount + increment
    : increment
  return {
    ...job,
    needsAttentionMessageCount,
  }
}

export const incrementJobNeedsAction = (job, increment, modelName) => {
  const { needsAction } = job;
  needsAction[modelName] = increment;
  return {
    ...job,
    needsAction,
  };
};

export const handleImageRedaction = (message, redactionData) => {
  const redactedText = `Redacted Image - ${message?._id || message?.id}`;
  return {
    ...message,
    isImageRedacted: true,
    body: redactedText,
    redactedBody: redactedText,
    redactionData,
  };
};

export const updateRedactionViewedEvents = (currentEvents, authUser) => {
  const newRedactionViewEvent = {
    userId: authUser?._id,
    operatorName: `${authUser?.firstName} ${authUser?.lastName}`,
    eventTime: new Date().getTime(),
  };
  return [...currentEvents, newRedactionViewEvent];
};
