// scans tokens in string, adds metadata to tokens so highlighting can be applied
export const constructHighlightedText = (
  inputStr = '',
  tokenSet = new Set(),
) => {
  if (!inputStr.trim().length > 0) {
    return '';
  }
  let isToken = false;
  let token = '';
  let result = '';
  for (let i = 0; i < inputStr.length; i += 1) {
    const char = inputStr[i];
    result += char;
    // start of token check
    if (char === '$') {
      isToken = true;
      token += char;
      continue;
    }
    if (isToken) {
      if (char.match(/[a-z]/i)) {
        token += char;
      } else {
        isToken = false;
        token = '';
        continue;
      }
      // check if end of token -> do replacement
      if (tokenSet.has(token)) {
        const tokenReplacement = `@[${token}](${token})`;
        result = `${result.substring(
          0,
          result.length - token.length,
        )}${tokenReplacement}`;
      }
    }
  }
  return result;
};
