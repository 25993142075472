/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '@chakra-ui/react';
import MultiStateButton from '../Form/MultiStateButton/MultiStateButton';
import { pluralize } from '../../helpers/common';
import './EnterForm.less';
import { setEditMode, createCase } from '../../actions/job';
import { clearAddFormErrors, addFormError, updateItem } from '../../actions/common';
import { clearFormErrors, resetEditObj, createUser } from '../../actions/users';

class AddForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.getSaveButtonText = this.getSaveButtonText.bind(this);

    const templateObject = (props.editObject && { ...props.editObject }) || {};
    this.state = {
      hasEdits: false,
      disableClick: false,
      templateObject,
      isEdit: props.editObject && props.editObject.id && true,
    };
  }

  componentDidMount() {
    const reducerCase = this.props.type ? this.props.type.reducerCase() : this.props.id;
    this.props.clearAddFormErrors(reducerCase);
  }

  componentWillUnmount() {
    const reducerCase = this.props.type ? this.props.type.reducerCase() : this.props.id;
    this.props.resetEditObj(reducerCase);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const hasFormObjectChanged = nextProps.editObject !== this.props.editObject;
    const hasUserObjectChanged = nextProps.userEditObject !== this.props.userEditObject;

    const stateChanges = {
      hasEdits: true,
      templateObject: nextProps.editObject,
      disableClick: false,
      requesting: false,
    };

    if (hasFormObjectChanged || hasUserObjectChanged) {
      this.setState(stateChanges);
    }
  }

  getSaveButtonText() {
    if (_.get(this.props, 'isEdit')) {
      return _.get(this.props, 'updateButtonText', `Update ${this.props.title}`);
    } if (!_.get(this.props, 'userEditObject.id')) {
      return _.get(this.props, 'createButtonText', `Create ${this.props.title}`);
    }
    return `Create ${this.props.title}`;
  }

  handleSubmitClick(e) {
    e.preventDefault();
    const { disableClick } = this.state;
    const { preventSubsequentClicks } = this.props;
    this.props.clearFormErrors();
    if (!preventSubsequentClicks || !disableClick) {
      const submitAction = () => {
        let isValid = true;
        const reducerCase = this.props.type ? this.props.type.reducerCase() : this.props.id;
        if (reducerCase) {
          this.props.clearAddFormErrors(reducerCase);
        }

        const templateInfo = this.state.templateObject;

        // check --> email, firstName, lastName, and phoneNumber
        if (this.props.validation) {
          const invalidity = this.props.validation(this.state, this.props);
          if (invalidity) {
            invalidity.forEach((i) => {
              const errorReducerCase = i.reducerCase || reducerCase;
              this.props.addFormError(errorReducerCase, i.reducerCase ? i.error : i);
              isValid = false;
            });
          }
        }

        // check that regex is satisfied
        const { regexFields } = this.props;
        if (regexFields) {
          regexFields.forEach((f) => {
            const key = f.id;
            if (templateInfo[key]) {
              if (!f.regexCheck.test(templateInfo[key])) {
                this.props.addFormError(reducerCase, key);
                isValid = false;
              }
            }
          });
        }

        // check that all required fields are present
        const { requiredFields } = this.props;
        if (requiredFields) {
          requiredFields.forEach((reqField) => {
            if (!templateInfo[reqField] || templateInfo[reqField] === '') {
              this.props.addFormError(reducerCase, reqField);
              isValid = false;
            }
          });
        }

        // eslint-disable-next-line no-empty
        if (!isValid) {
        } else if (this.state.isEdit) {
          const body = (this.props.prepPut && this.props.prepPut(templateInfo))
            || templateInfo;

          if (this.props.updateFunc) {
            this.props.updateFunc(body);
          } else {
            this.props.updateItem(reducerCase, body);
          }
        } else {
          const body = (this.props.prepPost && this.props.prepPost(templateInfo))
            || templateInfo;
          if (this.props.createFunc) {
            this.props.createFunc(body);
          } else if (this.props.title === 'Operator') {
            this.props.createUser(body);
          } else {
              this.props.createCase(reducerCase, body) // eslint-disable-line
          }
        }
      };
      this.setState({ requesting: true, disableClick: true }, submitAction);
    }
  }

  render() {
    const {
      id, process, hideButton, onBack, clearFormErrors, preventSubsequentClicks,
    } = this.props;
    const localRequesting = _.get(this.state, 'requesting', false);
    const { requesting, success, error } = (process || {});

    let currentStateId = 'preRequest';
    if (requesting) {
      currentStateId = 'requesting';
    } else if (success) {
      currentStateId = 'success';
    } else if (error) {
      currentStateId = 'error';
    }
    const backAndClearErrors = () => {
      onBack();
      clearFormErrors();
    };
    const submitButtonStates = {
      preRequest: {
        type: 'primary',
        text: this.getSaveButtonText(),
      },
      requesting: {
        type: 'secondary',
        text: 'Saving...',
        noClick: true,
      },
      success: {
        type: 'primary',
        text: 'Saved',
        noClick: true,
      },
      error: {
        type: 'primary',
        text: this.getSaveButtonText(),
      },
    };

    const isButtonDisabled = (preventSubsequentClicks && localRequesting) || this.props.disabled;

    const renderButton = () => (
      (this.props.processId)
        ? (
          <MultiStateButton
            data-button="enter-form-submit"
            onClick={this.handleSubmitClick}
            preventSubsequentClicks={preventSubsequentClicks}
            currentStateId={currentStateId}
            states={submitButtonStates}
            isDisabled={isButtonDisabled}
          />
        )
        : (
          <Button
            data-button="enter-form-submit"
            onClick={this.handleSubmitClick}
            isDisabled={isButtonDisabled}
          >
            {this.getSaveButtonText()}
          </Button>
        )
    );

    return (
      <div className={`enter-${id}-view enter-form`}>
        <div className={`enter-${id}-form-area add-form-area`}>
          <div autoComplete="new-password" onSubmit={this.handleSubmitClick}>
            {this.props.children}
            { (this.props.isEdit || this.props.isCreateOperatorForm) && (
            <div className="error-message">
              {this.props.error.message}
            </div>
            )}
            <div className={id ? `enter-${id}-row` : ''}>
              <div className="button-row">
                {this.props.readonly && (
                  <Button type="neutral" onClick={this.props.setEditMode}>{`Edit ${id.charAt(0).toUpperCase()}${id.slice(1)}`}</Button>
                )}
                {!this.props.readonly && (
                <div style={{ display: 'flex', marginBotton: 20 }}>
                  {this.props.onBack
                    && <Button data-button="enter-form-back" type="outline" onClick={backAndClearErrors}>Back</Button>}
                  {!hideButton && renderButton()}
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddForm.propTypes = {
  addFormError: PropTypes.func.isRequired,
  clearAddFormErrors: PropTypes.func.isRequired,
  createCase: PropTypes.func.isRequired,
  createUser: PropTypes.func,
  setEditMode: PropTypes.func.isRequired,
  prepPost: PropTypes.func.isRequired,
  validation: PropTypes.func,
  readonly: PropTypes.bool.isRequired,
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(Object).isRequired,
  updateItem: PropTypes.func.isRequired,
  createFunc: PropTypes.func,
  prepPut: PropTypes.func.isRequired,
  updateFunc: PropTypes.func,
  editObject: PropTypes.instanceOf(Object).isRequired,
  regexFields: PropTypes.arrayOf(Object).isRequired,
  requiredFields: PropTypes.arrayOf(Object).isRequired,
  isCreateOperatorForm: PropTypes.bool,
};

AddForm.defaultProps = {
  id: '',
  updateFunc: undefined,
  isCreateOperatorForm: false,
  validation: undefined,
  createFunc: undefined,
  regexFields: [],
};

const mapDispatchToProps = {
  clearAddFormErrors,
  addFormError,
  createCase,
  updateItem,
  setEditMode,
  clearFormErrors,
  resetEditObj,
  createUser,
};

const mapStateToProps = (state, props) => {
  const stateLocation = props.type ? props.type.stateLocation() : pluralize(props.id);
  return {
    requestCompleted: _.get(state, `${stateLocation}.requestCompleted`),
    disabled: _.get(state, `${stateLocation}.disabled`, false),
    activeItem: _.get(state, `${stateLocation}.activeItem`),
    editObject: _.get(state, `${stateLocation}.form.editObject`, {}),
    isEdit: _.get(state, `${stateLocation}.form.isEdit`, false),
    userEditObject: _.get(state, 'users.form.editObject', {}),
    readonly: _.get(state, `${stateLocation}.form.readonly`, false),
    hasEdits: _.get(state, `${stateLocation}.form.hasEdits`, false),
    process: _.get(state, `ui.requests.${props.processId}`, {}),
    error: _.get(state, 'users.error') || _.get(state, 'users.errorMessage'),
  };
};

export { AddForm };

export default connect(mapStateToProps, mapDispatchToProps)(AddForm);
