import concat from 'lodash/concat';
import get from 'lodash/get';
import partition from 'lodash/partition';
import moment from 'moment';
import React, { useState, useRef, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import './ChatLog.less';
import {
  getUnredactedMessage as getUnredactedMessageAction,
  clearUnredactedMessage as clearUnredactedMessageAction,
  addUserToChat as addUserToChatAction,
} from '@app/actions/chat';
import {
  patchJob as patchJobAction,
} from '@app/actions/job';
import {
  getUsersByEmail as getUsersByEmailAction,
} from '@app/actions/users';
import { ROLE_ENDUSER } from '@app/constants/general';
import { CONFIRMED_TOTAL_LOSS_STATUS } from '@app/constants/jobState';
import { FNOL_CAPTURE_LOSS_DATA } from '@app/constants/workflows';
import { formatDate } from '@app/helpers/datetime';
import { isMultiPartyEnabled } from '@app/helpers/featureFlags';
import { StateType } from '@app/types/reducer-state';

import AddTotalLossRep from './AddTotalLossRep/AddTotalLossRep';
import ChatBotMessage from './ChatBotMessage/ChatBotMessage';
import ChatMessage from './ChatMessage/chat-message';
import TotalLossPicker from './TotalLossPicker/TotalLossPicker';
import { getUserRelationship } from '../MultiPartyToolbar/multi-party-toolbar-helper';
import type { Message } from '../types';

const addTodayLine = (
  messages: Message[],
  renderFunction: (arg1: Message) => React.ReactNode,
) => {
  if (messages.length > 0) {
    const splitMessages = partition(messages.slice(), ['isToday', false]);
    const renderMessages = (ms: Message[] = []) => ms.map((m) => renderFunction(m));

    const yesterMessages: Message[] = get(splitMessages, '0', []);
    const todaysMessages: Message[] = get(splitMessages, '1', []);

    const TodayLine = todaysMessages.length > 0 ? (
      <div className="today-messages-separator">
        <div className="today-messages-separator-text">Today</div>
        <div className="today-messages-separator-line" />
      </div>
    ) : '';

    let messagesWithLine: React.ReactNode[] = [];
    if (yesterMessages.length === 0) {
      messagesWithLine = [renderMessages(todaysMessages)];
    }
    if (todaysMessages.length === 0) {
      messagesWithLine = [renderMessages(yesterMessages)];
    }
    if (todaysMessages.length > 0 && yesterMessages.length > 0) {
      messagesWithLine = concat(
        renderMessages(yesterMessages),
        [TodayLine],
        renderMessages(todaysMessages),
      );
    }
    return messagesWithLine;
  } return [];
};

const sortMessagesByCreatedAt = ({ messages }: { messages: Message[] }) => messages.sort(
  (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
);

const formatMessages = (
  { messages, AICompanyName }: { messages: Message[], AICompanyName: string },
) => messages.map((item) => ({
  ...item,
  authorName: item.isAI ? AICompanyName : get(item, 'author.name') || 'User',
  isToday: moment(item.createdAt).isSame(moment(), 'day'),
}));

const formatAndSortMessages = (
  { messages, AICompanyName }: { messages: Message[], AICompanyName: string },
) => (
  sortMessagesByCreatedAt({ messages: formatMessages({ messages, AICompanyName }) })
);

const mapStateToProps = (state: StateType, ownProps: {
  chat: {
    jobId?: string,
    id?: string,
    isLastMessageInLog?: boolean,
  }
}) => {
  const { chat } = ownProps;
  const jobId = chat && chat.jobId;
  const chatId = chat && chat.id;
  const isLastMessageInLog = chat && chat.isLastMessageInLog;
  const orgId = get(state, 'auth.user.organizationId', '');
  const orgPermissions: string[] = get(state, 'auth.permissions', []);
  const redactionSettings = orgPermissions.includes('FEATURE_FLAG_UI_REDACTION');
  const unredactedMessage = get(state, 'jobs.activeChat.unredactedMessage', {});
  const authPermissions: string[] = get(state, 'auth.permissions', []);
  const hasRedactionViewAccess = authPermissions.includes('REDACTION_VIEW_ACCESS');
  const messages = get(chat, 'messages', []).filter((m: Message) => m.chatId === chatId);
  const recipientId = get(state, 'jobs.activeJob.customer.id', '');
  const jobOperatorId = get(state, 'jobs.activeJob.operatorId', {});
  const jobCustomerId = get(state, 'jobs.activeJob.customer.id', {});
  const jobLanguagePref = get(state.jobs, 'activeJob.languagePreference');
  const userPermissions = get(state.auth, 'permissions', []);
  const AICompanyName = get(state, 'auth.settings.AICompanyName', 'Marley');
  const authId = get(state.auth, 'user._id', '');
  const listById = get(state, 'users.listById', {});
  const totalLossStatus = get(state, 'jobs.activeJob.totalLossStatus');
  const workflowName = get(state, 'jobs.activeJob.fnolData.workflowName');
  const airbagsDeployed = get(state, 'jobs.activeJob.fnolData.entities.airbagsDeployed.value');
  const fnolAirbagsDeployed = airbagsDeployed && airbagsDeployed !== 'Not Reported' && workflowName === FNOL_CAPTURE_LOSS_DATA;
  const fnolWorkflowExited = !!get(state, 'jobs.activeJob.fnolData.exitReason') && workflowName === FNOL_CAPTURE_LOSS_DATA;
  const activeJobId = get(state, 'jobs.activeJob.id', '');
  const chatParticipants = get(state, `jobs.chats.${chatId}.participants`) ?? [];

  return {
    authId,
    AICompanyName,
    chatId,
    isLastMessageInLog,
    jobId,
    messages,
    recipientId,
    orgId,
    redactionSettings,
    unredactedMessage,
    hasRedactionViewAccess,
    listById,
    jobOperatorId,
    jobCustomerId,
    jobLanguagePref,
    userPermissions,
    totalLossStatus,
    fnolAirbagsDeployed,
    fnolWorkflowExited,
    chatParticipants,
    activeJobId,
  };
};

const mapDispatchToProps = {
  getUnredactedMessage: getUnredactedMessageAction,
  clearUnredactedMessage: clearUnredactedMessageAction,
  addUserToChat: addUserToChatAction as typeof addUserToChatAction,
  getUsersByEmail: getUsersByEmailAction,
  patchJob: patchJobAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ChatLogProps = PropsFromRedux & {
  isInChatBotMode?: boolean;
  chatRef: React.RefObject<HTMLDivElement>;
};

const ChatLog: React.FC<ChatLogProps> = ({
  messages: initialMessages,
  recipientId,
  jobId,
  jobOperatorId,
  jobCustomerId,
  chatId,
  chatParticipants,
  isLastMessageInLog,
  AICompanyName,
  userPermissions,
  totalLossStatus,
  fnolAirbagsDeployed,
  fnolWorkflowExited,
  addUserToChat,
  getUsersByEmail,
  activeJobId,
  patchJob,
  isInChatBotMode,
  redactionSettings,
  hasRedactionViewAccess,
  orgId,
  getUnredactedMessage,
  unredactedMessage,
  clearUnredactedMessage,
  chatRef,
}) => {
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [messages, setMessages] = useState(
    formatAndSortMessages({ messages: initialMessages, AICompanyName }),
  );
  const [declareTotalLossDismissed, setDeclareTotalLossDismissed] = useState(false);
  const [addRepDismissed, setAddRepDismissed] = useState(false);
  const chatLogContainer = useRef<HTMLDivElement | null>(null);

  const firstUnreadMessageRef = useRef<HTMLDivElement | null>(null);

  const handleContainerScroll = () => {
    if (chatLogContainer.current) {
      if (chatLogContainer.current.scrollHeight - chatLogContainer.current.scrollTop
        - chatLogContainer.current.clientHeight <= 500 && isLastMessageInLog) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    }
  };

  useEffect(() => {
    const containerRef = chatLogContainer.current;
    if (containerRef) {
      containerRef.addEventListener('scroll', handleContainerScroll);
    }
    return () => {
      if (containerRef) {
        containerRef.removeEventListener('scroll', handleContainerScroll);
      }
    };
  }, []);

  useEffect(() => {
    setMessages(formatAndSortMessages({ messages: initialMessages, AICompanyName }));
  }, [initialMessages, AICompanyName]);

  const scrollToBottomAndHidePoint = () => {
    if (chatLogContainer.current) {
      chatLogContainer.current.scrollTo({
        top: chatLogContainer.current.scrollHeight,
      });
    }
  };

  useEffect(() => {
    if (firstUnreadMessageRef.current) {
      firstUnreadMessageRef.current.scrollIntoView();
    } else {
      scrollToBottomAndHidePoint();
    }
  }, [chatId, initialMessages.length, firstUnreadMessageRef, messages.length]);

  const handleAddTotalLossRep = async () => {
    const res = await getUsersByEmail(['jackie.booth@himarley.com']);
    const user = res.users?.[0];
    if (!user) {
      console.error('unable to get jackies user');
    }
    addUserToChat({ chatId, userId: user._id });
    setAddRepDismissed(true);
  };

  /**
  * Replace redacted text with redaction cover.
  * @param {object} messageObject - Object containing message, and redactionData.
  * @param {string} action - Either 'cover' or 'highlight'.
  * @return {array} Array that will serve as formatted text for rendering.
  */
  const replaceRedactedTextWithCover = (messageObject: Message) => {
    if (!redactionSettings) {
      return [];
    }

    const messageBody = messageObject?.messageBody || messageObject?.body;

    return messageBody;
  };

  let isUnreadMessage = false;
  const showMessage = (message: Message) => {
    const outbound = message.isAI
      || (message.author && message.author.id !== recipientId);

    const useMultiPartyDisplayName = isMultiPartyEnabled(userPermissions);
    const relationship = useMultiPartyDisplayName
      ? getUserRelationship({
        jobId, jobOperatorId, jobCustomerId, message,
      })
      : null;

    const messageFormatSpecificProps = {
      isToday: moment(message.createdAt).isSame(moment(), 'day'),
      todayTimestamp: formatDate(message?.createdAt, 'just-time-with-seconds'),
      timestamp: formatDate(message?.createdAt, 'date-time-with-seconds'),
      message,
      orgId,
      getUnredactedMessage,
      unredactedMessage,
      clearUnredactedMessage,
    };

    if (message.isFirstUnreadMessage && !isUnreadMessage) {
      isUnreadMessage = true;
    }

    return (
      <ChatMessage
        key={`${message.id}-${message.createdAt}`}
        firstUnreadMessageRef={message.isFirstUnreadMessage ? firstUnreadMessageRef : null}
        inbound={!outbound}
        message={{
          ...message,
          relationship,
        }}
        isRecipient={get(message, 'author.role') === ROLE_ENDUSER}
        todayMessage={message.isToday}
        orgRedactionEnabled={redactionSettings}
        hasRedactionViewAccess={hasRedactionViewAccess}
        replaceRedactedTextWithCover={replaceRedactedTextWithCover}
        orgId={orgId}
        messageFormatSpecificProps={messageFormatSpecificProps}
        jobId={jobId}
        isUnreadMessage={isUnreadMessage}
        chatRef={chatRef}
      />
    );
  };

  const showPredictedTotalLoss = fnolAirbagsDeployed
    && fnolAirbagsDeployed !== 'Not Reported'
    && !totalLossStatus
    && !declareTotalLossDismissed;
  const isTotalLossRepOnJob = chatParticipants.some(
    (participant) => participant.email === 'jackie.booth@himarley.com',
  );
  const showAddTotalLossRep = totalLossStatus === CONFIRMED_TOTAL_LOSS_STATUS
    && !addRepDismissed
    && fnolWorkflowExited
    && !isTotalLossRepOnJob;

  return (
    <div className="chat-log-wrap">
      <div className="chat-log" data-testid="chat-log" ref={chatLogContainer}>
        {addTodayLine(messages, showMessage)}
        {isInChatBotMode && (
          <ChatBotMessage
            messageBody="An automated workflow is gathering info from this person. You can take over now to start messaging directly with them instead, or the automated workflow will continue gathering info until you are ready to respond."
            leftButtonText="Take Over"
            rightButtonText="Continue Gathering Info"
            recipientId={recipientId}
            continueText="Okay, Marley is now gathering claim info."
          />
        )}
        {showPredictedTotalLoss && (
          <TotalLossPicker
            onDismiss={() => setDeclareTotalLossDismissed(true)}
            activeJobId={activeJobId}
            patchJob={patchJob}
          />
        )}
        {showAddTotalLossRep && (
          <AddTotalLossRep
            onDismiss={() => setAddRepDismissed(true)}
            onNotify={handleAddTotalLossRep}
          />
        )}
      </div>
      {!isAtBottom && (
        <div className="chat-log-back-button">
          <div className="chat-log-back-button-title">
            You are viewing older archived messages.
          </div>
          <div
            onClick={scrollToBottomAndHidePoint}
            onKeyDown={scrollToBottomAndHidePoint}
            className="chat-log-back-button-select"
            role="button"
            tabIndex={0}
          >
            Back to recent messages
          </div>
        </div>
      )}
    </div>
  );
};

export default connector(ChatLog);
