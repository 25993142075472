/* eslint-disable consistent-return */
import React, {
  useEffect,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VIRUS_SCAN_STATUS } from '@app/components/Media/constants';
import {
  keyboardShortcuts,
} from './media-modal-helpers';
import useFileCache from '../../../hooks/file-loader';

import MediaModal from './MediaModal';
import { updateMediaModalIndex, closeMediaModal } from '../../../actions/ui';

const MediaModalContainer = () => {
  const mediaItems = useSelector(
    (state) => (state?.jobs?.activeChat?.media?.filter(
      (media) => !media?.isImageRedacted && media?.virusScanStatus === VIRUS_SCAN_STATUS.CLEAN,
    ) || [])?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt)),
  );
  const { open, mediaIndex: selectedMediaIndex } = useSelector((state) => state?.ui?.mediaModal);
  const dispatch = useDispatch();
  const fileUrl = mediaItems[selectedMediaIndex]?.fileUrl;
  const selectedMediaItem = useSelector((state) => state?.media[fileUrl]);
  const {
    loadingState,
    localFileUrl,
  } = useFileCache(fileUrl);

  const changeMediaIndex = useCallback((index) => {
    if (open) {
      dispatch(updateMediaModalIndex(index));
    }
  }, [dispatch, open]);
  const handleClose = useCallback(() => dispatch(closeMediaModal()), [dispatch]);
  const handleUpdateSelectedMediaIndex = (newIndex) => dispatch(updateMediaModalIndex(newIndex));
  // Key presses that work when the modal is open
  useEffect(() => {
    const mediaModalKeypressShortcuts = (event) => keyboardShortcuts({
      key: event?.key,
      mediaIndex: selectedMediaIndex,
      mediaCount: mediaItems.length,
      changeMediaIndex,
      close: handleClose,
    });
    window.addEventListener('keydown', mediaModalKeypressShortcuts);
    return () => window.removeEventListener('keydown', mediaModalKeypressShortcuts);
  }, [
    selectedMediaIndex,
    mediaItems.length,
    changeMediaIndex,
    handleClose,
  ]);
  if (!open || loadingState === 'loading' || !localFileUrl) {
    return null;
  }
  return (
    <MediaModal
      localFileUrl={localFileUrl}
      mediaItem={selectedMediaItem}
      selectedMediaIndex={selectedMediaIndex}
      mediaCount={mediaItems.length}
      moveToLeftMedia={(index) => handleUpdateSelectedMediaIndex(index + -1)}
      moveToRightMedia={(index) => handleUpdateSelectedMediaIndex(index + 1)}
      closeModal={handleClose}
    />
  );
};

export default MediaModalContainer;
