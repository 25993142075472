/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { Modal } from 'react-bootstrap'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import Loader from 'react-loader-spinner'
import PropTypes from 'prop-types'
import IconButton from '../../elements/buttons/IconButton/IconButton'
import CloseIcon from '../../../../images/icons/close.svg'

import './MassNotificationModal.less'

import {
  hideMassNotificationModal as hideMassNotificationModalAction,
} from '../../../actions/massNotifications'

import CreateMassNotificationForm from '../CreateMassNotificationForm/CreateMassNotificationForm'

// stateless functional componenet_______________________________________
const MassNotificationModal = ({
  modalLoading,
  show,
  onHide,
  hideMassNotificationModal,
}) => {
  const handleClickHideModal = () => {
    onHide()
    hideMassNotificationModal()
  }

  return (
    <Modal
      show={show}
      className="mass-notification-creation-modal"
    >
      <Modal.Header>
        <div className="mass-notification-modal-title">New Notification</div>
        <div className="closeBtnContainer">
          <IconButton
            onClick={handleClickHideModal}
            className="closeBtn"
            id="chat-input-survey-close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <CreateMassNotificationForm onHide={onHide} />
        <div className={`marleySpinner flexCenter ${modalLoading ? '' : 'hidden'}`}>
          <Loader
            type="Oval"
            color="#44C7E9"
            height="100"
            width="100"
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

MassNotificationModal.propTypes = {
  modalLoading: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  hideMassNotificationModal: PropTypes.func.isRequired,
}

MassNotificationModal.defaultProps = {
  modalLoading: undefined,
}

const mapDispatchToProps = {
  hideMassNotificationModal: hideMassNotificationModalAction,
}

const mapStateToProps = (state) => ({
  modalLoading: _get(state, 'massNotifications.modalLoading'),
})

export default connect(mapStateToProps, mapDispatchToProps)(MassNotificationModal)
