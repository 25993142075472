import {
  Avatar,
  Button,
  ButtonRow,
  Modal,
} from '@himarley/unity';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateLineOfBusiness } from '@app/actions/organization';
import FormInputField from '@app/components/FormInput/FormInputField';
import { capitalizeEachWord } from '@app/helpers/format';
import { StateType } from '@app/types/reducer-state';

import { typeToLine } from './helpers';
import LoBIcon from './lob-icon';

interface EditLineOfBusinessModalProps {
  isEditing: boolean;
  show: boolean;
  hideModal: () => void;
  name: string;
  lob: {
    _id: string;
    type: 'personal' | 'commercial' | 'other';
    displayName: string;
    subType: string;
    branding: string;
  };
}

const EditLineOfBusinessModal: React.FC<EditLineOfBusinessModalProps> = ({
  show = false,
  hideModal = () => { },
  isEditing = false,
  name = '',
  lob = {},
}) => {
  const [lineName, setLineName] = useState(name || '');
  const dispatch = useDispatch();
  const orgId = useSelector((state: StateType) => state.auth?.user?.organizationId);

  const handleHideModal = () => {
    if (!isEditing) {
      setLineName('');
    }
    hideModal();
  };

  useEffect(() => {
    setLineName(name);
  }, [name]);

  const isValid = () => {
    if (lineName?.trim()?.length < 1 || lineName.length > 50) {
      return false;
    }
    return true;
  };

  const handleEditLine = () => {
    if (!isValid()) {
      return;
    }
    dispatch(updateLineOfBusiness(
      {
        organizationId: orgId,
        lineOfBusinessId: lob?._id,
        displayName: capitalizeEachWord(lineName),
      },
      'Lines of Business saved.',
      'Error Saving Lines of Business. Please Try Again.',
    ));
  };

  const isSaveDisabled = () => {
    if (lineName?.trim()?.length < 1) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      show={show}
      showCloseButton
      title={`${isEditing ? 'Edit' : 'Create'} Line`}
      className="confirmation-modal"
      toggleModal={handleHideModal}
      backdrop
      size="lg"
    >
      <>
        <div className="edit-lob-modal-container">
          <div>
            <div className="edit-avatar-container">
              <Avatar name={lob?.branding ?? ''} isCustomer className="brandAvatar" />
              <label htmlFor="edit-avatar-input" className="edit-avatar-label">{lob.branding}</label>
            </div>
            <div className="type-container">
              <p>Type</p>
              <p>{lob?.type && typeToLine[lob.type]}</p>
            </div>
          </div>

          <div>
            <div className="lob-icon-container">
              <LoBIcon name={lob.subType || ''} />
              <h2 className="lob-icon-title">
                {capitalizeEachWord(
                  lob?.subType,
                  lob?.subType?.includes('-') ? '-' : ' ',
                )}
                {lob?.subType === 'workers-comp' && '.'}
              </h2>
            </div>
            <FormInputField
              label="Display Name"
              value={lineName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLineName(e?.target?.value || '')}
              errorMessage={
                !isValid()
                  ? 'Line Name must be less than 50 characters.'
                  : ''
              }
              isValid={isValid()}
            />

          </div>
        </div>
        <div className="confirmation-modal-footer">
          <ButtonRow>
            <Button
              onClick={handleHideModal}
              type="outline"
              className="cancelButton"
            >
              Cancel
            </Button>
            <Button
              data-testid="delete-note-button-confirm"
              data-test="delete-note-button-confirm"
              onClick={() => {
                handleEditLine();
                handleHideModal();
              }}
              type="positive"
              disabled={isSaveDisabled() || !isValid()}
            >
              {isEditing ? 'Save' : 'Create'}
              {' '}
              Changes
            </Button>
          </ButtonRow>
        </div>
      </>
    </Modal>
  );
};

export default EditLineOfBusinessModal;
