import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Note from './Note';

const NotesList = ({
  setShowDeleteConfirmation,
  setActiveNoteIds,
  authId,
  activeCase,
  notes,
  addReply,
  readNoteMention,
  hasNotesReplies,
}) => {
  const filteredAndSortedNotes = useMemo(() => notes?.notes
    ?.filter((n) => !n?.archived || (n?.archived && n?.replies?.length > 0))
    ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)), [notes?.notes]);

  return filteredAndSortedNotes?.map((n) => (
    <Note
      note={n}
      hasNotesReplies={hasNotesReplies}
      key={`note-${n?._id}`}
      activeCase={activeCase}
      setShowDeleteConfirmation={setShowDeleteConfirmation}
      setActiveNoteIds={setActiveNoteIds}
      authId={authId}
      addReply={addReply}
      readNoteMention={readNoteMention}
    />
  ));
};

NotesList.propTypes = {
  setShowDeleteConfirmation: PropTypes.func,
  setActiveNoteIds: PropTypes.func,
  authId: PropTypes.string,
  activeCase: PropTypes.shape({}).isRequired,
  notes: PropTypes.shape({}).isRequired,
  addReply: PropTypes.func,
};

NotesList.defaultProps = {
  setShowDeleteConfirmation: () => {},
  setActiveNoteIds: () => {},
  authId: null,
  addReply: () => {},
};

export default NotesList;
