import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';

import usePermissionCheck from '@app/components/HigherOrderComponents/usePermissionCheck';

type IconButtonWithPermissionsProps = IconButtonProps & {
  hasAccess: boolean;
  disabled: boolean;
};

const IconButtonWithPermissions: React.FC<IconButtonWithPermissionsProps> = ({
  hasAccess,
  disabled,
  ...other
}) => {
  const disabledIcon = disabled || !hasAccess;

  return (
    hasAccess
      ? (
        <IconButton
          isDisabled={disabledIcon}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...other}
        />
      ) : null
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePermissionCheck(IconButtonWithPermissions);
