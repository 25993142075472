import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { makeApiRequest } from '../../../../../actions/api/api';
import EditIcon from '../../../../icons/EditIcon';
import GarbageIcon from '../../../../icons/GarbageIcon';
import SlideToggle from '../../../../SlideToggle/SlideToggle';
import IconButton from '../../../../elements/buttons/IconButton/IconButton';
import Button from '../../../../Form/Button/Button';
import './ReplyActions.less';
import _ from "lodash";

const ReplyActions = ({ openEditModal, userId, reply }) => {
  const { success, error, pendingRequest } = useSelector(
    (state) => ({
      success: state.profile.success,
      error: state.profile.error,
      pendingRequest: state.profile.pendingRequest,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState('');
  const [toggleSlide, setToggleSlide] = useState(reply.active);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (success && success.length > 0 && activeId === reply.id) {
      dispatch({ type: 'RESET_PROFILE_REQUESTS' });
      if (showDeleteModal) {
        setActiveId('');
        setShowDeleteModal(false);
      } else if (activeId === reply.id) {
        setActiveId('');
        setToggleSlide(reply.active);
      }
    }
  }, [success]);

  useEffect(() => {
    setToggleSlide(reply.active);
  }, [reply.active]);

  useEffect(() => {
    if (error && error.length > 0 && activeId === reply.id) {
      const timer = setTimeout(() => {
        setActiveId('');
        dispatch({ type: 'RESET_PROFILE_REQUESTS' });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  function handleToggleSlide() {
    dispatch({ type: 'RESET_PROFILE_REQUESTS' });
    dispatch(makeApiRequest('PUT_USERS_AUTOREPLIES', [userId, reply.id], { ...reply, active: !toggleSlide }));
    setActiveId(reply.id);
  }

  function handleDelete() {
    dispatch({ type: 'RESET_PROFILE_REQUESTS' });
    dispatch(makeApiRequest('DELETE_USERS_AUTOREPLIES', [userId, reply.id]));
    setActiveId(reply.id);
  }

  function hideDeleteModal() {
    setShowDeleteModal(false);
  }
  return (
    <div>
      <div className="auto-replies-actions-icon-row">
        <div className="auto-replies-actions-toggle-container">
          <h3>{_.get(reply, 'title', '')}</h3>
          <SlideToggle
            on={toggleSlide}
            onToggle={handleToggleSlide}
          />
          {pendingRequest && !showDeleteModal && activeId === reply.id ? (
            <Loader type="Oval" color="#44C7E9" height="20" width="20" />
          ) : null}
          {error
          && error.length > 0
          && !showDeleteModal
          && activeId === reply.id ? (
            <div className="auto-replies-actions-error">{error}</div>
            ) : null}
        </div>
        <div className="auto-replies-actions-icons">
          <IconButton onClick={openEditModal} data-test="auto-replies-edit-icon">
            <EditIcon className="edit" />
          </IconButton>
          <IconButton onClick={() => setShowDeleteModal(true)} data-test="auto-replies-delete-icon">
            <GarbageIcon className="delete" />
          </IconButton>
          <Modal
            className="confirmation-modal"
            show={showDeleteModal}
            onHide={() => hideDeleteModal()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Message?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              This will permanently delete this message. Do you wish to
              continue?
              <div className="auto-replies-actions-delete-error">
                {error && error.length > 0 && activeId === reply.id
                  ? error
                  : null}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="hymarley-button cancel outline primary auto-reply-action-modal-button"
                data-test="auto-replies-actions-cancel-delete-button"
                onClick={() => hideDeleteModal()}
              >
                Cancel
              </Button>
              <Button
                className="hymarley-button cancel outline primary auto-reply-action-modal-button"
                data-test="auto-replies-actions-delete-confirm-button"
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ReplyActions;
