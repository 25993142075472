import cookie from 'react-cookies';
import _ from 'lodash';

export const getCookie = (cookieName) => {
  const val = cookie.load(cookieName, { path: '/' });
  if ((val === 'undefined') || !val) {
    return null;
  }
  return val;
};

export const saveCookies = (params) => {
  Object.keys(params).forEach((key) => {
    cookie.save(key, _.get(params, key, ''), { path: '/' });
  });
};

export const getUser = () => {
  let user = cookie.load('user');
  if ((user === 'undefined') || !user) {
    return null;
  }
  user = JSON.parse('user');
  return user;
};

export const clearCookies = () => {
  cookie.remove('IdToken', '/');
  cookie.remove('RefreshToken', '/');
  cookie.remove('AccessToken', '/');
};

export const saveCustomInboxCookie = (params) => {
  cookie.save('customInboxParams', params, '/');
};

export const saveAnalyticsCookie = (params) => {
  cookie.save('analyticsFilters', params, '/');
};

export const getCustomInboxCookie = () => {
  const customInboxParams = cookie.load('customInboxParams');
  if (!customInboxParams) {
    return null;
  }
  return customInboxParams;
};

export const saveBranding = (params) => {
  cookie.save('savedBranding', params, '/');
};

export const saveBrowserCheckCookie = (wasChecked) => {
  cookie.save('wasBrowserChecked', wasChecked, '/');
};

export const getBrowserCheckCookie = () => {
  const wasBrowserChecked = cookie.load('wasBrowserChecked');
  if (!wasBrowserChecked) {
    return null;
  }
  return wasBrowserChecked;
};

export const saveQueueCookie = (activeQueueId) => {
  cookie.save('activeQueueId', activeQueueId, '/');
};

export const saveUser = (user) => {
  cookie.save('user', JSON.stringify(user), '/');
};

export const getUserId = () => {
  const user = cookie.load('user');
  return user ? user._id : null;
};
