/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import DatePicker from 'react-bootstrap-date-picker';
import PropTypes from 'prop-types';

import ConfigurableOptInWidget from '@app/components/Form/ConfigurableOptInWidget/ConfigurableOptInWidget';

import {
  addFormError,
  clearAddFormErrors,
  clearEditObject,
  createCaseWithUser,
  handleFormInputChange,
} from '@app/actions/common';
import { closeForm } from '@app/actions/ui';
import { jobPutRequest } from '@app/actions/job';

// eslint-disable-next-line import/no-named-as-default
import BrandedCopy from '@app/components/Form/BrandedCopy/BrandedCopy';
import Button from '@app/components/Form/Button/Button';
import CaseVisibilityDropdown from '@app/components/Form/CaseVisibilityDropdown/CaseVisibilityDropdown';
import FormInputField from '@app/components/FormInput/FormInputField';
import LinesOfBusinessList from '@app/components/Form/LinesOfBusinessList/LinesOfBusinessList';
import MultiStateButton from '@app/components/Form/MultiStateButton/MultiStateButton';
import PermissionVerifier from '@app/components/PermissionVerifier/PermissionVerifier';
// eslint-disable-next-line import/no-named-as-default
import PrimaryContactInput from '@app/components/PrimaryContactInput/PrimaryContactInput';
// eslint-disable-next-line import/no-named-as-default
import UserForm from '@app/components/Chats/RightBar/AddUser/AddUserForm/UserForm';

import { CASE_VISIBILITY, LINES_OF_BUSINESS_ENABLE } from '@app/constants/permissions';

import { formatBrandLobs } from '@app/helpers/format';
import { PolicyFormValidation } from '@app/helpers/formValidation/policyForm/policyFormValidation';
import { UserFormValidation } from '@app/helpers/formValidation/userForm/userFormValidation';

import BriefcaseIcon from '@images/icons/briefcase.svg';
import PersonalIcon from '@images/icons/policy@2x.svg';

import { caseType, userType } from '@app/models/marleyTypes';

import './PolicyForm.less';
import CaseMatchAlert from '../../elements/CaseMatchAlert/case-match-alert';

class PolicyForm extends React.Component {
  constructor(props) {
    super(props);

    this.mapNameToMetadataId = { // make this object an importable utility object
      policyNumber: '038d0c94-d346-11ea-87d0-0242ac130003',
      companyName: '19de7971-8555-4cfb-91ed-f45011ec04d1',
      deductible: '4c7a89a2-8ea1-4984-b5d4-43a05c1a4983',
      address1: 'c4eddfac-4243-4360-9941-18869c483cdb',
      address2: 'a06af07d-0bd6-482d-9fc0-1ba6fe59c48e',
      city: '7540520d-834c-4fd9-99c6-37173a5f1351',
      state: 'f94b9981-9e26-40d5-b2db-4cbdaef467b4',
      zipCode: '753c54ce-fecb-4704-9d5a-f6d01dbbdb6c',
      ageOfDwelling: '2c21403e-22cc-4cc2-988b-28086b7e6c86',
      effectiveDate: '8ae3a30a-0011-4b2d-abf4-d248b6d47b89',
      expirationDate: '5935bd29-ad61-41b9-bfa3-1fbe8f99c88c',
      policyType: '270d571f-fe3d-4b7e-bfac-a974c0c669b6',
    };

    this.IdToMetadataLocation = (key) => `metaData.${this.mapNameToMetadataId[key]}`;

    this.state = {
      privacy: 'public',
      submitIsDisabled: false,
      companyName: props.isEdit ? _.get(props, `editObject.${this.IdToMetadataLocation('companyName')}`) : '',
      deductible: props.isEdit ? _.get(props, `editObject.${this.IdToMetadataLocation('deductible')}`) : '',
      deductibleIsValid: true,
      deductibleErrorMessage: 'Please only submit numeric values for deductible',
      policyNumber: props.isEdit ? _.get(props, `editObject.${this.IdToMetadataLocation('policyNumber')}`) : '',
      policyNumberIsValid: true,
      policyNumberErrorMessage: 'You must enter a policy number',
      policyType: props.isEdit ? _.get(props, `editObject.${this.IdToMetadataLocation('policyType')}`) : '',
      address1: props.isEdit && this.checkAddressField(this.mapNameToMetadataId.address1) ? _.get(props, `editObject.${this.IdToMetadataLocation('address1')}`) : '',
      address2: props.isEdit && this.checkAddressField(this.mapNameToMetadataId.address2) ? _.get(props, `editObject.${this.IdToMetadataLocation('address2')}`) : '',
      city: props.isEdit && this.checkAddressField(this.mapNameToMetadataId.city) ? _.get(props, `editObject.${this.IdToMetadataLocation('city')}`) : '',
      state: props.isEdit && this.checkAddressField(this.mapNameToMetadataId.state) ? _.get(props, `editObject.${this.IdToMetadataLocation('state')}`) : '',
      zipCode: props.isEdit && this.checkAddressField(this.mapNameToMetadataId.zipCode) ? _.get(props, `editObject.${this.IdToMetadataLocation('zipCode')}`) : '',
      ageOfDwelling: props.isEdit ? _.get(props, `editObject.${this.IdToMetadataLocation('ageOfDwelling')}`) : '',
      effectiveDate: props.isEdit ? _.get(props, `editObject.${this.IdToMetadataLocation('effectiveDate')}`) : '',
      expirationDate: props.isEdit ? _.get(props, `editObject.${this.IdToMetadataLocation('expirationDate')}`) : '',
      caseTypeId: props.isEdit ? _.get(props, 'editObject.caseTypeId') : '11f43fa7-df90-4da2-92b9-caae0d215323',
      caseLobData: {},
      userPolicies: [],
      existingCases: [],
      showPolicyMatchAlert: false,
      showPolicySummaryAlert: false,
    };

    this.checkAddressField = this.checkAddressField.bind(this);

    this.props.handleFormInputChange({
      inputId: 'caseTypeId',
      tableId: caseType.id(),
      value: this.state.caseTypeId,
    });

    this.props.handleFormInputChange({
      inputId: 'metadataReferenceId',
      tableId: caseType.id(),
      value: '038d0c94-d346-11ea-87d0-0242ac130003',
    });
  }

  componentWillUnmount() {
    this.props.clearAddFormErrors(caseType.reducerCase());
  }

  getSaveButtonText = () => {
    const isEdit = _.get(this.props, 'isEdit', false);
    const userEditObjectHasId = _.get(this.props, 'userEditObject.id', null);
    if (isEdit) {
      return 'Update Policy';
    } if (userEditObjectHasId) {
      return this.props.createButtonText
        ? this.props.createButtonText
        : 'Create Policy';
    }
    return 'Create Policy';
  };

  getFormErrorText = () => {
    const errorLocations = [
      'jobs.errorMessage',
      'users.error',
      'policies.error',
      'requests.error',
    ];

    let returnError = '';
    errorLocations.forEach((el) => {
      const error = _.get(this.props, el);
      if (error && !returnError) {
        returnError = error;
      } else {
        return returnError;
      }
    });

    return returnError;
  };

  handleCaseVisibilityChange = (id) => {
    this.setState({ privacy: id });
  };

  handleLobChange = (item) => {
    this.setState({ caseLobData: item[0] });
  };

  togglePolicyType = (value) => {
    // Don't need to delete company name here or else it might lead to a bug where the company name is lost if case type is toggled
    const caseTypeId = value === 'personal' ? '11f43fa7-df90-4da2-92b9-caae0d215323' : 'badf9200-a0e0-48ae-ae7c-c51c97e9f14b';
    this.props.handleFormInputChange({
      inputId: 'caseTypeId',
      tableId: caseType.id(),
      value: caseTypeId,
    });
    this.setState({ caseTypeId });
  };

  // used when policy is being created outside of case view
  toggleLocalSubmitIsDisabled = (value) => this.setState({ submitIsDisabled: value });

  handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    if (this.state.submitIsDisabled) {
      this.toggleLocalSubmitIsDisabled(false);
    }
    if (this.props.submitIsDisabled) {
      this.props.toggleDisableFormSubmit(false);
    }

    // format address fields
    this.props.handleFormInputChange({
      inputId: this.IdToMetadataLocation(name),
      tableId: caseType.id(),
      value,
    });
    this.setState({ [name]: value });
  };

  handleBlur = (e) => {
    const { name, value } = e.target;
    const { editObject, userCases, view } = this.props;

    const validation = PolicyFormValidation.policyFormValidation({
      fieldName: name,
      policyFormData: editObject,
      map: this.IdToMetadataLocation,
    });
    this.setState({ [`${name}IsValid`]: validation.isValid });

    if (name === 'policyNumber' && !value) {
      this.setState({ policyNumberIsValid: false });
    }
    if (name === 'policyNumber' && value) {
      this.setState((state) => {
        const policies = view === 'case' ? userCases : state.userPolicies;
        const existingCases = policies.filter((c) => c.referenceId === value && c.type === 'POLICY');
        return {
          existingCases,
          showPolicySummaryAlert: existingCases.length > 0,
          showPolicyMatchAlert: false,
        };
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let canSubmit = true;

    const {
      isEdit,
      requests,
      branding,
      editObject,
      userEditObject,
    } = this.props;

    // POLICY VALIDATION -------------------------------------------------------------------
    const policyData = {
      metaData: editObject.metaData || [],
      ...editObject,
    };

    // do validation for Point of Contact.
    if (!editObject.operatorId) {
      // TODO: fix
      this.props.addFormError(caseType.reducerCase(), 'primaryContactId');
      canSubmit = false;
    }
    // branding validation
    if (!policyData.metaData['c54f88b2-fee1-4216-8c6e-7a7aa5e83a3e'] && branding.length) {
      this.props.addFormError(caseType.reducerCase(), 'branding');
      canSubmit = false;
      if (this.props.toggleDisableFormSubmit) {
        this.props.toggleDisableFormSubmit(true);
      } else {
        this.toggleLocalSubmitIsDisabled(true);
      }
    }

    // do regex validation and formatting for deductible.
    const deductible = _.get(editObject, this.IdToMetadataLocation('deductible'));
    if ((deductible && PolicyFormValidation.validateDeductible(deductible)) || !deductible) {
      _.set(policyData, this.IdToMetadataLocation('deductible'), PolicyFormValidation.formatDeductible(deductible));
    } else {
      this.setState({ deductibleIsValid: false });
      canSubmit = false;
    }

    // do validation for policyNumber
    const policyNumber = _.get(editObject, this.IdToMetadataLocation('policyNumber'));
    // eslint-disable-next-line no-mixed-operators
    if (policyNumber && !PolicyFormValidation.validatePolicyNumber(policyNumber) || !policyNumber) {
      this.setState({ policyNumberIsValid: false });
      canSubmit = false;
    }

    // remove companyName from metaData if caseTypeId is personal or the put request will fail
    if (editObject.caseTypeId === '11f43fa7-df90-4da2-92b9-caae0d215323') {
      delete policyData.metaData['19de7971-8555-4cfb-91ed-f45011ec04d1'];
    }

    policyData.privacy = this.state.privacy;
    policyData.lineOfBusiness = this.state.caseLobData;
    const preppedPolicyData = {
      ...policyData,
    };

    // USER VALIDATION ----------------------------------------------------------------------
    // test the functionality of the form when this variable, and the below loop are removed, this may be dead code.
    const userFormFields = ['phoneNumber', 'firstName', 'lastName', 'email'];
    userFormFields.forEach((field) => {
      const invalidity = UserFormValidation.userFormValidation({
        fieldName: field,
        userFormData: userEditObject,
      });

      if (!invalidity.isValid) {
        this.props.addFormError(userType.reducerCase(), field);
      }
    });

    // this may be the only needed user form validation check. The above may be dead code.
    const invalidUserField = userFormFields.find((field) => UserFormValidation.userFormValidation({
      fieldName: field,
      userFormData: userEditObject,
    }).isValid === false);

    if (userFormFields.includes(invalidUserField)) {
      canSubmit = false;
      this.toggleLocalSubmitIsDisabled(true);

      if (this.props.toggleDisableFormSubmit) {
        this.props.toggleDisableFormSubmit(true);
      }
    }

    // SUBMIT --------------------------------------------------------------------------------
    if (isEdit && canSubmit) {
      if (this.props.toggleDisableFormSubmit) {
        this.props.toggleDisableFormSubmit(true);
      } else {
        this.toggleLocalSubmitIsDisabled(true);
      }
      this.props.jobPutRequest(preppedPolicyData);
    } else if (!isEdit && canSubmit) {
      if (this.props.toggleDisableFormSubmit) {
        this.props.toggleDisableFormSubmit(true);
      } else {
        this.toggleLocalSubmitIsDisabled(true);
      }
      const phoneNumber = UserFormValidation.cleanPhone(_.get(userEditObject, 'phoneNumber', '')); // replace this with "phone" formatter
      // const phoneNumber = UserFormValidation.phoneToE164Format(userEditObject.phoneNumber)
      const preppedPolicyAndUserData = {
        preppedPolicyData,
        userData: {
          ...userEditObject,
          phoneNumber,
          companyName: _.get(editObject, this.IdToMetadataLocation('companyName')),
        },
      };

      this.props.createCaseWithUser({
        type: caseType.reducerCase(),
        userData: preppedPolicyAndUserData.userData,
        data: preppedPolicyAndUserData.preppedPolicyData,
        userIdName: 'customerId',
        overrideType: 'POLICY',
      });

      if (requests && requests.requesting) {
        this.toggleLocalSubmitIsDisabled(true);
        if (this.props.toggleDisableFormSubmit) {
          this.props.toggleDisableFormSubmit(true);
        }
      } else if (requests && requests.error) {
        this.toggleLocalSubmitIsDisabled(false);
        if (this.props.toggleDisableFormSubmit) {
          this.props.toggleDisableFormSubmit(false);
        }
      }
    }
  };

  checkAddressField(field) {
    const { editObject } = this.props;
    if (editObject.metaData) {
      const fields = {
        [field]: editObject.metaData[field],
      };
      return fields[field];
    }
    return false;
  }

  render() {
    const {
      isEdit,
      userIsEdit,
      requests,
      userEditObject,
      policyErrors,
      branding,
      linesOfBusiness,
      hideUserForm,
      onBack,
      view,
    } = this.props;

    const {
      submitIsDisabled,

      caseTypeId,
      companyName,
      deductible,
      deductibleIsValid,
      deductibleErrorMessage,
      policyNumber,
      policyNumberIsValid,
      policyNumberErrorMessage,
      policyType,
      address1,
      address2,
      city,
      state,
      zipCode,
      ageOfDwelling,
      effectiveDate,
      expirationDate,
      userPolicies,
      existingCases,
      showPolicyMatchAlert,
      showPolicySummaryAlert,
    } = this.state;

    let currentStateId = 'preRequest';
    if (requests && requests.requesting) {
      currentStateId = 'requesting';
    } else if (requests && requests.success) {
      currentStateId = 'success';
    } else if (requests && requests.error) {
      currentStateId = 'error';
    }

    const submitButtonStates = {
      preRequest: {
        type: 'primary',
        text: this.getSaveButtonText(),
      },
      requesting: {
        type: 'secondary',
        text: 'Saving...',
        noClick: true,
      },
      success: {
        type: 'primary',
        text: 'Saved',
        noClick: true,
      },
      error: {
        type: 'primary',
        text: this.getSaveButtonText(),
      },
    };

    const phoneNumber = userEditObject && userEditObject.phoneNumber ? userEditObject.phoneNumber : '';
    const brandLobs = linesOfBusiness?.length && formatBrandLobs(linesOfBusiness);
    const brandData = this?.props?.editObject?.metaData;
    const selectedBrand = brandData && brandData['c54f88b2-fee1-4216-8c6e-7a7aa5e83a3e'];

    return (
      <div className="case-form-and-user-form-container">
        { !hideUserForm
          && (
          <div className="user-form-container">
            <div className="case-form-user-status">
              {phoneNumber.length >= 14 && !isEdit ? 'New' : null}
              {' '}
              Client
            </div>
            {/* user form */}
            <UserForm
              toggleDisableParentFormSubmit={this.props.toggleDisableFormSubmit ? this.props.toggleDisableFormSubmit : this.toggleLocalSubmitIsDisabled}
              parentFormContext="jobs"
              parentIsEdit={isEdit}
              handleParentJobs={(cases) => this.setState(
                { userPolicies: cases, showPolicyMatchAlert: cases.length > 0 },
              )}
              view="POLICY"
            />
            <CaseMatchAlert
              cases={userPolicies}
              defaultShowAlert={showPolicyMatchAlert}
              onClose={() => this.setState({ showPolicyMatchAlert: false })}
            />
          </div>
          )}
        {/* policy form */}
        {(phoneNumber.length >= 14 || isEdit || userIsEdit) && (
          <div data-test="policy-form" className={`${view === 'case' ? 'case-view' : ''} case-form-container`}>
            <div className="case-form-title">Policy Type</div>
            <div className="case-form-inputs-container">
              <div className="case-form-item case-form-item-full case-type-selections">
                <div
                  className={`${caseTypeId === '11f43fa7-df90-4da2-92b9-caae0d215323' ? 'selected' : ''} case-type-selection`}
                  onClick={() => this.togglePolicyType('personal')}
                  onKeyDown={() => this.togglePolicyType('personal')}
                  role="button"
                  tabIndex={0}
                >
                  <span><PersonalIcon /></span>
                  Personal
                </div>
                <div
                  className={`${caseTypeId === 'badf9200-a0e0-48ae-ae7c-c51c97e9f14b' ? 'selected' : ''} case-type-selection `}
                  onClick={() => this.togglePolicyType('business')}
                  onKeyDown={() => this.togglePolicyType('business')}
                  role="button"
                  tabIndex={0}
                >
                  <span><BriefcaseIcon /></span>
                  Business
                </div>
              </div>
              {caseTypeId === 'badf9200-a0e0-48ae-ae7c-c51c97e9f14b' && (
              <div className="case-form-item case-form-item-full business-name">
                <FormInputField
                  label="Business Name"
                  onChange={this.handleChange}
                  name="companyName"
                  type="text"
                  value={companyName}
                  required
                />
              </div>
              )}
              {branding && branding?.length > 0 && !isEdit && (
                <div className="case-form-item branded-copy">
                  <BrandedCopy
                    key="bc"
                    id="policy"
                    tableId="policies"
                    type={caseType}
                    toggleDisableParentFormSubmit={this.props.toggleDisableFormSubmit ? this.props.toggleDisableFormSubmit : this.toggleLocalSubmitIsDisabled}
                    required
                    errorMessage="You must pick an Insurance Brand"
                  />
                </div>
              )}
              {/* temporary class for style difference in cases tab V.S. claims tab (remove when claims and policies tabs have been removed) */}
              <div className={` ${view === 'case' ? 'point-of-contact' : 'point-of-contact-policy-tab'} poc case-form-item ${policyErrors.includes('primaryContactId') ? 'has-error' : ''}`}>
                <PrimaryContactInput
                  key="pc"
                  id="policy"
                  tableId="cases"
                  type={caseType}
                  toggleDisableParentFormSubmit={this.props.toggleDisableFormSubmit ? this.props.toggleDisableFormSubmit : this.toggleLocalSubmitIsDisabled}
                  required
                  errorMessage="You must assign Point of Contact"
                  editObject={{ ...this.props.editObject }}
                />
              </div>
              <PermissionVerifier neededPermissions={[CASE_VISIBILITY]}>
                <div className="case-form-item">
                  <CaseVisibilityDropdown
                    label="Case Visibility"
                    required
                    onChange={this.handleCaseVisibilityChange}
                    caseAssignment={this.props.editObject.operatorId}
                  />
                </div>
              </PermissionVerifier>
              <div className="case-form-item">
                <FormInputField
                  prefixAddOn="$"
                  label="Deductible"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="deductible"
                  type="text"
                  value={deductible}
                  isValid={policyErrors.indexOf('deductible') > -1 ? false : deductibleIsValid}
                  errorMessage={deductibleErrorMessage}
                />
              </div>
              <div className="case-form-item">
                <FormInputField
                  label="Policy Number"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="policyNumber"
                  type="text"
                  value={policyNumber}
                  required
                  isValid={policyErrors.indexOf('') > -1 ? false : policyNumberIsValid}
                  errorMessage={policyNumberErrorMessage}
                />
              </div>
              {branding && branding.length > 0 && !isEdit && (
                <PermissionVerifier neededPermissions={[LINES_OF_BUSINESS_ENABLE]}>
                  <div className="case-form-item">
                    <div className="case-form-item-label">
                      Line of Business
                    </div>
                    <LinesOfBusinessList
                      onChange={this.handleLobChange}
                      selectedBrand={selectedBrand}
                      listOfLob={brandLobs && brandLobs[selectedBrand]}
                    />
                  </div>
                </PermissionVerifier>
              )}
              <div className="case-form-item">
                <FormInputField
                  label="Policy Type"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="policyType"
                  type="text"
                  value={policyType}
                />
              </div>
              <div className="case-form-item">
                <FormInputField
                  label="Address (1)"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="address1"
                  type="text"
                  value={address1}
                />
              </div>
              <div className="case-form-item">
                <FormInputField
                  label="Address (2)"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="address2"
                  type="text"
                  value={address2}
                />
              </div>
              <div className="case-form-item">
                <FormInputField
                  label="City"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="city"
                  type="text"
                  value={city}
                />
              </div>
              <div className="case-form-item">
                <FormInputField
                  label="State"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="state"
                  type="text"
                  value={state}
                />
              </div>
              <div className="case-form-item">
                <FormInputField
                  label="Zip Code"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="zipCode"
                  type="text"
                  value={zipCode}
                />
              </div>
              <div className="case-form-item">
                <FormInputField
                  label="Year Built"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="ageOfDwelling"
                  type="number"
                  value={ageOfDwelling}
                />
              </div>
              <div className="case-form-item effective-date">
                <div className="date-container">
                  <div className="effective-date-label">
                    Effective Date
                  </div>
                  <DatePicker
                    style={{ width: 180 }}
                    className="effective-date"
                    onChange={(value) => {
                      this.handleChange({ target: { name: 'effectiveDate', value } });
                    }}
                    onBlur={this.onBlur}
                    showClearButton={false}
                    value={effectiveDate}
                  />
                </div>
              </div>
              <div className="case-form-item expiration-date">
                <div className="date-container">
                  <div className="expiration-date-label">
                    Expiration Date
                  </div>
                  <DatePicker
                    style={{ width: 180 }}
                    className="expiration-date"
                    onChange={(value) => {
                      this.handleChange({ target: { name: 'expirationDate', value } });
                    }}
                    onBlur={this.onBlur}
                    showClearButton={false}
                    value={expirationDate}
                  />
                </div>
              </div>
              <div className="case-form-item case-form-item-full case-error">{this.getFormErrorText()}</div>
              <div className="case-form-item case-form-item-full">
                <CaseMatchAlert
                  cases={existingCases}
                  defaultShowAlert={showPolicySummaryAlert}
                  onClose={() => this.setState({ showPolicySummaryAlert: false })}
                  isSummary
                />
              </div>
              <div className="form-footer">
                <ConfigurableOptInWidget userId={userEditObject.id} />
                <div className={`${view === 'case' ? 'case-submit' : ''} case-form-item case-form-item-full submit-btn`}>
                  { onBack && (
                  <Button
                    type="outline"
                    className="policy-back-btn"
                    data-button="policy-back-btn"
                    onClick={onBack}
                    disabled={false}
                  >
                    Back
                  </Button>
                  )}
                  <MultiStateButton
                    data-button="policy-form-submit"
                    className="policy-submit-btn general-submit-btn"
                    onClick={this.handleSubmit}
                    currentStateId={currentStateId}
                    states={submitButtonStates}
                    disabled={view === 'case' ? this.props.submitIsDisabled : submitIsDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PolicyForm.propTypes = {
  hideUserForm: PropTypes.bool,
  toggleDisableFormSubmit: PropTypes.func,
  submitIsDisabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  userEditObject: PropTypes.object,
  userIsEdit: PropTypes.bool,
  userCases: PropTypes.arrayOf(Object),
  onBack: PropTypes.func,
  view: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  linesOfBusiness: PropTypes.array,
};

PolicyForm.defaultProps = {
  hideUserForm: false,
  toggleDisableFormSubmit: () => {},
  submitIsDisabled: () => {},
  userEditObject: null,
  userIsEdit: false,
  userCases: [],
  onBack: () => {},
  view: null,
  linesOfBusiness: [],

};

const mapDispatchToProps = {
  handleFormInputChange,
  createCaseWithUser,
  addFormError,
  clearAddFormErrors,
  clearEditObject,
  jobPutRequest,
  closeForm,
};

const mapStateToProps = (state, props) => ({
  jobs: _.get(state, 'jobs', {}), // only used for error messasges
  policies: _.get(state, 'jobs', {}),
  isEdit: _.get(state, 'jobs.form.isEdit', false),
  editObject: _.get(state, 'jobs.form.editObject', {}),
  userIsEdit: props.userIsEdit || _.get(state, 'users.form.isEdit', false),
  userEditObject: props.userEditObject || _.get(state, 'users.form.editObject', {}),
  policyErrors: _.get(state, 'jobs.errors', []),
  userErrors: _.get(state, 'users.errors', []),
  requests: _.get(state, 'ui.requests.addCase', {}),
  branding: _.get(state, 'organizations.branding', []),
  linesOfBusiness: _.get(state, 'organizations.linesOfBusiness'),
});

export { PolicyForm };
export default connect(mapStateToProps, mapDispatchToProps)(PolicyForm);
