import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { closeInactivityAlert } from '../../actions/ui'
import { logout } from '../../actions/auth'
import './InactivityAlert.less'

class InactivityAlert extends React.Component {
  constructor() {
    super()
    this.onClose = this.onClose.bind(this)
    this.state = { countdown: 10 }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.timer = setInterval(() => {
      const countdown = this.state.countdown - 1
      if (countdown > 0) {
        this.setState({ countdown })
      } else {
        this.props.closeInactivityAlert(true)
        this.props.logout()
      }
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  onClose() {
    this.props.closeInactivityAlert(false)
  }

  render() {
    return (
      <Modal show backdrop="static" keyboard className="inactivity-modal">
        <Modal.Header>
          <Modal.Title>Session Timeout Warning</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="inactivity-descr">
            Your session will expire in:
          </div>
          <div className="countdown">
            {this.state.countdown}
            {' '}
            seconds
          </div>
          <div className="inactivity-descr">
            Click "continue" to keep workng,
            <br />
            or "log out" if you are finished.
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={this.onClose}
          >
            Continue
          </Button>
          <Button
            bsStyle="default"
            onClick={() => this.props.logout()}
          >
            Log Out
          </Button>
        </Modal.Footer>

      </Modal>
    )
  }
}

InactivityAlert.propTypes = {
  closeInactivityAlert: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
}
const mapDispatchToProps = {
  closeInactivityAlert,
  logout,
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(InactivityAlert)
