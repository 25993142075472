import _ from 'lodash'
import User from './user'

class Group {
  constructor(g) {
    this.id = g._id || g.id
    this.archived = g.archived
    this.name = g.name
    this.description = g.description
    this.organization = g.organization
    this.leads = _.get(g, 'leads', []).map(l => new User({ ...l }))
    this.members = _.get(g, 'members', []).map(m => new User({ ...m }))
    this.subgroups = g.subgroups
    this.count = g.count
  }
}

export default Group
