/* eslint-disable react/prop-types */
import { ChatDotsIcon, PinIcon } from '@himarley/unity';
import { Icon } from '@chakra-ui/react';
import React from 'react';

const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;

const IconWithProps = ({ icon }) => (
  <Icon as={icon} boxSize="16px" mr="5px" />
);

const menuItems = isUnreadStateRefactorEnabled ? [
  {
    id: 1,
    label: 'Pin Chat',
    subIcon: <IconWithProps icon={PinIcon} />,
  },
  {
    id: 2,
    label: 'Mark Unread',
    subIcon: <IconWithProps icon={ChatDotsIcon} />,
  },
] : [
  {
    id: 1,
    label: 'Pin Chat',
    subIcon: <IconWithProps icon={PinIcon} />,
  },
];

const menuItemsUnpinned = isUnreadStateRefactorEnabled ? [
  {
    id: 1,
    label: 'Unpin Chat',
    subIcon: <IconWithProps icon={PinIcon} />,
  },
  {
    id: 2,
    label: 'Mark Unread',
    subIcon: <IconWithProps icon={ChatDotsIcon} />,
  },
] : [
  {
    id: 1,
    label: 'Pin Chat',
    subIcon: <IconWithProps icon={PinIcon} />,
  },
];

const ROW_TYPES = {
  PINNED_HEADER: 'pinnedHeader',
  UNPINNED_HEADER: 'unpinnedHeader',
  END_OF_LIST: 'endOfList',
};

export { menuItems, menuItemsUnpinned, ROW_TYPES };
