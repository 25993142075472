import _ from 'lodash'
import { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { persistUserProperties } from '../../../actions/profile'
import { sortItems } from '../../../actions/ui'
import { usePrevious } from '../../Hooks/usePrevious'

/**
 * usePersistentProperties
 * hook to allow easy persistence of user properties
 * @param {ref} input ref of input
 * @param disallowedProperties object representing blocked properties based on permissions
* */
const usePersistentProperties = (args) => {
  const {
    location, defaults, onPropertyChange, disallowedProperties, sortOrder,
  } = args || {}
  const [initialSet, setInitialSet] = useState(false)
  const rootLocation = _.first((location || '').split('.'))
  const childLocation = (location || '').substr(rootLocation.length + 1)
  const propertiesStore = useSelector((state) => state?.profile?.properties);
  const allProperties = useSelector((state) => _.get(state, `profile.properties.${rootLocation}`, {}), shallowEqual) // so we can maintain all when we put
  const initialProperties = useSelector((state) => _.get(state, `profile.properties.${location}`), shallowEqual)
  const dispatch = useDispatch()

  const [localProperties, setLocalProperties] = useState()

  const filterDisallowedProps = (props) => {
    const filteredProps = { ...props }
    Object.keys(disallowedProperties).forEach((k) => {
      const propValue = _.get(props, k)
      const disallowedValue = disallowedProperties[k]
      const notAllowed = _.isFunction(disallowedValue)
        ? disallowedValue(propValue) : disallowedValue === propValue

      if (!notAllowed) delete filteredProps[k]
    })

    return filteredProps
  }

  const setInitialPropertiesWithDefaults = (props) => {
    const hasDisallowedProps = disallowedProperties && Object.keys(disallowedProperties).length
    const filteredProps = hasDisallowedProps ? filterDisallowedProps(props) : props

    if (props) {
      setLocalProperties({
        ...defaults, // has to be BEFORE props
        ...filteredProps,
      })
      setInitialSet(true)
    } else if (!_.isNull(allProperties)) {
      setInitialSet(true)
    }
  }
  useEffect(() => {
    setInitialPropertiesWithDefaults(initialProperties)
  }, [initialProperties])

  const setProperties = (props) => {
    // So we don't lose saved properties on other tabs
    const allProps = {
      ...localProperties,
      ...props,
    }
    setLocalProperties(allProps)

    const modifiedAllProperties = { ...allProperties }
    _.set(modifiedAllProperties, childLocation, allProps)

    if (allProperties !== modifiedAllProperties) {
      dispatch(persistUserProperties({ [rootLocation]: modifiedAllProperties }))
      if (sortOrder) {
        // Return to default order in case of a reload due to a filter
        dispatch(sortItems(sortOrder.type, sortOrder.order.column, 'DESC'))
      }
    }
  }

  const modifyProperty = (newProp) => {
    const newProperties = {
      ...localProperties,
      ...newProp,
    }
    setProperties(newProperties)
  }

  const prevProperties = usePrevious(localProperties)

  useEffect(() => {
    if (localProperties && !_.isEqual(prevProperties, localProperties)) {
      if (onPropertyChange) {
        onPropertyChange(localProperties)
      }
    }
  }, [localProperties])

  useEffect(() => {
    if (propertiesStore?.identityId && !propertiesStore?.filters) {
      // sets the tab to My Inbox if never been set before
      modifyProperty({
        tabSelection: 'assigned',
        operators: null,
        onlyActive: false,
        mentions: null,
      });
    }
  }, [propertiesStore?.identityId, propertiesStore?.filters]);

  return {
    properties: localProperties,
    setProperties,
    modifyProperty,
    initialSet,
  }
}

export default usePersistentProperties
