import { useSelector } from 'react-redux';

import { StateType } from '@app/types/reducer-state';

/**
 * Custom hook to verify if the user has the required permissions
 * @param permissions Array of permissions to verify
 * returns Object with user's permissions as keys
 */
export default (permissions: Array<string>) => {
  const authPermissions = useSelector(({ auth }: StateType) => auth?.permissions);
  return authPermissions.reduce((acc: Record<string, boolean>, permission) => {
    if (permissions.includes(permission)) {
      acc[permission] = true;
    }
    return acc;
  }, {});
};
