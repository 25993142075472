/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AudioIcon from '../../../../images/icons/icon-thumbnail-audio-file.svg';

const MediaAudio = ({ fileUrl, providerId, isThumbnail }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (isThumbnail) {
      return () => {};
    }
    const audioElement = audioRef.current;
    const preventAutoPlay = (event) => {
      event.preventDefault();
      audioElement.pause();
    };
    audioElement.addEventListener('click', preventAutoPlay);
    return () => {
      audioElement.removeEventListener('click', preventAutoPlay);
    };
  }, [isThumbnail]);

  return (
    isThumbnail
      ? (
        <AudioIcon title="audio-file" />
      )
      : (
        // TODO: to enable captions would need to generate
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio
          ref={audioRef}
          className={`audio-display audio-display-${isThumbnail ? 'thumbnail' : 'expanded'}`}
          controls={!isThumbnail}
          controlsList="nofullscreen nodownload noplaybackrate"
          aria-label="Audio Player"
          preload="metadata"
          data-testid={`media-audio-${providerId}`}
        >
          {/* <track kind="captions" /> */}
          <source src={fileUrl} type="audio/mp4" />
        </audio>
      )
  );
};

MediaAudio.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  isThumbnail: PropTypes.bool,
};

MediaAudio.defaultProps = {
  isThumbnail: false,
};

export default MediaAudio;
