import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { operatorType } from '../../../models/marleyTypes';
import withLazyLoad from '../../withLazyLoad/withLazyLoad';
import withLoading from '../../HigherOrderComponents/withLoading';

const SearchOperators = ({
  excludeIdList, isLoading, modifyQuery, onSelect, searchText, loadedItems, ListItem, className,
}) => {
  useEffect(() => {
    if (searchText && searchText.length > 0) {
      modifyQuery({ searchText });
    } else {
      modifyQuery({ searchText: null });
    }
  }, [searchText]);

  const filteredOperators = loadedItems
    .filter((o) => !(excludeIdList || []).includes(o.id))
    .map((o) => ({
      id: o.id,
      label: o.name,
      imageUrl: o.imageUrl,
      email: o.email,
      title: o.title,
      role: o.role,
    }));
  return <ListItem displayEmail data-test="search-operators" isLoading={isLoading} className={className} onToggle={(id) => onSelect(id)} items={filteredOperators} />;
};

const SearchOperatorsWithLoading = withLoading(SearchOperators, { type: operatorType });
const SearchUsers = withLazyLoad(SearchOperatorsWithLoading, {
  type: operatorType,
  sort: { column: 'name', order: 'DESC' },
  listLocation: 'users',
  disableInitialFetch: true,
});

SearchOperators.propTypes = {
  excludeIdList: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  modifyQuery: PropTypes.func,
  onSelect: PropTypes.func,
  searchText: PropTypes.string,
  loadedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      email: PropTypes.string,
      title: PropTypes.string,
      role: PropTypes.string,
    }),
  ),
  ListItem: PropTypes.elementType,
  className: PropTypes.string,
};

SearchOperators.defaultProps = {
  excludeIdList: [],
  isLoading: false,
  modifyQuery: () => {},
  onSelect: () => {},
  searchText: '',
  loadedItems: [],
  ListItem: null,
  className: '',
};

export default SearchUsers;
