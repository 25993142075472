/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { useDispatch } from 'react-redux';

// eslint-disable-next-line import/no-named-as-default
import { bulkUpdateHelper } from '../../../helpers/cases'
import { jobBulkUpdateRequest } from '../../../actions/job'
import MarleyModal from '../../elements/Modal/Modal';
import ButtonRow from '../../elements/buttons/ButtonRow/ButtonRow';
import Button from '../../elements/buttons/Button/Button';
import './CloseCaseConfirmation.less'

const CloseCaseConfirmation = ({ show, toggleModal, selectedJobs }) => {
  const dispatch = useDispatch()
  const numJobs = (selectedJobs && selectedJobs.length) || 0

  const handleSubmitCaseClose = () => {
    const jobsToClose = bulkUpdateHelper(selectedJobs, 'isOpen', false)
    dispatch(jobBulkUpdateRequest(jobsToClose, { preUpdateText: 'Closing', postUpdateText: 'closed' }))
    toggleModal(false)
  }

  return (
    <MarleyModal
      className="bulk-close-modal"
      title={`Are you sure you want to close ${numJobs} case${numJobs > 1 ? 's' : ''}?`}
      show={show}
      toggleModal={toggleModal}
      size="md"
    >
      <div className="message">
        You can reopen these cases through the action button in the closed cases list..
      </div>
      <ButtonRow>
        <Button type="positive" onClick={handleSubmitCaseClose}>Close All Cases</Button>
      </ButtonRow>
    </MarleyModal>
  )
}

export default CloseCaseConfirmation
