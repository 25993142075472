export const colors = {
  blue: {
    100: '#C4CEF8',
    200: '#A4B6F5',
    300: '#839FF0',
    400: '#5C88EC',
    50: '#E2E6FC',
    500: '#1B73E7',
    600: '#225BB4',
    700: '#224484',
    800: '#1D2F57',
    900: '#151B2D',
  },
  gray: {
    100: '#E4E7EB',
    200: '#CBD2D9',
    300: '#9AA5B1',
    400: '#7B8794',
    50: '#F5F7FA',
    500: '#616E7C',
    600: '#52606D',
    700: '#3E4C59',
    800: '#323F4B',
    900: '#1F2933',
  },
  green: {
    100: '#BDE4D4',
    200: '#9BD6C0',
    300: '#78C8AB',
    400: '#4FB997',
    50: '#DEF1EA',
    500: '#00AB84',
    600: '#158668',
    700: '#1A634E',
    800: '#184235',
    900: '#12241E',
  },
  navy: {
    100: '#B7BED2',
    200: '#8B96B8',
    300: '#60709F',
    400: '#364885',
    50: '#E2E6EC',
    500: '#263456',
    600: '#202B48',
    700: '#19223A',
    800: '#13192C',
    900: '#1a202c',
  },
  orange: {
    100: '#FFE1CA',
    200: '#FFD1B0',
    300: '#FFC296',
    400: '#FFB179',
    50: '#FFF0E4',
    500: '#FCA463',
    600: '#D48B55',
    700: '#905F3C',
    800: '#5E402A',
    900: '#312319',
  },
  red: {
    100: '#FFBCB1',
    200: '#FC9A8C',
    300: '#F47768',
    400: '#EA4F46',
    50: '#FFDED7',
    500: '#DE0A26',
    600: '#AE1820',
    700: '#811A1A',
    800: '#561814',
    900: '#2E120C',
  },
  marleyRed: {
    500: '#F2685F',
  },
  marleyBlue: {
    500: '#44C7E9',
  },
};

export default colors;
