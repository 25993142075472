import {
  ModalBody, ModalFooter, Button, ButtonGroup, Icon,
} from '@chakra-ui/react';
import { AddCircleIcon } from '@himarley/unity';
import React from 'react';

import { ROLE_OPERATOR, ROLE_ENDUSER } from '@app/constants/general';

import { defaultRelationships } from './relationships';
import ViewParticipantItem from './ViewParticipantItem';

import './ViewParticipantList.less';

interface Participant {
  id: string,
  imageUrl: string,
  name: string,
  role: string,
  relationship: string,
}

const sortedActive = (participants: Participant[]) => {
  // Primary Contact
  // primary operator
  // secondary operators
  // other active users
  const primaryEndUser = participants.filter((p) => (
    p.relationship === defaultRelationships.primaryEndUser
  ));
  const primaryOperator = participants.filter((p) => (
    p.relationship === defaultRelationships.primaryOperator
  ));
  const operators = participants.filter((p) => (
    p.role === ROLE_OPERATOR && p.relationship !== defaultRelationships.primaryOperator
  ));
  const endUsers = participants.filter((p) => (
    p.role === ROLE_ENDUSER && p.relationship !== defaultRelationships.primaryEndUser
  ));

  return [
    ...primaryEndUser,
    ...primaryOperator,
    ...operators,
    ...endUsers,
  ];
};

interface ViewParticipantListProps {
  activeParticipants: Participant[];
  pendingParticipants: Participant[];
  inactiveParticipants: Participant[];
  loggedInOperatorId: string;
  primaryEndUserId: string;
  onClickRemoveParticipant: (participantId: string) => void;
  onClose: () => void;
  onClickAddParticipant: () => void;
  onClickEditParticipant: (participantId: string) => void;
  onClickReinviteParticipant: (participantId: string) => void;
  enableAddParticipants: boolean;
}

const ViewParticipantList: React.FC<ViewParticipantListProps> = ({
  activeParticipants,
  pendingParticipants,
  inactiveParticipants,
  loggedInOperatorId,
  primaryEndUserId,
  onClickRemoveParticipant,
  onClose,
  onClickAddParticipant,
  onClickEditParticipant,
  onClickReinviteParticipant,
  enableAddParticipants,
}) => (
  <>
    <ModalBody>
      <div className="view-participant-header">
        <div className="view-participant-header-participant">
          Participant
        </div>
        <div className="view-participant-header-phone-number">
          Phone Number
        </div>
        <div className="view-participant-header-participant-status">
          Participant Status
        </div>
        <div className="view-participant-header-actions">
          Actions
        </div>
      </div>
      <div className="view-participant-list-body">
        {
          sortedActive(activeParticipants).map((participant) => (
            <ViewParticipantItem
              key={participant.id}
              participant={participant}
              isLoggedInOperator={loggedInOperatorId === participant.id}
              isPrimaryEndUser={primaryEndUserId === participant.id}
              status="Active"
              onClickRemoveParticipant={onClickRemoveParticipant}
              onClickEditParticipant={onClickEditParticipant}
              onClickReinviteParticipant={onClickReinviteParticipant}
            />
          ))
        }
        {
          pendingParticipants.map((participant) => (
            <ViewParticipantItem
              key={participant.id}
              participant={participant}
              isPrimaryEndUser={primaryEndUserId === participant.id}
              status="Invited"
              onClickRemoveParticipant={onClickRemoveParticipant}
              onClickEditParticipant={onClickEditParticipant}
              onClickReinviteParticipant={onClickReinviteParticipant}
            />
          ))
        }
        {
          inactiveParticipants.map((participant) => (
            <ViewParticipantItem
              key={participant.id}
              participant={participant}
              status="Removed"
              onClickRemoveParticipant={onClickRemoveParticipant}
              onClickEditParticipant={onClickEditParticipant}
              onClickReinviteParticipant={onClickReinviteParticipant}
            />
          ))
        }
      </div>
    </ModalBody>
    <ModalFooter>
      <ButtonGroup>
        <Button
          data-testid="view-participant-list-close"
          onClick={onClose}
          variant="outline"
        >
          Close
        </Button>
        {enableAddParticipants && (
          <Button
            data-testid="view-participant-list-add"
            onClick={onClickAddParticipant}
            leftIcon={<Icon as={AddCircleIcon} />}
          >
            Add Participant
          </Button>
        )}
      </ButtonGroup>
    </ModalFooter>
  </>
);

export default ViewParticipantList;
