import React from 'react';

import { formatDate } from '@app/helpers/datetime';
import { capitalizeFirstLetter } from '@app/helpers/format';
import { languagesById } from '@app/helpers/languages';
import ArchiveIcon from '@images/icons/archive.svg';
import OpenFolderIcon from '@images/icons/folder-open.svg';

import Alert from '../Alert/alert';

interface CaseMatchAlertProps {
  cases: Array < MatchedCase >;
  defaultShowAlert: boolean;
  isSummary: boolean;
  onClose?: () => void;
}

interface MatchedCase {
  _id: string;
  isOpen: boolean;
  type: string;
  referenceId: string;
  lineOfBusiness: {
    type: string;
    displayName: string;
  };
  languagePreference: string;
  createdAt: string;
}

type LanguagesById = {
  [id: string]: string;
};

const caseTypePlurals: { [key: string]: string } = {
  CLAIM: 'claims',
  POLICY: 'policies',
  CASE: 'cases',
};

const renderTitle = (cases: Array<MatchedCase>, isSummary: boolean) => {
  if (cases.length <= 0) return 'No matching cases found';
  if (!isSummary) return 'Recent case activity for this contact';
  const caseType = cases[0].type;
  if (cases.length === 1) {
    return `Matching ${caseType.toLowerCase()} found. Do you want to create a duplicate?`;
  }
  return `${cases.length} matching ${caseTypePlurals[caseType]} found.`;
};

const renderCase = (c: MatchedCase, isSummary: boolean) => {
  const lineOfBusiness = c.lineOfBusiness ? `| ${capitalizeFirstLetter(c.lineOfBusiness.type)} ${capitalizeFirstLetter(c.lineOfBusiness.displayName)}` : '';
  const languagePreferenceFull: string = (languagesById as LanguagesById)[c.languagePreference]?.replace(' (Default)', '');
  const { languagePreference: languagePreferenceId } = c;

  const openInfo = c.isOpen ? (
    <>
      <OpenFolderIcon />
      &nbsp;&nbsp;OPEN
    </>
  ) : (
    <>
      <ArchiveIcon />
      &nbsp;&nbsp;CLOSED
    </>
  );
  const caseInfoText = `${capitalizeFirstLetter(c.type)} #${c.referenceId} ${lineOfBusiness}`;
  const caseInfo = isSummary ? caseInfoText : <a href={`/chats/${c._id}`}>{caseInfoText}</a>;
  const languageInfo = `${languagePreferenceId?.toUpperCase()} - ${languagePreferenceFull}`;
  const createdInfo = `Created ${formatDate(c.createdAt, 'human')}`;

  return (
    <li key={c._id}>
      {openInfo}
      &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
      {caseInfo}
      &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
      {languageInfo}
      &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
      {createdInfo}
    </li>
  );
};

const renderMessage = (cases: Array<MatchedCase>, isSummary: boolean) => {
  if (!isSummary || cases.length === 1) {
    return (
      <ul>
        {cases.map((c) => renderCase(c, isSummary))}
      </ul>
    );
  }
  return <div>Do you want to create a duplicate?</div>;
};

const CaseMatchAlert: React.FC<CaseMatchAlertProps> = ({
  cases,
  defaultShowAlert = false,
  isSummary = false,
  onClose = () => {}, // Provide a default empty function for onClose
}) => {
  const title = renderTitle(cases, isSummary);
  const message = renderMessage(cases, isSummary);
  const { _id: caseId, referenceId } = cases[0] || {};
  const href = cases.length === 1 ? `/chats/${caseId}` : `/cases?searchFilter=${referenceId}`;
  const text = cases.length === 1 ? 'Go to case' : 'Go to cases';
  const link = {
    href,
    text,
  };

  if (isSummary) {
    return (
      <Alert
        title={title}
        message={message}
        link={link}
        show={defaultShowAlert}
        onClose={onClose}
      />
    );
  }
  return <Alert title={title} message={message} show={defaultShowAlert} onClose={onClose} />;
};

export default CaseMatchAlert;
