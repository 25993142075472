class MarleyType {
  constructor(lowercaseSingular, stateName) {
    this.lowercaseSingular = lowercaseSingular
    this.stateName = stateName
  }

  id() {
    return this.lowercaseSingular
  }

  title() {
    return this.lowercaseSingular.charAt(0).toUpperCase() + this.lowercaseSingular.slice(1)
  }

  allUpperCase() {
    return this.lowercaseSingular.toUpperCase()
  }

  reducerCase() {
    return this.allUpperCase()
  }

  stateLocation() {
    return this.stateName || this.lowercasePlural()
  }

  lowercasePlural() {
    const item = this.lowercaseSingular
    const end = item.slice(-1)
    let pluralend = 's'
    switch (end) {
      case 'y':
        pluralend = 'ies'
        break
      case 's':
        pluralend = 's'
        break
      default:
        pluralend = `${end}s`
    }
    return `${item.slice(0, item.length - 1)}${pluralend}`
  }

  capitalizedPlural() {
    return this.lowercasePlural().charAt(0).toUpperCase() + this.lowercasePlural().slice(1)
  }
}

export const caseType = new MarleyType('case', 'jobs')
export const policyType = new MarleyType('policy')
export const claimType = new MarleyType('claim')
export const userType = new MarleyType('user')
export const operatorType = new MarleyType('operator', 'operators')
export const groupType = new MarleyType('group', 'groups')
export const subGroupType = new MarleyType('subgroup');
export const queueType = new MarleyType('queue')
export const analyticsType = new MarleyType('analytics')
export const templateType = new MarleyType('template', 'templates')
export const massNotificationType = new MarleyType('mass notification')
