/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ThumbnailOverlay from '../../../../images/icons/icon-video-play-overlay.svg';

import './MediaVideo.less';

const renderVideo = ({
  providerId, videoRef, isThumbnail, fileUrl,
}) => (
  // TODO: to enable captions would need to generate
  // eslint-disable-next-line jsx-a11y/media-has-caption
  <video
    ref={videoRef}
    className={`video-display video-display-${isThumbnail ? 'thumbnail' : 'expanded'}`}
    controls={!isThumbnail}
    disablePictureInPicture
    controlsList="nofullscreen nodownload noplaybackrate"
    aria-label="Video Player"
    preload="metadata"
    data-testid={`media-video-${providerId}`}
  >
    {/* <track kind="captions" /> */}
    <source src={fileUrl} type="video/mp4" />
  </video>

);

const MediaVideo = ({ fileUrl, providerId, isThumbnail }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    const preventAutoPlay = (event) => {
      event.preventDefault();
      videoElement.pause();
    };
    videoElement.addEventListener('click', preventAutoPlay);
    return () => {
      videoElement.removeEventListener('click', preventAutoPlay);
    };
  }, []);
  return (
    isThumbnail
      ? (
        <div className="video-thumbnail-overlay-wrap">
          {
          renderVideo({
            providerId, videoRef, isThumbnail, fileUrl,
          })
          }
          <ThumbnailOverlay />
        </div>
      )
      : (
        renderVideo({
          providerId, videoRef, isThumbnail, fileUrl,
        })
      )

  );
};

MediaVideo.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  isThumbnail: PropTypes.bool,
};

MediaVideo.defaultProps = {
  isThumbnail: false,
};

export default MediaVideo;
