/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import './CheckBoxInput.less'

const CheckBoxInput = (props) => {
  const {
    display, value, onChange, uniqueKey, isChecked,
  } = props

  const handleOnChange = () => {
    if (onChange) {
      onChange({ target: { value, checked: !isChecked } })
    }
  }

  return (
    <div className="checkbox-input">
      <div onClick={handleOnChange} className="checkbox-sub">
        <input
          type="checkbox"
          key={uniqueKey}
          value={value}
          checked={isChecked}
          onChange={() => null}
        />
        <span className={`checkmark ${display ? 'checkmark-display' : ''}`} />
      </div>
    </div>
  )
}

CheckBoxInput.propTypes = {
  display: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  uniqueKey: PropTypes.string,
  isChecked: PropTypes.bool,
}

CheckBoxInput.defaultProps = {
  display: false,
  value: '',
  onChange: undefined,
  uniqueKey: '',
  isChecked: false,
}

export default CheckBoxInput
