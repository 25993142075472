import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import './ErrorModal.less';

interface ErrorModalProps {
  show: boolean;
  title: string;
  children: React.ReactNode;
  checkedBrowser: (checked: boolean) => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  show,
  title,
  children,
  checkedBrowser,
}: ErrorModalProps) => {
  const [showModal, setShowModal] = useState<boolean>(show);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    checkedBrowser(true);
  }, [checkedBrowser]);

  return (
    <Modal
      className="error-modal"
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{children}</p>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
