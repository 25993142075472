import _ from 'lodash';
import Company from './company';
import { localToRemoteUrl } from '../helpers/urls';
import { cleanPhone, formatName } from '../helpers/format';
import { formatDate } from '../helpers/datetime';

class User {
  constructor(user) {
    this.id = user.id || user._id;
    const profile = user.profile || { firstName: '', lastName: '' };
    this.imageUrl = localToRemoteUrl(profile.imageUrl || user.imageUrl);
    this.firstName = user.firstName || profile.firstName;
    this.lastName = user.lastName || profile.lastName;
    this.nameTableFormat = formatName({ firstName: this.firstName, lastName: this.lastName });
    this.name = `${this.firstName} ${this.lastName}`;
    this.lastActivity = user.lastActivity && formatDate(new Date(user.lastActivity), 'human');
    this.lastLoginTime = user.lastLoginTime && formatDate(new Date(user.lastLoginTime), 'human');
    this.lastLoginRaw = user.lastLoginTime;

    this.archived = user.active !== undefined ? !user.active : false;

    this.activated = user.verified && !user.blocked && !user.locked;
    this.verified = user.verified;
    this.blocked = user.blocked;
    this.locked = user.locked;
    this.inChatBotMode = user.inChatBotMode;
    this.isAutoAssign = user.isAutoAssign || false;
    this.optIn = user.optIn;

    this.email = user.email;
    this.phone = _.get(user, 'profile.phoneNumber', user.phoneNumber);
    this.phoneNumber = cleanPhone(this.phone);
    this.contactNumber = _.get(user, 'profile.contactNumber', user.contactNumber);
    this.title = user.profile?.title;
    this.displayPhoneNumber = this.phoneNumber
    this.organization = user.organization && new Company(user.organization)
    this.company = user.organization && user.organization.name
    this.role = user.role
    this.roles = user.roles
    this.branding = user.branding
    this.groups = user.groups
    this.groupLeads = user.groupLeads
    this.inTranslationMode = user.inTranslationMode
    this.languagePreference = user.languagePreference
    this.relationships = user.relationships || []

    let uniqueId = user.oktaUserId;
    if (user?.organization?.prefix) {
      const regex = new RegExp(`^(${user.organization.prefix}-)`);
      uniqueId = uniqueId.replace(regex, '');
    }
    this.oktaUserId = uniqueId;
  }
}

export default User;
