import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import MultiStateButton from '../../Form/MultiStateButton/MultiStateButton'
import { toggleConfirmation } from '../../../actions/ui'
import './ConfirmationModal.less'



class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: props.isConfirmationOpen,
      showSuccess: false,
    }
    this.close = this.close.bind(this)
    this.submit = this.submit.bind(this)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const traditionalId = 'isConfirmationOpen'
    if (!newProps.requestId) {
      if (_.get(this.props, traditionalId) !== _.get(newProps, traditionalId)) {
        this.setState({ showModal: _.get(newProps, traditionalId), showSuccess: false })
      }
    } else if (_.get(this.props, traditionalId) !== _.get(newProps, traditionalId)
      && _.get(newProps, traditionalId)) {
      this.setState({ showModal: true, showSuccess: false })
    } else if (_.get(newProps, 'process.success')) {
      //THIS IS NOT A SUSTAINABLE FIX, BUT SHOULD GET US BY
      clearTimeout(this.closeTimeout)
      this.closeTimeout = setTimeout(() => this.close(), 2000)
    }
  }

  componentWillUnmount() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout)
    }
  }

  close() {
    const { id, action } = this.props
    if (this.props.close) this.props.close()
    setTimeout(() => this.props.toggleConfirmation({
      id,
      isOpen: false,
    }), 500)
    if (action) action()
    this.setState({ showModal: false })
  }

  submit() {
    this.props.open(this.props.selectedId)
    if (!this.props.requestId) this.close()
  }

  render() {
    const {
      className, title, children, footer, process, successText, errorText,
      openButtonStates, cancelButtonStates, helpText } = this.props

    const { requesting, success, error } = process
    let currentStateId = 'preRequest'
    if (requesting) {
      currentStateId = 'requesting'
    } else if (success) {
      currentStateId = 'success'
    } else if (error) {
      currentStateId = 'error'
    }

    const formattedTitle = (typeof title === 'string') ? title : _.get(title, currentStateId)
    // TODO: is this supposed to be instanceof? https://marley.atlassian.net/browse/HMB-4826
    // eslint-disable-next-line valid-typeof
    const formattedHelpText = (typeof title === 'helpText') ? helpText : _.get(helpText, currentStateId)

    return (
      <Modal className={`confirmation-modal ${className || ''}`} show={this.state.showModal} onHide={this.close}>
        <Modal.Header closeButton>
          <Modal.Title>
            {formattedTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
          {formattedHelpText}
        </Modal.Body>
        <Modal.Footer>
          <div className="confirmation-modal-footer">
            {process.success && successText}
            {errorText || process.error}
            <div className="confirmation-modal-buttons">
              {openButtonStates &&
                <MultiStateButton
                  className="confirmationButton submitConfirmation"
                  onClick={this.submit}
                  currentStateId={currentStateId}
                  states={openButtonStates}
                />}
              {cancelButtonStates &&
                <MultiStateButton
                  className="confirmationButton closeConfirmation"
                  onClick={this.close}
                  currentStateId={currentStateId}
                  states={cancelButtonStates}
                />}
            </div>
            {footer || ''}
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

ConfirmationModal.propTypes = {
  id: PropTypes.string.isRequired,
  close: PropTypes.func,
  open: PropTypes.func.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  successText: PropTypes.string,
  errorText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  requestId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object)
  ]),
  process: PropTypes.instanceOf(Object),
  footer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object)
  ]),
  isConfirmationOpen: PropTypes.bool.isRequired,
  openButtonStates: PropTypes.instanceOf(Object),
  cancelButtonStates: PropTypes.instanceOf(Object),
  selectedId: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]),
}

ConfirmationModal.defaultProps = {
  title: '',
  errorText: '',
  className: '',
  successText: '',
  process: {},
  footer: '',
  requestId: '',
  openButtonStates: {},
  cancelButtonStates: {},
}

const mapStateToProps = (state, ownProps) => {
  const returnState = {
    selectedId: _.get(state, `ui.confirmationIsOpen.${ownProps.id}.selectedId`, ''),
    isConfirmationOpen: _.get(state, `ui.confirmationIsOpen.${ownProps.id}.isOpen`, false),
    process: _.get(state, `ui.requests.${ownProps.requestId}`, {}),
  }
  return returnState
}

export { ConfirmationModal }
export default connect(mapStateToProps, { toggleConfirmation })(ConfirmationModal)
