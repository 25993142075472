import _ from 'lodash';

/**
 * If there is an active day in the auto reply object then return it.
 * @param {object} reply - auto reply message object
 * @return {object} a single active day
 */
export function getActiveDay(reply) {
  if (
    _.has(reply, 'sendCriteria.timing')
    && _.has(reply, 'sendCriteria.days')
    && reply.sendCriteria.timing === 'SPECIFIED'
  ) {
    return Object.values(reply.sendCriteria.days).find((day) => day.active);
  }
  return null;
}
/**
 * Check to see if any days are active in the days obect
 * @param {object} days - Object containing the days and times info for an auto reply message
 * @return {boolean} true if an active day is found and false otherwise
 */
export function anyActiveDays(days) {
  for (const key in days) {
    if (days[key].active === true) {
      return true;
    }
  }
  return false;
}

export function detectTimezone() {
  const today = new Date();
  const short = today.toLocaleDateString(undefined);
  const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });
  const abbrev = today.toLocaleDateString(undefined, {
    timeZoneName: 'short',
  });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const longNameTrimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
    const abbrevTrimmed = abbrev.substring(0, shortIndex)
      + abbrev.substring(shortIndex + short.length);
    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    const longName = longNameTrimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
    const abbrevName = abbrevTrimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
    const fullTimezoneName = `${longName} (${abbrevName})`;
    const defaultTimeZoneObj = { id: abbrevName, label: fullTimezoneName };
    return defaultTimeZoneObj;
  }
}

export const daysOfTheWeek = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thur: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
};

export const fullDaysToShort = {
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thur',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
};

// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const timeZoneOptions = [
  { id: 'AST/ADT', label: 'Atlantic Time (AST/ADT)' },
  { id: 'EST/EDT', label: 'Eastern Time (EST/EDT)' },
  { id: 'CST/CDT', label: 'Central Time (CST/CDT)' },
  { id: 'MST/MDT', label: 'Mountain Time (MST/MDT)' },
  { id: 'PST/PDT', label: 'Pacific Time (PST/PDT)' },
  { id: 'AKST/AKDT', label: 'Alaska Time (AKST/AKDT)' },
  { id: 'HST', label: 'Hawaii Time (HST)' },
];

/**
 * Function to create the contents of the time dropdown and restrict what times are added if a timespan is provided.
 * @param {object} timespan { time : "before/after", hour: 4, minute: 0 } object used as an indicator to describe what time values should get populated in the dropdown
 * @return {Array} the array of times to be displayed in the dropdown.
 */
export function displayTimes(timespan = null) {
  // calculating military times for each id and 12 hour times for the labels
  const times = [];
  for (let hour = 0; hour <= 23; hour++) {
    // eslint-disable-next-line no-nested-ternary
    const labelHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
    const meridiem = hour >= 12 ? 'PM' : 'AM';
    for (const minute of ['00', '30']) {
      // if the timespan parameter is passed we want to restrict the times we add based on the timespans properties
      if (
        timespan === null
        || (hour === 0 && minute === '00')
        || (timespan && timespan.type === 'after' && hour > timespan.hour)
        || (timespan
          && timespan.type === 'after'
          && timespan.hour === hour
          && minute === '30'
          && timespan.minute === 0)
        || (timespan && timespan.type === 'before' && hour < timespan.hour)
        || (timespan
          && timespan.type === 'before'
          && timespan.hour === hour
          && minute === '00'
          && timespan.minute === 30)
      ) {
        times.push({
          id: `${hour}:${minute === '00' ? '0' : minute}`,
          label: `${labelHour}:${minute} ${meridiem}`,
        });
      }
    }
  }
  return times;
}
/**
 * Function that takes a days object and constructs a string that summarizes the days and times an auto reply message gets sent
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {object} days - Object containing the days and times info for an auto reply message
 * @return {string} summary string of when the auto reply message gets sent to be displayed in the bottom right hand corner below the auto reply message
 */
export function buildSendCadence(days) {
  let sendCadence = 'Sends Mon, Tue, Wed, Thur, Fri, ';
  let [beginTime, endTime] = ['', '', ''];
  const times = displayTimes();
  for (const day in days) {
    if (days[day].active) {
      const beginObj = times.find(
        (time) => time.id
          === `${days[day].beginTime.hour}:${days[day].beginTime.minute}`,
      );
      beginTime = _.get(beginObj, 'label');
      const endObj = times.find(
        (time) => time.id === `${days[day].endTime.hour}:${days[day].beginTime.minute}`,
      );
      endTime = _.get(endObj, 'label');
    } else {
      // since the order of elements in an object is not preserved in JSON, we cannot guarantee what order the days object comes in at
      // by removing the inactive days from the complete list we get the list of active days in order
      sendCadence = sendCadence.replace(`${fullDaysToShort[day]}, `, '');
    }
  }
  sendCadence = `${sendCadence.slice(0, -2)} ${beginTime} - ${endTime}`;
  return sendCadence;
}

export const defaultGeneralAutoReply = {
  sendCriteria: {
    timeZone: 'EST/EDT',
    minutesInactiveThreshold: -1,
    days: {
      monday: {
        active: true,
        allDay: false,
        beginTime: {
          hour: 0,
          minute: 0,
        },
        endTime: {
          hour: 23,
          minute: 30,
        },
      },
      tuesday: {
        active: false,
        allDay: false,
        beginTime: {
          hour: 0,
          minute: 0,
        },
        endTime: {
          hour: 23,
          minute: 30,
        },
      },
      wednesday: {
        active: false,
        allDay: false,
        beginTime: {
          hour: 0,
          minute: 0,
        },
        endTime: {
          hour: 23,
          minute: 30,
        },
      },
      thursday: {
        active: false,
        allDay: false,
        beginTime: {
          hour: 0,
          minute: 0,
        },
        endTime: {
          hour: 23,
          minute: 30,
        },
      },
      friday: {
        active: false,
        allDay: false,
        beginTime: {
          hour: 0,
          minute: 0,
        },
        endTime: {
          hour: 23,
          minute: 30,
        },
      },
      saturday: {
        active: false,
        allDay: false,
        beginTime: {
          hour: 0,
          minute: 0,
        },
        endTime: {
          hour: 23,
          minute: 30,
        },
      },
      sunday: {
        active: false,
        allDay: false,
        beginTime: {
          hour: 0,
          minute: 0,
        },
        endTime: {
          hour: 23,
          minute: 30,
        },
      },
    },
    timing: 'ALL',
  },
  active: true,
  message: {
    during: {
      type: 'STRING',
      value: '',
    },
    after: {
      type: 'STRING',
      value: '',
    },
  },
  title: '',
  type: 'GENERAL-AUTO-REPLY',
};

export const defaultOfficeHoursReply = {
  sendCriteria: {
    timeZone: 'EST/EDT',
    minutesInactiveThreshold: 120,
    days: {
      monday: {
        active: true,
        allDay: false,
        beginTime: {
          hour: 9,
          minute: 0,
        },
        endTime: {
          hour: 17,
          minute: 0,
        },
      },
      tuesday: {
        active: true,
        allDay: false,
        beginTime: {
          hour: 9,
          minute: 0,
        },
        endTime: {
          hour: 17,
          minute: 0,
        },
      },
      wednesday: {
        active: true,
        allDay: false,
        beginTime: {
          hour: 9,
          minute: 0,
        },
        endTime: {
          hour: 17,
          minute: 0,
        },
      },
      thursday: {
        active: true,
        allDay: false,
        beginTime: {
          hour: 9,
          minute: 0,
        },
        endTime: {
          hour: 17,
          minute: 0,
        },
      },
      friday: {
        active: true,
        allDay: false,
        beginTime: {
          hour: 9,
          minute: 0,
        },
        endTime: {
          hour: 17,
          minute: 0,
        },
      },
      saturday: {
        active: false,
        allDay: false,
        beginTime: {
          hour: 9,
          minute: 0,
        },
        endTime: {
          hour: 17,
          minute: 0,
        },
      },
      sunday: {
        active: false,
        allDay: false,
        beginTime: {
          hour: 9,
          minute: 0,
        },
        endTime: {
          hour: 17,
          minute: 0,
        },
      },
    },
    timing: 'SPECIFIED',
  },
  active: true,
  message: {
    during: {
      type: 'STRING',
      value: '',
    },
    after: {
      type: 'STRING',
      value: '',
    },
  },
  title: 'Office Hours',
  type: 'OUT-OF-OFFICE',
};
