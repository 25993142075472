import React from "react";
import { daysOfTheWeek } from "../../Utils"
import "./WeekdayCheckboxes.less";
import _ from "lodash";

const WeekdayCheckboxes = ({ reply, setReply, showFullDay }) => {
  
  return (
    <div className="times-day-container">
      <div className="days-row">
        {Object.entries(daysOfTheWeek).map(([key, val]) => {
          const fullDay = showFullDay ? val : key;
          const valueLower = val.toLowerCase();
          return (
            <div key={`${key}_${val}`} className="weekday-checkblock">
              <input
                className="weekday-checkbox"
                name="weekday-checkbox"
                type="checkbox"
                checked={
                  _.has(reply, `sendCriteria.days.${valueLower}.active`)
                    ? reply.sendCriteria.days[valueLower].active
                    : false
                }
                onChange={(e) => {
                  e.persist();
                  const cloneReply = { ...reply };
                  if (_.has(cloneReply, `sendCriteria.days.${valueLower}.active`)) {
                    cloneReply.sendCriteria.days[valueLower].active = e.target.checked;
                    setReply(cloneReply);
                  }
                }}
              ></input>
              <label className="weekday-label" htmlFor="weekday-checkbox">
                {fullDay}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WeekdayCheckboxes;
