import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { useOktaAuth } from '@okta/okta-react';
import MarleyIcon from '../icons/MarleyIcon';
import { Logout } from '../Logout/Logout';
import { checkInitDone } from '../../helpers/common';
import { FAILURE, SUCCESS } from '../../constants/general';

const ErrorMessage = ({ message, loginId }) => (
  <div className="text-container">
    <div className="connectingText error">
      {`${message}\n`}
    </div>
    <div>
      {`User id: ${loginId}\n`}
    </div>
    <div>
      Please contact support@himarley.com with a screenshot of this page
    </div>
    <Logout />
  </div>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  loginId: PropTypes.string.isRequired,
};

const withLoadingScreen = (WrappedComponent) => {
  const LoadingScreen = (props) => {
    const { authState } = useOktaAuth();
    const loginId = authState?.idToken?.claims?.preferred_username;
    const { loadingStatus } = useSelector((state) => ({
      loadingStatus: checkInitDone(state),
    }));
    const { children } = props;
    let message;
    if (loadingStatus === FAILURE) {
      message = 'There was an error loading the page';
    } else if (loadingStatus === 'health-check-failure') {
      message = 'Health check failure';
    } else if (loadingStatus === 'user-lookup-failure') {
      message = 'Error fetching user information';
    } else if (loadingStatus === 'websockets-failure') {
      message = 'WebSocket initialization failure';
    }
    return loadingStatus === SUCCESS
      ? (
        <WrappedComponent>
          {children}
        </WrappedComponent>
      )
      : (
        <div className="splashScreen">
          <div className="logo-container"><MarleyIcon /></div>
          {!message
            ? <div className="connectingText"> Pulling up your dashboard </div>
            : <ErrorMessage message={message} loginId={loginId} />}
          <div className="validatorSpinner">
            {!message ? <Loader type="ThreeDots" color="#44C7E9" height="60" width="70" /> : null}
          </div>
        </div>
      );
  };

  LoadingScreen.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return LoadingScreen;
};

export default withLoadingScreen;
