/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import './MyInsights.less';
import { Button } from '@chakra-ui/react';
import ConnectedHomeHeader from './HomeHeader/HomeHeader';
import Card from './Cards/Card/Card';
import QuoteCard from './Cards/QuoteCard/QuoteCard';
import LookContent from './Content/LookContent/LookContent';
import LargeLookContent from '../LargeLookContent/LargeLookContent';
import { useCheckPermissions } from '../../helpers/common';
import { getLookerEnvironment, lookNumberConfig } from '../../helpers/analytics';
import { NEEDS_ATTENTION, NEEDS_ACTION } from '../../constants/permissions';
import { viewMyOpenConversations } from '../../actions/profile';
import { buildNewInboxFilters, LookNumber } from './helpers';

const S3_IMAGE_BUCKET = process.env.IMAGE_BUCKET || 'https://prod-himarley-static-content.s3.amazonaws.com';
const BACKGROUND = `${S3_IMAGE_BUCKET}/background.png`;

const MyInsights = () => {
  const [openLookNumber, setOpenLookNumber] = useState(null);
  const lookerEnv = getLookerEnvironment();

  const hasNeedsAction = useCheckPermissions([NEEDS_ACTION]);
  const hasNeedsAttention = useCheckPermissions([NEEDS_ATTENTION]);
  const hasCoaching = hasNeedsAction || hasNeedsAttention;

  const history = useHistory();

  const handleViewAllOpenChatsLook = () => setOpenLookNumber(lookNumberConfig[lookerEnv].openConvoDrill);
  const handleViewUnansweredOptIns = () => setOpenLookNumber(lookNumberConfig[lookerEnv].unansweredOptInsDrill);
  const handleViewWaitingOnOptIns = () => setOpenLookNumber(lookNumberConfig[lookerEnv].waitingOptInsDrill);
  const handleViewResponsesRecommended = () => setOpenLookNumber(lookNumberConfig[lookerEnv].responseRecommendedDrill);
  const quoteLookNumber = lookNumberConfig[lookerEnv].quoteOfTheDay;
  const dispatch = useDispatch();

  const closeLook = () => setOpenLookNumber(null);

  const showChats = (lookId) => {
    dispatch(viewMyOpenConversations({
      payload: { inboxFilters: buildNewInboxFilters(lookId) },
      history,
    }));
  };

  const openCoachingDoc = () => { window.open('https://www.himarley.com/coaching/'); };

  return (
    openLookNumber
      ? <LargeLookContent closeLook={closeLook} lookNumber={openLookNumber} caseId={null} />
      : (
        <div
          className="personal-insights-page"
          style={{
            backgroundImage: `url(${BACKGROUND})`,
          }}
        >
          <div className="personal-top">
            <div className="personal-header-container">
              <ConnectedHomeHeader />
              <QuoteCard>
                <LookContent dataTestId="quoteOfTheDay" lookNumber={quoteLookNumber} className="look-card" />
              </QuoteCard>
            </div>
          </div>
          <div className="personal-bottom">
            <div className="personal-card-list-container">
              <div>
                <Card>
                  <LookContent
                    lookNumber={LookNumber.OpenConversations}
                    dataTestId="openChats"
                    onClick={handleViewAllOpenChatsLook}
                    className="look-card"
                  />
                  <Button
                    variant="outline"
                    onClick={() => showChats(LookNumber.OpenConversations)}
                    data-testid="view-open-chats"
                  >
                    View Chats
                  </Button>
                </Card>
              </div>
              <div>
                <Card>
                  <LookContent
                    lookNumber={LookNumber.UnansweredOptIns}
                    dataTestId="unansweredOptIn"
                    onClick={handleViewUnansweredOptIns}
                    className="look-card"
                  />
                  <Button
                    variant="outline"
                    onClick={() => showChats(LookNumber.UnansweredOptIns)}
                    data-testid="view-unanswered-chats"
                  >
                    View Chats
                  </Button>
                </Card>
              </div>
              <div>
                <Card>
                  <LookContent
                    lookNumber={LookNumber.WaitingOnOptIns}
                    dataTestId="waitingOnOptIn"
                    onClick={handleViewWaitingOnOptIns}
                    className="look-card"
                  />
                  <Button
                    variant="outline"
                    data-testid="view-waiting-chats"
                    onClick={() => showChats(LookNumber.WaitingOnOptIns)}
                  >
                    View Chats
                  </Button>
                </Card>
              </div>
              <div>
                {/* if the customer has coaching, show full coaching filter and drill in, otherwise show learn more */}
                {hasCoaching
                  ? (
                    <Card>
                      <LookContent
                        lookNumber={LookNumber.ResponseRecommended}
                        dataTestId="responseRecommended"
                        onClick={handleViewResponsesRecommended}
                        className="look-card"
                      />
                      <Button
                        variant="outline"
                        data-testid="view-response-chats"
                        onClick={() => showChats(LookNumber.ResponseRecommended)}
                      >
                        View Chats
                      </Button>
                    </Card>
                  )
                  : (
                    <Card>
                      <LookContent
                        lookNumber={LookNumber.ResponseRecommended}
                        dataTestId="responseRecommended"
                        disabled
                        onClick={undefined}
                        className="look-card look-card-no-click"
                      />
                      <Button
                        variant="outline"
                        onClick={openCoachingDoc}
                      >
                        Learn More
                      </Button>
                    </Card>
                  )}
              </div>
            </div>
          </div>
        </div>
      )
  );
};

export default MyInsights;
