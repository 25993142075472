import { Button, Icon, Spinner } from '@chakra-ui/react';
import {
  AddCircleIcon, EyeIcon, TrashIcon, ConversationIcon, DownloadIcon, CheckmarkIcon,
} from '@himarley/unity';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getMassNotifications,
  subscribeSocket,
  deleteNotification,
  downloadErrorCSV,
  downloadCSVTemplate,
  setMessageCopied,
  downloadTranscript,
} from '@app/actions/massNotifications';
// eslint-disable-next-line import/no-named-as-default
import SortTable from '@app/components/elements/table/SortTable/SortTable';
import TableHeader from '@app/components/elements/table/SortTable/TableHeader/TableHeader';
import Panel from '@app/components/Panel/Panel';
import { formatDate } from '@app/helpers/datetime';
import { massNotificationType } from '@app/models/marleyTypes';
import { StateType } from '@app/types/reducer-state';

import {
  CREATING_MESSAGES,
  VALIDATION_COMPLETE,
  PROCESSING,
  SENDING,
  SENT,
  TRANSCRIPT_CREATED,
  FAILED,
  MAX_ENTRIES_SURPASSED,
  NO_VALID_ENTRIES,
  FAILED_TO_SEND,
  PARTIALLY_FAILED,
} from './constants';
import MassNotificationDeleteVerifyModal from './MassNotificationDeleteVerifyModal/MassNotificationDeleteVerifyModal';
import MassNotificationModal from './MassNotificationModal/MassNotificationModal';
import MassNotificationRow from './MassNotificationRow/mass-notification-row';
import MassNotificationVerificationModal from './MassNotificationVerificationModal/MassNotificationVerificationModal';
import MassNotificationViewModal from './MassNotificationViewModal/MassNotificationViewModal';

import './MassNotifications.less';

const MassNoteSpinner = () => (
  <Spinner size="sm" emptyColor="gray.50" speed="0.75s" ml={2} />
);

export const NOTIFICATION_STATUS_HASH = {
  INVALID_CSV: <span className="errorStatus">CSV is in an invalid format</span>,
  VALIDATION_FAILED: <span className="errorStatus">Unexpected failure during validation</span>,
  [VALIDATION_COMPLETE]: (
    <span className="ready-to-send">
      Ready To Send
    </span>
  ),
  VALIDATING: (
    <span className="validating">
      <span>Validating</span>
      <MassNoteSpinner />
    </span>
  ),
  CREATED: 'Validation starting shortly',
  [PROCESSING]: (
    <span className="validating">
      <span>Processing</span>
      <MassNoteSpinner />
    </span>
  ),
  [SENDING]: (
    <span className="validating">
      <span>Sending</span>
      <MassNoteSpinner />
    </span>
  ),
  [CREATING_MESSAGES]: (
    <span className="validating">
      <span>Creating Messages</span>
      <MassNoteSpinner />
    </span>
  ),
  [SENT]: (
    <span className="delivered">
      Delivered
      <Icon ml={1} as={CheckmarkIcon} />
    </span>
  ),
  [TRANSCRIPT_CREATED]: (
    <span className="delivered">
      Delivered
      <Icon ml={1} as={CheckmarkIcon} />
    </span>
  ),
  [FAILED]: <span className="errorStatus">Unexpected failure occurred</span>,
  FAILED_TO_SEND: <span className="errorStatus">Failed To Send</span>,
  PARTIALLY_FAILED: <span className="delivered">Some Messages Failed</span>,
  [MAX_ENTRIES_SURPASSED]: <span className="errorStatus">Surpassed Max Entries</span>,
  VALIDATION_TIMEOUT: <span className="errorStatus">Validation Timeout</span>,
  [NO_VALID_ENTRIES]: 'No entries in CSV were valid',
};

/* Possible status states */
/*
  INVALID_CSV:INVALID_FORMAT
  INVALID_CSV:MISSING_REQUIRED_FIELDS
  VALIDATION_FAILED:FAILED_TO_VALIDATE_ENTRIES
  VALIDATION_FAILED:FAILED_TO_WRITE_ERROR_REPORT
  VALIDATION_FAILED:FAILED_TO_ADD_ERROR_REPORT_TO_ENTRY
  VALIDATION_COMPLETE
  VALIDATING
  CREATED
*/

const MassNotifications: React.FC = () => {
  const dispatch = useDispatch();

  const massNotificationList = useSelector(
    (state: StateType) => state.massNotifications.massNotificationList,
  );

  const [showDeleteVerificationModal, setShowDeleteVerificationModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [activeMassNotification, setActiveMassNotification] = useState<
    typeof massNotificationList[0] | undefined
  >(undefined);
  const [showModal, setShowModal] = useState(false);

  const verifyNotification = (id: string) => {
    setShowVerificationModal(true);
    setActiveMassNotification(massNotificationList.find((n) => n.id === id));
  };

  const formatDateCreatedCell = ({ value }: { value: string }) => (<div>{formatDate(value, 'just-date')}</div>);
  const formatScheduledDateCell = ({ value }: { value: string }) => (
    value
      ? <div>{formatDate(value)}</div>
      : <div>-</div>
  );
  const formatStatusCell = ({ value, id }: { value: string; id: string }) => {
    const status = value && value.split(':')[0];
    const currMN = massNotificationList.find((curr) => curr.Id === id);

    if (status === CREATING_MESSAGES && currMN?.BatchesLeft && currMN.BatchesLeft !== 0) {
      return (
        <span>
          <span className="validating">
            <span>
              {currMN.BatchesLeft * 20}
              {' '}
              Msgs to Create
            </span>
            <MassNoteSpinner />
          </span>
        </span>
      );
    }

    if (currMN?.ScheduledSendDate) {
      return (
        <span>
          {
            status === VALIDATION_COMPLETE
              ? <div className="ready-to-send">Scheduled</div>
              : _.get(NOTIFICATION_STATUS_HASH, status, '') || (<div className="errorStatus">{status}</div>)
          }
        </span>
      );
    }

    return (
      <span>
        {
          status === VALIDATION_COMPLETE
            ? (
              <div
                onClick={() => verifyNotification(id)}
                onKeyDown={() => { }}
                className="sendOverlay"
                role="button"
                tabIndex={0}
              >
                {_.get(NOTIFICATION_STATUS_HASH, status, '') || (<div className="errorStatus">{status}</div>)}
              </div>
            )
            : _.get(NOTIFICATION_STATUS_HASH, status, '') || (<div className="errorStatus">{status}</div>)
        }
      </span>
    );
  };

  const tableColumns = [
    {
      sort: true, id: 'notification-name', label: 'Notification Name', class: 'class-number-head', location: 'Title',
    },
    {
      sort: true,
      id: 'date',
      label: 'Date Created',
      location: 'Date',
      numericSort: true,
      sortType: 'date',
      format: formatDateCreatedCell,
    },
    {
      sort: true,
      id: 'date',
      label: 'Scheduled Date',
      location: 'ScheduledSendDate',
      numericSort: true,
      sortType: 'date',
      format: formatScheduledDateCell,
    },
    {
      sort: true,
      id: 'sent-messages',
      label: 'Sent Messages',
      location: 'MessagesSent',
    },
    {
      sort: true,
      id: 'total-messages',
      label: 'Total Messages',
      location: 'MessagesTotal',
    },
    {
      label: 'Status',
      location: 'Status',
      id: 'status',
      format: formatStatusCell,
    },
    { label: 'Actions', id: 'actions', class: 'table-col-actions' },
  ];

  useEffect(() => {
    dispatch(subscribeSocket());
    dispatch(getMassNotifications());
  }, [dispatch]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleDeleteVerify = (id: string) => {
    setShowDeleteVerificationModal(!showDeleteVerificationModal);
    setActiveMassNotification(massNotificationList.find((n) => n.id === id));
  };

  const toggleVerifyModal = () => {
    setShowVerificationModal(!showVerificationModal);
  };

  const errorDownloadIsDisabled = (row: {
    Status: string;
  }) => row.Status === VALIDATION_COMPLETE || row.Status === SENDING
  || row.Status === PROCESSING || row.Status === FAILED || row.Status === SENT
  || row.Status === TRANSCRIPT_CREATED || row.Status === NO_VALID_ENTRIES
    || row.Status === MAX_ENTRIES_SURPASSED;

  const downloadTranscriptIsDisabled = (status: string) => (
    ![SENT, FAILED_TO_SEND, PARTIALLY_FAILED].includes(status)
  );

  const massNotificationHeaders = tableColumns.map((col) => (
    <TableHeader
      tableId="massNotifications"
      key={col.id || col.label}
      sort={col.sort}
      id={col.id}
      label={col.label}
      className={col.class}
      location={col.location}
      numericSort={col.numericSort}
      sortLocation={col.location}
      type={undefined}
    />
  ));
  const rows = massNotificationList.map((current) => (
    <MassNotificationRow
      data={current}
      key={current.Id}
      columns={tableColumns}
      actionOptions={[{
        id: 'send',
        label: 'Send Notification',
        action: (id: string) => verifyNotification(id),
        leftIcon: ConversationIcon,
        isDisabled: current.Status !== VALIDATION_COMPLETE,
      },
      {
        id: 'info',
        label: 'View Notification',
        action: (id: string) => {
          setShowViewModal(true);
          setActiveMassNotification(massNotificationList.find((n) => n.id === id));
        },
        leftIcon: EyeIcon,
      },
      {
        id: 'download_csv',
        label: 'Download CSV Template',
        action: () => dispatch(downloadCSVTemplate()),
        leftIcon: DownloadIcon,
      },
      {
        id: 'download_error',
        label: 'Download Error Report',
        action: (id: string) => {
          dispatch(downloadErrorCSV(id));
        },
        leftIcon: DownloadIcon,
        isDisabled: !errorDownloadIsDisabled(current),
      },
      {
        id: 'download_transcript',
        label: 'Download Transcript',
        action: (id: string) => dispatch(downloadTranscript(id)),
        leftIcon: DownloadIcon,
        isDisabled: downloadTranscriptIsDisabled(current.Status),
      },
      {
        id: 'delete',
        label: 'Delete Notification',
        action: (id: string) => {
          toggleDeleteVerify(id);
        },
        leftIcon: TrashIcon,
      }]}
    />
  ));

  return (
    <Panel
      className="mass-notification-page"
      header={(
        <Button
          onClick={toggleModal}
          leftIcon={<Icon as={AddCircleIcon} />}
        >
          Create Notification
        </Button>
      )}
      title="Notifications"
      titleFooter={undefined}
      titleNotification={undefined}
      right={undefined}
    >
      <SortTable
        id="notifications"
        label="notifications"
        columns={massNotificationHeaders}
        rows={rows}
        type={massNotificationType}
      />
      <MassNotificationModal show={showModal} onHide={toggleModal} />
      <MassNotificationVerificationModal
        show={showVerificationModal}
        toggleShow={toggleVerifyModal}
        activeMassNotification={activeMassNotification}
        downloadErrorCSV={(id: string) => dispatch(downloadErrorCSV(id))}
      />
      <MassNotificationDeleteVerifyModal
        show={showDeleteVerificationModal}
        toggleShow={toggleDeleteVerify}
        activeMassNotification={activeMassNotification}
        deleteNotification={(id: string) => dispatch(deleteNotification(id))}
      />
      <MassNotificationViewModal
        show={showViewModal}
        hide={() => {
          setShowViewModal(false);
          dispatch(setMessageCopied(false));
        }}
        activeMassNotification={activeMassNotification}
        setMessageCopied={(isCopied: boolean) => dispatch(setMessageCopied(isCopied))}
      />
    </Panel>
  );
};

export default MassNotifications;
