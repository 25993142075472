import React from 'react'
import { Redirect } from 'react-router-dom'
import usePermissionCheck from '../HigherOrderComponents/usePermissionCheck'
import './PermissionVerifier.less'

const PermissionVerifier = ({ hasAccess = false, children, redirect = false }) => (
  <div className="authBox">
    {hasAccess ? children : redirect ? <Redirect to="/" /> : ''}
  </div>
)

// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePermissionCheck(PermissionVerifier)
