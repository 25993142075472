import React from 'react';
import LogoOneInc from '../../../../../images/logos/logoOneInc.svg';
import Panel from '../../../Panel/Panel';
import './VendorPartners.less';
import IntegrationRow from '../AppIntegrations/IntegrationRow';

const VendorPartners = () => (
  <Panel className="group-mgmt-page" title="Vendor Partners">
    <IntegrationRow
      Logo={LogoOneInc}
      id="OneInc"
      title="One Inc"
      description="vendorPartners.oneIncDescription"
      link="https://himarley.zendesk.com/hc/en-us/articles/9702003708307-One-Inc-Integrations-Overview'"
    />
  </Panel>
)

export default VendorPartners;
