import isEmail from "validator/lib/isEmail";

// const phoneRegex = /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$/

export const validateEmail = email => email ? isEmail(email) : true
export const validatePassword = password => password && password.length > 0;
export const validatePhone = phone => {
  const removedPlusOne = phone.replace("+1", "");
  const normalizedPhone = removedPlusOne
    .split("")
    .filter(
      symbol =>
        !(symbol === ")" || symbol === "(" || symbol === " " || symbol === "-")
    )
    .join("");

  return normalizedPhone && normalizedPhone.length === 10;
};
