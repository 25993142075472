import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

const withLoading = (WrappedComponent, config) => {
  class ComponentWithLoading extends React.Component {
    render() {
      return (
        <WrappedComponent isLoading={this.props.loadingItem && !this.props.loadedItem} {...this.props}/>
      )
    }
  }

  const mapStateToProps = (state) => {
    const thisState = _.get(state, _.get(config, 'type.stateName'), {})
    const { loadingItem, loadedItem } = thisState
    return { loadingItem, loadedItem }
  }

  return connect(mapStateToProps, {})(ComponentWithLoading)
}

export default withLoading
