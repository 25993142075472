import React from 'react'
import ReactDOM from 'react-dom';
import AssignDropdown from '../AssignDropdown'
import './LineDropdown.less'
import _ from 'lodash'

let domNode
    
class LineDropdown extends React.Component {
  state = {
    open: false,
  }  
  
  componentDidMount() {
    // This is a valid use case of findDOMNode, but it is deprecated long term
    // eslint-disable-next-line react/no-find-dom-node
    domNode = ReactDOM.findDOMNode(this);
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.open !== nextProps.open) {
      this.setState({ open: nextProps.open })
    }
  }

  handleClick = (e) => {
    const { onClick } = this.props
    if (onClick) onClick(e)

    this.setState({ open: !this.state.open })
  }

  handleClickOutside = event => {
    const isAlreadyOpen = _.get(this.state, 'open', false)
    
    // only need to take this action if the dropdown is already open. had regressions where it would OPEN with outside clicks
    if (isAlreadyOpen && (!domNode || !domNode.contains(event.target))) {
        this.setState({ open: false })
    }
  }

  render() {
    const { open } = this.state
    return (
      <AssignDropdown {...this.props} open={open} onClick={this.handleClick} className={`${this.props.className || ''} line-dropdown`} />
    )
  }
}

export default LineDropdown
