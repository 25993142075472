import React from 'react';
import PropTypes from 'prop-types';
import './InfoItem.less';

const InfoItem = ({ title, value, large }) => (!title ? null : (
  <div
    className={`media-metadata-item ${large && 'large-media-metadata-item'}`}
    data-testid="media-metadata-item"
  >
    {!title ? null
      : (
        <div className="media-metadata-item-title" data-testid="media-metadata-item-title">
          {title}
        </div>
      )}
    {!value ? null : (
      <div className="media-metadata-item-value" data-testid="media-metadata-item-value">
        {value}
      </div>
    )}
  </div>
));

InfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  large: PropTypes.bool,
};

InfoItem.defaultProps = {
  large: false,
};

export default InfoItem;
