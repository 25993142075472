/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line import/prefer-default-export
export const getSelectedTagIds = (tags = new Map()) => {
  if (tags?.size < 1) {
    return [];
  }
  const selectedIds = [];
  for (const [, { isSelected, id }] of tags) {
    if (isSelected) {
      selectedIds.push(id);
    }
  }
  return selectedIds;
};
