import _ from 'lodash';
import {
  CLOSE_CHAT,
  OPEN_JOB,
  // TODO: SORT_QUEUE and FILTER_QUEUE are not exported from constants/actions.js https://marley.atlassian.net/browse/HMB-4831
  // eslint-disable-next-line import/named
  SORT_QUEUE,
  // eslint-disable-next-line import/named
  FILTER_QUEUE,
  CLEAR_QUEUE_NOTIFICATION,
  UPDATE_CLAIM_SENT,
  ADD_JOB,
  MODIFY_CHAT_HEIGHT,
  SELECT_QUEUE,
  CLOSE_JOB_TAB,
  AUDIO_NOTIFICATION,
  CLEAR_NOTIFICATION,
  MUTE_AUDIO,
  AUTH_USER,
  FILTER_USERS,
  SET_ACTIVE_CLAIM,
  SORT_TEMPLATES,
  OPEN_INACTIVITY_ALERT,
  CLOSE_INACTIVITY_ALERT,
  OPEN_PROFILE_CONFIG,
  CLOSE_PROFILE_CONFIG,
  FILTER_TEMPLATES,
  SET_CONFIG_ITEM,
  OPEN_DASHBOARD_VIEW,
  SET_RIGHT_SIDEBAR_TAB,
  CLEAN_SEND_SURVEY,
  DEAUTH_USER,
  SET_LEFT_BAR_BLUE_DOT,
  INIT_LOAD_DONE,
  UPDATE_MEDIA_MODAL,
  OPEN_MEDIA_MODAL,
  CLOSE_MEDIA_MODAL,
  SET_TEMPLATE_TRAY_IS_OPEN,
  REQUEST_GET_JOBS_ERROR,
  SET_ACTIVE_CHAT,
} from '../constants/actions';
import { formatSuccess, formatInit, formatError } from '../constants/endpoints';
import { REQUEST_PUT_USER_AUTOASSIGN_ERROR_MSG } from '../constants/apierrors';
import { ANALYTICS_TAB_DASHBOARD } from '../constants/uiState';

import { JOBS_VIEW } from '../constants/dashboardState';
import { GENERAL_INFO } from '../constants/configState';
import commonLoadingCompleted from './commonLoadingCompleted';
import { setReleaseIssueModalOpen, setReleaseIssueModalError } from '../actions/ui';
import { completeReleaseIssues } from '../actions/job';

export const initialState = {
  activeJob: undefined,
  analyticsTab: ANALYTICS_TAB_DASHBOARD,
  audioNotification: false,
  confirmationIsOpen: {},
  muted: JSON.parse(localStorage.getItem('muted')) ?? false,
  chatLevel: 0,
  activeJobs: [],
  typingByChatId: {},
  activeChatsById: [],
  rightBar: '',
  templateTrayIsOpen: false,
  usersFilter: '',
  queueFilter: '',
  claimsSort: {},
  templateSort: {},
  claimsFilter: '',
  templateFilter: '',
  dashboard: null,
  claimSaved: false,
  queueList: [
    { id: 'open', name: 'My Open Cases' },
    { id: 'closed', name: 'My Closed Cases' },
    { id: 'allOpen', name: 'All Open Cases' },
    { id: 'allClosed', name: 'All Closed Cases' },
    { id: 'unassigned', name: 'Unassigned' },
  ],
  thematicQueues: [],
  activeQueueId: 'open',
  showInactivityAlert: false,
  showProfileConfig: false,
  configId: GENERAL_INFO,
  paging: {},
  filter: {},
  sort: {
    user: {
      column: 'lastLoginTime',
      order: 'DESC',
    },
    case: {
      column: 'createdAt',
      order: 'DESC',
    },
  },
  mediaModal: {
    open: false,
    mediaIndex: null,
  },
  requests: {},
  errors: {
    REQUEST_PUT_USER_AUTOASSIGN_ERROR_MSG,
  },
  customInboxParams: {},
  savedBranding: {},
  leftBarBlueDot: [],
  pageInitLoadsFinished: false,
  chatView: {
    status: 'empty',
  },
  releaseIssueModal: {
    isOpen: false,
    selectedReleaseIssue: {},
  },
};

export const formatProcess = (type, state, payload) => {
  let process = {};
  if (state === 'init') {
    process = {
      requesting: true,
      success: false,
    };
  } else if (state === 'success') {
    process = {
      requesting: false,
      success: true,
    };
  } else if (state === 'error') {
    if (type === 'sendWelcomeEmail') {
      process = {
        requesting: false,
        success: false,
        error: _.get(
          payload,
          'message.message',
          'Sorry, welcome email cannot be sent.',
        ),
      };
    } else if (type === 'sendSurvey') {
      process = {
        requesting: false,
        success: false,
        error: _.get(payload, 'message', 'Sorry, survey cannot be sent.'),
      };
    } else {
      process = {
        requesting: false,
        success: false,
        error: _.get(payload, 'message.message', 'Sorry, system error.'),
      };
    }
  }
  const changes = {};
  changes[type] = process;
  return changes;
};

/* Add action names here for processing success/error/init in components */
export const PROCESS_HASH = {
  UPLOAD_PROFILE_IMAGE: 'profileImage',
  ACTIVE_JOB: 'activeCase',
  SEND_SURVEY: 'sendSurvey',
  SEND_WELCOME_EMAIL: 'sendWelcomeEmail',
  SEND_CASE_WELCOME_EMAIL: 'sendCaseWelcomeEmail',
  // PATCH_JOB: 'updateJob', // TODO: check that this is needed
  PUT_JOB: 'updateJob',
  RESET_EMAIL_SENT: 'resetEmailSent',
  LOAD_CLAIMS: 'loadClaims',
  ADD_CLAIM: 'addClaim',
  ADD_POLICY: 'addPolicy',
  ADD_CASE: 'addCase',
  NEW_USER: 'newUser',
  NEW_BULK_USER: 'newBulkUser',
  BULK_UPDATE_JOB: 'reassignjob',
  MASS_NOTIFICATION_SENT: 'sentMassNotification',
};

export const commonProcess = (state, action) => {
  const CLEAN_LIST = [
    'OPEN_CASE_CREATE_FORM',
    'OPEN_CLAIM_CREATE_FORM',
    'OPEN_POLICY_CREATE_FORM',
    'SET_EDIT_CLAIM',
    'SET_EDIT_POLICY',
  ];

  let returnState = state;
  CLEAN_LIST.forEach((c) => {
    if (action.type === c) {
      returnState = {
        ...state,
        requests: {},
      };
    }
  });

  Object.keys(PROCESS_HASH).forEach((k) => {
    if (action.type === formatInit(k)) {
      returnState = {
        ...returnState,
        requests: {
          ...returnState.requests,
          ...formatProcess(PROCESS_HASH[k], 'init'),
        },
      };
    } else if (action.type === formatSuccess(k)) {
      returnState = {
        ...returnState,
        requests: {
          ...returnState.requests,
          ...formatProcess(PROCESS_HASH[k], 'success'),
        },
      };
    } else if (action.type === formatError(k)) {
      returnState = {
        ...returnState,
        requests: {
          ...returnState.requests,
          ...formatProcess(PROCESS_HASH[k], 'error', action.payload),
        },
      };
    }
  });
  return returnState;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const ui = (preState = initialState, action) => {
  const state = commonProcess(
    commonLoadingCompleted(
      ['RESET_EMAIL_SENT', 'ACTIVE_CHAT'],
      preState,
      action,
    ),
    action,
  );

  switch (action.type) {
    case 'CLEAR_ACTIVE_JOB': {
      return {
        ...state,
        chatView: {
          status: 'empty',
        },
      };
    }
    case 'MOBILE_TYPE': {
      return {
        ...state,
        mobileLookup: action.payload,
      };
    }
    case 'SET_CUSTOM_INBOX_PARAMS': {
      return {
        ...state,
        customInboxParams: action.payload,
      };
    }
    case 'SET_BRANDING': {
      return {
        ...state,
        savedBranding: action.payload,
      };
    }
    case completeReleaseIssues?.pending?.type: {
      return {
        ...state,
        releaseIssueModal: {
          ...state.releaseIssueModal,
          isSubmitting: true,
        },
      };
    }
    case completeReleaseIssues?.rejected?.type: {
      return {
        ...state,
        releaseIssueModal: {
          ...state.releaseIssueModal,
          isSubmitting: false,
          hasError: true,
        },
      };
    }

    case 'INCREASE_OFFSET': {
      const type = action.payload.id;
      let typePaging = { ...state.paging[type] };
      if (!typePaging) {
        typePaging = { limit: 0, offset: 0 };
      }
      typePaging.offset = _.get(typePaging, 'offset', 0) + _.get(action.payload, 'offset', 0);
      const paging = { ...state.paging };
      paging[type] = typePaging;
      return {
        ...state,
        paging,
      };
    }
    case 'CLEAR_MOBILE_LOOKUP': {
      return {
        ...state,
        mobileLookup: null,
      };
    }
    case ADD_JOB: {
      const newJobNoNotificationQueues = ['all', 'open'];
      let queueNotification = '';
      if (newJobNoNotificationQueues.indexOf(state.activeQueueId) === -1) queueNotification = 'New job created.';
      return {
        ...state,
        queueNotification,
      };
    }
    case 'TOGGLE_CONFIRMATION': {
      const { id, isOpen, selectedId } = action.payload;
      const confirmationIsOpen = { ...state.confirmationIsOpen };
      if (id) {
        if (!confirmationIsOpen[id]) confirmationIsOpen[id] = {};
        confirmationIsOpen[id] = { isOpen, selectedId };
      }
      const changes = { confirmationIsOpen };
      if (!isOpen) changes.requests = {};
      return { ...state, ...changes };
    }
    case CLEAR_QUEUE_NOTIFICATION: {
      return {
        ...state,
        queueNotification: '',
      };
    }
    case AUDIO_NOTIFICATION: {
      return {
        ...state,
        audioNotification: true,
      };
    }
    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        audioNotification: false,
      };
    }
    case MUTE_AUDIO: {
      return {
        ...state,
        muted: action.payload,
      };
    }
    case MODIFY_CHAT_HEIGHT: {
      return {
        ...state,
        chatLevel: action.payload,
      };
    }
    case SORT_QUEUE: {
      return {
        ...state,
        queueSort: action.payload,
      };
    }
    case 'SORT_ITEMS': {
      const { column, order, type } = action.payload;
      const sort = { ...state.sort };
      _.set(sort, type, { order, column });
      return { ...state, sort };
    }
    case 'FILTER_ITEMS': {
      const { filter, type } = action.payload;
      const changes = {
        filter: { ...state.filter },
      };
      changes.filter[type] = filter;
      changes[`${type}Filter`] = filter;
      return { ...state, ...changes };
    }
    case 'CLEAR_FILTER': {
      const id = action.payload;
      const changes = {
        filter: { ...state.filter },
      };
      delete changes.filter[id];
      return { ...state, ...changes };
    }
    case FILTER_USERS: {
      return {
        ...state,
        usersFilter: action.payload,
      };
    }
    case FILTER_QUEUE: {
      return {
        ...state,
        queueFilter: action.payload,
      };
    }
    case SORT_TEMPLATES: {
      return {
        ...state,
        templateSort: action.payload,
      };
    }
    case FILTER_TEMPLATES: {
      return {
        ...state,
        templateFilter: action.payload,
      };
    }
    case AUTH_USER: {
      const { user } = action.payload;
      const thematicQueues = _.get(user, 'organization.categories') || [];
      const queueList = [...initialState.queueList];
      return { ...state, queueList, thematicQueues };
    }
    case SELECT_QUEUE: {
      const activeQueueId = action.payload;
      return { ...state, activeQueueId };
    }
    case OPEN_JOB: {
      const activeJobs = state.activeJobs.slice();
      if (activeJobs.indexOf(action.payload) === -1) {
        activeJobs.push(action.payload);
      }
      const activeJob = action.payload;
      return {
        ...state,
        dashboard: JOBS_VIEW,
        activeJobs,
        activeJob,
        chatLevel: 1,
      };
    }
    case CLOSE_JOB_TAB: {
      const activeJobs = state.activeJobs.slice();
      const { jobId } = action.payload;
      if (activeJobs.indexOf(jobId) > -1) {
        activeJobs.splice(activeJobs.indexOf(jobId), 1);
      }
      const activeJob = activeJobs.length > 0 && activeJobs[0];
      let chatLevel = 1;
      if (activeJobs.length === 0) {
        chatLevel = 0;
      }
      return {
        ...state,
        activeJobs,
        activeJob,
        chatLevel,
      };
    }

    case CLOSE_CHAT: {
      const chatArray = state.activeChatsById.slice();
      chatArray.splice(chatArray.indexOf(action.payload), 1);
      return { ...state, activeChatsById: chatArray };
    }
    // case OPEN_CLAIMS_ENTRY:
    case SET_ACTIVE_CLAIM: {
      return state; // Object.assign({}, state, { dashboard: CLAIM_EDIT_VIEW, claimSaved: false })
    }
    case OPEN_PROFILE_CONFIG: {
      return { ...state, showProfileConfig: true };
    }
    case CLOSE_PROFILE_CONFIG: {
      return { ...state, showProfileConfig: false };
    }
    case SET_RIGHT_SIDEBAR_TAB: {
      return { ...state, rightBar: action.payload };
    }
    case 'SET_ANALYTICS_TAB': {
      return { ...state, analyticsTab: action.payload };
    }
    case OPEN_DASHBOARD_VIEW: {
      return { ...state, dashboard: action.payload, showProfileConfig: false };
    }
    case UPDATE_CLAIM_SENT: {
      return { ...state, claimSaved: true };
    }
    case OPEN_INACTIVITY_ALERT: {
      return { ...state, showInactivityAlert: true, showProfileConfig: false };
    }
    case CLOSE_INACTIVITY_ALERT: {
      return { ...state, showInactivityAlert: false };
    }
    case SET_CONFIG_ITEM: {
      return { ...state, configId: action.payload };
    }
    case CLEAN_SEND_SURVEY: {
      return { ...state, requests: { ...state.requests, sendSurvey: {} } };
    }
    case DEAUTH_USER: {
      return { ...initialState };
    }
    case SET_LEFT_BAR_BLUE_DOT: {
      let updatedArray = _.cloneDeep(state.leftBarBlueDot);
      if (action.payload.set) {
        updatedArray.push(action.payload.target);
      } else {
        updatedArray = updatedArray.filter(
          (currName) => currName !== action.payload.target,
        );
      }
      return { ...state, leftBarBlueDot: updatedArray };
    }
    case SET_TEMPLATE_TRAY_IS_OPEN: {
      if ('context' in action.payload) {
        return {
          ...state,
          templateTrayIsOpen: action.payload.boolean,
          context: action.payload.context,
        };
      }
      return {
        ...state,
        templateTrayIsOpen: action.payload.boolean,
      };
    }
    case setReleaseIssueModalOpen?.type: {
      return {
        ...state,
        releaseIssueModal: {
          isOpen: action.payload.isOpen,
          selectedReleaseIssue: action.payload.selectedReleaseIssue,
          hasError: false,
        },
      };
    }
    case setReleaseIssueModalError?.type: {
      return {
        ...state,
        releaseIssueModal: {
          ...state.releaseIssueModal,
          hasError: true,
        },
      };
    }
    case INIT_LOAD_DONE: {
      return { ...state, pageInitLoadsFinished: true };
    }
    case CLOSE_MEDIA_MODAL: {
      return {
        ...state,
        mediaModal: {
          open: false,
          mediaIndex: null,
        },
      };
    }
    case OPEN_MEDIA_MODAL:
    case UPDATE_MEDIA_MODAL: {
      const { mediaIndex } = action.payload;
      return {
        ...state,
        mediaModal: {
          open: true,
          mediaIndex,
        },
      };
    }
    case 'REQUEST_ACTIVE_JOB_INIT': {
      const updatedState = {
        ...state,
        chatView: {
          status: 'loading',
        },
      };
      return updatedState;
    }
    case 'REQUEST_ACTIVE_JOB_ERROR': {
      const { name } = action.payload;
      const updatedState = {
        ...state,
        chatView: {
          status: 'failed',
          detail: name,
        },
      };
      return updatedState;
    }
    case SET_ACTIVE_CHAT: {
      const updatedState = {
        ...state,
        chatView: {
          status: 'loaded',
        },
      };
      return updatedState;
    }
    case REQUEST_GET_JOBS_ERROR: {
      const { isError } = action.payload;
      const updatedState = {
        ...state,
        chatView: {
          status: isError ? 'error' : 'empty',
        },
      };
      return updatedState;
    }
    default:
      return state;
  }
};

export default ui;
