import _ from 'lodash'

class Template {
  constructor(template) {
    this.raw = template

    this.id = template.id ? _.get(template, 'id') : _.get(template, 'key')
    this.token = _.get(template, 'templateKey', '')
    this.messageTemplate = _.get(template, 'template', '')
  }
}

export default Template
