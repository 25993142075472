import { showSnackbar } from '../actions/notification';
import { bulkUpdateUserRoles } from '../actions/users';
import { usersJsonToCSV } from '../helpers/jsonToCSVConvert';

// eslint-disable-next-line import/prefer-default-export
export const handleBulkUploadOktaComplete = (data) => (dispatch) => {
  const { errorUsers, uploadUserErrorCount, userRoles } = data?.userData;
  dispatch(bulkUpdateUserRoles(userRoles));
  if (errorUsers.length === 0) {
    dispatch(
      showSnackbar({
        text: `${uploadUserErrorCount > 0 ? 'Remaining users were configured successfully.' : 'All users were configured successfully.'}`,
        isError: false,
      }),
    );
  } else {
    const csv = usersJsonToCSV(errorUsers);
    dispatch(
      showSnackbar({
        text: `Unable to configure ${errorUsers.length} users. They were removed.`,
        isError: true,
        persist: true,
        linkText: 'VIEW ERRORS',
        isHyperLink: true,
        downloadFile: csv,
        downloadFileName: 'InvalidUsers.csv',
      })
    );
  }
};
