export const displayTransform = (id, display) => {
  const username = display.replace(/\s/g, '');
  return `@${username}`;
};

export const markCaseNoteRead = (job, userId) => {
  const newJob = { ...job };
  if (newJob.unreadNoteMentions) {
    delete newJob.unreadNoteMentions[userId];
  }
  return newJob;
};
