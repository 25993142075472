import React from "react";
import NoMessages from "../SharedComponents/NoMessages/NoMessages";
import Modal from "../../../modal/Modal/Modal";
import withModal from "../../../HigherOrderComponents/withModal";
import OfficeHoursRow from "./OfficeHoursRow/OfficeHoursRow";
import OfficeHoursModal from "./OfficeHoursModal/OfficeHoursModal";
import Loader from "react-loader-spinner";
import "./OfficeHours.less";

const OfficeHours = ({ userId, isLoading, oooReplies, toggleModal, error }) => {
  return (
    <div className="auto-replies-container office-hours-container">
      <div className="office-hours-header">
        <h2>Office Hours Messages</h2>
        {oooReplies && oooReplies.length > 0 ? null : (
          <div className="button-row">
            <button data-test="add-office-hours-message" className="add-office-hours-message" onClick={toggleModal}>
              {"+ Create New Message"}
            </button>
          </div>
        )}
      </div>
      <div className="office-hours-subheader">
        <p>
          Configure automated messages that will be sent to customers during
          regular business hours or after regular business hours.
        </p>
      </div>
      {isLoading ? (
        <div className="autoreplies-loading-icon">
          <Loader type="Oval" color="#44C7E9" height="50" width="50" />
        </div>
      ) : oooReplies && oooReplies.length > 0 ? (
        oooReplies.map((reply) => (
          <OfficeHoursRow key={reply.id} userId={userId} reply={reply} />
        ))
      ) : (
        <NoMessages error={error} />
      )}
    </div>
  );
};

const OfficeHoursWithModal = withModal({
  Modal: (
    <Modal className="create-ooo-modal" data-test="create-ooo-modal" backdrop="static">
      <OfficeHoursModal />
    </Modal>
  ),
})(OfficeHours);

export default OfficeHoursWithModal;
