/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-danger */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import {
  Card,
  Modal,
  Divider,
  ButtonRow,
} from '@himarley/unity';
import { Button } from '@chakra-ui/react';
import Preview from '../OrgSettings/Preview';
import MessageInput from '../WelcomeFlowSettings/message-input';
import RadioButton from '../../elements/RadioButton/RadioButton';
import LightBulb from '../../../../images/icons/lightbulb.svg';
import {
  constructPreviewText,
  NO_VALUE,
  TEMPLATE_KEYS,
  MODAL_RADIO_BUTTON_VALUES_OPT_IN,
} from './constants';

const CustomerOptedInModal = ({
  caseType,
  show,
  hideModal,
  welcomeAssigned,
  onSave,
  isUpdatingOrgSetting,
  welcomeUnassigned,
  tokenOverrideMap,
}) => {
  const handleHide = () => {
    hideModal();
  };

  const [welcomeAssignedMessage, setWelcomeAssignedMessage] = useState(welcomeAssigned);

  const [welcomeUnassignedMessage, setWelcomeUnassignedMessage] = useState(welcomeUnassigned);

  const [previewOption, setPreviewOption] = useState(MODAL_RADIO_BUTTON_VALUES_OPT_IN[0].id);

  const assignedText = useMemo(
    () => (welcomeAssignedMessage.trim().length > 0
      ? (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              constructPreviewText(welcomeAssignedMessage, tokenOverrideMap),
            ),
          }}
        />
      )
      : null),
    [tokenOverrideMap, welcomeAssignedMessage],
  );

  const unassignedText = useMemo(
    () => (welcomeUnassignedMessage.trim().length > 0
      ? (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              constructPreviewText(welcomeUnassignedMessage, tokenOverrideMap),
            ),
          }}
        />
      )
      : null),
    [welcomeUnassignedMessage, tokenOverrideMap],
  );

  const hasChangedMessages = useMemo(() => (welcomeAssignedMessage.trim().length > 0
      && welcomeUnassignedMessage.trim().length > 0
  ), [welcomeAssignedMessage, welcomeUnassignedMessage]);

  const handleSave = () => {
    onSave({
      [TEMPLATE_KEYS.welcomeVerifiedAssigned]: {
        template:
          welcomeAssignedMessage.trim().length > 0
            ? welcomeAssignedMessage
            : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeVerifiedAssigned,
      },
      [TEMPLATE_KEYS.welcomeVerifiedUnassigned]: {
        template:
          welcomeUnassignedMessage.trim().length > 0
            ? welcomeUnassignedMessage
            : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeVerifiedUnassigned,
      },
      [TEMPLATE_KEYS.welcomeCaseVerifiedAssigned]: {
        template:
          welcomeAssignedMessage.trim().length > 0
            ? welcomeAssignedMessage
            : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeCaseVerifiedAssigned,
      },
      [TEMPLATE_KEYS.welcomeCaseVerifiedUnassigned]: {
        template:
          welcomeUnassignedMessage.trim().length > 0
            ? welcomeUnassignedMessage
            : NO_VALUE,
        templateKey: TEMPLATE_KEYS.welcomeCaseVerifiedUnassigned,
      },

    }, true, caseType);
  };

  return (
    <Modal
      show={show}
      showCloseButton
      title="Customer Already Opted In"
      className="confirmation-modal"
      toggleModal={handleHide}
      backdrop
      size="xl"
    >
      <>
        <div className="instructionText">
          <span>Required fields labeled with an asterisk.</span>
          <RadioButton
            currentValue={previewOption}
            values={MODAL_RADIO_BUTTON_VALUES_OPT_IN}
            setCheckedValue={setPreviewOption}
            isHorizontal
          />
        </div>
        <section className="modalBody">
          <section className="topContent">
            <section className="modalLeftSection">
              <div className="numberLineCardWrap">
                <Card>
                  <h2>New Claim Confirmation</h2>
                  The following messages will send when a new claim is opened and the customer has previously opted in.
                  <h3>Assigned Cases</h3>
                  <MessageInput
                    label="Message Body"
                    maxLength={1400}
                    required
                    value={welcomeAssignedMessage}
                    setValue={setWelcomeAssignedMessage}
                    testId="optedInAssignedCaseMessageInput"
                  />
                  <div className="tipWrap">
                    <LightBulb />
                    {' '}
                    TIP: Type the '$' symbol to insert a message
                    template variable.
                  </div>
                  <h3>Unassigned Cases</h3>
                  <MessageInput
                    label="Message Body"
                    maxLength={1400}
                    required
                    value={welcomeUnassignedMessage}
                    setValue={setWelcomeUnassignedMessage}
                    testId="optedInUnassignedCaseMessageInput"
                  />
                  <div className="tipWrap">
                    <LightBulb />
                    {' '}
                    TIP: Type the '$' symbol to insert a message
                    template variable.
                  </div>
                </Card>
              </div>

            </section>
            <Preview
              firstTextName="Lovable Insurance"
              firstTextMessage={
                previewOption === MODAL_RADIO_BUTTON_VALUES_OPT_IN[0].id
                  ? assignedText
                  : unassignedText
              }
              previewLabel=""
            />
          </section>
          <div className="messagesButtonRow">
            <Divider />
            <ButtonRow type="positive">
              <Button onClick={handleHide} variant="outline">
                Cancel
              </Button>
              <Button
                isDisabled={isUpdatingOrgSetting === true || !hasChangedMessages}
                onClick={handleSave}
              >
                {isUpdatingOrgSetting === true ? 'Saving...' : 'Save Messages'}
              </Button>
            </ButtonRow>
          </div>
        </section>
      </>
    </Modal>
  );
};

CustomerOptedInModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  welcomeAssigned: PropTypes.string,
  welcomeUnassigned: PropTypes.string,
  onSave: PropTypes.func,
  isUpdatingOrgSetting: PropTypes.bool,
  tokenOverrideMap: PropTypes.shape({}),
  caseType: PropTypes.string,
};

CustomerOptedInModal.defaultProps = {
  show: false,
  hideModal: () => {},
  welcomeAssigned: '',
  welcomeUnassigned: '',
  onSave: () => {},
  isUpdatingOrgSetting: false,
  tokenOverrideMap: {},
  caseType: '',
};

export default CustomerOptedInModal;
