/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Required from '../../../icons/Required';
import './TextInput.less';

const TextInput = (props) => {
  const {
    required,
    forwardRef,
    error,
    label,
    help,
    value,
    placeholder,
    type,
    onChange,
    onBlur,
    onKeyDown,
    testId,
    name,
    maxlength,
  } = props;

  return (
    <div className={type === 'line' ? 'text-input-line' : 'text-input'}>
      <div className={`text-input-label ${error ? 'error' : ''}`}>
        {label}
        {required && <Required />}
      </div>
      {help && (
        <div className={`text-input-help ${error ? 'error' : ''}`}>{help}</div>
      )}
      <input
        ref={forwardRef}
        className={`text-input-box ${error ? 'error' : ''}`}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={(e) => {
          if (onBlur) onBlur(e);
        }}
        value={value}
        placeholder={placeholder || ' '}
        data-testid={testId || 'textInput'}
        name={name}
        maxLength={maxlength}
      />
      {' '}
      {/* empty string allows style to match textarea with placeholder */}
      {error && (
        <div
          className={`textarea-input-message ${
            error ? 'textarea-input-error-message' : ''
          }`}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default TextInput;
