import _isEqual from 'lodash/isEqual'
import { CHAT_VIEW_TOPIC } from '../constants/websocket';

export const getAllOperatorsTopics = (orgId) => [`${orgId}#OPERATOR#ALL#web-v2`];

/**
 * Returns list of topics for provided operators and organization
 * @param {string} orgId
 * @param {Array} operators
 * @returns {Array}
 */
export const getOperatorTopics = (orgId, operators) => operators?.map(
  (operator) => `${operator?.organization?.id || orgId}#OPERATOR#${
    operator?.id === 'unassigned' ? 'UNASSIGNED' : operator?.id
  }#web-v2`,
); // example output: [<org-guid>#OPERATOR#<operator-guid>#web-v2]

/**
 * Returns single item array of topic of current active chat
 * @param {string} orgId
 * @param {string} chatId
 * @returns {Array}
 */
export const getCurrentChatTopic = (orgId, chatId) => [CHAT_VIEW_TOPIC(orgId, chatId)];

const buildOperatorTopic = (orgId, operatorId) => `${orgId}#OPERATOR#${
  operatorId === 'unassigned' ? 'UNASSIGNED' : operatorId
}#web-v2`;

export const filteredUnsubTopics = (topics, authId) => topics.filter(
  (topic) => !topic.includes(authId),
);
/**
 *
 * Returns a list of topics to subscribe to
 * and a list of topics to unsubscribe from
 *
 * @param {string} orgId - identifier of socket
 * @param {Object} prevProperties - previous filters applied
 * @param {Object} currProperties - current filters applied
 *
 */
export const manageOperatorSubscriptions = (
  orgId,
  prevProperties,
  currProperties,
) => {
  const prevOperators = prevProperties?.operators
  const currentOperatorsList = currProperties?.operators
  const unsubscribeList = [];
  const subscribeList = [];
  if (!_isEqual(currProperties, prevProperties)) {
    const currentTopicsList = getOperatorTopics(orgId, currentOperatorsList);
    const prevTopicsList = getOperatorTopics(orgId, prevOperators);
    const allOperatorsTopics = getAllOperatorsTopics(orgId);
    if (currProperties?.groups?.length || !currentOperatorsList?.length) {
      // filtering by all operators or 1+ groups
      if (prevOperators?.length) {
        unsubscribeList.push(...prevTopicsList);
      }
      subscribeList.push(...allOperatorsTopics);
    } else if (currentOperatorsList?.length) {
      // filtering by 1+ operator, subscribe to those operator topics
      subscribeList.push(...currentTopicsList)
      unsubscribeList.push(...allOperatorsTopics)
      if (prevOperators?.length > currentOperatorsList.length) {
        // if we removed an operator filter, unsubscribe from that topic
        const operatorsToUnsub = prevOperators.filter((op) => !currentOperatorsList.includes(op));
        unsubscribeList.push(...getOperatorTopics(orgId, operatorsToUnsub));
      }
    }
  }
  return { subscribeList, unsubscribeList };
}

export const manageInboxOperatorSubscriptions = (orgId, prev, curr) => {
  const unsubscribeList = prev?.filter((o) => !curr?.includes(o));
  const subscribeList = curr?.filter((o) => !prev?.includes(o));
  const subscribeTopics = subscribeList?.map((opId) => buildOperatorTopic(orgId, opId)) || [];
  const unsubscribeTopics = unsubscribeList?.map((opId) => buildOperatorTopic(orgId, opId)) || [];
  return { subscribeTopics, unsubscribeTopics };
};
