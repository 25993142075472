import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  PRIVACY_ALL_PRIVATE_TYPES,
  PRIVACY_ALL_PRIVATE_HIDDEN,
} from '../constants/permissions';
import { showSnackbar } from '../actions/notification';

export const isPrivateOrUnassigned = (job, primaryOperatorId) => {
  const casePrivacy = _.get(job, 'privacy', '')
  const isPrivate = casePrivacy.match(/private*/)
  return isPrivate !== null && primaryOperatorId === 'unassigned'
}

export const useBuildCaseVisibilityList = () => {
  const currentPermissions = useSelector((state) => _.get(state, 'auth.permissions', []))
  // default for radioButtonValues is the same as when PRIVACY_ALL_PRIVATE_REDACTED is a permission
  let radioButtonValues = [
    { id: 'public', label: 'Public' },
    { id: 'private-redacted', label: 'Private' },
  ];
  if (currentPermissions.includes(PRIVACY_ALL_PRIVATE_HIDDEN)) {
    radioButtonValues = [
      { id: 'public', label: 'Public' },
      { id: 'private-hidden', label: 'Private' },
    ];
  } else if (currentPermissions.includes(PRIVACY_ALL_PRIVATE_TYPES)) {
    radioButtonValues = [
      { id: 'public', label: 'Public' },
      { id: 'private-hidden', label: 'Private - Hidden' },
      { id: 'private-redacted', label: 'Private - Redacted' },
    ];
  }
  return radioButtonValues;
};

export const sortCases = (jobs, authId, currentPermissions, presetSort) => jobs?.sort(
  (a, b) => (currentPermissions?.includes('NEEDS_ATTENTION') && b?.needsAttentionMessageCount - a?.needsAttentionMessageCount)
      || (currentPermissions?.includes('NEEDS_ACTION') && b?.needsAction?.hasQuestionMessageCount - a?.needsAction?.hasQuestionMessageCount)
      || (currentPermissions?.includes('NOTES_ACCESS') && (a?.unreadNoteMentions?.[authId] === undefined) - (b?.unreadNoteMentions?.[authId] === undefined))
      || (presetSort === 'ASC' ? a?.latestActivity?.raw - b?.latestActivity?.raw : b?.latestActivity?.raw - a?.latestActivity?.raw),
);

const caseAccessVariables = (
  participants,
  activeCase,
  currentPermissions,
  currentUserId,
) => {
  const isCurrentUserOperator = activeCase.operatorId === currentUserId
  const isCurrentUserParticipant = participants.find(
    (p) => p.id === currentUserId,
  );
  const hasUserOverride = currentPermissions.includes(
    'OVERRIDE_CASE_VISIBILITY',
  );
  const isOperatorUnassigned = !_.has(activeCase, 'operatorId')
    || activeCase.operatorId === 'undefined'
    || activeCase.operatorId === 'unassigned';

  return {
    isOperatorUnassigned,
    isCurrentUserOperator,
    isCurrentUserParticipant,
    hasUserOverride,
  }
};

export const isCaseVisibilityDisabled = (
  participants,
  activeCase,
  currentPermissions,
  currentUserId,
) => {
  const {
    isOperatorUnassigned,
    isCurrentUserOperator,
    isCurrentUserParticipant,
    hasUserOverride,
  } = caseAccessVariables(
    participants,
    activeCase,
    currentPermissions,
    currentUserId,
  )

  let result = true;
  if (isOperatorUnassigned) {
    result = true;
  } else if (hasUserOverride || isCurrentUserParticipant || isCurrentUserOperator) {
    result = false;
  }
  return result;
};

export const useCheckForCaseAccess = (item, userId, currentPermissions) => {
  if (!userId) {
    // eslint-disable-next-line no-param-reassign, react-hooks/rules-of-hooks
    userId = useSelector((state) => _.get(state, 'auth.user._id', []));
  }
  if (!currentPermissions) {
    // eslint-disable-next-line no-param-reassign, react-hooks/rules-of-hooks
    currentPermissions = useSelector((state) => _.get(state, 'auth.permissions', []));
  }

  const hasUserOverride = currentPermissions.includes(
    'OVERRIDE_CASE_VISIBILITY',
  );
  const selectedPrivacy = _.get(item, 'privacy');
  const operatorId = _.get(item, 'assignedOperator.id');
  const secondaryOperatorIds = _.get(item, 'secondaryOperatorIds');
  // eslint-disable-next-line max-len
  const isUserSecondaryOperator = () => (secondaryOperatorIds ? secondaryOperatorIds.includes(userId) : false);

  if (
    hasUserOverride
    || selectedPrivacy === 'public'
    || userId === operatorId
    || isUserSecondaryOperator(item)
  ) {
    return true;
  }
  return false;
};

export const handleUserAssignment = (activeCase, assignJob, dispatch) => (primaryOperator) => {
  const primaryOperatorId = typeof primaryOperator === 'string' ? primaryOperator : primaryOperator.id
  if (isPrivateOrUnassigned(activeCase, primaryOperatorId)) {
    dispatch(showSnackbar({ text: 'You cannot mark a private case as unassigned.', isError: false }));
  } else {
    dispatch(assignJob({ ...activeCase, operatorId: primaryOperatorId })) // api call
  }
};

export const bulkUpdateHelper = (jobs, property, newValue) => {
  const jobsToUpdate = jobs.map((jobToUpdate) => {
    const changes = {}
    changes[`${property}`] = newValue
    return { ...jobToUpdate, ...changes }
  })
  return jobsToUpdate
}
