/* eslint-disable jsx-a11y/media-has-caption */
import {
  Flex, Icon, Text, theme, Tooltip,
} from '@chakra-ui/react';
import {
  PhoneCallIcon,
  VoicemailIcon,
  InfoCircleIcon,
  StarburstFilledIcon,
} from '@himarley/unity';
import React, { useState } from 'react';

import { formatTime } from '@app/helpers/datetimeWithoutMoment';
import { getLanguageStringById } from '@app/helpers/languages';
import useFileCache from '@app/hooks/file-loader';

import { VoiceRecordingType } from './types';
import styles from './voice-message.module.scss';

interface VoiceMessageProps {
  voiceRecordingType: VoiceRecordingType;
  fileUrl: string;
  author: string;
  createdAt: string;
  messageBody?: string;
  messageId: string;
  translatedMessage?: string;
  sourceLanguage?: string;
}

const VoiceMessage = ({
  voiceRecordingType,
  fileUrl,
  author,
  createdAt,
  messageBody,
  messageId,
  translatedMessage,
  sourceLanguage,
}: VoiceMessageProps): JSX.Element => {
  const [shouldRenderTranslation, setShouldrenderTranslation] = useState(false);
  const { localFileUrl } = useFileCache(fileUrl, false) || {};
  const timestamp = formatTime(createdAt);
  const grey = '#9AA5B1';
  const languageString = getLanguageStringById(sourceLanguage);

  let callEvent = '';
  let callEventDetails = '';
  let phoneIcon = null;
  switch (voiceRecordingType) {
    case VoiceRecordingType.FORWARDED_CALL:
      callEvent = 'Forwarded Call';
      callEventDetails = `${author}'s call was forwarded to the primary operator at ${timestamp}`;
      phoneIcon = <PhoneCallIcon width={32} height={32} color={grey} />;
      break;
    case VoiceRecordingType.FNOL_STORY:
      callEvent = 'Voice Story';
      callEventDetails = `${author} told their story at ${timestamp}`;
      phoneIcon = <VoicemailIcon width={32} height={32} color={grey} />;
      break;
    // assume voicemail as a fallback
    default:
      callEvent = 'Voicemail';
      callEventDetails = `${author} left a voicemail at ${timestamp}`;
      phoneIcon = <VoicemailIcon width={32} height={32} color={grey} />;
      break;
  }

  return (
    <div className={styles.voiceMessage}>
      <div className={styles.messageInfo}>
        <span className={styles.callIcon}>{phoneIcon}</span>
        <div className={styles.callInfo}>
          <div className={styles.callType}>{callEvent}</div>
          <div className={styles.callDetails}>{callEventDetails}</div>
        </div>
      </div>
      {localFileUrl
        && (
          <div className={styles.messageAudio}>
            <audio
              controls
              src={localFileUrl}
              data-testid={`audio-player-${messageId}`}
            >
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
      {messageBody && (
        <Flex direction="column" gap={2} padding="16px 0 0">
          <Flex gap="8px">
            <Tooltip
              hasArrow
              placement="top"
              label={(
                <Text fontSize="xs" align="center">
                  This transcription was generated by AI and may be incomplete or inaccurate.
                </Text>
              )}
              bg="gray.900"
            >
              <Icon as={InfoCircleIcon} boxSize="16px" />
            </Tooltip>
            <Text fontWeight={500}>Transcription</Text>
            <Icon as={StarburstFilledIcon} color="orange.500" boxSize="16px" />
          </Flex>
          <Text>{messageBody}</Text>
          {(!!messageBody && !!languageString) && (
            <Text
              color={theme.colors.blue[500]}
              fontWeight={500}
              cursor="pointer"
              onClick={() => setShouldrenderTranslation(!shouldRenderTranslation)}
            >
              {shouldRenderTranslation ? `Translated from ${languageString}` : 'Show Translation'}
            </Text>
          )}

          {shouldRenderTranslation && <Text>{translatedMessage}</Text>}
        </Flex>
      )}
    </div>
  );
};

export default VoiceMessage;
