import {
  Accordion,
  AccordionItem,
  Card,
  Image,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FEATURE_FLAG_TOTAL_LOSS_ASSIST, FEATURE_FLAG_VERISK } from '@app/constants/permissions';
import { extractTokens } from '@app/helpers/common';
import usePermissionVerifyV2 from '@app/hooks/permission-verify';
import { getKeys } from '@app/slices/templates';
import { Rule } from '@app/types/ruleset';
import CopartLogo from '@images/logos/logoCopart.png';
import VeriskLogo from '@images/logos/logoVerisk.png';

import IntegrationSettings from './integration-settings';
import {
  ActiveMessageTemplateState,
  RuleData,
  RuleFormData,
  RuleSettingsState,
  TemplateFormData,
  ToggleTemplateModalArgs,
} from './integration-settings-types';
import { keyById, buildRequestOptions } from './utils';
import { showSnackbar } from '../../../actions/notification';
import { GET_RULESET } from '../../../constants/endpoints';
import {
  TOTAL_LOSS,
  defaultActiveMessageTemplateState,
  defaultActiveRuleState,
  defaultRuleSettingsState,
  VERISK_RULESET_TYPE,
} from '../../../constants/integration-constants';
import secureRequest from '../../../helpers/secure-request';
import MessageTemplateForm from '../../Form/MessageTemplate/MessageTemplateForm';
import RuleForm from '../../Form/Rule/rule-form';

const IntegrationSettingsContainer = () => {
  const {
    FEATURE_FLAG_TOTAL_LOSS_ASSIST: isTLAEnabled, FEATURE_FLAG_VERISK: isVeriskEnabled,
  } = usePermissionVerifyV2([FEATURE_FLAG_TOTAL_LOSS_ASSIST, FEATURE_FLAG_VERISK]);

  const [ruleSettings, setRuleSettings] = useState(defaultRuleSettingsState);
  const [initialRuleSettings, setInitialRuleSettings] = useState(defaultRuleSettingsState);

  const [activeMessageTemplate, setActiveMessageTemplate] = useState(
    defaultActiveMessageTemplateState,
  );
  const [activeRule, setActiveRule] = useState(
    defaultActiveRuleState,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getKeys());
  }, [dispatch]);

  const sortRules = (rules: Rule[]) => rules.sort((a, b) => {
    if (a.enabled !== b.enabled) {
      const aEnabled = +(Boolean(a.enabled));
      const bEnabled = +(Boolean(b.enabled));
      return bEnabled - aEnabled;
    }
    // If 'enabled' is the same, sort by 'ruleName' in ascending order
    const aRuleName = a.ruleName || a.messageTemplate.displayName || '';
    const bRuleName = b.ruleName || b.messageTemplate.displayName || '';
    return aRuleName.localeCompare(bRuleName);
  });

  const toggleTemplateModal = ({
    templateId: id,
    ruleId,
    messageTemplateTitle: displayName,
    messageTemplateText: body,
    messageTemplateTokens: tokens,
  }: ToggleTemplateModalArgs) => {
    const activeMessageTemplateData = (id ? {
      id,
      ruleId,
      showModal: true,
      body,
      displayName,
      tokens,
    } : defaultActiveMessageTemplateState) as ActiveMessageTemplateState;
    setActiveMessageTemplate(activeMessageTemplateData);
  };

  const toggleRuleModal = ({
    ruleId,
    ruleName,
    rule,
    enabled,
    messageTemplate,
  }: RuleData) => {
    const activeRuleData = (ruleId ? {
      ruleId,
      ruleName,
      enabled,
      showModal: true,
      rule,
      messageTemplate: {
        id: messageTemplate?.id,
        displayName: messageTemplate?.displayName,
        body: messageTemplate?.body,
        tokens: messageTemplate?.tokens,
      },
    } : defaultActiveRuleState) as RuleData;
    setActiveRule(activeRuleData);
  };

  const handleSubmitRules = async (ruleSettingsBody: RuleSettingsState) => {
    // initial state set
    const { url, options } = buildRequestOptions(ruleSettingsBody);
    const response = await secureRequest(url, options);
    if (response.ok) {
      const {
        rules,
        id,
        rulesetType,
      } = await response.json();
      const rulesSorted = sortRules(rules);
      const rulesState = { id, rulesetType, rules: { ...keyById(rulesSorted) } };
      setInitialRuleSettings(rulesState);
      setRuleSettings(rulesState);
      dispatch(showSnackbar({ text: 'Rules and Settings saved', isError: false }));
    } else {
      // Some Error handling here
      setRuleSettings(initialRuleSettings);
      dispatch(showSnackbar({ text: 'Error submitting rules', isError: true }));
    }
  };

  const handleSaveMessageTemplate = async (formData: TemplateFormData) => {
    const { ruleId } = activeMessageTemplate;
    const { body, tokens } = extractTokens(formData.body); // format readable tokens as ${TOKEN}
    const formattedFormData = {
      displayName: formData.displayName,
      body,
      tokens,
    };
    const newRuleSettings: RuleSettingsState = {
      ...ruleSettings,
      rules: {
        ...ruleSettings.rules,
        [ruleId]: {
          ...ruleSettings.rules[ruleId],
          messageTemplate: {
            ...ruleSettings.rules[ruleId].messageTemplate,
            ...formattedFormData,
          },
        },
      },
    };

    setRuleSettings(newRuleSettings);
    setActiveMessageTemplate(defaultActiveMessageTemplateState);
    await handleSubmitRules(newRuleSettings);
  };

  const handleSaveRule = async (formData: RuleFormData) => {
    const { ruleId = '' } = activeRule;
    const {
      ruleName,
      enabled,
      messageTemplateName,
      messageTemplateBody,
    } = formData;
    // format readable tokens as ${TOKEN}
    const { body, tokens } = extractTokens(messageTemplateBody);

    const newRuleSettings = {
      ...ruleSettings,
      rules: {
        ...ruleSettings.rules,
        [ruleId]: {
          ...ruleSettings.rules[ruleId],
          enabled,
          ruleName,
          messageTemplate: {
            ...ruleSettings.rules[ruleId].messageTemplate,
            displayName: messageTemplateName,
            body,
            tokens,
          },
        },
      },
    };
    setRuleSettings(newRuleSettings);
    setActiveRule(defaultActiveRuleState);
    await handleSubmitRules(newRuleSettings);
  };

  const onAccordionItemOpen = async (isExpanded: boolean, rulesetType: string) => {
    if (!isExpanded) {
      const response = await secureRequest(GET_RULESET(rulesetType), { method: 'GET' });
      const json = await response.json();
      const {
        rules,
        id,
        defaultVersion,
      } = json;
      const rulesSorted = sortRules(rules);
      // initial state set
      const rulesState = {
        id,
        defaultVersion,
        rules: { ...keyById(rulesSorted) },
        rulesetType,
      };
      setRuleSettings(rulesState);
      setInitialRuleSettings(rulesState);
    }
  };

  return (
    <div data-testid="integration-settings-container">
      <header>
        <h1>Integration Settings</h1>
      </header>
      <p>Configure rules and settings per software integration</p>
      <RuleForm
        show={activeRule.showModal}
        toggleModal={toggleRuleModal}
        handleSubmit={handleSaveRule}
        ruleData={activeRule}
        rulesetType={ruleSettings.rulesetType}
      />
      <MessageTemplateForm
        show={activeMessageTemplate.showModal}
        method="update"
        toggleModal={toggleTemplateModal}
        handleSubmit={handleSaveMessageTemplate}
        templateData={activeMessageTemplate}
      />
      <Accordion allowToggle>
        {isTLAEnabled && (
          <AccordionItem mb="4" border="0" data-testid={`accordion-item-${TOTAL_LOSS}`}>
            {({ isExpanded }) => (
              <Card overflow="hidden">
                <IntegrationSettings
                  ruleSettings={ruleSettings.rules}
                  toggleTemplateModal={toggleTemplateModal}
                  toggleRuleModal={toggleRuleModal}
                  onAccordionItemOpen={() => onAccordionItemOpen(isExpanded, TOTAL_LOSS)}
                  title="Copart"
                  isExpanded={isExpanded}
                >
                  <Image
                    w="80px"
                    h="40px"
                    objectFit="contain"
                    src={CopartLogo}
                  />
                </IntegrationSettings>
              </Card>
            )}
          </AccordionItem>
        )}
        {isVeriskEnabled && (
          <AccordionItem mb="4" border="0">
            {({ isExpanded }) => (
              <Card overflow="hidden">
                <IntegrationSettings
                  ruleSettings={ruleSettings.rules}
                  toggleTemplateModal={toggleTemplateModal}
                  toggleRuleModal={toggleRuleModal}
                  onAccordionItemOpen={() => onAccordionItemOpen(isExpanded, VERISK_RULESET_TYPE)}
                  title="Verisk"
                  isExpanded={isExpanded}
                >
                  <Image
                    w="80px"
                    h="40px"
                    objectFit="contain"
                    src={VeriskLogo}
                  />
                </IntegrationSettings>
              </Card>
            )}
          </AccordionItem>
        )}
      </Accordion>
    </div>
  );
};

export default IntegrationSettingsContainer;
