/* eslint-disable react/jsx-filename-extension */
import React, { memo } from 'react';
import List from '../../List/List';
import {
  filterTimeOptions,
} from '../../../../helpers/datetime';

// eslint-disable-next-line react/display-name
const TimeMenu = memo(
  ({
    searchtext,
    timeOptions,
    onSelect,
    selectedIds,
  }) => {
    const timeMenuOptions = filterTimeOptions(searchtext, timeOptions)

    return (
      <div className="time-input-menu">
        <List
          items={timeMenuOptions}
          selectedIds={selectedIds}
          onToggle={onSelect}
        />
      </div>
    );
  }
);

export default TimeMenu;
