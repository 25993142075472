/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import _ from 'lodash';
import Dropdown from '../Dropdown/Dropdown';
import './SearchDropdownWithStaticValue.less';
import TextInput from '../../form/TextInput/TextInput';
import { isTab } from '../../../../helpers/keyboard';
/**
 * Dropdown menu that also reveals a search bar
 * @param   {function} onSearchChange triggered on search change
 * @param   {string} placeholder placeholder for the search bar
 * @param   {function} forceFormat function to force format of label
 * @return  {Component}
 */

const SearchDropdownWithStaticValue = ({
  placeholder,
  children,
  defaultValue,
  label,
  onBlur,
  onChange,
  forceFormat,
  onToggleOpen,
  error,
  onKeyDown,
  testId,
}) => {
  const [filter, setFilter] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef();
  const handleToggle = (state) => {
    if (state === false) {
      setFilter('');
    }
    onToggleOpen(state);
  };
  const onSearchChange = (e) => {
    const searchText = forceFormat
      ? forceFormat(e, filter)
      : _.get(e, 'target.value', '');
    setFilter(searchText);
    setIsOpen(true); // Selecting with click will not work without this line
    if (onChange) onChange({ target: { value: searchText } });
  };

  const setIsOpenOnKeyDown = (e) => {
    const { keyCode } = e;
    if (isTab({ keyCode })) {
      setIsOpen(false);
    }
    onKeyDown(e);
  };
  return (
    <Dropdown
      onToggleOpen={handleToggle}
      className={`search-dropdown-static-label search-dropdown-static-label-${
        isOpen ? 'open' : 'closed'
      }`}
      openAction="focus"
      show={isOpen}
      onBlur={() => {}}
      DropItem={(
        <TextInput
          type="line"
          forwardRef={inputRef}
          placeholder={placeholder}
          onChange={onSearchChange}
          onKeyDown={setIsOpenOnKeyDown}
          onBlur={onBlur}
          value={label || filter}
          error={error}
          testId={testId}
        />
      )}
    >
      {React.Children.map(children, (child) => React.cloneElement(child, { searchtext: filter }))}
    </Dropdown>
  );
};

export default SearchDropdownWithStaticValue;
