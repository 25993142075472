import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateLastViewedMessage } from '@app/actions/job';
import { useQueryClient } from '@tanstack/react-query';
import { markChatAsRead } from '../../actions/profile';
import { BROADCAST_CHANNEL } from '../../constants/general';

const useBroadcastChannel = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const channel = new BroadcastChannel(BROADCAST_CHANNEL);

  const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;

  useEffect(() => {
    channel.addEventListener('message', (event) => {
      const { 
        authId, jobId, messageId, shouldAutoRead,
      } = event.data;
      dispatch(markChatAsRead(authId, jobId));
      if (isUnreadStateRefactorEnabled && shouldAutoRead) {
        setTimeout(() => dispatch(updateLastViewedMessage(queryClient, jobId, messageId)), 3000);
      }
    });
  }, []);
};

export default useBroadcastChannel;
