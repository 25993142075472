/* eslint-disable no-underscore-dangle */
const setSortCriteria = (order, sort) => {
  if (sort === 'lastMessage.sentAt' && order === 'DESC') {
    return (a, b) => (new Date(b?.lastMessage?.sentAt) - new Date(a?.lastMessage?.sentAt));
  }
  if (sort === 'lastMessage.sentAt' && order === 'ASC') {
    return (a, b) => (new Date(a?.lastMessage?.sentAt) - new Date(b?.lastMessage?.sentAt));
  }
  if (sort === 'createdAt' && order === 'DESC') {
    return (a, b) => (new Date(b?.createdAt) - new Date(a?.createdAt));
  }
  if (sort === 'createdAt' && order === 'ASC') {
    return (a, b) => (new Date(a?.createdAt) - new Date(b?.createdAt));
  }
  if (sort === 'smartSort') {
    return (a, b) => (a.smartSort - b.smartSort)
      || (new Date(b?.lastMessage?.sentAt) - new Date(a?.lastMessage?.sentAt));
  }
  return (a, b) => (new Date(b?.lastMessage?.sentAt) - new Date(a?.lastMessage?.sentAt));
};

export const insertAndSortItemInList = (list, item, order, sort) => [item, ...list]
  .sort(setSortCriteria(order, sort));

export const modifyAndSortItemInList = (list, item, order, sort) => {
  const index = list.findIndex((c) => c?._id === item?._id);
  const clonedList = [...list];
  clonedList[index] = item;
  return clonedList.sort(setSortCriteria(order, sort));
};

export const removeItemFromListById = (list, item) => list.filter((c) => c._id !== item?._id);

export const getActionType = (filtersMatch, cachedItem) => {
  if (filtersMatch && !cachedItem) {
    return 'insert';
  }
  if (filtersMatch && cachedItem?._id) {
    return 'update';
  }
  if (!filtersMatch && cachedItem?._id) {
    return 'delete';
  }
  return null;
};

// hats off https://stackoverflow.com/questions/8495687/split-array-into-chunks
export const chunkArray = (input, perChunk) => input.reduce((result, item, index) => {
  const chunkIndex = Math.floor(index / perChunk);
  if (!result[chunkIndex]) {
    result[chunkIndex] = []; // start a new chunk
  }
  result[chunkIndex].push(item);
  return result;
}, []);
