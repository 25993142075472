import React from 'react';
import { Tooltip } from '@himarley/unity';
import InfoIcon from '../../../../../images/icons/infoV2.svg';
import styles from './DynamicLabel.module.less';

const DynamicLabel = () => (
  <span className={styles.dynamicLabel}>
    &nbsp; (Dynamic)&nbsp;
    <Tooltip
      trigger={<InfoIcon />}
      side="right"
      tooltip={(
        <p>
          <strong>Smart Sort </strong>
          automatically moves cases to the top of the Inbox that are the highest priority. (Examples: Failed Messages, Coaching Assistance, Note @Mentions, etc.) This
          dynamic sort order is intended to help you prioritize which cases to respond to as your next best action.
        </p>
      )}
    />
  </span>
);

export default DynamicLabel;
