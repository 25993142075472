/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';

import Required from '../icons/Required';
import { UP_ARROW_CODE, DOWN_ARROW_CODE } from '../../constants/keycodes';

import './FormInputField.less';

class FormInputField extends React.Component {
  // use React.createRef() when "react": "^16.3" is in use
  constructor(props) {
    super(props);
    this.focusOnInput = React.createRef();
  }

  componentDidMount() {
    const { shouldFocus } = this.props;
    if (shouldFocus) {
      this.focusOnInput.current.focus();
    }
  }

  focusOnInput = (node) => {
    this.focusOnInput = node;
  };

  // eslint-disable-next-line class-methods-use-this
  handleKeyDown = (e) => {
    if (e.keyCode === UP_ARROW_CODE || e.keyCode === DOWN_ARROW_CODE) {
      e.preventDefault();
    }
  };

  render() {
    const {
      className,
      shouldFocus,
      prefixAddOn,
      suffixAddOn,
      label,
      onChange,
      onBlur,
      name,
      type,
      value,
      isValid,
      errorMessage,
      isDisabled,
      required,
      autoComplete,
      fieldSpecificMessage,
      placeholder,
      successMessage,
    } = this.props;

    return (
      <div
        data-test="form-input-field"
        className={`form-input-container ${!isValid ? 'has-error' : ''}`}
      >
        <div className="form-input-label no-select">
          {label}
          {required && <Required />}
        </div>
        <div className="form-input-inner-container">
          {prefixAddOn && (
            <span className="form-input-prefix">{prefixAddOn}</span>
          )}
          <input
            className={`${
              isDisabled ? 'disabled' : ''
            } form-input ${className}`}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={this.handleKeyDown}
            ref={shouldFocus && this.focusOnInput}
            name={name}
            type={type}
            value={value}
            disabled={isDisabled}
            required={required}
            autoComplete={autoComplete}
            placeholder={placeholder}
            data-testId={name || 'form-input-field-box'}
          />
          {suffixAddOn && (
            <span className="form-input-suffix">{suffixAddOn}</span>
          )}
        </div>
        {!isValid && <span className="error-message" data-testid="formInputError">{errorMessage}</span>}
        <div className={successMessage ? 'success-message-inline' : ''}>
          {successMessage && (<span data-testid="formInputSuccess">{successMessage}</span>)}
          {value && fieldSpecificMessage && (
            <span className="field-specific-message">{fieldSpecificMessage}</span>
          )}
        </div>
      </div>
    );
  }
}

FormInputField.propTypes = {
  className: PropTypes.string,
  shouldFocus: PropTypes.bool,
  prefixAddOn: PropTypes.string,
  suffixAddOn: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  fieldSpecificMessage: PropTypes.string,
  placeholder: PropTypes.string,
  successMessage: PropTypes.string,
};

FormInputField.defaultProps = {
  className: '',
  shouldFocus: false,
  prefixAddOn: '',
  suffixAddOn: '',
  label: '',
  onChange: () => {},
  onBlur: () => {},
  name: '',
  type: '',
  value: '',
  isValid: true,
  errorMessage: '',
  isDisabled: false,
  required: false,
  autoComplete: 'none',
  fieldSpecificMessage: '',
  placeholder: '',
  successMessage: '',
};

export default FormInputField;
