import { defineStyleConfig } from '@chakra-ui/react';

export const inputTheme = defineStyleConfig({
  baseStyle: {
    field: {
      borderRadius: 'base',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
});

export default inputTheme;
