import { defineStyleConfig } from '@chakra-ui/react';

export const checkboxTheme = defineStyleConfig({
  baseStyle: {
    label: {
      fontWeight: 'normal',
    },
  },
  variants: {
    reversed: {
      container: {
        flexDirection: 'row-reverse',
        margin: 0,
      },
      label: {
        fontSize: '14px',
        margin: 0,
        marginRight: '0.5em',
      },
    },
  },
});

export default checkboxTheme;
