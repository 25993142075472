import React from "react";
import "./ReplyMessage.less";

const ReplyMessage = ({ message }) => {
  return (
    <div className="auto-reply-message-container">
      <p className="auto-reply-message-body">{message && message.length > 200 ? message.substring(0, 200) + '...' : message}</p>
    </div>
  );
};

export default ReplyMessage;
