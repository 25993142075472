const FILE_SIZE_LIMIT = 52428800;
const FILE_TYPE = 'text/csv';
const MAX_USER_SIZE = 10000;
export const COLUMNS_INDEX = {
  'First Name': null,
  'Last Name': null,
  Email: null,
  'Phone Number': null,
  'Office Line': null,
};

export const INVALID_COLUMNS_ERROR = 'There is an issue with column header names in your file. Please reference column header names in our sample CSV template and try again.';

const resetHeaderIndexes = () => {
  COLUMNS_INDEX['First Name'] = null;
  COLUMNS_INDEX['Last Name'] = null;
  COLUMNS_INDEX.Email = null;
  COLUMNS_INDEX['Phone Number'] = null;
  COLUMNS_INDEX['Office Line'] = null;
};

export const verifyColumns = (columns) => {
  resetHeaderIndexes();
  if (columns.length < 3) {
    return INVALID_COLUMNS_ERROR;
  }
  for (let i = 0; i < columns.length; i += 1) {
    COLUMNS_INDEX[columns[i]] = i;
  }

  const phoneNumberIndex = COLUMNS_INDEX['Phone Number'];
  const officeLineNumberIndex = COLUMNS_INDEX['Office Line'];
  // phone and office line numbers are optional so delete before checking required columns
  delete COLUMNS_INDEX['Phone Number'];
  delete COLUMNS_INDEX['Office Line'];

  // check if any of required columns are missing
  const isRequiredFieldMissing = Object.values(COLUMNS_INDEX).some(
    (k) => k === null,
  );
  COLUMNS_INDEX['Phone Number'] = phoneNumberIndex;
  COLUMNS_INDEX['Office Line'] = officeLineNumberIndex;

  return isRequiredFieldMissing ? INVALID_COLUMNS_ERROR : '';
};

export const verifyFile = (file) => {
  if (file?.type !== FILE_TYPE) {
    return 'Unsupported file type. Please choose a .CSV file.';
  }
  if (file?.size > FILE_SIZE_LIMIT) {
    return 'Filesize is too large. Maximum filesize is 50MB.';
  }
  return '';
};

export const verifyUserSize = (users) => {
  if (users.length - 1 > MAX_USER_SIZE) {
    return 'Too many rows of data. File must have 10,000 rows or less.';
  }
  return '';
};

export const radioButtonValues = [
  { id: 'IGNORE', label: 'Ignore existing users' },
  { id: 'UPDATE', label: 'Update existing users' },
];
