/* eslint-disable promise/no-return-wrap */
// eslint-disable-next-line import/no-extraneous-dependencies
import fetch from 'isomorphic-fetch'
import { get, has } from 'lodash'
import axios from 'axios'
import { API_ERROR, DEAUTH_USER } from '../../constants/actions'
import { localToRemoteUrl } from '../../helpers/urls'
import { getAccessToken } from '../../helpers/localStorage'

const { CancelToken } = axios
const UNAUTH = 'UNAUTH'
const DEBUG = false

export function validateAuthErrorAndDispatchDeauth(error, dispatch) {
  if (error === UNAUTH) {
    dispatch({ type: DEAUTH_USER })
  }
}

export const getOrgId = ({ store }) => get(store, 'auth.user.organization._id')

export async function makeRequest(opts) {
  const url = localToRemoteUrl(opts.endpoint, opts.host)
  const authTok = await getAccessToken()

  const headers = {
    Authorization: authTok,
  }

  const options = {
    url,
    method: opts.method || 'GET',
    headers,
  }

  if (has(opts, 'body')) {
    options.data = opts.body
  }

  return axios(options)
}

export async function doRequest(opts) {
  if (DEBUG) console.log(opts)
  const url = localToRemoteUrl(opts.endpoint, opts.host)
  if (DEBUG) console.log('doRequest url', url)
  const authTok = opts.noAuth ? null : await getAccessToken()
  const headers = opts.headers || {
    'content-type': 'application/json',
    Accept: 'application/json, application/xml, text/plain, text/html, *.*',
    Authorization: authTok,
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: 0,
  }

  /**
   * This line is necessary to be backwards-compatible with old requests
   * that pass their own headers. This is an attempt to load the tokens
   * in the least amount of spots
   */
  headers.Authorization = authTok

  const payload = {
    method: opts.method || 'GET',
    mode: opts.mode,
    headers: new Headers(headers),
    body: opts.body ? JSON.stringify(opts.body) : undefined,
  }
  const actionName = opts.action
  const { dispatch } = opts

  return fetch(url, payload)
    .then((response) => {
      let responseData
      const contentType = response.headers.get('content-type')
      if (contentType && contentType.includes('application/json')) {
        responseData = response.json()
      } else if (contentType && contentType.includes('image')) {
        responseData = response.blob()
      } else {
        responseData = response.text()
      }
      if (response.status >= 300) {
        if (response.status === 401 || response.status === 403) {
          return Promise.reject(UNAUTH)
        }
        // eslint-disable-next-line promise/no-nesting
        return responseData.then((data) => Promise.reject(data))
      }
      return responseData
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('fetch error in doRequest', error)
      if (dispatch) {
        if (opts.passBodyOnError) {
          // eslint-disable-next-line no-undef
          dispatch({ type: `REQUEST_${actionName}_ERROR`, payload: { error, body: args.body } })
        } else {
          dispatch({ type: `REQUEST_${actionName}_ERROR`, payload: error })
          if (opts.errorCallback) opts.errorCallback({ body: opts.body, error, dispatch })
        }
      }
      return Promise.reject(error);
    })
}

export const preApiCall = ({ dispatch, args }) => {
  const opts = { ...args }
  const actionName = args.action
  if (!actionName) console.error("'action' field not provided in apiCall()")
  if (args.preApiCallback) args.preApiCallback({ body: args.body, dispatch })
  dispatch({ type: `REQUEST_${actionName}_INIT`, payload: args.initPayload })
  return { opts: { ...opts }, actionName }
}

export const postApiCall = ({
  body, dispatch, getStore, args, actionName,
}) => {
  if (body) {
    const processPayload = { body, dispatch, getStore }
    const process = args.process || ((t) => t)
    if (args.callback) {
      dispatch(args.callback(processPayload))
    }
    const actionDispatch = { type: actionName }
    const processedPayload = process(processPayload)
    if (processedPayload) { actionDispatch.payload = processedPayload }
    dispatch(actionDispatch)
    dispatch({ type: `REQUEST_${actionName}_SUCCESS`, payload: args.successPayload })
  } else {
    dispatch({ type: API_ERROR, payload: { args } })
  }
}

export function cancelableRequest(args) {
  return async (dispatch, getStore) => {
    const { opts, actionName } = preApiCall({ dispatch, getStore, args })
    const authTok = await getAccessToken()
    return axios({
      url: opts.endpoint,
      method: opts.method,
      data: opts.data,
      headers: { Authorization: authTok },
      cancelToken: opts.cancelToken && new CancelToken(opts.cancelToken),
    }).then((response) => {
      if (response.status === 200) {
        return postApiCall({
          body: response.data, dispatch, getStore, args, actionName,
        })
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('search request is canceled')
    }).catch((error) => {
      console.log(error);
      dispatch(args.errorCallback());
    })
  }
}

export function apiCall({ args }) {
  if (!args) console.error("'args' field in parameter for apiCall() is undefined")
  return (dispatch, getStore) => {
    const { opts, actionName } = preApiCall({ dispatch, getStore, args })
    return doRequest({ ...opts, dispatch, actionName })
      .then((body) => {
        postApiCall({
          body, dispatch, getStore, args, actionName,
        })
        return Promise.resolve(body);
      })
      .catch((error) => {
        validateAuthErrorAndDispatchDeauth(error, dispatch)
        dispatch({ type: API_ERROR })
        dispatch({ type: `REQUEST_${actionName}_ERROR`, payload: { error, body: args.body } })
        return Promise.reject(error);
      })
  }
}

export async function uploadFile(opts) {
  const url = localToRemoteUrl(opts.endpoint)
  const data = new FormData()
  data.append('file', opts.file)
  data.append('name', opts.name)
  const authTok = await getAccessToken()
  return axios({
    url,
    method: 'post',
    data,
    headers: { Authorization: authTok },
    cancelToken: opts.cancelToken && new CancelToken(opts.cancelToken),
  })
}

export const getFile = async (url) => {
  const opts = {
    endpoint: url,
  }
  return doRequest(opts)
    .then((blob) => URL.createObjectURL(blob)).catch((err) => {
      console.error(err)
    })
}
