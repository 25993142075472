import {
  Modal, ModalContent, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

import RemoveParticipantContainer from './remove-participant-container';

interface RemoveParticipantModalProps {
  participant: {
    id: string,
    imageUrl: string,
    name: string,
    role: string,
    relationship: string,
  },
  onClose: () => void,
}

const RemoveParticipantModal: React.FC<RemoveParticipantModalProps> = (
  { participant, onClose },
) => (
  <Modal isOpen onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Remove Participant</ModalHeader>
      <RemoveParticipantContainer
        participant={participant}
        onClose={onClose}
      />
    </ModalContent>
  </Modal>
);

export default RemoveParticipantModal;
