import React from 'react'
import _ from 'lodash'

import { userType, groupType } from '../../../../models/marleyTypes'
import UserIcon from '../../../icons/UserIcon'
import GroupIcon from '../../../icons/GroupIcon'

import './SubscribersPanel.less'

const SubscriberItem = ({type, name}) => (
  <div className='subscriber-item'>
    <div className='subscriber-icon'>
      { 
        type === userType.id() ? <UserIcon/> :
        type === groupType.id() ? <GroupIcon/> :
        null
      }
    </div>
    <div className='subscriber-name'>{name}</div>
  </div>
)

const SubscriberPanel = ({ subscribers }) => (
  <div className="subscribers-panel" >
    <div className="subscribers-title-container">
      <div className="title">{`Subscribers`}&nbsp;</div>
      <div className="count">{`(${subscribers ? subscribers.length : 0})`}</div>
    </div>
    <div className="subscribers-list">
      { subscribers && subscribers.map( s => (
        <SubscriberItem key={`${_.get(s, 'id')}}`} type={_.get(s, 'type')} name={_.get(s, 'name')}/>
      ))}
    </div>
  </div>
)

export default SubscriberPanel
