import React from 'react';
import propTypes from 'prop-types'
import LastEditedSVG from '../../../../images/icons/last-edited.svg';
import './LastEdited.less'

const LastEdited = ({ fullName, dateAndTime }) => (
  <div data-testid="last-edited-container" className="last-edited">
    <span id="last-edited">Last Edited:   </span>
    <LastEditedSVG className="last-edited-svg" />
    {' '}
    <span data-testid="last-edited-name" id="first-last-name">{fullName}</span>
    <span data-testid="last-edited-datetime" id="last-edited-time">{dateAndTime}</span>
  </div>
);

LastEdited.propTypes = {
  fullName: propTypes.string.isRequired,
  dateAndTime: propTypes.string.isRequired,
}
export default LastEdited;
