import React, { useEffect, useState } from 'react'
import { doRequest } from '../../actions/api'
import './FeatureFlags.less';

const FeatureFlags = () => {
  const [flags, setFlags] = useState({ data: {}, status: 'loading' })

  useEffect(() => {
    if (flags.status === 'loading') {
      doRequest({
        endpoint: '/api/feature-flags',
        method: 'GET',
      }).then((data) => setFlags({ data, status: 'success' }))
        .catch((err) => {
          console.error('Failed to get feature flags!', err)
          setFlags({ status: 'error' })
        })
    }
  }, [flags.status])

  if (flags.status === 'loading') {
    return <p>Loading...</p>
  }

  if (flags.status === 'error') {
    return <p>Failed to get feature flags!</p>
  }

  const sortedFlags = Object.keys(flags.data).sort();

  return (
    <ul className="hm-feature-flag-list">
      {
        sortedFlags.map((name) => {
          const value = flags.data[name]
          const cls = `hm-feature-flag-item hm-feature-flag-${value}`
          const label = `${name} = ${value}`
          return (
            <li
              className={cls}
              key={name}
            >
              {label}
            </li>
          )
        })
      }
    </ul>
  )
}

export default FeatureFlags
