/* eslint-disable react/jsx-filename-extension */
import React, {
  createRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { Modal } from '@himarley/unity';
import {
  verifyColumns,
  verifyFile,
  verifyUserSize,
  radioButtonValues,
} from './BulkUsersUploadModal.helpers';
import ImportCSV from './ImportCSV';
import ImportingProgress from './ImportingProgress';
import ImportErrors from './ImportErrors';
import ChangeUniqueIdErrors from './change-unique-id-errors';

import '../UserMgmt.less';
import './BulkUsersUpload.less';
import { CHANGE_UNIQUE_ID } from '../../../../../constants/general';

const BulkUsersUploadModal = ({
  type,
  createUsers,
  updateOperatorsUniqueIds,
  downloadCSVTemplate,
  show,
  onClose,
  showSnackbar,
  modifyQuery,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isImporting, setIsImporting] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [userNumber, setUserNumber] = useState([]);
  const [errorUsers, setErrorUsers] = useState([]);
  const [usersToImport, setUsersToImport] = useState([]);
  const [duplicateUsers, setDuplicateUsers] = useState(0);
  const [updateUniqueIdsResults, setUpdateUniqueIdsResults] = useState([]);
  const [ignoreOrUpdateVal, setIgnoreOrUpdateVal] = useState(
    radioButtonValues[0].id,
  );

  const fileInputRef = createRef();
  const handleClickSelectFile = () => fileInputRef.current.click();

  const handleFileDrop = (files) => {
    const file = files[0];
    setSelectedFile(file);
    setFileName(file.name);
  };

  const handleImportUsers = async (users) => {
    try {
      showSnackbar({
        text: 'Imported users are being configured. This could take up to 15 minutes.',
      });
      const isUpdating = ignoreOrUpdateVal === radioButtonValues[1].id;
      const duplicateUsersCount = await createUsers(users, isUpdating);
      if (duplicateUsersCount === 0) {
        showSnackbar({
          text: `All ${users.length - 1} users imported.`,
        });
      } else if (
        duplicateUsersCount > 0
        && duplicateUsersCount === users.length - 1
      ) {
        showSnackbar({
          text: (
            <div>
              No users were imported.
              <br />
              {' '}
              {duplicateUsersCount}
              {' '}
              were
              {' '}
              {isUpdating ? 'updated.' : 'ignored.'}
            </div>
          ),
        });
      } else {
        showSnackbar({
          text: (
            <div>
              {users.length - 1 - duplicateUsersCount}
              {' '}
              users imported.
              <br />
              {duplicateUsersCount}
              {' '}
              were
              {' '}
              {isUpdating ? 'updated.' : 'ignored.'}
            </div>
          ),
        });
      }
      onClose();
    } catch (errors) {
      const { errorUsers: errUsers, duplicateUsersCount } = errors;
      setDuplicateUsers(duplicateUsersCount);
      setErrorUsers(errUsers);
    } finally {
      modifyQuery({ searchText: '' });
      setIsImporting(false);
    }
  };

  const changeOperatorsUniqueIds = async (users) => {
    const result = await updateOperatorsUniqueIds(users);
    const updateIssueCount = result.filter((row) => row.Status !== 'Success').length;
    if (updateIssueCount) {
      setUpdateUniqueIdsResults(result);
    }
    const moreThanOneChange = users.length > 2; // Takes into account the header row of the CSV
    let snackbarText;
    if (updateIssueCount) {
      if (moreThanOneChange) {
        snackbarText = `There were ${updateIssueCount} issues with the requested change`;
      } else {
        snackbarText = 'There was an issue with the requested change';
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (moreThanOneChange) {
        snackbarText = `Successfully updated the unique ids for ${updateIssueCount} users`;
      } else {
        snackbarText = 'Successfully updated the unique id for the user';
      }
    }
    showSnackbar({
      text: snackbarText,
      uncloseableDelay: 0,
      showProgress: true,
    });
    if (!updateIssueCount) {
      onClose();
    }
    modifyQuery({ searchText: '' });
    setIsImporting(false);
  };

  useEffect(() => {
    if (isImporting && usersToImport?.length) {
      if (type === CHANGE_UNIQUE_ID) {
        changeOperatorsUniqueIds(usersToImport);
        return;
      }
      handleImportUsers(usersToImport);
    }
  }, [isImporting, usersToImport]);

  const handleImportClick = () => {
    const fileError = verifyFile(selectedFile);
    setErrorText(fileError);
    if (!fileError) {
      Papa.parse(selectedFile, {
        complete(results) {
          if (type === CHANGE_UNIQUE_ID) {
            setIsImporting(true);
            setUserNumber(results.data.length - 1);
            setUsersToImport(results?.data);
            return;
          }
          const columnError = verifyColumns(results?.data[0]);
          const userLengthError = verifyUserSize(results?.data);
          setErrorText(columnError || userLengthError);
          if (!columnError && !userLengthError) {
            setIsImporting(true);
            setUserNumber(results.data.length - 1);
            setUsersToImport(results?.data);
          }
        },
      });
    }
  };

  const handleFileInputChange = (event) => {
    setFileName(event.currentTarget?.files[0].name);
    setSelectedFile(event.currentTarget?.files[0]);
  };

  const renderModalTitle = useMemo(() => {
    if (isImporting) {
      return 'Importing Users';
    }
    if (type === CHANGE_UNIQUE_ID) {
      return 'Change Unique Ids';
    }
    if (errorUsers?.length > 0) {
      const errorCount = errorUsers?.length;
      return `${
        errorCount === 1 ? `${errorCount} Error` : `${errorCount} Errors`
      } Found`;
    }
    return 'Import Users';
  }, [errorUsers, isImporting]);

  const handleCSVExport = () => {
    const csv = Papa.unparse(errorUsers);
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'InvalidUsers.csv';
    hiddenElement.click();
  };

  const downloadFile = ({ data, downloadFileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = downloadFileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = useCallback((e) => {
    e.preventDefault();
    const headers = ['Old Unique Id,New Unique Id,Status,Reason'];
    const usersCsv = updateUniqueIdsResults.reduce((acc, user) => {
      const oldUniqueId = user['Old Unique Id'];
      const newUniqueId = user['New Unique Id'];
      const status = user.Status;
      const reason = user.Reason;
      acc.push([oldUniqueId, newUniqueId, status, reason].join(','));
      return acc;
    }, []);
    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      downloadFileName: 'changeUniqueIdResults.csv',
      fileType: 'text/csv',
    });
  }, [updateUniqueIdsResults]);

  const renderModalContent = useMemo(() => {
    if (isImporting) {
      return <ImportingProgress userNumber={userNumber} />;
    }
    if (errorUsers?.length > 0) {
      return (
        <ImportErrors
          fileName={fileName}
          onClose={onClose}
          errorUsers={errorUsers}
          handleCSVExport={handleCSVExport}
          userNumber={userNumber}
          duplicateUsers={duplicateUsers}
          isUpdating={ignoreOrUpdateVal === radioButtonValues[1].id}
        />
      );
    }
    if (updateUniqueIdsResults.length && type === CHANGE_UNIQUE_ID) {
      return (
        <ChangeUniqueIdErrors
          download={exportToCsv}
          onClose={onClose}
        />
      );
    }
    return (
      <ImportCSV
        type={type}
        fileName={fileName}
        downloadCSVTemplate={downloadCSVTemplate}
        fileInputRef={fileInputRef}
        handleFileDrop={handleFileDrop}
        handleClickSelectFile={handleClickSelectFile}
        handleFileInputChange={handleFileInputChange}
        errorText={errorText}
        handleImportClick={handleImportClick}
        setIgnoreOrUpdateVal={setIgnoreOrUpdateVal}
        ignoreOrUpdateVal={ignoreOrUpdateVal}
      />
    );
  }, [
    onClose,
    duplicateUsers,
    handleCSVExport,
    isImporting,
    fileName,
    downloadCSVTemplate,
    fileInputRef,
    handleFileDrop,
    handleClickSelectFile,
    handleFileInputChange,
    errorText,
    handleImportClick,
    userNumber,
    errorUsers,
    ignoreOrUpdateVal,
    setIgnoreOrUpdateVal,
    exportToCsv,
    updateUniqueIdsResults.length,
    type,
  ]);

  return (
    <Modal
      show={show}
      title={renderModalTitle}
      showCloseButton={!isImporting}
      toggleModal={onClose}
    >
      {renderModalContent}
    </Modal>
  );
};

BulkUsersUploadModal.propTypes = {
  createUsers: PropTypes.func.isRequired,
  updateOperatorsUniqueIds: PropTypes.func.isRequired,
  downloadCSVTemplate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  modifyQuery: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default BulkUsersUploadModal;
