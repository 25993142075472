import { useEffect } from 'react'

/**
 * useOutsideClick
 * hook to allow triggered actions by key input
 * @param {ref} componentRef ref of inside click component
 * @param {function} action action to take
 * @param {boolean} isTriggered recording outside clicks?
* */
const useOutsideClick = ({ isTriggered, action, componentRef }) => {
  const handleClick = (e) => {
    const { current } = componentRef || {}

    if (current.contains(e.target)) {
      return // click inside the component
    }
    if (componentRef) action(false) // click outside the component
  }

  // Adds listeners to detect clicks outside of the component
  useEffect(() => {
    if (isTriggered) {
      document.addEventListener('click', handleClick, true);
    } else {
      document.removeEventListener('click', handleClick, true);
    }
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [isTriggered]);

  return { handleClick }
}

export default useOutsideClick
