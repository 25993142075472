import {
  Badge,
  IconButton,
} from '@himarley/unity';
import React, { useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';

import type { StateType } from '@app/types/reducer-state';
import Caret from '@images/icons/caret.svg';
import PinIcon from '@images/icons/pinV2.svg';

import styles from './InboxContent.module.less';
import { PageType } from './types';

const HIDE_PINNED_CHATS_BADGE = true;

interface PinnedHeaderProps {
  index: number,
  data: {
    pages: PageType[],
    pinnedChatsCount: string | number,
    toggleShowPinnedChats: () => void,
    showPinnedChats: boolean,
  },
  style: React.CSSProperties,
  windowWidth?: number | string,
  setSize: (index: number, size: number | undefined) => void,
}

const PinnedHeader: React.FC<PinnedHeaderProps> = ({
  index,
  data,
  style,
  setSize,
  windowWidth,
}: PinnedHeaderProps) => {
  const rowRef = useRef<HTMLButtonElement>(null);

  const {
    pinnedChatsCount,
    showPinnedChats,
    toggleShowPinnedChats,
  } = data;

  const { viewedChats, pinnedJobs } = useSelector((state: StateType) => ({
    viewedChats: state.jobs?.viewedChats,
    pinnedJobs: state.jobs?.pinnedJobs,
  }));

  const unreadPinnedChatsCount = useMemo(
    () => data.pages[index].unreadPinnedChatsCount
    || pinnedJobs?.filter((p) => !viewedChats?.has(p)).length,
    [data.pages, index, pinnedJobs, viewedChats],
  );

  useEffect(() => {
    if (rowRef.current?.firstChild) {
      const firstChild = rowRef.current.firstChild as HTMLElement;
      const { height } = firstChild.getBoundingClientRect();
      setSize(index, height + 1);
    }
  }, [setSize, index, windowWidth]);

  return (
    <button
      key={index}
      className={styles.pinnedLabel}
      data-testid="inbox-pinned-header"
      style={style}
      ref={rowRef}
      onClick={toggleShowPinnedChats}
      type="button"
      onKeyDown={toggleShowPinnedChats}
      tabIndex={0}
    >
      <div className={styles.pinnedRow} data-testid="pinnedRow">
        <div>
          <PinIcon />
          <span data-testid="pinnedLabel">
            PINNED (
            {pinnedChatsCount}
            )
          </span>
        </div>
        <div className={styles.pinnedColumn}>
          {(!showPinnedChats && unreadPinnedChatsCount > 0 && !HIDE_PINNED_CHATS_BADGE) && (
            <Badge className={styles.pinnedBadge} testId="pinnedCountBadge" value={unreadPinnedChatsCount} />
          )}
          <IconButton
            onClick={toggleShowPinnedChats}
            left
            className={!showPinnedChats && styles.caretDown}
            id="togglePinnedChats"
          >
            <Caret />
          </IconButton>
        </div>
      </div>
    </button>

  );
};

export default PinnedHeader;
