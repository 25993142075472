import _ from 'lodash';
import {
  GET_USER_BY_OKTA_USER_ID,
  AUTH_ERROR,
  DEAUTH_USER,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  PUT_USER_AUTOASSIGN,
  DELETE_USERS_AUTOREPLIES,
  PUT_USERS_AUTOREPLIES,
  POST_USERS_AUTOREPLIES,
  GET_USERS_AUTOREPLIES,
  AUTH_USER,
} from '../constants/actions';
import { formatInit, formatSuccess, formatError } from '../constants/endpoints';
import { SUCCESS, TIMEOUT_DURATION } from '../constants/general';
import fullstory from '../helpers/fullstory';
// eslint-disable-next-line import/default
import pendo from '../helpers/pendo/pendo';

import {
  CHAT_ALL_INBOX,
  CASES_TAB,
  CLAIMS_TAB,
  POLICY_TAB,
  MASSNOTIFICATIONS_TAB,
  USER_MANAGEMENT_TAB,
  ANALYTICS_TAB,
} from '../constants/permissions';
import { FOCUSED_OPERATOR } from '../constants/roles';

export const initialState = {
  error: false,
  user: {},
  inactivityTimeout: TIMEOUT_DURATION,
  permissions: [],
  retrievedUserInfo: null,
  passwordIsChanged: false,
  passwordResetted: false,
  pendingRequest: false,
  oktaUserId: null,
  logout: false,
  score: '',
  userAutoReply: {
    generalAutoReplies: {
      list: [],
    },
    oooReplies: {
      list: [],
    },
  },
  userStatus: false,
};

const standardPermissions = [
  CHAT_ALL_INBOX, // Ability to view chat all inbox
];

const personas = {};
// user roles are not separated by _ characters
_.set(personas, FOCUSED_OPERATOR, {
  EXCLUDES: [
    CHAT_ALL_INBOX,
    CASES_TAB,
    CLAIMS_TAB,
    POLICY_TAB,
    MASSNOTIFICATIONS_TAB,
    USER_MANAGEMENT_TAB,
    ANALYTICS_TAB,
  ],
});

const availablePersonas = Object.keys(personas);

const whatStatus = (autoReply) => (_.get(autoReply, 'active') ? 'away' : 'active');
const isLoggedInUserAutoReply = (autoReply, user) => (autoReply?.payload?.pk
    && autoReply?.payload?.pk?.includes(user?._id))
  || (autoReply?.payload[0]?.pk
    && autoReply?.payload[0]?.pk?.includes(user?._id))

const auth = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_BY_OKTA_USER_ID: {
      const user = action.payload;
      let permissions = _.union(user.permissions, standardPermissions);

      const { roles } = user;
      const rolesInAvailablePersonas = _.intersection(roles, availablePersonas);
      rolesInAvailablePersonas.forEach((r) => {
        const { INCLUDES, EXCLUDES } = personas[r];
        permissions = _.union(permissions, INCLUDES);
        permissions = _.difference(permissions, EXCLUDES);
      });

      const userClone = _.cloneDeep(user);
      delete userClone.permissions;

      return {
        ...state,
        user: userClone,
        permissions,
        retrievedUserInfo: 'loading',
      };
    }
    case formatSuccess(GET_USER_BY_OKTA_USER_ID): {
      return { ...state, retrievedUserInfo: SUCCESS };
    }
    case formatError(GET_USER_BY_OKTA_USER_ID): {
      const error = action.payload?.error?.name;
      const errorCode = action.payload?.error?.status;
      if (error === 'failedToGetUser' && errorCode === 500) {
        return {
          ...state,
          logout: true,
          retrievedUserInfo: 'user-lookup-failure',
        };
      }
      return {
        ...state,
        logout: true,
        retrievedUserInfo: 'health-check-failure',
      };
    }
    case formatInit(RESET_PASSWORD): {
      return { ...state, pendingRequest: true };
    }
    case formatSuccess(RESET_PASSWORD): {
      return {
        ...state,
        passwordResetted: true,
        error: false,
        pendingRequest: false,
      };
    }
    case formatSuccess(CHANGE_PASSWORD): {
      return {
        ...state,
        passwordIsChanged: true,
        error: false,
        pendingRequest: false,
      };
    }
    case AUTH_ERROR: {
      const error = action.payload && typeof action.payload === 'object'
        ? 'Auth error'
        : action.payload;
      return {
        ...state,
        error,
        pendingRequest: false,
        passwordIsChanged: false,
      };
    }
    case AUTH_USER: {
      const { user } = action.payload;
      if (process.env.ENABLE_THIRD_PARTY_SCRIPTS) {
        fullstory.initialize(user);
        pendo.initialize(user);
      }
      return { ...state };
    }
    case DEAUTH_USER: {
      return { ...state, logout: true };
    }
    case PUT_USER_AUTOASSIGN: {
      const isAutoAssign = _.get(
        action,
        'payload.isAutoAssign',
        state.user.isAutoAssign,
      );
      return {
        ...state,
        user: {
          ...state.user,
          isAutoAssign,
        },
      };
    }
    case DELETE_USERS_AUTOREPLIES: {
      if (!isLoggedInUserAutoReply(action, state?.user)) {
        return state;
      }
      const stateClone = { ...state };
      const hasGar = stateClone.userAutoReply.generalAutoReplies.list.find(
        (reply) => reply.id === action.payload.id,
      );
      const hasOoo = stateClone.userAutoReply.oooReplies.list.find(
        (reply) => reply.id === action.payload.id,
      );
      if (hasGar) {
        _.set(
          stateClone,
          'userAutoReply.generalAutoReplies.list',
          stateClone.userAutoReply.generalAutoReplies.list.filter(
            (reply) => reply.id !== action.payload.id,
          ),
        );
        stateClone.userStatus = whatStatus(action.payload);
      } else if (hasOoo) {
        _.set(
          stateClone,
          'userAutoReply.oooReplies.list',
          stateClone.userAutoReply.oooReplies.list.filter(
            (reply) => reply.id !== action.payload.id,
          ),
        );
      }
      return stateClone;
    }
    case PUT_USERS_AUTOREPLIES: {
      if (!isLoggedInUserAutoReply(action, state?.user)) {
        return state;
      }
      const stateClone = { ...state };
      if (action.payload.type === 'GENERAL-AUTO-REPLY') {
        _.set(
          stateClone,
          'userAutoReply.generalAutoReplies.list',
          stateClone.userAutoReply.generalAutoReplies.list.map(
            (reply) => (reply.id === action.payload.id ? action.payload : reply),
          ),
        );
        stateClone.userStatus = whatStatus(action.payload);
      } else if (action.payload.type === 'OUT-OF-OFFICE') {
        _.set(
          stateClone,
          'userAutoReply.oooReplies.list',
          stateClone.userAutoReply.oooReplies.list.map(
            (reply) => (reply.id === action.payload.id ? action.payload : reply),
          ),
        );
      }
      return stateClone;
    }
    case POST_USERS_AUTOREPLIES: {
      if (!isLoggedInUserAutoReply(action, state?.user)) {
        return state;
      }
      const stateClone = { ...state };
      if (action.payload.type === 'GENERAL-AUTO-REPLY') {
        _.set(stateClone, 'userAutoReply.generalAutoReplies.list', [
          action.payload,
          ...stateClone.userAutoReply.generalAutoReplies.list,
        ]);
        stateClone.userStatus = whatStatus(action.payload);
      } else if (action.payload.type === 'OUT-OF-OFFICE') {
        _.set(stateClone, 'userAutoReply.oooReplies.list', [
          action.payload,
          ...stateClone.userAutoReply.oooReplies.list,
        ]);
      }
      return stateClone;
    }
    case GET_USERS_AUTOREPLIES: {
      if (!isLoggedInUserAutoReply(action, state?.user)) {
        return state;
      }
      const list = action.payload;
      const generalAutoRepliesList = _.filter(list, {
        type: 'GENERAL-AUTO-REPLY',
      });
      const oooRepliesList = _.filter(list, { type: 'OUT-OF-OFFICE' });
      return {
        ...state,
        userStatus: whatStatus(_.first(generalAutoRepliesList)),
        userAutoReply: {
          generalAutoReplies: { list: [...generalAutoRepliesList] },
          oooReplies: { list: [...oooRepliesList] },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default auth;
