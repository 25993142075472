import {
  Modal, ModalContent, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

import ParticipantFormContainer from './participant-form-container';

interface ParticipantModalProps {
  onClose: () => void;
  initialParticipantData?: {
    phoneNumber: string;
    firstName: string;
    lastName: string;
    relationship: string;
  };
}

const ParticipantModal: React.FC<ParticipantModalProps> = (
  { onClose, initialParticipantData = null },
) => {
  const title = initialParticipantData ? 'Edit Participant' : 'Add Participant';
  return (
    <Modal size="lg" isOpen onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ParticipantFormContainer
          onClose={onClose}
          initialParticipantData={initialParticipantData}
        />
      </ModalContent>
    </Modal>
  );
};

export default ParticipantModal;
