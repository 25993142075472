import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../Button/Button';

const MultiStateButton = ({
  currentStateId, states, className, testid, ...all
}) => {
  const props = states[currentStateId];

  // const processedAll = { ...all };

  const combinedClassName = `${_.get(props, 'className', '')} ${className}` || className;
  if (props && props.className) delete props.className;

  const currentButton = props ? <Button data-testid={testid} className={combinedClassName} {...props} {...all}>{props.text || ''}</Button> : '';
  return (<span>{currentButton}</span>);
};

MultiStateButton.propTypes = {
  className: PropTypes.string.isRequired,
  currentStateId: PropTypes.string,
  states: PropTypes.shape({}),
  testid: PropTypes.string,
  text: PropTypes.string,
};

MultiStateButton.defaultProps = {
  currentStateId: '',
  states: null,
  testid: '',
  text: '',
};

export default MultiStateButton;
