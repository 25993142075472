import React from 'react';
import PropTypes from 'prop-types';
import './Button.less';

/**
 * Button
 * types - neutral, positive, positive-marley, negative, outline, disabled
 */
const Button = ({
  children,
  onClick,
  disabled,
  type,
  className,
  Icon,
  buttonCorners,
  ...other
}) => {
  const classNames = ['marley-button', className];
  classNames.push(disabled ? 'marley-button-disabled' : `marley-button-${type}`);
  if (!disabled) classNames.push(`marley-button-corners-${buttonCorners}`);
  if (Icon) classNames.push('marley-button-multi');

  return (
    // Adding type button here will disable some buttons such as "Add Participant"
    // eslint-disable-next-line react/button-has-type
    <button
      onClick={
        disabled ? () => {} : onClick
      }
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      data-test="button-test"
      className={classNames.join(' ')}
    >
      {children}
      {' '}
      {Icon ? <Icon /> : null}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  buttonCorners: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  Icon: PropTypes.elementType,
};
Button.defaultProps = {
  className: '',
  buttonCorners: 'round',
  type: 'neutral',
  children: null,
  disabled: false,
  Icon: null,
  onClick: null,
};

export default Button;
