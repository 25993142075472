import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { Button, WithDot, Card } from '@himarley/unity';
import NoteBody from './NoteBody';
import { handleCaseCacheItem } from '../../../../../cache/caseCache';
import { markCaseNoteRead } from './Note.helpers';

const Reply = ({
  header,
  isLastReply,
  setShowCreateNote,
  activeCase,
  authId,
  reply,
  showCreateReply,
  readNoteMention,
  noteId,
}) => {
  const store = useSelector((state) => state);
  const queryClient = useQueryClient();
  // auto set reply to read after 3 seconds - clears blue dot/highlight from reply
  useEffect(() => {
    let timer = null;
    if (reply?.notReadMentionedUsers?.[authId]) {
      timer = setTimeout(() => {
        const caseItem = markCaseNoteRead(activeCase, authId);
        handleCaseCacheItem(caseItem, 'read-note', queryClient, store);
        readNoteMention(activeCase?.id, authId, noteId, reply?._id);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [
    activeCase?.id,
    authId,
    readNoteMention,
    noteId,
    reply?._id,
    reply?.notReadMentionedUsers,
  ]);

  const isReplyUnread = reply?.notReadMentionedUsers?.[authId] || false;

  return (
    <WithDot
      type="blue"
      show={isReplyUnread}
      position="top-left"
      className="cardWithDot"
    >
      <Card
        className={`${isReplyUnread ? 'marleyCardHighlight' : null} replyCard`}
      >
        {header && <div className="marley-card-header">{header}</div>}
        {!reply?.archived && (
          <NoteBody
            mentionedUserIds={reply?.mentionedUserIds}
            noteText={reply?.replyText}
            noteType="DEFAULT"
            isDeleted={reply?.archived}
          />
        )}
        {isLastReply && !showCreateReply && (
          <Button
            data-testid="new-note-button"
            data-test="new-note-button"
            className="replyButton"
            onClick={() => setShowCreateNote(true)}
            type="outline"
          >
            Reply
          </Button>
        )}
      </Card>
    </WithDot>
  );
};

Reply.propTypes = {
  header: PropTypes.node,
  isLastReply: PropTypes.bool,
  setShowCreateNote: PropTypes.func,
  authId: PropTypes.string.isRequired,
  activeCase: PropTypes.shape({ id: PropTypes.string }).isRequired,
  reply: PropTypes.shape({
    notReadMentionedUsers: PropTypes.shape({}),
    _id: PropTypes.string,
    archived: PropTypes.bool,
    replyText: PropTypes.string,
    plainText: PropTypes.string,
    mentionedUserIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  showCreateReply: PropTypes.bool,
  readNoteMention: PropTypes.func,
  noteId: PropTypes.string,
};

Reply.defaultProps = {
  header: null,
  isLastReply: false,
  setShowCreateNote: () => {},
  showCreateReply: false,
  readNoteMention: () => {},
  noteId: null,
};

export default Reply;
