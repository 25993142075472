class Company {
  constructor(c) {
    this.id = c.id || c._id
    this.name = c.name
    this.createdAt = c.createdAt
    this.branding = c.branding
  }
}

export default Company
