import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@himarley/unity';
import { MentionsInput, Mention } from 'react-mentions';
import { generateReadableBody } from '@app/components/Form/MessageTemplate/helpers';
import { useSelector } from 'react-redux';
import Card from '../../../elements/Card/Card';
import EditIcon from '../../../icons/EditIcon';
import GarbageIcon from '../../../icons/GarbageIcon';

const TemplateCard = ({ template, setEditItem, setTemplateToRemove }) => {
  const tokens = useSelector(({ templates }) => templates.keys);
  const { annotatedText } = generateReadableBody(tokens, template);
  return (
    <Card
      key={template.id}
      id={template.id}
      header={(
        <>
          <strong className="templateTitle">{template?.displayName}</strong>
          <div className="marley-card-editicons">
            <IconButton
              onClick={setEditItem}
              className="editTemplateIcon"
              id="edit-template-icon"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={setTemplateToRemove}
              className="deleteTemplateIcon"
              id="delete-template-icon"
            >
              <GarbageIcon />
            </IconButton>
          </div>
        </>
      )}
    >
      <MentionsInput
        value={annotatedText}
        className="mentionsInput disabledMentionsInput"
      >
        <Mention className="noteSuggestion" />
      </MentionsInput>
    </Card>
  );
};

TemplateCard.propTypes = {
  setEditItem: PropTypes.func.isRequired,
  template: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  setTemplateToRemove: PropTypes.func.isRequired,
};

export default TemplateCard;
