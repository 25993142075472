import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignInWidget from './OktaSignInWidget';
import './Login.less';

/**
 * Login component that was constructed by very closely following the guidelines outlined
 * in the Okta Sign In Widget documentation for an embedded widget:
 * https://github.com/okta/okta-signin-widget
 * https://developer.okta.com/code/react/okta_react_sign-in_widget/
 *
 */
const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [sessionLookupPending, setSessionLookupPending] = useState(true);
  const [remoteSessionExists, setRemoteSessionExists] = useState(false);
  useEffect(() => {
    oktaAuth.session.exists().then((exists) => {
      if (exists) {
        setRemoteSessionExists(exists);
        console.log('logged in');
        // logged in
        oktaAuth.token.getWithRedirect({
          responseType: 'id_token', // or array of types
        });
      }
      setSessionLookupPending(false);
    });
  }, []);

  const onSuccess = (tokens) => {
    console.log('successfully logging in with password');
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    console.log('error logging in with password', err);
  };

  if (
    sessionLookupPending
    || (remoteSessionExists && !authState?.isAuthenticated)
  ) return <div />;

  if (authState?.isAuthenticated) return <Redirect to={{ pathname: '/' }} />;

  return (
    <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  );
};

Login.propTypes = {
  config: PropTypes.instanceOf(Object).isRequired,
};

export default Login;
