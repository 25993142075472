/* eslint-disable no-underscore-dangle */
import { get } from 'lodash'
import {
  ANALYTICS_GET_REPORTS, ANALYTICS_LOOKER_URL, ANALYTICS_LOOK_RESULT,
  ANALYTICS_IFRAME_LOOK_URL, ANALYTICS_CASE_IDS_FROM_LOOK,
} from '../constants/endpoints'
import {
  LOAD_ANALYTICS_REPORTS, LOAD_LOOKER_URL,
  LOAD_LOOK_RESULT, LOAD_IFRAME_LOOK_URL, LOAD_LOOK_CASE_IDS,
} from '../constants/actions'

import { apiCall } from './api'

export const getLookerUrl = (dashboardName, timezone) => {
  const args = {
    endpoint: ANALYTICS_LOOKER_URL(dashboardName, timezone),
    method: 'GET',
    action: LOAD_LOOKER_URL,
    process: ({ body }) => body,
  }
  return apiCall({ args })
}

export const getLookResult = (lookNumber) => {
  const args = {
    endpoint: ANALYTICS_LOOK_RESULT(lookNumber),
    method: 'GET',
    action: LOAD_LOOK_RESULT,
    process: ({ body }) => body,
  }
  return apiCall({ args });
}

export const getCaseIdsFromLook = (lookNumber) => {
  const args = {
    endpoint: ANALYTICS_CASE_IDS_FROM_LOOK(lookNumber),
    method: 'GET',
    action: LOAD_LOOK_CASE_IDS,
    process: ({ body }) => body,
  }
  return apiCall({ args })
}

export const getiFrameLookUrl = (caseId, lookNumber, timezone) => {
  const args = {
    endpoint: ANALYTICS_IFRAME_LOOK_URL(caseId, lookNumber, timezone),
    method: 'GET',
    action: LOAD_IFRAME_LOOK_URL,
    process: ({ body }) => body,
  }
  return apiCall({ args })
}

export const loadReports = () => (dispatch, getStore) => {
  const organization = get(getStore(), 'auth.user.organization.name')
  const args = {
    endpoint: ANALYTICS_GET_REPORTS(organization.replace(/\//g, " ")),
    method: 'GET',
    process: ({ body }) => (body),
    action: LOAD_ANALYTICS_REPORTS,
  }
  return apiCall({ args })(dispatch, getStore)
}
