/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable no-extra-boolean-cast */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './TimeDropdown.less';
import _ from 'lodash';
import { displayTimes } from '../../Utils';
import TimeInput from '../../../../elements/form/TimeInput/TimeInput';
import {
  findTimeOption,
  options,
  convertTimeToMilliseconds,
} from '../../../../../helpers/datetime';

const TimeDropdown = ({
  reply,
  setReply,
  weekday,
  allDay,
  setAllDay,
  selectedTime,
  setSelectedTime,
  showAllDayCheckBox,
  setIsTimeValid,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [, setBeginTimesList] = useState(displayTimes());
  const [beginTime, setBeginTime] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [, setEndTimesList] = useState(displayTimes());
  const [endTime, setEndTime] = useState('');
  const [isBeginTimeDropdownOpen, setIsBeginTimeDropdownOpen] = useState(false);
  const [isEndTimeDropdownOpen, setIsEndTimeDropdownOpen] = useState(false);
  const cloneReply = { ...reply };
  const [isValidTimeRange, setIsValidTimeRange] = useState(false);

  useEffect(() => {
    const beginTimeMs = convertTimeToMilliseconds(beginTime);
    const endTimeMs = convertTimeToMilliseconds(endTime);
    const isTimeRangeValid = beginTimeMs < endTimeMs;
    setIsValidTimeRange(isTimeRangeValid);
    setIsTimeValid(isTimeRangeValid);
  }, [beginTime, endTime, setIsValidTimeRange, setIsTimeValid, convertTimeToMilliseconds]);

  const timeOptions = options({
    minuteStep: 5,
    minTime: null,
    maxTime: null,
  });

  useEffect(() => {
    // for General Auto Replies
    const formattedMin = (minute) => (minute < 10 ? `0${minute}` : minute);

    if (_.has(selectedTime, 'begin')) {
      setBeginTime(selectedTime.begin);
      setEndTime(selectedTime.end);
    }
    // for Office Hours
    if (_.has(reply, `sendCriteria.days.${weekday}.beginTime.hour`)) {
      const beginHour = reply.sendCriteria.days[weekday].beginTime.hour;
      const beginMinute = reply.sendCriteria.days[weekday].beginTime.minute;
      const beginTimeOption = findTimeOption(
        `${formattedMin(beginHour)}:${formattedMin(beginMinute)}`,
      );
      setBeginTime(!!beginTimeOption ? beginTimeOption.label : '');
    }
    if (_.has(reply, `sendCriteria.days.${weekday}.endTime.hour`)) {
      const endHour = reply.sendCriteria.days[weekday].endTime.hour;
      const endMinute = reply.sendCriteria.days[weekday].endTime.minute;
      const endTimeOption = findTimeOption(
        `${formattedMin(endHour)}:${formattedMin(endMinute)}`,
      );
      setEndTime(!!endTimeOption ? endTimeOption.label : '');
    }
  }, []);

  function handleSelectBeginTime(e) {
    const timeOption = findTimeOption(e);
    const [hour, minute] = timeOption.id.split(':');
    setBeginTime(!!timeOption ? timeOption.label : e);
    setIsBeginTimeDropdownOpen(!isBeginTimeDropdownOpen);
    // validation to restrict the end times displayed in the end times dropdown based on the begin time selected
    const endTimes = displayTimes({
      type: 'after',
      hour: parseInt(hour, 10),
      minute: parseInt(minute, 10),
    });
    setEndTimesList(endTimes);
    if (_.has(selectedTime, 'begin')) {
      setSelectedTime({ ...selectedTime, begin: timeOption.id });
    }
    if (_.has(cloneReply, `sendCriteria.days.${weekday}.beginTime`)) {
      cloneReply.sendCriteria.days[weekday].beginTime.hour = parseInt(hour, 10);
      cloneReply.sendCriteria.days[weekday].beginTime.minute = parseInt(minute, 10);
      setReply(cloneReply);
    }
  }

  function handleSelectEndTime(e) {
    if (!e) {
      e = timeOptions.find((t) => t.label === endTime).id;
    }
    const timeOption = findTimeOption(e);
    setEndTime(!!timeOption ? timeOption.label : e);
    setIsEndTimeDropdownOpen(!isEndTimeDropdownOpen);
    // validation to restrict the begin times displayed in the begin times dropdown based on the end time selected
    const [hour, minute] = timeOption.id.split(':');
    const beginTimes = displayTimes({
      type: 'before',
      hour: parseInt(hour, 10),
      minute: parseInt(minute, 10),
    });
    setBeginTimesList(beginTimes);
    if (_.has(selectedTime, 'begin')) {
      setSelectedTime({ ...selectedTime, end: timeOption.id });
    }
    if (_.has(cloneReply, `sendCriteria.days.${weekday}.endTime`)) {
      cloneReply.sendCriteria.days[weekday].endTime.hour = parseInt(hour, 10);
      cloneReply.sendCriteria.days[weekday].endTime.minute = parseInt(minute, 10);
      setReply(cloneReply);
    }
  }

  return (
    <div className="times-day-container">
      <div className="times-container">
        <div className="times-container-begin">
          {showAllDayCheckBox ? <h5>Begin</h5> : null}
          <div className="times-row">
            <TimeInput
              label=""
              defaultTime={beginTime || '9:00 am'}
              onSelect={(e) => handleSelectBeginTime(e)}
              testId={`${weekday}-begin-time`}
            />
          </div>
        </div>
        <h5 className="to-heading">to</h5>
        <div className="times-container-end">
          {showAllDayCheckBox ? <h5>End</h5> : null}
          <div className="times-row">
            <TimeInput
              label=""
              defaultTime={endTime || '5:00 pm'}
              onSelect={(e) => handleSelectEndTime(e)}
              testId={`${weekday}-end-time`}
            />
          </div>
        </div>
        {showAllDayCheckBox ? (
          <div className="all-day">
            <input
              className="all-day-checkbox"
              name="all-day-checkbox"
              type="checkbox"
              checked={allDay}
              onChange={(e) => setAllDay(e.target.checked)}
            />
            <label htmlFor="all-day-checkbox">All day</label>
          </div>
        ) : null}
      </div>
      {!isValidTimeRange && (
        <div className="error-message" data-testid="invalid-time-range">
          Please enter a valid time range.
        </div>
      )}
    </div>
  );
};

TimeDropdown.propTypes = {
  showAllDayCheckBox: PropTypes.bool.isRequired,
};

export default TimeDropdown;
