/* eslint-disable no-underscore-dangle */
import Notify from 'notifyjs';
import { NEW_MESSAGE, GENERIC_MESSAGE, AUTH_USER } from '../constants/actions';

const MARLEY_ICON = '/images/favicon/favicon.ico';

const onPermissionGranted = (notification) => () => {
  console.debug('Desktop notification permission has been granted by the user');
  if (notification) notification.show();
};

function onPermissionDenied() {
  console.debug('Desktop notification permission has been denied by the user');
}

const onNotifyShow = (message) => {
  console.debug(message, 'Desktop Notification sent');
};

const showNotification = ({ title, body, tag }) => {
  const notification = new Notify(title, {
    body,
    // recommended icon sizes are between 96x96 and 180x180
    icon: MARLEY_ICON,
    notifyShow: () => onNotifyShow(body),
    tag: tag || title,
  });
  if (!Notify.needsPermission) {
    notification.show();
  } else if (Notify.isSupported()) {
    Notify.requestPermission(
      onPermissionGranted(notification),
      onPermissionDenied,
    );
  }
};

export default (store) => (next) => (action) => {
  switch (action.type) {
    case AUTH_USER: {
      if (Notify.needsPermission && Notify.isSupported()) {
        Notify.requestPermission(onPermissionGranted, onPermissionDenied);
      }
      break;
    }
    case GENERIC_MESSAGE: {
      const { name, body } = action.payload || {};
      showNotification({
        title: name,
        body,
      });
      break;
    }
    case NEW_MESSAGE: {
      const state = store.getState();
      const currentUserId = state?.auth?.user?._id;
      const message = action?.payload?.message;

      if (!message) break;
      const { author } = message;
      if (message?.isAI || !author || author?.id === currentUserId) {
        break;
      }
      const participants = message.participants || [];
      const { messageBody, id } = message;
      if (participants.includes(currentUserId)) {
        showNotification({
          title: author?.name || 'new message',
          body: messageBody,
          tag: id,
        });
      }
      break;
    }
    default:
      break;
  }
  return next(action);
};
