/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userType } from '../../../models/marleyTypes'
import Required from '../../icons/Required';
import DropDownIcon from '../../icons/DropDownIcon';
import { handleFormInputChange } from '../../../actions/common';

import './SelectDropdown.less'

class SelectDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      listIsOpen: false,
      selectedOption: '',
    };
  }

  componentDidMount() {
    const { options, initialValue } = this.props;
    if (options.length && initialValue) {
      const initialValueLabel = options.find((option) => option.id === initialValue).label;
      this.setState({ selectedOption: initialValueLabel })
    } else if (options.length && !initialValue) {
      this.setState({ selectedOption: options[0].label });
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setDropdownRef = (node) => {
    this.dropdownRef = node;
  };

  handleClickOutside = (e) => {
    if (this.dropdownRef && !this.dropdownRef.contains(e.target)) {
      this.setState({ listIsOpen: false });
    }
  };

  toggleDropdown = () => {
    const { listIsOpen } = this.state;
    this.setState({ listIsOpen: !listIsOpen });
  };

  makeSelection = (e) => {
    const { name, options, handleFormInputChange: handleChange } = this.props;
    const { textContent } = e.target;
    const selectedOptionObject = options.find((option) => option.label === textContent);

    if (selectedOptionObject && selectedOptionObject.id) {
      handleChange({
        value: selectedOptionObject.id,
        inputId: name,
        tableId: userType.id(),
      });

      this.setState({ selectedOption: textContent });
      this.toggleDropdown();
    }
  };

  render() {
    const {
      label,
      name,
      required,
      options,
      isDisabled,
    } = this.props;

    const {
      listIsOpen,
      selectedOption,
    } = this.state;

    return (
      <div className="select-dropdown-container" ref={this.setDropdownRef}>
        <div className="select-dropdown-label">
          {label}
          {required ? <Required /> : ''}
        </div>
        <div
          className={`${isDisabled ? 'disabled' : ''} select-dropdown-chosen-option`}
          onClick={!isDisabled ? this.toggleDropdown : () => {}}
          role="button"
          tabIndex={0}
        >
          {selectedOption}
          <span className={`${listIsOpen ? 'list-open' : ''} select-dropdown-arrow`}><DropDownIcon /></span>
        </div>
        { listIsOpen
          && (
          <div className="select-dropdown-options">
            {
              options.map((option) => {
                if (option.label !== selectedOption) {
                  return (
                    <div
                      key={option.id}
                      data-test="select-dropdown-option"
                      className="select-dropdown-option"
                      name={name}
                      onClick={this.makeSelection}
                      role="button"
                      tabIndex={0}
                    >
                      {option.label}
                    </div>
                  );
                }
                return null;
              })
            }
          </div>
          )}
      </div>
    );
  }
}

SelectDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleFormInputChange: PropTypes.func.isRequired,
};

SelectDropdown.defaultProps = {
  isDisabled: false,
  required: false,
  initialValue: '',
};

const mapDispatchToProps = {
  handleFormInputChange,
};

export { SelectDropdown }
export default connect(null, mapDispatchToProps)(SelectDropdown);
