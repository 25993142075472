export const keyboardShortcuts = ({
  key,
  mediaIndex,
  mediaCount,
  changeMediaIndex,
  close,
}) => {
  if (key === 'ArrowLeft' && mediaIndex > 0) {
    changeMediaIndex(mediaIndex - 1);
  } else if (key === 'ArrowRight' && (mediaIndex + 1) < mediaCount) {
    changeMediaIndex(mediaIndex + 1);
  } else if (key === 'Escape') {
    close();
  }
};
