import React from 'react'
import { connect } from 'react-redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { analyticsType } from '../../../models/marleyTypes'
import TableRowComponent from '../../elements/table/TableRow/TableRow'
import SortTableComponent from '../../elements/table/SortTable/SortTable'
import TableHeader from '../../elements/table/SortTable/TableHeader/TableHeader'
import DownloadIconClickable from '../../icons/DownloadIcon'
import './DataDownload.less'
import { formatISODateSlashandAMPM } from '../../../helpers/datetime'

const columns = [
  { label: 'Link', location: 'icon' },
  { label: 'Report', location: 'report' },
  { label: 'Last Run', location: 'lastRun' },
]
const dlColumns = columns.map(col => (
  <TableHeader className={col.location} key={col.id || col.label} {...col} tableId={'data-dl'} />
))
const buildReportNameComponent = (reportName, lastRun) => {
  let tooltip
  if (lastRun) {
    tooltip = `This report is updated every day at ${lastRun.split(',')[1]}`
  }
  return (
    tooltip ?
      <OverlayTrigger placement="top" overlay={<Tooltip id={reportName}>{tooltip}</Tooltip>}><div>{reportName}</div></OverlayTrigger> :
      lastRun
  )
}

const DataDownload = (props) => {
  const { reports } = props
  const uiRows = reports.map((reportInfo) => {
    const reportName = _.get(reportInfo, 'name', 'Default')
    const lastRun = formatISODateSlashandAMPM(_.get(reportInfo, 'lastRun'))
    const icon = <DownloadIconClickable className="download-button" link={reportInfo.link} />
    const uiRow = {
      key: reportName,
      id: reportName,
      lastRun: lastRun || '--',
      icon,
      report: buildReportNameComponent(reportName, lastRun),
    }
    return (
      <TableRowComponent
        className="data-download-row"
        columns={columns}
        item={uiRow}
        key={reportName}
      />
    )
  })
  return (
    <div className="data-download-table">
      <SortTableComponent
        isStandard
        columns={dlColumns}
        rows={uiRows}
        type={analyticsType}
      />
    </div>
  )
}

DataDownload.propTypes = {
  reports: PropTypes.instanceOf(Array).isRequired,
}

const mapStateToProps = state => ({
  reports: _.get(state, 'analytics.reports', []),
})

export default connect(mapStateToProps)(DataDownload)
