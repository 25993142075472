import React, { useState, useEffect } from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap'
import LineDropdown from '../../elements/dropdowns/LineDropdown/LineDropdown'
import List from '../../elements/List/List'
import './CaseVisibilityDropdown.less'
import TooltipInfo from '../../elements/Tootip/TooltipInfo'
import { useBuildCaseVisibilityList } from '../../../helpers/cases';
import { useSelector } from 'react-redux';
import _ from 'lodash';
const CaseVisibilityDropdown = ({ label, onChange, caseAssignment }) => {
  const currentPermissions = useSelector((state) => _.get(state, 'auth.permissions', []))
  const items = useBuildCaseVisibilityList(currentPermissions)
  const [selectedValue, setSelectedValue] = useState(_.find(items, { id: 'public' }))
  const messages = [{ id: 'unassigned', text: 'Unassigned cases are Public and cannot be configured.' }, { id: 'private_unassigned', text: 'Unassigning a user from a Private case will make that case Public - Consider assigning to a user to keep case Private.' }, { id: 'generic', text: 'Private cases display only ID and cannot be opened or changed by others.' }]
  const [message, setMessage] = useState(_.get(_.find(messages, { id: 'generic' }), 'text'))
  const [isPrivateSelected, setIsPrivateSelected] = useState(false);

  function toggleDropdownSelection(id) {
    setIsPrivateSelected(id !== 'public')
    const value = items.find((i) => i.id === id)
    setSelectedValue(value)
    onChange(id);
  }

  useEffect(() => {
    if (caseAssignment !== 'unassigned') {
      setMessage(_.get(_.find(messages, { id: 'generic' }), 'text'))
      setIsPrivateSelected(false)
    } else {
      setSelectedValue(_.find(items, { id: 'public' }))
      if (!isPrivateSelected) {
        setMessage(_.get(_.find(messages, { id: 'unassigned' }), 'text'))
      } else {
        setMessage(_.get(_.find(messages, { id: 'private_unassigned' }), 'text'))
        onChange('public')
        const timer = setTimeout(() => {
          setIsPrivateSelected(false)
          setMessage(_.get(_.find(messages, { id: 'unassigned' }), 'text'))
        }, 8000);
        return () => clearTimeout(timer);
      }
    }
  }, [caseAssignment])

  return (
    <div className="form-input-container">
      <div className="form-input-label no-select">
        <FormGroup>
          <div className="form-input lable-container">
            <ControlLabel>
              {label}
            </ControlLabel>
            <TooltipInfo
              message={message}
              isForcedDisplay={isPrivateSelected && caseAssignment === 'unassigned'}
              caseAssignment={caseAssignment}
            />
          </div>
          {caseAssignment !== 'unassigned'
            ? (
              <LineDropdown label={_.get(selectedValue, 'label')} className="form-input-inner-container">
                <div>
                  <List
                    id="selected-form-dropdown"
                    selectedIds={[_.get(selectedValue, 'id')]} // Allows checkmarks to always be on
                    items={items}
                    onToggle={(id) => toggleDropdownSelection(id)}
                  />
                </div>
              </LineDropdown>
            )
            : <div>{selectedValue.label}</div>}
        </FormGroup>
      </div>
    </div>
  );
};

export default CaseVisibilityDropdown;
