/* eslint-disable react/no-unescaped-entities */
import React, {
  useRef, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import {
  Modal, ButtonRow, TextInputLine,
} from '@himarley/unity';
import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import LightBulb from '../../../../images/icons/lightbulb.svg';
import { capitalizeEachWord } from '../../../helpers/format';
import { generateReadableBody } from './helpers';
import './MessageTemplateForm.less';
import { messageTemplatePropTypes } from '../../modal/TemplatesModal/template-helpers';
import {
  CREATE_MESSAGE_TEMPLATE_MODAL_TITLE,
  CREATE_MESSAGE_TEMPLATE_MODAL_SUBMIT,
  DELETE_TEMPLATE_MODAL_SUBMIT,
  DELETE_TEMPLATE_MODAL_TITLE,
  EDIT_MESSAGE_TEMPLATE_MODAL_TITLE,
  EDIT_MESSAGE_TEMPLATE_MODAL_SUBMIT,
  DELETE_TEMPLATE_OPTION,
  CREATE_TEMPLATE_OPTION,
  EDIT_TEMPLATE_OPTION,
} from './constants';

const MAX_CHAR_TITLE_LENGTH = 250;
const MAX_CHAR_INPUT_LENGTH = 1400;

/**
 * MessageTemplateForm Component
 * @param {Object} props - Component props
 * @param {boolean} props.show - Show this modal
 * @param {function} props.toggleModal - Trigger change in show
 * @param {function} props.handleSubmit - Called when submitting the form
 * @param {string} props.method - Method: create, update, delete
 * @param {Object} [props.templateData] - Template data for editing
 * @param {string} [props.lob] - Line of business (optional)
 * @returns {React.JSX.Element} - Rendered component
 */

const MessageTemplateForm = ({
  show,
  lob,
  handleSubmit,
  method,
  toggleModal,
  templateData = {},
}) => {
  const formTextMap = {
    create: {
      title: CREATE_MESSAGE_TEMPLATE_MODAL_TITLE,
      submit: CREATE_MESSAGE_TEMPLATE_MODAL_SUBMIT,
    },
    update: {
      title: EDIT_MESSAGE_TEMPLATE_MODAL_TITLE,
      submit: EDIT_MESSAGE_TEMPLATE_MODAL_SUBMIT,
    },
    delete: {
      title: DELETE_TEMPLATE_MODAL_TITLE,
      submit: DELETE_TEMPLATE_MODAL_SUBMIT,
    },
  };

  const initialFormDataState = {
    displayName: '',
    body: '',
    changedFields: {},
  };

  const [formData, setFormData] = useState(initialFormDataState);

  const handleFormFieldChange = (name, maxLength) => (e) => {
    const { value } = e.target;
    const newValue = value.slice(0, maxLength);
    setFormData({
      ...formData,
      [name]: newValue,
      changedFields: { ...formData.changedFields, [name]: true },
    });
  };

  const cancelButtonDisabled = useMemo(() => {
    const { changedFields, ...data } = formData;
    switch (method) {
      case CREATE_TEMPLATE_OPTION:
        return Object.keys(changedFields).length < 2
        || Object.values(data).some((value) => value.trim() === '');
      case EDIT_TEMPLATE_OPTION:
        return Object.keys(changedFields).length === 0
        || Object.values(data).some((value) => value.trim() === '');
      default:
        return false;
    }
  }, [formData, method]);

  const handleCancel = () => {
    toggleModal(false);
  };

  useEffect(() => {
    if (!show) {
      setFormData({
        displayName: '',
        body: '',
        changedFields: {},
      });
    }
  }, [show]);

  const tokens = useSelector(({ templates }) => templates.keys);

  useEffect(() => {
    if (templateData && Object.keys(templateData).length) {
      // parse template tokens and replace with readable text
      const { annotatedText: readableBody } = generateReadableBody(tokens, templateData);
      setFormData({
        body: readableBody,
        displayName: templateData.displayName,
        changedFields: {},
      });
    }
  }, [templateData, tokens]);

  // passed into Mentions to render dropdown of available tokens
  // updated when text changes
  const filteredTokens = tokens?.map(({ key }, index) => ({
    id: `${index}`,
    display: key,
  }));

  const templateInput = useRef(null);

  const renderSuggestion = (suggestion) => (
    <div className="suggestionWrap">
      <div className="user-info">
        <div className="user-name">
          {' '}
          {suggestion?.display}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      show={show}
      title={formTextMap[method].title}
      className={
        method === CREATE_TEMPLATE_OPTION
          ? 'createMessageEditorModal message-editor-modal'
          : 'editMessageEditorModal message-editor-modal'
      }
      toggleModal={handleCancel}
      size="lg"
    >
      {
        method === DELETE_TEMPLATE_OPTION ? (
          <div>
            <p>
              Are you sure you want to delete
              {' '}
              <strong>{templateData?.displayName}</strong>
              {' '}
              message
              template? This action cannot be undone.
            </p>
          </div>
        ) : (
          <div>
            <p className="requiredWrap">Required fields labeled with an asterisk.</p>
            <div className="titleVisibleToWrap">
              <TextInputLine
                label="Title"
                type="line"
                value={formData?.displayName}
                onChange={handleFormFieldChange('displayName', MAX_CHAR_TITLE_LENGTH)}
              />
              {lob && (
              <div className="lobVisibleTo">
                <div className="lobVisibleToLabel">Visible To</div>
                <div>
                  {capitalizeEachWord(
                    `${lob?.type} ${lob?.displayName} (${lob?.branding})`,
                  )}
                </div>
              </div>
              )}
            </div>
            <div className="text-input-label templatesLabelWrap">
              <div>
                Message Template
                <span className="text-danger">*</span>
              </div>
              <div
                className={`textarea-input-char-count ${
                  formData?.body ? 'textarea-input-char-count-active' : ''
                }`}
                data-testid="textarea-input-char-count"
              >
                {formData?.body?.length ?? 0}
                /
                {MAX_CHAR_INPUT_LENGTH}
              </div>
            </div>
            <MentionsInput
              inputRef={templateInput}
              value={formData?.body ?? ''}
              onChange={handleFormFieldChange('body', MAX_CHAR_INPUT_LENGTH)}
              className="mentionsInput integrationTemplateModal"
              allowSpaceInQuery
            >
              <Mention
                renderSuggestion={renderSuggestion}
                className="noteSuggestion"
                trigger="$"
                data={filteredTokens}
              />
            </MentionsInput>
            <div className="tipWrap">
              <LightBulb />
              {' '}
              TIP: Type the '$' symbol to insert a message template
              variable.
            </div>
          </div>
        )
      }
      <ButtonRow>
        <Button
          variant="outline"
          onClick={handleCancel}
          data-testid="template-cancel"
        >
          Cancel
        </Button>
        <Button
          data-testid="template-submit-button"
          isDisabled={cancelButtonDisabled}
          onClick={() => {
            const { changedFields, ...data } = formData;
            return handleSubmit(
              { ...data, ...(lob && { lineOfBusinessId: lob._id }) },
              templateData.id,
            );
          }}
        >
          {formTextMap[method].submit}
        </Button>
      </ButtonRow>
    </Modal>
  );
};

MessageTemplateForm.propTypes = {
  show: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  method: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  templateData: messageTemplatePropTypes.isRequired,
  lob: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    displayName: PropTypes.string,
    branding: PropTypes.string,
  }),
};

MessageTemplateForm.defaultProps = {
  show: false,
  lob: null,
};

export default MessageTemplateForm;
