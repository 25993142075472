import React, { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import SearchBar from '../../../SearchBar/SearchBar'
import LineDropdown from '../LineDropdown/LineDropdown'
import List from '../../List/List'
import withLazyLoad from '../../../withLazyLoad/withLazyLoad'
import withLoading from '../../../HigherOrderComponents/withLoading'

import { operatorType } from '../../../../models/marleyTypes'
import { pluralize } from '../../../../helpers/common'
import Divider from '../../Divider/Divider'

import './AssignUserDropdown.less'

const AssignUserDropdown = ({
  returnFull, isLoading, onSelect, authId, authName, assignedOperator, recentlyAssignedOperators, loadedItems, modifyQuery
}) => {
  const [showRecentlyAssigned, setShowRecentlyAssigned] = useState(true)

  const defaultSelected = [{
    id: authId,
    label: `Assign to ${authName} (me)`,
  }, {
    id: 'unassigned',
    label: 'Mark as Unassigned',
  }]

  const complexSelect = (ops) => (e) => {
    returnFull ? onSelect(ops.find(d => d.id === e)) : onSelect(e)
  }

  return (
    <LineDropdown className="assign-user-dropdown" label={assignedOperator && assignedOperator.name} multiSelect>
      <div>
        <SearchBar
          placeholder="Search Operators"
          onChange={(e) => {
            const searchText = e.target.value
            setShowRecentlyAssigned(!(searchText && searchText.length > 0))
            modifyQuery({ searchText })
          }}
        />
        <Divider>Selected</Divider>
        <List onToggle={complexSelect(defaultSelected)} items={defaultSelected} />
        <Divider>{showRecentlyAssigned ? 'Recently Assigned' : 'Search Results'}</Divider>
        {showRecentlyAssigned
          ? <List id="recently-assigned" className="assign-user-list" onToggle={complexSelect(recentlyAssignedOperators)} items={recentlyAssignedOperators} />
          : <List isLoading={isLoading} id="filtered-operators" className="assign-user-list" onToggle={complexSelect(loadedItems)} items={loadedItems.map((o) => ({ id: o.id, label: `${o.firstName} ${o.lastName}` }))} />}
      </div>
    </LineDropdown>
  )
}

export const mapStateToProps = (state, ownProps) => {
  const id = ownProps.id === 'policy' ? pluralize(ownProps.id) : ownProps.id
  return {
    type: operatorType,
    recentlyAssignedOperators: _.concat(_.get(state.auth, 'user', {}), _.get(state, `profile.properties.recentlyAssignedOperators.${id}`, []))
      .map((operator) => ({ id: operator.id || operator._id, label: operator.name || `${operator.firstName} ${operator.lastName}` })),
    authId: _.get(state, 'auth.user._id'),
    authName: `${_.get(state, 'auth.user.firstName', '')} ${_.get(state, 'auth.user.lastName', '')}`,

  }
}

const AssignUserDropdownWithLoading = withLoading(AssignUserDropdown, { type: operatorType })
const AssignUserDropdownWithLazy = withLazyLoad(AssignUserDropdownWithLoading,
  {
    type: operatorType,
    sort: { column: 'name', order: 'DESC' },
    listLocation: 'users',
    disableInitialFetch: true,
  })

export default connect(mapStateToProps)(AssignUserDropdownWithLazy)