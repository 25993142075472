import React from 'react'
import './DisabledBanner.less'

const DisabledBanner = ({ includesLink, linkRef, linkText, bannerText }) => (
  <div className="alert alert-info marley-banner">
    { includesLink && (
        <a
        target="_blank"
        href={ linkRef }
        rel="noreferrer"
      >
        { linkText }{' '}
      </a>
      ) 
    }
      { bannerText }
  </div>
)

export default DisabledBanner