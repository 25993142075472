import React from 'react'
import PropTypes from 'prop-types'
import Muted from '../../../images/icons/volume-mute-fill.svg'
import Unmuted from '../../../images/icons/volume-up-fill.svg'

const MuteIcon = ({ muted }) => (
  muted ? <Muted /> : <Unmuted />
)

MuteIcon.propTypes = {
  muted: PropTypes.bool,
}

MuteIcon.defaultProps = {
  muted: false,
}

export default MuteIcon
