import {
  Button,
  Icon,
  Menu,
  MenuDivider,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {
  CheckmarkFilledIcon,
  GlobalIcon,
  CaretDownIcon,
} from '@himarley/unity';
import React from 'react';

import { languagesById, languageList } from '@app/helpers/languages';

interface LanguageMenuProps {
  languagePreference: string;
  selectLanguage: (language: string) => void;
}

const LanguageMenu: React.FC<LanguageMenuProps> = ({ languagePreference, selectLanguage }) => (
  <Menu placement="top">
    <MenuButton
      as={Button}
      variant="ghost"
      leftIcon={<GlobalIcon as={Icon} />}
      rightIcon={<CaretDownIcon as={Icon} />}
      size="sm"
      ml={1}
    >
      {languagesById[languagePreference] || 'en'}
    </MenuButton>
    <MenuList maxHeight="400px" overflowY="scroll" defaultValue={languagesById[languagePreference] || 'en'}>
      <MenuItem
        key={languagePreference}
        onClick={() => selectLanguage(languagePreference)}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {languagesById[languagePreference]}
        <Icon as={CheckmarkFilledIcon} mr={2} />
      </MenuItem>
      <MenuDivider />

      {languageList
        .filter((language) => language.id !== languagePreference)
        .map((language) => (
          <MenuItem
            key={language.id}
            onClick={() => selectLanguage(language.id)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {language.label}
            {languagePreference === language.id && <Icon as={CheckmarkFilledIcon} mr={2} />}
          </MenuItem>
        ))}
    </MenuList>
  </Menu>
);

export default LanguageMenu;
