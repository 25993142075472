import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import secureRequest from '@app/helpers/secure-request';
import { TemplateKey } from '@app/types/reducer-state';

import { GET_KEYS } from '../constants/endpoints';
import Key from '../models/key';

export const initialState = {
  keys: [],
  loadedTemplate: false,
  loadingTemplate: false,
  loadedTemplateError: false,
  templateIndex: 0,
  showTemplateSelector: false,
  loadedTemplateText: '',
};

export const getKeys = createAsyncThunk(
  'templates/get-keys',
  async () => {
    const args = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };

    const response = await secureRequest(GET_KEYS, args);
    const json = await response.json();
    return json.map((t: TemplateKey) => new Key(t));
  },
);

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    selectTemplate: (state, action) => ({ ...state, loadedTemplateText: action.payload.text }),
    clearLoadedTemplate: (state) => ({ ...state, loadedTemplate: false, loadedTemplateText: '' }),
  },
  extraReducers: (builder) => {
    builder.addCase(getKeys.fulfilled, (state, action) => {
      const keys = action.payload;
      let changes = {};
      if (keys) {
        changes = {
          keys,
        };
      }
      return { ...state, ...changes };
    });
  },
});
