import _ from 'lodash'
import { SET_EDIT_MODE } from '../constants/actions'
import { formatSuccess, formatInit, formatError } from '../constants/endpoints'

export const initialState = {
  errors: [],
  form: {
    editObject: {},
    hasEdits: false,
    isEdit: false,
    isCreate: false,
    readOnly: false,
  },
  requestCompleted: false,
  editObject: {},
  readonly: false,
  errorMessage: '',
  error: '',
  disabled: false,
}

const pluralize = item =>
  `${item.slice(0, item.length - 1)}${item.slice(-1) === 'Y' ? 'IES' : `${item.slice(-1)}S`}`

const reducer = (reducerId, state, action) => {
  switch (action.type) {
    case `ADD_${reducerId}_ERROR`: { // TODO different for claims and tempates (work like temp)
      const errors = _.concat(state.errors, action.payload)
      return { ...state, disabled: false, errors }
    }
    case 'REQUEST_NEW_USER_ERROR': {
      return { ...state, disabled: false}
    }
    case `ADD_${pluralize(reducerId).toUpperCase()}_ERROR`: {
      const errors = _.concat(state.errors, action.payload)
      return { ...state, errors }
    }
    case `CLEAR_${reducerId}_ERRORS`: {
      return { ...state, errors: [], errorMessage: '', error: '' }
    }
    case `OPEN_${reducerId}_CREATE_FORM`: {
      return { ...state,
        editObject: {},
        form: {
          ...state.form,
          editObject: {},
          readonly: false,
          isEdit: false,
          isCreate: true,
          hasEdits: false,
        },
        requestCompleted: false,
        readonly: false,
      }
    }
    case `CLOSE_${reducerId}_FORM`: {
      return { ...state,
        editObject: {},
        form: {
          ...state.form,
          editObject: {},
          readonly: false,
          isEdit: false,
          isCreate: false,
          hasEdits: false,
        },
        requestCompleted: false,
        readonly: false,
      }
    }
    case `SET_VIEW_${reducerId}`: {
      const listById = state.listById || state.listById
      const editObj = (reducerId == "USER") ? action.payload : listById[action.payload]
      return { ...state,
        showModal: true,
        activeItem: editObj,
        form: {
          ...state.form,
          editObject: editObj,
          readonly: true,
          isEdit: false,
          isCreate: false,
          hasEdits: false,
        },
        editObject: editObj,
        requestCompleted: false,
        readonly: true,
      }
    }
    case `SET_EDIT_${reducerId}`: { // TODO get rid of following, prior version of this
      const listById = state.listById || []
      // this extremely ugly hack is needed to get readOnly into a state that works for both
      // create case/claim/policy forms
      let editObject = _.get(action, 'payload.editObject') ? action.payload.editObject : listById[action.payload]
      if (!editObject) { editObject = action.payload }
      // Needed because this has nested objects.
      editObject = _.cloneDeep(editObject)
      let readOnly = !!((reducerId === "USER" && state.list.length <= 1 ))
      readOnly = _.get(action, 'payload.readOnly', undefined) !== undefined ? action.payload.readOnly : readOnly
      // end hack
      return { ...state,
        showModal: true,
        activeItem: editObject,
        form: {
          ...state.form,
          editObject,
          readonly: readOnly,
          isEdit: true,
          isCreate: false,
          hasEdits: false,
        },
        editObject,
        requestCompleted: false,
        readonly: false,
      }
    }
    case `SET_${reducerId}_IS_EDIT`: {
      return {
        ...state,
        form: {
          ...state.form,
          isEdit: action.payload,
        }
      }
    }
    case `SET_ACTIVE_${reducerId}`: {
      return { ...state, activeItem: state.listById[action.payload] }
    }
    case `CLEAR_${pluralize(reducerId).toUpperCase()}_EDIT_OBJECT`: {
      const except = action.payload
      const keepers = {}
      except.forEach((e) => {
        keepers[e] = _.get(state, 'form.editObject', {})[e]
      })
      return { ...state,
        activeItem: {},
        form: {
          ...state.form,
          editObject: { ...keepers },
          readonly: false,
          isEdit: false,
          hasEdits: false,
        },
        editObject: { ...keepers },
        readonly: false,
        requestCompleted: false,
      }
    }
    case `MODIFY_${reducerId}_EDIT_OBJECT`: {
      const { id, value } = action.payload
      const editObject = _.cloneDeep(state.editObject)
      _.set(editObject, id, value)
      const form = {
        ...state.form,
        editObject,
        hasEdits: true,
      }
      return { ...state, error: '', editObject, form }
    }
    case SET_EDIT_MODE: {
      return { ...state,
        readonly: false,
        form: { ...state.form, readonly: false, isEdit: true, hasEdits: false } }
    }
    case formatInit(`ADD_${reducerId}`): {
      return { ...state, requestCompleted: false, disabled: true }
    }
    case formatSuccess(`ADD_${reducerId}`): {
      return { ...state, requestCompleted: true, disabled: false }
    }
    case formatError(`ADD_${reducerId}`): {
      return { ...state, requestCompleted: false, disabled: false }
    }
    case formatInit(`NEW_${reducerId}`): {
      return { ...state, requestCompleted: false, disabled: true }
    }
    case formatSuccess(`NEW_${reducerId}`): {
      return { ...state, requestCompleted: true, disabled: false }
    }
    case formatError(`NEW_${reducerId}`): {
      return { ...state, requestCompleted: false, disabled: false }
    }
    default:
      return state
  }
}

export default reducer
