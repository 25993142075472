/* eslint-disable react/jsx-props-no-spreading */
import { LoadingCard } from '@himarley/unity';
import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import ChatCard from './chat-card';
import { ROW_TYPES } from './constants';
import styles from './InboxContent.module.less';
import PinnedHeader from './pinned-header';
import type { PageType } from './types';
import UnpinnedHeader from './unpinned-header';
import InboxFooter from '../InboxFooter/inbox-footer';

const PINNED_HEADER_ROW: PageType = { type: ROW_TYPES.PINNED_HEADER, size: 40 };
const UNPINNED_HEADER_ROW = { type: ROW_TYPES.UNPINNED_HEADER, size: 40 };
const END_OF_LIST_ROW = { type: ROW_TYPES.END_OF_LIST, size: 110 };

const renderRow = (props: {
  index: number;
  style: React.CSSProperties;
  windowWidth?: number | string;
  data: {
    pages: PageType[];
    hasPinnedChats: boolean;
    showPinnedChats: boolean;
    pinnedChatsCount: string | number;
    unpinnedLabelIndex?: string | number;
    toggleShowPinnedChats: () => void;
    setSize: (index: number, size?: number) => void;
    infiniteLoadRef: React.RefObject<InfiniteLoader>;
    containerRef?: React.RefObject<HTMLDivElement> | null;
  };
}) => {
  const { type } = (props?.data?.pages || [])[props?.index];
  const { setSize } = (props?.data || []);
  if (type === ROW_TYPES.PINNED_HEADER) {
    return <PinnedHeader {...props} setSize={setSize} />;
  }
  if (type === ROW_TYPES.UNPINNED_HEADER) {
    return <UnpinnedHeader {...props} setSize={setSize} />;
  }
  if (type === ROW_TYPES.END_OF_LIST) {
    return <InboxFooter {...props} setSize={setSize} />;
  }
  return <ChatCard {...props} setSize={setSize} />;
};

interface InboxContentProps {
  fetchNextPage?: () => void;
  isLoading?: boolean;
  isNextPageLoading?: boolean;
  hasNextPage?: boolean;
  unpinnedChats: PageType[];
  pinnedChats: PageType[];
  hasPinnedChats?: boolean;
  containerRef?: React.RefObject<HTMLDivElement> | null;
}

const InboxContent: React.FC<InboxContentProps> = ({
  fetchNextPage,
  isLoading = false,
  isNextPageLoading = false,
  hasNextPage = false,
  unpinnedChats = [],
  pinnedChats = [],
  containerRef,
  hasPinnedChats = false,
}) => {
  const listRef = useRef<List | null>(null);
  const infiniteLoadRef = useRef<InfiniteLoader | null>(null);
  const sizeMap = useRef<{ [key: number]: number }>({});
  const [showPinnedChats, setShowPinnedChats] = useState(true);
  const [pages, setPages] = useState<PageType[]>([]);

  const toggleShowPinnedChats = useCallback(() => {
    setShowPinnedChats(!showPinnedChats);
  }, [showPinnedChats]);

  useEffect(() => {
    if (hasPinnedChats) {
      const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;

      if (isUnreadStateRefactorEnabled) {
        const unreadPinnedChatsCount = pinnedChats.map(
          (p) => ((p.unreadMessageCount || 0) > 0 ? 1 : 0),
        ).reduce((a: number, b: number) => a + b, 0);
        PINNED_HEADER_ROW.unreadPinnedChatsCount = unreadPinnedChatsCount;
      }

      const allPages = showPinnedChats
        ? [...pinnedChats, UNPINNED_HEADER_ROW, ...unpinnedChats]
        : [UNPINNED_HEADER_ROW, ...unpinnedChats];
      allPages.unshift(PINNED_HEADER_ROW);
      allPages.push(END_OF_LIST_ROW);
      setPages(allPages);
    } else {
      setPages([...unpinnedChats, END_OF_LIST_ROW]);
    }
  }, [hasPinnedChats, pinnedChats, showPinnedChats, unpinnedChats]);

  const setSize = useCallback((index: number, size?: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size || 0 };
    listRef?.current?.resetAfterIndex(index);
  }, []);

  if (isLoading) {
    const loadingCards = [...Array(5).keys()];
    return (
      <section data-testid="inbox-content-loading">
        {loadingCards.map((card) => (
          <LoadingCard key={card} />
        ))}
      </section>
    );
  }

  const getSize = (index: number) => {
    const size = pages[index]?.size;
    if (size) {
      return size;
    }
    return sizeMap.current[index] || 110;
  };

  const loadMoreData = () => {
    if (fetchNextPage && hasNextPage) {
      fetchNextPage();
    }
  };

  const isItemLoaded = (index: number) => index < pages?.length;

  // eslint-disable-next-line no-unsafe-optional-chaining
  const itemCount = pages?.length + 1;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={isNextPageLoading ? () => {} : loadMoreData}
          ref={infiniteLoadRef}
        >
          {({ onItemsRendered }) => (
            <List
              className={styles.list}
              itemCount={pages?.length}
              height={height}
              itemSize={getSize}
              width={width}
              itemData={{
                pages,
                hasPinnedChats,
                infiniteLoadRef,
                pinnedChatsCount: pinnedChats?.length,
                toggleShowPinnedChats,
                showPinnedChats,
                setSize,
                containerRef,
              }}
              useIsScrolling
              ref={listRef}
              onItemsRendered={onItemsRendered}
            >
              {renderRow}
            </List>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default InboxContent;
