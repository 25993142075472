/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import './Ghosts.less'

const NUMBER_GHOST_ITEMS = 5
const TYPE_HASH = {
  card: 'marley-card-ghost',
}
const GhostRows = ({ id, className, numItems, type }) => {
  const classType = TYPE_HASH[type] || className
  const items = []
  for (let i = 0; i < (numItems || NUMBER_GHOST_ITEMS); i++) {
    items.push(<div key={`${id || 'common'}-ghost-${i}`} className={`list-item ${classType || 'default-item'}`} />)
  }

  return <div className="ghost">{items}</div>
}

export default GhostRows
