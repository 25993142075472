import {
  Checkbox, CheckboxGroup, Icon, Grid, GridItem, Heading, Text, Select, HStack, Box,
} from '@chakra-ui/react';
import { AddCircleIcon } from '@himarley/unity';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';

import usePermissionVerify from '@app/components/elements/hooks/usePermissionVerify';
import {
  PREFIX_CLAIM_NUMBER,
  PREFIX_PHONE_NUMBER,
  PREFIX_USER_TITLE,
} from '@app/constants/permissions';

import { constructUserPrefixPreview } from './constants';
import Preview from './Preview';

interface TitleDropdownList {
  id?: string;
  label?: string;
  placeholderLabel?: string;
  value?: boolean;
}

const titleDropdownList: TitleDropdownList[] = [
  {
    id: 'hide',
    label: "Don't Show Title",
    placeholderLabel: 'hide',
    value: false,
  },
  {
    id: 'show',
    label: 'Job Title',
    placeholderLabel: 'show',
    value: true,
  },
];

interface UserMessagePrefixProps {
  userMessagePrefix?: {
    isTitleEnabled: boolean;
    isCaseIdEnabled: boolean;
    isPhoneNumberEnabled: boolean;
  };
  isTitleEnabled?: boolean;
  isCaseIdEnabled?: boolean;
  isPhoneNumberEnabled?: boolean;
  setIsTitleEnabled?: (value: boolean) => void;
  setIsCaseIdEnabled?: (value: boolean) => void;
  setIsPhoneNumberEnabled?: (value: boolean) => void;
}

const UserMessagePrefix: React.FC<UserMessagePrefixProps> = ({
  userMessagePrefix = {},
  isTitleEnabled = false,
  isCaseIdEnabled = false,
  isPhoneNumberEnabled = false,
  setIsTitleEnabled = () => { },
  setIsCaseIdEnabled = () => { },
  setIsPhoneNumberEnabled = () => { },
}) => {
  const { hasAccess: hasCaseIdNumberPrefix } = usePermissionVerify({
    neededPermissions: [PREFIX_CLAIM_NUMBER],
  });
  const { hasAccess: hasPhoneNumberPrefix } = usePermissionVerify({
    neededPermissions: [PREFIX_PHONE_NUMBER],
  });
  const { hasAccess: hasUserTitlePrefix } = usePermissionVerify({
    neededPermissions: [PREFIX_USER_TITLE],
  });
  const [titleItem, setTitleItem] = useState<TitleDropdownList>(titleDropdownList[0]);

  // set user message prefix default values - check api object first -
  // if not present default to prefix flags
  useEffect(() => {
    if (!isEmpty(userMessagePrefix)) {
      setIsTitleEnabled(userMessagePrefix.isTitleEnabled ?? hasUserTitlePrefix);
      setIsCaseIdEnabled(userMessagePrefix.isCaseIdEnabled ?? hasCaseIdNumberPrefix);
      setIsPhoneNumberEnabled(userMessagePrefix.isPhoneNumberEnabled ?? hasPhoneNumberPrefix);
    } else {
      setIsTitleEnabled(hasUserTitlePrefix);
      setIsCaseIdEnabled(hasCaseIdNumberPrefix);
      setIsPhoneNumberEnabled(hasPhoneNumberPrefix);
    }
  }, [
    hasCaseIdNumberPrefix,
    hasPhoneNumberPrefix,
    hasUserTitlePrefix,
    userMessagePrefix,
    setIsCaseIdEnabled,
    setIsPhoneNumberEnabled,
    setIsTitleEnabled,
  ]);

  useEffect(() => {
    setTitleItem(isTitleEnabled ? titleDropdownList[1] : titleDropdownList[0]);
  }, [isTitleEnabled]);

  const titleToggle = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const item = titleDropdownList.find((i) => i.id === e.target.value) || {};
    setTitleItem(item);
    setIsTitleEnabled(item.value || false);
  };

  return (
    <HStack gap="10%">
      <Box w="50%">
        <Grid templateRows="repeat(4, 1fr)" templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={3}>
            <Heading as="h1" size="md">
              User Message Prefix
            </Heading>
            <Text>
              Displays as the beginning of outgoing SMS text messages sent from
              users.
            </Text>
          </GridItem>
          <GridItem>
            <Text color="gray.500" mb="5px">Name Format</Text>
            <Text>First Name, Last Initial</Text>
          </GridItem>
          <GridItem justifyContent="center" display="flex" alignItems="center">
            <Icon as={AddCircleIcon} />
          </GridItem>
          <GridItem>
            <Text color="gray.500" mb="5px">Title/Role</Text>
            <Select w="150px" size="sm" variant="flushed" value={titleItem?.id} onChange={titleToggle}>
              {titleDropdownList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.label}
                </option>
              ))}
            </Select>
          </GridItem>
          <GridItem>
            <Text color="gray.500" mb="5px">Optional Fields</Text>
            <CheckboxGroup>
              <Checkbox
                isChecked={isCaseIdEnabled}
                onChange={(e) => setIsCaseIdEnabled(e.target.checked)}
                size="sm"
              >
                Case ID
              </Checkbox>
              <Checkbox
                isChecked={isPhoneNumberEnabled}
                onChange={(e) => setIsPhoneNumberEnabled(e.target.checked)}
                size="sm"
              >
                Phone
              </Checkbox>
            </CheckboxGroup>
          </GridItem>
          <GridItem justifyContent="center" display="flex" alignItems="center">
            <Icon as={AddCircleIcon} />
          </GridItem>
          <GridItem mt="5px">
            <Text color="gray.500" mb="5px">Org/Brand Name</Text>
            <Text>$BrandName</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text color="gray.500" mb="5px">
              NOTE: Message prefixes will cut off (and truncate...) at 100
              characters.
            </Text>
          </GridItem>
        </Grid>
      </Box>
      <Box w="50%">
        <Preview
          firstTextName={constructUserPrefixPreview(
            isTitleEnabled,
            isCaseIdEnabled,
            isPhoneNumberEnabled,
          )}
          firstTextMessage="Hi Charlotte, I’m Justin your Lovable Insurance claims adjuster.
        I’ll be helping resolve your auto claim."
          secondTextMessage="Hi Justin"
        />
      </Box>
    </HStack>

  );
};

export default UserMessagePrefix;
