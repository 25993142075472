/* eslint-disable object-shorthand */
import { ClaimsFormValidation } from '../claimsForm/claimsFormValidation';
import _ from 'lodash'

export const PolicyFormValidation = {
  formatDeductible: ClaimsFormValidation.formatDeductible,
  validateDeductible: ClaimsFormValidation.validateDeductible,

  validatePolicyNumber: function (policyNumber) {
    return !!policyNumber;
  },

  // would be nice to have data about the field EX: isRequired in the form data. 🤔
  policyFormValidation: function ({ fieldName, policyFormData, map }) {
    const validator = {
      deductible: this.validateDeductible,
      policyNumber: this.validatePolicyNumber,
    };

    const value = _.get(policyFormData, map(fieldName));
    const validationMethod = validator[fieldName];

    const validation = {
      isValid: true,
    };

    if (value && validationMethod && !validationMethod(value)) {
      validation.isValid = false;
      return validation;
    }

    return validation;
  },
};
