/* eslint-disable no-unsafe-optional-chaining */
import React, {
  useMemo, useRef, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import InfoPanel from './panels/InfoPanel/InfoPanel';
import ListingPanel from './panels/ListingPanel/ListingPanel';
import './GroupRow.less';

const GroupRow = ({
  group, toggleModal, setItemSize, index,
}) => {
  const [isGroupLeadsExpanded, setIsGroupLeadsExpanded] = useState(false);
  const groupRowRef = useRef(null);
  const membersAndSubgroups = useMemo(() => {
    const subgroups = group?.subgroups?.map((subgroup) => ({
      ...subgroup,
      isGroup: true,
      id: subgroup?._id,
    })) || [];
    return [...group?.members, ...subgroups];
  }, [group]);

  useEffect(() => {
    if (groupRowRef.current.firstChild) {
      setItemSize(
        index,
        groupRowRef.current.firstChild.getBoundingClientRect().height,
      );
    }
  }, [groupRowRef, setItemSize, isGroupLeadsExpanded, index, group?.leads]);

  return (
    <div ref={groupRowRef}>
      <div className="group-row-container">
        <div
          className="group-row"
          data-test="group-row"
          style={{ display: 'flex', width: '100%' }}
        >
          <InfoPanel group={group} toggleShow={setIsGroupLeadsExpanded} />
          <ListingPanel
            members={membersAndSubgroups || []}
            groupId={group?.id}
            openEditModal={() => toggleModal(group)}
          />
        </div>
      </div>
    </div>
  );
};

GroupRow.propTypes = {
  group: PropTypes.instanceOf(Object),
  toggleModal: PropTypes.func.isRequired,
  setItemSize: PropTypes.func.isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

GroupRow.defaultProps = {
  group: [],
};

export default GroupRow;
