import React from 'react'
import PropTypes from 'prop-types'
import './Typing.less'

const Typing = ({firstName, lastName}) => {
  const nameLength = firstName.length + lastName.length
  /* If the total length of the user's name is longer than 24 characters we show 
  their first name with only the first initial of their last. */
  const displayName = nameLength > 24 ? `${firstName} ${lastName.charAt(0)}.` : `${firstName} ${lastName}`

  return (
    <div className="typing-notification">
      <strong className="typing-notification-name">{displayName}</strong>is typing
      <div className="typing-notification-dots">
        <div className="typing-notification-dot">.</div>
        <div className="typing-notification-dot second-dot">.</div>
        <div className="typing-notification-dot third-dot">.</div>
      </div>
    </div>  
  )
}

Typing.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
}

export default Typing
