import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { MultiSelectDropdownMenu } from '@himarley/unity';
import styles from './FilterTray.module.less';

const BrandLoBSelect = ({
  menuItems,
  setSelectedItems,
  selectedItems,
  container,
}) => {
  const handleSelectItem = useCallback(
    (item) => {
      const selectedItemsMap = new Map(selectedItems);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      selectedItemsMap.has(item?.id)
        ? selectedItemsMap.delete(item?.id)
        : selectedItemsMap.set(item?.id, item?.label);
      setSelectedItems(selectedItemsMap);
    },
    [selectedItems, setSelectedItems],
  );

  return (
    <MultiSelectDropdownMenu
      triggerClassName={styles.autoWidth}
      menuItems={menuItems}
      selectedItemLabel={[...selectedItems.values()].join(', ')}
      selectedItemsMap={selectedItems}
      onSelectItem={handleSelectItem}
      container={container}
    />
  );
};

BrandLoBSelect.propTypes = {
  menuItems: PropTypes.shape({}).isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  selectedItems: PropTypes.instanceOf(Map).isRequired,
  container: PropTypes.node.isRequired,
};

export default BrandLoBSelect;
