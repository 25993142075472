import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@himarley/unity';
import styles from './EmptyPlaceholder.module.less';

const EmptyPlaceholder = ({
  text,
  className,
  buttonText,
  onClick,
  ...other
}) => (
  <section
    {...other}
    className={styles.wrapper}
  >
    {text && <p>{text}</p>}
    {buttonText && <Button type="outline" onClick={onClick}>{buttonText}</Button>}
  </section>
);

EmptyPlaceholder.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};
EmptyPlaceholder.defaultProps = {
  className: '',
  text: null,
  buttonText: null,
  onClick: () => {},
  type: 'primary',
  children: null,
};

export default EmptyPlaceholder;
