/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import PropTypes from 'prop-types'

const CreateMassNotificationScheduling = ({
  createButtonEnabled,
  handleChangeScheduleEnabled,
  handleFormInputChange,
  handleClickSubmit,
  handleBackStep,
  handleNextStep,
  isScheduleEnabled,
  minScheduledDatetimeLocalStr,
  showErrorMessage,
  formData,
}) => (
  <>
    <div className="modal-container paddedInModal">
      <h3 className="modal-step-title">Step 2: Schedule Notification</h3>
      <p>
        You are able to schedule a notification to be sent out at a future time
        or you can start sending it right away.
      </p>
      <div className="radio-btn-group">
        <label className="radio-btn-field">
          <input
            checked={isScheduleEnabled}
            className="radio-btn-input"
            name="isScheduleEnabled"
            onChange={handleChangeScheduleEnabled}
            type="radio"
            value="yes"
          />
          <span className="radio-btn-label">Schedule at a specific time</span>
        </label>
        <label className="radio-btn-field">
          <input
            checked={!isScheduleEnabled}
            className="radio-btn-input"
            name="isScheduleEnabled"
            onChange={handleChangeScheduleEnabled}
            type="radio"
            value="no"
          />
          <span className="radio-btn-label">Create notification now</span>
        </label>
      </div>
      {
        isScheduleEnabled ? (
          <>
            <h4 className="modal-section-title">Date/Time to Schedule</h4>
            <p>Select the date and time your notification begins sending:</p>
            <div className="notification-scheduled-container">
              <div className="innerContainer">
                <div className="inputTitle">Scheduled Send Date</div>
                <input
                  className="notification-scheduled-datetime-input"
                  type="datetime-local"
                  min={minScheduledDatetimeLocalStr}
                  name="scheduledDatetime"
                  onChange={handleFormInputChange}
                  formNoValidate
                  value={formData.scheduledDatetime}
                />
              </div>
              <div className="innerContainer">
                <div className="inputTitle">Destination Timezone</div>
                <select
                  className="notification-scheduled-timezone-input"
                  name="scheduledTimezone"
                  onChange={handleFormInputChange}
                  value={formData.scheduledTimezone}
                >
                  <option value="US_EAST">Eastern (US)</option>
                  <option value="US_CENTRAL">Central (US)</option>
                  <option value="US_MOUNTAIN">Mountain (US)</option>
                  <option value="US_PACIFIC">Pacific (US)</option>
                </select>
              </div>
            </div>
          </>
        ) : null
      }
    </div>
    <div className="buttonRow">
      {
        showErrorMessage
          ? <div className="warningText errorText">Failed to create Mass Notification, please try again later!</div>
          : null
      }
      <button
        type="button"
        onClick={handleBackStep}
        className="hymarley-button secondary"
      >
        Back
      </button>
      {
        isScheduleEnabled ? (
          <button
            type="button"
            onClick={handleNextStep}
            className="next-btn hymarley-button primary"
            disabled={
              !createButtonEnabled || !formData.scheduledDatetime || !formData.scheduledTimezone
            }
          >
            Next
          </button>
        ) : (
          <button
            type="button"
            onClick={handleClickSubmit}
            className="next-btn hymarley-button primary"
            disabled={!createButtonEnabled}
          >
            Create Now
          </button>
        )
      }
    </div>
  </>
)

CreateMassNotificationScheduling.propTypes = {
  createButtonEnabled: PropTypes.bool,
  handleChangeScheduleEnabled: PropTypes.func.isRequired,
  handleFormInputChange: PropTypes.func.isRequired,
  handleClickSubmit: PropTypes.func.isRequired,
  handleBackStep: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  isScheduleEnabled: PropTypes.bool.isRequired,
  minScheduledDatetimeLocalStr: PropTypes.string.isRequired,
  showErrorMessage: PropTypes.bool,
  formData: PropTypes.shape({
    scheduledDatetime: PropTypes.string,
    scheduledTimezone: PropTypes.string,
  }).isRequired,
}

CreateMassNotificationScheduling.defaultProps = {
  createButtonEnabled: undefined,
  showErrorMessage: undefined,
}

export default CreateMassNotificationScheduling
