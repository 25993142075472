/* eslint-disable react/jsx-props-no-spreading */
import { Icon, IconProps } from '@chakra-ui/react';
import {
  AutoIcon,
  HomeIcon,
  MoreCircleIcon,
  CropIcon,
  WorkersCompIcon,
  LifeInsuranceIcon,
  BuildingIcon,
  GeneralLiabilityIcon,
  TruckIcon,
} from '@himarley/unity';
import React from 'react';

const LOB_TYPE_TO_ICON: {
  [key: string]: (props: IconProps) => JSX.Element;
} = {
  home: (props) => <Icon as={HomeIcon as React.FC<IconProps>} {...props} />,
  other: (props) => <Icon as={MoreCircleIcon as React.FC<IconProps>} {...props} />,
  health: (props) => <Icon as={WorkersCompIcon as React.FC<IconProps>} {...props} />,
  truck: (props) => <Icon as={TruckIcon as React.FC<IconProps>} {...props} />,
  property: (props) => <Icon as={BuildingIcon as React.FC<IconProps>} {...props} />,
  auto: (props) => <Icon as={AutoIcon as React.FC<IconProps>} {...props} />,
  'general-liability': (props) => <Icon as={GeneralLiabilityIcon as React.FC<IconProps>} {...props} />,
  'workers-comp': (props) => <Icon as={WorkersCompIcon as React.FC<IconProps>} {...props} />,
  crop: (props) => <Icon as={CropIcon as React.FC<IconProps>} {...props} />,
  life: (props) => <Icon as={LifeInsuranceIcon as React.FC<IconProps>} {...props} />,
};

interface LoBIconProps extends IconProps {
  name: string;
}

const LoBIcon = ({
  name,
  ...props
}: LoBIconProps) => {
  const IconComponent = LOB_TYPE_TO_ICON[name?.toLowerCase()] || LOB_TYPE_TO_ICON.other;
  return IconComponent(props);
};

export default LoBIcon;
