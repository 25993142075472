import emojilib from 'emojilib'
import _ from 'lodash'

function extractEmoji() {
  const list = _(emojilib.ordered)
    .filter(id => emojilib.lib[id].category === 'people')
    .map(id => ({
      id,
      icon: emojilib.lib[id].char,
    }))
    .value()
  return list
}

const list = extractEmoji()

export function getEmojis() {
  return list
}

export function encodeEmoji(icon) {
  return `:${icon.id}:`
}

export function decodeEmoji(id) {
  return _.get(emojilib.lib, `${id}.char`)
}

export function getMainIcon() {
  return emojilib.lib.slightly_smiling_face.char
}

export function formatMessageWithEmojis(message) {
  const regex = /:[a-zA-Z0-9-_+]+?:/g
  const text = _.replace(message, regex, (pattern) => {
    const iconId = pattern.substr(1, pattern.length - 2)
    return decodeEmoji(iconId) || pattern
  })
  return text
}

/** 
* Returns message formatted with correct emojis for messages that both need and don't need redacting.
* @param {string | array} message - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
* @return {string | array} Brief description of the returning value here.
*/
export function formatRedactedMessageWithEmojis(message = '') {
  let text

  if (typeof message === 'string') {
    text = formatMessageWithEmojis(message)
  } else {
    text = message.map((slice) => {
      if (typeof slice === 'string') {
        return formatMessageWithEmojis(slice)
      } else {
        return slice
      }
    })
  }

  return text
}