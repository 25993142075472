/* eslint-disable no-undef */
/* eslint-disable no-else-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable indent */
import _ from 'lodash';
import { updateFirstUnreadMessage } from '@app/helpers/unread-message';

import { isApplicableUnreadMessage } from '@app/helpers/inbox.helpers';
import {
  ASSIGN_USER,
  LOAD_CHAT_MESSAGES,
  NEW_MESSAGE,
  SET_CASE_SELECTOR,
  ACTIVE_JOB,
  SET_ACTIVE_CHAT,
  OPEN_DASHBOARD_VIEW,
  CHAT_SEND_FILE_STARTED,
  CHAT_SEND_FILE_COMPLETED,
  CHAT_SEND_FILE_ERROR,
  CHAT_SEND_FILE_CLEAR,
  UPDATED_USER,
  DEAUTH_USER,
  WELCOME_EMAIL_REQUEST_CLEAN,
  WELCOME_EMAIL_IS_SENT,
  RESEND_WELCOME_EMAIL_FOR_JOB_ERROR,
  APPEND_CHAT_MESSAGES,
  PRESENT_MESSAGE,
  UPDATE_CHAT_MESSAGES,
  UPDATE_JOB,
  LOAD_CHAT_PARTICIPANTS,
  LOAD_JOBS,
  PUT_JOB,
  LOAD_LOOK_CASE_IDS,
  SHOW_SEND_FILE_MODAL,
  REQUEST_REOPEN_JOB_ERROR,
  CLEAR_REOPEN_JOB_ERROR,
  REQUEST_PUT_JOB_SUCCESS,
  SET_ACTIVE_CHAT_FIRST_KEY_STROKE,
  USER_IS_TYPING,
  USER_IS_TYPING_TIMEOUT,
  FETCH_CASE_TYPES,
  INLINE_MESSAGE_UPDATE,
  INLINE_UPDATE_JOB_NEEDS_ATTENTION_MESSAGE_COUNT,
  PUT_MESSAGE_NEEDS_ACTION_RESPONSE,
  PUT_JOB_NEEDS_ACTION_RESPONSE,
  UNREDACTED_MESSAGE,
  CLEAR_UNREDACTED_MESSAGE,
  UPDATE_MESSAGE,
  ENDUSER_JOINED_CHAT,
  LOAD_ALL_CHAT_PARTICIPANTS,
  READ_NOTE_MENTION,
  ADD_REPLY,
  DELETE_REPLY,
  FETCH_FILTERED_OPERATOR_IDS,
  PATCH_JOB,
  INLINE_UPDATE_JOB_NEEDS_ACTION_MESSAGE_COUNT,
  UPDATE_JOB_SEARCH,
  SET_EXPAND_SEARCH_BAR,
  UPDATE_SEARCH_RESULTS_COUNT,
  UPDATE_TOTAL_CHAT_COUNT,
  REDACT_IMAGE,
  VIEW_REDACTED_IMAGE,
  UPDATE_UNREAD_MESSAGES,
  UPDATE_VIRUS_SCAN_STATUS,
} from '../constants/actions';
import { saveLastActiveChat } from '../helpers/localStorage';
import Message from '../models/message';
import {
  buildIdList,
  isJobPrivacy,
  isVisibleToOperator,
  incrementJobNeedsAttention,
  incrementJobNeedsAction,
  handleImageRedaction,
  updateRedactionViewedEvents,
} from './helpers/index';
import * as commonReducer from './common';
import User from '../models/user';
import { s3toPipedUrl } from '../helpers/urls';
import { completeReleaseIssues } from '../actions/job';

export const initialState = {
  ...commonReducer.initialState,
  activeJob: {},
  activeChat: {},
  caseSelectorId: 'assigned',
  chatIdsByJobId: {},
  chats: {},
  caseTypes: null,
  // eslint-disable-next-line no-undef
  lastJobRequest: SERVER_TIMESTAMP_FROM_POLL,
  pinnedJobs: [],
  uploadFileError: '',
  uploadFileIsInProgress: false,
  welcomeEmailError: '',
  welcomeEmailRequest: false,
  presetFilterIds: [],
  showSendFileModal: false,
  fileToSend: null,
  reopenChatsError: '',
  markChatUnreadError: '',
  filteredOperatorIds: [],
  viewedChats: null, // setting to null initially keeps blue dots from loading on all chats
  search: null,
  expandInboxSearchBar: false,
  prefetchCasesCount: 0,
};

export const updateJobHelper = (action, state) => {
  const { operatorId, job, permissions } = action.payload;
  const activeJob = { ...state.activeJob };
  let list = state.list.slice();
  const { filter } = state;
  const changesForMatchedActiveJob = {};
  let changesForUpdatedList = {};
  // this is brittle and relies on only one filter being set based on the view
  const searchFilter = filter?.chats || filter?.case;
  const isJobInFilteredList = searchFilter ? commonReducer.matchesSearchFilter(job, searchFilter) : true;
  const isActiveJob = job.id === activeJob.id;
  // No need to update
  if (!isActiveJob && !isJobInFilteredList) return state;

  // Update list of jobs
  if (isJobInFilteredList) {
    list = list.map((item) => {
      // Skip on no match
      if (job.id !== item.id) return item;

      // If we should redact, override item with redacted job
      if (isJobPrivacy(job, 'private-redacted') && !isVisibleToOperator(job, operatorId, permissions)) {
        return {
          id: job.id,
          displayId: job.displayId,
          createdAt: job.createdAt,
          assignedOperator: job.assignedOperator,
          privacy: job.privacy,
          isOpen: job.isOpen,
          isClosed: job.isClosed,
          latestActivity: job.latestActivity,
        };
      }

      // Match found and do extra modifying to job before injecting into list
      if (_.get(job, 'lastMessage.authorId') === operatorId) job.hasUnreadMessages = false;

      // this is a hack to prevent invalid dates getting set.  needs further debug
      if (!_.get(job, 'createdAt')) job.createdAt = item.createdAt;
      return job;
    });
    if (!list.includes(job)) list.push(job);
    changesForUpdatedList = { list, ...buildIdList(list) };
  }

  // Update active job
  if (isActiveJob) {
    if (isJobPrivacy(job, 'private-redacted') && !isVisibleToOperator(job, operatorId, permissions)) {
      changesForMatchedActiveJob.activeJob = {};
      changesForMatchedActiveJob.activeChat = {};
    } else {
      changesForMatchedActiveJob.activeJob = _.cloneDeep(job);
    }
  }

  return {
    ...changesForUpdatedList,
    ...changesForMatchedActiveJob,
  };
};

export const LOOK_CASE_IDS_HASH = {
  171: 'openCases',
  172: 'waitingOnOptIn',
  174: 'unansweredOptIns',
  405: 'responseRecommended',
};

// eslint-disable-next-line consistent-return
const jobs = (state = initialState, action) => {
  switch (action.type) {
    case 'INCREMENT_PREFETCH_CASES_COUNT': {
      return { ...state, prefetchCasesCount: state.prefetchCasesCount + 1 };
    }
    case completeReleaseIssues?.fulfilled?.type: {
      // update job with updated release issues
      const { releaseIssues, eventLog } = action.payload;
      const { list, activeJob } = state;
      return {
        ...state,
        activeJob: { // update active job
          ...activeJob,
          releaseIssues,
          eventLog,
        }, // update list of jobs
        list: list.map((job) => (job.id === activeJob.id ? { ...job, releaseIssues, eventLog } : job)),
      };
    }
    case LOAD_LOOK_CASE_IDS: {
      const { payload } = action;
      const { lookNumber, result } = payload;

      const id = LOOK_CASE_IDS_HASH[lookNumber];
      const lookResult = { id, caseIds: result };
      const presetFilterIds = [...state.presetFilterIds].filter((pfi) => pfi.id !== id);
      presetFilterIds.push(lookResult);

      return {
        ...state,
        presetFilterIds,
      };
    }
    case 'GET_SCHEDULED_MESSAGES': {
      return { ...state, scheduledMessages: action.payload };
    }
    case READ_NOTE_MENTION: {
      const { payload } = action;
      const { userId, noteId, replyId } = payload;
      const activeNotes = { ...state.activeNotes };
      const activeJob = { ...state.activeJob };
      const theNote = activeNotes?.notes?.find((note) => note?._id === noteId);

      const unreadNoteMentions = { ...activeJob.unreadNoteMentions };
      delete unreadNoteMentions[userId];
      const newActiveJob = { ...activeJob, unreadNoteMentions };
      const list = state.list.map((job) => {
        if (job._id === activeJob?.id) {
          _.set(job, 'unreadNoteMentions', unreadNoteMentions);
        }
        return job;
      });

      if (replyId) {
        // remove unread state from the reply
        const theReply = theNote?.replies?.find((reply) => reply?._id === replyId);
        const notReadMentionedUsers = { ...theReply?.notReadMentionedUsers };
        delete notReadMentionedUsers[userId];
        theReply.notReadMentionedUsers = notReadMentionedUsers;
        theNote?.replies?.map((reply) => (reply?._id === replyId ? theReply : reply));
        const updatedNotes = activeNotes?.notes?.map((note) => (note?._id === noteId ? theNote : note));
        return {
          ...state,
          activeNotes: {
            ...state.activeNotes,
            notes: updatedNotes,
          },
          activeJob: newActiveJob,
          list,
        };
      } else {
        // remove unread state from the note
        const notReadMentionedUsers = { ...theNote?.notReadMentionedUsers };
        delete notReadMentionedUsers[userId];
        return {
          ...state,
          activeNotes: {
            ...state.activeNotes,
            notes: [...state.activeNotes.notes.map((note) => (note?._id === noteId ? { ...note, notReadMentionedUsers } : note))],
          },
          activeJob: newActiveJob,
          list,
        };
      }
    }
    case 'CANCEL_SCHEDULED_MESSAGE': {
      const { payload } = action;
      const { id } = payload;
      const scheduledMessages = [...state.scheduledMessages].filter((m) => m.id !== id);
      return { ...state, scheduledMessages };
    }
    case 'BULK_UPDATE_JOB': {
      return { ...state };
    }
    case 'MODIFIED_JOB_LANGUAGE': {
      const { inTranslationMode, jobId, languagePreference } = action.payload;
      const activeJob = { ...state.activeJob };
      if (activeJob.id === jobId) {
        _.set(activeJob, 'inTranslationMode', inTranslationMode);
        _.set(activeJob, 'languagePreference', languagePreference);
      }
      // find the job and also update (duplicate references are fun!)
      const list = state.list.map((job) => {
        if (job._id === jobId) {
          _.set(job, 'inTranslationMode', inTranslationMode);
          _.set(job, 'languagePreference', languagePreference);
        }
        return job;
      });

      return { ...state, activeJob, list };
    }
    case 'FILTER_ITEMS': {
      const { filter, type } = action.payload;
      const changes = {
        filter: { ...state.filter },
      };
      changes.filter[type] = filter;
      changes[`${type}Filter`] = filter;
      return { ...state, ...changes };
    }
    case 'MODIFIED_BRANDING': {
      // TODO: is this reducer doing anything nothing is getting returned
      const { branding, jobId } = action.payload;
      const activeJob = { ...state.activeJob };
      if (activeJob.id === jobId) {
        _.set(activeJob, 'branding', branding);
      }
      // TODO: remove once reducer actually returns something
      break;
    }

    case OPEN_DASHBOARD_VIEW: {
      return { ...state, form: {} };
    }
    case LOAD_CHAT_PARTICIPANTS: {
      let changes;
      if (action.payload.users) {
        const { chatId } = action.payload;
        const chats = { ...state.chats };
        if (!chats[chatId]) chats[chatId] = {};
        chats[chatId].participants = action.payload.users;
        changes = { chats };
      }
      return { ...state, ...changes };
    }
    case LOAD_ALL_CHAT_PARTICIPANTS: {
      const { chatId, activeParticipants } = action.payload;
      if (chatId && state.chats[chatId] && activeParticipants) {
        const chats = {
          ...state.chats,
          [chatId]: {
            ...state.chats[chatId],
            participants: activeParticipants,
          },
        };
        return { ...state, chats };
      }
      return state;
    }
    case LOAD_CHAT_MESSAGES: {
      let changes;
      if (action.payload.chat) {
        const { chat } = action.payload;
        const chats = { ...state.chats };
        if (chats[chat.id]) {
          chats[chat.id] = chat;
        }
        changes = { chats };
      }
      return { ...state, ...changes };
    }
    case UNREDACTED_MESSAGE: {
      const activeChat = { ...state.activeChat };
      activeChat.unredactedMessage = action.payload;
      return { ...state, activeChat };
    }
    case CLEAR_UNREDACTED_MESSAGE: {
      const activeChat = { ...state.activeChat };
      activeChat.unredactedMessage = {};
      return { ...state, activeChat };
    }
    case UPDATE_MESSAGE: {
      const activeChat = { ...state.activeChat };
      const updatedMessage = new Message(action.payload.newMessage);
      const newMessages = activeChat.messages.map((message) => {
        if (message.id === updatedMessage.id) {
          const newMessage = {
            ...message,
            deliveryStatus: updatedMessage.deliveryStatus,
            redactionViewedEvents: updatedMessage.redactionViewedEvents,
            bodyPrefix: updatedMessage?.bodyPrefix,
          };
          return newMessage;
        }
        return message;
      });
      activeChat.messages = newMessages;

      return { ...state, activeChat };
    }
    case 'ADD_TRANSLATION': {
      const activeChat = { ...state.activeChat };
      const { messageId, translatedMessage } = action.payload;
      activeChat.messages = activeChat.messages.slice().map((m) => {
        if (m.id === messageId) {
          m.setTranslation(translatedMessage);
          return m;
        }
        return m;
      });
      return { ...state, activeChat };
    }
    case NEW_MESSAGE: {
      let changes;
      const {
        jobId, message: newMessage, chatId, currentUserId: currentOperator,
      } = action.payload;

      const {
        list, chats, viewedChats, activeJob, activeChat,
      } = state;
      if (chatId) {
        const newMessageAuthor = newMessage.author?.id;

        const chat = chats[chatId];
        if (chat && chat.messages) {
          const messages = chat.messages.slice();
          messages.push(newMessage);
          chat.messages = messages;
        }
        changes = { chats, viewedChats: new Set(viewedChats) };
        // forward path for updating unread message status in the UI
        // currently jobId is used to track viewed status
        const removeViewedChat = jobId && newMessageAuthor !== currentOperator && !newMessage.isAI && activeJob.id !== jobId;
        if (removeViewedChat) {
          changes.viewedChats.delete(jobId);
        }
        _.set(changes, 'list', list);
        if (chatId === activeChat.id) {
          const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;

          let added = false;
          if (isUnreadStateRefactorEnabled
            && !activeChat.messages.find((activeChatMessage) => activeChatMessage.isFirstUnreadMessage)
            && isApplicableUnreadMessage(newMessage, currentOperator, !activeJob?.lastViewedMessage?.messageId)
          ) {
            newMessage.isFirstUnreadMessage = true;
          }

          let activeChatMessages = activeChat.messages.map((activeChatMessage) => {
            if (activeChatMessage.id === newMessage.id || (!activeChatMessage?.id && activeChatMessage?.messageBody === newMessage.messageBody)) {
              added = true;
              // attempt to catch edge case where INLINE_MESSAGE_UPDATE action is run before NEW_MESSAGE action
              if ((activeChatMessage?.sentimentData?.sentiment && !newMessage?.sentimentData?.sentiment)
                || (activeChatMessage.classifications && !newMessage.classifications)) {
                return {
                  ...newMessage,
                  sentimentData: activeChatMessage.sentimentData,
                  classifications: { ...newMessage.classifications },
                  isFirstUnreadMessage: activeChatMessage.isFirstUnreadMessage,
                };
              }
              return { ...newMessage, isFirstUnreadMessage: activeChatMessage.isFirstUnreadMessage };
            }
            return activeChatMessage;
          });
          if (!added) { // new message has not been added to active chat messages
            // if message exists replace with new updated message else add it to the end of the list
            activeChatMessages = [...activeChatMessages, newMessage];
          }
          _.set(changes, 'activeChat', {
            ...activeChat,
            messages: activeChatMessages,
          });
          const { fileUrl, fileType } = newMessage;

          if ((fileUrl && !changes.activeChat.media.some((item) => item.fileUrl === fileUrl))
            || (fileType && !changes.activeChat.media.some((item) => item.fileType === fileType))) {
            newMessage.fileUrl = s3toPipedUrl(fileUrl);
            changes.activeChat.media = [newMessage, ...changes.activeChat.media];
          }
        }
      }
      return {
        ...state, ...changes, ...buildIdList(list), ...updateJobHelper(action, state),
      };
    }
    case UPDATE_UNREAD_MESSAGES: {
      let changes;
      const { jobId, messageId, currentUserId } = action.payload;
      const { activeChat } = state;

      if (jobId === activeChat?.activeJob?.id) {
        const activeChatMessages = updateFirstUnreadMessage(activeChat.messages, messageId, currentUserId);

        changes = {
          activeChat: {
            ...activeChat,
            messages: activeChatMessages,
          },
        };
      }

      return {
        ...state, ...changes,
      };
    }
    case UPDATE_JOB: {
      return { ...state, ...updateJobHelper(action, state) };
    }
    case PATCH_JOB:
    case PUT_JOB: {
      const job = _.get(action, 'payload', {});
      const list = [..._.get(state, 'list', [])];
      const changes = {};
      if (job.id) {
        const newList = list.map((j) => {
          if (j.id === job.id) {
            return job;
          }
          return j;
        });
        changes.list = newList;
        changes.listById = buildIdList(newList).listById;
      }
      return {
        ...state,
        ...changes,
      };
    }
    case INLINE_UPDATE_JOB_NEEDS_ATTENTION_MESSAGE_COUNT: {
      const newChanges = {
        activeJob: state.activeJob,
        list: state.list,
      };
      const message = action.payload?.message;
      if (state.activeJob.primaryChatId === message.chatId) {
        newChanges.activeJob = incrementJobNeedsAttention(state.activeJob, action.payload.needsAttentionMessageCount);
      }

      newChanges.list = state.list.map((singleJob) => {
        if (message.chatId === singleJob.primaryChatId) {
          return incrementJobNeedsAttention(singleJob, action.payload.needsAttentionMessageCount);
        }
        return { ...singleJob };
      });

      return { ...state, ...newChanges };
    }

    case INLINE_UPDATE_JOB_NEEDS_ACTION_MESSAGE_COUNT: {
      const { job } = action.payload;
      const newChanges = {
        activeJob: { ...state.activeJob },
        list: [...state.list],
      };

      const message = action.payload.message || action.payload.msg;
      if (state.activeJob.primaryChatId === message.chatId) {
        newChanges.activeJob = incrementJobNeedsAction(newChanges.activeJob, job.needsAction?.hasQuestionMessageCount, 'hasQuestionMessageCount');
      }

      newChanges.list = newChanges.list.map((singleJob) => {
        if (message.chatId === singleJob.primaryChatId) {
          return incrementJobNeedsAction({ ...singleJob }, job.needsAction?.hasQuestionMessageCount, 'hasQuestionMessageCount');
        }
        return singleJob;
      });

      return { ...state, ...newChanges };
    }
    case PUT_MESSAGE_NEEDS_ACTION_RESPONSE: {
      const listOfJobs = _.get(state, 'list', []);
      const updatedJobId = _.get(action, 'payload.body.message.job.id', {});
      const jobHasQuestionCount = _.get(action, 'payload.body.message.job.needsAction.hasQuestionMessageCount', 0);
      const { activeChat } = state;
      if (state.activeJob.primaryChatId !== action.payload.body.message.chatId) return state;

      // Map over the list of jobs and update the job that matches the ID of the updated job
      const updatedListOfJobs = listOfJobs.map((job) => (job._id === updatedJobId ? { ...job, needsAction: { hasQuestionMessageCount: jobHasQuestionCount } } : job));

      let updatedMessages = activeChat.messages.map((item) => {
        if (item.id === action.payload.body.message._id) {
          const {
            needsAction = item.needsAction,
          } = action.payload.body.message;
          return {
            ...item,
            needsAction,
          };
        }
        return item;
      });
      if (!updatedMessages.find((message) => message.id === action.payload.body.message._id)) {
        updatedMessages = [...updatedMessages, { ...action.payload.body.message, id: action.payload.body.message._id }];
      }
      return { ...state, list: updatedListOfJobs, activeChat: { ...activeChat, messages: updatedMessages } };
    }

    case INLINE_MESSAGE_UPDATE: {
      const changes = {};
      const { activeChat } = state;

      const newMessage = action.payload?.message;
      if (state.activeJob.primaryChatId !== newMessage.chatId) {
        return { ...state };
      }

      let messageExists = false;
      let updatedMessages = activeChat.messages.map((item) => {
        // eslint-disable-next-line eqeqeq
        if (item.id == newMessage._id) {
          messageExists = true;
          const sentimentData = _.has(newMessage, 'sentimentData') ? newMessage.sentimentData : _.get(item, 'sentimentData', {});
          const classifications = _.has(newMessage, 'classifications') ? newMessage.classifications : _.get(item, 'classifications', {});
          const needsAttention = _.has(newMessage, 'needsAttention') ? newMessage.needsAttention : _.get(item, 'needsAttention', false);
          const needsAction = _.has(newMessage, 'needsAction') ? _.get(newMessage, 'needsAction') : _.get(item, 'needsAction', '');
          return {
            ...item,
            sentimentData,
            classifications,
            needsAttention,
            needsAction,
          };
        }
        return item;
      });

      if (!messageExists) {
        const message = {
          ...newMessage,
          id: newMessage._id,
        };
        updatedMessages = [...updatedMessages, message];
      }

      _.set(changes, 'activeChat', {
        ...activeChat,
        messages: updatedMessages,
      });

      return { ...state, ...changes };
    }

    case PUT_JOB_NEEDS_ACTION_RESPONSE: {
      const currentActiveJobId = state?.activeJob?.id;
      const updatedJob = action?.payload?.body;
      const listOfJobs = state?.list;
      // Map over the list of jobs and update the job that matches the ID of the updated job
      const updatedListOfJobs = listOfJobs.map((job) => (job._id === updatedJob._id ? { ...job, needsAction: updatedJob.needsAction.hasQuestionMessageCount } : job));

      let messagesWithNeedsAction = [];

      if (updatedJob._id === currentActiveJobId) {
        const allMessages = state?.activeChat?.messages;

        messagesWithNeedsAction = allMessages.map((message) => (message.needsAction.questionResult === 'hasQuestion' ? { ...message, needsAction: { questionResult: 'dismissed' } } : message));
      }

      // Return the updated state, including the updated list of jobs and the updated messages if applicable
      return {
        ...state,
        list: updatedListOfJobs,
        ...(messagesWithNeedsAction.length ? { activeChat: { ...state.activeChat, messages: messagesWithNeedsAction } } : {}),
      };
    }

    case ASSIGN_USER: {
      let activeJob;
      const { _id, operatorId } = action.payload;
      const changes = {};

      if (_.get(state, 'activeJob.id') === _id) {
        activeJob = state.activeJob;
        activeJob.operatorIds = operatorId && operatorId !== 'unassigned' ? [operatorId] : [];
        changes.activeJob = activeJob;
      }
      return { ...state, ...changes };
    }
    case LOAD_JOBS: {
      let changes;
      let { list } = _.get(action.payload, 'items', []);
      const { operatorId, timestamp } = _.get(action.payload, 'items', []);

      if (list && timestamp > state.lastJobRequest) {
        list = list.sort((firstEle, secondEle) => {
          const d1 = new Date(firstEle.createdAt);
          const d2 = new Date(secondEle.createdAt);
          if (d1 > d2) return -1;
          if (d2 > d1) return 1;
          return 0;
        });
        changes = {
          list,
          ...buildIdList(list),
        };
        // eslint-disable-next-line no-undef
        if (_.get(state, 'activeJob.id') === _id) {
          // eslint-disable-next-line no-undef
          activeJob = state.activeJob;
          // eslint-disable-next-line no-undef
          activeJob.operatorIds = operatorId && operatorId !== 'unassigned' ? [operatorId] : [];
          // eslint-disable-next-line no-undef
          changes.activeJob = activeJob;
        }

        return { ...state, ...changes };
      }
      break;
    }
    case 'REQUEST_LOAD_NOTES_INIT': {
      const activeNotes = {
        ...state.activeNotes,
        isLoading: true,
        isLoaded: false,
      };

      return { ...state, activeNotes };
    }
    case 'REQUEST_LOAD_NOTES_SUCCESS': {
      const activeNotes = {
        ...state.activeNotes,
        isLoading: false,
        isLoaded: true,
      };

      return { ...state, activeNotes };
    }
    case 'LOAD_NOTES': {
      const activeNotes = { ...state.activeNotes };
      activeNotes.notes = action.payload;
      return { ...state, activeNotes };
    }
    case 'DELETE_NOTE': {
      const id = _.get(action.payload, '_id');
      const activeNotes = { ...state.activeNotes };
      activeNotes.notes = [...activeNotes.notes].map((n) => (n._id === id ? action.payload : n));
      return { ...state, activeNotes };
    }
    case 'CREATE_NOTE': {
      if (action?.payload?.caseId === state?.activeJob?.id) {
        const activeNotes = { ...state.activeNotes };
        const alreadyExists = !!activeNotes.notes.find((n) => n._id === _.get(action.payload, '_id'));
        if (!alreadyExists) activeNotes.notes = [...activeNotes.notes, action.payload];
        return { ...state, activeNotes };
      }
      return state;
    }
    case DELETE_REPLY:
    case ADD_REPLY: {
      const id = action.payload?._id;
      const replies = action.payload?.replies;
      return {
        ...state,
        activeNotes: {
          ...state.activeNotes,
          notes: state.activeNotes.notes.map((note) => (note?._id === id ? { ...note, replies } : note)),
        },
      };
    }
    case 'LOAD_VIEWED_CHATS': {
      const viewedChats = new Set(action.payload);
      return { ...state, viewedChats };
    }
    case REQUEST_REOPEN_JOB_ERROR: {
      return { ...state, reopenChatsError: action.payload };
    }
    case CLEAR_REOPEN_JOB_ERROR: {
      return { ...state, reopenChatsError: '' };
    }
    case SET_CASE_SELECTOR: {
      return { ...state, caseSelectorId: action.payload };
    }
    case 'CLEAR_ACTIVE_JOB': {
      const caseId = _.get(action, 'payload');
      let list = [...state.list];
      if (caseId) {
        list = list.filter((c) => c._id !== caseId);
      }
      return {
        ...state,
        list,
        activeJob: {},
        activeChat: {},
        previousActiveChat: _.get(state, 'activeChat.id'),
      };
    }
    case ACTIVE_JOB: {
      const activeJob = action.payload || {};
      return { ...state, activeJob };
    }
    case SET_ACTIVE_CHAT: {
      const activeJob = state.activeJob || {};
      let chat = _.get(action, 'payload.chat');
      const { operatorId, permissions } = action.payload;
      const isJobPrivate = isJobPrivacy(activeJob, 'private');

      if (isJobPrivate) {
        const visibleToLoggedInOp = isVisibleToOperator(activeJob, operatorId, permissions);
        if (!visibleToLoggedInOp) {
          chat = { noAccess: true };
        }
      }
      saveLastActiveChat(action.payload.jobId);
      return {
        ...state,
        activeChat: {
          activeJob,
          ...chat,
          isLastMessageInLog: true,
          firstKeyStroke: true,
        },
      };
    }
    case SET_ACTIVE_CHAT_FIRST_KEY_STROKE: {
      const newState = _.cloneDeep(state);
      _.set(newState, 'activeChat.firstKeyStroke', _.get(action, 'payload.setting', false));
      return newState;
    }
    case APPEND_CHAT_MESSAGES: {
      const messageIds = {};
      _.forEach(state.activeChat.messages, (message) => {
        messageIds[message.id] = true;
      });
      const newMessages = _.filter(action.payload, (message) => !messageIds[message.id]);
      const messages = _.orderBy([...newMessages, ...state.activeChat.messages], ['createdAt']);
      const isLastMessageInLog = !!_.find(messages, { theLastMessage: true });
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages,
          isLastMessageInLog,
        },
      };
    }
    case UPDATE_CHAT_MESSAGES: {
      const { messages } = action.payload;
      const orderedMessages = _.orderBy(messages, ['createdAt']);
      const isLastMessageInLog = !!_.find(orderedMessages, {
        theLastMessage: true,
      });
      const chatLocation = 'activeChat';
      const changes = _.set({}, chatLocation, {
        ..._.get(state, chatLocation, {}),
        messages,
        isLastMessageInLog,
      });

      return {
        ...state,
        ...changes,
      };
    }
    case 'LOAD_PINNED_CHATS': {
      const pinnedJobs = action.payload;
      return { ...state, pinnedJobs };
    }
    case 'UPDATE_PINNED_CHATS': {
      /* TODO - clean up discrepancy between chatId and jobId, this says "chatId" because that's what the api calls it, but it's called jobId (and is jobId) everywhere else */
      const { isPinned, chatId } = action.payload;
      const pinnedJobs = isPinned ? [...state.pinnedJobs, chatId] : _.filter(state.pinnedJobs, (pinnedId) => pinnedId !== chatId);
      return { ...state, pinnedJobs };
    }
    case 'MARK_CHAT_READ': {
      /* TODO - clean up discrepancy between chatId and jobId, this says "chatId" because that's what the api calls it, but it's called jobId (and is jobId) everywhere else */
      const { chatId } = action.payload;
      const changes = { viewedChats: new Set(state.viewedChats) };
      changes.viewedChats.add(chatId);
      return { ...state, ...changes };
    }
    case 'MARK_CHAT_UNREAD': {
      const { chatId } = action.payload;
      const changes = { viewedChats: new Set(state.viewedChats) };
      changes.viewedChats.delete(chatId);
      return { ...state, ...changes };
    }
    case 'REQUEST_MARK_CHAT_UNREAD_ERROR': {
      return { ...state, markChatUnreadError: action.payload };
    }
    case CHAT_SEND_FILE_STARTED: {
      return { ...state, uploadFileIsInProgress: true, uploadFileError: '' };
    }
    case CHAT_SEND_FILE_CLEAR:
    case CHAT_SEND_FILE_COMPLETED: {
      const url = action.payload;
      return {
        ...state,
        uploadFileUrl: url,
        uploadFileIsInProgress: false,
        uploadFileError: '',
      };
    }
    case CHAT_SEND_FILE_ERROR: {
      return {
        ...state,
        uploadFileIsInProgress: false,
        uploadFileError: action.payload,
      };
    }
    case UPDATED_USER: {
      const user = action.payload;
      const list = state.list.map((job) => {
        if (job.customer && job.customer.id === user.id) {
          return { ...job, customer: user };
        }
        return job;
      });
      let activeChat = _.get(state, 'activeChat');
      const activeJob = _.get(state, 'activeJob');
      if (_.get(activeChat, 'activeJob.customer')) {
        const messages = state.activeChat.messages.map((message) => {
          if (message.author && message.author.id && message.author.id === user.id) {
            return { ...message, author: user };
          }
          return message;
        });
        if (activeChat.activeJob.customer.id === user.id) {
          activeChat.activeJob.customer = user;
        }
        activeChat = {
          ...activeChat,
          messages,
        };
      }
      if (_.get(activeJob, 'customer')) {
        if (activeJob.customer.id === user.id) {
          activeJob.customer = user;
        }
      }

      return {
        ...state,
        list,
        activeChat,
        activeJob,
        ...buildIdList(list),
      };
    }
    case DEAUTH_USER: {
      return { ...initialState };
    }
    case WELCOME_EMAIL_REQUEST_CLEAN: {
      return { ...state, welcomeEmailRequest: false, welcomeEmailError: '' };
    }
    case WELCOME_EMAIL_IS_SENT: {
      return { ...state, welcomeEmailRequest: true };
    }
    case RESEND_WELCOME_EMAIL_FOR_JOB_ERROR: {
      return { ...state, welcomeEmailError: action.payload };
    }
    case USER_IS_TYPING: {
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          authorTyping: _.get(action, 'payload.authorName'),
          typingTickCount: _.get(state, 'activeChat.typingTickCount', 0) + 1,
        },
      };
    }
    case USER_IS_TYPING_TIMEOUT: {
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          authorTyping: null,
          typingTickCount: null,
        },
      };
    }
    case PRESENT_MESSAGE: {
      // todo: make sure new message was not already added to chat
      const { message } = action.payload;
      const chatLocation = 'activeChat';
      const { messages } = _.get(state, chatLocation, []);
      const changes = _.set({}, chatLocation, {
        ..._.get(state, chatLocation, {}),
        messages: [...messages, message],
      });

      return {
        ...state,
        ...changes,
      };
    }
    case SHOW_SEND_FILE_MODAL: {
      return {
        ...state,
        showSendFileModal: _.get(action, 'payload.show', false),
        fileToSend: _.get(action, 'payload.file', null),
      };
    }
    case REQUEST_PUT_JOB_SUCCESS: {
      return {
        ...state,
        requestCompleted: true,
        editObject: {},
        httpRequestError: false,
      };
    }
    case FETCH_CASE_TYPES: {
      return { ...state, caseTypes: _.get(action, 'payload.body') };
    }
    case ENDUSER_JOINED_CHAT: {
      const { chat } = action.payload;
      const chats = { ...state?.chats };
      // need to map to expected user format
      const updatedParticipants = chat?.participants?.map((participant) => new User(participant));
      if (chats?.[chat._id]?.participants) {
        chats[chat._id].participants = updatedParticipants;
      }
      return {
        ...state,
        chats,
      };
    }
    case FETCH_FILTERED_OPERATOR_IDS: {
      return { ...state, filteredOperatorIds: _.get(action, 'payload.body.operatorIds', []) };
    }
    case UPDATE_JOB_SEARCH: {
      const update = {};
      if (typeof action?.payload?.search !== 'undefined') {
        update.search = action?.payload?.search;
      }
      if (typeof action?.payload?.searchDisplayValue !== 'undefined') {
        update.searchDisplayValue = action?.payload?.searchDisplayValue;
      }
      return { ...state, ...update };
    }
    case SET_EXPAND_SEARCH_BAR: {
      return { ...state, expandInboxSearchBar: action?.payload?.expandInboxSearchBar };
    }
    case UPDATE_SEARCH_RESULTS_COUNT: {
      return { ...state, searchResultsCount: action?.payload?.searchResultsCount };
    }
    case UPDATE_TOTAL_CHAT_COUNT: {
      return { ...state, totalChatCount: action?.payload?.totalChatCount };
    }
    case REDACT_IMAGE: {
      const { messageId, redactionData } = action.payload;
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: state.activeChat.messages.map((m) => {
            if (m?._id === messageId || m?.id === messageId) {
              return handleImageRedaction(m, redactionData);
            }
            return m;
          }),
          media: state.activeChat.media.map((m) => {
            if (m?._id === messageId || m?.id === messageId) {
              return handleImageRedaction(m, redactionData);
            }
            return m;
          }),
        },
      };
    }
    case VIEW_REDACTED_IMAGE: {
      const { messageId, authUser } = action.payload;
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: state.activeChat.messages.map((m) => {
            if (m?._id === messageId || m?.id === messageId) {
              return {
                ...m,
                redactionViewedEvents: updateRedactionViewedEvents(m?.redactionViewedEvents, authUser),
              };
            }
            return m;
          }),
          media: state.activeChat.media.map((m) => {
            if (m?._id === messageId || m?.id === messageId) {
              return {
                ...m,
                redactionViewedEvents: updateRedactionViewedEvents(m?.redactionViewedEvents, authUser),
              };
            }
            return m;
          }),
        },
      };
    }
    case UPDATE_VIRUS_SCAN_STATUS: {
      const { fileUrl, virusScanStatus } = action.payload;
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          media: state.activeChat.media.map((m) => {
            if (m?.fileUrl === fileUrl) {
              return {
                ...m,
                virusScanStatus,
              };
            }
            return m;
          }),
        },
      };
    }
    default:
      return state;
  }
};

export default commonReducer.reducer('CASE', jobs);
