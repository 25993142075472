import { useState } from 'react'
import _ from 'lodash'
import {
  UP_ARROW_CODE, DOWN_ARROW_CODE, TAB_CODE, RIGHT_ARROW_CODE, LEFT_ARROW_CODE,
} from '../../../constants/keycodes'

const upDownMovements = [
  { code: UP_ARROW_CODE, delta: -1 },
  { code: [DOWN_ARROW_CODE, TAB_CODE], delta: 1 },
]

const TwoDMovements = (p) => [
  { code: UP_ARROW_CODE, delta: -1 * parseInt(p, 10) },
  { code: LEFT_ARROW_CODE, delta: -1 },
  { code: [DOWN_ARROW_CODE, TAB_CODE], delta: 1 * parseInt(p, 10) },
  { code: RIGHT_ARROW_CODE, delta: 1 },
]

/**
 * useKeyNav
 * hook to allow navigation by key input
 * @param {object} options options
 * @param {number} options.length max value for cyclin
 * @param {number} options.perRow values per row for 2
 * @param {number} options.dimensions dimensions for navigation, default 1
 * @param {boolean} disabled
* */
const useKeyNav = (args) => {
  const { options, disabled } = args || {}
  const { perRow, dimensions } = options || {}
  const [currentIndex, setCurrentIndex] = useState(0)

  const processKey = ({ keyCode, preventDefault }) => {
    let movementDelta = 0
    const movementSet = dimensions === 2 ? TwoDMovements(perRow) : upDownMovements
    let isMovement = false
    movementSet.forEach((m) => {
      const codes = _.get(m, 'code', [])
      const codeArray = _.isArray(codes) ? codes : [codes]
      if (_.includes(codeArray, keyCode)) {
        preventDefault()
        movementDelta += _.get(m, 'delta', 0)
        isMovement = true
      }
    })

    if (isMovement) {
      let adjustedIndex = currentIndex + movementDelta

      const min = 0
      const max = options.length

      if (adjustedIndex < min) adjustedIndex = max
      if (adjustedIndex > max) adjustedIndex = min

      setCurrentIndex(adjustedIndex)
    }
  }

  const handleKeyNav = (e) => {
    if (!disabled) {
      const { keyCode, shiftKey } = e
      processKey({ keyCode, isShift: shiftKey, preventDefault: () => e.preventDefault() })
    }
  }

  const resetIndex = () => {
    setCurrentIndex(0)
  }

  return { handleKeyNav, currentIndex, resetIndex }
}

export default useKeyNav
