import React from 'react';
import PropTypes from 'prop-types'
import styles from './MarleyAssistantMessage.module.less'

const MarleyAssistantMessage = ({ children }) => (
  <div>
    <div
      className={styles['marley-assistant-message']}
      data-testid="marley-assistant-message"
      >
      <div className={styles['message-prompt']}>
        <div className={styles['message-info']}>Only Visible to you</div>
        <div className={styles['assistant-name']}>System Message</div>
        {children}
      </div>
    </div>
  </div>
);

MarleyAssistantMessage.propTypes = {
  children: PropTypes.instanceOf(Object),
};

MarleyAssistantMessage.defaultProps = {
  children: null,
};

export default MarleyAssistantMessage;
