import {
  CheckmarkIcon,
  PaperAirplaneIcon,
  LightbulbIcon,
  CheckCircleIcon,
  StopIcon,
  Button,
} from '@himarley/unity';
import React from 'react';
import { useSelector } from 'react-redux';

import { StateType } from '@app/types/reducer-state';
import { RulesEngineRule } from '@app/types/ruleset';

import { RuleData, ToggleTemplateModalArgs } from './integration-settings-types';
import styles from './IntegrationSettingsContainer.module.less';
import { ruleSettingsCopy, TRUNCATE_TEXT_LENGTH } from '../../../constants/integration-constants';
import { generateReadableBody } from '../../Form/MessageTemplate/helpers';

interface IntegrationRuleProps {
  ruleId?: string;
  isEnabled?: boolean;
  messageTemplateText: string;
  messageTemplateDisplayName: string;
  ruleName: string;
  templateId: string;
  toggleTemplateModal: (args: ToggleTemplateModalArgs) => void;
  toggleRuleModal: (args: RuleData) => void;
  messageTemplateTokens?: { token: string }[];
  rule: RulesEngineRule;
}
const IntegrationRule = ({
  ruleId = '',
  ruleName,
  templateId,
  isEnabled,
  messageTemplateText,
  messageTemplateTokens,
  messageTemplateDisplayName,
  toggleTemplateModal,
  toggleRuleModal,
  rule,
}: IntegrationRuleProps) => {
  const { conditions, event } = rule;
  const tokens = useSelector(({ templates }: StateType) => templates.keys);
  const { text: textData } = generateReadableBody(
    tokens,
    { body: messageTemplateText, tokens: messageTemplateTokens, truncateAt: TRUNCATE_TEXT_LENGTH },
  );

  function formatText(text: string, templateTokens?: { token: string }[]) {
    const formattedText = [];
    let offset = 0;
    templateTokens?.forEach((token) => {
      const tokenIndex = text.indexOf(`$${token.token}`, offset);
      if (tokenIndex > -1) {
        formattedText.push((
          <>
            {text.substring(offset, tokenIndex)}
            <b>{`$${token.token}`}</b>
          </>
        ));
        offset = tokenIndex + token.token.length + 1;
      }
    });
    if (offset < text.length) {
      formattedText.push((
        <>
          {text.substring(offset)}
        </>
      ));
    }
    return formattedText;
  }

  return (
    <div
      data-testid={`ruleset-rule-container-${ruleId}`}
      className={`${styles.integrationRuleContainer} ${(isEnabled) ? styles.padding16 : ''}`}
    >
      <div className={(!isEnabled) ? styles.flexRowDisabled : styles.flexRowEnabled}>
        <h1 data-testid="integration-rule-name" className={`${styles.ruleName}  ${(!isEnabled) ? styles.disabledColor : ''}`}>{ruleName}</h1>
        <div className={styles.ruleStatus}>
          {(isEnabled) ? (
            <div data-testid="enabled-rule-status" className={`${styles.enabledStatus} ${styles.enabledColor}`}>
              <CheckCircleIcon color="#51d6b8" className={styles.enabledIcon} />
              Enabled
            </div>
          ) : (
            <div data-testid="disabled-rule-status" className={`${styles.enabledStatus} ${styles.disabledColor}`}>
              <StopIcon color="#5E7388" className={styles.enabledIcon} />
              Disabled
            </div>
          )}
          <Button
            type="outline"
            testId={`edit-message-rule-button-${ruleId}`}
            className={styles.editRuleBtn}
            onClick={
              () => toggleRuleModal({
                ruleId,
                ruleName,
                rule: {
                  conditions: rule.conditions,
                  sendType: rule.event.type,
                },
                enabled: isEnabled,
                messageTemplate: {
                  id: templateId,
                  body: messageTemplateText,
                  displayName: messageTemplateDisplayName,
                  tokens: messageTemplateTokens,
                },
              })
            }
          >
            Edit Rule
          </Button>
        </div>
      </div>
      {isEnabled && (
        <div data-testid="enabled-rule-content">
          <div className={styles.flexRowIcons}>
            {conditions.all.map((condition) => {
              // condition values are either a string or an array of strings
              switch (typeof condition.value) {
                case 'string':
                  return null; // no need to return event name since it's already the title
                case 'boolean':
                  return condition.value ? (
                    <div key={condition.fact} className={styles.condition}>
                      <CheckmarkIcon color="#5E7388" className={styles.ruleIcon} />
                      {ruleSettingsCopy[condition.fact]}
                    </div>
                  ) : null;
                default:
                  return condition.value?.map((conditionValue) => (
                    <div key={conditionValue} className={styles.condition}>
                      <CheckmarkIcon color="#5E7388" className={styles.ruleIcon} />
                      {ruleSettingsCopy[conditionValue]}
                    </div>
                  ));
              }
            })}
            <div className={styles.condition} style={{ width: '50%' }}>
              {ruleSettingsCopy[event.type]?.includes('Send') ? <PaperAirplaneIcon color="#5E7388" className={styles.ruleIcon} /> : <LightbulbIcon color="#5E7388" className={styles.ruleIcon} />}
              {ruleSettingsCopy[event.type]}
            </div>
          </div>
          <div className={`${styles.templatePreviewMode} ${styles.flexRowEnabled}`}>
            <div className={styles.templateText} data-testid="enabled-rule-text">
              <p>{formatText(textData, messageTemplateTokens)}</p>
            </div>
            <Button
              type="outline"
              testId={`edit-message-template-button-${ruleId}`}
              className={styles.editTemplateBtn}
              onClick={
                () => toggleTemplateModal({
                  templateId,
                  ruleId,
                  messageTemplateTitle: messageTemplateDisplayName,
                  messageTemplateText,
                  messageTemplateTokens,
                })
              }
            >
              Edit Template
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IntegrationRule;
