import React from 'react';
import PropTypes from 'prop-types';
import MessageTemplate from './MessageTemplate';
import styles from './MessageTemplateModal.module.less';
import { messageTemplateListPropTypes } from './template-helpers';

const sortHelper = () => (a, b) => {
  const aItem = a?.location;
  const bItem = b?.location;
  return aItem < bItem ? -1 : 1;
};

const MessageTemplateList = ({
  label,
  messageTemplates,
  onPinButtonClick,
  onTemplateSelect,
  isPinned,
}) => {
  const { list, count } = messageTemplates;
  if (count === 0) {
    return null;
  }
  const listTitle = `${label.toUpperCase()} TEMPLATES (${count})`;
  return (
    <>
      <div
        className={styles.templateSectionTitle}
        data-testid={`${label}-templates-section-title`}
      >
        {listTitle}
      </div>
      {list.sort(sortHelper('az', 'title'))?.map((messageTemplate) => (
        <MessageTemplate
          key={messageTemplate.id}
          onTemplateSelect={onTemplateSelect}
          onPinButtonClick={onPinButtonClick}
          isPinned={isPinned}
          messageTemplateData={messageTemplate}
        />
      ))}
    </>
  );
};

MessageTemplateList.propTypes = {
  label: PropTypes.string.isRequired,
  messageTemplates: messageTemplateListPropTypes.isRequired,
  isPinned: PropTypes.bool,
  onTemplateSelect: PropTypes.func.isRequired,
  onPinButtonClick: PropTypes.func,
};

MessageTemplateList.defaultProps = {
  isPinned: false,
  onPinButtonClick: null,
};

export default MessageTemplateList;
