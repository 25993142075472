import React from "react";
import "./NoMessages.less";
import EnvelopeLarge from "../../../../../../images/icons/envelope-large.svg";

const NoMessages = (error) => {
  return (
    <div className="auto-reply-no-messages">
      {error && error.error === "get error" ? (
        <p className="auto-reply-no-messages-error">
          There was a problem with our servers, please try again.
        </p>
      ) : (
        <p>You don't have any messages at this time.</p>
      )}
      <EnvelopeLarge />
    </div>
  );
};

export default NoMessages;
