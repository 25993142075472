import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Col, FormGroup, FormControl, ControlLabel, HelpBlock, InputGroup } from 'react-bootstrap'
import { handleFormInputChange } from '../../actions/common'

import Required from '../icons/Required'

import './FormInput.less'

class FormInput extends React.Component {
  constructor(props) {
    super(props)
    this.textInput = ''
    this.state = {
      value: this.props.value || '',
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.value !== newProps.value) {
      let help = null
      if (newProps.help) {
        help = (typeof newProps.help === "function") ? newProps.help(newProps.value) : newProps.help
      }

      this.setState({
        value: newProps.value,
        help,
      })
    }
  }

  render() {
    const {
      height,
      componentClass,
      className,
      required,
      label,
      subLabel,
      showHelp,
      labelBefore,
      addOn,
      addOnAfter, // ...other
    } = this.props
    const props = this.props
    const id = props.id || props.label.toLowerCase()
    const { onBlur, location, error, errorMessage, tableId, disableOnEdit, formatFunction } = props
    const format = formatFunction || (e => e)

    const control = this.props.children ?
      React.Children.map(this.props.children,
        child => React.cloneElement(child, {
          name: id,
          inputRef: (e) => {
            this.textInput = e
            if (this.props.setRef) this.props.setRef(e)
          },
          ref: (e) => {
            this.textInput = e
            if (this.props.setRef) this.props.setRef(e)
          },
          autoComplete: "new-phone-who-dis", //disabling chromes annoying autocomplete
          disabled: this.props.isDisabled,
          className: `form-input-input form-control ${tableId}-${id}-input ${this.props.readonly && 'form-input-readonly'}`,
          onBlur: (e) => {
            // e.preventDefault()
            if (onBlur) onBlur(e)
          },
          onKeyDown: (e) => {
            if (this.props.onKeyDown) this.props.onKeyDown(e)
          },
          onChange: (e) => {
            if (e.persist) e.persist()
            props.handleFormInputChange({
              inputId: location || id,
              tableId,
              value: _.get(e, 'target.value'),
            })
            this.setState({
              value: format(_.get(e, 'target.value')),
            }, () => { if (this.props.onChange) this.props.onChange(e) })
          },
          value: format(this.state.value) || '',
        }))
      : (
        <FormControl
          autoComplete="new-input" //setting it to invalid value to turn autocomplete off 
          name={id}
          inputRef={(e) => {
            this.textInput = e
            if (this.props.setInputRef) this.props.setInputRef(e)
          }}
          ref={(e) => {
            if (this.props.setRef) this.props.setRef(e)
          }}
          className={`form-input-input ${tableId}-${id}-input ${this.props.readonly && 'form-input-readonly'}`}
          componentClass={componentClass || 'input'}
          style={height && { height }}
          disabled={(disableOnEdit && this.props.isEdit) || this.props.readonly || this.props.isDisabled || this.props.selectExistingUser}
          onBlur={(e) => {
            // e.preventDefault()
            if (onBlur) onBlur(e)
          }}
          onKeyDown={(e) => {
            if (this.props.onKeyDown) this.props.onKeyDown(e)
          }}
          onChange={(e) => {
            e.persist()
            props.handleFormInputChange({
              inputId: location || id,
              tableId,
              value: _.get(e, 'target.value'),
            })
            this.setState({
              value: format(_.get(e, 'target.value')),
            }, () => { if (this.props.onChange) this.props.onChange(e) })
          }}
          value={format(this.state.value) || ''}
        />
    )

    let help = null
    if (this.props.help) {
      help = (typeof this.props.help === "function") ? this.props.help(this.state.value) : this.props.help
    }

    return (
      <div className={`enter-${tableId}-row form-input`}>
        <Col className={className} >
          <FormGroup validationState={error ? 'error' : null}>
            {labelBefore &&
              <span>
                <ControlLabel onClick={() => {  /*this.textInput.focus() */ }}>{label}
                  {required && <Required />}
                </ControlLabel>
                {subLabel && (<div className="control-sub-label">{subLabel}</div>)}
              </span>
            }
            <Col className="form-input-input-group">
              {addOn || addOnAfter ? (
                <InputGroup className="form-field-value-wrap">
                  {addOn && <div className="form-field-prefix">{addOn}</div>}
                  {control}
                  {addOnAfter &&
                    <InputGroup.Addon className="input-field-addon">
                      {addOnAfter}</InputGroup.Addon>}
                </InputGroup>
              ) : <span>{control}</span>}
            </Col>
            <FormControl.Feedback />
            {showHelp && <HelpBlock>
              <span className={error && 'helpblock-error'}>{(error && errorMessage) || help || ' '}</span>
            </HelpBlock>}
            {!labelBefore &&
              <Col componentClass={ControlLabel} className="input-profile-field-label">
                {label}</Col>
            }
          </FormGroup>
        </Col>
      </div>
    )
  }
}

FormInput.propTypes = {
  label: PropTypes.string,
  labelBefore: PropTypes.bool,
  addOn: PropTypes.string,
  addOnAfter: PropTypes.string,
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showHelp: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
}
FormInput.defaultProps = {
  className: '',
  label: '',
  labelBefore: true,
  required: false,
  addOn: undefined,
  addOnAfter: undefined,
  help: '',
  showHelp: true,
  error: null,
}

const mapDispatchToProps = {
  handleFormInputChange,
}

const pluralize = item =>
  `${item.slice(0, item.length - 1)}${item.slice(-1) === 'y' ? 'ies' : `${item.slice(-1)}s`}`

const mapStateToProps = (state, props) => {
  const stateLocation = props.type ? props.type.stateLocation() : pluralize(props.tableId)
  return {
    isEdit: _.get(state, `${stateLocation}.form.isEdit`, false),
    readonly: _.get(state, `${stateLocation}.form.readonly`, false),
    value: _.get(state, `${stateLocation}.form.editObject.${
      props.location ||
        props.id || _.get(props, 'label', '').toLowerCase()}`, ''),
    error: _.get(state, `${stateLocation}.errors`, []).find(e => e === props.id),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormInput)
