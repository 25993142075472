export const typeToUiText = (type: string) => {
  const uiText: { [key: string]: string } = {
    personal: 'Personal Line',
    commercial: 'Commercial Line',
    other: 'Other/Misc. Line',
  };
  return uiText[type] || '';
};

export const LOB_TAB_NAMES: { [key: string]: string } = {
  optInMessages: 'optInMessages',
  templates: 'templates',
  welcomeMessages: 'welcomeMessages',
};

export const getSelectedLobFromPath = (path: string, linesOfBusiness: [{ _id: string }]) => {
  const urlPaths = path.split('/');
  const lastParam = urlPaths[urlPaths.length - 1];
  const secondLastParam = urlPaths[urlPaths.length - 2];
  let lobId: string | null = null;
  if (LOB_TAB_NAMES[lastParam]) {
    lobId = secondLastParam;
  } else {
    lobId = lastParam;
  }
  const lob = linesOfBusiness?.find(
    (thisLob) => thisLob._id === lobId,
  );
  return lob;
};
