import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { VIRUS_SCAN_STATUS } from '@app/components/Media/constants';
import { FEATURE_FLAG_VIRUS_SCANNING } from '@app/constants/permissions';
import usePermissionVerify from '@app/components/elements/hooks/usePermissionVerify';
import { useDispatch, useSelector } from 'react-redux';
import ForwardMediaModal from '../ForwardMediaModal/ForwardMediaModal';
import { forwardMedia } from '../../../../../actions/chat';
import { isMultiPartyEnabled } from '../../../../../helpers/featureFlags';
import { ROLE_ENDUSER } from '../../../../../constants/general';
import MediaToolbar from '../../../../Media/MediaToolbar';
import MediaContainer from '../../../../Media/MediaContainer';
import { openMediaModal } from '../../../../../actions/ui';
import './MediaMessageContainer.less';

const MediaMessageContainer = ({ fileUrl }) => {
  const [file, setFile] = useState({ loadingState: 'loading' });

  const onLoad = (loadedFile) => {
    if (file.loadingState === 'loading') {
      setFile(loadedFile);
    }
  };
  const [showForwardMediaModal, setShowForwardMediaModal] = useState(false);
  const [forwardMediaStatus, setForwardMediaStatus] = useState('');
  const mediaItem = useSelector((state) => state?.media[fileUrl]);
  const participants = useSelector(
    (state) => state?.participants?.chatParticipants?.activeParticipants,
  );
  const userPermissions = useSelector((state) => state?.auth?.permissions);
  const hasMultiEndUsers = participants?.filter((user) => user.role === ROLE_ENDUSER).length > 1;

  const activeChatId = useSelector((state) => state?.jobs?.activeChat?.id);
  const chatMedia = useSelector(
    (state) => state?.jobs?.activeChat?.media?.filter(
      (media) => !media?.redactionViewedEvents?.length,
    ) || [],
  );
  const dispatch = useDispatch();
  const useExpand = () => dispatch(openMediaModal(fileUrl, chatMedia));

  const handleCloseModal = (event) => {
    event.preventDefault();
    setShowForwardMediaModal(false);
  };

  const handleForwardMediaSend = (event) => {
    event.preventDefault();
    setForwardMediaStatus('PENDING');
    dispatch(forwardMedia(activeChatId, mediaItem.messageId))
      .then(() => {
        setShowForwardMediaModal(false);
        setForwardMediaStatus('SUCCESS');
        return true;
      })
      .catch((err) => {
        console.error('Failed to forward media:', err);
        setForwardMediaStatus('FAILURE');
      });
  };
  let handleClickForward = null;
  if (hasMultiEndUsers && isMultiPartyEnabled(userPermissions)) {
    handleClickForward = () => {
      setShowForwardMediaModal(true);
    };
  }
  const [isActionMenuShown, setIsActionMenuShown] = useState(false);

  const { hasAccess: hasVirusScanningEnabled } = usePermissionVerify({
    neededPermissions: [FEATURE_FLAG_VIRUS_SCANNING],
  });

  return (
    <div
      className="media-message-container"
      onMouseEnter={() => setIsActionMenuShown(true)}
      onMouseLeave={() => setIsActionMenuShown(false)}
      data-testid="media-message-container"
    >
      <div className="media-message-container-thumbnail">
        <MediaContainer
          mediaItem={mediaItem}
          onExpand={useExpand}
          onLoad={onLoad}
          configuration={{
            mode: 'fixed',
            fixedHeight: 250,
            fixedWidth: 250,
          }}
        />
      </div>
      {file.loadingState !== 'loading' && (
        <>
          <div className="media-message-container-toolbar">
            <MediaToolbar
              localFileUrl={file.localFileUrl}
              onForward={handleClickForward}
              mediaItem={mediaItem}
              onExpand={useExpand}
              isActionMenuShown={
                hasVirusScanningEnabled
                  ? mediaItem?.virusScanStatus === VIRUS_SCAN_STATUS.CLEAN
                    && isActionMenuShown
                  : isActionMenuShown
              }
            />
          </div>
          <ForwardMediaModal
            fileUrl={file.localFileUrl}
            forwardMediaStatus={forwardMediaStatus}
            onClose={handleCloseModal}
            onSend={handleForwardMediaSend}
            show={showForwardMediaModal}
            toggleModal={setShowForwardMediaModal}
          />
        </>
      )}
    </div>
  );
};

MediaMessageContainer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

export default MediaMessageContainer;
