import { Tab, Tabs } from '@himarley/unity';
import React from 'react';

import { MASSNOTIFICATIONS_TAB } from '@app/constants/permissions';

import MassNotifications from './mass-notifications';
import LookerDashboard from '../Analytics/LookerDashboard/LookerDashboard';
import PermissionVerifier from '../PermissionVerifier/PermissionVerifier';

import './MassNotificationsRoot.less';

interface MassNotificationsRootProps {
  tab?: string;
}

const MassNotificationsRoot: React.FC<MassNotificationsRootProps> = ({ tab }) => (
  <PermissionVerifier neededPermissions={[MASSNOTIFICATIONS_TAB]} redirect>
    <Tabs defaultActiveKey={tab || 'notifications'}>
      <Tab eventKey="notifications" title="Notifications">
        <div style={{ height: '100%' }}>
          <MassNotifications />
        </div>
      </Tab>
      {process.env.FEATURE_FLAG_MASS_NOTE_ANALYTICS && (
        <Tab eventKey="dashboard" title="Dashboard">
          <LookerDashboard dashboardName="mass_notification" />
        </Tab>
      )}
    </Tabs>
  </PermissionVerifier>
);

export default MassNotificationsRoot;
