export const ConfigOptInStatus: { [key: string]: string } = {
  PENDING_OPT_IN: 'not opted in', // verified = false, blocked false, locked false
  OPTED_IN: 'already opted in', // verified = true, blocked false, locked false
};

export const KEYS = {
  INITIAL_MESSAGE: 'INITIAL_MESSAGE',
  CONFIRMATION_MESSAGE: 'CONFIRMATION_MESSAGE',
  FOLLOWUP_MESSAGE: 'FOLLOWUP_MESSAGE',
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  ANY: 'ANY',
  OPTED_IN: 'OPTED_IN',
  PENDING_OPT_IN: 'PENDING_OPT_IN',
} as const;

export const WELCOME_FLOW_RULESETS: { [key: string]: string } = {
  INITIAL_MESSAGE: 'WELCOME_FLOW_INITIAL_MESSAGE',
  CONFIRMATION_MESSAGE: 'WELCOME_FLOW_OPTIN_CONFIRMATION',
  FOLLOWUP_MESSAGE: 'WELCOME_FLOW_OPTIN_FOLLOWUP',
};

export const CLAIM = 'Claim';
export const CASE = 'General Case';

export const LEGAL_MESSAGE_TOKEN = '$legalMessage';
export const MOID_LEGAL_MESSAGE_TOKEN = '$marleyOptInDisabledLegalMessage';

export const MOID_LEGAL_MESSAGE = 'By responding you are agreeing to our terms and privacy policy (himarley.com/tp). Msg & Data rates may apply. Text STOP to opt-out, HELP for help.';

export const MODAL_ACTIONS = {
  SET_MESSAGES: 'SET_MESSAGES' as const,
  SET_PREVIEW_OPTION: 'SET_PREVIEW_OPTION' as const,
  SET_SHOW_ENDING_MESSAGE: 'SET_SHOW_ENDING_MESSAGE' as const,
  TOGGLE_OPTIONAL_FOLLOW_UP: 'TOGGLE_OPTIONAL_FOLLOW_UP' as const,
  SET_IS_SAVING: 'SET_IS_SAVING' as const,
  SET_IS_DIRTY: 'SET_IS_DIRTY' as const,
  SET_TOKEN_OVERRIDE_MAP: 'SET_TOKEN_OVERRIDE_MAP' as const,
  UPDATE_MESSAGE: 'UPDATE_MESSAGE' as const,
};
