import {
  NEW_MESSAGE,
  SET_ACTIVE_CHAT,
  UPDATE_MEDIA,
  REDACT_IMAGE,
  UPDATE_VIRUS_SCAN_STATUS,
} from '../constants/actions';

const initialState = {};

const reducer = (state = initialState, action) => {
  const { payload = {} } = action;
  switch (action.type) {
    case SET_ACTIVE_CHAT: {
      const { media = [] } = payload?.chat || {};
      const updatedState = { ...state };
      media.forEach((mediaItem) => {
        const {
          mediaInfo,
          providerId,
          fileUrl,
          _id: messageId,
          isImageRedacted,
          redactionData,
          body = '',
        } = mediaItem;
        const hasImageRedactionData = redactionData?.find((r) => r.Type === REDACT_IMAGE);
        updatedState[fileUrl] = {
          mediaInfo,
          fileUrl,
          messageId,
          providerId,
          rotation: 0,
          body,
          isImageRedacted: isImageRedacted || hasImageRedactionData,
          virusScanStatus: mediaItem?.virusScanStatus,
        };
      });
      return updatedState;
    }
    case NEW_MESSAGE: {
      const {
        message = {},
      } = payload;
      const {
        fileUrl,
        mediaInfo,
        providerId,
        id: messageId,
        // new message event uses the model, which translates
        // body -> messagebody
        messageBody = '',
      } = message;
      if (!fileUrl) {
        return state;
      }
      return ({
        ...state,
        [fileUrl]: {
          mediaInfo,
          fileUrl,
          providerId,
          messageId,
          body: messageBody,
          rotation: 0,
        },
      });
    }
    case UPDATE_MEDIA: {
      const { fileUrl } = payload;
      return ({
        ...state,
        [fileUrl]: payload,
      });
    }
    case REDACT_IMAGE: {
      const { fileUrl } = action.payload;

      const updatedState = { ...state };
      const theMediaItem = updatedState[fileUrl] || {};

      updatedState[fileUrl] = {
        ...theMediaItem,
        isImageRedacted: true,
      };

      return updatedState;
    }
    case UPDATE_VIRUS_SCAN_STATUS: {
      const { fileUrl, virusScanStatus } = action.payload;

      const updatedState = { ...state };
      const theMediaItem = updatedState[fileUrl] || {};

      updatedState[fileUrl] = {
        ...theMediaItem,
        virusScanStatus,
      };

      return updatedState;
    }
    default:
      return state;
  }
};

export default reducer;
