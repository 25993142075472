import React from 'react';

import AlertIcon from '@images/icons/alert.svg';
import CloseIcon from '@images/icons/close2.svg';

import styles from './alert.module.less';

interface Link {
  href: string;
  text: string;
}

interface AlertProps {
  title: string;
  message: React.ReactNode;
  link?: Link;
  show: boolean;
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = (
  {
    title,
    message,
    link,
    show,
    onClose,
  }: AlertProps,
) => show && (
  <div className={styles.alert}>
    <div className={styles['alert-icon']}>
      <AlertIcon />
    </div>
    <div className={styles['alert-content']}>
      <div className={styles['alert-title']}>
        {title}
      </div>
      <div className={styles['alert-message']}>
        {message}
      </div>
    </div>
    {link && (
      <div className={styles['alert-link']}>
        <a href={link.href}>{link.text}</a>
      </div>
    )}
    <div
      className={styles['alert-close']}
      aria-label="close"
      onClick={onClose}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
    >
      <CloseIcon />
    </div>
  </div>
);

export default Alert;
