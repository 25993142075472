/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import PropTypes from 'prop-types'
import Title from '../text/Title/Title'
import './Modal.less'
import IconButton from '../buttons/IconButton/IconButton'
import CloseIcon from '../../../../images/icons/close.svg'
import BackButton from '../../../../images/icons/back-btn.svg'
/**
 * Modal Innards
 * @param   {string} title top title of modal
 * @param   {string} size size of modal
 * @param   {function} toggleModal funciton to toggle show
 * @return  {Component}
 */
const ModalInner = (props) => {
  const {
    toggleModal, className, backdrop, title, children, size, backButton,
  } = props

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div className={`${className} ${size ? `marley-modal-${size}` : ''} marley-modal animation-fade-in`} backdrop={backdrop || true}>
      {title && (
      <div className="marley-modal-header">
        <Title type="header-3">{title}</Title>
        <div className="modal-buttons">
          {!!backButton && (
          <IconButton onClick={() => backButton(false)}><BackButton /></IconButton>
          )}
          <IconButton onClick={() => toggleModal(false)}><CloseIcon /></IconButton>
        </div>
      </div>
      )}
      <div className="marley-modal-body">
        {children}
      </div>
    </div>
  )
}

ModalInner.propTypes = {
  title: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  backdrop: PropTypes.bool,
  toggleModal: PropTypes.func,
  show: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  backButton: PropTypes.func,
}

ModalInner.defaultProps = {
  title: '',
  backdrop: true,
  toggleModal: () => {},
  show: false,
  backButton: null,
  className: '',
  size: 'lg',
}

export default ModalInner
