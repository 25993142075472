import _ from 'lodash';
import { useState, useEffect } from 'react'
import { Validate } from '../../../helpers/formValidation/validate';

const useForm = ({ validation, item }) => {
  const [formObject, setFormObject] = useState({ ...item })
  const [isEdit, setIsEdit] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [errorObject, setErrorObject] = useState({})

  useEffect(() => {
    checkFormSubmitStatus(formObject, validation)
  }, [errorObject, formObject, validation])

  useEffect(() => {
    if (_.get(formObject, 'id')) setIsEdit(true)
  }, [formObject])

  const handleFormChange = (changeObj) => { // }, changedMetaId) => {
    const newFormObject = {
      ...formObject,
      ...changeObj,
    }
    setFormObject(newFormObject)
  }

  // Unset Item from an object
  const omitItem = (keyToOmit, { [keyToOmit]: _, ...omittedPropObj } = {}) => omittedPropObj;

  const handleValidation = (fieldId) => {
    const validate = Validate.validate({
      formObject,
      formMeta: validation,
      fieldId,
    })

    if (!validate.isValid) {
      setCanSubmit(false)

      // Return and set error object with a format error feature
      setErrorObject({ ...errorObject, [fieldId]: validate.errorMessage })
    } else {
      setErrorObject(omitItem([fieldId], errorObject)) // remove item from the error object
    }
  }

  const checkFormSubmitStatus = (formObject, validation) => {
    if (_.isEmpty(validation)) { return setCanSubmit(true) }

    if (_.isEmpty(errorObject)) {
      const validate = Validate.validateRequired({
        formObject,
        formMeta: validation,
      })
      setCanSubmit(validate.submit)
    } else {
      setCanSubmit(false)
    }
  }

  const resetForm = (resetObj) => {
    setFormObject(resetObj)
    setErrorObject({})
    setIsEdit(false)
    setCanSubmit(false)
  }

  const forceValidateForm = () => {
    const hasValidation = !_.isEmpty(validation)
    if (hasValidation) {
      const newErrorObject = {}
      validation.forEach((field) => {
        const fieldId = _.get(field, 'id')
        const validationResult = Validate.validate({
          formObject,
          formMeta: validation,
          fieldId,
        })
        const { isValid, errorMessage } = validationResult
        if (!isValid) _.set(newErrorObject, fieldId, errorMessage)
      })
      setErrorObject(newErrorObject)
    }
  }

  return {
    handleFormChange,
    resetForm,
    handleValidation,
    checkFormSubmitStatus,
    omitItem,
    formObject,
    errorObject,
    canSubmit,
    isEdit,
    forceValidateForm,
  }
}

export default useForm
