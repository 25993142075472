import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Tooltip } from '@himarley/unity';

import { DISABLE_MARLEY_OPT_IN } from '@app/constants/permissions';

import Check from '@images/icons/check.svg';
import Info from '@images/icons/infoV2.svg';

import { useCheckPermissions } from '@app/helpers/common';

import style from './ConfigurableOptInWidget.module.less';

const InfoIcon = () => <div data-testid="info-icon" className={style.infoIcon}><Info /></div>;
const OPTED_IN = 'OPTED_IN';

const ConfigurableOptInWidget = ({ userId }) => {
  const { users } = useSelector(
    (state) => ({
      users: state.users,
    }),
  );

  const userData = users?.listById ? users?.listById[userId] : null;
  const isDisableMarleyOptIn = useCheckPermissions([DISABLE_MARLEY_OPT_IN]);
  const optedIn = userData?.optIn?.status === OPTED_IN;

  const classes = classnames(style.optInWidget, {
    [style.optIn]: optedIn,
    [style.noOptIn]: !optedIn,
  });

  if (isDisableMarleyOptIn) {
    return (
      <div className={style.optInWidget}>
        Did you confirm opt-in?
        <Tooltip
          side="top"
          trigger={<InfoIcon />}
          tooltip="By creating this case you confirm that this customer has already consented to receive messages from your organization."
        />
      </div>
    );
  }

  return (
    <div className={classes}>
      {optedIn ? (
        <>
          <div data-testid="check-icon" className={style.iconCircle}>
            <Check />
          </div>
          Customer Opted-In
        </>
      ) : (
        <>
          Customer Not Opted-In
          <Tooltip
            side="top"
            trigger={<InfoIcon />}
            tooltip="Customers that have not previously opted-in to receive texts must opt-in before you can message them on this case."
          />
        </>
      )}
    </div>
  );
};

ConfigurableOptInWidget.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default ConfigurableOptInWidget;
