import { DISPLAY_MESSAGE_STATUS_FEATURE_START_DATE } from '../constants/general';
import { DISPLAY_MESSAGE_STATUS } from '../constants/permissions';
import { useCheckPermissions } from './common';

/* eslint-disable import/prefer-default-export */
export const isMultiPartyEnabled = (userPermissions) => userPermissions.includes('MULTI_PARTY_CHAT');

export const useDisplayFailedMessageStatus = (
  messageStatus,
  messageCreatedAt,
  jobShowFailedMessage,
) => {
  const messageCreatedAtDate = new Date(messageCreatedAt);
  return (
    useCheckPermissions([DISPLAY_MESSAGE_STATUS])
    && jobShowFailedMessage
    && messageStatus
    && ['failed', 'undelivered'].includes(messageStatus.toLowerCase())
    && Date.parse(messageCreatedAtDate)
      > Date.parse(DISPLAY_MESSAGE_STATUS_FEATURE_START_DATE)
  );
};

export const hasPermissionChanged = (
  prevPermissions,
  permissions,
  featureFlag,
) => prevPermissions !== null
  && prevPermissions?.includes(featureFlag) !== permissions?.includes(featureFlag);
