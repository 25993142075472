export const HOME_VIEW = "HOME_VIEW"
export const CASES_VIEW = 'CASES_VIEW'
export const CLAIMS_VIEW = 'CLAIMS_VIEW'
export const CLAIM_EDIT_VIEW = 'CLAIM_EDIT_VIEW'
export const JOBS_VIEW = 'JOBS_VIEW'
export const TEMPLATES_VIEW = 'TEMPLATES_VIEW'
export const POLICIES_VIEW = 'POLICIES_VIEW'
export const CONFIG_VIEW = 'CONFIG_VIEW'
export const USER_MGMT_VIEW = 'USER_MGMT_VIEW'
export const CHATS_VIEW = 'CHATS_VIEW'
export const ANALYTICS_VIEW = 'ANALYTICS_VIEW'
export const MASSNOTIFICATIONS_VIEW = 'MASSNOTIFICATIONS_VIEW'
export const SETTINGS_VIEW = 'SETTINGS_VIEW';
