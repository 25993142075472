import {
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormHelperText,
  InputLeftAddon,
  InputGroup,
  FormErrorMessage,
  Divider,
  Text,
  ModalFooter,
  VStack,
  HStack,
  ButtonGroup,
} from '@chakra-ui/react';
import React from 'react';

import './ParticipantForm.less';
import { buildOptedInWelcomeMessage, buildNonOptedInWelcomeMessage } from './welcomeMessagePrompts';

interface ParticipantFormProps {
  onClose: () => void;
  handlePhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFirstNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLastNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRelationshipChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  mobileLookupMessage: string | null;
  participantForm: {
    phoneNumber: string;
    firstName: string;
    lastName: string;
    relationship: string;
    error?: string;
  };
  requestStatus: string;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  relationships: { label: string; value: string; lobSubTypes?: string[] }[];
  isExistingUser: boolean;
  orgName: string;
  verifiedUser: boolean;
  isInEditMode: boolean;
  isPrimaryEndUser: boolean;
  lobSubType?: string;
}

const ParticipantForm: React.FC<ParticipantFormProps> = ({
  onClose,
  mobileLookupMessage = '',
  handlePhoneNumberChange,
  handleFirstNameChange,
  handleLastNameChange,
  handleRelationshipChange,
  participantForm = {
    phoneNumber: '',
    firstName: '',
    lastName: '',
    relationship: '',
  },
  requestStatus = undefined,
  onSubmit,
  relationships,
  isExistingUser = false,
  orgName,
  verifiedUser = false,
  isInEditMode,
  isPrimaryEndUser,
  lobSubType,
}) => {
  const handleOnClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onClose();
  };

  const isParticipantSelected = (
    participantForm.phoneNumber
    && participantForm.firstName
    && participantForm.lastName
  );

  const isPhoneNumberValid = mobileLookupMessage === 'SMS Supported' || isInEditMode;
  const isPhoneNumberAlreadyAdded = mobileLookupMessage === 'This phone number is already active on the conversation';
  const isSubmitDisabled = !isParticipantSelected || isPhoneNumberAlreadyAdded;

  // This is a temp fix for Farmers Alpha. Should be replaced with templating at some point.
  const marleyTermsOfService = 'himarley.com/tp';
  const farmersTermsOfService = `farmers.com/privacy-center/ and ${marleyTermsOfService}`;
  const termsOfService = orgName?.trim()?.toLowerCase() === 'farmers insurance' ? farmersTermsOfService : marleyTermsOfService;

  const welcomeMessageTemplate = verifiedUser
    ? buildOptedInWelcomeMessage(participantForm, orgName, termsOfService)
    : buildNonOptedInWelcomeMessage(participantForm, orgName, termsOfService);

  // only show welcome message preview if participant is selected and we are not in edit user mode
  const shouldShowWelcomeMessage = !isInEditMode && !!isParticipantSelected;

  const isLoading = requestStatus === 'PENDING';
  const isError = requestStatus === 'ERROR';

  let errorMessage;
  if (isInEditMode) {
    errorMessage = 'Unable to edit participant. Please try again later.';
  } else {
    errorMessage = 'Unable to invite participant. Please try again later.';
    if (participantForm.error) {
      errorMessage = participantForm.error;
    }
  }
  const submitButtonNotLoadingText = isInEditMode ? 'Save Participant' : 'Invite Participant';
  const submitButtonText = isLoading ? 'Saving...' : submitButtonNotLoadingText;

  const editingNameEnabled = isInEditMode || (!isExistingUser && !isLoading);

  const formatPhoneNumber = (phoneNumber: string) => {
    const cleanedValue = phoneNumber.replace(/\D/g, '');

    // Format the phone number (###) ###-####
    const formattedValue = cleanedValue
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      .slice(0, 14); // Limit length
    return formattedValue;
  };

  return (
    <>
      <ModalBody>
        <VStack>
          <FormControl isRequired isInvalid={!isPhoneNumberValid}>
            <FormLabel>Mobile Phone</FormLabel>
            <InputGroup>
              <InputLeftAddon>+1</InputLeftAddon>
              <Input
                value={formatPhoneNumber(participantForm.phoneNumber)}
                placeholder="Enter Phone Number"
                disabled={isLoading || isInEditMode}
                data-testid="addParticipantMobilePhoneInput"
                onChange={handlePhoneNumberChange}
              />
            </InputGroup>
            <FormHelperText>{mobileLookupMessage}</FormHelperText>
            <FormErrorMessage>
              {!isPhoneNumberValid && participantForm.phoneNumber.length === 10 ? 'Please enter a valid phone number.' : ''}
            </FormErrorMessage>
          </FormControl>
          <HStack w="100%">
            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                value={participantForm.firstName}
                placeholder="Enter First Name"
                disabled={!editingNameEnabled}
                data-testid="firstNameInput"
                onChange={handleFirstNameChange}
                maxLength={50}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                value={participantForm.lastName}
                placeholder="Enter Last Name"
                disabled={!editingNameEnabled}
                data-testid="lastNameInput"
                onChange={handleLastNameChange}
                maxLength={50}
              />
            </FormControl>
          </HStack>
          <FormControl>
            <FormLabel>Relationship</FormLabel>
            <Select
              placeholder="Choose Relationship"
              value={participantForm.relationship}
              disabled={isLoading || isPrimaryEndUser}
              data-testid="relationshipInput"
              onChange={handleRelationshipChange}
            >
              {relationships.filter(
                (option) => (option.lobSubTypes?.includes(lobSubType || '')) || !option.lobSubTypes,
              ).map((option) => (
                <option key={`${option.label}-${option.value}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
          {shouldShowWelcomeMessage ? (
            <>
              <Divider />
              {welcomeMessageTemplate}
            </>
          ) : null}
          <Divider />
          {isError ? (
            <Text
              color="red.500"
              data-testid="errorText"
            >
              {errorMessage}
            </Text>
          ) : null}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            onClick={handleOnClose}
            variant="outline"
            isDisabled={isLoading}
            data-testid="inviteParticipantCancelButton"
          >
            Cancel
          </Button>
          <Button
            isDisabled={isSubmitDisabled || isLoading}
            data-testid="inviteParticipantSubmitButton"
            onClick={onSubmit}
          >
            {submitButtonText}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

export default ParticipantForm;
