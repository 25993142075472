import _ from 'lodash'
import User from './user'

class Claim {
  constructor(claim) {
    this.raw = claim
    this.id = _.get(claim, '_id') || _.get(claim, 'id', '')
    this.archived = claim.archived
    this.companyName = claim.companyName
    const dateCreated = _.get(claim, 'dateCreated')
    this.claimNumber = _.get(claim, 'claimNumber')
      || _.get(claim, 'claimnumber')
      || _.get(claim, 'claims.0.claimnumber', '')
      
    this.insured = _.get(claim, 'identity.profile') ? new User({
      lastName: _.get(claim.identity.profile, 'lastName'),
      firstName: _.get(claim.identity.profile, 'firstName'),
      phoneNumber: _.get(claim.identity.profile, 'phoneNumber'),
      email: _.get(claim.identity.profile, 'email') || _.get(claim.identity, 'email'),
    }) : _.get(claim, 'identity.firstName') ? new User(_.get(claim, 'identity', {})) : claim.identity && new User({
      lastName: _.get(claim.identity, 'last'),
      firstName: _.get(claim.identity, 'first'),
      phoneNumber: _.get(claim.identity, 'mobile'),
      email: _.get(claim.identity, 'email') || _.get(claim.identity.profile, 'email')
    })

    this.claimant = this.insured
    this.insuredId = _.get(claim, 'identityId')
      || _.get(claim, 'insuredId')
    this.created = dateCreated || _.get(claim, 'nickname')
      || _.get(claim, 'created')
    this.status = _.get(claim, 'claimStatus')
      || _.get(claim, 'status')
    this.policyNumber = _.get(claim, 'policynumber')
      || _.get(claim, 'policyNumber')
      || _.get(claim, 'policies.0.policynumber', '')
      || _.get(claim, 'policies.0.policyNumber', '')
    this.insurerCompanyId = _.get(claim, 'companyId')
      || _.get(claim, 'insurerCompanyId')
      || _.get(claim, 'claims.0.companyId', '')
    const primaryContact = { ..._.get(claim, 'primaryContact', {}),
      firstName: _.get(claim, 'primaryContact.first') || _.get(claim, 'primaryContact.firstName'),
      lastName: _.get(claim, 'primaryContact.last') || _.get(claim, 'primaryContact.lastName'),
    }
    this.primaryContact = new User(primaryContact || {})
    this.primaryContactId = _.get(claim, 'primaryContactId')
      || _.get(claim, 'claims.0.primaryContact.id', '')
    this.branding = _.get(claim, 'branding')
    this.deductible = _.get(claim, 'deductible')
    this.dateOfLoss = _.get(claim, 'dateOfLoss')
  }
}

export default Claim
