import React from 'react';
import { useSelector } from 'react-redux';

import ParticipantCircles from './participant-circles';

interface ParticipantCirclesContainerProps {
  onClick: () => void;
}

const ParticipantCirclesContainer: React.FC<ParticipantCirclesContainerProps> = (
  { onClick },
) => {
  const chatId = useSelector((state) => state?.jobs?.activeChat?.id);
  const chats = useSelector((state) => state?.jobs?.chats);
  const participants = chats?.[chatId]?.participants || [];

  return (
    <ParticipantCircles
      participants={participants}
      onClick={onClick}
    />
  );
};

export default ParticipantCirclesContainer;
