import React from 'react'

const DownloadIcon = () => (
  <img alt="icon" width="48" src="../../../images/icons/download.svg" className="dl-icon" target="_blank" />
)

const DownloadIconClickable = ({ link }) => (
  <a href={link}>
    <DownloadIcon />
  </a>
)

export default DownloadIconClickable
