/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, ButtonRow } from '@himarley/unity';
import Download from '..././../../../../images/icons/download.svg';
import CheckIcon from '..././../../../../images/icons/check.svg';
import SnackFail from '../../../../../../images/icons/snackfail.svg';

const ImportErrors = ({
  errorUsers,
  fileName,
  userNumber,
  onClose,
  handleCSVExport,
  duplicateUsers,
  isUpdating,
}) => {
  const importedUsersCount = Math.max(
    userNumber - errorUsers?.length - duplicateUsers,
    0,
  );

  return (
    <div className="importErrorsWrap">
      <p>
        {fileName}
        {' '}
        has finished parsing
        {' '}
        {userNumber === 1 ? `${userNumber} user.` : `${userNumber} users.`}
        <br />
        There were some errors with the CSV.
      </p>
      <div className="statusWrap">
        <div className="importErrorWrap">
          <SnackFail className="iconsWrap" />
          {errorUsers?.length === 1
            ? `${errorUsers?.length} Error `
            : `${errorUsers?.length} Errors `}
          Found - Please fix all errors and import again.
        </div>
        <div className="importSuccessWrap">
          <CheckIcon className="iconsWrap" />
          {importedUsersCount === 1
            ? `${importedUsersCount} new user `
            : `${importedUsersCount} new users `}
          successfully imported.
        </div>
        {!isUpdating && (
          <div className="importSuccessWrap">
            <CheckIcon className="iconsWrap" />
            {duplicateUsers === 1
              ? `${duplicateUsers} duplicate user `
              : `${duplicateUsers} duplicate users `}
            ignored.
          </div>
        )}
      </div>
      <ButtonRow>
        <Button onClick={onClose} type="outline">
          Cancel
        </Button>
        <Button
          className="downloadIcon"
          onClick={handleCSVExport}
          type="neutral"
          Icon={Download}
        >
          Download Error CSV
        </Button>
      </ButtonRow>
    </div>
  );
};

ImportErrors.propTypes = {
  errorUsers: PropTypes.shape([]).isRequired,
  fileName: PropTypes.string.isRequired,
  userNumber: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  handleCSVExport: PropTypes.func.isRequired,
  duplicateUsers: PropTypes.string.isRequired,
  isUpdating: PropTypes.bool.isRequired,
};

export default ImportErrors;
