import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import StarIcon from './StarIcon/StarIcon';

const configs = {
  small: { width: 10, className: 'star-icon-small' },
  large: { width: 21, className: 'star-icon-large' },
};
const StarRate = ({ score, type, ...other }) => {
  if (score === null) {
    return null;
  }
  const config = configs[type] || configs.small;
  const rateStarsWidth = config.width * score;
  const roundedScore = Math.round(score);
  return (
    <div style={{ width: rateStarsWidth }} {...other}>
      {_.times(roundedScore, (index) => <StarIcon key={index} className={config.className} />)}
    </div>
  );
};

StarRate.propTypes = {
  score: PropTypes.number,
  type: PropTypes.string,
};

StarRate.defaultProps = {
  score: null,
  type: '',
};

export default StarRate;
