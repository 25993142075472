import { defineStyleConfig } from '@chakra-ui/react';

export const badgeTheme = defineStyleConfig({
  variants: {
    number: {
      borderRadius: 'full',
      width: 4,
      height: 4,
      textAlign: 'center',
      lineHeight: 4,
      backgroundColor: 'marleyRed.500',
      color: 'white',
    },
  },
});

export default badgeTheme;
