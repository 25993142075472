/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Avatar, WithDot, Divider } from '@himarley/unity';
import List from '../elements/List/List';
import HomeIcon from '../../../images/icons/home/home.svg';
import './Header.less';
import Dropdown from '../elements/dropdowns/Dropdown/Dropdown';
import { makeApiRequest } from '../../actions/api/api';
import { closeSnackbar, showSnackbar } from '../../actions/notification';
import { getUserProfile } from '../../actions/profile';

const MinusIcon = () => <div className="minus-icon">-</div>;
const AwayIcon = () => (
  <div
    className="notification-point-relative notification-point-lg notification-point notification-red-point"
    data-testid="away-icon"
  >
    <MinusIcon />
  </div>
);
const ActiveIcon = () => (
  <div
    className="notification-point-relative notification-point-lg notification-point notification-green-point"
    data-testid="active-icon"
  />
);
const ProfileDropdown = ({
  profile,
  isAway,
  logout,
  setConfigItem,
  awayMessage,
  authUserId,
}) => {
  const {
    firstName, lastName, score, imageUrl,
  } = profile || {};

  const dispatch = useDispatch();
  const toggleStatus = (away) => dispatch(
    makeApiRequest('PUT_USERS_AUTOREPLIES', [authUserId, awayMessage?.id], {
      ...(awayMessage || {}),
      active: away,
    }),
  );

  const history = useHistory();
  const handleOpenProfile = useCallback(() => {
    dispatch(getUserProfile(authUserId));
    history.push('/profile');
  }, [dispatch, authUserId, history]);

  useEffect(() => {
    if (isAway) {
      dispatch(
        showSnackbar({
          text: 'Away messaging enabled',
          persist: true,
          linkText: 'Disable',
          onToggle: () => {
            toggleStatus(!isAway);
          },
          isHyperLink: true,
        }),
      );
    }
  }, [authUserId, dispatch, isAway]);

  useEffect(() => {
    if (!isAway) dispatch(closeSnackbar());
  }, [isAway, dispatch]);

  const [statusMenuOpen, setStatusMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const id = _.get(profile, '_id');
  const name = !firstName || !lastName || `${firstName} ${lastName}`;
  const menuItems = [
    {
      id: 'status',
      onClick: () => setStatusMenuOpen(!statusMenuOpen),
      icon: isAway ? <AwayIcon /> : <ActiveIcon />,
      label: isAway ? 'Away' : 'Active',
    },
    {
      id: 'profile',
      onClick: () => {
        handleOpenProfile();
        setConfigItem('GENERAL_INFO');
      },
      label: 'Profile',
    },
    {
      id: 'help',
      onClick: () => window.open('https://himarley.zendesk.com/hc/en-us', '_blank'),
      label: 'Help',
    },
    {
      id: 'logout',
      onClick: () => logout(),
      label: 'Logout',
    },
  ];

  const HeaderDrop = () => (
    <div style={{ display: 'flex' }}>
      <div role="link" tabIndex={0} className="header-button" data-testid="header-btn" aria-label="user profile">
        <UserPhoto />
      </div>
    </div>
  );

  const UserPhoto = () => (
    <WithDot
      show
      withBorder
      type={isAway ? 'red' : 'green'}
      size="lg"
      position="bottom-right"
      Icon={isAway ? MinusIcon : null}
    >
      <Avatar
        id={id}
        imageUrl={imageUrl}
        name={name}
        className="user-profile-photo"
      />
    </WithDot>
  );

  return (
    <div className="user-name-block">
      {!process.env.REMOVE_WELCOME_PAGE_ENABLED && (
        <Link data-testId="home-icon" className="home-icon-container" to="/home">
          <HomeIcon />
        </Link>
      )}
      <div>
        <Dropdown
          id="profile-dropdown"
          multiSelect
          show={isDropdownOpen}
          DropItem={<HeaderDrop />}
          onClick={() => setIsDropdownOpen(true)}
        >
          <>
            <div className="dropdown-profile">
              <UserPhoto />
              <div className="dropdown-profile-name-score">
                <div
                  className={`dropdown-profile-user-name ${
                    !score ? 'dropdown-profile-user-name-larger' : ''
                  }`}
                >
                  {name}
                </div>
              </div>
            </div>
            {statusMenuOpen && (
              <div className="status-menu marley-dropdown-container-inner">
                {awayMessage?.id && (
                <>
                  <List
                    onToggle={(id) => {
                      setStatusMenuOpen(false);
                      setIsDropdownOpen(false);
                      toggleStatus(id === 'away');
                    }}
                    items={[
                      {
                        id: 'active',
                        icon: <ActiveIcon />,
                        label: 'Active',
                      },
                      { id: 'away', icon: <AwayIcon />, label: 'Away' },
                    ]}
                  />
                  <Divider />
                </>
                )}
                <List
                  onToggle={() => {
                    setStatusMenuOpen(false);
                    setIsDropdownOpen(false);
                    handleOpenProfile();
                    setConfigItem('AUTO_REPLIES');
                  }}
                  items={[{ id: 'editAway', label: 'Edit away message' }]}
                />
              </div>
            )}
            <div className="menu-caret">{'>'}</div>
            <List
              onToggle={(toggledId) => {
                const item = menuItems.find((b) => b.id === toggledId);
                if (item) {
                  item.onClick();
                  if (item.id !== 'status') {
                    setIsDropdownOpen(false);
                  }
                }
              }}
              items={menuItems}
            />
          </>
        </Dropdown>
      </div>
    </div>
  );
};

export { ProfileDropdown };

ProfileDropdown.propTypes = {
  isAway: PropTypes.bool.isRequired,
  setConfigItem: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  logout: PropTypes.func.isRequired,
  authUserId: PropTypes.func,
  awayMessage: PropTypes.instanceOf(Object),
};

ProfileDropdown.defaultProps = {
  authUserId: '',
  awayMessage: {},
};

export default ProfileDropdown;
