import type { Message } from '@app/components/Chats/Chat/types';
import { isApplicableUnreadMessage } from '@app/helpers/inbox.helpers';

export const updateFirstUnreadMessage = (
  messages: Message[],
  lastViewedMessageId: string,
  userId: string,
) => {
  // If there is no lastViewedMessageId, then all messages are unread
  let previousMessageRead = !lastViewedMessageId;

  const updatedMessages = messages.map((message) => {
    if (previousMessageRead && isApplicableUnreadMessage(message, userId, !lastViewedMessageId)) {
      previousMessageRead = false;
      return {
        ...message,
        isFirstUnreadMessage: true,
      };
    }

    const messageId = message.id || message._id;

    if (lastViewedMessageId && (messageId === lastViewedMessageId)) {
      previousMessageRead = true;
    }

    return { ...message, isFirstUnreadMessage: false };
  });

  return updatedMessages;
};

export default { updateFirstUnreadMessage };
