/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
// eslint-disable-next-line import/no-named-as-default
import { bulkUpdateHelper, isPrivateOrUnassigned } from '../../../helpers/cases'
import { jobBulkUpdateRequest } from '../../../actions/job'
import { showSnackbar } from '../../../actions/notification'
import AssignUserDropdown from '../../AssignDropdown/AssignUserDropdown'
import './ReassignCaseConfirmation.less'
import Logo from '../../../../images/icons/hi-marley_logo_photo.svg';
import MarleyModal from '../../elements/Modal/Modal'
import ButtonRow from '../../elements/buttons/ButtonRow/ButtonRow'
import Button from '../../elements/buttons/Button/Button'

const ReassignCaseConfirmation = ({ show, toggleModal, selectedJobs }) => {
  const dispatch = useDispatch()
  const [selectedOperator, setSelectedOperator] = useState({})

  const handleSubmit = () => {
    // eslint-disable-next-line max-len
    const foundPrivateOrUnassigned = selectedJobs.find((selectedJob) => isPrivateOrUnassigned(selectedJob, selectedOperator.id))
    if (foundPrivateOrUnassigned) {
      dispatch(showSnackbar({ text: 'You cannot mark a private case as unassigned.', isError: false }));
    } else {
      const jobsToClose = bulkUpdateHelper(selectedJobs, 'operatorId', selectedOperator.id)
      dispatch(jobBulkUpdateRequest(jobsToClose, { preUpdateText: 'Reassigning', postUpdateText: 'reassigned' }))
      toggleModal()
    }
  }

  return (
    <MarleyModal
      className="bulk-reassign-modal"
      title="Reassign All Cases"
      show={show}
      toggleModal={toggleModal}
      size="sm"
    >
      <div className="message">
        <div className="message-logo"><Logo /></div>
          Clicking "Reassign All" will notify all open cases with a message.
      </div>
      <AssignUserDropdown
        className="bulk-reassign-dropdown"
        id="cases"
        defaultLabel={selectedOperator.id ? `${selectedOperator.label}` : 'Select a new assignee'}
        selectedId={_.get(selectedOperator, 'id', 'unassigned')}
        handleSelect={(e) => setSelectedOperator(e)}
        options={[]}
      />
      <ButtonRow>
        <Button type="positive" disabled={!selectedOperator.id} onClick={handleSubmit}>Reassign All Cases</Button>
      </ButtonRow>

    </MarleyModal>
  )
}

export default ReassignCaseConfirmation
