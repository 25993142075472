import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAllChatParticipants } from '@app/actions/chat';
import { putChatParticipant as putChatParticipantAction, setChatRemoveUserStatus } from '@app/actions/participants';
import { setUpdateUserStatus } from '@app/actions/users';
import { StateType } from '@app/types/reducer-state';

import ParticipantModal from './participant-modal';
import { setRelationship, setRelationshipFromList } from './relationships';
import RemoveParticipantModal from './remove-participant-modal';
import ViewParticipantsModal from './view-participants-modal';

export const selectChatParticipants = (state: StateType) => state?.participants?.chatParticipants;

interface ViewParticipantsContainerProps {
  handleCloseViewParticipantsModal: () => void;
  handleOpenParticipantModal: () => void;
  handleCloseParticipantModal: () => void;
  currentView: string;
  setCurrentView: (view: string) => void;
  handleCloseRemoveParticipantModal: () => void;
  selectedParticipantId: string;
  setSelectedParticipantId: (id: string) => void;
  enableAddParticipants: boolean;
}

const ViewParticipantsContainer: React.FC<ViewParticipantsContainerProps> = ({
  handleCloseViewParticipantsModal,
  handleOpenParticipantModal,
  handleCloseParticipantModal,
  currentView = null,
  setCurrentView,
  handleCloseRemoveParticipantModal,
  selectedParticipantId,
  setSelectedParticipantId,
  enableAddParticipants,
}) => {
  const dispatch = useDispatch();
  const chatId = useSelector((state) => state?.jobs?.activeChat?.id);
  const jobId = useSelector((state) => state?.jobs?.activeJob?._id);
  const primaryEndUserId = useSelector((state) => state?.jobs?.activeJob?.customer?.id) || '';
  const primaryOperatorId = useSelector((state) => state?.jobs?.activeJob?.assignedOperator?.id);
  const loggedInOperatorId = useSelector((state) => state?.auth?.user?._id) || '';

  const {
    activeParticipants: active,
    pendingParticipants: pending,
    inactiveParticipants: inactive,
  } = useSelector(selectChatParticipants);

  const findActiveParticipant = (p: { id?: string }) => p.id === selectedParticipantId;
  const participant = active?.find(findActiveParticipant)
    || pending?.find(findActiveParticipant)
    || inactive?.find(findActiveParticipant);

  const handleClickRemoveParticipant = (id: string) => {
    setSelectedParticipantId(id);
    setCurrentView('REMOVE_PARTICIPANT_VIEW');
  };

  const handleClickEditParticipant = (id: string) => {
    setSelectedParticipantId(id);
    setCurrentView('EDIT_PARTICIPANT_VIEW');
  };

  const handleClickReinviteParticipant = (id: string) => {
    setSelectedParticipantId(id);
    dispatch(putChatParticipantAction({
      chatId,
      endUserId: id,
      relationship: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    }));
    handleCloseViewParticipantsModal();
  };

  useEffect(() => {
    dispatch(setUpdateUserStatus(''));
    dispatch(setChatRemoveUserStatus(null));
    if (chatId) {
      dispatch(getAllChatParticipants(chatId));
    }
  }, [currentView, chatId, dispatch]);

  if (currentView === 'REMOVE_PARTICIPANT_VIEW') {
    if (participant) {
      const participantWithRelationship = setRelationship(
        participant,
        jobId,
        primaryEndUserId,
        primaryOperatorId,
      );
      return (
        <RemoveParticipantModal
          participant={participantWithRelationship}
          onClose={handleCloseRemoveParticipantModal}
        />
      );
    }
  } else if (['ADD_PARTICIPANT_VIEW', 'ADD_PARTICIPANT_FROM_TOOLBAR_VIEW'].includes(currentView || '')) {
    return <ParticipantModal onClose={handleCloseParticipantModal} />;
  } else if (currentView === 'EDIT_PARTICIPANT_VIEW') {
    if (participant) {
      const participantWithRelationship = setRelationship(
        participant,
        jobId,
        primaryEndUserId,
        primaryOperatorId,
      );
      if (participant?.id === primaryEndUserId) {
        participantWithRelationship.isPrimaryEndUser = true;
      }
      return (
        <ParticipantModal
          onClose={handleCloseParticipantModal}
          initialParticipantData={participantWithRelationship}
        />
      );
    }
  } else if (currentView === 'PARTICIPANTS_VIEW') {
    const activeParticipants = setRelationshipFromList(
      active,
      jobId,
      primaryEndUserId,
      primaryOperatorId,
    );
    const pendingParticipants = setRelationshipFromList(pending, jobId);
    const inactiveParticipants = setRelationshipFromList(inactive, jobId);
    return (
      <ViewParticipantsModal
        activeParticipants={activeParticipants}
        pendingParticipants={pendingParticipants}
        inactiveParticipants={inactiveParticipants}
        loggedInOperatorId={loggedInOperatorId}
        primaryEndUserId={primaryEndUserId}
        onClickRemoveParticipant={handleClickRemoveParticipant}
        onClickEditParticipant={handleClickEditParticipant}
        onClickReinviteParticipant={handleClickReinviteParticipant}
        onClose={handleCloseViewParticipantsModal}
        onClickAddParticipant={handleOpenParticipantModal}
        enableAddParticipants={enableAddParticipants}
      />
    );
  }

  return null;
};

export default ViewParticipantsContainer;
