import React from 'react';
import PropTypes from 'prop-types';
import styles from './Highlighter.module.less';

/**
 * Modifies chunk of text in a body of text.
 * @return {ReactComponent} Wrapper around chunk of text that adds extra styling.
 */
const Highlighter = ({
  text,
  fontWeight,
  textDecoration,
  color,
  backgroundColor,
}) => {
  const style = {
    fontWeight,
    textDecoration,
    color,
    backgroundColor,
    padding: '3px',
    borderRadius: '5px',
  };

  return (
    <span className={styles.container} style={style}>
      {text}
    </span>
  );
};

Highlighter.propTypes = {
  fontWeight: PropTypes.string,
  textDecoration: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  text: PropTypes.string.isRequired,
};

Highlighter.defaultProps = {
  fontWeight: 'normal',
  textDecoration: 'none',
  color: 'black',
  backgroundColor: '#F7E9BA',
};

export default Highlighter;
