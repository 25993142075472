import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '@himarley/unity';
import PropTypes from 'prop-types';
import { setExpandInboxSearchBar, updateSearch } from '../../../../actions/job';
import styles from './CancelSearchButton.module.less';

const CancelSearchButton = ({ testId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onClick = () => {
    dispatch(updateSearch({ search: null, searchDisplayValue: '' }));
    dispatch(setExpandInboxSearchBar(false));
    history.push({ search: '' });
  };
  return (
    <Button className={styles.cancelSearchBtn} type="outline" onClick={onClick} testId={testId}>
      Cancel Search
    </Button>
  );
};

CancelSearchButton.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default CancelSearchButton;
