import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getiFrameLookUrl } from '../../actions/analytics'
import FullpageModal from '../modal/FullpageModal/FullpageModal'
import './LargeLookContent.less'

class LargeLookContent extends React.Component {
  componentDidMount() {
    const {
      lookNumber, getiFrameLook, caseId,
    } = this.props
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    timezone = timezone.replace(/\//ig, '-')
    getiFrameLook(caseId, lookNumber, timezone)
  }

  render() {
    const { lookNumber, lookUrls, closeLook } = this.props
    return (
      <FullpageModal data-testid="look-modal" title="" className="look-modal" view="look" onClose={closeLook}>
        <div className="large-look-content-container">
          <iframe className="large-look-iframe" title="look" src={lookUrls[lookNumber]} frameBorder="0" />
        </div>
      </FullpageModal>
    )
  }
}

LargeLookContent.propTypes = {
  lookUrls: PropTypes.instanceOf(Object).isRequired,
  lookNumber: PropTypes.string.isRequired,
  getiFrameLook: PropTypes.func.isRequired,
  closeLook: PropTypes.func.isRequired,
  caseId: PropTypes.string,
}

LargeLookContent.defaultProps = {
  caseId: '',
}

const mapStateToProps = (state) => ({
  userId: _.get(state, 'auth.user._id'),
  lookUrls: _.get(state, 'analytics.lookUrls', ''),
})

const mapDispatchToProps = {
  getiFrameLook: getiFrameLookUrl,
}

export default connect(mapStateToProps, mapDispatchToProps)(LargeLookContent)
