/* eslint-disable no-restricted-syntax */
import {
  SET_SOCKET, SUBSCRIBE_TO_ORG, SUBSCRIBE_TO_TOPICS,
  LIVE_UPDATE_STRATEGY_SELECTED, SET_SOCKET_FAILURE, UNSUBSCRIBE_FROM_TOPICS,
} from '../constants/actions'
import { SUCCESS } from '../constants/general';
import { listToMap } from '../helpers/common'

export const initialState = {
  socketObject: null,
  liveUpdateStrategySelected: null,
  subscribedTopics: {},
  topicHandler: null,
}

const socket = (state = initialState, action) => {
  switch (action.type) {
    case SET_SOCKET:
      return {
        ...state,
        socketObject: action.payload?.socket,
        topicHandler: action.payload?.topicHandler,
      };
    case SUBSCRIBE_TO_ORG:
      return { ...state }
    case SUBSCRIBE_TO_TOPICS: {
      const { topics, handler } = action.payload;
      const { subscribedTopics } = state;
      const topicsToSubTo = topics.filter((topic) => !subscribedTopics[topic]);
      const ws = state.socketObject;
      const topicsMap = { ...subscribedTopics };
      if (topicsToSubTo.length) {
        ws?.subscribe(topicsToSubTo);
        topicsToSubTo.forEach((topic) => {
          ws?.on(topic, handler);
          topicsMap[topic] = true;
        });
      }
      return { ...state, subscribedTopics: topicsMap }
    }
    case UNSUBSCRIBE_FROM_TOPICS: {
      const { topics } = action.payload;
      const topicsAsMap = listToMap(topics);
      const { socketObject: ws, subscribedTopics } = state;
      const newTopics = {};
      const topicsToUnsub = [];
      if (topics && Object.keys(subscribedTopics).length) {
        for (const topic in subscribedTopics) {
          if (topicsAsMap[topic]) {
            topicsToUnsub.push(topic);
          } else {
            newTopics[topic] = true;
          }
        }
        if (topicsToUnsub.length) ws.unsubscribe(topicsToUnsub);
      }
      return { ...state, subscribedTopics: newTopics }
    }
    case LIVE_UPDATE_STRATEGY_SELECTED:
      return { ...state, liveUpdateStrategySelected: SUCCESS }
    case SET_SOCKET_FAILURE:
      return { ...state, liveUpdateStrategySelected: 'websockets-failure' }
    default:
      return { ...state }
  }
}

export default socket
