import React from 'react';
import PropTypes from 'prop-types';

import './MediaImage.less';

const MediaImage = ({
  fileUrl,
  providerId,
  isThumbnail,
}) => (
  <img
    className={`img-display ${isThumbnail ? 'img-display-thumbnail' : 'img-display-expanded'}`}
    src={fileUrl}
    alt="chat"
    data-testid={`media-image-${providerId}`}
  />
);

MediaImage.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  isThumbnail: PropTypes.bool.isRequired,
};

export default MediaImage;
