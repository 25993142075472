import React from 'react';
import RedactIcon from '../../../images/icons/hide.svg';
import EyeIcon from '../../../images/icons/eye.svg';
import { print } from '../Chats/RightBar/UserDetails/Media/helpers';
import PrintIcon from '../../../images/icons/print.svg';
import RotateLeftIcon from '../../../images/icons/rotate_left.svg';
import RotateRightIcon from '../../../images/icons/rotate_right.svg';

const getImageControls = ({
  localFileUrl,
  testIdPrefix,
  providerId,
  handleRotateRight,
  handleRotateLeft,
  setIsRedactImageModalShown,
  setIsConfirmViewRedactedImageModalShown,
  setIsViewRedactedImageModalShown,
}) => {
  const controls = {
    print: {
      subIcon: <PrintIcon />,
      label: 'Print Image',
      onClick: () => print(localFileUrl),
      testId: `${testIdPrefix}-button-print-${providerId}`,
    },
    rotateRight: {
      label: 'Rotate Image Right',
      subIcon: <RotateRightIcon />,
      onClick: handleRotateRight,
      testId: `${testIdPrefix}-button-rotate-right-${providerId}`,
    },
    rotateLeft: {
      label: 'Rotate Image Left',
      subIcon: <RotateLeftIcon />,
      onClick: handleRotateLeft,
      testId: `${testIdPrefix}-button-rotate-left-${providerId}`,
    },
    redactImage: {
      label: 'Redact Image',
      subIcon: <RedactIcon />,
      onClick: () => {
        setIsRedactImageModalShown(true);
        setIsConfirmViewRedactedImageModalShown(false);
        setIsViewRedactedImageModalShown(false);
      },
      testId: `${testIdPrefix}-button-redact-image-${providerId}`,
    },
    viewRedactedImage: {
      subIcon: <EyeIcon />,
      label: 'View Redacted Image',
      onClick: () => {
        setIsConfirmViewRedactedImageModalShown(true);
        setIsRedactImageModalShown(false);
        setIsViewRedactedImageModalShown(false);
      },
      testId: `${testIdPrefix}-button-view-redacted-image-${providerId}`,
    },
  };
  return controls;
};

export default getImageControls;
