/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ALL_TAG } from './constants';
import styles from './TagsSelect.module.less';

const TagsSelect = ({ tags, setTags }) => {
  const [selectedTags, setSelectedTags] = useState(new Map(tags));

  useEffect(() => {
    if (tags?.size > 0) {
      setSelectedTags(new Map(tags));
    }
  }, [tags, setSelectedTags]);

  const handleSelectTag = useCallback(
    (tag) => {
      const newTags = new Map(selectedTags);
      if (tag === ALL_TAG) {
        for (const key of newTags.keys()) {
          key === ALL_TAG
            ? newTags.set(key, { ...newTags.get(key), isSelected: true })
            : newTags.set(key, { ...newTags.get(key), isSelected: false });
        }
      } else {
        newTags.set(ALL_TAG, { id: 'all', isSelected: false });
        newTags.set(tag, {
          ...selectedTags.get(tag),
          isSelected: !selectedTags.get(tag)?.isSelected,
        });
      }
      let hasAtLeastOneSelected = false;
      for (const { isSelected } of newTags.values()) {
        if (isSelected) {
          hasAtLeastOneSelected = true;
          break;
        }
      }
      if (!hasAtLeastOneSelected) {
        return;
      }
      setTags(newTags);
      setSelectedTags(newTags);
    },
    [setTags, selectedTags],
  );

  return [...selectedTags.entries()]?.map(
    ([label, { isSelected, id, disabled }]) => (
      <button
        data-testid={`${id}-${isSelected ? 'selected' : 'notSelected'}`}
        key={label}
        type="button"
        onClick={() => handleSelectTag(label)}
        className={`${styles.tag} ${
          isSelected ? styles.selected : styles.unselected
        }
        ${disabled ? styles.disabled : ''}`}
        disabled={disabled}
      >
        {label}
      </button>
    ),
  );
};

TagsSelect.propTypes = {
  tags: PropTypes.shape(Map).isRequired,
  setTags: PropTypes.func.isRequired,
};

export default TagsSelect;
