import React from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from '../../icons/LoadingIcon';
import './OrgSettings.less';

const Preview = ({
  firstTextName,
  firstTextMessage,
  secondTextMessage,
  thirdTextName,
  thirdTextMessage,
  fourthTextName,
  fourthTextMessage,
  previewLabel,
  isLoading,
  previewBanner,
}) => (
  <div className="previewWrap">
    {previewLabel && <div className="previewLabel">{previewLabel}</div>}
    <div className="previewBox">
      {isLoading
        ? (
          <div className="loading-notification">
            <div className="loading none-found-icon">
              <LoadingIcon />
            </div>
            <div>Loading Preview</div>
          </div>
          )
        : (
          <>
            {previewBanner}
            <div className="sentText">
              <strong>{firstTextName}</strong>
              <br />
              <span data-testid="firstTextMessage">{firstTextMessage}</span>
            </div>
            {secondTextMessage && (
            <div className='receivedTextWrap'>
              <div className='receivedText'>
                <strong>Charlotte Customer:</strong>
                {secondTextMessage}
              </div>
            </div>
          )}
            {thirdTextName && thirdTextMessage && (
            <div className="sentText">
              <strong>{thirdTextName}</strong>
              <br />
              <span data-testid="secondSentText">{thirdTextMessage}</span>
            </div>
            )}
            {fourthTextName && fourthTextMessage && (
            <div className="sentText">
              <strong>{fourthTextName}</strong>
              <br />
              <span data-testid="fourthTextMessage">{fourthTextMessage}</span>
            </div>
            )}
          </>
          )}
    </div>
  </div>
);

Preview.propTypes = {
  firstTextName: PropTypes.string,
  firstTextMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondTextMessage: PropTypes.string,
  thirdTextName: PropTypes.string,
  thirdTextMessage: PropTypes.string,
  fourthTextName: PropTypes.string,
  fourthTextMessage: PropTypes.string,
  previewLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isLoading: PropTypes.bool,
  previewBanner: PropTypes.node,
};

Preview.defaultProps = {
  firstTextName: '',
  firstTextMessage: '',
  secondTextMessage: '',
  thirdTextName: '',
  thirdTextMessage: '',
  fourthTextName: '',
  fourthTextMessage: '',
  previewLabel: 'Preview',
  isLoading: false,
  previewBanner: null,
};

export default Preview;
