import React from 'react'
import './ButtonRow.less'

const ButtonRow = ({ children }) => (
  <div className="button-row">
    {children}
  </div>
)

export default ButtonRow
