/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import unionBy from 'lodash/unionBy';
import differenceBy from 'lodash/differenceBy';
import List from '../../../elements/List/List';
import withLazyLoad from '../../../withLazyLoad/withLazyLoad';
import SearchBar from '../../../SearchBar/SearchBar';
import { operatorType, groupType } from '../../../../models/marleyTypes';
import { lazyLoad } from '../../../../actions/common';

import Divider from '../../../elements/Divider/Divider';
import Text from '../../../elements/text/Text/Text';

import './CustomSelector.less';
import { usePrevious } from '../../../Hooks/usePrevious';

const unassigned = { id: 'unassigned', name: 'Unassigned', lastName: 'AAA' };
const defaultOperators = [{ id: 'all', name: 'All Operators', isDefault: true }];

const CustomSelector = ({
  // eslint-disable-next-line @typescript-eslint/no-shadow
  operators, lazyLoad, groups, modifyQuery, setProperties, authId,
  areGroupsLoading, areOperatorsLoading, onChange, properties, authName,
}) => {
  const [searchText, setSearchText] = useState();
  const [selectedList, setSelectedList] = useState([]);
  const [remainingOperators, setRemainingOperators] = useState([]);
  const [remainingGroups, setRemainingGroups] = useState([]);
  const [selectedOperators, setSelectedOperators] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [allOperators, setAllOperators] = useState([]); // for adding "unassigned"

  const loadGroups = (params) => lazyLoad(
    groupType,
    { ...params, offset: 0 },
    'groups',
  );

  const loadAllData = () => {
    loadGroups();
    modifyFilter();
  };

  const meLabel = { id: authId, name: `${authName} (Me)` };

  const loadFullOperatorList = () => {
    const hasSearchText = !!(searchText && searchText.length > 0);
    const properators = !hasSearchText
      ? [meLabel, unassigned, ...operators.filter((o) => o.id !== authId)] : [...operators];
    setAllOperators(properators); // Needed to have 'unassigned' option
  };

  const mapIdName = (full) => {
    const { id, name, isGroup } = full;
    return { id, name, isGroup };
  };

  const updateProperties = ({ newOperators, newGroups }) => {
    if (newOperators) {
      setSelectedOperators(newOperators);
      if (onChange) onChange({ operatorIds: newOperators.map((o) => o.id) });
    }
    if (newGroups) setSelectedGroups(newGroups);
    setProperties({
      operators: newOperators || selectedOperators.map(mapIdName),
      groups: newGroups || selectedGroups.map(mapIdName),
    });
  };

  const addToSelectedOperators = (id) => {
    const newOperator = allOperators.find((o) => o.id === id);
    newOperator.isGroup = false;
    updateProperties({ newOperators: unionBy([...selectedOperators, newOperator], 'id') });
  };

  const removeFromSelectedOperators = (id) => {
    updateProperties({
      newGroups: [...selectedGroups].filter((g) => g.id !== id),
      newOperators: [...selectedOperators].filter((o) => o.id !== id),
    });
  };

  const toggleGroupSelection = (id) => {
    const toggledGroup = groups.find((g) => g.id === id);
    toggledGroup.isGroup = true;
    const newGroups = unionBy([...selectedGroups, toggledGroup], 'id');
    updateProperties({ newOperators: null, newGroups });
  };
  const listFormat = (ops) => ops.map((o) => ({
    id: o.id, label: o.name, isGroup: o.isGroup, isDefault: o.isDefault,
  }));
  // for search filter
  const modifyFilter = () => {
    console.log('cdm trigger modify');
    const hasSearchText = (searchText && searchText.length > 0);

    const operatorQuery = {};
    operatorQuery.searchText = hasSearchText ? searchText : null;
    modifyQuery(operatorQuery, true); // this changes operator search

    const groupQuery = {};
    if (hasSearchText) groupQuery.searchText = searchText;
    loadGroups(groupQuery);
  };

  /*
    UseEffects -- Should be below functions
  */

  // Initial Mount -- load groups and queues, operators loaded through HOC
  useEffect(loadAllData, []);

  useEffect(() => {
    const groups = properties?.groups || [];
    const operators = properties?.operators || [];
    if (groups || operators) {
      setSelectedList([...operators, ...groups]);
    }
    setSelectedOperators(operators);
    setSelectedGroups(groups);
  }, [properties]);

  // Load unassigned
  useEffect(loadFullOperatorList, [operators]);

  // Triggering filter
  const previousSearch = usePrevious(searchText);
  useEffect(previousSearch !== searchText ? modifyFilter : () => {}, [searchText]);

  // Set Remaining Operators -- When selectedOperators are set, need to set remaining operators
  useEffect(() => {
    setRemainingOperators(differenceBy(allOperators, selectedOperators, 'id'));
    setRemainingGroups(differenceBy(groups, selectedGroups, 'id'));
  }, [selectedOperators, allOperators, selectedGroups, groups, selectedList]);

  const mapIds = (m) => m.id;
  const defaultOperatorList = searchText && searchText.length > 0 ? [] : defaultOperators;
  const operatorList = listFormat(selectedList?.length > 0 ? selectedList
    : defaultOperatorList);
  const isAll = operatorList[0]?.id === 'all';

  return (
    <>
      <SearchBar
        placeholder="Search name"
        onChange={(e) => setSearchText(e.target.value)}
      />
      {!isAll && (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className="clear-selected"
          data-testid="clear-selected"
          onClick={() => updateProperties({ newOperators: [], newGroups: [] })}
        >
          <Text type="primary">
            Clear Selected
          </Text>
        </div>
      )}
      <Divider>Selected</Divider>
      <List
        id="selected-operators"
        maxDisplay={5}
        showIcon
        selectedIds={operatorList.map(mapIds)} // Allows checkmarks to always be on
        items={operatorList}
        onToggle={(id) => removeFromSelectedOperators(id)}
      />

      <Divider>Operators</Divider>
      <List
        id="remaining-operators"
        selectedIds={[]}
        maxDisplay={5}
        isLoading={areOperatorsLoading}
        items={listFormat(remainingOperators)}
        onToggle={(id) => addToSelectedOperators(id)}
      />
      <Divider>Groups</Divider>
      <List
        selectedIds={(selectedGroups || [])
          .map((g) => g.id)} // allows checkmarks to properly populate
        id="groups"
        maxDisplay={5}
        isLoading={areGroupsLoading}
        items={listFormat(remainingGroups)}
        onToggle={(id) => toggleGroupSelection(id)}
      />
    </>
  );
};

CustomSelector.propTypes = {
  operators: PropTypes.instanceOf(Array).isRequired,
  groups: PropTypes.shape([]).isRequired,
  lazyLoad: PropTypes.func.isRequired,
  modifyQuery: PropTypes.func.isRequired,
  areGroupsLoading: PropTypes.bool.isRequired,
  areOperatorsLoading: PropTypes.bool.isRequired,
  properties: PropTypes.arrayOf(Object),
  setProperties: PropTypes.func.isRequired,
  authId: PropTypes.string.isRequired,
  authName: PropTypes.string.isRequired,
  onChange: PropTypes.string.isRequired,
};

CustomSelector.defaultProps = {
  properties: {},
};
export { CustomSelector as CustomSelectorUnbound };

const mapStateToProps = (state) => {
  const { operators, groups } = state;
  const areOperatorsLoading = !operators.loadedItem && operators.loadingItem;
  const areGroupsLoading = !groups.loadedItem && groups.loadingItem;

  return {
    areOperatorsLoading,
    areGroupsLoading,
    areOperatorsLoaded: operators.loadedItem,
    areGroupsLoaded: groups.loadedItem,
    operators: get(state.operators, 'list', []),
    groups: get(state, 'groups.list', []),
    authId: get(state, 'auth.user._id'),
    authName: `${get(state, 'auth.user.firstName')} ${get(state, 'auth.user.lastName')}`,
  };
};

const CustomSelectorWithOperators = withLazyLoad(
  CustomSelector,
  {
    type: operatorType,
    disableInitialFetch: true,
    sort: { column: 'name', order: 'DESC' },
    listLocation: 'users',
  },
);

const ReduxCustomSelect = connect(mapStateToProps, { lazyLoad })(CustomSelectorWithOperators);

export default ReduxCustomSelect;
