import logo from './logo.png';

const CALLBACK_PATH = '/login/callback';

const oktaAuthConfig = {
  clientId: process.env.OKTA_CLIENT_ID || '0oaqwvmw9dLBue3iT0h7',
  issuer:
    process.env.OKTA_ISSUER
    || 'https://himarley-prod.oktapreview.com/oauth2/ausqt450vmoOdOEbT0h7',
  redirectUri: window.location.origin + CALLBACK_PATH,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  postLogoutRedirectUri: process.env.APP_HOST || 'http://localhost:8083',
  tokenManager: {
    expireEarlySeconds: 240, // 4 minutes early
  },
};

const oktaSignInConfig = {
  baseUrl: 'https://himarley-prod.oktapreview.com',
  clientId: process.env.OKTA_CLIENT_ID || '0oaqwvmw9dLBue3iT0h7',
  redirectUri: `${window.location.origin}/login/callback`,
  authParams: {
    // If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to uncomment the below line
    pkce: true,
    issuer:
      process.env.OKTA_ISSUER
      || 'https://himarley-prod.oktapreview.com/oauth2/ausqt450vmoOdOEbT0h7',
    scopes: ['openid', 'profile', 'email'],
  },
  features: {
    idpDiscovery: true,
  },
  idpDiscovery: {
    requestContext: window.location.href,
  },
  logo,
  colors: {
    brand: '#F9685D',
  },
  language: 'en',
  i18n: {
    en: {
      'primaryauth.title': 'Sign in',
      'primaryauth.submit': 'LOGIN',
      'oform.next': 'LOGIN',
      'primaryauth.username.placeholder': 'Email',
      'primaryauth.password.placeholder': 'Password',
    },
  },
  useInteractionCodeFlow: false,
  useClassicEngine: true,
};

export { oktaAuthConfig, oktaSignInConfig, CALLBACK_PATH };
