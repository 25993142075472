import React from 'react'

class DetectBottom extends React.Component {
    state = {
      hasHitBottom: false,
    }

    hasHitBottom = false

    bodyElement = null

    isBottom = (e) => {
      const containerHeight = e.target.clientHeight
      const listHeight = e.target.scrollHeight
      const scrollPosition = e.target.scrollTop
      const bufferPercent = this.props.bufferPercent || 30

      const scrollPercentOfContainer = (scrollPosition + containerHeight) * 100 / listHeight
      const hasHitBottom = scrollPercentOfContainer >= 100 - bufferPercent

      if (this.props.onBottom && hasHitBottom && !this.hasHitBottom) {
        this.props.onBottom(hasHitBottom)
        this.hasHitBottom = true
        console.log('scrollpos', 'hitbottom')
      }

      if (!hasHitBottom && this.hasHitBottom) {
        this.hasHitBottom = false
        console.log('scrollpos', 'reset')
      }
    }

    setRef = (element) => {
      this.bodyElement = element
      if (this.props.setRef && element) this.props.setRef(element)
    }

    render() {
      return (
        <div
          ref={this.setRef}
          className={this.props.className || ''}
          onScroll={this.isBottom}
        >
          {this.props.children}
        </div>
      )
    }
}

export default DetectBottom
