/* eslint-disable react/jsx-filename-extension */
/* eslint-disable max-len */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import Twemoji from 'react-twemoji';

import { formatRedactedMessageWithEmojis } from '@app/helpers/emoji';
import RedactionModal from './RedactionModal';

import MessageFormatter from '../../ChatLog/MessageFormatter/message-formatter';
import { highlightPartOfMessage } from './Redaction.helpers';

import styles from './RedactionPopup.module.less';

/**
 * Popup alerting that redacted text has been clicked.
 * @param {bool} showPopup - toggles popup visibility.
 * @return {ReactComponent} Style element that will replace redaction bubbles '●●●●●'.
 */
const RedactionPopup = ({
  redactionType,
  hasRedactionViewAccess,
  messageFormatSpecificProps,
  showPopup,
  togglePopup,
}) => {
  const {
    isToday,
    todayTimestamp,
    timestamp,
    message,
    orgId,
    getUnredactedMessage,
    unredactedMessage,
    clearUnredactedMessage,
  } = messageFormatSpecificProps;

  const [showUnredactedMessage, setShowUnredactedMessage] = useState(false);

  const msgProps = {
    todayTimestamp,
    timestamp,
    isToday,
    authorName: message?.author?.name || 'User',
    authorId: message?.author?.id || message?.authorId,
    imageUrl: message?.author?.imageUrl,
    messageId: message?.id,
    displayMessage: message?.messageBody || message?.body,
  };

  let modalTitle = 'Please Read!';
  let rightModalBtnText = 'OK';
  let content = (
    <div>
      You do not have permission to view this personally identifiable
      <br />
      information. If this is an error, please contact
      <br />
      support@himarley.com
      <br />
    </div>
  );

  useEffect(() => {
    if (!showPopup && hasRedactionViewAccess) {
      clearUnredactedMessage();
    }
    if (showUnredactedMessage && hasRedactionViewAccess) {
      getUnredactedMessage(orgId, msgProps.messageId, redactionType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUnredactedMessage, showPopup]);

  const toggleShowUnredactedMessage = () => {
    setShowUnredactedMessage(!showUnredactedMessage);
  };

  // changes warning for operators with REDACTION_VIEW_ACCESS permission
  if (hasRedactionViewAccess) {
    rightModalBtnText = 'I Understand';
    content = (
      <>
        By viewing this redacted information, you accept the responsibility and
        understand the sensitivity of this redacted information.
        <div>Only view if it is necessary. Your name will be documented.</div>
      </>
    );
  }

  const formatRedactedMessage = useMemo(
    () => formatRedactedMessageWithEmojis(
      highlightPartOfMessage(unredactedMessage),
    ),
    [unredactedMessage],
  );

  let cancelBtnText = 'Cancel';
  if (hasRedactionViewAccess && showUnredactedMessage) {
    modalTitle = 'Viewing Redacted Text';
    rightModalBtnText = '';
    cancelBtnText = 'Close';
    if (Object.keys(unredactedMessage).length) {
      content = (
        <MessageFormatter
          hasIconBar={false}
          timestamp={
            msgProps.isToday ? msgProps.todayTimestamp : msgProps.timestamp
          }
          authorName={msgProps.authorName}
          authorNameMessage={null}
          authorId={msgProps.authorId}
          isAI={false}
          imageUrl={msgProps.imageUrl}
          messageId={`message-id-${msgProps.messageId}`}
          isRecipient
          displayMessage={msgProps.displayMessage}
        >
          <span data-test="display-message">
            <Twemoji options={{ className: 'emoji' }} key={msgProps.messageId}>
              {formatRedactedMessage}
            </Twemoji>
          </span>
        </MessageFormatter>
      );
    } else {
      content = (
        <div className="redaction-loader">
          <Loader type="Oval" color="#44C7E9" height="50" width="50" />
        </div>
      );
    }
  }

  // resets modal states
  if (showUnredactedMessage && rightModalBtnText === 'Done' && !showPopup) {
    toggleShowUnredactedMessage();
  }

  return (
    <RedactionModal
      title={modalTitle}
      show={showPopup}
      description={content}
      onConfirm={
        hasRedactionViewAccess && !showUnredactedMessage
          ? toggleShowUnredactedMessage
          : togglePopup
      }
      confirmButtonText={rightModalBtnText}
      hideModal={() => {
        togglePopup();
        if (showUnredactedMessage) {
          toggleShowUnredactedMessage();
        }
      }}
      cancelBtnText={cancelBtnText}
      className={
        hasRedactionViewAccess && showUnredactedMessage && styles.modal
      }
    />
  );
};

RedactionPopup.propTypes = {
  showPopup: PropTypes.bool,
  togglePopup: PropTypes.func.isRequired,
  hasRedactionViewAccess: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  messageFormatSpecificProps: PropTypes.object,
  redactionType: PropTypes.string.isRequired,
};

RedactionPopup.defaultProps = {
  showPopup: false,
  messageFormatSpecificProps: {},
};

export default RedactionPopup;
