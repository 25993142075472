import _ from 'lodash';
import {
  UPDATE_PROFILE,
  ADD_USER,
  UPDATED_USER_REQUEST_SUCCESS,
  UPDATED_USER_REQUEST_ERROR,
  UPDATED_USER_REQUEST_CLEAN,
  UPDATED_USER,
  DEAUTH_USER,
  RESET_EDIT_OBJECT,
  CLEAR_USERS,
  UPDATE_LOCK_PROGRESS,
  UPDATE_USER,
  SET_UPDATE_USER_STATUS,
} from '../constants/actions';
import { buildList } from './helpers';
import commonForm from './commonForm';
import User from '../models/user';
import * as commonReducer from './common';
import { formatError, formatInit, formatSuccess } from '../constants/endpoints';

export const initialState = {
  ...commonReducer.initialState,
  list: [],
  operators: [],
  listById: {},
  error: '',
  groups: [],
  errors: [],
  editObject: {},
  lockingUserProgress: {},
};

const buildIndexes = (list) => {
  const listById = {};
  list.forEach((user) => {
    listById[user.id] = user;
  });
  const operators = list.filter(
    (u) => u.role && u.role.toUpperCase() === 'OPERATOR'
  );
  return { listById, operators };
};

const users = (prestate = initialState, action) => {
  const state = commonForm('USER', prestate, action);
  switch (action.type) {
    case UPDATE_PROFILE: {
      const id = action.payload._id;
      const imageUrl = action.payload.imageUrl;
      const listById = Object.assign({}, state.listById);
      if (listById[id]) {
        listById[id] = {
          ...listById[id],
          imageUrl,
        };
      }
      const list = buildList(listById);
      return {
        ...state,
        list,
        ...buildIndexes(list),
      };
    }
    case 'DELETE_USER': {
      const { id } = action.payload;
      const listById = Object.assign({}, state.listById);
      if (listById[id]) {
        listById[id] = {
          ...listById[id],
          archived: true,
        };
      }
      const list = buildList(listById);
      return {
        ...state,
        list,
        ...buildIndexes(list),
      };
    }
    case ADD_USER: {
      const user = action.payload;
      const list = state.list.slice();
      list.push(user);
      return {
        ...state,
        list,
        ...buildIndexes(list),
        error: '',
      };
    }
    case UPDATED_USER_REQUEST_SUCCESS: {
      return { ...state, error: '', requestComplete: true };
    }
    case UPDATED_USER_REQUEST_ERROR: {
      return { ...state, error: action.payload };
    }
    case UPDATED_USER_REQUEST_CLEAN: {
      return { ...state, requestComplete: false, error: '' };
    }
    case UPDATED_USER: {
      const updatedUser = action.payload.id
        ? action.payload
        : new User(action.payload);
      const list = state.list.map((user) => {
        if (user.id === updatedUser.id) {
          return updatedUser;
        } else {
          return user;
        }
      });
      const newState = _.cloneDeep(state);
      // delete newState.form
      return { ...newState, list: [...list], ...buildIndexes(list) };
    }
    case 'REQUEST_NEW_USER_ERROR': {
      const error = _.get(action.payload, 'error', 'Create user error');
      return { ...state, error };
    }
    case 'GET_ORGANIZATION_ROLES': {
      const roles = _.get(action, 'payload.roles');
      return { ...state, groups: _.isArray(roles) ? roles : [] };
    }
    case DEAUTH_USER: {
      return { ...initialState };
    }
    case RESET_EDIT_OBJECT: {
      const payload = _.get(action, 'payload', {});
      const { issuingFormType } = payload;
      const newState = _.cloneDeep(state);
      if (issuingFormType === 'USER') {
        newState.form = {};
        newState.editObject = {};
      }
      return { ...newState };
    }
    case 'CLOSE_CASE_FORM': {
      return {
        ...state,
        form: {
          ...state.form,
          editObject: {},
        },
      };
    }
    case 'CLOSE_CLAIM_FORM': {
      return {
        ...state,
        form: {
          ...state.form,
          editObject: {},
        },
      };
    }
    case 'CLOSE_POLICY_FORM': {
      return {
        ...state,
        form: {
          ...state.form,
          editObject: {},
        },
      };
    }

    case UPDATE_LOCK_PROGRESS: {
      const { payload } = action;
      if (payload.id) {
        return {
          ...state,
          lockingUserProgress: {
            ...state.lockingUserProgress,
            [payload.id]: payload.inProgress,
          },
        };
      }
      return state;
    }

    case 'CLEAR_FORM_ERRORS': {
      return { ...state, errors: [], error: '' };
    }
    case CLEAR_USERS: {
      return { ...state, list: [] };
    }
    case SET_UPDATE_USER_STATUS: {
      return {
        ...state,
        updateUserStatus: action.status,
      };
    }
    case formatInit(UPDATE_USER): {
      return {
        ...state,
        updateUserStatus: 'PENDING',
      };
    }
    case formatSuccess(UPDATE_USER): {
      return {
        ...state,
        updateUserStatus: 'SUCCESS',
      };
    }
    case formatError(UPDATE_USER): {
      return {
        ...state,
        updateUserStatus: 'ERROR',
      };
    }
    default:
      return state;
  }
};

export default commonReducer.reducer('USER', users);
