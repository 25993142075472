import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { topicHandler } from '../../../helpers/event-processor';

const useTopicHandler = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const memoizedTopicHandler = useMemo(
    () => topicHandler(dispatch, queryClient),
    [dispatch, queryClient],
  );
  return memoizedTopicHandler;
};
export default useTopicHandler;
