/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AddForm from '../AddForm/EnterForm' // eslint-disable-line
import FormInput from '../FormInput/FormInput';
import TypeAheadDropdown from '../elements/form/TypeAheadDropdown/TypeAheadDropdown';
import { cleanPhone, formatPhoneNumber } from '../../helpers/format';
import { userType } from '../../models/marleyTypes';

import './EnterOperator.less';

export const inputs = [
  {
    id: 'firstName',
    label: 'First Name',
    errorMessage: 'You must enter a first name',
    required: true,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    errorMessage: 'You must enter a last name',
    required: true,
  },
  {
    id: 'email',
    label: 'Email',
    errorMessage: 'You must enter an email',
    required: true,
  },
  {
    id: 'title',
    label: 'Title',
    required: false,
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    errorMessage: 'You must enter a phone number',
    required: false,
    formatFunction: formatPhoneNumber,
    cleanFunction: cleanPhone,
  },
  {
    id: 'contactNumber',
    label: 'Office Line',
  },
];

const TITLE = 'title';
const filteredInputs = inputs.filter((key) => key.id !== TITLE);

export const requiredFields = inputs.filter((i) => i.required).map((i) => i.id);

class EnterOperator extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inputs: this.props.isTitleEnabled ? inputs : filteredInputs };
  }

  render() {
    const prepPost = (data) => ({
      ...data,
      phoneNumber: cleanPhone(data.phoneNumber),
    });
    return (
      <AddForm
        id="user"
        type={userType}
        title="Operator"
        prepPost={prepPost}
        isCreateOperatorForm
        prepPut={(d) => d}
        requiredFields={requiredFields}
      >
        <div className="enter-operator-title enter-form-title">User Information</div>
        <div className="enter-form-item">
          {this.state.inputs && this.state.inputs.map((input) => (
            input.id === 'title'
              ? (
                <TypeAheadDropdown
                  type={input.id}
                  placeholder="Search or add a title"
                  testId="operators-title"
                  className="enter-user-row"
                  operatorData
                  list={this.props.listTitles}
                />
              )
              : (
                <FormInput
                  key={input.id}
                  tableId="user"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...input}
                />
              )
          ))}
        </div>
        {!this.props.isEdit && !this.props.readonly && (
          <div>
            {this.props.identityProvider === 'MARLEY' ? (
              'The user will receive an activation email to activate their account and sign in.'
            ) : (
              // eslint-disable-next-line react/jsx-no-target-blank
              <>
                <a target="_blank" href="https://himarley.zendesk.com/hc/en-us/articles/360057881753-Is-Single-Sign-On-SSO-right-for-your-organization-" rel="noreferrer">Single-Sign-On (SSO)</a>
                {' '}
                is enabled so once you create this user they will be able to login.
                {' '}
                <br />
                {' '}
                An email will NOT be sent to inform the user they have access.
              </>
            )}
          </div>
        )}
      </AddForm>
    );
  }
}

EnterOperator.propTypes = {
  users: PropTypes.instanceOf(Object),
  listTitles: PropTypes.arrayOf(Object),
  isEdit: PropTypes.bool,
  readonly: PropTypes.bool,
  identityProvider: PropTypes.string,
  isTitleEnabled: PropTypes.bool,
};

EnterOperator.defaultProps = {
  users: {},
  listTitles: [],
  isEdit: false,
  readonly: false,
  identityProvider: 'MARLEY',
  isTitleEnabled: false,
};

const mapStateToProps = (state) => ({
  users: state.users,
  identityProvider: state?.auth?.user?.organization?.identityProvider || 'MARLEY',
  readonly: state?.users?.form?.readonly || false,
  listTitles: state.operators?.listOfTitles,
  isTitleEnabled: (state?.auth?.permissions || []).includes('TITLE_ENABLED'),
});

export default connect(mapStateToProps, null)(EnterOperator);
