import { ROLE_OPERATOR } from '@app/constants/general';
import { LINES_OF_BUSINESS } from '@app/components/Settings/CompanyHierarchy/helpers';

export const defaultRelationships = {
  primaryEndUser: 'Primary Contact',
  primaryOperator: 'Primary Operator',
  secondaryOperator: 'Secondary Operator',
};

export const relationships = [
  {
    label: 'Parent',
    value: 'Parent',
  },
  {
    label: 'Spouse / Partner',
    value: 'Spouse / Partner',
  },
  {
    label: 'Child',
    value: 'Child',
  },
  {
    label: 'Other Family',
    value: 'Other Family',
  },
  {
    label: 'Friend',
    value: 'Friend',
  },
  {
    label: 'Agent',
    value: 'Agent',
  },
  {
    label: 'Vendor',
    value: 'Vendor',
  },
  {
    label: 'Witness',
    value: 'Witness',
  },
  {
    label: 'Attorney',
    value: 'Attorney',
  },
  {
    label: 'Appraiser',
    value: 'Appraiser',
    lobSubTypes: [LINES_OF_BUSINESS.PERSONAL_AUTO.subType],
  },
  {
    label: 'Tow Company',
    value: 'Tow Company',
    lobSubTypes: [LINES_OF_BUSINESS.PERSONAL_AUTO.subType],
  },
  {
    label: 'Body Shop',
    value: 'Body Shop',
    lobSubTypes: [LINES_OF_BUSINESS.PERSONAL_AUTO.subType],
  },
  {
    label: 'Glass Company',
    value: 'Glass Company',
    lobSubTypes: [LINES_OF_BUSINESS.PERSONAL_AUTO.subType],
  },
  {
    label: 'Contractor',
    value: 'Contractor',
    lobSubTypes: [
      LINES_OF_BUSINESS.COMMERCIAL_PROPERTY.subType,
      LINES_OF_BUSINESS.PERSONAL_HOME.subType,
    ],
  },
  {
    label: 'Public Adjuster',
    value: 'Public Adjuster',
    lobSubTypes: [
      LINES_OF_BUSINESS.COMMERCIAL_PROPERTY.subType,
      LINES_OF_BUSINESS.PERSONAL_HOME.subType,
    ],
  },
  {
    label: 'Mitigation Company',
    value: 'Mitigation Company',
    lobSubTypes: [
      LINES_OF_BUSINESS.COMMERCIAL_PROPERTY.subType,
      LINES_OF_BUSINESS.PERSONAL_HOME.subType,
    ],
  },
  {
    label: 'Independent Adjuster',
    value: 'Independent Adjuster',
    lobSubTypes: [
      LINES_OF_BUSINESS.COMMERCIAL_PROPERTY.subType,
      LINES_OF_BUSINESS.PERSONAL_HOME.subType,
    ],
  },
  {
    label: 'Contents Provider',
    value: 'Contents Provider',
    lobSubTypes: [
      LINES_OF_BUSINESS.COMMERCIAL_PROPERTY.subType,
      LINES_OF_BUSINESS.PERSONAL_HOME.subType,
    ],
  },
  {
    label: 'Housing Company',
    value: 'Housing Company',
    lobSubTypes: [
      LINES_OF_BUSINESS.COMMERCIAL_PROPERTY.subType,
      LINES_OF_BUSINESS.PERSONAL_HOME.subType,
    ],
  },
  {
    label: 'Injured Worker',
    value: 'Injured Worker',
    lobSubTypes: [LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP.subType],
  },
  {
    label: 'Employer',
    value: 'Employer',
    lobSubTypes: [LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP.subType],
  },
  {
    label: 'Nurse Case Manager',
    value: 'Nurse Case Manager',
    lobSubTypes: [LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP.subType],
  },
  {
    label: 'Medical Provider',
    value: 'Medical Provider',
    lobSubTypes: [LINES_OF_BUSINESS.COMMERCIAL_WORKERS_COMP.subType],
  },
];

export const setRelationship = (participant, jobId, primaryEndUserId, primaryOperatorId) => {
  if (participant?.id === primaryOperatorId) {
    return {
      ...participant,
      relationship: defaultRelationships.primaryOperator,
    };
  }

  if (participant.role === ROLE_OPERATOR) {
    return {
      ...participant,
      relationship: defaultRelationships.secondaryOperator,
    };
  }

  if (participant?.id === primaryEndUserId) {
    return {
      ...participant,
      relationship: defaultRelationships.primaryEndUser,
    };
  }

  return {
    ...participant,
    relationship: participant?.relationships?.[jobId] || '',
  };
};

export const setRelationshipFromList = (
  participants = [],
  jobId,
  primaryEndUserId,
  primaryOperatorId,
) => participants.map(
  (participant) => setRelationship(participant, jobId, primaryEndUserId, primaryOperatorId),
);
