import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "./AutoReplies.less";
import OfficeHours from "./OfficeHours/OfficeHours";
import GeneralAutoReplies from "./GeneralAutoReplies/GeneralAutoReplies";
import { makeApiRequest } from "../../../actions/api/api";
import selector from "../../../selectors/autoReply";
import { defaultOfficeHoursReply, defaultGeneralAutoReply } from "./Utils";

const AutoReplies = () => {
  // explanation for use of shallowEqual in this article https://thoughtbot.com/blog/using-redux-with-react-hooks
  const {
    id,
    initializeAutoReply,
    generalAutoReplies,
    oooReplies,
    error
  } = useSelector((state) => selector(state), shallowEqual);
  const dispatch = useDispatch();
  
  // need to create deep clone of default reply objects or they will be changed when set
  const defaultOH = JSON.parse(JSON.stringify(defaultOfficeHoursReply)) 
  const defaultAR = JSON.parse(JSON.stringify(defaultGeneralAutoReply)) 
  
  useEffect(() => {
    dispatch(makeApiRequest('GET_USERS_AUTOREPLIES', [id]));
  }, [id]);

  return (
    <div className="auto-replies">
      <GeneralAutoReplies
        isLoading={initializeAutoReply}
        userId={id}
        reply={defaultAR}
        generalAutoReplies={generalAutoReplies.list}
        error={error}
      />
      <OfficeHours
        isLoading={initializeAutoReply}
        userId={id}
        reply={defaultOH}
        oooReplies={oooReplies.list}
        error={error}
      />
    </div>
  );
};

export default AutoReplies;
