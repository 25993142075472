import React, { useState } from "react";
import LineDropdown from "../../../../AssignDropdown/LineDropdown/LineDropdown";
import "./TimeOutDropdown.less";
import _ from "lodash";

const TimeOutDropdown = ({ reply, setReply }) => {
  const [timeout, setTimeoutValue] = useState(_.get(reply, 'sendCriteria.minutesInactiveThreshold', 120));
  const [isTimeoutDropdownOpen, setIsTimeoutDropdownOpen] = useState(false);
  const timeoutOptions = [
    { id: 120, label: "2 hours" },
    { id: 90, label: "1.5 hours" },
    { id: 60, label: "1 hour" },
    { id: 45, label: "45 minutes" },
    { id: 30, label: "30 minutes" },
    { id: 20, label: "20 minutes" },
    { id: 15, label: "15 minutes" },
    { id: 10, label: "10 minutes" },
    { id: 1, label: "1 minute"}
  ];

  const handleSelectTimeOut = (e) => {
    setTimeoutValue(e);
    setIsTimeoutDropdownOpen(!isTimeoutDropdownOpen);
    setReply({
      ...reply,
      sendCriteria: {
        ...reply.sendCriteria,
        minutesInactiveThreshold: e
      }
    })
  };

  return (
    <div className="timeout-container">
      <LineDropdown
        className="timeout-dropdown"
        defaultLabel={"15 mins"}
        options={timeoutOptions}
        open={isTimeoutDropdownOpen}
        onToggle={(value) => setIsTimeoutDropdownOpen(value)}
        handleSelect={(e) => handleSelectTimeOut(e)}
        selectedId={timeout}
      />
    </div>
  );
};

export default TimeOutDropdown;
