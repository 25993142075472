import React, { useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import { getBrowserCheckCookie, saveBrowserCheckCookie } from '../helpers/cookies'
import { Routes } from './Routes/Routes'
import ErrorBoundary from './ErrorBoundary/ErrorBoundary'
import ErrorSplashScreen from './ErrorBoundary/ErrorSplashScreen'

const IEWarning = () => (
  <Alert data-test="ie-warning" className="ie-warning">
    Hey there, it looks like you&apos;re using an unsupported browser, please visit
    {' '}
    <a href="https://chat.marley.ai">chat.marley.ai</a>
    {' '}
    using your Chrome browser.
  </Alert>
)

const Root = () => {
  const [isChrome, setIsChrome] = useState(getBrowserCheckCookie() || (!!window.chrome))

  useEffect(() => {
    saveBrowserCheckCookie(true)
    if (isChrome) setIsChrome(isChrome)
  }, [isChrome])

  const ua = window.navigator.userAgent
  const isIE = ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1

  return (
    <div className="root">
      <ErrorBoundary errorMessage={<ErrorSplashScreen />}>
        <BrowserRouter>
          {isIE ? <IEWarning /> : <Routes />}
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  )
}

export default Root
