import React from 'react';
import PropTypes from 'prop-types';
import styles from './AddTotalLossRep.module.less';
import Button from '../../../../Form/Button/Button';
import copartLogo from '../../../../../../images/logos/logoCopart.png';
import MarleyAssistantMessage from '../MarleyAssistantMessage/MarleyAssistantMessage';

const AddTotalLossRep = ({
  onDismiss,
  onNotify,
}) => (
  <MarleyAssistantMessage>
    <div className={styles.box}>
      <div className={styles.logo}>
        <img src={copartLogo} alt="Copart logo" />
      </div>
      <div>
        <div className={styles.title}>Notify Copart Coordinator</div>
        <div className={styles.body}>
          Would you like to add a total loss representative to this claim and notify them?
        </div>
      </div>
    </div>
    <div className={styles.buttons}>
      <Button
        className={styles['left-button']}
        onClick={onDismiss}
        type="outline"
      >
        No Thanks
      </Button>
      <Button
        onClick={onNotify}
        type="black"
      >
        Notify Copart Coordinator
      </Button>
    </div>
  </MarleyAssistantMessage>
);

AddTotalLossRep.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  onNotify: PropTypes.func.isRequired,
};

export default AddTotalLossRep;
