import React from 'react'
import PropTypes from 'prop-types'
import SortColumn from '../../SortColumn/SortColumn'

const TableHeader = ({
  className, type, tableId, sort, id, label, location, sortLocation, numericSort,
}) => {
  const tid = type ? type.id() : tableId
  const column = sort ? (
    <SortColumn
      className={`${id || ''}`}
      label={label}
      location={sortLocation || location}
      tableId={tid}
      numericSort={numericSort}
    />
  ) : (<div className={`${id || ''} table-column`}>{label}</div>)

  return <div className={`table-col table-col-${id} ${className || ''}`}>{column}</div>
}

TableHeader.propTypes = {
  tableId: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  sort: PropTypes.bool,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(String)]),
}

TableHeader.defaultProps = {
  id: '',
  label: '',
  tableId: '',
  sort: false,
  location: '',
}

export default TableHeader
