import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import DropdownIconDown from '../../../../../images/icons/dropdown-arrow--down.svg';
import './Dropdown.less';
import { PropTypes } from 'prop-types';

/**
 * Generic Dropdown element
 *  - when an action is taken on the DropItem, the dropdown menu (props.children) is shown
 * @param   {boolean} show toggles display of the selection menu
 * @param   {Component} DropItem item that drops
 * @param   {boolean} multiSelect if true, menu doesn't close on selection
 * @param   {string} openAction type of action that toggles dropdown, e.g. click or focus
 * @param   {string} className passthrough of css class
 * @return  {Component}
 */
const Dropdown = ({
  id,
  children,
  show,
  DropItem,
  multiSelect,
  openAction,
  className,
  onToggleOpen,
  onBlur,
  position,
  showArrowIcon,
  onClick,
  isDisabled,
  displayDropDownIcon,
}) => {
  const [open, setOpen] = useState(false);
  const componentRef = useRef(null);
  const menuRef = useRef(null);

  const handleClick = useCallback(
    (e) => {
      if (componentRef.current && componentRef.current.contains(e.target)) {
        return; // click inside the component
      }
      if (menuRef.current && menuRef.current.contains(e.target)) {
        return; // click inside the component
      }
      if (onToggleOpen) onToggleOpen(false);
      setOpen(false); // click outside the component
    },
    [onToggleOpen],
  );

  // Adds listeners to detect clicks outside of the component
  useEffect(() => {
    if (open) {
      if (onToggleOpen) onToggleOpen(open);
      document.addEventListener('click', handleClick, true);
    } else {
      if (onToggleOpen) onToggleOpen(open);
      document.removeEventListener('click', handleClick, true);
    }
    return () => {
      if (onToggleOpen) onToggleOpen(true);
      document.removeEventListener('click', handleClick, true);
    };
  }, [handleClick, onToggleOpen, open]);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleAction = () => {
    if (openAction === 'click') {
      if (onToggleOpen) onToggleOpen(!open);
      setOpen(!open);
    }
  };

  const handleLinkClick = () => {
    onClick();
    setOpen(!open);
    if (onToggleOpen) onToggleOpen(show);
  };

  const handleLinkBlur = () => {
    if (onBlur) {
      onBlur();
    } else {
      handleAction(true);
    }
  };

  return (
    <div ref={componentRef} className={`${className || ''} no-select marley-dropdown ${isDisabled && 'dropdown-disabled'}`}>
      <div
        className="marley-dropdown-link"
        data-test={id}
        data-testid={id}
        onBlur={handleLinkBlur}
        onClick={handleLinkClick}
        onKeyPress={handleLinkClick}
        role="button"
        tabIndex={id}
      >
        <span
          style={{ flex: 1 }}
          onKeyPress={handleClick}
          role="button"
          tabIndex={id}
          onClick={handleClick}
        >
          {DropItem}

        </span>
        {showArrowIcon && (
          <span
            className={`${open ? 'marley-dropdown-open' : 'marley-dropdown-closed'} marley-dropdown-caret`}
          >
            {displayDropDownIcon && <DropdownIconDown />}
          </span>
        )}
      </div>
      {open && (
        <div ref={menuRef} className="marley-dropdown-container">
          <div
            onKeyPress={() => { if (!multiSelect) setOpen(false); }}
            tabIndex={id}
            role="button"
            onClick={() => { if (!multiSelect) setOpen(false); }}
            className={`marley-dropdown-container-inner ${position === 'left' ? 'leftPosition' : 'rightPosition'}`}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
  DropItem: PropTypes.element.isRequired,
  multiSelect: PropTypes.bool,
  openAction: PropTypes.string,
  className: PropTypes.string,
  onToggleOpen: PropTypes.func,
  onBlur: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
  showArrowIcon: PropTypes.bool,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  displayDropDownIcon: PropTypes.bool,
};

Dropdown.defaultProps = {
  id: '',
  children: null,
  multiSelect: false,
  openAction: 'click',
  className: '',
  onToggleOpen: null,
  onBlur: null,
  position: 'right',
  showArrowIcon: true,
  onClick: () => {},
  isDisabled: false,
  displayDropDownIcon: true,
};

export default Dropdown;
