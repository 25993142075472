import React from 'react'
import Title from '../Title/Title'
import Text from '../Text/Text'

const HeaderText = ({ title, titleType, text }) => (
    <div className="header-text">
        <Title type={titleType || 'lg'}>{title}</Title>
        <Text type={'primary'}>{text}</Text>
    </div>
)

export default HeaderText