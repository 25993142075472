import React from 'react'

const withModal = defaultState => Component => class ComponentWithModal extends React.Component {
  state = {
    ...defaultState,
    showModal: false,
  }

  toggleModal = toggle => {
    if (typeof toggle === 'boolean') {
      this.setState({ showModal: toggle })
    } else {
      this.setState({ showModal: !this.state.showModal })
    }
  }

  render() {

    const Modal = React.cloneElement(
      this.state.Modal,
      {
        show: this.state.showModal,
        toggleModal: this.toggleModal,
        pageProps: this.props,
      },
    )

    return (
      <div style={{ height: '100%' }} className="panelWithModal">
        {Modal}
        <Component {...this.props} show={this.state.showModal} toggleModal={this.toggleModal} />
      </div>
    )
  }
}

export default withModal
