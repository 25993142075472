/**
 * Use Amazon Translate's documentation to get the correct ids
 * https://docs.aws.amazon.com/translate/latest/dg/what-is-languages.html
 */
export const languageList = [
  {
    id: 'en',
    label: 'English (Default)',
  },
  {
    id: 'es',
    label: 'Spanish',
  },
  {
    id: 'ar',
    label: 'Arabic',
  },
  {
    id: 'sq',
    label: 'Albanian',
  },
  {
    id: 'bs',
    label: 'Bosnian',
  },
  {
    id: 'zh',
    label: 'Chinese',
  },
  {
    id: 'hr',
    label: 'Croatian',
  },
  {
    id: 'fr',
    label: 'French',
  },
  {
    id: 'fr-ca',
    label: 'French Canadian',
  },
  {
    id: 'de',
    label: 'German',
  },
  {
    id: 'ht',
    label: 'Haitian Creole',
  },
  {
    id: 'hi',
    label: 'Hindi',
  },
  {
    id: 'it',
    label: 'Italian',
  },
  {
    id: 'ja',
    label: 'Japanese',
  },
  {
    id: 'ko',
    label: 'Korean',
  },
  {
    id: 'pl',
    label: 'Polish',
  },
  {
    id: 'pt',
    label: 'Portuguese',
  },
  {
    id: 'ru',
    label: 'Russian',
  },
  {
    id: 'so',
    label: 'Somali',
  },
  {
    id: 'th',
    label: 'Thai',
  },
  {
    id: 'tl',
    label: 'Tagalog',
  },
  {
    id: 'tr',
    label: 'Turkish',
  },
  {
    id: 'uk',
    label: 'Ukrainian',
  },
  {
    id: 'ur',
    label: 'Urdu',
  },
  {
    id: 'vi',
    label: 'Vietnamese',
  },
];

export const languagesById: {
  [key: string]: string
} = languageList.reduce((allLanguagesKeyedById, languageItem) => ({
  ...allLanguagesKeyedById,
  [languageItem.id]: languageItem.label,
}), {});

export const getLanguageStringById = (code?: string) => {
  const languageObject = languageList.find(({ id }) => id === code);
  return languageObject?.label || null;
};
