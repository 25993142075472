/* eslint-disable jsx-a11y/media-has-caption */
import {
  PhoneCallIcon,
} from '@himarley/unity';
import React from 'react';

import styles from './voice-message.module.scss';

interface ForwardedCallEventProps {
  messageBody?: string;
}

const ForwardedCallEvent = ({ messageBody }
: ForwardedCallEventProps): JSX.Element => {
  const grey = '#9AA5B1';
  const phoneIcon = <PhoneCallIcon width={32} height={32} color={grey} />;
  return (
    <div className={styles.voiceMessage}>
      <div className={styles.messageInfo}>
        <span className={styles.callIcon}>{phoneIcon}</span>
        <div className={styles.callInfo}>
          <div className={styles.callType}>Call Forwarded</div>
          <div className={styles.callDetails}>{messageBody}</div>
        </div>
      </div>
    </div>
  );
};

export default ForwardedCallEvent;
