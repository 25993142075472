import * as commonReducer from './common';

/*
  this reducer is required for when searching/filtering groups in
  manage tab>groups>create group> groups tab so the groups table underneath doesn't also
  get filtered out
*/

export const initialState = {
  list: [],
  listById: {},
};

const reducer = (state = initialState) => state;

export default commonReducer.reducer('SUBGROUP', reducer);
