/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import MediaMetadata from './MediaMetadata';
import CloseIcon from '../../../../images/icons/close.svg';
import ArrowLeftIcon from '../../../../images/icons/arrow-btn-left.svg';
import ArrowRightIcon from '../../../../images/icons/arrow-btn-right.svg';
import './MediaModal.less';
import MediaContainer from '../MediaContainer';
import MediaToolbar from '../MediaToolbar';
import { mediaItemPropTypes } from '../media-helpers';

const MediaModal = ({
  localFileUrl,
  mediaItem,
  selectedMediaIndex,
  mediaCount,
  moveToRightMedia,
  moveToLeftMedia,
  closeModal,
}) => (
  <div className="full-screen-media-modal" data-testid="full-screen-media-modal">
    <div className="full-screen-media-modal-header">
      <button
        className="full-screen-media-button full-screen-media-close-button"
        data-testid="full-screen-media-close-button"
        type="button"
        onClick={() => closeModal()}
      >
        <CloseIcon />
      </button>
      <div className="full-screen-media-action-button-container">
        { !mediaCount ? null : (
          <div
            className="full-screen-media-count"
            data-testid="full-screen-media-count"
          >
            {`${selectedMediaIndex + 1}/${mediaCount}`}
          </div>
        )}
        <MediaToolbar
          mediaItem={mediaItem}
          localFileUrl={localFileUrl}
          isInlineControls
        />
      </div>
    </div>
    <div className="full-screen-media-middle-container">
      <div className="full-screen-media-next-image-arrow">
        { selectedMediaIndex > 0 && (
          <button
            className="full-screen-media-button"
            data-testid="full-screen-media-button-arrow-left"
            onClick={() => moveToLeftMedia(selectedMediaIndex)}
            type="button"
          >
            <ArrowLeftIcon />
          </button>
        )}
      </div>
      <MediaContainer
        localFileUrl={localFileUrl}
        mediaItem={mediaItem}
      />
      <div className="full-screen-media-next-image-arrow">
        { selectedMediaIndex + 1 < mediaCount && (
          <button
            className="full-screen-media-button"
            data-testid="full-screen-media-button-arrow-right"
            onClick={() => moveToRightMedia(selectedMediaIndex)}
            type="button"
          >
            <ArrowRightIcon />
          </button>
        )}
      </div>
    </div>
    <div className="full-screen-media-container-padding-bottom" />
    <div className="full-screen-media-modal-footer">
      <MediaMetadata mediaItem={mediaItem} selectedMediaIndex={selectedMediaIndex} />
    </div>
  </div>
);

MediaModal.propTypes = {
  localFileUrl: PropTypes.string.isRequired,
  mediaItem: mediaItemPropTypes.isRequired,
  selectedMediaIndex: PropTypes.number.isRequired,
  mediaCount: PropTypes.number.isRequired,
  moveToRightMedia: PropTypes.func.isRequired,
  moveToLeftMedia: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default MediaModal;
