import { createSelector } from '@reduxjs/toolkit';
import {
  EVENT_TYPES,
  RELEASE_ISSUE_STATE_COPART_ASSIGNED,
  RELEASE_ISSUE_STATE_OPENED,
  RELEASE_ISSUE_STATE_WAITING_FOR_RELEASE,
  PROGRESS_STEP_STATUS_COMPLETE,
  PROGRESS_STEP_STATUS_IN_PROGRESS,
  PROGRESS_STEP_STATUS_NOT_STARTED,
  RELEASE_STATUS,
} from '../../constants/integration-constants';

const copartAssignedState = (isVehicleAtYard, releaseIssues) => {
  if (isVehicleAtYard) return PROGRESS_STEP_STATUS_COMPLETE;
  return (!releaseIssues.length ? PROGRESS_STEP_STATUS_IN_PROGRESS : PROGRESS_STEP_STATUS_COMPLETE);
};

const releaseIssueText = (releaseIssues) => {
  if (!releaseIssues.length) return '';
  return `${releaseIssues.length} Release Issue${releaseIssues.length > 1 ? 's' : ''}`;
};
const waitingForReleaseState = (isVehicleAtYard, releaseIssues) => {
  if (isVehicleAtYard) return PROGRESS_STEP_STATUS_COMPLETE;
  if (!releaseIssues.length) return PROGRESS_STEP_STATUS_NOT_STARTED;
  return PROGRESS_STEP_STATUS_IN_PROGRESS;
};

const releaseReadableEventLogs = (eventLog) => {
  // we can add other events here once we start recieving updates
  const releaseIssueEvents = [
    EVENT_TYPES.RELEASE_COMPLETED,
    EVENT_TYPES.RELEASE_UPDATED,
    EVENT_TYPES.RELEASE_RESOLVED,
  ];
  const filteredEvents = eventLog?.filter((log) => releaseIssueEvents.includes(log.type));
  const formattedEvents = filteredEvents?.reduce((acc, entry) => {
    entry.params.issues.forEach((issue) => {
      const { id, comment } = issue;
      const { eventTime, actorId = '', type } = entry;
      acc[id] = acc[id] || [];
      acc[id].push({
        comment, eventTime, actorId, type, id,
      });
    });
    return acc;
  }, {});
  return formattedEvents || {};
};

const selectEventLog = ({ jobs }) => jobs.activeJob?.eventLog || [];

const selectReleaseIssues = ({ jobs }) => jobs.activeJob?.releaseIssues || [];

const selectIsOpen = ({ jobs }) => jobs.activeJob?.isOpen;

const selectAssignedOperator = ({ jobs }) => jobs.activeJob?.assignedOperator;

const generateReleaseEvents = (eventLog, releaseIssues) => releaseIssues.map((issue) => {
  const events = releaseReadableEventLogs(eventLog)[issue.id];
  const hasRejectedEvent = events?.some((event) => event.type === EVENT_TYPES.RELEASE_UPDATED);
  if (hasRejectedEvent && issue.status === RELEASE_STATUS.ACTIVE) {
    // TODO: Align this logic with total_loss.release_issue.updated server side work.
    return { ...issue, events, rejected: true };
  }
  return {
    ...issue,
    ...(events && { events }),
  };
});

const generateCaseProgress = (isOpen, assignedOperator, eventLog, releaseIssues) => {
  const isVehicleAtYard = eventLog?.some((event) => event.type === EVENT_TYPES.VEHCHKIN);

  const statesList = [
    {
      title: RELEASE_ISSUE_STATE_OPENED,
      key: 'opened',
      status: PROGRESS_STEP_STATUS_COMPLETE,
      subText: `Assigned to ${assignedOperator.name}`,
    },
    {
      title: RELEASE_ISSUE_STATE_COPART_ASSIGNED,
      key: 'copartAssigned',
      status: copartAssignedState(isVehicleAtYard, releaseIssues),
    },
    {
      title: RELEASE_ISSUE_STATE_WAITING_FOR_RELEASE,
      key: 'waitingForRelease',
      status: waitingForReleaseState(isVehicleAtYard, releaseIssues),
      subText: releaseIssueText(releaseIssues),
      showButton: !!releaseIssues.length,
    },
    {
      title: 'Vehicle at Yard',
      key: 'vehicleAtYard',
      status: isVehicleAtYard ? PROGRESS_STEP_STATUS_IN_PROGRESS : PROGRESS_STEP_STATUS_NOT_STARTED,
    },
    {
      title: 'Closed',
      key: 'closed',
      status: isOpen ? PROGRESS_STEP_STATUS_NOT_STARTED : PROGRESS_STEP_STATUS_COMPLETE,
    },
  ];

  const currentStep = statesList.find((state) => state.status === PROGRESS_STEP_STATUS_IN_PROGRESS);

  return {
    currentStep: currentStep?.title || RELEASE_ISSUE_STATE_OPENED,
    steps: statesList,
  };
};

export default createSelector(
  selectIsOpen,
  selectAssignedOperator,
  selectEventLog,
  selectReleaseIssues,
  (isOpen, assignedOperator, eventLog, releaseIssues) => ({
    ...generateCaseProgress(isOpen, assignedOperator, eventLog, releaseIssues),
    releaseEvents: generateReleaseEvents(eventLog, releaseIssues),
  }),
);
