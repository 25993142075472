
import React from 'react'
import PropTypes from 'prop-types'
import './Button.less'

const types = {
  primary: 'primary',
  secondary: 'secondary',
  red: 'red',
  blue: 'blue',
  error: 'error',
  pending: 'pending',
  default: 'default',
  outline: 'outline',
  link: 'link',
  black: 'black',
  whiteRounded: 'whiteRounded',
  darkBlueRounded: 'darkBlueRounded',
}

const Button = ({ children, type, className, ...other }) => {
  const buttonType = types[type] || 'primary'
  return (
    <button {...other} data-test="button-test" className={`hymarley-button ${className} ${buttonType}`}>
      {children}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}
Button.defaultProps = {
  className: '',
  type: 'primary',
  children: null,
}

export default Button
