export const EXPECTED_RESPONSE_TIME = 24 * 60 * 60;
export const DEADLINE_GREEN_SECS = 3 * 60 * 60;
export const DEADLINE_RED_SECS = 1 * 60 * 60;

export const MARLEY_STATUS_RESPONSE = process.env.MARLEY_STATUS_RESPONSE || 'The status of your claim is $1'; // This doesnt look like it's being used

const region = typeof process.env.REGION !== 'undefined' ? process.env.REGION : 'us-east-1';
export const API_HOST = `https://${process.env.API_BASE_REST_ID}.execute-api.${region}.amazonaws.com/dev/`;

export const FILTER_DEBOUNCE = 500;
