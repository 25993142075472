import { defineStyleConfig } from '@chakra-ui/react';

export const selectTheme = defineStyleConfig({
  baseStyle: {
    field: {
      borderRadius: 'base',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
});

export default selectTheme;
