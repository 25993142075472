import _ from 'lodash'
import User from './user'
import { formatDate } from '../helpers/datetime'

class Policy {
  constructor(policy) {
    this.raw = policy
    this.id = policy._id || policy.id
    this.deductible = policy.deductible
    this.policyNumber = policy.policyNumber || policy.policynumber
    const dateCreated = _.get(policy, 'dateCreated', '')
    this.dateCreated = dateCreated ? formatDate(dateCreated, 'just-date') : ''
    this.ageOfDwelling = policy.ageOfDwelling
    this.companyName = policy.companyName
    this.policyType = policy.policyType
    this.address = policy.address
    this.insurer = policy.insurer
    this.insured = policy.identity && new User({
      lastName: _.get(policy, 'identity.profile.lastName'),
      firstName: _.get(policy, 'identity.profile.firstName'),
      phoneNumber: _.get(policy, 'identity.profile.phoneNumber'),
      email: _.get(policy, 'identity.email')
    }) || policy.insured && new User({
      lastName: _.get(policy.insured, 'last'),
      firstName: _.get(policy.insured, 'first'),
      phoneNumber: _.get(policy.insured, 'mobile'),
      email: _.get(policy.insured, 'email'),
    })
    this.claimant = this.insured
    this.insuredId = _.get(policy, 'identityId')
      || _.get(policy, 'insuredId') || _.get(policy, 'insured.id')
    const primaryContact = Object.assign(_.get(policy, 'primaryContact') || {}, {
      firstName: _.get(policy, 'primaryContact.first') || _.get(policy, 'primaryContact.firstName'),
      lastName: _.get(policy, 'primaryContact.last') || _.get(policy, 'primaryContact.lastName'),
    })
    this.primaryContact = new User(primaryContact)
    this.primaryContactId = _.get(policy, 'primaryContactId')
      || _.get(policy, 'claims.0.primaryContact.id', '')
    this.branding = _.get(policy, 'branding')
    this.contractTerm = _.get(policy, 'contractTerm.effectiveDate', false) && _.get(policy, 'contractTerm.expirationDate', false) ? {
      effectiveDate: formatDate(policy.contractTerm.effectiveDate, 'just-date'),
      expirationDate: formatDate(policy.contractTerm.expirationDate, 'just-date')
    } : null
  }
}

export default Policy
