import _ from 'lodash'

class Key {
  constructor(template) {
    this.raw = template

    this.key = _.get(template, 'key', '')
    this.type = _.get(template, 'type', '')
  }
}

export default Key
