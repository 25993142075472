/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { Button, Dropdown, Panel } from 'react-bootstrap';
import SearchBar from '../SearchBar/SearchBar';
import withLazyLoad from '../withLazyLoad/withLazyLoad';
import withLoading from '../HigherOrderComponents/withLoading';
import { operatorType } from '../../models/marleyTypes';
import './AssignUserDropdown.less';
import SearchOperators from '../SearchSelect/SearchOperator/SearchOperators';
import { pluralize } from '../../helpers/common';

class AssignUserDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      dropdownOpen: false, defaultView: true, inputElement: null,
    };
    this.onSearchTextChange = this.onSearchTextChange.bind(this);
  }

  // Adds listeners to detect clicks outside of the component
  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, true);
  }

  handleOutsideClick = (e) => {
    // eslint-disable-next-line max-len
    if (this.componentRef.current && this.componentRef.current.contains(e.target)) return; // click inside the component
    this.setState({ dropdownOpen: false }); // click outside the component
  };

  onSearchTextChange = (e) => {
    const { modifyQuery } = this.props;
    const searchText = _.get(e, 'target.value');
    modifyQuery({ searchText });
    this.setState({ defaultView: searchText.length === 0, inputElement: searchText });
  };

  render() {
    const {
      authId, firstName, lastName, disabled, error, recentlyAssignedOperators,
      className, selectedId, id, defaultLabel, loadedItems, options, handleSelect, authEmail,
      authRole, imageUrl, authTitle,
    } = this.props;
    const { defaultView, inputElement, dropdownOpen } = this.state;
    const me = firstName && lastName ? `${firstName} ${lastName} (me)` : 'Me';
    // eslint-disable-next-line max-len
    const assignedOperatorsList = options.map((loadedItem) => (loadedItem.id === authId ? { ...loadedItem, label: me } : loadedItem));
    const operatorList = loadedItems.map(loadedItem => loadedItem.id === authId ? { ...loadedItem, label: me } : loadedItem) // eslint-disable-line
    const defaultSelectedOperator = assignedOperatorsList.find((option) => option.id === selectedId);

    const dropdownLabelItems = [
      {
        id: 'unassigned',
        label: 'Mark as Unassigned',
      },
      {
        id: authId,
        label: `Assign to ${me}`,
        email: authEmail,
        role: authRole,
        title: authTitle,
        imageUrl,
      },
    ];

    return (
      <div ref={this.componentRef} className={`assign-user-dropdown ${className}`}>
        <Dropdown
          id={id}
          open={dropdownOpen}
          onToggle={() => {}}
          pullRight
        >
          <Button
            bsRole="toggle"
            className={`assign-user-dropdown-button ${className} ${error && 'has-error'} btn-dropdown disabled`}
            disabled={disabled}
            onClick={() => this.setState({ dropdownOpen: !dropdownOpen })}
          >
            {
              defaultSelectedOperator
                ? (<div className="assign-user-dropdown-button-selected">{defaultSelectedOperator.label}</div>)
                : (<div className="assign-user-dropdown-button-selected">{defaultLabel}</div>)
            }
            <span className="caret" />
          </Button>
          <Dropdown.Menu>
            <Panel bsRole="menu" className={className}>
              <SearchBar
                onChange={this.onSearchTextChange}
                placeholder="Search Operators"
              />
              <SearchOperators
                auth={authId}
                defaultView={defaultView}
                inputElement={inputElement}
                defaultSelectedOperator={defaultSelectedOperator || { id: 'unassigned' }}
                dropdownLabelItems={dropdownLabelItems}
                operatorList={operatorList.filter((o) => o.id && o.name)}
                recentlyAssignedOperators={recentlyAssignedOperators}
                selectAction={(e) => {
                  handleSelect(e);
                  this.setState({
                    dropdownOpen: false,
                  });
                }}

              />
            </Panel>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

AssignUserDropdown.propTypes = {
  options: PropTypes.arrayOf(Object),
  id: PropTypes.string,
  defaultLabel: PropTypes.string,
  disabled: PropTypes.bool,
  loadedItems: PropTypes.arrayOf(Object),
  modifyQuery: PropTypes.func.isRequired,
  selectedId: PropTypes.string,
  handleSelect: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.bool,
  authId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  recentlyAssignedOperators: PropTypes.array,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  authEmail: PropTypes.string,
  authRole: PropTypes.string,
  authTitle: PropTypes.string,
  imageUrl: PropTypes.string,
};

AssignUserDropdown.defaultProps = {
  loadedItems: [],
  options: [],
  error: false,
  id: '',
  authId: '',
  className: '',
  firstName: '',
  lastName: '',
  disabled: false,
  handleSelect: () => {},
  defaultLabel: '',
  selectedId: undefined,
  authEmail: '',
  authRole: '',
  imageUrl: '',
  authTitle: '',
};

export const mapStateToProps = (state, ownProps) => {
  const id = ownProps.id === 'policy' ? pluralize(ownProps.id) : ownProps.id;
  return {
    type: operatorType,
    recentlyAssignedOperators: _.concat(_.get(state.auth, 'user', {}), _.get(state, `profile.properties.recentlyAssignedOperators.${id}`, []))
      // eslint-disable-next-line no-underscore-dangle
      .map((operator) => ({
        id: operator.id || operator._id, label: operator.name || `${operator.firstName} ${operator.lastName}`, email: operator.email, title: operator.title, role: operator.role, imageUrl: operator.imageUrl,
      })),
    authId: _.get(state, 'auth.user._id', 'me'),
    authEmail: _.get(state, 'auth.user.email', ''),
    authRole: _.get(state, 'auth.user.role', ''),
    authTitle: _.get(state, 'auth.user.title', ''),
    imageUrl: _.get(state, 'auth.user.imageUrl', ''),
    firstName: _.get(state, 'profile.firstName', 'me'),
    lastName: _.get(state, 'profile.lastName', 'me'),
  };
};

const AssignedUsersWithLoading = withLoading(AssignUserDropdown, { type: operatorType });
const LoadedAssignedUsersDropdown = withLazyLoad(
  AssignedUsersWithLoading,
  {
    type: operatorType,
    sort: { column: 'name', order: 'DESC' },
    listLocation: 'users',
    disableInitialFetch: true,
  },
);
export default connect(mapStateToProps)(LoadedAssignedUsersDropdown);
