import React from 'react'
import PropTypes from 'prop-types'
import Required from '../../icons/Required'
import './FormField.less'

class FormField extends React.Component {

  focusControl() {
    if (this.textInput) {
      this.textInput.focus()
    } else if (this.props.textInput) {
      this.props.textInput.focus()
    }
  }

  render() {
    const {
      componentClass, className, required, error, label, placeholder,
      help, showHelp, control, addOn, textInput, ...other
    } = this.props
    return (
      <div className={`form-field ${className}`} >
        <div onClick={() => this.focusControl()} className={`form-field-label ${error ? 'error' : ''}`}>
          {label}{required && <Required />}
        </div>
        <div className="form-field-value-wrap">
          <div className="form-field-prefix">{addOn}</div>
          {control || <input placeholder={placeholder} ref={(e) => { this.textInput = e }} className={`input-field ${componentClass} ${error ? 'error' : ''}`} {...other} />}
        </div>
        {(error || showHelp) && <div className={`help-message ${error ? 'error-message' : ''}`}>
          {help}
        </div>}
      </div>
    )
  }
}

FormField.propTypes = {
  label: PropTypes.string,
  help: PropTypes.string,
  control: PropTypes.node,
  showHelp: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  required: PropTypes.bool,
  className: PropTypes.string,
  componentClass: PropTypes.string,
  addOn: PropTypes.string,
  placeholder: PropTypes.string,
  textInput: PropTypes.node,
}
FormField.defaultProps = {
  className: '',
  componentClass: '',
  label: '',
  control: null,
  required: false,
  help: '',
  addOn: '',
  placeholder: '',
  showHelp: false,
  error: null,
  textInput: null,
}

export default FormField
