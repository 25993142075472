import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CollapsibleSection.less';

const CollapsibleSection = ({
  toggleShow: toggleShowProp,
  label,
  children,
  className,
  float,
}) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    if (toggleShowProp) {
      toggleShowProp(!show);
    }
    setShow(!show);
  };

  return (
    <div className={`collapsible-section ${className}`}>
      <div
        className="collapsible-section-header"
        onClick={toggleShow}
        onKeyDown={toggleShow}
        role="link"
        tabIndex="0"
        data-testid="collapsible-section-header"
      >
        {label}
        {' '}
        <span className={`caret ${show ? 'caret-open' : 'caret-closed'}`} />
      </div>
      {show && (
        <div
          className={`collapsible-section-body ${
            float ? 'collapsible-section-body-float' : ''
          }`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

CollapsibleSection.propTypes = {
  toggleShow: PropTypes.func,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  float: PropTypes.bool.isRequired,
};

CollapsibleSection.defaultProps = {
  toggleShow: () => {},
  label: '',
  className: '',
};

export default CollapsibleSection;
