import _ from 'lodash'
import { apiCall } from './api/index'
import { HIDE_CHATLOG_BUTTONS, SHOW_CHATLOG_BUTTONS, CHAT_BOT_TAKE_OVER } from '../constants/actions'
import { CHAT_BOT_TAKE_OVER_ENDPOINT } from '../constants/endpoints'

exports.hideButtonsAction = () => (dispatch, getStore) => {
  const state = getStore()
  const userId = state.jobs.activeJob.customer.id
  dispatch({
    type: HIDE_CHATLOG_BUTTONS,
    payload: {
      userId,
    },
  })
}

exports.showButtonsAction = () => (dispatch, getStore) => {
  const state = getStore()
  const userId = state.jobs.activeJob.customer.id
  dispatch({
    type: SHOW_CHATLOG_BUTTONS,
    payload: {
      userId,
    },
  })
}

exports.chatBotTakeOver = () => (dispatch, getStore) => {
  const state = getStore()
  const recipientId = _.get(state, 'jobs.activeJob.customer.id')
  const jobId = _.get(state, 'jobs.activeJob.id')
  const operatorId = _.get(state, 'auth.user._id')
  const body = {
    identityId: recipientId,
    operatorId,
    jobId,
  }
  const args = {
    endpoint: CHAT_BOT_TAKE_OVER_ENDPOINT(jobId),
    method: 'POST',
    body,
    action: CHAT_BOT_TAKE_OVER,
  }
  dispatch(apiCall({args}))
}
