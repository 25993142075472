import { PAGE_SIZE } from '../constants/general';
import { shouldInboxConsumeUpdate } from './shouldInboxConsumeUpdate';
import {
  insertAndSortItemInList,
  modifyAndSortItemInList,
  removeItemFromListById,
  getActionType,
  chunkArray,
} from './cacheHelpers';

import {
  collectCaseCacheData,
  collectCustomerCaseCacheData,
  buildCasesToSort,
  buildNewCachedData,
} from './caseCacheHelpers';

export const handleCaseCacheCustomerNames = (customer, queryClient, store) => {
  const customerId = customer?.id || customer?._id;
  if (!customerId) {
    return;
  }

  const { customerName, unpinnedQueryKey } = collectCustomerCaseCacheData(
    store,
    customer,
  );

  const pinnedUpdater = (oldData) => oldData.map((c) => {
    if (c.customerId === customerId && c.customerName !== customerName) {
      return {
        ...c,
        customerName,
      };
    }
    return c;
  });
  queryClient.setQueriesData(pinnedUpdater);

  const updater = (oldData) => {
    const allCases = oldData.pages.length > 1 ? oldData.pages.flat() : oldData.pages[0];
    const clonedCases = allCases.map((c) => {
      if (c.customerId === customerId && c.customerName !== customerName) {
        return {
          ...c,
          customerName,
        };
      }
      return c;
    });
    const pages = oldData.pages.length > 1
      ? chunkArray(clonedCases, PAGE_SIZE)
      : [clonedCases];
    return { pages };
  };
  queryClient.setQueriesData(unpinnedQueryKey, updater);
};

export const handleCaseCacheItem = (job, eventName, queryClient, store) => {
  if (!job || !job?._id) {
    return;
  }
  const {
    groups,
    filters,
    inboxJob,
    cachedJob,
    userHasScrolled,
    unpinnedQueryKey,
    viewedCaseIds,
  } = collectCaseCacheData(store, queryClient, job);
  const filtersMatch = shouldInboxConsumeUpdate({
    filters,
    job: {
      ...job,
      inboxJob,
    },
    groups,
    eventName,
    cachedJob,
    viewedCaseIds,
  });
  const action = getActionType(filtersMatch, cachedJob);
  if (action) {
    const updater = (oldData) => {
      const casesToSort = buildCasesToSort(oldData, userHasScrolled);
      let clonedCases = null;
      switch (action) {
        case 'insert':
          clonedCases = insertAndSortItemInList(
            casesToSort,
            inboxJob,
            filters.order,
            filters.sort,
          );
          break;
        case 'update':
          clonedCases = modifyAndSortItemInList(
            casesToSort,
            inboxJob,
            filters.order,
            filters.sort,
          );
          break;
        case 'delete':
          clonedCases = removeItemFromListById(casesToSort, inboxJob);
          break;
        default:
      }
      const newData = buildNewCachedData(oldData, clonedCases, userHasScrolled);
      return newData;
    };

    queryClient.setQueriesData(unpinnedQueryKey, updater);
  }
};
