import {
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Heading,
} from '@chakra-ui/react';
import React from 'react';

import IntegrationRulesList from './integration-rules-list';
import { RuleData, RuleMap, ToggleTemplateModalArgs } from './integration-settings-types';
import styles from './IntegrationSettingsContainer.module.less';

interface IntegrationSettingsProps {
  title: string;
  children: React.ReactNode;
  ruleSettings: RuleMap;
  toggleTemplateModal: (args: ToggleTemplateModalArgs) => void;
  toggleRuleModal: (args: RuleData) => void;
  onAccordionItemOpen: () => void;
  isExpanded: boolean;
}
const IntegrationSettings = ({
  title,
  children,
  ruleSettings,
  toggleTemplateModal,
  toggleRuleModal,
  onAccordionItemOpen,
  isExpanded,
}: IntegrationSettingsProps) => (
  <div className={styles.accordionContent}>
    <AccordionButton py="3" border="0" onClick={onAccordionItemOpen} bg={isExpanded ? 'blue.50' : ''} _hover={{ backgroundColor: 'blue.50' }}>
      <Flex align="center" grow="1">
        {children}
        <Heading ml="6" mb="0" size="md">{`${title} Integration`}</Heading>
      </Flex>
      <Flex>Rules</Flex>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel p="0" data-testid={`rules-accordion-content-${isExpanded ? 'open' : 'closed'}`}>
      <IntegrationRulesList
        ruleSettings={ruleSettings}
        toggleTemplateModal={toggleTemplateModal}
        toggleRuleModal={toggleRuleModal}
      />
    </AccordionPanel>
  </div>
);

export default IntegrationSettings;
