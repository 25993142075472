import React from 'react'
import './Panel.less'

const Panel = ({ titleFooter, titleNotification, className, header, title, right, children }) => (
  <div data-testid={className} className={`modal-page ${className}`}>
    <div className="modal-page-header">
      <div className="modal-header-title-row">
<div className="modal-page-title">{title}</div>
{titleNotification && <div className="loading-text title-notification">{titleNotification}</div>}
        {right && <div className="modal-page-title-right">{right}</div>}
      </div>
      <div className="modal-header-button-row">{header}</div>
      {titleFooter &&
        <div className="">{titleFooter}</div>
      }
    </div>
    {children}
  </div>
)

export default Panel
