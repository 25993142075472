/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Text from '../../elements/text/Text/Text';
import styles from './MessageTemplateModal.module.less';
import { messageTemplatePropTypes } from './template-helpers';
import IconButton from '../../elements/buttons/IconButton/IconButton';
import UnpinIcon from '../../../../images/icons/unpin.svg';
import PinIcon from '../../../../images/icons/pin.svg';

const renderTemplate = (messageTemplate) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = messageTemplate?.body.split(/\${TOKEN}/g);
  return (
    <span>
      {parts.map((part, i) => (
        <Fragment key={i}>
          <span key={`${messageTemplate.id}-${i}`}>
            { part }
          </span>
          {i < parts.length - 1 ? (
            <span
              key={`${messageTemplate.id}-${i}-token`}
              className="noteSuggestion"
            >
              {`$${messageTemplate.tokens[i]?.token}`}
            </span>
          ) : null}
        </Fragment>
      ))}
    </span>
  );
};

const MessageTemplate = ({
  messageTemplateData, onTemplateSelect, onPinButtonClick, isPinned,
}) => (
  <div
    className={styles.unpinnedMsg}
    data-testid={`templateRow-${messageTemplateData?.id}`}
    role="button"
  >
    <div
      className="template-text"
      onClick={() => onTemplateSelect(messageTemplateData)}
      onKeyDown={() => onTemplateSelect(messageTemplateData)}
      data-testid={`template-btn-${messageTemplateData?.id}`}
      role="button"
      tabIndex="0"
    >
      <Text>
        <h3>{messageTemplateData?.displayName}</h3>
      </Text>
      {renderTemplate(messageTemplateData)}
    </div>
    {onPinButtonClick ? (
      <div className={styles.templatePinWrapper}>
        <IconButton
          data-testid={isPinned ? 'unpin-button' : 'pin-button'}
          className={
            isPinned
              ? 'chat-item-pin-button show-pinned-icon'
              : 'chat-item-pin-button-hover'
          }
          onClick={onPinButtonClick && onPinButtonClick(messageTemplateData?.id, !isPinned)}
        >
          {isPinned ? <UnpinIcon /> : <PinIcon />}
        </IconButton>
      </div>
    ) : null}
  </div>
);

MessageTemplate.propTypes = {
  messageTemplateData: messageTemplatePropTypes.isRequired,
  onTemplateSelect: PropTypes.func.isRequired,
  onPinButtonClick: PropTypes.func,
  isPinned: PropTypes.bool,
};

MessageTemplate.defaultProps = {
  isPinned: false,
  onPinButtonClick: null,
};

export default MessageTemplate;
