/* eslint-disable import/prefer-default-export */
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const print = (fileUrl) => {
  const printPicture = window.open(`${fileUrl}`, 'Print', 'status=no, location=no, toolbar=no, scrollbars=yes');
  printPicture.onload = () => {
    printPicture.print();
    setTimeout(() => printPicture.close());
  };
};

export const downloadAll = async (files, referenceId) => {
  const zip = new JSZip();
  const zipFilename = `media-${referenceId}.zip`;
  for (let i = 0; i < files.length; i += 1) {
    const { localFileUrl, extension } = files[i];
    // eslint-disable-next-line no-await-in-loop
    const fileBlob = await fetch(localFileUrl);
    zip.file(`media-${i}.${extension}`, fileBlob.blob(), { binary: true });
  }
  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, zipFilename);
};

// This logic was maintained. Unsure if we support more than mp4s but our UI currently restricts
export const isVideo = (mediaUrl) => mediaUrl?.endsWith('mp4');
