import React from 'react'
import PropTypes from 'prop-types'
import './Card.less'

const Card = ({ children }) => (
  <div data-testid="cardContainer" className="personal-on-click-container">
    <div className="personal-card-container">
      {children}
    </div>
  </div>
)

Card.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Card
