/* eslint-disable react/jsx-filename-extension */
import React, { createRef, useState } from 'react'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'

import {
  setFilename as setFilenameAction,
  setCreateButton as setCreateButtonAction,
  setModalLoading as setModalLoadingAction,
  createMassNotification as createMassNotificationAction,
  setSelectedFile as setSelectedFileAction,
} from '../../../actions/massNotifications'

import { DatetimeLocalRegExp } from '../../../helpers/datetime'

import CreateMassNotificationInfo from './CreateMassNotificationInfo'
import CreateMassNotificationScheduling from './CreateMassNotificationScheduling'
import CreateMassNotificationThrottling from './CreateMassNotificationThrottling'

const checkCreateButtonEnabled = (message, title, setCreateBtn) => {
  // validate inputs maybe?? TODO
  // const isValid = fileName && message && title
  setCreateBtn({
    message,
    title,
  })
}

const NotificationStep = {
  get INFO() {
    return 'INFO'
  },
  get SCHEDULE() {
    return 'SCHEDULE'
  },
  get THROTTLE() {
    return 'THROTTLE'
  },
}

let submitTimer

/**
 * Handles submission of Mass Notification creation form
 * @param {*} messageEle
 * @param {*} titleEle
 * @param {*} setLoad
 * @param {*} setCreateBtn
 * @param {*} createNotification
 * @param {*} onHide
 * @param {*} event
 * @param {Object} scheduledSendOptions object with scheduling params:
 * - scheduledDatetimeLocal: String
 * - destinationTimezoneLocale: String
 */
const handleSubmission = (
  notificationMessage,
  notificationTitle,
  setLoad,
  setCreateBtn,
  createNotification,
  onHide,
  event,
  scheduledSendOptions,
) => {
  event.preventDefault()
  setLoad(true)
  setCreateBtn(false)
  clearTimeout(submitTimer)
  submitTimer = setTimeout(() => {
    createNotification(notificationMessage, notificationTitle, onHide, scheduledSendOptions)
  }, 200)
}

const CreateMassNotificationForm = ({
  currentFilename,
  createButtonEnabled,
  createNotification,
  onHide,
  setCreateButton,
  setFilename,
  setModalLoading,
  setSelectedFile,
  showErrorMessage,
}) => {
  const fileInputRef = createRef()

  /*
    Get min from ISO without seconds, milliseconds, and offset.
    Don't allow any date/time in the past.
  */
  const minScheduledDatetimeLocalStr = new Date().toISOString().slice(0, 16)

  const [stepName, setStepName] = useState(NotificationStep.INFO)
  const [formData, setFormData] = useState({
    notificationMessage: '',
    notificationTitle: '',
    scheduledDatetime: '',
    scheduledTimezone: 'US_EAST',
    throttleRate: 1000,
  })

  const [isScheduleEnabled, setScheduleEnabled] = useState(false)
  const handleChangeScheduleEnabled = (event) => {
    event.stopPropagation() // stop form change event bubble
    setScheduleEnabled(event.target.value === 'yes')
  }

  const [isThrottleEnabled, setThrottleEnabled] = useState(false)
  const handleChangeThrottleEnabled = (event) => {
    event.stopPropagation() // stop form change event bubble
    setThrottleEnabled(event.target.value === 'yes')
  }

  const handleFormChange = () => {
    checkCreateButtonEnabled(
      formData.notificationMessage,
      formData.notificationTitle,
      setCreateButton,
    )
  }

  const handleFormInputChange = (event) => {
    const { name, value } = event.target
    if (['throttleRate'].includes(name)) {
      setFormData({ ...formData, [name]: Number(value) })
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }

  const handleFileDrop = (files) => {
    const file = files[0]
    setFilename(file.name)
    setSelectedFile(file)
    checkCreateButtonEnabled(
      formData.notificationMessage,
      formData.notificationTitle,
      setCreateButton,
    )
  }

  const handleClickSelectFile = () => fileInputRef.current.click()

  const handleFileInputChange = (event) => {
    setFilename(event.currentTarget.files[0].name)
    setSelectedFile(event.currentTarget.files[0])
  }

  const handleClickSubmit = (event) => {
    const scheduledDatetimeLocal = formData.scheduledDatetime
      && DatetimeLocalRegExp.test(formData.scheduledDatetime)
      ? formData.scheduledDatetime
      : null

    const destinationTimezoneLocale = formData.scheduledTimezone
      ? formData.scheduledTimezone
      : null

    const scheduledSendOptions = {
      isScheduleEnabled,
      scheduledDatetimeLocal,
      destinationTimezoneLocale,
      isThrottleEnabled,
      throttleRate: formData.throttleRate,
    }

    handleSubmission(
      formData.notificationMessage,
      formData.notificationTitle,
      setModalLoading,
      setCreateButton,
      createNotification,
      onHide,
      event,
      scheduledSendOptions,
    )
  }

  const handleInfoNextStep = () => setStepName('SCHEDULE')
  const handleScheduleBackStep = () => setStepName('INFO')
  const handleScheduleNextStep = () => setStepName('THROTTLE')
  const handleThrottleBackStep = () => setStepName('SCHEDULE')

  if (stepName === NotificationStep.SCHEDULE) {
    return (
      <Form onChange={handleFormChange}>
        <CreateMassNotificationScheduling
          createButtonEnabled={createButtonEnabled}
          handleChangeScheduleEnabled={handleChangeScheduleEnabled}
          handleFormInputChange={handleFormInputChange}
          handleClickSubmit={handleClickSubmit}
          handleBackStep={handleScheduleBackStep}
          handleNextStep={handleScheduleNextStep}
          isScheduleEnabled={isScheduleEnabled}
          minScheduledDatetimeLocalStr={minScheduledDatetimeLocalStr}
          showErrorMessage={showErrorMessage}
          formData={formData}
        />
      </Form>
    )
  }

  if (stepName === NotificationStep.THROTTLE) {
    return (
      <Form onChange={handleFormChange}>
        <CreateMassNotificationThrottling
          createButtonEnabled={createButtonEnabled}
          handleBackStep={handleThrottleBackStep}
          handleChangeThrottleEnabled={handleChangeThrottleEnabled}
          handleClickSubmit={handleClickSubmit}
          handleFormInputChange={handleFormInputChange}
          isScheduleEnabled={isScheduleEnabled}
          isThrottleEnabled={isThrottleEnabled}
          showErrorMessage={showErrorMessage}
          formData={formData}
        />
      </Form>
    )
  }

  return (
    <Form onChange={handleFormChange}>
      <CreateMassNotificationInfo
        currentFilename={currentFilename}
        createButtonEnabled={createButtonEnabled}
        fileInputRef={fileInputRef}
        formData={formData}
        handleClickSelectFile={handleClickSelectFile}
        handleClickSubmit={handleClickSubmit}
        handleFileDrop={handleFileDrop}
        handleFileInputChange={handleFileInputChange}
        handleFormInputChange={handleFormInputChange}
        handleNextStep={handleInfoNextStep}
        showErrorMessage={showErrorMessage}
      />
    </Form>
  )
}

CreateMassNotificationForm.propTypes = {
  currentFilename: PropTypes.string,
  createButtonEnabled: PropTypes.bool.isRequired,
  createNotification: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  setCreateButton: PropTypes.func.isRequired,
  setFilename: PropTypes.func.isRequired,
  setSelectedFile: PropTypes.func.isRequired,
  setModalLoading: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.bool,
}

CreateMassNotificationForm.defaultProps = {
  currentFilename: '',
  showErrorMessage: undefined,
}

const mapStateToProps = (state) => ({
  currentFilename: _get(state, 'massNotifications.currentFilename'),
  createButtonEnabled: _get(state, 'massNotifications.createButtonEnabled'),
  showErrorMessage: _get(state, 'massNotifications.showCreateErrorMessage'),
  showTemplateSelector: _get(state, 'templates.showTemplateSelector'),
})

const mapDispatchToProps = {
  createNotification: createMassNotificationAction,
  setCreateButton: setCreateButtonAction,
  setFilename: setFilenameAction,
  setModalLoading: setModalLoadingAction,
  setSelectedFile: setSelectedFileAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMassNotificationForm)
