import React from "react";
import _ from "lodash";
import OfficeHoursModal from "../OfficeHoursModal/OfficeHoursModal";
import ReplyMessage from "../../SharedComponents/ReplyRowMessage/ReplyMessage";
import ReplyActions from "../../SharedComponents/ReplyRowActions/ReplyActions";
import Modal from "../../../../modal/Modal/Modal";
import withModal from "../../../../HigherOrderComponents/withModal";

const OfficeHoursRow = ({ userId, reply, toggleModal }) => {
  return (
    <div className="auto-reply-row">
      <ReplyActions openEditModal={toggleModal} userId={userId} reply={reply} />
      <ReplyMessage message={_.get(reply, "message.after.value", "")} />
    </div>
  );
};

const OfficeHoursWithModal = withModal({
  Modal: (
    <Modal className="create-ooo-modal" data-test="create-ooo-modal" backdrop="static">
      <OfficeHoursModal />
    </Modal>
  ),
})(OfficeHoursRow);

export default OfficeHoursWithModal;
