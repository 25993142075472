export const lookNumberConfig = {
  staging: {
    openConvoDrill: '351', unansweredOptInsDrill: '352', waitingOptInsDrill: '355', responseRecommendedDrill: '365', quoteOfTheDay: '367', auditLog: '357',
  },
  production: {
    openConvoDrill: '572', unansweredOptInsDrill: '573', waitingOptInsDrill: '574', responseRecommendedDrill: '584', quoteOfTheDay: '681', auditLog: '487',
  },
  chubb: {
    openConvoDrill: '2', unansweredOptInsDrill: '3', waitingOptInsDrill: '4', responseRecommendedDrill: '5', quoteOfTheDay: '1', auditLog: '6',
  },
};

export const getLookerEnvironment = () => {
  const { origin } = window.location;

  if (origin === 'https://chat.marley.ai') {
    return 'production';
  } if (origin === 'https://chat.chubb.marley.ai') {
    return 'chubb';
  }
  return 'staging';
};
