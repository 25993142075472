import React from 'react';
import PropTypes from 'prop-types';
import UserPhoto2 from '../../../UserPhoto2/UserPhoto2';
import { ROLE_ENDUSER } from '../../../../constants/general';

import './UserProfile.less';

const UserProfile = ({ profile, isLoggedInOperator, isRemoved }) => {
  const {
    name,
    role,
    imageUrl,
    relationship,
    id,
  } = profile;

  return (
    <div className="user-profile">
      <div className="user-profile-avatar">
        <UserPhoto2
          id={id}
          className="user-profile-circle"
          isRecipient={role === ROLE_ENDUSER}
          name={name}
          imageUrl={imageUrl}
          isRemoved={isRemoved}
        />
      </div>
      <div className="user-profile-info">
        <div className="user-profile-name">{isLoggedInOperator ? `${name} (Myself)` : name}</div>
        <div className="user-profile-relationship">{relationship}</div>
      </div>
    </div>
  );
}

UserProfile.propTypes = {
  profile: PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    relationship: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  isLoggedInOperator: PropTypes.bool,
  isRemoved: PropTypes.string,
};

UserProfile.defaultProps = {
  isLoggedInOperator: false,
  isRemoved: false,
};

export default UserProfile;
