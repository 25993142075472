const region = typeof process.env.REGION !== 'undefined' ? process.env.REGION : 'us-east-1'
const assistDomainApiId = typeof process.env.ASSIST_DOMAIN_REST_API_ID !== 'undefined' ? process.env.ASSIST_DOMAIN_REST_API_ID : 'i829txajde';
const environment = typeof process.env.ASSIST_DOMAIN_STAGE !== 'undefined' ? process.env.ASSIST_DOMAIN_STAGE : 'dev';

export const ASSIST_BASE_URL = `https://${assistDomainApiId}.execute-api.${region}.amazonaws.com/${environment}/`;

export const QUERY_KEYS = {
  CASES: 'joblist',
  PINNED_CASES: 'pinnedCases',
};
