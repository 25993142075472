import React from 'react';
import './SettingsTable.less';
import PropTypes from 'prop-types'

const SettingsTableWrapper = ({ children }) => (<section data-testid="settings-table-wrapper" className="settings-table-wrapper">{children}</section>)
const SettingsTableIntro = ({ title, intro }) => (
  <div className="settings-table-intro">
    <h3 data-testid="settings-table-title">{title}</h3>
    <p data-testid="settings-table-intro">{intro}</p>
  </div>
)
const SettingsTable = ({ children }) => (<table><tbody data-testid="settings-table-table" className="settings-table-table">{children}</tbody></table>)
const SettingsTableInputTitle = ({ inputTitle = [] }) => (
  <tr>
    <td />
    {inputTitle.map((title) => (
      <td key={title} className="settings-table-Input-title">{title}</td>
    ))}
  </tr>
);

const SettingsTableRow = ({ children }) => (<tr data-testid="settings-table-row">{children}</tr>)
const SettingsTableData = ({ children, width, textAlign }) => {
  const sizes = {
    large: '90%',
    medium: '50%',
    small: '30%',
    default: 'auto',
  }

  return (<td data-testid="settings-table-data" style={{ width: sizes[width], textAlign }}>{children}</td>);
}

SettingsTableWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

SettingsTableIntro.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
};
SettingsTable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
SettingsTableInputTitle.propTypes = {
  inputTitle: PropTypes.arrayOf(PropTypes.string).isRequired,
};
SettingsTableRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
SettingsTableData.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  width: PropTypes.oneOf(['large', 'medium', 'small', 'default']),
  textAlign: PropTypes.oneOf(['center', 'left', 'right']),
};

SettingsTableData.defaultProps = {
  width: 'default',
  textAlign: 'left',
}

export {
  SettingsTableWrapper,
  SettingsTableIntro,
  SettingsTable,
  SettingsTableInputTitle,
  SettingsTableRow,
  SettingsTableData,
};
