import React from 'react'
import './Row.less'

const Row = ({ children, type }) => (
  <div className={`hm-row hm-row-${type}`}>
    {children}
  </div>
)

export default Row
