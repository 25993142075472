import _ from 'lodash'
import moment from 'moment'
import { ROLE_OPERATOR } from '../constants/general'

const TOKEN_DETERMINANT = ':'
const cleanPhone = phoneString => phoneString
  .replace('(', '')
  .replace(')', '')
  .replace(' ', '')
  .replace('-', '')
  .replace('+1', '')

export const textFilter = ({ items, filter, searchColumns }) => {
  const lcFilter = filter.toLowerCase()
  return items.filter(i =>
    searchColumns.filter((c) => {
      const f = _.get(i, c) // throwing error in edge case otherwise
      return f && f.toLowerCase().indexOf(lcFilter) > -1
    }).length > 0,
  )
}

export const phoneFilter = ({ items, filter, phoneColumns }) => {
  const lcFilter = cleanPhone(filter)
  return items.filter(i =>
    phoneColumns.filter((c) => {
      const testItem = cleanPhone(_.get(i, c, ''))
      return testItem.indexOf(lcFilter) > -1
    }).length > 0,
  )
}

export const tokenFilter = ({ items, searches, filter }) => {
  const filterParts = filter.split(':')
  const token = filterParts[0]
  const determinant = filterParts[1]
  const filt = _.get(searches, `${token}.${determinant}`)
  return (items && filt && items.filter(i => filt(i))) || []
}

export const createTextFilters = (filter) => {
  const filters = filter.split(' ')
  return filters.filter(f => f)
}

export const commonFilter = ({ items, searches, filter, token = TOKEN_DETERMINANT }) => {
  let returnItems = items
  const filters = createTextFilters(filter)
  const searchColumns = searches ? searches.text || [] : []
  const phoneColumns = searches ? searches.phone || [] : []
  const searchTokens = { ...searches }
  if (searchTokens.text) delete searchTokens.text
  if (searchTokens.phone) delete searchTokens.phone

  filters.forEach((subFilter) => {
    const isTokenFilter = (subFilter.indexOf(token) > -1 &&
      subFilter.split(token)[0] &&
      searches[subFilter.split(token)[0]]
    )

    if (isTokenFilter) {
      returnItems = tokenFilter({ items, searches: searchTokens, filter: subFilter })
    } else {
      const textItems = textFilter({ items, searchColumns, filter: subFilter })
      const phoneItems = phoneFilter({ items, phoneColumns, filter: subFilter })
      returnItems = _.intersection(returnItems, textItems.concat(phoneItems))
    }
  })

  return returnItems
}

export const commonForm = (state) => {
  const isEditing = _.get(state, 'form') && (_.get(state, 'form.isEdit') && !_.get(state, 'requestCompleted'))
  const isCreating = _.get(state, 'form') && (_.get(state.form, 'isCreate') && !_.get(state, 'requestCompleted'))
  const isViewing = _.get(state, 'form') && _.get(state.form, 'readonly')
  let mode = null
  if (isEditing) {
    mode = 'edit'
  } else if (isCreating) {
    mode = 'create'
  } else if (isViewing) {
    mode = 'view'
  }

  return {
    form: { ..._.get(state, 'form', {}),
      isEditing,
      isCreating,
      isViewing,
      mode,
    },
  }
}

export const commonLoading = state => ({
  loadingItem: _.get(state, 'loadingItem'),
  loadedItem: _.get(state, 'loadedItem'),
})

export const commonSort = ({ sort, items, columns = [] }) => {
  let column // eslint-disable-line
  let returnItems = items

  const sortOrder = sort.order ? sort.order.toLowerCase() : 'asc'
  if (sort.column) {
    const sortColumn = Array.isArray(sort.column) ? sort.column[0] : sort.column
    column = columns.find((c) => {
      let sortItem = c.sortLocation || c.location || ''
      if (!Array.isArray(sortItem)) sortItem = [sortItem]
      return _.includes(sortItem, sortColumn)
    })
  }
  const findValue = (item, c) => {
    const sortItem = c.sortLocation || c.location || ''
    return Array.isArray(sortItem) ?
      _.get(item, sortItem.find(s => _.get(item, s))) :
      _.get(item, sortItem)
  }
  const isEmpty = i => i === undefined || i === null || i === ''

  if (column) {
    const emptyItems = items.filter(i => isEmpty(findValue(i, column)))
    const nonEmptyItems = items.filter(i => !isEmpty(findValue(i, column)))
    const sortFunction = (item) => {
      let sortValue = findValue(item, column)
      let sortType = column.sortType
      if (!sortType) {
        sortType = (typeof sortValue === 'string') ? 'string' : 'number'
      }

      switch (sortType) {
        case 'string': {
          sortValue = sortValue.toLowerCase()
          break
        }
        case 'date': {
          sortValue = moment(new Date(sortValue))
          break
        }
        default:
          break
      }
      return sortValue
    }

    returnItems = _.orderBy(nonEmptyItems,
      [sortFunction],
      [sortOrder])
    returnItems = _.concat(returnItems, emptyItems)
  }
  return returnItems
}

export const commonSelector = () => ({
  ...commonLoading,
  items: commonFilter(),
})

export const activeOperators = (users = []) => (
  users.filter(user => (user.role === ROLE_OPERATOR) && !user.archived)
    .map(user => ({ id: user.id, label: user.name }))
)

export const filterJobsByType = (state={}, jobsList=[], type='') => {
  // filtering jobs by type should probably be done in back-end
  const jobs = jobsList
    .map((job) => ({
      ...job,
      claimant: job.customer,
      chatId: _.get(state, `jobs.chatIdsByJobId.${job.id}`, ''),
    }))
    .filter((job) => {
      if (type) {
        return job.type === type ? job : null
      } else {
        return job
      }
    })
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  return jobs
}