import React, { useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import NoMessages from '../SharedComponents/NoMessages/NoMessages';
import GeneralAutoReplyModal from './GeneralAutoReplyModal/GeneralAutoReplyModal';
import GeneralAutoReplyRow from './GeneralAutoReplyRow/GeneralAutoReplyRow';
import Modal from '../../../modal/Modal/Modal';
import withModal from '../../../HigherOrderComponents/withModal';
import { getAutoReplies } from '../../../../actions/profile';
import './GeneralAutoReplies.less';

const GeneralAutoReplies = ({
  userId,
  isLoading,
  generalAutoReplies,
  toggleModal,
  error,
}) => {
  useEffect(() => {
    getAutoReplies(userId);
  }, [userId]);

  // only show auto replies if userId matches
  // to prevent the logged in user auto replies from being used when editing a different user
  const filteredGeneralAutoReplies = useMemo(
    () => generalAutoReplies?.[0]?.pk?.includes(userId)
      ? generalAutoReplies
      : [],
    [generalAutoReplies, userId],
  );

  return (
    <div className="auto-replies-container general-auto-replies-container">
      <div className="general-auto-replies-header">
        <h2>Away Messages</h2>
        {filteredGeneralAutoReplies && filteredGeneralAutoReplies.length > 0
          ? null
          : (
            <div className="button-row">
              <button
                className="add-general-auto-replies-message"
                onClick={toggleModal}
            >
                + Create New Message
              </button>
            </div>
            )}
      </div>
      <div className="general-auto-replies-subheader">
        <p>Configure an away message that sends at all times.</p>
      </div>

      {isLoading
        ? (
          <div className="autoreplies-loading-icon">
            <Loader type="Oval" color="#44C7E9" height="50" width="50" />
          </div>
          )
        : filteredGeneralAutoReplies && filteredGeneralAutoReplies.length > 0
          ? (
              filteredGeneralAutoReplies.map((reply) => (
                <GeneralAutoReplyRow key={reply.id} userId={userId} reply={reply} />
              ))
            )
          : (
            <NoMessages error={error} />
            )}
    </div>
  );
};

const GeneralAutoRepliesWithModal = withModal({
  Modal: (
    <Modal
      className="create-auto-reply-modal"
      data-test="create-auto-reply-modal"
      title="New Away Message"
      backdrop="static"
    >
      <GeneralAutoReplyModal />
    </Modal>
  ),
})(GeneralAutoReplies);

export default GeneralAutoRepliesWithModal;
