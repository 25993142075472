import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@himarley/unity';
import styles from './CaseProgress.module.less';
import VerifiedIcon from '../../../images/icons/verified-icon.svg';
import CurrentIcon from '../../../images/icons/curent-status-icon.svg';
import UnverifiedIcon from '../../../images/icons/unverified-icon.svg';
import {
  PROGRESS_STEP_STATUS_IN_PROGRESS,
  PROGRESS_STEP_STATUS_COMPLETE,
} from '../../constants/integration-constants';

const CaseProgressStep = ({
  title,
  id,
  status,
  subText,
  showButton,
  children,
  showDetails,
  finalStep,
  handleShowDetails,
}) => {
  const getStatusIcon = () => {
    if (status === PROGRESS_STEP_STATUS_COMPLETE) {
      return <div data-testid={`${id}-status-complete`}><VerifiedIcon /></div>;
    }
    if (status === PROGRESS_STEP_STATUS_IN_PROGRESS) {
      return <div data-testid={`${id}-status-in-progress`}><CurrentIcon /></div>;
    }
    return <div data-testid={`${id}-status-not-started`}><UnverifiedIcon /></div>;
  };

  return (
    <div className={styles.step}>
      <div className={`${styles.stepHeader} ${(status === PROGRESS_STEP_STATUS_IN_PROGRESS) && styles.inprogressText}`}>
        {getStatusIcon()}
        <span>{title}</span>
      </div>
      <div className={`${!finalStep && styles.stepBody} ${status === PROGRESS_STEP_STATUS_COMPLETE && styles.stepBodyComplete}`}>
        <div className={styles.stepSubHeader}>
          <span>{subText}</span>
          {showButton && (
            <Button
              onClick={handleShowDetails}
              type="outline"
              className={styles.showDetailsButton}
            >
              {showDetails ? 'Hide Details' : 'Show Details'}
            </Button>
          )}
        </div>
        <div className={styles.stepContent}>
          {children}
        </div>
      </div>
    </div>
  );
};

CaseProgressStep.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleShowDetails: PropTypes.func,
  subText: PropTypes.string,
  showButton: PropTypes.bool,
  showDetails: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  finalStep: PropTypes.bool.isRequired,
};

CaseProgressStep.defaultProps = {
  handleShowDetails: null,
  subText: '',
  showButton: false,
};

export default CaseProgressStep;
