/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import './IconButton.less'
import WithDot from '../../../WithDot/WithDot'

const IconButton = ({
  className, left, children, tooltip, id, disabled, showDot, onClick, ...other
}) => {
  const actualButton = (
    <div {...other} onClick={!disabled && onClick} disabled={disabled} className={`icon-button ${className}`}>
      <div disabled={disabled} className="icon-button-veil" />
      {children}
    </div>
  )
  return (
    tooltip
      ? <OverlayTrigger style={{ order: left ? 0 : 1 }} placement="top" overlay={<Tooltip className="tooltip" id={id}>{tooltip}</Tooltip>}>{actualButton}</OverlayTrigger>
      : (
        <span style={{ order: left ? 0 : 1, position: 'relative' }}>
          <WithDot type="blue" show={showDot}>{actualButton}</WithDot>
        </span>
      )
  )
}

IconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  tooltip: PropTypes.string,
  id: PropTypes.string,
}
IconButton.defaultProps = {
  className: '',
  children: null,
  tooltip: undefined,
  id: '',
}

export default IconButton
