import {
  Heading, Grid, GridItem, Text, Switch, HStack, Box, NumberInput, NumberInputField,
} from '@chakra-ui/react';
import { TextAreaInput } from '@himarley/unity';
import React, { useEffect } from 'react';

import { MIN_MIN_VALUE, MAX_MIN_VALUE } from './constants';
import TooltipInfo from '../../elements/Tootip/TooltipInfo';

interface InactivityAutoReplyProps {
  isAutoReplyEnabled: boolean;
  setIsAutoReplyEnabled: (value: boolean) => void;
  orgAutoReply: {
    active: boolean;
    sendCriteria: {
      minutesInactiveThreshold: string;
    };
    message: {
      during: {
        value: string;
      };
    };
  };
  minutes: string;
  autoReplyMessage: string;
  setMinutes: (value: string) => void;
  setAutoReplyMessage: (value: string) => void;
}

const InactivityAutoReply: React.FC<InactivityAutoReplyProps> = ({
  isAutoReplyEnabled = false,
  setIsAutoReplyEnabled = () => { },
  minutes = MIN_MIN_VALUE,
  autoReplyMessage = '',
  setMinutes = () => { },
  setAutoReplyMessage = () => { },
  orgAutoReply = {},
}) => {
  const handleMinuteChange = (valueAsString: string) => {
    setMinutes(valueAsString);
  };

  useEffect(() => {
    setMinutes(orgAutoReply?.sendCriteria?.minutesInactiveThreshold || MIN_MIN_VALUE.toString());
  }, [orgAutoReply?.sendCriteria?.minutesInactiveThreshold, setMinutes]);

  useEffect(() => {
    setAutoReplyMessage(orgAutoReply?.message?.during?.value || '');
  }, [orgAutoReply?.message?.during?.value, setAutoReplyMessage]);

  useEffect(() => {
    setIsAutoReplyEnabled(orgAutoReply?.active || false);
  }, [orgAutoReply?.active, setIsAutoReplyEnabled]);

  return (
    <HStack gap="10%">
      <Box w="50%">
        <Grid templateRows={`repeat(${isAutoReplyEnabled ? 2 : 1}, 1fr)`} templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={3}>
            <Heading as="h1" size="md">
              Inactivity Auto Reply
            </Heading>
            <Text>
              Add an auto reply text for received messages on cases when users are
              inactive for a specified time.
            </Text>
          </GridItem>
          {isAutoReplyEnabled && (
            <GridItem colSpan={3}>
              <TextAreaInput
                className="autoReplyInput"
                onKeyDown={() => { }}
                autoFocus
                label="Auto Reply Message"
                maxLength={1400}
                value={autoReplyMessage}
                onChange={
                  (e: React.ChangeEvent<HTMLTextAreaElement>) => setAutoReplyMessage(e.target.value)
                }
                labelTooltip={(
                  <TooltipInfo
                    message="Individual users can override this org auto reply message with their own auto reply and away message settings."
                    placement="top"
                  />
                )}
                showMaxLengthOnTopRight
              />
            </GridItem>
          )}
        </Grid>
      </Box>
      <Box
        w="50%"
        justifyContent="end"
        alignSelf="baseline"
      >
        <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={2}>
            {isAutoReplyEnabled && (
              <HStack>
                <Text m={0} fontSize="lg">Threshold</Text>
                <NumberInput
                  variant="flushed"
                  size="sm"
                  maxW={20}
                  value={minutes}
                  min={MIN_MIN_VALUE}
                  max={MAX_MIN_VALUE}
                  onChange={handleMinuteChange}
                  precision={0}
                >
                  <NumberInputField data-testid="auto-reply-number-input" />
                </NumberInput>
                <Text m={0} fontSize="lg">Minutes</Text>
              </HStack>
            )}
          </GridItem>
          <GridItem display="flex" justifyContent="flex-end">
            <Switch
              data-testid="auto-reply-switch"
              onChange={() => setIsAutoReplyEnabled(!isAutoReplyEnabled)}
              isChecked={isAutoReplyEnabled}
              size="lg"
            />
          </GridItem>
          {isAutoReplyEnabled && (
            <GridItem colSpan={3}>
              <TextAreaInput
                value={autoReplyMessage}
                onChange={() => { }}
                onKeyDown={() => { }}
                autoFocus
                label="Preview"
                disabled
                showMaxLengthOnTopRight
              />
            </GridItem>
          )}
        </Grid>
      </Box>
    </HStack>

  );
};

export default InactivityAutoReply;
