/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import './RadioButton.less';
import _ from 'lodash';

const RadioButton = ({
  disabled,
  currentValue,
  values,
  setCheckedValue,
  isHorizontal,
  testId,
}) => {
  const handleChange = (value) => {
    setCheckedValue(_.get(value, 'id'));
  };
  return (
    <div className={`${isHorizontal ? 'radioHorizontal' : ''} radio-button`}>
      {values.map((value, index) => (
        // eslint-disable-next-line react/jsx-key
        <div className="radio-wrapper">
          <label className="radio-container">
            {_.get(value, 'label')}
            <input
              type="radio"
              disabled={disabled}
              checked={currentValue === _.get(value, 'id') ? 'checked' : false}
              name={value.name}
              data-testid={`${value.name}-${index}${testId ? `-${testId}` : ''}`}
              onChange={() => handleChange(value)}
            />
            <span
              className={
                disabled ? 'disabled radio-checkmark' : 'radio-checkmark'
              }
            />
          </label>
        </div>
      ))}
    </div>
  );
};

RadioButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  currentValue: PropTypes.string.isRequired,
  values: PropTypes.shape([]).isRequired,
  setCheckedValue: PropTypes.func.isRequired,
  isHorizontal: PropTypes.bool,
  testId: PropTypes.string,
};

RadioButton.defaultProps = {
  isHorizontal: false,
  testId: '',
};

export default RadioButton;
