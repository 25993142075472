import {
  HIDE_CHATLOG_BUTTONS, SHOW_CHATLOG_BUTTONS, LOAD_CASES, CHAT_BOT_TAKE_OVER, SET_ACTIVE_CHAT, ACTIVE_JOB,
} from '../constants/actions';
import { formatError, formatInit, formatSuccess } from '../constants/endpoints';

export const initialState = {
  showButtons: {},
  isTakingOverChatbot: false,
};
let changes;
let users;
let userId;
let jobs;
const chatLog = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_CHATLOG_BUTTONS:
      changes = {
        showButtons: { ...state.showButtons },
      };
      userId = action.payload.userId;
      changes.showButtons[userId] = false;
      return { ...state, ...changes };
    case LOAD_CASES:
      changes = {
        showButtons: { ...state.showButtons },
      };
      jobs = action.payload.items;
      users = jobs.map((j) => j.customer);
      users.forEach((user) => {
        if (user) {
          changes.showButtons[user.id] = true;
        }
      });
      return { ...state, ...changes };
    case SET_ACTIVE_CHAT:
    case ACTIVE_JOB: {
      changes = {
        showButtons: { ...state.showButtons },
      };
      const activeJob = action?.payload?.activeJob || action?.payload;
      const user = activeJob?.customer;
      if (user) {
        changes.showButtons[user.id] = true;
      }
      return { ...state, ...changes };
    }
    case SHOW_CHATLOG_BUTTONS:
      changes = {
        showButtons: { ...state.showButtons },
      };
      userId = action.payload.userId;
      changes.showButtons[userId] = true;
      return { ...state, ...changes };
    case formatInit(CHAT_BOT_TAKE_OVER):
      changes = {
        isTakingOverChatbot: true,
      };
      return { ...state, ...changes };
    case formatSuccess(CHAT_BOT_TAKE_OVER):
      changes = {
        isTakingOverChatbot: false,
      };
      return { ...state, ...changes };
    case formatError(CHAT_BOT_TAKE_OVER):
      changes = {
        isTakingOverChatbot: false,
      };
      return { ...state, ...changes };
    default:
      return { ...state };
  }
};

export default chatLog;
