import React from 'react';
import PropTypes from 'prop-types';

const EmptyNewInboxJob = ({ totalChatCount }) => {
  const chatCount = Number(totalChatCount);
  let message = 'There are No Cases in the Inbox.';
  if (chatCount === 1) {
    message = 'There is 1 Case in the Inbox.';
  } else if (chatCount > 50) {
    message = 'There are 50+ Cases in the Inbox.';
  } else if (chatCount > 1 && chatCount <= 50) {
    message = `There are ${chatCount} Cases in the Inbox.`;
  }
  return (
    <div className="chatComponent chatComponent-state-box">
      <div className="chatComponent-message">
        {message}
      </div>
      <div className="chatComponent-helpmessage">
        {chatCount === 0
          ? 'Once new cases are created you will see them here.'
          : 'Please select a case from the Inbox to view it and start messaging.'}
      </div>
    </div>
  );
};

EmptyNewInboxJob.propTypes = {
  totalChatCount: PropTypes.string.isRequired,
};

export default EmptyNewInboxJob;
