/* eslint-disable no-template-curly-in-string */
export const TOKENS_MAP = {
  $claimantFullName: 'Charlotte Customer',
  $claimantLastName: 'Customer',
  $claimantFirstName: 'Charlotte',
  $claimantPhoneNumber: '+555-555-1234',
  $contactFullName: 'Justin Adjuster',
  $contactFirstName: 'Justin',
  $contactLastName: 'Adjuster',
  $contactEmail: 'justin.adjuster@lovable.com',
  $contactTitle: 'Sr. Adjuster',
  $contactPhoneNumber: '+555-555-5555',
  $claimNumber: '987654321',
  $policyNumber: '123456',
  $companyName: 'Lovable Insurance',
};

export const TEMPLATE_KEYS = {
  welcomeMessageInitial: 'welcomeMessageInitial',
  welcomeMessageOptionalEnding: 'welcomeMessageOptionalEnding',
  welcomeAssigned: 'welcomeAssigned',
  welcomeUnassigned: 'welcomeUnassigned',
  welcomeMessageOptional: 'welcomeMessageOptional',
  welcomeVerifiedAssigned: 'welcomeVerifiedAssigned',
  welcomeVerifiedUnassigned: 'welcomeVerifiedUnassigned',
  welcomeCaseMessageInitial: 'welcomeCaseMessageInitial',
  welcomeCaseMessageOptionalEnding: 'welcomeCaseMessageOptionalEnding',
  welcomeCaseAssigned: 'welcomeCaseAssigned',
  welcomeCaseUnassigned: 'welcomeCaseUnassigned',
  welcomeCaseMessageOptional: 'welcomeCaseMessageOptional',
  welcomeCaseVerifiedAssigned: 'welcomeCaseVerifiedAssigned',
  welcomeCaseVerifiedUnassigned: 'welcomeCaseVerifiedUnassigned',
};

export const LEGAL_MESSAGE = 'Please reply YES to agree to the terms and privacy policy (himarley.com/tp). Msg and data rates may apply. Msg freq varies. Reply HELP for help or STOP to opt out.';

export const BEGINNING_MESSAGE = 'Hi $claimantFirstName. Thanks for reporting your claim to $companyName.';

export const ENDING_MESSAGE = 'Once you reply YES you can begin texting with your claims rep.';

export const ASSIGNED_MESSAGE = 'Your claim rep is $contactFullName. You can text them here, or by email at $contactEmail, or call $contactPhoneNumber. $contactFirstName will message you shortly to assist with this claim. In the meantime, feel free to text any photos related to your claim.';

export const UNASSIGNED_MESSAGE = 'We will assign a representative to assist with this claim soon. In the meantime, feel free to text any photos related to your claim.';

export const ALREADY_OPT_IN_MESSAGE_ASSIGNED = 'Hi $claimantFirstName thanks for reporting your claim $claimNumber. Your claim representative’s name is $contactFullName. You can contact them at $contactEmail, or call $contactPhoneNumber.';

export const ALREADY_OPT_IN_MESSAGE_UNASSIGNED = 'Hi $claimantFirstName, thanks for reporting your claim #$claimNumber.  Once your claim representative is assigned, they will contact you. In the meantime, please text us any photos you have available.';

// convert tokens to values
export const constructPreviewText = (
  inputStr = '',
  TOKEN_OVERRIDE_MAP = {},
) => {
  if (!inputStr.trim().length > 0) {
    return '';
  }
  let isToken = false;
  let token = '';
  let result = '';
  for (let i = 0; i < inputStr.length; i += 1) {
    const char = inputStr[i];
    result += char;
    // start of token check
    if (char === '$') {
      isToken = true;
      continue;
    }
    if (isToken) {
      if (char.match(/[a-z]/i)) {
        token += char;
      } else {
        isToken = false;
        token = '';
        continue;
      }
      // check if end of token -> do replacement
      const tokenVal = TOKEN_OVERRIDE_MAP[`$${token}`] || TOKENS_MAP[`$${token}`];
      if (tokenVal) {
        result = `${result.substring(
          0,
          result.length - token.length - 1,
        )}<strong>${tokenVal}</strong>`;
      }
    }
  }
  return result;
};

export const NO_VALUE = '<NULL>';

export const RADIO_BUTTON_VALUES = [
  { id: 'ASSIGNED', label: 'Preview Assigned', name: 'RADIO_BUTTON_VALUES' },
  {
    id: 'UNASSIGNED',
    label: 'Preview Unassigned',
    name: 'RADIO_BUTTON_VALUES',
  },
];
export const RADIO_BUTTON_VALUES_OPT_IN = [
  {
    id: 'ASSIGNED',
    label: 'Preview Assigned',
    name: 'RADIO_BUTTON_VALUES_OPT_IN',
  },
  {
    id: 'UNASSIGNED',
    label: 'Preview Unassigned',
    name: 'RADIO_BUTTON_VALUES_OPT_IN',
  },
];
export const MODAL_RADIO_BUTTON_VALUES_OPT_IN = [
  {
    id: 'ASSIGNED',
    label: 'Preview Assigned',
    name: 'MODAL_RADIO_BUTTON_VALUES_OPT_IN',
  },
  {
    id: 'UNASSIGNED',
    label: 'Preview Unassigned',
    name: 'MODAL_RADIO_BUTTON_VALUES_OPT_IN',
  },
];
