import { CheckmarkFilledIcon } from '@himarley/unity';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addUserRole, removeUserRole } from '@app/actions/users';
import Menu from '@app/components/chakra/menu';
import { SYSTEM_ADMIN } from '@app/constants/permissions';
import { SYS_ADMIN } from '@app/constants/roles';
import { useCheckPermissions } from '@app/helpers/common';

interface AssignRoleActionMenuProps {
  user: {
    roles: string[];
  };
}

const AssignRoleActionMenu: React.FC<AssignRoleActionMenuProps> = ({
  user,
}) => {
  const dispatch = useDispatch();

  const hasRole = (roleName: string) => user.roles.some((role) => role === roleName);
  const orgRoles = useSelector((state) => state.users.groups);

  const updateGroup = (roleName: string) => {
    const isAdd = !hasRole(roleName);

    if (isAdd) {
      dispatch(addUserRole(user, roleName));
    }
    if (!isAdd) {
      dispatch(removeUserRole(user, roleName));
    }
  };

  const buildButtonLabel = (
    userRoles: string[] = [],
    orgRolesParam: { groupName?: string }[] = [],
  ) => {
    const orgRoleNames = orgRolesParam.map((role) => role.groupName ?? undefined);
    const actualUserRoles = userRoles.filter((role) => orgRoleNames.includes(role));

    const rolesCount = actualUserRoles.length || 0;
    const hasMultipleRoles = rolesCount > 1;
    return hasMultipleRoles ? `${rolesCount} Roles` : actualUserRoles[0] || 'No Roles';
  };

  const userRoles = user.roles || [];
  const buttonLabel = buildButtonLabel(userRoles, orgRoles);
  const isUserSysAdmin = useCheckPermissions([SYSTEM_ADMIN]);
  const cannotAssignSysAdmin = (roleName: string) => !isUserSysAdmin && roleName === SYS_ADMIN;
  const handleRoleSelect = (groupName: string) => {
    updateGroup(groupName);
  };

  return (
    <Menu
      id="assign-role"
      defaultLabel={buttonLabel}
      options={orgRoles.map((role) => {
        const { groupName } = role;
        return {
          id: groupName,
          label: groupName,
          isDisabled: cannotAssignSysAdmin(groupName),
          onClick: () => {
            if (!cannotAssignSysAdmin(groupName)) {
              handleRoleSelect(groupName);
            }
          },
          rightIcon: hasRole(groupName) ? CheckmarkFilledIcon : null,
        };
      })}
      menuButtonProps={{
        w: '120px',
      }}
    />
  );
};

export default AssignRoleActionMenu;
