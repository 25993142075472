import React from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { Logout } from '../Logout/Logout';
import MarleyIcon from '../icons/MarleyIcon';

const OktaError = ({ error }) => {
  const DisplayError = (error?.name && error?.message)
    ? (
      <p>
        {error?.name}
        :
        {' '}
        {error?.message}
      </p>
    )
    : (
      <p>
        Error:
        {error?.toString()}
      </p>
    );

  return (
    <div className="splashScreen">
      <div className="logo-container"><MarleyIcon /></div>
      <div className="text-container">
        <div className="connectingText error">
          {DisplayError}
        </div>
        <Logout />
      </div>
    </div>
  );
};

OktaError.propTypes = {
  error: PropTypes.instanceOf(Object).isRequired,
};

/**
 * Rendered at the /login/callback route in order to handle authentication for ProtectedRoutes.
 * This route gets hit after a valid login.
 * Errors from the inline token hook get displayed at this point as well
 * The purpose of providing the custom callback component is because upgrading the okta-react
 *    dependency caused errors from the inline hook to be trapped in the developer console,
 *    rendering a 'blank screen' that our users would be stuck on.
 * Most code in this function is copy-pasta from Okta's repo
 */
const CustomCallback = ({ errorComponent }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [caughtError, setCaughtError] = React.useState();

  const ErrorReporter = errorComponent || OktaError;

  React.useEffect(() => {
    oktaAuth.handleLoginRedirect().catch((e) => {
      console.error(e);
      setCaughtError(e);
    });
  }, [oktaAuth]);

  if (authState?.error) {
    return <ErrorReporter error={authState.error} />;
  }

  if (caughtError) {
    return (
      <ErrorReporter error={{
        name: caughtError?.name,
        message: caughtError?.errorSummary,
      }}
      />
    );
  }

  return null;
};

CustomCallback.propTypes = {
  errorComponent: PropTypes.node,
};

CustomCallback.defaultProps = {
  errorComponent: null,
};

export default CustomCallback;
