import _ from 'lodash';
import React from 'react';

// eslint-disable-next-line import/no-named-as-default
import TableRow from '../../elements/table/TableRow/TableRow';

interface MassNotificationRowProps {
  data: unknown
  key: string
  columns: unknown[]
  actionOptions: unknown
}

const MassNotificationRow: React.FC<MassNotificationRowProps> = ({
  data,
  key,
  columns,
  actionOptions,
}) => {
  const id = _.get(data, 'id', 'id');
  return (
    <TableRow
      className="massNotifRow"
      columns={columns && columns.slice(0, columns.length - 1)}
      item={data}
      actions={actionOptions}
      key={key}
      itemId={id}
      useNewActionMenu
    />
  );
};

export default MassNotificationRow;
