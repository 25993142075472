import React from 'react';

export const buildOptedInWelcomeMessage = (participantForm, orgName, termsOfService) => (
  <>
    <p>Welcome Message Preview (Sent to All Participants)</p>
    <div className="participant-form-preview-text-opted-in">
      <p>
        {`${participantForm.firstName} ${participantForm.lastName[0]}. `}
        is being invited to join a conversation about a claim with
        {` ${orgName}`}
        .
        {` ${participantForm.firstName}, `}
        by replying JOIN to join you also agree to our terms and
        {' '}
        {`privacy policy (${termsOfService}).`}
        {' '}
        Text LEAVE at any time to leave this
        {' '}
        conversation after joining.
      </p>
      <p>
        Msg and data rates may apply. Reply HELP for help
        {' '}
        or STOP to opt out of all future texts.
      </p>
    </div>
  </>
)

export const buildNonOptedInWelcomeMessage = (participantForm, orgName, termsOfService) => (
  <>
    <p>Welcome Message Preview (Sent to All Participants)</p>
    <div className="participant-form-preview-text-non-opted-in">
      <p>
        {`${participantForm.firstName} ${participantForm.lastName[0]}. `}
        is being invited to join a conversation about a claim with
        {` ${orgName}`}
        .
        {` ${participantForm.firstName}, `}
        reply YES to join now. Text LEAVE at any time to leave this
        {' '}
        conversation after joining.
      </p>
      <p>
        By joining you agree to the terms and privacy policy of our
        {' '}
        {`communication provider (${termsOfService}). Msg and data`}
        {' '}
        rates may apply. Msg frequency varies. Text HELP for help or STOP
        {' '}
        to opt out of all future texts.
      </p>
    </div>
  </>
)
