import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Button,
  Box,
  Text,
  VStack,
  HStack,
  Avatar,
} from '@chakra-ui/react';
import { CaretDownIcon, CaretUpIcon, SearchBar } from '@himarley/unity';
import debounce from 'lodash/debounce';
import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { lazyLoad } from '@app/actions/common';
import { getShortenedEmail } from '@app/helpers/common';
import { sortByAlphabetical } from '@app/helpers/sorting';
import { operatorType } from '@app/models/marleyTypes';
import { StateType, User } from '@app/types/reducer-state';

interface UserRowProps {
  option: User;
}

const UserRow: React.FC<UserRowProps> = ({ option }) => (
  <Box w="100%">
    <HStack gap={3}>
      <Avatar
        size="sm"
        id={option.id}
        src={option.imageUrl}
        name={(option.name || option.label || '').replace('Assign to', '').replace('(me)', '').trim()}
        borderRadius="md"
        bg="marleyRed.500"
        color="white"
      />
      <VStack gap={0} alignItems="flex-start">
        <Text m={0} fontSize="md" fontWeight="semibold">{option.label || option.name}</Text>
        <Text mt={1} color="gray.500" mb={0}>{getShortenedEmail(option.email)}</Text>
      </VStack>
    </HStack>
  </Box>
);

const getDropdownLabelItems = (auth: {
  user: {
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: string;
    title?: string;
    imageUrl?: string;
    _id?: string;
  };
}) => {
  const {
    firstName,
    lastName,
    email: authEmail,
    role: authRole,
    title: authTitle,
    imageUrl,
    _id: authId,
  } = auth.user;

  const me = firstName && lastName ? `${firstName} ${lastName} (me)` : 'Me';

  return [
    {
      id: authId,
      label: `Assign to ${me}`,
      email: authEmail,
      role: authRole,
      title: authTitle,
      imageUrl,
    },
    { id: 'unassigned', label: 'Mark as Unassigned' },
  ];
};

interface AssignUserActionMenuProps {
  id: string;
  defaultLabel: string;
  rowId: string;
  selectedId: string;
  handleSelect: (id: string, rowId: string) => void;
  options: User[];
}

const AssignUserActionMenu: React.FC<AssignUserActionMenuProps> = ({
  id,
  defaultLabel,
  rowId,
  selectedId,
  handleSelect,
  options,
}) => {
  const [searchFilter, setSearchFilter] = useState('');
  const dispatch = useDispatch();

  const {
    auth,
    users,
    recentlyAssignedOperators,
  } = useSelector(
    (state: StateType) => ({
      auth: state.auth,
      users: (state.operators?.list || []).sort((a, b) => sortByAlphabetical(a?.name, b?.name)),
      recentlyAssignedOperators: [
        ...(state.auth?.user ? [state.auth.user] : []),
        ...(state.profile?.properties?.recentlyAssignedOperators?.[id] || []),
      ]
        .map((operator: User) => ({
          id: operator.id || operator._id,
          label: operator.name || `${operator.firstName} ${operator.lastName}`,
          email: operator.email,
          title: operator.title,
          role: operator.role,
          imageUrl: operator.imageUrl,
        }))
        .filter(
          (operator, index, self) => index === self.findIndex((o) => o.id === operator.id),
        ),
    }),
  );

  const loadUsers = useCallback(
    (params?: { offset?: number; limit?: number; searchText?: string }) => dispatch(lazyLoad(operatorType, { ...params, offset: 0, limit: 20 }, 'users')),
    [dispatch],
  );

  const debouncedHandleSearchUsers = useCallback(
    (searchText: string) => {
      debounce(() => {
        const userQuery: {
          offset?: number;
          limit?: number;
          searchText?: string;
        } = {};

        userQuery.searchText = searchText || '';

        loadUsers(userQuery);
      }, 500)();
    },
    [loadUsers],
  );

  useEffect(() => {
    debouncedHandleSearchUsers(searchFilter);
  }, [searchFilter, debouncedHandleSearchUsers]);

  const assignedOperatorsList = options.map((item) => (item.id === auth.user._id ? { ...item, label: `${auth.user.firstName} ${auth.user.lastName} (me)` } : item));
  const operatorList = users.map((user) => (user.id === auth.user._id ? { ...user, label: `Assign to ${auth.user.firstName} ${auth.user.lastName} (me)` } : user));
  const defaultSelectedOperator = assignedOperatorsList.find((option) => option.id === selectedId);
  const dropdownLabelItems = getDropdownLabelItems(auth);

  const renderMenuItems = useCallback((list: User[]) => list
    .filter((option) => option.id !== selectedId && option.id !== auth.user._id)
    .map((option) => (
      <MenuItem
        data-dropdown-item={`item-${option.id}`}
        key={option.id}
        onClick={() => handleSelect(option.id || '', rowId)}
        value={option.id}
      >
        <UserRow option={option} />
      </MenuItem>
    )), [auth.user._id, handleSelect, rowId, selectedId]);

  return (
    <Menu isLazy data-testid="assign-user-action-menu">
      {({ isOpen }) => (
        <>
          <MenuButton
            id={id}
            data-testid="btn-assign-user-action-menu"
            rightIcon={<Icon as={isOpen ? CaretUpIcon : CaretDownIcon} />}
            as={Button}
            size="sm"
            variant="outline"
            mr={2}
            width="162px"
            textAlign="left"
          >
            <Box isTruncated>
              {defaultSelectedOperator ? defaultSelectedOperator.label : defaultLabel}
            </Box>
          </MenuButton>
          <MenuList w="400px" sx={{ 'max-height': '300px', overflowY: 'auto' }}>
            <Box pb={1} px={3} w="400px" sx={{ div: { width: 'unset' } }}>
              <SearchBar
                id="operators"
                placeholder="Search Operators"
                value={searchFilter}
                onValueChange={setSearchFilter}
                onClear={() => setSearchFilter('')}
              />
            </Box>
            {dropdownLabelItems.map((option) => (
              <MenuItem
                data-dropdown-item={`item-${option.id}`}
                key={option.id}
                isDisabled={option.id === selectedId}
                onClick={() => handleSelect(option.id || '', rowId)}
                value={option.id}
              >
                <UserRow option={option} />
              </MenuItem>
            ))}
            {searchFilter === '' && renderMenuItems(recentlyAssignedOperators)}
            {renderMenuItems(operatorList)}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default AssignUserActionMenu;
