/* eslint-disable no-console */
import { ANALYTICS_CW_LOG } from '../constants/endpoints';
import { doRequest } from './api';

/* eslint-disable import/prefer-default-export */
export function reportToCW(payload = {}, description = '-', logLevel = 'STAT') {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    const state = getState();
    // eslint-disable-next-line no-underscore-dangle
    const userId = state?.auth?.user?._id;
    const organizationId = state?.auth?.user?.organizationId;
    try {
      return doRequest({
        endpoint: ANALYTICS_CW_LOG,
        method: 'POST',
        body: {
          payload,
          description,
          logLevel,
          userId,
          organizationId,
        },
        noAuth: true,
      });
    } catch (error) {
      console.error('FAILED TO SEND CW LOG');
    }
  };
}
