/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import FormInputField from '../FormInput/FormInputField';
import { UserFormValidation } from '../../helpers/formValidation/userForm/userFormValidation';
import {
  lazyLoad,
  handleFormInputChange,
  clearEditObject,
  setIsEdit,
} from '../../actions/common';
import { clearUsers } from '../../actions/users';
import { userType } from '../../models/marleyTypes';
import {
  UP_ARROW_CODE,
  DOWN_ARROW_CODE,
  ENTER_CODE,
} from '../../constants/keycodes';

import './PhoneNumberSearchSelect.less';

const SEARCH_DISPLAY_LIMIT = 100;
export const MIN_FILTER_LEN = 8;

class PhoneNumberSearchSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listIsOpen: false,
      cursorLocation: 0,
    };
  }

  componentDidUpdate() {
    const { isEdit, usersList, editObject } = this.props;
    const editObjectHasItems = Object.keys(editObject || {}).length;
    const usersListHasItems = (usersList || []).length;
    const phone = UserFormValidation.cleanPhone(_.get(this.props, 'value'));

    if (phone.length === 10 && usersListHasItems && !editObjectHasItems) {
      const usersListSlice = (usersList || []).slice(0, SEARCH_DISPLAY_LIMIT);
      const userExists = usersListSlice.find(
        (user) => user.phoneNumber === phone,
      );

      if (!isEdit && userExists) {
        this.makeSelection(null, phone);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearUsers();
  }

  setDropdownRef = (node) => {
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.dropdownRef = node;
  };

  toggleDropdown = () => {
    this.setState({ listIsOpen: true });
  };

  makeSelection = (id, phone, index) => {
    const usersList = this.props.usersList.slice(0, SEARCH_DISPLAY_LIMIT);

    const user = typeof index === 'number'
      ? usersList[index]
      : usersList.find(
        (user) => user.id === id || user.phoneNumber === phone,
      );

    if (user) {
      const userFormFields = [
        'phoneNumber',
        'firstName',
        'lastName',
        'email',
        'languagePreference',
        'id',
      ];
      userFormFields.forEach((field) => {
        this.props.handleFormInputChange({
          value: user[field],
          inputId: field,
          tableId: userType.id(),
        });
      });
      this.props.setIsEdit(userType, true);
    }
    this.setState({ listIsOpen: false });
  };

  handleLocalChange = (e) => {
    const { value } = e.target;
    const phone = UserFormValidation.cleanPhone(value);
    const canRequestExistingUsers = phone.length >= MIN_FILTER_LEN;
    const phoneNumberIsComplete = phone.length >= 10;
    if (this.props.onChange) this.props.onChange(e);

    if (canRequestExistingUsers) {
      this.props.lazyLoad(
        userType,
        { role: 'enduser', phoneStartsWith: phone, offset: 0 },
        'users',
      );
      this.setState({
        listIsOpen: true,
      });
    }

    if (!phoneNumberIsComplete) {
      this.props.clearEditObject({ id: 'users', except: ['phoneNumber'] });
      this.props.setIsEdit(userType, false);
      this.props.clearUsers();
    }

    if (phoneNumberIsComplete) {
      this.makeSelection(null, phone);
      this.setState({ listIsOpen: false });
    }
  };

  handleLocalBlur = () => {
    this.setState({ listIsOpen: false });
  };

  handleKeyDown = (e) => {
    const keyPressed = e.keyCode;
    const { usersList, isEdit } = this.props;
    const { cursorLocation, listIsOpen } = this.state;
    const usersListSlice = (usersList || []).slice(0, SEARCH_DISPLAY_LIMIT);
    const usersListHasItems = usersListSlice.length;
    const existingUsersCount = usersListSlice.length - 1;

    if (
      usersListHasItems
      && !listIsOpen
      && !isEdit
      && keyPressed === DOWN_ARROW_CODE
    ) {
      this.setState({ listIsOpen: true });
    }

    if (keyPressed === UP_ARROW_CODE && cursorLocation > 0) {
      this.setState((prevState) => ({
        cursorLocation: prevState.cursorLocation - 1,
      }));
    } else if (
      keyPressed === DOWN_ARROW_CODE
      && cursorLocation < existingUsersCount
    ) {
      this.setState((prevState) => ({
        cursorLocation: prevState.cursorLocation + 1,
      }));
    } else if (keyPressed === ENTER_CODE) {
      e.preventDefault();
      this.makeSelection(null, null, cursorLocation);
    }
  };

  render() {
    const { listIsOpen, cursorLocation } = this.state;

    const {
      prefixAddOn,
      name,
      value,
      isValid,
      errorMessage,
      fieldSpecificMessage,
      required,
      isDisabled,
      userErrors,
      usersList,
      isLandlinePhoneNumber,
      isMobilePhoneNumber,
      hasLookedUpNumber,
    } = this.props;

    const ExistingUsersList = (usersList || []).slice(0, SEARCH_DISPLAY_LIMIT);

    const isFormValid = ((userErrors || '').indexOf('phoneNumber') > -1
    || (!isMobilePhoneNumber && value?.length >= 14)
      ? false
      : isValid);

    const errorMessageText = (isLandlinePhoneNumber && value?.length >= 14
      ? 'Landline - SMS Not Supported'
      : errorMessage);

    return (
      <div
        data-test="phone-number-search-select"
        className="phone-number-search-select"
        onClick={this.toggleDropdown}
        onKeyDown={this.handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <FormInputField
          label="Mobile Phone Number"
          prefixAddOn={prefixAddOn}
          onChange={this.handleLocalChange}
          onBlur={this.handleLocalBlur}
          name={name}
          type="tel"
          value={value}
          isValid={
            hasLookedUpNumber ? isFormValid : true
          }
          errorMessage={
            hasLookedUpNumber ? errorMessageText : ''
          }
          successMessage={
            hasLookedUpNumber ? value?.length >= 14 && isMobilePhoneNumber && 'SMS Supported' : ''
          }
          fieldSpecificMessage={hasLookedUpNumber ? value?.length >= 14 && fieldSpecificMessage : ''}
          isDisabled={isDisabled}
          required={required}
          autoComplete="off"
          shouldFocus
        />
        {listIsOpen && !isDisabled && ExistingUsersList.length > 0 && (
          <div
            className={`${
              _.get(this.props, 'view', '') === 'case' ? 'case-view' : ''
            } options`}
            ref={this.setDropdownRef}
          >
            {ExistingUsersList.map((user, i) => (
              <div
                key={user.id}
                data-test="phone-number-search-select-option"
                className={`option ${cursorLocation === i ? 'active' : ''}`}
                onMouseDown={() => this.makeSelection(user.id, null)}
                role="button"
                tabIndex={0}
              >
                <div className="option-name">
                  {user.firstName}
                  {' '}
                  {user.lastName}
                </div>
                <div className="option-data">
                  <div className="option-phone-number">
                    {prefixAddOn}
                    {' '}
                    {UserFormValidation.formatPhoneNumber(user.phoneNumber)}
                  </div>
                  <div className="option-email">{user.email}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

PhoneNumberSearchSelect.propTypes = {
  prefixAddOn: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  formContext: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  fieldSpecificMessage: PropTypes.string,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLandlinePhoneNumber: PropTypes.bool.isRequired,
  isMobilePhoneNumber: PropTypes.bool.isRequired,
  hasLookedUpNumber: PropTypes.bool,
};

PhoneNumberSearchSelect.defaultProps = {
  prefixAddOn: '',
  formContext: '',
  onChange: () => {},
  name: '',
  value: '',
  isValid: true,
  errorMessage: '',
  fieldSpecificMessage: '',
  required: false,
  isDisabled: false,
  hasLookedUpNumber: false,
};

const mapDispatchToProps = {
  lazyLoad,
  clearUsers,
  handleFormInputChange,
  clearEditObject,
  setIsEdit,
};

const mapStateToProps = (state, props) => ({
  isEdit: _.get(state[props.formContext], 'form.isEdit', false),
  editObject: _.get(state[props.formContext], 'form.editObjet', {}),
  usersList: _.get(state[props.formContext], 'list', []),
  userErrors: _.get(state[props.formContext], 'errors', []),
});

export { PhoneNumberSearchSelect };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneNumberSearchSelect);
