import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import EditIcon from '../../../icons/EditIcon'
import FormField from '../../../Form/FormField/FormField'
import IconButton from '../../../elements/buttons/IconButton/IconButton'
import { updateItem } from '../../../../actions/common'
// import withForm from '../../../HigherOrderComponents/withForm'
import { queueType } from '../../../../models/marleyTypes'

import './InfoPanel.less'

const EDIT_MODE = 'EDIT_MODE'

class InfoPanel extends React.Component {
  state = {
    mode: null,
  }

  setMode = (mode) => {
    let newMode = mode // following logic allows toggle and only one edit to be open at once
    if (this.state.mode === mode) { newMode = null }
    this.setState({ mode: newMode })
  }


  componentDidMount = () => {
    const { queue } = this.props
    const change = {
      ...queue,
    }

    this.props.handleFormChange(change)
  }

  handleUpdate = ({ id, value }) => {
    const change = {}
    _.set(change, id, value)
    this.props.handleFormChange(change)
    this.props.updateItem(queueType.allUpperCase(), change, this.props.formObject.id)
  }

  render() {
    const { mode } = this.state
    const name = _.get(this.props, 'formObject.name', '') // always drawing from form object keeps synced on update
    const description = _.get(this.props, 'formObject.description', '') // always drawing from form object keeps synced on update
    const initials = name ? name.split(' ').map(n => n.slice(0, 1)) : ''

    return (
      <div className='info-panel'>
        <div className="queue-header">
          <div className="name"> 
            {
              mode !== EDIT_MODE ? name :
                <textarea
                  data-test="name-textarea"
                  className="queue-name-edit"
                  placeholder={name}
                  onChange={(e) => this.handleUpdate({ id: 'name', value: _.get(e, 'target.value', '') })}
                  value={_.get(this.props, 'formObject.name', '')}
                  maxLength={50}
                />
            }
          </div>
          <div className="left">
            <div>
              <IconButton onClick={() => this.setMode(EDIT_MODE)}>
                <EditIcon />
              </IconButton>
            </div>
            {/* <div>
              <IconButton onClick={() => {}} >
                <GarbageIcon/>
              </IconButton>
            </div> */}
          </div>
        </div>

        <div data-test="initials" className="group-photo">{ initials }</div>

        <div className="description">
          {mode !== EDIT_MODE ? description : (
            <FormField
              control={
                <textarea
                  data-test="description-textarea"
                  className="queue-description-edit"
                  placeholder={`Queue description (150 characters max)`}
                  onChange={(e) => this.handleUpdate({ id: 'description', value: _.get(e, 'target.value', '') })}
                  value={_.get(this.props, 'formObject.description', '')}
                  maxLength={150}
                />
              }
            />
          )}
        </div>
      </div>
    )
  }
}

// export default connect(null, { updateItem })(withForm(InfoPanel))
export default connect(null, { updateItem })(InfoPanel)
