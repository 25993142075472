import { formatPhoneNumberV2 } from '../../helpers/format';

import { COPART_SELLER_PORTAL } from '../../constants/integration-constants';

const formatAddress = (addressData) => {
  const {
    companyName = '',
    yardName = '',
    contactPhone = '',
    yardPhoneNumber = '',
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    state = '',
    zipCode = '',
    type = '',
  } = addressData;
  const name = companyName || yardName;
  const addressPhone = contactPhone || yardPhoneNumber;
  const streetAddress = `${addressLine1} ${addressLine2}`;
  const cityStateZip = `${city}, ${state && state.concat(' ')}${zipCode}`;
  const locationType = type;
  return {
    ...locationType && { locationType: `Vehicle at ${locationType}` },
    name,
    fullAddress: `${streetAddress}, ${cityStateZip}`,
    addressPhone: formatPhoneNumberV2(addressPhone),
  };
};

const buildCopartData = (totalLossData) => {
  const {
    vehicleLocation = {},
    yardLocation = {},
    ownerDetails = {},
    vehicleDetails = {},
    stockNumber = '',
    titleExpressUrl = '',
  } = totalLossData;

  const { firstName = '', lastName = '', phoneNumber = '' } = ownerDetails;
  const {
    year = '',
    color = '',
    model = '',
    make = '',
    licensePlateNumber = '',
  } = vehicleDetails;

  const ownerInfo = {
    fullName: `${firstName} ${lastName}`,
    phoneNumber: formatPhoneNumberV2(phoneNumber),
  };

  const vehicleInfo = {
    makeDetail: `${make} ${model} ${year} ${color} ${licensePlateNumber}`,
  };

  const vehicleLocationInfo = formatAddress(vehicleLocation);
  const yardLocationInfo = formatAddress(yardLocation);

  const sellerPortalLink = {
    link: {
      url: `${COPART_SELLER_PORTAL}${stockNumber}`,
      text: 'Copart Seller Portal',
    },
  };

  const titleExpressLink = {
    link: {
      url: titleExpressUrl,
      text: 'Title Express Portal',
    },
  };
  return {
    ...Object.entries(ownerDetails).length && { ownerInfo },
    ...Object.entries(vehicleDetails).length && { vehicleInfo },
    ...Object.entries(vehicleLocation).length && { vehicleLocationInfo },
    ...Object.entries(yardLocation).length && { yardLocationInfo },
    ...stockNumber && { sellerPortalLink },
    ...titleExpressUrl && { titleExpressLink },
  };
};

export default buildCopartData;
