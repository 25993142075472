/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './Media.less';
import { useSelector } from 'react-redux';
import MediaContainer from '../../../../Media/MediaContainer';

const Media = ({ fileUrl, onExpand, onLoad }) => {
  const mediaItem = useSelector((state) => state?.media[fileUrl]);
  return mediaItem
    ? (
      <div
        className="details-media-item-container"
        data-testid={`case-details-user-media-${mediaItem.providerId}`}
        key={mediaItem.messageId}
      >
        <MediaContainer
          mediaItem={mediaItem}
          onExpand={onExpand}
          onLoad={onLoad}
          configuration={{
            mode: 'fixed',
            fixedHeight: 96,
            fixedWidth: 96,
            isThumbnail: true,
          }}
        />
      </div>
    ) : null;
};

Media.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  onExpand: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default Media;
