import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import { resetPassword } from '../../../actions/auth'
import { toggleConfirmation } from '../../../actions/ui'
import './ResetPassword.less'

const ResetPassword = ({ email, toggleConfirmation, resetPassword }) => {
  const sendButtonStates = {
    preRequest: {
      className: 'password-reset-send-button',
      type: 'primary',
      text: 'Reset Password',
    },
    requesting: {
      className: 'password-reset-resetting-button',
      type: 'secondary',
      text: 'Resetting...',
    },
  }

  const cancelButtonStates = {
    preRequest: {
      className: 'password-reset-cancel',
      type: 'outline',
      text: 'Cancel',
    },
    requesting: {
      className: 'password-reset-cancel',
      type: 'outline',
      text: 'Cancel',
    },
    success: {
      type: 'outline',
      text: 'Close',
    },
    error: {
      type: 'default',
      text: 'Close',
    },
  }

  const helpText = (<div className="message">
    This will send an automatic reset notification to the email associated with the user&apos;s account.
  </div>)

  const sentText = (<div className="message">
    The user should receive an email with a link to reset their password momentarily.
  </div>)

  const texts = {
    preRequest: helpText,
    requesting: helpText,
    success: sentText,
    error: helpText,
  }

  return (
    <ConfirmationModal
      className="resetpassword"
      id="resetpassword"
      title={{
        preRequest: "Send password reset?",
        requesting: "Send password reset?",
        success: "Reset sent!",
        error: "Send password reset?",
      }}
      requestId="resetEmailSent"
      open={() => resetPassword(email)}
      close={() => {}}
      openButtonStates={sendButtonStates}
      cancelButtonStates={cancelButtonStates}
      helpText={texts}
    />
  )
}

ResetPassword.propTypes = {}

ResetPassword.defaultProps = {}

export { ResetPassword }

const mapStateToProps = (state) => {
  const userId = _.get(state, 'ui.confirmationIsOpen.resetpassword.selectedId')
  return {
    email: _.get(state, `operators.listById.${userId}.email`, ''),
  }
}

export default connect(mapStateToProps, { resetPassword, toggleConfirmation })(ResetPassword)
