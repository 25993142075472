/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import './SlideToggle.less';

const SlideToggle = ({ on, onToggle, testId }) => (
  <div className="customToggle">
    <button
      data-testid={testId}
      className={`${on ? 'on_tog' : 'off_tog'} new_tog`}
      onClick={onToggle}
      type="button"
    >
      <div className="toggle-ball" />
      <div className="toggle-label">{on ? 'ON' : 'OFF'}</div>
    </button>
  </div>
);

SlideToggle.propTypes = {
  on: PropTypes.bool,
  onToggle: PropTypes.func,
  testId: PropTypes.string.isRequired,
};

SlideToggle.defaultProps = {
  on: false,
  onToggle: () => {},
};

export default SlideToggle;
