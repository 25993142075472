import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TooltipInfo from '../../../elements/Tootip/TooltipInfo';

import './ChatBanner.less';

const renderBannerMessage = ({
  isLocked,
  isBlocked,
  isNotActivated,
  isInChatBotMode,
  marleyNumberStatus,
  isInFNOLFlow,
}) => {
  let chatBlockBanner = '';
  let chatBlockBannerMessage = '';
  let chatBlockBannerClass = '';
  if (isLocked) {
    chatBlockBanner = 'Customer Locked Out';
  } else if (isBlocked) {
    chatBlockBanner = 'User Opted Out';
    chatBlockBannerMessage = (
      <div>
        User has opted out.
        <br />
        This user has opted not to receive SMS messages through the Hi Marley
        system.
      </div>
    );
  } else if (isInFNOLFlow) {
    chatBlockBanner = 'User is in FNOL Flow';
  } else if (isNotActivated) {
    chatBlockBanner = 'Customer Not Opted-In';
    chatBlockBannerMessage = (
      <div>
        This customer has been invited to join this conversation but has not
        opted-in yet. You can resend the opt-in text to them.
      </div>
    );
  } else if (isInChatBotMode) {
    chatBlockBanner = 'Automated workflow is gathering information';
    chatBlockBannerMessage = (
      <div>
        An automated workflow is currently gathering information from this person.
        To cancel this workflow press the `Take Over` button below.
      </div>
    );
  } else if (marleyNumberStatus === 'NEW_MARLEY_NUMBER') {
    chatBlockBanner = 'The number for this case was recently changed';
    chatBlockBannerClass = 'changed-number-info-header';
    chatBlockBannerMessage = (
      <div>
        Due to regulatory messaging changes the number for this case has
        changed. A text message was sent before your last message to the enduser
        explaining why the number changed.
      </div>
    );
  }

  return {
    chatBlockBanner,
    chatBlockBannerMessage,
    chatBlockBannerClass,
  };
};

const ChatBanner = ({
  isNotActivated,
  isLocked,
  isBlocked,
  isInChatBotMode,
  job,
  updateJob,
  toggleConfirmation,
  jobId,
  updateEndUser,
  isUnlockingUser,
  isInFNOLFlow,
}) => {
  const [showUserActivationInfo, setShowUserActivationInfo] = useState(false);

  const toggleUserActivationInfo = (updatedVal) => {
    if (isLocked) {
      updateEndUser();
      return;
    }
    setShowUserActivationInfo(updatedVal);
  };

  const handleClickRemoveMarleyNumberStatusBanner = () => {
    updateJob({
      ...job,
      marleyNumberStatus: 'BANNER_CLOSED',
    });
  };
  const { marleyNumberStatus } = job;
  const { chatBlockBanner, chatBlockBannerMessage, chatBlockBannerClass } = renderBannerMessage({
    isLocked,
    isBlocked,
    isNotActivated,
    isInChatBotMode,
    marleyNumberStatus,
    isInFNOLFlow,
  });
  const showResendWelcome = isNotActivated
    && !isLocked
    && !isBlocked
    && !isInChatBotMode
    && !isInFNOLFlow;
  const showRemoveMarleyNumberChangeBannerButton = job.marleyNumberStatus === 'NEW_MARLEY_NUMBER';
  return (
    <div
      className={`chat-banner ${chatBlockBannerClass} ${
        isLocked ? 'chat-banner-locked' : 'chat-banner-default'
      }`}
    >
      <Modal
        dialogClassName="user-activation-info"
        show={showUserActivationInfo}
        onHide={() => toggleUserActivationInfo(false)}
      >
        <button
          className="modal-close-button cls-btn"
          onClick={() => toggleUserActivationInfo(false)}
          aria-label="toggleUserActivationInfo"
          type="button"
        />
        <div className={`user-activation-info-header ${chatBlockBannerClass}`}>
          {' '}
          {chatBlockBanner}
          {' '}
        </div>
        <div className="user-activation-info-text-wrapper">
          <div className="user-activation-info-text">
            {chatBlockBannerMessage}
          </div>
        </div>
      </Modal>
      <div className="chat-banner-text" data-testid={`chat-banner-text-${job?.id}`}>
        {chatBlockBanner}
        {chatBlockBannerMessage && (
          <TooltipInfo message={chatBlockBannerMessage} placement="bottom" />
        )}
      </div>
      <div className="chat-banner-buttons">
        {showResendWelcome && (
          <button
            className="chat-banner-button"
            onClick={() => toggleConfirmation({
              id: 'resendwelcome',
              isOpen: true,
              selectedId: jobId,
            })}
            type="button"
          >
            Resend Opt-In Text
          </button>
        )}
        {isLocked && (
          <button
            className="chat-banner-button"
            disabled={isUnlockingUser}
            onClick={() => toggleUserActivationInfo(true)}
            type="button"
          >
            {isUnlockingUser ? 'Unlocking...' : 'Unlock Customer'}
          </button>
        )}
        {showRemoveMarleyNumberChangeBannerButton && (
          <button
            className="chat-banner-button"
            onClick={handleClickRemoveMarleyNumberStatusBanner}
            type="button"
          >
            Dismiss
          </button>
        )}
      </div>
    </div>
  );
};

ChatBanner.propTypes = {
  isNotActivated: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  isInChatBotMode: PropTypes.bool.isRequired,
  isInFNOLFlow: PropTypes.bool.isRequired,
  job: PropTypes.shape({
    marleyNumberStatus: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  updateJob: PropTypes.func.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  jobId: PropTypes.string.isRequired,
  updateEndUser: PropTypes.func,
  isUnlockingUser: PropTypes.bool,
};

ChatBanner.defaultProps = {
  updateEndUser: () => {},
  isUnlockingUser: false,
};

export default ChatBanner;
