import React from 'react';
import DynamicLabel from './InboxHeader/DynamicLabel';

export const sortMenuItems = [
  {
    label: 'Smart Sort', additionalLabels: <DynamicLabel />, sort: 'smartSort', order: 'ASC', testId: 'smartSortOption',
  },
  {
    label: 'Newest: Sent and Received', sort: 'lastMessage.sentAt', order: 'DESC', testId: 'newestSentAndReceivedSortOption',
  },
  {
    label: 'Oldest: Sent and Received', sort: 'lastMessage.sentAt', order: 'ASC', testId: 'oldestSentAndReceivedSortOption',
  },
  {
    label: 'Newest: Case Created', sort: 'createdAt', order: 'DESC', testId: 'newestCaseCreatedSortOption',
  },
  {
    label: 'Oldest: Case Created', sort: 'createdAt', order: 'ASC', testId: 'oldestCaseCreatedSortOption',
  },
];

export const DEFAULT_SELECTED_SORT_INDEX = 0;

export const CARD_DENSITY = {
  EXPANDED: 'expanded',
  COMFORTABLE: 'comfortable',
  COMPACT: 'compact',
};
