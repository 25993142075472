// eslint-disable-next-line import/default
import ProfilePage from '../ProfilePage/ProfilePage'
// eslint-disable-next-line import/default
import DropDownBox from '../../DropDownBox/DropDownBox'
import React from 'react'
import SlideToggle from '../../SlideToggle/SlideToggle'
import EmailIcon from '../../icons/EmailIcon'
import { connect } from 'react-redux'
import { get } from 'lodash'
import './EmailConfig.less'
import { getEmailConfig, setSMSResponseEmail, setEmailWhileOffline } from '../../../actions/profile'


const EmailConfig = ({
    smsResponseMailOn,
    getEmailConfig,
    setSMSResponseEmail,
    emailWhileOffline,
    setEmailWhileOffline
}) =>{
    getEmailConfig()
    return (
        <div className="emailConfig">
            <ProfilePage
                title={"E-mail Configuration"}
                description={"This is where you customize all e-mail related configurations, including notifications"}
            >
                <DropDownBox
                title={"SMS Triggered E-mail"}
                description={"Turn on to recieve emails on inbound texts."}
                >
                    <div className="mainEntry">
                        <div className="emailIcon"><EmailIcon></EmailIcon></div>      
                        <div className="entryDescription">E-mail me this this kind of notification</div>
                        <SlideToggle
                            on={smsResponseMailOn}
                            onToggle={setSMSResponseEmail}
                        ></SlideToggle>
                    </div>  
                </DropDownBox>
                <DropDownBox
                    title="Recieve Email While Offline "
                    description="Recieve an email to notify you of inbound messages when logged out of the system."    
                >
                  <div className="mainEntry">
                        <div className="emailIcon"><EmailIcon></EmailIcon></div>      
                        <div className="entryDescription">E-mail me this this kind of notification</div>
                        <SlideToggle
                            on={emailWhileOffline}
                            onToggle={setEmailWhileOffline}
                        ></SlideToggle>
                    </div>
                </DropDownBox>  
            </ProfilePage>
        </div> 
    )
}


const mapStateToProps = state => ({
    smsResponseMailOn : get(state,'profile.emailConfig.smsResponseMailOn',false),
    emailWhileOffline : get(state,'profile.emailConfig.emailWhileOffline',false)
})

const mapDispatchToProps = {
    getEmailConfig,
    setSMSResponseEmail,
    setEmailWhileOffline
}

module.exports = connect(mapStateToProps,mapDispatchToProps)(EmailConfig)
