import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import FormField from '../../Form/FormField/FormField'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import { deleteUser } from '../../../actions/users'
import { toggleConfirmation } from '../../../actions/ui'
import './DeleteUser.less'

const defaultState = { isEmailEntered: false }
class DeleteUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }
  render() {
    const { user, deleteUser } = this.props
    const sendButtonStates = {
      preRequest: {
        type: 'red',
        disabled: !this.state.isEmailEntered,
        text: 'Delete User',
      },
      requesting: {
        type: 'secondary',
        text: 'Deleting...',
      },
    }

    const cancelButtonStates = {
      preRequest: {
        type: 'default',
        text: 'Cancel',
      },
      success: {
        type: 'default',
        text: 'Ok',
      },
      error: {
        type: 'default',
        text: 'Ok',
      },
    }
    return (
      <ConfirmationModal
        className="deleteuser"
        id="userdelete"
        title={`Are you sure you want to delete ${user && user.name}?`}
        open={() => deleteUser(user && user.id)}
        close={() => this.setState(defaultState)}
        openButtonStates={sendButtonStates}
        cancelButtonStates={cancelButtonStates}
      >
        <div className="message">
          This action cannot be undone and will remove this user from any active cases.
        </div>
        <div>
          <FormField
            onChange={e => this.setState({
              isEmailEntered: user.email === e.target.value,
            })}
            className="delete-user-name-input"
            type="text"
          />
          <div className="help">Type the user&apos;s email:</div>
          <div className="help">{user && user.email}</div>
        </div>
      </ConfirmationModal>
    )
  }
}

DeleteUser.propTypes = {}

DeleteUser.defaultProps = {}

export { DeleteUser }

const mapStateToProps = (state) => {
  const userId = _.get(state, 'ui.confirmationIsOpen.userdelete.selectedId')
  return {
    user: _.get(state, `operators.listById.${userId}`),
  }
}

export default connect(mapStateToProps, { deleteUser, toggleConfirmation })(DeleteUser)
