/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import Highlighter from '../Highlighter/Highlighter';

/* NOTE: Try to make this and the below methods a generic one for adding a component
 * in the middle of text. May create a problem.
 */
/**
 * Add different styling to portion of text.
 * @param {object} messageObject - Object containing message.
 * @return {array} Array that will serve as formatted text for rendering.
 */
export const highlightPartOfMessage = (messageObject = {}) => {
  const highlightedMessage = [];

  if (Object.keys(messageObject).length) {
    const id = messageObject?._id || '';
    const messageBody = messageObject?.body ?? '';
    // indexes in text where wrapping componenet begins and ends
    const redactionData = messageObject?.redactionData || [];
    // Sort from smallest beginOffset to largest beginOffset
    const sortedRedactionData = [...redactionData].sort((item1, item2) => {
      const beginOffset1 = item1?.beginOffset ?? item1?.BeginOffset;
      const beginOffset2 = item2?.beginOffset ?? item2?.BeginOffset;
      return beginOffset1 - beginOffset2;
    });
    let previousBeginOffset = sortedRedactionData[0]?.beginOffset
      ?? sortedRedactionData[0]?.BeginOffset;
    let lastEndOffset = 0;

    for (let i = 0; i < sortedRedactionData.length; i += 1) {
      const isLastRedaction = i === sortedRedactionData.length - 1;
      const beginOffset = sortedRedactionData[i]?.beginOffset
        ?? sortedRedactionData[i]?.BeginOffset;
      const endOffset = sortedRedactionData[i]?.endOffset ?? sortedRedactionData[i]?.EndOffset;

      if (i !== 0 && previousBeginOffset === beginOffset) {
        // Skip the iteration when the condition is met
        continue;
      }
      previousBeginOffset = beginOffset;

      highlightedMessage.push(messageBody.slice(lastEndOffset, beginOffset));
      highlightedMessage.push(
        <Highlighter
          key={`${id}+${i}`}
          text={messageBody.slice(beginOffset, endOffset)}
          fontWeight="bolder"
        />,
      );

      lastEndOffset = endOffset;
      if (isLastRedaction) {
        highlightedMessage.push(
          messageBody.slice(lastEndOffset, messageBody.length),
        );
      }
    }
  }

  return highlightedMessage;
};
