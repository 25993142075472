/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import {
  Divider, Tab, Tabs,
} from '@himarley/unity';
import { Button } from '@chakra-ui/react';
import Preview from '../OrgSettings/Preview';
import CustomerNotOptedInModal from './CustomerNotOptedInModal';
import CustomerOptedInModal from './CustomerOptedInModal';
import RadioButton from '../../elements/RadioButton/RadioButton';
import { useCheckPermissions } from '../../../helpers/common';
import { ORG_SETTINGS_GENERAL_WELCOME_FLOW } from '../../../constants/permissions';

import {
  updateOrgSetting,
  setIsUpdatingOrgSetting,
} from '../../../actions/organization';
import {
  ASSIGNED_MESSAGE,
  UNASSIGNED_MESSAGE,
  BEGINNING_MESSAGE,
  LEGAL_MESSAGE,
  constructPreviewText,
  NO_VALUE,
  RADIO_BUTTON_VALUES,
  ALREADY_OPT_IN_MESSAGE_UNASSIGNED,
  ALREADY_OPT_IN_MESSAGE_ASSIGNED,
  RADIO_BUTTON_VALUES_OPT_IN,
} from './constants';
import './OptInMessages.less';

const OptInMessages = ({
  instructionText,
  className,
  dispatch,
  welcomeFlow,
  lineOfBusinessId,
  previewBanner,
  tokenOverrideMap,
  isLobTab,
}) => {
  const isUpdatingOrgSetting = useSelector(
    (state) => state?.organizations?.isUpdatingOrgSetting,
  );
  const organization = useSelector(
    (state) => state?.auth?.user?.organization || {},
  );
  const isGettingFullOrganizationData = useSelector(
    (state) => state?.organizations?.isGettingFullOrganizationData,
  );

  useEffect(() => {
    if (isUpdatingOrgSetting === false) {
      dispatch(setIsUpdatingOrgSetting(null));
      setShowModal(false);
      setShowModalOptIn(false);
    } else if (isUpdatingOrgSetting === 'error') {
      dispatch(setIsUpdatingOrgSetting(null));
    }
  }, [dispatch, isUpdatingOrgSetting]);

  const [showModal, setShowModal] = useState(false);
  const [showModalOptIn, setShowModalOptIn] = useState(false);
  const [radioPreviewOption, setRadioPreviewOption] = useState(
    RADIO_BUTTON_VALUES[0].id,
  );
  const [radioPreviewOptionOptIn, setRadioPreviewOptionOptIn] = useState(
    RADIO_BUTTON_VALUES_OPT_IN[0].id,
  );

  const {
    welcomeMessageInitial = {},
    welcomeMessageOptionalEnding = {},
    welcomeAssigned = {},
    welcomeUnassigned = {},
    welcomeVerifiedAssigned = {},
    welcomeVerifiedUnassigned = {},
    welcomeMessageOptional = {},

    welcomeCaseVerifiedAssigned = {},
    welcomeCaseVerifiedUnassigned = {},

    welcomeCaseMessageInitial = {},
    welcomeCaseMessageOptionalEnding = {},
    welcomeCaseAssigned = {},
    welcomeCaseUnassigned = {},
    welcomeCaseMessageOptional = {},
  } = welcomeFlow;

  const optInRequestMessage = useMemo(() => {
    const defaultMessage = `${BEGINNING_MESSAGE} ${LEGAL_MESSAGE}`;
    const apiMessage = `${welcomeMessageInitial?.template} ${LEGAL_MESSAGE} ${welcomeMessageOptionalEnding?.template}`;
    return welcomeMessageInitial?.template
      || welcomeMessageOptionalEnding?.template
      ? apiMessage
      : defaultMessage;
  }, [welcomeMessageInitial, welcomeMessageOptionalEnding]);

  const optInCaseRequestMessage = useMemo(() => {
    const defaultMessage = `${BEGINNING_MESSAGE} ${LEGAL_MESSAGE}`;
    const apiMessage = `${welcomeCaseMessageInitial?.template} ${LEGAL_MESSAGE} ${welcomeCaseMessageOptionalEnding?.template}`;
    return welcomeCaseMessageInitial?.template
      || welcomeCaseMessageOptionalEnding?.template
      ? apiMessage
      : defaultMessage;
  }, [welcomeCaseMessageInitial, welcomeCaseMessageOptionalEnding]);

  const renderTexInitialMessage = useMemo(
    () => (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            constructPreviewText(optInRequestMessage, tokenOverrideMap),
          ),
        }}
      />
    ),
    [optInRequestMessage, tokenOverrideMap],
  );

  const renderCaseTextInitialMessage = useMemo(
    () => (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            constructPreviewText(optInCaseRequestMessage, tokenOverrideMap),
          ),
        }}
      />
    ),
    [optInCaseRequestMessage, tokenOverrideMap],
  );

  const renderAssignedMessage = useMemo(
    () => (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            constructPreviewText(
              welcomeAssigned?.template || ASSIGNED_MESSAGE,
              tokenOverrideMap,
            ),
          ),
        }}
      />
    ),
    [welcomeAssigned, tokenOverrideMap],
  );
  const renderCaseAssignedMessage = useMemo(
    () => (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            constructPreviewText(
              welcomeCaseAssigned?.template || ASSIGNED_MESSAGE,
              tokenOverrideMap,
            ),
          ),
        }}
      />
    ),
    [welcomeAssigned, tokenOverrideMap],
  );

  const renderUnassignedMessage = useMemo(
    () => (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            constructPreviewText(
              welcomeUnassigned?.template || UNASSIGNED_MESSAGE,
              tokenOverrideMap,
            ),
          ),
        }}
      />
    ),
    [welcomeUnassigned, tokenOverrideMap],
  );
  const renderCaseUnassignedMessage = useMemo(
    () => (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            constructPreviewText(
              welcomeCaseUnassigned?.template || UNASSIGNED_MESSAGE,
              tokenOverrideMap,
            ),
          ),
        }}
      />
    ),
    [welcomeCaseUnassigned, tokenOverrideMap],
  );

  const renderOptionalMessage = useMemo(
    () => (welcomeMessageOptional?.template?.trim().length > 0
      && welcomeMessageOptional?.template !== NO_VALUE
      ? (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              constructPreviewText(
                welcomeMessageOptional?.template,
                tokenOverrideMap,
              ),
            ),
          }}
        />
      )
      : null),
    [welcomeMessageOptional, tokenOverrideMap],
  );
  const renderCaseOptionalMessage = useMemo(
    () => (welcomeCaseMessageOptional?.template?.trim().length > 0
      && welcomeCaseMessageOptional?.template !== NO_VALUE
      ? (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              constructPreviewText(
                welcomeCaseMessageOptional?.template,
                tokenOverrideMap,
              ),
            ),
          }}
        />
      )
      : null),
    [welcomeCaseMessageOptional, tokenOverrideMap],
  );

  const renderWelcomeVerifiedAssigned = useMemo(
    () => (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            constructPreviewText(
              welcomeVerifiedAssigned?.template
                || ALREADY_OPT_IN_MESSAGE_ASSIGNED,
              tokenOverrideMap,
            ),
          ),
        }}
      />
    ),
    [welcomeVerifiedAssigned, tokenOverrideMap],
  );

  const renderCaseWelcomeVerifiedAssigned = useMemo(
    () => (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            constructPreviewText(
              welcomeCaseVerifiedAssigned?.template
                || ALREADY_OPT_IN_MESSAGE_ASSIGNED,
              tokenOverrideMap,
            ),
          ),
        }}
      />
    ),
    [welcomeCaseVerifiedAssigned, tokenOverrideMap],
  );

  const renderWelcomeVerifiedUnassigned = useMemo(
    () => (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            constructPreviewText(
              welcomeVerifiedUnassigned?.template
                || ALREADY_OPT_IN_MESSAGE_UNASSIGNED,
              tokenOverrideMap,
            ),
          ),
        }}
      />
    ),
    [welcomeVerifiedUnassigned, tokenOverrideMap],
  );
  const renderCaseWelcomeVerifiedUnassigned = useMemo(
    () => (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            constructPreviewText(
              welcomeCaseVerifiedUnassigned?.template
                || ALREADY_OPT_IN_MESSAGE_UNASSIGNED,
              tokenOverrideMap,
            ),
          ),
        }}
      />
    ),
    [welcomeCaseVerifiedUnassigned, tokenOverrideMap],
  );

  const handleSave = (welcomeFlowParam, isOptedIn = false, caseType = 'claim') => {
    const welcomeFlowKeyMap = {
      true: {
        claim: ['welcomeVerifiedAssigned', 'welcomeVerifiedUnassigned'],
        case: ['welcomeCaseVerifiedAssigned', 'welcomeCaseVerifiedUnassigned'],
      },
      false: {
        case: [
          'welcomeCaseMessageInitial',
          'welcomeCaseMessageOptionalEnding',
          'welcomeCaseAssigned',
          'welcomeCaseUnassigned',
          'welcomeCaseMessageOptional',
        ],
        claim: [
          'welcomeMessageInitial',
          'welcomeMessageOptionalEnding',
          'welcomeAssigned',
          'welcomeUnassigned',
          'welcomeMessageOptional',
        ],
      },
    };

    const keysToUpdate = welcomeFlowKeyMap[isOptedIn][caseType];

    const updatedWelcomeFlow = keysToUpdate.reduce((acc, key) => {
      acc[key] = {
        ...(welcomeFlow?.[key] || {}),
        ...(welcomeFlowParam?.[key] || {}),
      };
      return acc;
    }, {});

    dispatch(
      updateOrgSetting(
        {
          organizationId: organization?._id,
          lineOfBusinessId,
          welcomeFlow: updatedWelcomeFlow,
        },
        'Opt-In Messages Saved.',
        'Error Saving Opt-In Messages. Please Try Again.',
      ),
    );
  };

  return (
    <section className={`${className} optInMessagesWrap`}>
      <header>
        <h1>Opt-In Messages</h1>
      </header>
      <p className="orgSettingsInfo">{instructionText}</p>
      <Tabs defaultActiveKey="Claim" showTabBar>
        <Tab eventKey="Claim" title="Claim">
          <section className="usersMessageWrap">
            <div className="claim-container-opt-in">
              <div className="welcome-flow-card">
                <div className="claim-container">
                  <p>CLAIM</p>
                </div>
                <header>
                  <h2>Customer Not Opted In</h2>
                  <Button data-testid="notOptedInEdit" onClick={() => setShowModal(!showModal)} variant="outline" className="cancelButton" isDisabled={isGettingFullOrganizationData}>
                    Edit
                  </Button>
                </header>
                <p className="infoText">The following messages will send when a new claim is opened and the customer has not yet opted in.</p>
                <Preview
                  previewBanner={previewBanner}
                  isLoading={isGettingFullOrganizationData}
                  firstTextName="Lovable Insurance"
                  firstTextMessage={renderTexInitialMessage}
                  secondTextMessage="YES"
                  thirdTextName="Lovable Insurance"
                  thirdTextMessage={radioPreviewOption === RADIO_BUTTON_VALUES[0].id ? renderAssignedMessage : renderUnassignedMessage}
                  fourthTextName="Lovable Insurance"
                  fourthTextMessage={renderOptionalMessage}
                  previewLabel={<RadioButton testId="notOptedIn" currentValue={!showModal ? radioPreviewOption : null} values={RADIO_BUTTON_VALUES} setCheckedValue={setRadioPreviewOption} isHorizontal />}
                />
                {showModal && (
                  <CustomerNotOptedInModal
                    caseType="claim"
                    tokenOverrideMap={tokenOverrideMap}
                    show={showModal}
                    hideModal={() => setShowModal(false)}
                    welcomeMessageInitial={welcomeMessageInitial?.template === NO_VALUE ? '' : welcomeMessageInitial?.template || BEGINNING_MESSAGE}
                    welcomeAssigned={welcomeAssigned?.template || ASSIGNED_MESSAGE}
                    welcomeUnassigned={welcomeUnassigned?.template || UNASSIGNED_MESSAGE}
                    welcomeMessageOptional={welcomeMessageOptional?.template}
                    welcomeMessageOptionalEnding={welcomeMessageOptionalEnding?.template === NO_VALUE ? '' : welcomeMessageOptionalEnding?.template}
                    onSave={handleSave}
                    isUpdatingOrgSetting={isUpdatingOrgSetting}
                  />
                )}
                {showModalOptIn && (
                  <CustomerOptedInModal
                    caseType="claim"
                    tokenOverrideMap={tokenOverrideMap}
                    show={showModalOptIn}
                    hideModal={() => setShowModalOptIn(false)}
                    welcomeAssigned={welcomeVerifiedAssigned?.template || ALREADY_OPT_IN_MESSAGE_ASSIGNED}
                    welcomeUnassigned={welcomeVerifiedUnassigned?.template || ALREADY_OPT_IN_MESSAGE_UNASSIGNED}
                    onSave={handleSave}
                    isUpdatingOrgSetting={isUpdatingOrgSetting}
                  />
                )}
              </div>
              <div className="welcome-flow-card">
                <div className="claim-container">
                  <p>CLAIM</p>
                </div>
                <header>
                  <h2>Customer Already Opted In</h2>
                  <Button data-testid="optedInEdit" onClick={() => setShowModalOptIn(!showModalOptIn)} variant="outline" className="cancelButton" isDisabled={isGettingFullOrganizationData}>
                    Edit
                  </Button>
                </header>
                <p className="infoText">The following message will send when a new claim is opened and the customer has previously opted in.</p>
                <Preview
                  previewBanner={previewBanner}
                  isLoading={isGettingFullOrganizationData}
                  firstTextName="Lovable Insurance"
                  firstTextMessage={radioPreviewOptionOptIn === RADIO_BUTTON_VALUES_OPT_IN[0].id ? renderWelcomeVerifiedAssigned : renderWelcomeVerifiedUnassigned}
                  previewLabel={
                    <RadioButton testId="optedIn" currentValue={!showModal ? radioPreviewOptionOptIn : null} values={RADIO_BUTTON_VALUES_OPT_IN} setCheckedValue={setRadioPreviewOptionOptIn} isHorizontal />
                  }

                />
              </div>
            </div>
          </section>
        </Tab>
        {useCheckPermissions([ORG_SETTINGS_GENERAL_WELCOME_FLOW]) && !isLobTab ? (
          <Tab eventKey="GeneralCase" title="General Case">
            <section className="usersMessageWrap">
              <div className="claim-container-opt-in">
                <div className="welcome-flow-card">
                  <div className="claim-container">
                    <p>GENERAL CASE</p>
                  </div>
                  <header>
                    <h2>Customer Not Opted In</h2>
                    <Button data-testid="notOptedInEdit" onClick={() => setShowModal(!showModal)} type="outline" className="cancelButton" isDisabled={isGettingFullOrganizationData}>
                      Edit
                    </Button>
                  </header>
                  <p className="infoText">The following messages will send when a general case is opened and the customer has not yet opted in.</p>
                  <Preview
                    previewBanner={previewBanner}
                    isLoading={isGettingFullOrganizationData}
                    firstTextName="Lovable Insurance"
                    firstTextMessage={renderCaseTextInitialMessage}
                    secondTextMessage="YES"
                    thirdTextName="Lovable Insurance"
                    thirdTextMessage={radioPreviewOption === RADIO_BUTTON_VALUES[0].id ? renderCaseAssignedMessage : renderCaseUnassignedMessage}
                    fourthTextName="Lovable Insurance"
                    fourthTextMessage={renderCaseOptionalMessage}
                    previewLabel={<RadioButton testId="notOptedIn" currentValue={!showModal ? radioPreviewOption : null} values={RADIO_BUTTON_VALUES} setCheckedValue={setRadioPreviewOption} isHorizontal />}
                  />
                  {showModal && (
                  <CustomerNotOptedInModal
                    caseType="case"
                    tokenOverrideMap={tokenOverrideMap}
                    show={showModal}
                    hideModal={() => setShowModal(false)}
                    welcomeMessageInitial={welcomeCaseMessageInitial?.template === NO_VALUE ? '' : welcomeCaseMessageInitial?.template || BEGINNING_MESSAGE}
                    welcomeAssigned={welcomeCaseAssigned?.template || ASSIGNED_MESSAGE}
                    welcomeUnassigned={welcomeCaseUnassigned?.template || UNASSIGNED_MESSAGE}
                    welcomeMessageOptional={welcomeCaseMessageOptional?.template}
                    welcomeMessageOptionalEnding={welcomeCaseMessageOptionalEnding?.template === NO_VALUE ? '' : welcomeCaseMessageOptionalEnding?.template}
                    onSave={handleSave}
                    isUpdatingOrgSetting={isUpdatingOrgSetting}
                  />
                  )}
                  {showModalOptIn && (
                  <CustomerOptedInModal
                    caseType="case"
                    tokenOverrideMap={tokenOverrideMap}
                    show={showModalOptIn}
                    hideModal={() => setShowModalOptIn(false)}
                    welcomeAssigned={welcomeCaseVerifiedAssigned?.template || ALREADY_OPT_IN_MESSAGE_ASSIGNED}
                    welcomeUnassigned={welcomeCaseVerifiedUnassigned?.template || ALREADY_OPT_IN_MESSAGE_UNASSIGNED}
                    onSave={handleSave}
                    isUpdatingOrgSetting={isUpdatingOrgSetting}
                  />
                  )}
                </div>
                <div className="welcome-flow-card">
                  <div className="claim-container">
                    <p>GENERAL CASE</p>
                  </div>
                  <header>
                    <h2>Customer Already Opted In</h2>
                    <Button data-testid="optedInEdit" onClick={() => setShowModalOptIn(!showModalOptIn)} type="outline" className="cancelButton" isDisabled={isGettingFullOrganizationData}>
                      Edit
                    </Button>
                  </header>
                  <p className="infoText">The following message will send when a general case is opened and the customer has previously opted in.</p>
                  <Preview
                    previewBanner={previewBanner}
                    isLoading={isGettingFullOrganizationData}
                    firstTextName="Lovable Insurance"
                    firstTextMessage={radioPreviewOptionOptIn === RADIO_BUTTON_VALUES_OPT_IN[0].id ? renderCaseWelcomeVerifiedAssigned : renderCaseWelcomeVerifiedUnassigned}
                    previewLabel={
                      <RadioButton testId="optedIn" currentValue={!showModal ? radioPreviewOptionOptIn : null} values={RADIO_BUTTON_VALUES_OPT_IN} setCheckedValue={setRadioPreviewOptionOptIn} isHorizontal />
                     }
                  />
                </div>
              </div>
            </section>
          </Tab>
        ) : <></>}
      </Tabs>
      <Divider />
    </section>
  );
};

OptInMessages.propTypes = {
  organization: PropTypes.shape({ welcomeFlow: PropTypes.shape({}) }),
  dispatch: PropTypes.func,
  className: PropTypes.string,
  instructionText: PropTypes.string,
  welcomeFlow: PropTypes.shape({}).isRequired,
  lineOfBusinessId: PropTypes.string,
  previewBanner: PropTypes.node,
  tokenOverrideMap: PropTypes.shape({}),
  isLobTab: PropTypes.bool,
};

OptInMessages.defaultProps = {
  organization: { welcomeFlow: {} },
  dispatch: () => {},
  className: '',
  instructionText:
    'Configure text messages automatically sent to a customer when a new claim is opened.',
  lineOfBusinessId: null,
  previewBanner: null,
  tokenOverrideMap: {},
  isLobTab: false,
};

export default OptInMessages;
