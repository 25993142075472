/* eslint-disable react/jsx-filename-extension */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import './Modal.less'
import ModalInner from './ModalInner'
import useOutsideClick from '../hooks/useOutsideClick'

/**
 * Generic Slide Modal element
 * @param   {boolean} show toggles display of the modal
 * @param   {string} title top title of modal
 * @param   {string} size size of modal
 * @param   {function} toggleModal funciton to toggle show
 * @return  {Component}
 */
const SlideModal = (props, ref) => {
  const {
    toggleModal, show, className, backdrop, title, children, size, style, backButton,
  } = props

  const componentRef = useRef(ref)
  useOutsideClick({
    isTriggered: show,
    componentRef,
    action: () => toggleModal && toggleModal(false),
  })

  return show ? (
    <div ref={componentRef} style={style} className={`${className} marley-slide-modal-container`}>
      <ModalInner
        size={size}
        backdrop={backdrop}
        title={title}
        toggleModal={toggleModal}
        backButton={backButton}
      >
        {children}
      </ModalInner>
    </div>
  ) : null
}

SlideModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  backdrop: PropTypes.bool,
  toggleModal: PropTypes.func,
  show: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
}

SlideModal.defaultProps = {
  title: '',
  backdrop: true,
  toggleModal: () => {},
  show: false,
  className: '',
  size: 'lg',
}

export default React.forwardRef(SlideModal)
