import { defineStyleConfig } from '@chakra-ui/react';

const getStatusColor = (status: string) => {
  const statusColorMap: { [key: string]: string } = {
    info: 'blue',
    success: 'green',
    warning: 'orange',
    error: 'red',
    loading: 'blue',
  };

  return statusColorMap[status] || 'blue';
};

export const alertTheme = defineStyleConfig({
  baseStyle: {
    icon: {
      height: '24px',
      width: '24px',
      svg: {
        color: 'unset',
        height: '24px',
        width: '24px',
      },
      marginRight: '16px',
    },
    title: {
      fontWeight: '600',
    },
    container: {
      paddingLeft: '16px',
      paddingRight: '56px',
      fontSize: '16px',
    },
  },
  variants: {
    subtle: (props) => {
      const color = getStatusColor(props.status);

      return {
        container: {
          bg: `${color}.50`,
        },
        icon: {
          color: `${color}.500`,
        },
      };
    },
    loading: (props) => {
      const color = getStatusColor(props.status);

      return {
        container: {
          bg: `${color}.50`,
        },
        icon: {
          color: `${color}.500`,
        },
      };
    },
    'left-accent': (props) => {
      const color = getStatusColor(props.status);

      return {
        container: {
          pl: 3,
          borderColor: `${color}.500`,
          bg: `${color}.50`,
        },
        icon: {
          color: `${color}.500`,
        },
      };
    },
    'top-accent': (props) => {
      const color = getStatusColor(props.status);

      return {
        container: {
          pt: 2,
          borderColor: `${color}.500`,
          bg: `${color}.50`,
        },
        icon: {
          color: `${color}.500`,
        },
      };
    },
  },
});

export default alertTheme;
