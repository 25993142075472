import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Checkbox } from 'react-bootstrap'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import { jobPutRequest } from '../../../actions/job'
import './CloseClaimConfirmation.less'


const CloseClaimConfirmation = ({ claims, jobPutRequest }) => {
  const [openDisabled, setOpenDisabled] = useState(true)
  const sendButtonStates = useMemo(() => ({
    preRequest: {
      disabled: openDisabled,
      type: 'primary',
      text: 'Archive Claim',
    },
    requesting: {
      type: 'secondary',
      text: 'Archiving...',
    },
  }), [openDisabled])

  const cancelButtonStates = {
    success: {
      type: 'default',
      text: 'Ok',
    },
    error: {
      type: 'default',
      text: 'Ok',
    },
  }

  return (
    <ConfirmationModal
      className="close-claim-confirmation"
      id="close"
      requestId="updateJob"
      title="Are you sure you want to archive this claim?"
      successText="Claim Archived"
      open={(id) => {
        let job = _.get(claims, id)
        if (job) {
          job = { ...job, isOpen: false }
          jobPutRequest(job)
        }
      }}
      close={() => setOpenDisabled(true)}
      openButtonStates={sendButtonStates}
      cancelButtonStates={cancelButtonStates}
      footer={(
        <div className="confirmation-claim-close-checkbox">
          <Checkbox onChange={(e) => setOpenDisabled(!e.target.checked)}>
            {'Yes, I\'m sure.'}

          </Checkbox>
        </div>
        )}
    >
      <span>You can reopen this claim through the action button in the archived claims list.</span>
    </ConfirmationModal>
  )
}

CloseClaimConfirmation.propTypes = {
  jobPutRequest: PropTypes.func,
  claims: PropTypes.object,
}

CloseClaimConfirmation.defaultProps = {}

export default connect((state) => ({ claims: _.get(state, 'jobs.listById', {}) }), { jobPutRequest })(CloseClaimConfirmation)
