import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@himarley/unity';
import _ from 'lodash';
import Ghosts from '../../../../elements/Ghosts/Ghosts';
import '../../../../confirmationModals/ConfirmationModal/ConfirmationModal.less';
import './Notes.less';
import NoteInput from './NoteInput';
import DeleteNoteModal from './DeleteNoteModal';
import NotesList from './NotesList';
import { getChatParticipants } from '../../../../../actions/chat';
import {
  addReply,
  deleteReply,
  readNoteMention,
} from '../../../../../actions/job';
import usePermissionVerify from '../../../../elements/hooks/usePermissionVerify';
import { NOTE_REPLIES_ENABLED } from '../../../../../constants/permissions'

const Notes = ({
  authId,
  getNotes,
  deleteNote,
  createNote,
  activeCase,
  notes,
  users,
  activeChatId,
  getChatParticipants: getChatParticipantsProp,
  readNoteMention: readNoteMentionProp,
  addReply,
  deleteReply,
}) => {
  const componentRef = useRef(null);
  const activeCaseId = activeCase?.id;
  const [showCreateNote, setShowCreateNote] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [activeNoteIds, setActiveNoteIds] = useState({
    noteId: null,
    replyId: null,
  });
  const [isReplyDeleteModal, setIsReplyDeleteModal] = useState(false);

  useEffect(() => {
    if (getChatParticipantsProp && activeChatId) {
      getChatParticipantsProp(activeChatId);
    }
  }, [activeChatId, getChatParticipantsProp]);

  useEffect(() => {
    if (getNotes) getNotes(activeCaseId);
  }, [activeCaseId, getNotes]);

  const isLoading = _.get(notes, 'isLoading', false);

  const isPrivate = useMemo(
    () => activeCase?.privacy === 'private',
    [activeCase?.privacy],
  );

  const handleHide = () => {
    setShowDeleteConfirmation(false);
  };

  const handleDeleteModal = (show, isReply = false) => {
    setIsReplyDeleteModal(isReply);
    setShowDeleteConfirmation(show);
  };

  const handleDelete = () => {
    const { noteId, replyId } = activeNoteIds;
    if (isReplyDeleteModal) {
      deleteReply({
        caseId: activeCaseId,
        noteId,
        chatId: activeChatId,
        replyId,
      });
    } else {
      deleteNote({
        caseId: activeCaseId,
        noteId,
        chatId: activeChatId,
      });
    }
    handleHide();
  };

  const permissionVerification = usePermissionVerify({ neededPermissions: [NOTE_REPLIES_ENABLED] });
  const hasNotesReplies = permissionVerification?.hasAccess;

  return (
    <div className="marley-notes" ref={componentRef}>
      <DeleteNoteModal
        show={showDeleteConfirmation}
        hideModal={handleHide}
        noteIds={activeNoteIds}
        isReply={isReplyDeleteModal}
        handleDelete={handleDelete}
      />
      {!showCreateNote && (
        <div className="marley-notes-header">
          <Button
            data-testid="new-note-button"
            data-test="new-note-button"
            className="downloadIcon"
            onClick={() => setShowCreateNote(true)}
            type="outline"
          >
            Add a Note
          </Button>
        </div>
      )}
      <div className="marley-notes-body">
        {showCreateNote && (
          <NoteInput
            activeCaseId={activeCaseId}
            createNote={createNote}
            isPrivate={isPrivate}
            users={users}
            setShowCreateNote={setShowCreateNote}
          />
        )}
        {isLoading && <Ghosts className="marley-note" numItems={3} />}
        {!isLoading
        && _.get(notes, 'notes', []).length === 0
        && !showCreateNote ? (
          <div className="marley-note">
            <div
              data-testid="marley-note-none"
              data-test="marley-note-none"
              className="marley-note-none"
            >
              We&apos;re looking, but no notes found.
            </div>
          </div>
          ) : (
            !isLoading && (
            <NotesList
              setShowDeleteConfirmation={handleDeleteModal}
              hasNotesReplies={hasNotesReplies}
              setActiveNoteIds={setActiveNoteIds}
              authId={authId}
              activeCase={activeCase}
              notes={notes}
              addReply={addReply}
              readNoteMention={readNoteMentionProp}
            />
            )
          )}
      </div>
    </div>
  );
};

Notes.propTypes = {
  authId: PropTypes.string.isRequired,
  getNotes: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  activeCase: PropTypes.shape({
    id: PropTypes.string,
    privacy: PropTypes.string,
  }).isRequired,
  notes: PropTypes.shape([]).isRequired,
  users: PropTypes.shape([]).isRequired,
  activeChatId: PropTypes.string.isRequired,
  getChatParticipants: PropTypes.func.isRequired,
  readNoteMention: PropTypes.func.isRequired,
  addReply: PropTypes.func,
  deleteReply: PropTypes.func,
};

Notes.defaultProps = {
  addReply: () => {},
  deleteReply: () => {},
};

const mapStateToProps = (state, ownProps) => {
  const { activeCase } = ownProps;
  const isPrivate = activeCase?.privacy === 'private';
  const activeChatId = _.get(state, 'jobs.activeChat.id', '');
  const privateUsers = _.get(
    state,
    `jobs.chats.${activeChatId}.participants`,
    [],
  ).map((participant) => ({
    id: participant?.id,
    display: participant?.name,
    imageUrl: participant?.imageUrl,
  }));
  const publicUsers = _.get(state, 'operators.list')
    ?.slice(0, 20)
    .map((participant) => ({
      id: participant?.id,
      display: participant?.name,
      imageUrl: participant?.imageUrl,
    }));
  return {
    activeChatId,
    users: isPrivate ? privateUsers : publicUsers,
  };
};

export default connect(mapStateToProps, {
  getChatParticipants,
  readNoteMention,
  addReply,
  deleteReply,
})(Notes);
