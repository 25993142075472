import { useState } from 'react'

/**
 * useKeyTrigger
 * hook to allow triggered actions by key input
 * @param {array} triggerActions array of action triggers
 * @param {function} triggerActions.replacementAction action to take
 * @param {boolean} triggerActions.trigger passed { keyCode, isShift } to determine isTriggered
 * @param {boolean} triggerActions.preventDefault do prevent default?
* */
const useKeyTrigger = (args) => {
  const { triggerActions } = args || {}
  const [isTriggered, setIsTriggered] = useState(false)

  const handleKeyTrigger = (e) => {
    const { keyCode, shiftKey } = e
    triggerActions.forEach((triggerAction) => {
      const { replacementAction, trigger, preventDefault } = triggerAction
      const status = trigger({ keyCode, isShift: shiftKey })
      if (status) {
        if (replacementAction) {
          replacementAction()
          if (preventDefault) e.preventDefault()
        } else {
          setIsTriggered(status)
        }
      }
    })
  }

  const resetTrigger = () => {
    setIsTriggered(false)
  }

  return { handleKeyTrigger, isTriggered, resetTrigger }
}

export default useKeyTrigger
