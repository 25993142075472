import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap'
import { handleFormInputChange } from '../../../actions/common'
import { setBranding } from '../../../actions/ui'
import { updateUserProperties } from '../../../actions/profile'
import Required from '../../icons/Required'
import LineDropdown from '../../AssignDropdown/LineDropdown/LineDropdown'

class BrandedCopy extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      isBrandOpen: false,
      value: ''
    }

    const editObject = props.editObject || {}
    this.metaDataBrandLocation = 'metaData.c54f88b2-fee1-4216-8c6e-7a7aa5e83a3e'

    if (editObject.branding) {
      props.handleFormInputChange({
        inputId: this.metaDataBrandLocation,
        type: props.type,
        value: editObject.branding,
      })
    } else if (this.props.brandId) {
      this.props.handleFormInputChange({
        inputId: this.metaDataBrandLocation,
        type: this.props.type,
        value: this.props.brandId
      })
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    console.log('initial props', nextProps.brandId)
    if (nextProps.brandId !== this.props.brandId) {
      this.props.handleFormInputChange({
        inputId: this.metaDataBrandLocation,
        type: nextProps.type,
        value: nextProps.brandId
      })
    }
  }

  render() {
    const { isBrandOpen } = this.state
    const props = this.props
    const branding = props.branding.map((str, index) => ({ label: str, id: index + 1 }));
    const initialSelect = branding.find(b => b.label === props.brandId)
    return (
      <div key="bc" className={`enter-${props.id}-row form-input`}>
        <div className="enter-form-branded-copy-dropdown">
          <FormGroup validationState={props.error ? 'error' : null}>
            <ControlLabel>
              Insurance Brand
              {props.required && <Required/>}
            </ControlLabel>
            <LineDropdown
              type='brand'
              selectedId={this.state.value || _.get(initialSelect, 'id')}
              defaultLabel="Select brand"
              open={isBrandOpen}
              onToggle={value => this.setState({ isBrandOpen: value })}
              disabled={this.props.readonly || this.props.isEdit}
              handleSelect={
                (value) => {
                  const label = branding.find(brand => brand.id === value).label
                  this.props.toggleDisableParentFormSubmit(false)
                  this.props.updateUserProperties({ brandId: label })
                  this.props.handleFormInputChange({
                    inputId: this.metaDataBrandLocation,
                    type: props.type,
                    value: label
                  })
                  this.setState({ isBrandOpen: !isBrandOpen, value: value })
                  this.props.setBranding({ value })
                }
              }
              options={branding}
            />
            <HelpBlock><span className={props.error && 'helpblock-error'}>{props.error && props.errorMessage}</span></HelpBlock>
          </FormGroup>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  editObject: _.get(state, `${props.type.stateLocation()}.editObject`),
  error: _.get(state, `${props.type.stateLocation()}.errors`, []).find(e => e === 'branding'),
  branding: _.get(state, 'organizations.branding', []),
  brandId: _.get(state, 'profile.properties.brandId'),
})

export { BrandedCopy }

export default connect(mapStateToProps, { handleFormInputChange, updateUserProperties, setBranding })(BrandedCopy)

