import React from 'react';

import FileIcon from '@images/icons/icon-file-generic.svg';
import PDFIcon from '@images/icons/icon-file-pdf.svg';

import ContactCard from './MediaContactCard';
import type { MediaItem } from '../types';

const ICON_WIDTH_THRESHOLD = 400;

const embeddedItem = ({
  height,
  width,
  fileUrl,
}: {
  height: number;
  width: number;
  fileUrl: string;
}) => (
  <embed
    src={fileUrl}
    width={width}
    height={height}
  />
);

interface MediaGenericProps {
  mediaItem: MediaItem;
  containerDimensions: {
    height: number;
    width: number;
  };
  fileUrl: string;
}

const MediaGeneric: React.FC<MediaGenericProps> = ({
  mediaItem,
  containerDimensions,
  fileUrl,
}) => {
  const { width, height } = containerDimensions;
  const { contentType } = mediaItem.mediaInfo;
  const style = {
    width,
    height,
  };
  switch (contentType.toLowerCase()) {
    case 'text/x-vcard':
      return (
        <ContactCard
          fileUrl={fileUrl}
          mediaItem={mediaItem}
          containerDimensions={containerDimensions}
        />
      );
    case 'application/pdf': {
      return (
        width > ICON_WIDTH_THRESHOLD
          ? embeddedItem({ height, width, fileUrl })
          : (
            <PDFIcon
              aria-label="pdf-file"
              style={style}
            />
          )
      );
    }
    default:
      return (
        <FileIcon
          aria-label="generic-file"
          style={style}
        />
      );
  }
};

export default MediaGeneric;
