import { InfoIconCircle } from '@himarley/unity';
import React from 'react';

import usePermissionVerify from '@app/components/elements/hooks/usePermissionVerify';
import { FEATURE_FLAG_VIRUS_SCANNING } from '@app/constants/permissions';
import FileNotLoaded from '@images/icons/fileNotLoaded.svg';

import { VIRUS_SCAN_STATUS } from './constants';
import MediaGeneric from './MediaTypes/media-generic';
import MediaVirus from './MediaTypes/media-virus';
import MediaAudio from './MediaTypes/MediaAudio';
import MediaImage from './MediaTypes/MediaImage';
import MediaVideo from './MediaTypes/MediaVideo';
import './MediaItem.less';
import type { MediaItem as MediaItemType } from './types';

interface MediaItemProps {
  className?: string;
  file: {
    loadingState: string;
    localFileUrl: string;
    contentType: string;
    virusScanStatus?: string;
  };
  onExpand?: () => void;
  mediaItem: MediaItemType;
  isThumbnail: boolean;
  containerDimensions: {
    height: number;
    width: number;
  };
  isImageRedacted: boolean;
}

const MediaItem: React.FC<MediaItemProps> = ({
  className = '',
  file,
  onExpand = null,
  isThumbnail,
  mediaItem,
  containerDimensions,
  isImageRedacted,
}) => {
  const {
    loadingState,
    localFileUrl,
    contentType,
    virusScanStatus,
  } = file || {};
  const {
    rotation,
    providerId,
    mediaInfo,
  } = mediaItem || {};
  const isVertical = rotation === 90 || rotation === 270;
  const adjustedWidth = isVertical ? containerDimensions.height : containerDimensions.width;
  const adjustedHeight = isVertical ? containerDimensions.width : containerDimensions.height;

  const { hasAccess: hasVirusScanningEnabled } = usePermissionVerify({
    neededPermissions: [FEATURE_FLAG_VIRUS_SCANNING],
  });

  const virusScanQueuedOrInfected = hasVirusScanningEnabled && (
    virusScanStatus === VIRUS_SCAN_STATUS.INFECTED
    || virusScanStatus === VIRUS_SCAN_STATUS.QUEUED);

  const renderMediaItem = () => {
    const type = contentType?.split('/')[0];

    if (virusScanQueuedOrInfected) {
      return (
        <MediaVirus
          containerDimensions={containerDimensions}
          isBlocked={virusScanStatus === VIRUS_SCAN_STATUS.INFECTED}
          fileUrl={localFileUrl}
          providerId={providerId}
        />
      );
    }

    switch (type) {
      case 'image':
        return (
          <>
            { mediaInfo?.exif && <img src={InfoIconCircle} alt="media-item-info-icon" className="media-item-info-icon" /> }
            <MediaImage
              fileUrl={localFileUrl}
              providerId={providerId}
              isThumbnail={!!onExpand}
            />
          </>
        );
      case 'video':
        return (
          <MediaVideo
            fileUrl={localFileUrl}
            providerId={providerId}
            isThumbnail={isThumbnail}
          />
        );
      case 'audio':
        return (
          <MediaAudio
            fileUrl={localFileUrl}
            providerId={providerId}
            isThumbnail={isThumbnail}
          />
        );
      default:
        return (
          <MediaGeneric
            mediaItem={mediaItem}
            fileUrl={localFileUrl}
            containerDimensions={containerDimensions}
          />
        );
    }
  };

  const wrapMediaInButton = () => (
    onExpand && !isImageRedacted && !virusScanQueuedOrInfected
      ? (
        <button
          type="button"
          onClick={onExpand}
          className="media-expand-button"
          aria-label="expand media item"
          data-testid={`media-expand-button-${providerId}-${adjustedWidth}`}
        >
          {renderMediaItem()}
        </button>
      )
      : (renderMediaItem())
  );

  const contentToRender = () => {
    let mediaContent;
    if (loadingState === 'loading') {
      mediaContent = <div className="media-loading" data-testid={`media-loading-${providerId}`} />;
    } else if (loadingState === 'loaded') {
      mediaContent = wrapMediaInButton();
    } else {
      mediaContent = (
        <FileNotLoaded />
      );
    }
    return mediaContent;
  };
  return (
    <div
      className={`media-item-container ${className} rotate${rotation} ${virusScanQueuedOrInfected ? 'virus-scan-media-item' : ''}`}
      data-testid={`media-item-container-${providerId}`}
      style={{
        maxWidth: `${adjustedWidth}px`,
        maxHeight: `${adjustedHeight}px`,
      }}
    >
      {
        contentToRender()
      }
    </div>
  );
};

export default MediaItem;
