import React from 'react'
import { Modal } from 'react-bootstrap'

class MarleyModal extends React.Component {
  render() {
    const { toggleModal, show, className, backdrop, title, children, pageProps } = this.props

    const childrenWithProps = React.cloneElement(children, { show, toggleModal, pageProps })
    return (
      <Modal className={className} show={show} onHide={toggleModal} backdrop={ backdrop || true }>
        <div className="header-modal">
          <div className="header">{title}</div>
          <button className="cls-btn" onClick={toggleModal} />
        </div>
        <div className="body-modal">
          {childrenWithProps}
        </div>
      </Modal>
    )
  }
}

export default MarleyModal
