const initialize = (user) => {
  try {
    if (typeof FS !== 'undefined' && user._id && user.firstName && user.lastName) { // eslint-disable-line no-undef
      // just for storybook to work we access its global
      // eslint-disable-next-line no-undef
      FS.identify(user._id, {
        displayName: `${user.firstName} ${user.lastName}`,
        email: user.email,
      })
    }
  } catch(e){
    console.error(e)
  }
}

module.exports = {
  initialize
}
