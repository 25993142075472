export const cleanPhone = (phone) => (phone || '').replace('+1', '').replace(/\D/g, '');

export const formatPhoneNumber = (phone) => {
  // returns phone number in the format +1 (123) 456-7890
  const numbers = cleanPhone(phone);
  const char = { 0: '(', 3: ') ', 6: '-' };
  let formatted = '+1 ';
  for (let i = 0; i < numbers.length; i++) {
    formatted += (char[i] || '') + numbers[i];
  }
  return formatted.slice(0, 17);
};

export const formatPhoneNumberV2 = (phone) => {
  // returns phone number in the format (123) 456-7890
  const numbers = phone.replace('+1', '').replace(/\D/g, '');
  const char = { 0: '(', 3: ') ', 6: '-' };
  let formatted = '';
  for (let i = 0; i < numbers.length; i++) {
    formatted += (char[i] || '') + numbers[i];
  }
  return formatted.slice(0, 14);
};

export const formatName = ({ firstName, lastName }) => `${lastName}, ${firstName}`;

export const stub = () => {};

export const buildUrlQuery = (data) => {
  const urlQuery = Object.keys(data)
    .map((q) => data[q]
        && (Array.isArray(data[q])
          ? `${q}[]=${data[q].join(`&${q}[]=`)}`
          : `${encodeURIComponent(q)}=${encodeURIComponent(data[q])}`))
    .join('&');
  return urlQuery;
};

export const capitalizeEachWord = (strInput = '', separator = ' ') => {
  if (strInput?.trim()?.length < 1 || !strInput) {
    return '';
  }
  const trimmedString = strInput.trim();
  let result = '';
  trimmedString?.split(separator)?.forEach((word, i) => {
    if (word?.trim()?.length > 0) {
      if (i !== 0) {
        result += ' ';
      }
      // eslint-disable-next-line no-unsafe-optional-chaining
      result += `${word[0]?.toUpperCase() + word?.substring(1)}`;
    }
  });
  return result;
};

export const capitalizeFirstLetter = (item) => {
  if (typeof item !== 'string') return '';
  return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
};

export const formatBrandLobs = (linesOfBusiness) => {
  if (!linesOfBusiness || linesOfBusiness.length === 0) {
    return {};
  }

  const brandLobs = {};
  for (let i = 0; i < linesOfBusiness.length; i++) {
    const { branding, type } = linesOfBusiness[i];
    if (!brandLobs[branding]) {
      brandLobs[branding] = {};
    }
    if (!brandLobs[branding][type]) {
      brandLobs[branding][type] = [];
    }
    linesOfBusiness[i].label = capitalizeFirstLetter(linesOfBusiness[i].displayName);
    brandLobs[branding][type].push(linesOfBusiness[i]);
  }
  return brandLobs;
};

export const truncateText = (string, length) => {
  if (!string) return '';
  if (string.length > length) {
    return `${string.substring(0, length)}...`;
  }
  return string;
};

export const middleTruncateText = (inputString, maxLength) => {
  if (!inputString) return '';
  if (inputString.length <= maxLength) {
    return inputString;
  }

  const ellipsis = '...';
  const halfMaxLength = Math.floor((maxLength - ellipsis.length) / 2);
  const truncatedStart = inputString.slice(0, halfMaxLength);
  const truncatedEnd = inputString.slice(-halfMaxLength);

  return truncatedStart + ellipsis + truncatedEnd;
};
