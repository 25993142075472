const MULTI_PARTY_CHAT = 'MULTI_PARTY_CHAT'
export const CHANGE_PHONE_NUMBER = `${MULTI_PARTY_CHAT}:CHANGE_PHONE_NUMBER`
export const CHANGE_FIRST_NAME = `${MULTI_PARTY_CHAT}:CHANGE_FIRST_NAME`
export const CHANGE_LAST_NAME = `${MULTI_PARTY_CHAT}:CHANGE_LAST_NAME`
export const CHANGE_RELATIONSHIP = `${MULTI_PARTY_CHAT}:CHANGE_RELATIONSHIP`
export const SET_PUT_CHAT_PARTICIPANT_STATUS = `${MULTI_PARTY_CHAT}:SET_PUT_CHAT_PARTICIPANT_STATUS`
export const FRAGMENT_PUT_CHAT_PARTICIPANT = `${MULTI_PARTY_CHAT}:PUT_CHAT_PARTICIPANT`
export const REQUEST_PUT_CHAT_PARTICIPANT_INIT = `REQUEST_${FRAGMENT_PUT_CHAT_PARTICIPANT}_INIT`
export const REQUEST_PUT_CHAT_PARTICIPANT_SUCCESS = `REQUEST_${FRAGMENT_PUT_CHAT_PARTICIPANT}_SUCCESS`
export const REQUEST_PUT_CHAT_PARTICIPANT_ERROR = `REQUEST_${FRAGMENT_PUT_CHAT_PARTICIPANT}_ERROR`
export const REQUEST_CHAT_REMOVE_USER_INIT = 'REQUEST_CHAT_REMOVE_USER_INIT'
export const REQUEST_CHAT_REMOVE_USER_SUCCESS = 'REQUEST_CHAT_REMOVE_USER_SUCCESS'
export const REQUEST_CHAT_REMOVE_USER_ERROR = 'REQUEST_CHAT_REMOVE_USER_ERROR'
export const SET_CHAT_REMOVE_USER_STATUS = 'SET_CHAT_REMOVE_USER_STATUS'
