/* eslint-disable no-restricted-syntax */
const {
  NEEDS_ATTENTION,
  NEEDS_ACTION,
  FEATURE_FLAG_TOTAL_LOSS_ASSIST,
  NOTES_ACCESS,
  DISPLAY_MESSAGE_STATUS,
} = require('../../../../../constants/permissions');

export const ALL_BRANDS = [
  {
    id: 'allBrands',
    label: 'All Brands',
  },
];

export const ALL_LOBS = [
  {
    id: 'allLobs',
    label: 'All Lines',
  },
];

export const DEFAULT_SELECTED_BRAND = new Map();
DEFAULT_SELECTED_BRAND.set(ALL_BRANDS[0].id, ALL_BRANDS[0].label);

export const DEFAULT_SELECTED_LOB = new Map();
DEFAULT_SELECTED_LOB.set(ALL_LOBS[0].id, ALL_LOBS[0].label);
export const NEW_MENTIONS_REPLIES = 'New Mentions/Replies';

export const ALL_TAG = 'All';
export const TAGS = new Map();
TAGS.set(ALL_TAG, { isSelected: false, id: 'all' });
TAGS.set('Failed to Send', { id: 'failed-to-send', isSelected: false, permission: DISPLAY_MESSAGE_STATUS });
TAGS.set('Has a Question', { id: 'has-question', isSelected: false, permission: NEEDS_ACTION });
TAGS.set('Locked Out', { id: 'locked-out', isSelected: false });
TAGS.set(NEW_MENTIONS_REPLIES, {
  id: 'my-note-mentions-and-replies',
  isSelected: false,
  permission: NOTES_ACCESS,
});
TAGS.set('Needs Attention', { id: 'needs-attention', isSelected: false, permission: NEEDS_ATTENTION });
TAGS.set('Not Opted In', { id: 'not-opted-in', isSelected: false });
TAGS.set('Opted Out', { id: 'opted-out', isSelected: false });
TAGS.set('Copart', { id: 'total-loss', isSelected: false, permission: FEATURE_FLAG_TOTAL_LOSS_ASSIST });
TAGS.set('Release Issue', { id: 'release-issue', isSelected: false, permission: FEATURE_FLAG_TOTAL_LOSS_ASSIST });

export const ALL_CASES = 'allCases';
export const ACTIVE_CASES = 'activeCases';

export const CASE_STATUS_ITEMS = [
  {
    label: 'All Open Cases',
    value: ALL_CASES,
  },
  {
    label: 'Active Cases',
    value: ACTIVE_CASES,
  },
];

export const getValidTags = (permissionsParam = []) => {
  const tags = new Map(TAGS);
  const permissions = new Set(permissionsParam);

  for (const [tag, { permission }] of tags) {
    if (permission && !permissions.has(permission)) {
      tags.delete(tag);
    }
  }
  return tags;
};

export const toggleTagsDisabledState = (isDisabled = false, permissions = []) => {
  const tags = getValidTags(permissions);

  for (const [tag, properties] of tags) {
    if (tag === ALL_TAG) {
      tags.set(tag, { ...properties, disabled: false, isSelected: true });
    } else {
      tags.set(tag, { ...properties, disabled: isDisabled, isSelected: false });
    }
  }
  return tags;
};
