import {
  IconButton,
  CompactCardIcon,
  ComfortableCardIcon,
  ExpandedCardIcon,
  Tooltip,
} from '@himarley/unity';
import React from 'react';

import styles from './card-density-control.module.scss';
import { CARD_DENSITY } from '../constants';

interface CardDensityControlProps {
  density: string;
  onClick: (display: string) => void;
}

const CardDensityControl: React.FC<CardDensityControlProps> = ({ density, onClick }) => (
  <div
    className={styles.cardDensityWrap}
  >
    <Tooltip
      trigger={(
        <IconButton
          onClick={() => onClick(CARD_DENSITY.EXPANDED)}
          id="cardExpandBtn"
        >
          <ExpandedCardIcon
            className={
              density === CARD_DENSITY.EXPANDED
                ? styles.selected
                : styles.unselected
            }
          />
        </IconButton>
      )}
      tooltip="Expanded View"
    />
    <Tooltip
      trigger={(
        <IconButton
          onClick={() => onClick(CARD_DENSITY.COMFORTABLE)}
          id="cardComfortableBtn"
        >
          <ComfortableCardIcon
            className={
              density === CARD_DENSITY.COMFORTABLE
                ? styles.selected
                : styles.unselected
            }
          />
        </IconButton>
      )}
      tooltip="Comfortable View"
    />
    <Tooltip
      trigger={(
        <IconButton
          onClick={() => onClick(CARD_DENSITY.COMPACT)}
          className={
            density === CARD_DENSITY.COMPACT
              ? styles.selected
              : styles.unselected
          }
          id="cardCompactBtn"
        >
          <CompactCardIcon />
        </IconButton>
      )}
      tooltip="Compact View"
    />
  </div>
);

export default CardDensityControl;
