export const MAX_MIN_VALUE = 999;
export const MIN_MIN_VALUE = 5;

export const constructUserPrefixPreview = (
  isTitleEnabled,
  isCaseIdEnabled,
  isPhoneNumberEnabled
) =>
  `Justin A. ${isTitleEnabled ? '| Sr. Claims Adjuster |' : '|'}${
    isCaseIdEnabled ? ' 987654321 |' : ''
  }${isPhoneNumberEnabled ? ' +555-555-5555 |' : ''} Lovable Insurance:`;

export const snackBarSuccessMessage = 'Org. Settings Saved';
