/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { union, set } from 'lodash';
import DetectBottom from '../../../../elements/table/DetectBottom';
import UserListItem from '../UserListItem';

const UserList = ({
  increaseDataSet,
  users,
  members,
  leads,
  handleFormChange,
}) => (
  <DetectBottom
    className="userList-container"
    onBottom={(b, callback) => b && increaseDataSet && increaseDataSet(callback)}
  >
    {(users || [])
      .filter(
        (u) => !union(members, leads)
          .map((m) => m.id)
          .includes(u.id),
      )
      .map((u) => (
        <UserListItem
          item={u}
          key={u?.id}
          updateList={({ isLead }) => {
            const activeList = isLead ? leads : members;
            const changes = set({}, isLead ? 'leads' : 'members', [
              ...(activeList || []),
              u,
            ]);
            handleFormChange(changes);
          }}
        />
      ))}
  </DetectBottom>
);

UserList.propTypes = {
  increaseDataSet: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  members: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  leads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleFormChange: PropTypes.func.isRequired,
};

UserList.defaultProps = {
  members: null,
  users: null,
  leads: null,
};

export default UserList;
