import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../elements/Modal/Modal';
import Divider from '../../../../elements/Divider/Divider';
import ButtonRow from '../../../../elements/buttons/ButtonRow/ButtonRow';
import Button from '../../../../elements/buttons/Button/Button';
import './ForwardMediaModal.less';

const ForwardMediaModal = ({
  fileUrl,
  forwardMediaStatus,
  onClose,
  onSend,
  show,
  toggleModal,
}) => {
  const hasError = forwardMediaStatus === 'FAILURE';
  const isPending = forwardMediaStatus === 'PENDING';
  const handleToggle = isPending ? () => 0 : toggleModal;
  const sendButtonLabel = isPending ? 'Sending...' : 'Send';

  return (
    <Modal
      size="sm"
      show={show}
      toggleModal={handleToggle}
      title="Forward to All"
    >
      <div className="forward-media-modal-body">
        <div
          className="forward-media-modal-preview"
          data-testid="forward-media-preview"
          style={{ backgroundImage: `url('${fileUrl}')` }}
        />
        <p className="forward-media-modal-message" data-testid="forward-media-message">
          This will forward your media to all participants in this conversation.
        </p>
      </div>
      <Divider />
      <ButtonRow>
        <Button
          data-testid="forward-media-cancel"
          disabled={isPending}
          onClick={onClose}
          type="outline"
        >
          Cancel
        </Button>
        <Button
          data-testid="forward-media-send"
          disabled={isPending}
          onClick={onSend}
          type="positive"
        >
          {sendButtonLabel}
        </Button>
      </ButtonRow>
      {
        hasError && (
          <p
            className="forward-media-modal-error text-danger"
            data-testid="forward-media-error"
          >
            Failed to forward media. Please try again later.
          </p>
        )
      }
    </Modal>
  );
};

ForwardMediaModal.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  forwardMediaStatus: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

ForwardMediaModal.defaultProps = {
  forwardMediaStatus: '',
  show: false,
};

export default ForwardMediaModal;
