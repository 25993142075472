/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { FEATURE_FLAG_VIRUS_SCANNING } from '@app/constants/permissions';
import { savePreviousPermissions, getPreviousPermissions } from '@app/helpers/localStorage';
import globalMediaCacheFactory from '@app/hooks/global-file-cache';
import { hasPermissionChanged } from '@app/helpers/featureFlags';
import useTopicHandler from '../elements/hooks/useTopicHandler';
import { setupSocketClient, getUserByOktaUserId } from '../../actions/auth';
import { useQueryParameters } from './useQueryParameters';
import { usePrefetchQueries } from '../../services/case';
import { getUserProperties } from '../../actions/profile';
import { logoutConfig } from '../../constants/okta';
import styles from './withAuth.module.less';

/**
 * HOC to wrap the entire App component and fetch user information for the UI
 * using the email from the Okta id token. This separates flooding App.js with
 * extra logic
 * @param {*} WrappedComponent
 */
const withAuth = (WrappedComponent) => {
  const Auth = (props) => {
    const { oktaAuth } = useOktaAuth();
    const dispatch = useDispatch();
    const memoizedTopicHandler = useTopicHandler();

    const {
      logout,
      authId,
      permissions,
    } = useSelector((state) => ({
      logout: state?.auth?.logout,
      authId: state?.auth?.user?._id,
      permissions: state?.auth?.permissions,
    }));

    useEffect(() => {
      // invalidate media cache when FEATURE_FLAG_VIRUS_SCANNING is changed
      const clearMediaCache = async () => {
        const globalFileCache = await globalMediaCacheFactory();
        await globalFileCache?.deleteAllItems();
      };
      if (authId && permissions) {
        const prevPermissions = getPreviousPermissions(authId);
        if (hasPermissionChanged(prevPermissions, permissions, FEATURE_FLAG_VIRUS_SCANNING)) {
          clearMediaCache();
        }
        savePreviousPermissions(authId, permissions);
      }
    }, [authId, dispatch, permissions]);

    useEffect(() => {
      dispatch(setupSocketClient(memoizedTopicHandler));
      const idToken = oktaAuth.getIdToken();
      const decodedIdToken = idToken ? jwt_decode(idToken) : null;
      const oktaUserId = decodedIdToken?.preferred_username;
      dispatch(getUserByOktaUserId(oktaUserId));
      dispatch(getUserProperties());
    }, []);

    useEffect(() => {
      if (!logout) {
        return;
      }
      const oktaLogout = async () => oktaAuth.signOut(logoutConfig);
      oktaLogout();
    }, [
      oktaAuth,
      logout,
    ]);

    usePrefetchQueries();
    useQueryParameters();

    return (
      <div className={styles.withAuth}>
        {!logout && <WrappedComponent {...props} />}
      </div>
    );
  };

  return Auth;
};

export default withAuth;
