/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar } from '@himarley/unity';
import get from 'lodash/get';
import includes from 'lodash/includes';
import set from 'lodash/set';

import EditableLine from '../../../../../elements/EditableLine/EditableLine';
import EditIcon from '../../../../../icons/EditIcon';
import TextAreaInput from '../../../../../elements/form/TextAreaInput/TextAreaInput';
import UserPhoto from '../../../../../UserPhoto2/UserPhoto2';
import IconButton from '../../../../../elements/buttons/IconButton/IconButton';
import CollapsibleSection from '../../../../../CollapsibleSection/CollapsibleSection';
import { updateItem } from '../../../../../../actions/common';
import useForm from '../../../../../elements/hooks/useForm';

import './InfoPanel.less';

const GroupLead = (
  { name } // eslint-disable-line
) => (
  <div data-test="group-lead" data-testid="group-lead" className="group-lead">
    <UserPhoto name={name} />
    {name}
  </div>
);

const DESC_EDIT = 'DESC_EDIT';

const formValidGroupForPut = (group) => {
  const modifiedGroup = {};
  const objectToIds = ['leads', 'members']; // endpoint accepts ids, but this comes in as objects
  const extractId = (i, k) => get(i, k, []).map((g) => g?.id);
  Object.keys(group).forEach((key) => {
    const value = includes(objectToIds, key)
      ? extractId(group, key)
      : get(group, key);
    set(modifiedGroup, key, value);
  });

  return modifiedGroup;
};

const InfoPanel = (props) => {
  const { group, toggleShow } = props;
  const [mode, setMode] = useState();
  const { handleFormChange, formObject } = useForm({
    item: formValidGroupForPut(group),
    validFields: [
      'archived',
      'description',
      'id',
      'leads',
      'members',
      'name',
      'organization',
    ],
  });

  const handleUpdate = (delta) => {
    const { updateItem: updateItemProp } = props;
    handleFormChange(delta);
    updateItemProp('GROUP', { ...formObject, ...delta }, formObject.id);
  };

  useEffect(() => {
    handleFormChange(group);
  }, [group]);

  const { name, description } = formObject || {};
  const leads = get(group, 'leads', []);
  const memberCount = group.count;
  const topLeads = leads.slice(0, 2);
  const remainingLeads = leads.slice(2);

  return (
    <div className={`info-panel ${mode ? 'info-panel-edit' : ''}`}>
      <div className="group-name">
        <EditableLine
          onBlur={(e) => handleUpdate({ name: get(e, 'target.value', '') })}
          value={name}
          id="name"
        />
      </div>

      <div data-test="initials">
        <Avatar name={name} className="groupAvatar" />
      </div>

      <div className="group-description group-editable">
        Group Description
        <IconButton
          data-test="group-description-edit-button"
          className={`group-description-edit-button group-edit-icon-button ${
            mode === DESC_EDIT ? 'group-edit-icon-button-active' : ''
          }`}
          onClick={() => {
            if (mode === DESC_EDIT) handleUpdate({ description });
            setMode(mode === DESC_EDIT ? null : DESC_EDIT);
          }}
        >
          <EditIcon className="edit" />
        </IconButton>
      </div>
      <div data-test="group-description" className="description">
        {mode !== DESC_EDIT ? (
          description
        ) : (
          <TextAreaInput
            data-test="description-textarea"
            placeholder="Group description (150 characters max)"
            onChange={(e) => {
              handleFormChange({ description: get(e, 'target.value', '') });
            }}
            onBlur={(e) => {
              handleUpdate({ description: get(e, 'target.value', '') });
            }}
            value={description}
            maxLength={150}
          />
        )}
      </div>

      <div data-test="group-count" data-testid="group-count" className="group-count">
        {memberCount || 0}
        {' '}
        Member
        {memberCount === 1 ? '' : 's'}
      </div>
      <hr />
      <div className="group-leads-title">Group Leads</div>
      <div data-test="group-leads" className="group-leads">
        {topLeads.map((l) => (
          <GroupLead key={l.id} name={l.name} />
        ))}
        {remainingLeads && remainingLeads.length > 0 && (
          <CollapsibleSection toggleShow={toggleShow}>
            {remainingLeads
              && remainingLeads.map((l) => <GroupLead key={l.id} name={l.name} />)}
          </CollapsibleSection>
        )}
      </div>
    </div>
  );
};

InfoPanel.propTypes = {
  formObject: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
  }),
  group: PropTypes.instanceOf(Object),
  updateItem: PropTypes.func.isRequired,
  toggleShow: PropTypes.func,
};

InfoPanel.defaultProps = {
  group: {},
  formObject: {
    id: '',
    description: '',
  },
  toggleShow: () => {},
};

export default connect(null, { updateItem })(InfoPanel);
