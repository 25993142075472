import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import './ChatBotMessage.less'
import Button from '../../../../Form/Button/Button'

// Don't know why this is showing a linting problem but it works as expected
// eslint-disable-next-line import/named
import { hideButtonsAction, showButtonsAction, chatBotTakeOver } from '../../../../../actions/chatLog'
import MarleyAssistantMessage from '../MarleyAssistantMessage/MarleyAssistantMessage'

class ChatBotMessage extends Component {
  render() {
    const { messageBody, leftButtonText, rightButtonText,
      showButtons, hideButtonsAction, recipientId, continueText, showButtonsAction,
      chatBotTakeOver, isTakingOverChatbot
    } = this.props
    const willShowButtons = showButtons[recipientId]
    return (
      <MarleyAssistantMessage>
          <div className="message-body">{messageBody}</div>
          { willShowButtons ?
            <div className="message-buttons">
              <Button className="left-message-button" onClick={chatBotTakeOver} type="black" disabled={isTakingOverChatbot}>{ isTakingOverChatbot ? 'Loading...' : leftButtonText }</Button>
              <Button className="right-message-button" onClick={hideButtonsAction}type="outline" >{ rightButtonText }</Button>
            </div> :
            <div className="undo">
              <div className="undo-message"> {continueText}</div><div className="undo-button" onClick={showButtonsAction}>Undo action</div>
            </div>
          }
      </MarleyAssistantMessage>
    )
  }
}

ChatBotMessage.propTypes = {
  messageBody: PropTypes.string,
  leftButtonText: PropTypes.string,
  rightButtonText: PropTypes.string,
  showButtons: PropTypes.object,
  hideButtonsAction: PropTypes.func,
  chatBotTakeOver: PropTypes.func,
  showButtonsAction: PropTypes.func,
  recipientId: PropTypes.string,
  continueText: PropTypes.string,
}
const defaultActionFunction = () => {}

ChatBotMessage.defaultProps = {
  messageBody: '',
  rightButtonText: 'Right Button',
  leftButtonText: 'Left Button',
  showButtons: {},
  hideButtonsAction: defaultActionFunction,
  chatBotTakeOver: defaultActionFunction,
  recipientId: '',
  continueText: '',
  showButtonsAction: defaultActionFunction,
}

const mapStateToProps = state => ({
  showButtons: _.get(state, 'chatLog.showButtons', {}),
  isTakingOverChatbot: _.get(state, 'chatLog.isTakingOverChatbot', {}),
})


const mapDispatchToProps = {
  hideButtonsAction,
  showButtonsAction,
  chatBotTakeOver,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBotMessage)
