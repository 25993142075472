import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import UserRow from '../../UserRow/UserRow'
import AssignUserDropdown from '../../../../elements/dropdowns/AssignUserDropdown/AssignUserDropdown'
import Button from '../../../../elements/buttons/Button/Button'

const Participants = ({
  participants, removeUser, activeCase, jobPutRequest,
}) => {
  const claimantId = _.get(activeCase, 'customer.id')
  const assignedOperator = _.get(activeCase, 'assignedOperator')
  const nonAssignedParticipants = participants.filter((p) => (
    assignedOperator.id ? p.id !== assignedOperator.id : true))
  const isAssigned = _.get(assignedOperator, 'id')

  return (
    <div className="participants">
      {!isAssigned && (
      <UserRow
        className="unassigned-user-row"
        key="unassigned"
        user={{ name: 'Not Applicable' }}
        highlightRole
      >
        <div className="assigned-operator-role">Unassigned Case</div>
        <AssignUserDropdown onSelect={(id) => jobPutRequest(id)} assignedOperator={{ name: 'Select operator to assign' }} />
      </UserRow>
      )}
      <div className="subtitle">
        Participants
        {participants.length > 0 && `(${participants.length})`}
      </div>
      {isAssigned && (
      <UserRow
        key={assignedOperator.id}
        user={assignedOperator}
        highlightRole
      >
        <div className="assigned-operator-role">Primary Operator</div>
        <AssignUserDropdown
          onSelect={(id) => jobPutRequest(id)}
          assignedOperator={assignedOperator}
        />
      </UserRow>
      )}
      {nonAssignedParticipants && nonAssignedParticipants.map((u) => (
        <UserRow
          key={u.id}
          user={u}
          right={(u.id !== claimantId) && (
          <div className="add-button">
            <Button
              onClick={() => removeUser(u.id)}
              type="outline"
            >
              Remove
            </Button>
          </div>
          )}
        >
          <div className="user-name">{u.name}</div>
          <div className="user-role">{u.role === 'operator' ? 'Secondary Operator' : 'Customer'}</div>
        </UserRow>
      ))}
    </div>
  )
}
Participants.propTypes = {
  participants: PropTypes.arrayOf(Object).isRequired,
  removeUser: PropTypes.func.isRequired,
  activeCase: PropTypes.object,
  jobPutRequest: PropTypes.func.isRequired,
}
export default Participants
