import {
  IconButton,
  Modal,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  Card,
  Icon,
  CardBody,
  CardHeader,
  Avatar,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { TrashIcon } from '@himarley/unity';
import _ from 'lodash';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { cancelScheduledMessage } from '@app/actions/chat';
import { formatDate } from '@app/helpers/datetime';
import { User } from '@app/types/reducer-state';

interface ListScheduledMessagesProps {
  chatId: string;
  show: boolean;
  toggleShow: (value: boolean) => void;
}

const ListScheduledMessages: React.FC<ListScheduledMessagesProps> = ({
  chatId,
  show,
  toggleShow,
}) => {
  const dispatch = useDispatch();
  const { scheduledMessages, chats } = useSelector((state) => ({
    scheduledMessages: [...(state.jobs?.scheduledMessages || [])],
    chats: state.jobs?.chats || {},
  }));

  let participants: User[] = [];
  if (chats && chats[chatId]) {
    participants = chats[chatId].participants;
  }

  const noNotesFound = scheduledMessages?.length === 0;

  return (
    <Modal
      isCentered
      isOpen={show}
      onClose={() => toggleShow(false)}
      size="lg"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          Scheduled Messages
        </ModalHeader>
        <ModalBody mb={4}>
          <VStack>
            {noNotesFound ? (
              <div data-test="marley-note-none" className="marley-note-none">
                No messages scheduled.
              </div>
            ) : scheduledMessages?.map((m) => {
              const author = (participants || []).find((p) => p.id === _.get(m, 'userId._id'));
              const authorName = _.get(author, 'name', 'user');
              return (
                <Card w="100%">
                  <CardHeader>
                    <HStack>
                      <div className="marley-note-author">
                        <Avatar
                          size="xs"
                          id={_.get(author, 'id')}
                          src={_.get(author, 'imageUrl')}
                          name={authorName}
                          borderRadius="md"
                          bg="marleyRed.500"
                          color="white"
                        />
                        <div className="marley-note-author-name">{authorName}</div>
                      </div>
                      <div className="marley-note-createdAt">{formatDate(new Date(m.eventTime).toLocaleString())}</div>
                      <div
                        data-test="delete-note-button"
                        className="marley-note-icons"
                      >
                        <IconButton
                          aria-label="Cancel scheduled message"
                          onClick={() => dispatch(cancelScheduledMessage(m.id))}
                          icon={<Icon as={TrashIcon} />}
                          variant="ghost"
                        />
                      </div>
                    </HStack>
                  </CardHeader>
                  <CardBody>
                    {m.messageBody}
                  </CardBody>
                </Card>
              );
            })}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ListScheduledMessages;
