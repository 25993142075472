import { defineStyleConfig, IconProps } from '@chakra-ui/react';

export const iconTheme = defineStyleConfig({
  baseStyle: (props: IconProps) => ({
    color: props.color || 'gray.500',
    height: '20px',
    width: '20px',
  }),
});

export default iconTheme;
