import React from 'react'
import DropDownIcon from '../icons/DropDownIcon'
import './DropDownBox.less'




class DropDownBox extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            dropped : true
        }
    }


    toggleDrop(){
        this.setState({
            dropped : !this.state.dropped
        })
    }

    render(){
        const it = this
        return(
            <div className="dropDown">
                <div className="outerBox">
                    <div className="titleBox">
                        <div className="title"
                            onClick={()=>{
                                it.toggleDrop()
                            }}
                        >{this.props.title}</div>
                        <div className={`dropDownIcon ${this.state.dropped ? 'dropped' : ''}`}
                            onClick={()=>{
                                it.toggleDrop()
                            }}
                        >
                            <DropDownIcon
                            ></DropDownIcon>
                        </div>
                    </div>
                    <div className={`subContainer ${this.state.dropped ? 'dropped' : ''}`}>
                        <div className="dropDescription">{this.props.description}</div>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}



module.exports = DropDownBox


