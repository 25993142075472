import _ from 'lodash'
import {
  AUTH_USER,
  DEAUTH_USER,
  LIVE_UPDATE_STRATEGY_SELECTED,
  SET_SOCKET_FAILURE,
  UNSUBSCRIBE_FROM_TOPICS,
} from '../constants/actions'
import { reportToCW } from '../actions/cloudwatch';
import { subscribeToTopics } from '../actions/socket';

const WS_TOPIC_SUFFIX = '-web-v1'
const WS_TOPIC_V2_SUFFIX = '#web-v2'
const WAIT_FOR_WS_CONNECTION_IN_MS = 30000
let WS = null // websocket object (to be set)
let WS_TOPIC = null // websocket topic (to be set)
const OPERATOR_TOPIC = (org, operator) => `${org}#OPERATOR#${operator}${WS_TOPIC_V2_SUFFIX}`

/**
 * Runs a race between a promise resolving as false if a connection to LAMBDA WS is
 * not made in WAIT_FOR_WS_CONNECTION_IN_MS and a promise resolving true if a connection is made
 *
 * @param {*} socket
 */
function checkWSConnection(getStore) {
  let checkInterval
  const byTimeout = new Promise(
    // eslint-disable-next-line no-promise-executor-return
    (resolve) => setTimeout(() => resolve(false), WAIT_FOR_WS_CONNECTION_IN_MS),
  )
  const byConnect = new Promise((resolve) => {
    checkInterval = setInterval(() => {
      if (_.get(getStore(), 'socket.socketObject.successFullyConnectedOnce')) resolve(true)
    }, 500)
  })
  return Promise.race([byTimeout, byConnect])
    .then((result) => {
      clearInterval(checkInterval)
      return result
    })
}

function setupWSEventIn(socket, dispatch, getStore) {
  const store = getStore()
  const org = _.get(store, 'auth.user.organization._id', null)
  const operator = _.get(store, 'auth.user._id')
  const topicHandler = store?.socket?.topicHandler;
  WS_TOPIC = `${org}${WS_TOPIC_SUFFIX}`// put in module scope for deauth scenario
  // subscribe to operator topic
  WS = socket // put in module scope for deauth scenario
  subscribeToTopics(
    [OPERATOR_TOPIC(org, operator), WS_TOPIC],
    topicHandler,
  )(dispatch);
}

async function configWS(dispatch, getStore) {
  await global.socketBuildPromise
  const socketConnected = await checkWSConnection(getStore)
  if (socketConnected) {
    setupWSEventIn(_.get(getStore(), 'socket.socketObject'), dispatch, getStore)
    dispatch({
      type: LIVE_UPDATE_STRATEGY_SELECTED,
    })
  } else {
    reportToCW({}, 'FAILED_TO_CONNECT_TO_WS', 'STAT')(dispatch, getStore)
    dispatch({
      type: SET_SOCKET_FAILURE,
    })
  }
}

const unsubscribeFromTopics = (dispatch, getStore) => {
  const store = getStore();
  const subscribedTopics = _.get(store, 'socket.subscribedTopics');
  const topics = Object.keys(subscribedTopics);
  dispatch({ type: UNSUBSCRIBE_FROM_TOPICS, payload: { topics } });
}

export default (store) => (next) => (action) => {
  if (!action) return;
  switch (action.type) {
    case AUTH_USER: {
      configWS(store.dispatch, store.getState)
      break
    }
    case DEAUTH_USER: {
      if (WS) {
        unsubscribeFromTopics(store.dispatch, store.getState)
      }
      break
    }
    default:
      break
  }
  return next(action)
}
