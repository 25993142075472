import React from "react";
import MultiTimeDropdown from "../../../SharedComponents/MultiTimeDropdown/MultiTimeDropdown";
import WeekdayCheckboxes from "../../../SharedComponents/WeekdayCheckboxes/WeekdayCheckboxes";
import TimezoneDropdown from "../../../SharedComponents/TimezoneDropdown/TimezoneDropdown";
import "./SetOfficeHours.less";

const SetOfficeHours = ({ reply, setReply }) => {
  
  return (
    <div className="choose-office-hours-modal">
      <h2>Office Hours</h2>
      <div className="office-hours-subheader">
        <p>
          Configure automated messages that will be sent to customers during
          regular business hours or after regular business hours.
        </p>
      </div>
      <div className="column-titles">
        <div className="weekday-column">
          <h5>Days</h5>
          <WeekdayCheckboxes reply={reply} setReply={setReply} showFullDay={true} />
        </div>
        <div className="mulitime-dropdown-container">
          <div className="begin-end-headings">
            <h5>Begin</h5>
            <h5>End</h5>
          </div>
          <MultiTimeDropdown reply={reply} setReply={setReply} showAllDayCheckBox={false} />
        </div>
      </div>
      <div className="timezone-container">
        <h6>Time Zone:</h6>
        <TimezoneDropdown reply={reply} setReply={setReply} />
      </div>
    </div>
  );
};

export default SetOfficeHours;
