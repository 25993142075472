import React from 'react';
import './ProfilePage.less';

const ProfilePage = ({ title, description, children, show = false }) => (
  <div className={`profilePage ${show ? 'visible' : ''}`}>
    <div className="title" data-testid="title">
      {title}
    </div>
    <div className="description" data-testid="description">{description}</div>
    {children}
  </div>
);

module.exports = ProfilePage;
