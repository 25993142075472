/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Dropdown from '../Dropdown/Dropdown';
import './LineDropdown.less';

/**
 * Dropdown menu from a simple string
 * @param   {string} label string for the togglin'
 * @return  {Component}
 */
const LineDropdown = ({
  children, label, placeHolder, isDisabled, dataTestId, ...props
}) => {
  const [isOpen, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <Dropdown
      className={`line-dropdown ${props.className || ''}`}
      openAction="click"
      isDisabled={isDisabled}
      show={isOpen}
      id={dataTestId}
      DropItem={(
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className="line-dropdown-link"
          onClick={handleClick}
          data-test="line-dropdown-link"
          data-testid="line-dropdown-link"
        >
          {label || placeHolder}
        </div>
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Dropdown>
  );
};

LineDropdown.propTypes = {
  isDisabled: PropTypes.bool,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

LineDropdown.defaultProps = {
  isDisabled: false,
  placeHolder: '',
  label: '',
  dataTestId: null,
  className: '',
};

export default LineDropdown;
