import {
  Avatar, Button, ButtonRow, Modal,
} from '@himarley/unity';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateType } from '@app/types/reducer-state';

import LoBIcon from './lob-icon';
import { updateLineOfBusiness } from '../../../actions/organization';
import { capitalizeEachWord } from '../../../helpers/format';

interface LineOfBusiness {
  _id: string;
  subType: string;
  type: 'personal' | 'commercial' | 'other';
  displayName: string;
  branding: string;
}

interface RemoveLineOfBusinessModalProps {
  show: boolean;
  hideModal: () => void;
  name: string;
  lob: LineOfBusiness;
}

const RemoveLineOfBusinessModal: React.FC<RemoveLineOfBusinessModalProps> = ({
  show = false,
  hideModal = () => { },
  name = '',
  lob = {},
}) => {
  const [lineName, setLineName] = useState(name || '');
  const dispatch = useDispatch();
  const orgId = useSelector((state: StateType) => state.auth?.user?.organizationId);

  const handleHideModal = () => {
    hideModal();
  };

  useEffect(() => {
    setLineName(name);
  }, [name]);

  const handleRemoveLine = () => {
    dispatch(updateLineOfBusiness(
      {
        organizationId: orgId,
        lineOfBusinessId: lob._id,
        archived: true,
      },
      'Lines of Business saved.',
      'Error Saving Lines of Business. Please Try Again.',
    ));
  };

  const capitalize = (s: string): string => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const isSaveDisabled = () => {
    if (lineName?.trim()?.length < 1) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      show={show}
      showCloseButton
      title="Remove Line"
      className="confirmation-modal"
      toggleModal={handleHideModal}
      backdrop
      size="lg"
    >
      <>
        <div
          data-testid="remove-lob-modal"
          className="remove-lob-modal-container"
        >
          <div id="remove-lob-intro">
            <p>
              <span className="bold-intro">
                {`Are you sure you want to remove the ${capitalize(lob.type || '')} ${capitalize(lob.displayName || '')} line from the ${capitalize(lob.branding || '')} brand?`}
              </span>
              {' '}
              Once removed it will be hidden from this brand for any new cases.
              Existing cases and insights for this line will remain.
            </p>
          </div>
          <div className="lob-grey-container">
            <div>
              <Avatar name={lob.branding} isCustomer className="brandAvatar" />
              <label htmlFor="edit-avatar-input" className="edit-avatar-label">{lob.branding}</label>
            </div>
            <div>
              <LoBIcon name={lob.subType || ''} />
              <h2 className="lob-icon-title">
                {capitalizeEachWord(
                  lob.subType,
                  lob.subType?.includes('-') ? '-' : ' ',
                )}
                {lob?.subType === 'workers-comp' && '.'}
              </h2>
            </div>
          </div>
        </div>
        <div className="confirmation-modal-footer">
          <ButtonRow>
            <Button
              onClick={handleHideModal}
              type="outline"
              className="cancelButton"
            >
              Cancel
            </Button>
            <Button
              data-testid="delete-note-button-confirm"
              data-test="delete-note-button-confirm"
              onClick={() => {
                handleRemoveLine();
                handleHideModal();
              }}
              type="positive"
              disabled={isSaveDisabled()}
            >
              Remove Line
            </Button>
          </ButtonRow>
        </div>
      </>
    </Modal>
  );
};

export default RemoveLineOfBusinessModal;
