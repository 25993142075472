import React, { useEffect, useRef } from 'react';

import styles from './InboxContent.module.less';

interface UnpinnedHeaderProps {
  index: number;
  style: React.CSSProperties;
  setSize: (index: number, size: number) => void;
  windowWidth?: number | string;
}

const UnpinnedHeader: React.FC<UnpinnedHeaderProps> = ({
  index,
  style,
  setSize,
  windowWidth,
}) => {
  const rowRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (rowRef.current?.firstChild) {
      const firstChild = rowRef.current.firstChild as HTMLElement;
      const { height } = firstChild.getBoundingClientRect();
      setSize(index, height + 1);
    }
  }, [setSize, index, windowWidth]);

  return (
    <section
      key={index}
      className={styles.pinnedLabel}
      data-testid="inbox-unpinned-header"
      style={style}
      ref={rowRef}
    >
      <div className={styles.pinnedRow} data-testid="pinnedRow">
        <span data-testid="unPinnedLabel">UNPINNED</span>
      </div>
    </section>
  );
};

export default UnpinnedHeader;
