import React from 'react'
import PropTypes from 'prop-types'
import './QuoteCard.less'

const QuoteCard = ({ children }) => (
  <div data-testid="quote-card" className="quote-container">
    <div className="personal-card-container">
      {children}
    </div>
  </div>
)

QuoteCard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default QuoteCard
