import debounce from 'lodash/debounce';
import {
  useMemo, useEffect, useState, SetStateAction, Dispatch,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { filterItems } from '@app/actions/common';

const useSearchBar = (type: string): [string, Dispatch<SetStateAction<string>>] => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useMemo(() => new URLSearchParams(location?.search), [location?.search]);

  const [searchFilter, setSearchFilter] = useState<string>(params.get('search') || '');
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const handleSearch = debounce(() => {
      if (searchFilter === '') {
        params.delete('search');
      } else {
        params.set('search', searchFilter);
      }

      history.push({ search: params.toString() });
      dispatch(filterItems({ filter: searchFilter, type }));
    }, 500);

    if (!isInitialLoad) {
      handleSearch();
    } else {
      setIsInitialLoad(false);
      if (searchFilter && searchFilter !== '') {
        handleSearch();
      }
    }

    return () => {
      handleSearch.cancel();
    };
  }, [searchFilter, params, history, dispatch, isInitialLoad, type]);

  return [searchFilter, setSearchFilter];
};

export default useSearchBar;
