import React from 'react';
import PropTypes from 'prop-types';
import UserPhoto2 from '../../../../UserPhoto2/UserPhoto2';
import GarbageIcon from '../../../../icons/GarbageIcon';
import IconButton from '../../../../elements/buttons/IconButton/IconButton';
import { formatDate } from '../../../../../helpers/datetime';
import { INTEGRATION_NOTE_TYPE } from '../../../../../constants/notes';

const NoteHeader = ({
  note,
  setShowDeleteConfirmation,
  setActiveNoteIds,
  authId,
  isReply,
  noteId,
  replyId,
  isDeleted,
}) => {
  const createdAt = note?.createdAt;
  const author = note?.authorId || {};

  const authorName = (author, type) => ((author && type !== INTEGRATION_NOTE_TYPE)
    ? `${author?.profile?.firstName} ${author?.profile?.lastName}`
    : 'Copart (Vendor)');

  return (
    <div className="marley-card-header">
      <div className="marley-note-author">
        <UserPhoto2
          type="thumb"
          id={author.id}
          imageUrl={author.imageUrl}
          name={authorName(author, note?.type)}
          isCustomer={false}
          isVendor={note.type === INTEGRATION_NOTE_TYPE}
        />
        <div
          className="marley-note-author-name"
          data-testid="note-author-header"
        >
          {authorName(author, note?.type)}
        </div>
      </div>
      {isDeleted ? (
        <span className="deletedNoteWrap">Note Deleted</span>
      ) : (
        <>
          <div className="marley-note-createdAt">
            {createdAt
              ? formatDate(createdAt, 'human')
              : formatDate(new Date().toISOString(), 'human')}
          </div>
          <div
            data-testid={isReply ? 'delete-reply-button' : 'delete-note-button'}
            data-test="delete-note-button"
            className="marley-note-icons"
            onClick={() => {
              setShowDeleteConfirmation(true, isReply);
              setActiveNoteIds({ noteId, replyId });
            }}
            role="button"
            onKeyDown={() => {
              setShowDeleteConfirmation(true, isReply);
              setActiveNoteIds({ noteId, replyId });
            }}
            tabIndex="0"
          >
            {authId === author?._id && (
              <IconButton>
                <GarbageIcon />
              </IconButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};

NoteHeader.propTypes = {
  note: PropTypes.shape({
    createdAt: PropTypes.string,
    authorId: PropTypes.string,
    type: PropTypes.string,
  }),
  setShowDeleteConfirmation: PropTypes.func,
  setActiveNoteIds: PropTypes.func,
  authId: PropTypes.string,
  isReply: PropTypes.bool,
  noteId: PropTypes.string,
  replyId: PropTypes.string,
  isDeleted: PropTypes.bool,
};

NoteHeader.defaultProps = {
  note: {},
  setShowDeleteConfirmation: () => {},
  setActiveNoteIds: () => {},
  authId: null,
  isReply: false,
  noteId: null,
  replyId: null,
  isDeleted: false,
};

export default NoteHeader;
