import _ from 'lodash'
import { useSelector } from 'react-redux'

const usePermissionVerify = ({ neededPermissions }) => {
  const currentPermissions = useSelector((state) => state?.auth?.permissions || [])
  const hasAccess = _.intersection(
    neededPermissions || [], currentPermissions,
  ).length === (neededPermissions || []).length

  return { hasAccess }
}

export default usePermissionVerify
