/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Divider,
  List,
  Ghosts,
  AddCircleIcon,
} from '@himarley/unity';
import { Button, Icon } from '@chakra-ui/react';
import BrandModal from './BrandModal';
import SearchBar from '../../SearchBar/SearchBar';
import LineDropdown from '../../elements/dropdowns/LineDropdown/LineDropdown';
import BinocularsIcon from '../../../../images/icons/binoculars.svg';
import {
  updateBranding,
  setIsUpdatingBrand,
} from '../../../actions/organization';
import BrandLinesColumn from './BrandLinesColumn';
import './CompanyHierarchy.less';

const IS_SEARCH_SORT_ENABLED = false;

const sortDropdownList = [
  {
    id: 'az',
    label: 'A-Z',
    placeholderLabel: 'A-Z',
    query: true,
    sort: (brands) => brands.sort((a, b) => a.localeCompare(b)),
  },
  {
    id: 'za',
    label: 'Z-A',
    placeholderLabel: 'za',
    query: false,
    sort: (brands) => brands.sort((a, b) => b.localeCompare(a)),
  },
];

const buildBranding = (organization) => {
  const brandingArray = organization.branding?.length ? organization.branding : [];
  return brandingArray;
};

const buildBrandToLinesOfBusinessMap = (branding, lob) => {
  const brandToLinesOfBusinessMap = new Map(branding?.map((brand) => [brand, []]));

  lob?.forEach((lineOfBusiness) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    brandToLinesOfBusinessMap.has(lineOfBusiness?.branding)
      && brandToLinesOfBusinessMap.set(lineOfBusiness.branding, [
        ...brandToLinesOfBusinessMap.get(lineOfBusiness?.branding),
        lineOfBusiness,
      ]);
  });

  return brandToLinesOfBusinessMap;
};

const CompanyHierarchy = ({ organization }) => {
  const dispatch = useDispatch();
  const isGettingBrands = useSelector((state) => state?.organizations?.isGettingFullOrganizationData);
  const isUpdatingBranding = useSelector((state) => state?.organizations?.isUpdatingBranding);

  const brandingArray = buildBranding(organization);
  const [showModal, setShowModal] = useState(false);
  const [sortType, setSortType] = useState(sortDropdownList[0]);
  const [branding, setBranding] = useState(brandingArray);
  const [searchText, setSearchText] = useState('');
  // since brands can be sorted&filtered a hashmap is required
  // to keep track of which string within array user is editing/deleting
  const brandingMap = new Map(branding.map((brand, index) => [brand, index]));

  const brandToLinesOfBusinessMap = buildBrandToLinesOfBusinessMap(branding, organization?.linesOfBusiness);

  useEffect(() => {
    const brandingArray = buildBranding(organization);
    setBranding(sortType?.sort(brandingArray));
  }, [organization?.branding, sortType]);

  useEffect(() => {
    const brandingArray = buildBranding(organization);
    if (searchText?.trim() === '') {
      setBranding(sortType?.sort(brandingArray));
      return;
    }
    const filteredBrands = brandingArray.filter((brand) => brand.toUpperCase().includes(searchText.toUpperCase()));
    setBranding(sortType.sort(filteredBrands));
  }, [organization.branding, searchText, sortType]);

  useEffect(() => {
    if (isUpdatingBranding === false) {
      dispatch(setIsUpdatingBrand(null));
      setShowModal(false);
    } else if (isUpdatingBranding === 'error') {
      dispatch(setIsUpdatingBrand(null));
    }
  }, [dispatch, isUpdatingBranding]);

  const statusToggle = (id) => {
    const item = sortDropdownList.find((i) => i.id === id) || {};
    setBranding(item.sort([...organization.branding]));
    setSortType(item);
  };

  const onAddBrand = (newBrand) => {
    const successText = `${newBrand} Brand created.`;
    const errorText = `${newBrand} Brand could not be created. Please try again.`;
    dispatch(updateBranding(
      {
        organizationId: organization?._id,
        branding: [...organization.branding, newBrand],
      },
      successText,
      errorText,
    ));
  };

  const onEditBrand = (newBrand, index) => {
    const successText = 'Brand name updated.';
    const errorText = 'Brand name could not be updated. Please try again.';
    const brandingCopy = [...organization.branding];
    brandingCopy[index] = newBrand;

    // only proceed with saving if length of original array same as new array
    if (brandingCopy.length === organization.branding.length) {
      dispatch(updateBranding(
        {
          organizationId: organization?._id,
          branding: brandingCopy,
        },
        successText,
        errorText,
      ));
    }
  };

  const onDeleteBrand = (index, brandName) => {
    const successText = `${brandName} Brand deleted.`;
    const errorText = `${brandName} Brand could not be deleted. Please try again.`;
    const brandingCopy = [...organization.branding];
    brandingCopy.splice(index, 1);

    // only proceed with saving if length of new array is one less than original array
    if (brandingCopy.length === organization.branding.length - 1) {
      dispatch(updateBranding(
        {
          organizationId: organization?._id,
          branding: brandingCopy,
        },
        successText,
        errorText,
      ));
    }
  };

  const onSearchTextChange = (e) => {
    setSearchText(e?.target?.value || '');
  };

  const renderAddBrandButton = (isOutline = false) => (
    <Button
      isDisabled={isGettingBrands}
      data-testid="new-brand-button"
      onClick={() => {
        setShowModal(true);
      }}
      variant={`${isOutline ? 'outline' : 'solid'}`}
      leftIcon={<Icon as={AddCircleIcon} />}
    >
      Add Brand
    </Button>
  );

  return (
    <section className="brandsWrap">
      {showModal && (
        <BrandModal
          show={showModal}
          isEditing={false}
          hideModal={() => setShowModal(false)}
          handleAddBrand={onAddBrand}
          brandingMap={brandingMap}
          isUpdatingBranding={isUpdatingBranding}
        />
      )}
      <header>
        <h1>Company Hierarchy</h1>
        {renderAddBrandButton(false)}
      </header>
      <p>Add additional Brands or Lines of Business to set up a company hierarchy that best matches your business needs.</p>
      <div className="topBar">
        <h3>
          {`${branding?.length} ${
            branding?.length === 1 ? 'Active Brand' : 'Active Brands'
          }`}
        </h3>
        {IS_SEARCH_SORT_ENABLED && (
          <div className="searchSort">
            <SearchBar
              placeholder="Search Brands..."
              onChange={onSearchTextChange}
            />
            <LineDropdown label={`Sort: ${sortType?.label}`}>
              <List
                items={sortDropdownList}
                onToggle={statusToggle}
                selectedIds={sortType?.id}
              />
            </LineDropdown>
          </div>
        )}
      </div>
      <Divider />
      <div className="brandListWrap">
        {isGettingBrands
          ? (
            <>
              <section className="brandLinesColWrap">
                <Ghosts className="hm-row hm-row-brand" numItems={1} />
                <div className="verticalDivider">
                  <div className="verticalLeftOne" />
                  <div />
                </div>
                <Ghosts className="linesOfBusinessWrap" numItems={1} />
              </section>
              <section className="brandLinesColWrap">
                <Ghosts className="hm-row hm-row-brand" numItems={1} />
                <div className="verticalDivider">
                  <div className="verticalLeftOne" />
                  <div />
                </div>
                <Ghosts className="linesOfBusinessWrap" numItems={1} />
              </section>
              <section className="brandLinesColWrap">
                <Ghosts className="hm-row hm-row-brand" numItems={1} />
                <div className="verticalDivider">
                  <div className="verticalLeftOne" />
                  <div />
                </div>
                <Ghosts className="linesOfBusinessWrap" numItems={1} />
              </section>
              <section className="brandLinesColWrap">
                <Ghosts className="hm-row hm-row-brand" numItems={1} />
                <div className="verticalDivider">
                  <div className="verticalLeftOne" />
                  <div />
                </div>
                <Ghosts className="linesOfBusinessWrap" numItems={1} />
              </section>
            </>
          )
          : (
            <>
              <>
                {branding?.length === 0 && IS_SEARCH_SORT_ENABLED && (
                <div className="none-found-notification">
                  <div className="none-found-icon">
                    <BinocularsIcon />
                  </div>
                  <div>We're looking, but no Brands found.</div>
                </div>
                )}
              </>
              <>
                {[...brandToLinesOfBusinessMap.keys()]
                  ?.sort((a, b) => a.localeCompare(b))
                  ?.map((brandName) => (
                    <BrandLinesColumn
                      brandName={brandName}
                      handleEditBrand={onEditBrand}
                      handleDeleteBrand={onDeleteBrand}
                      brandingMap={brandingMap}
                      key={brandName}
                      linesOfBusiness={brandToLinesOfBusinessMap.get(brandName)}
                      orgId={organization?._id}
                    />
                  ))}
              </>
              <section className="addBrandBtnColumn">
                {renderAddBrandButton(true)}
              </section>
            </>
          )}
      </div>
    </section>
  );
};

CompanyHierarchy.propTypes = {
  organization: PropTypes.shape({ branding: PropTypes.shape([]) }),
};

CompanyHierarchy.defaultProps = {
  organization: { branding: [], _id: '' },
};

export default CompanyHierarchy;
