import { VIRUS_SCAN_STATUS } from '@app/components/Media/constants';

const selector = (state) => {
  const activeCase = state?.jobs?.activeJob || {};
  const customer = state?.jobs?.activeChat?.activeJob?.customer || {};
  const mediaMessages = (state?.jobs?.activeChat?.media?.filter(
    (media) => !media?.isImageRedacted && media?.virusScanStatus === VIRUS_SCAN_STATUS.CLEAN,
  ) || [])?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));
  const error = state?.users?.error;
  const requestComplete = state?.users?.requestComplete;
  const rightBar = state?.ui?.rightBar;
  const caseTypes = state?.jobs?.caseTypes || null;
  const fnolData = state?.jobs?.activeJob?.fnolData || {};

  return {
    hasNotesAccess: (state?.auth?.permissions || []).includes('NOTES_ACCESS'),
    authId: state?.auth?.user?._id,
    customer,
    mediaMessages,
    error,
    requestComplete,
    rightBar,
    caseTypes,
    activeCaseId: activeCase?.id,
    notes: state?.jobs?.activeNotes || [],
    caseData: fnolData, // TODO rename to fnolData from caseData
  };
};

export default selector;
