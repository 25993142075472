import React from "react";
import WithDot from '../../WithDot/WithDot';
import './Card.less'

const Card = ({ header, children, showDot }) => (
  <WithDot
    type="blue"
    show={showDot}
    position="top-left"
    className="cardWithDot"
  >
    <div className={`marley-card ${showDot ? 'marleyCardHighlight' : ''}`} data-testid="marley-card">
      {header && <div className="marley-card-header">{header}</div>}
      <div className="marley-card-text">{children}</div>
    </div>
  </WithDot>
);

export default Card;
