import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CaseProgressStep from './CaseProgressStep';
import ReleaseIssuesStep from './ReleaseIssuesStep';
import styles from './CaseProgress.module.less';
import { setReleaseIssueModalOpen } from '../../actions/ui';
import selector from './selector';

const CaseProgress = () => {
  const [showDetails, setShowDetails] = useState(false);
  const { steps, releaseEvents } = useSelector(selector);

  const dispatch = useDispatch();

  const handleShowDetails = () => {
    setShowDetails((showDetailsState) => !showDetailsState);
  };

  const onMarkAsCompleteButtonClick = (issueId) => {
    dispatch(setReleaseIssueModalOpen({ isOpen: true, selectedReleaseIssue: issueId }));
  };

  return (
    <div className={styles.caseProgress}>
      {steps.map((state, index) => (
        <CaseProgressStep
          key={state.key}
          id={state.key}
          title={state.title}
          status={state.status}
          subText={state.subText}
          showButton={state.showButton}
          showDetails={showDetails}
          handleShowDetails={handleShowDetails}
          finalStep={index === steps.length - 1}
        >
          {
          state.showButton
          && (
          <ReleaseIssuesStep
            showDetails={showDetails}
            releaseIssues={releaseEvents}
            status={state.status}
            onMarkAsCompleteButtonClick={onMarkAsCompleteButtonClick}
          />
          )
          }
        </CaseProgressStep>
      ))}
    </div>
  );
};

export default CaseProgress;
