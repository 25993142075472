/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Alert, Button } from 'react-bootstrap'
import CloseIcon from '../icons/CloseIcon'
import { closeNotification } from '../../actions/notification'
import './Notification.less'

const NOTIFICATION_TIMEOUT = 10000

class Notification extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    clearTimeout(this.notificationTimeout)
    if (nextProps.notification && !nextProps.notification.persistent) {
      this.notificationTimeout = setTimeout(() => {
        this.props.closeNotification(nextProps.notification)
      }, NOTIFICATION_TIMEOUT)
    }
  }
  render() {
    const notification = this.props.notification
    const splitNotification = _.get(notification, 'text', '').split('%l')
    const links = _.get(notification, 'links', [])
    const linksComponent = links.map(({ label, url }) => (
      // eslint-disable-next-line react/jsx-key
      <a href={url} target="_blank">{label}</a>
    ))

    const notificationText = []
    splitNotification.forEach((n) => {
      notificationText.push(n)
      const LinksComponent = linksComponent.shift()
      if (LinksComponent) notificationText.push(LinksComponent) })

    return (
      <div className="notification-container">
        {notification && notification.show && <Alert className="notification" bsStyle={notification.type || 'danger'} key={notification}>
          <strong>
            {notificationText}{notification.explanation && `: ${notification.explanation}`}
          </strong>
          {notification.canClose && (
            <Button bsStyle="link" className="close-button" onClick={() => this.props.closeNotification(notification)}>
              <CloseIcon />
            </Button>
          )}
        </Alert>}
      </div>
    )
  }
}

Notification.propTypes = {
  closeNotification: PropTypes.func.isRequired,
  notification: PropTypes.instanceOf(Object),
}
Notification.defaultProps = {
  notification: null,
}

const mapDispatchToProps = {
  closeNotification,
}

const mapStateToProps = state => ({ ...state.notification })

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
