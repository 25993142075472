import {
  Icon, Menu, MenuButton, MenuList, MenuItem, IconButton,
} from '@chakra-ui/react';
import {
  Card, EditIcon, CloseIcon, MoreIcon,
} from '@himarley/unity';
import React, { useState } from 'react';

import Row from '@app/components/elements/Row/Row';
import Title from '@app/components/elements/text/Title/Title';
import { capitalizeEachWord } from '@app/helpers/format';

import EditLineOfBusinessModal from './edit-line-of-business-modal';
import { typeToLine } from './helpers';
import LoBIcon from './lob-icon';
import RemoveLineOfBusinessModal from './remove-line-of-business-modal';

interface LineOfBusinessCardProps {
  name: string;
  lob: {
    _id: string;
    subType: string;
    type: 'personal' | 'commercial' | 'other';
    displayName: string;
    branding: string;
  };
}

const LineOfBusinessCard: React.FC<LineOfBusinessCardProps> = ({ name = '', lob }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const menuItems = [
    {
      id: 'edit',
      onClick: () => setShowEditModal(true),
      icon: <Icon as={EditIcon} />,
      label: 'Edit',
    },
    {
      id: 'remove',
      onClick: () => setShowDeleteModal(true),
      icon: <Icon as={CloseIcon} color="red.500" />,
      label: 'Remove',
      color: 'red.500',
    },
  ];

  return (
    <>
      <EditLineOfBusinessModal
        show={showEditModal}
        isEditing
        hideModal={() => setShowEditModal(false)}
        name={name}
        lob={lob}
      />
      <RemoveLineOfBusinessModal
        show={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        name={name}
        lob={lob}
      />
      <Row type="lob">
        <Card className="brandList">
          <Row type="">
            <div>
              <LoBIcon name={lob?.subType} boxSize="26px" />
            </div>
            <div className="lobName">
              <Title type="subheader">
                <b>
                  {capitalizeEachWord(lob?.displayName || lob?.subType)}
                </b>
              </Title>
              <p>{typeToLine[lob?.type]}</p>
            </div>
            <div className="iconRow">
              <Menu isLazy placement="bottom-start">
                <MenuButton data-testId={`${lob?.type}${lob?.subType}LoBActionDropdown`} as={IconButton} aria-label="options" icon={<Icon as={MoreIcon} />} variant="ghost" />
                <MenuList minWidth="unset">
                  {menuItems.map((item) => (
                    <MenuItem
                      color={item.color}
                      key={item.id}
                      onClick={item.onClick}
                      icon={item.icon}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </div>
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default LineOfBusinessCard;
