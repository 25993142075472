import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { getCustomerFeedback } from '../../../actions/profile'
import FeedbackItem from './FeedbackItem/FeedbackItem'
import BinocularsIcon from '../../../../images/icons/binoculars.svg'
import StarRate from '../../StarRate/StarRate'
import './CustomerFeedback.less'

export const countSummaryRate = (items) => {
  if (items.length > 0) {
    return (items.reduce((sum, item) => (sum + +item.score), 0)) / items.length
  } else {
    return 0
  }
}
const CustomerFeedback = ({ ...props }) => {
    const { profileUserId, getCustomerFeedback, list } = props

    useEffect(() => {
      getCustomerFeedback()
    }, [profileUserId])

    const rate = countSummaryRate(list).toFixed(1)

    return list.length > 0 ?
      (
        <div className="customer-feedback-wrap">
          <div className="customer-feedback-title">
            Customer Feedback
          </div>
          <div className="customer-feedback-rate-wrap">
            <div className="customer-feedback-rate-num">
              {rate}
            </div>
            <StarRate className="customer-feedback-rate-stars" type="large" score={+rate} />
          </div>
          <div className="customer-feedback-list">
            {list.map((item, index) => (
              <FeedbackItem
                key={`${item.userId}-${index}` || index}
                templateKey={`${item.userId}-${index}` || index}
                rate={+item.score}
                userName={item.userName || 'Unknown'}
                review={item.feedback || 'no feedback'}
                metricAnswerTimestamp={item.metricAnswerTimestamp}
              />
            ))}
          </div>
        </div>
      ) :
      (<div className="customer-feedback-wrap">
        <div className="customer-feedback-empty">
          <div className="customer-feedback-empty-icon"><BinocularsIcon /></div>
          <div className="customer-feedback-empty-header">
            No Surveys Found
          </div>
          <div className="customer-feedback-empty-help">
            {"We're looking, but no surveys found"}
          </div>
        </div>
      </div>)
}

CustomerFeedback.propTypes = {
  getCustomerFeedback: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(Object).isRequired,
}
const mapDispatchToProps = {
  getCustomerFeedback,
}
const mapStateToProps = ({ profile }) => {
  return { profileUserId: profile._id, list: _.filter(profile.customerFeedback, item => _.isObject(item) && item.score >= 0) }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeedback)
