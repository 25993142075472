import _ from 'lodash'
import {
  DEAUTH_USER, LOAD_ANALYTICS_REPORTS, LOAD_LOOKER_URL, LOAD_LOOK_RESULT,
  LOAD_IFRAME_LOOK_URL, LOAD_LOOK_CASE_IDS,
} from '../constants/actions'
import { buildList } from './helpers'

export const initialState = {
  list: [],
  listById: {},
  reports: [],
}

export const defaultValue = {
  openedcases: 0,
  closedcases: 0,
  avgtimecases: 0,
  surveytotal: 0,
  surveyavg: 0,
  surveyfive: 0,
  surveysent: 0,
}

export const STAT_TYPE_HASH = {
  OPEN_CASES: 'openedcases',
  CLOSED_CASES: 'closedcases',
  AVG_TIME_CASES: 'avgtimecases',
  SURVEY_TOTAL: 'surveytotal',
  SURVEY_AVG: 'surveyavg',
  SURVEY_FIVE: 'surveyfive',
  SURVEY_SENT: 'surveysent',
}

export const loadStat = ({
  listById, stats, name, statName,
}) => {
  const returnPayload = { ...listById }
  const userIds = Object.keys(returnPayload)
  stats && stats.forEach((u) => {
    const { user, value } = u
    const userId = user.id
    if (!returnPayload[userId]) {
      _.set(returnPayload, `${userId}.user`, user)
    }
    const stats = _.get(returnPayload, `${userId}.stats`, { ...defaultValue })

    _.set(returnPayload, `${userId}.stats`, _.set({ ...stats }, name, statName ? u[statName] : value))
    _.remove(userIds, (id) => userId && id === userId)
  })
  userIds.forEach((id) => {
    _.set(returnPayload, `${id}.stats.${name}`, 0)
  })
  return returnPayload
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ANALYTICS_REPORTS: {
      const { payload } = action
      return {
        ...state,
        reports: payload,
      }
    }
    case LOAD_LOOKER_URL: {
      const { payload } = action
      return {
        ...state,
        lookerUrl: payload,
      }
    }
    case LOAD_LOOK_RESULT: {
      const { payload } = action
      const { lookNumber, lookResult } = payload

      const lookResults = { ...state.lookResults }
      lookResults[lookNumber] = lookResult

      return {
        ...state,
        lookResults,
      }
    }
    case LOAD_LOOK_CASE_IDS: {
      const { payload } = action
      const { lookNumber, result } = payload

      const caseIds = { ...state.caseIds }
      caseIds[lookNumber] = result

      return {
        ...state,
        caseIds,
      }
    }
    case LOAD_IFRAME_LOOK_URL: {
      const { payload } = action
      const { lookNumber, url } = payload

      const lookUrls = { ...state.lookUrls }
      lookUrls[lookNumber] = url

      return {
        ...state,
        lookUrls,
      }
    }
    case 'LOAD_SURVEY_ANALYTICS': {
      const { payload } = action
      const { data } = payload

      const stats = [
        { name: STAT_TYPE_HASH.SURVEY_TOTAL, statName: 'count' },
        { name: STAT_TYPE_HASH.SURVEY_AVG, statName: 'avg' },
        { name: STAT_TYPE_HASH.SURVEY_FIVE, statName: 'surveyfive' },
        { name: STAT_TYPE_HASH.SURVEY_SENT, statName: 'surveysent' },
      ]

      let listById = { ...state.listById }
      stats.forEach((s) => {
        const { name, statName } = s
        listById = loadStat({
          listById, stats: data, name, statName,
        })
      })

      return {
        ...state,
        listById,
        list: buildList(listById),
      }
    }
    case 'LOAD_ANALYTICS_CASES': {
      const { payload } = action
      const { data, type } = payload
      const name = STAT_TYPE_HASH[type]
      const listById = loadStat({
        listById: { ...state.listById },
        stats: data,
        name,
      })

      return {
        ...state,
        listById,
        list: buildList(listById),
      }
    }
    case DEAUTH_USER: {
      return { ...initialState }
    }
    default:
      return state
  }
}

export default reducer
