import { constructHighlightedText } from '../../../helpers/tokenTransformers';

const truncateTemplateText = (text, length) => {
  if (text.length >= length) {
    const nextSpace = text.indexOf(' ', length);
    const truncatedText = `${text.slice(0, nextSpace)}...`;
    return truncatedText;
  }
  return text;
};

const replaceTokensWithPlaceholder = (body = '', tokens = []) => {
  let index = 0;
  const replacedBody = body.replace(/\${TOKEN}/g, () => {
    const token = tokens[index] ? tokens[index].token : '';
    index++;
    return `$${token}`;
  });
  return replacedBody;
};

const generateReadableBody = (tokens, templateData) => {
  const tokenSet = new Set(tokens.map((obj) => obj.key));
  const updatedBody = replaceTokensWithPlaceholder(templateData?.body, templateData?.tokens);
  let textToConstruct = updatedBody;
  if (templateData?.truncateAt) {
    const truncatedText = truncateTemplateText(updatedBody, templateData?.truncateAt);
    textToConstruct = truncatedText;
  }
  return { text: textToConstruct, annotatedText: constructHighlightedText(textToConstruct, tokenSet) };
};

export { generateReadableBody, replaceTokensWithPlaceholder, truncateTemplateText };
