import React, { useState } from "react";
import LineDropdown from "../../../../AssignDropdown/LineDropdown/LineDropdown";
import { detectTimezone, timeZoneOptions } from "../../Utils";
import _ from "lodash";

const TimezoneDropdown = ({ reply, setReply }) => {
  const [timezone, setTimezone] = useState(
    _.has(reply, "sendCriteria.timeZone")
      ? reply.sendCriteria.timeZone
      : "EST/EDT"
  );
  const [defaultTimezone] = useState(
    detectTimezone().label
  );
  const [isTimezoneDropdownOpen, setIsTimezoneDropdownOpen] = useState(false);

  const handleSelectTimeZone = (e) => {
    setTimezone(e);
    setIsTimezoneDropdownOpen(!isTimezoneDropdownOpen);
    setReply({
      ...reply,
      sendCriteria: {
        ...reply.sendCriteria,
        timeZone: e,
      },
    });
  };

  return (
    <div className="timezone-row">
      <LineDropdown
        className="timezone-dropdown"
        defaultLabel={defaultTimezone || "Eastern Standard Time (EST)"}
        options={timeZoneOptions}
        open={isTimezoneDropdownOpen}
        onToggle={(value) => setIsTimezoneDropdownOpen(value)}
        handleSelect={(e) => handleSelectTimeZone(e)}
        selectedId={timezone}
      />
    </div>
  );
};

export default TimezoneDropdown;
