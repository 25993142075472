import _ from "lodash";

export const ClaimsFormValidation = {
  formatDeductible: function (deductible) {
    return (
      deductible && deductible.toString().replace(",", "").replace("$", "")
    );
  },

  validateDeductible: function (deductible) {
    const deductibleRegex = /^(([1-9]\d{0,2}(,\d{3})*)|\d+)(\.\d{2})?$/;
    return !!((deductible && deductibleRegex.test(deductible)) || deductible === "");
  },

  validateClaimNumber: function (claimNumber) {
    return !!claimNumber;
  },

  // would be nice to have data about the field EX: isRequired in the form data. 🤔
  claimsFormValidation: function ({ fieldName, claimFormData, map }) {
    const validator = {
      deductible: this.validateDeductible,
      claimNumber: this.validateClaimNumber,
    };

    const value = _.get(claimFormData, map(fieldName));
    const validationMethod = validator[fieldName];

    const validation = {
      isValid: true,
    };

    if (value && validationMethod && !validationMethod(value)) {
      validation.isValid = false;
      return validation;
    }

    return validation;
  },
};
