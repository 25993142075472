/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import PropTypes from 'prop-types'
import './Modal.less'
import ModalInner from './ModalInner'

/**
 * Generic Modal element
 * @param   {boolean} show toggles display of the modal
 * @param   {string} title top title of modal
 * @param   {string} size size of modal
 * @param   {function} toggleModal funciton to toggle show
 * @return  {Component}
 */
const MarleyModal = (props) => {
  const {
    toggleModal, show, className, backdrop, title, children, size,
  } = props

  return show ? (
    <div className="marley-modal-container">
      <div className="marley-modal-veil" />
      <ModalInner
        className={className}
        size={size}
        backdrop={backdrop}
        title={title}
        toggleModal={toggleModal}
      >
        {children}
      </ModalInner>
    </div>
  ) : null
}

MarleyModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  backdrop: PropTypes.bool,
  toggleModal: PropTypes.func,
  show: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
}

MarleyModal.defaultProps = {
  title: '',
  backdrop: true,
  toggleModal: () => {},
  show: false,
  className: '',
  size: 'lg',
}

export default MarleyModal
