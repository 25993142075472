import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { lazyLoad as lazyLoadAction } from '@app/actions/common';
import {
  changePhoneNumber as changePhoneNumberAction,
  changeFirstName as changeFirstNameAction,
  changeLastName as changeLastNameAction,
  changeRelationship as changeRelationshipAction,
  putChatParticipant as putChatParticipantAction,
  setPutChatParticipantStatus as setPutChatParticipantStatusAction,
} from '@app/actions/participants';
import {
  checkUser as checkUserAction,
  clearMobileLookup as clearMobileLookupAction,
  updateUser as updateUserAction,
} from '@app/actions/users';
import { userType } from '@app/models/marleyTypes';
import { StateType } from '@app/types/reducer-state';

import ParticipantForm from './participant-form';
import { relationships } from './relationships';

const mapDispatchToProps = {
  checkUser: checkUserAction,
  changePhoneNumber: changePhoneNumberAction,
  changeFirstName: changeFirstNameAction,
  changeLastName: changeLastNameAction,
  clearMobileLookup: clearMobileLookupAction,
  changeRelationship: changeRelationshipAction,
  lazyLoad: lazyLoadAction,
  putChatParticipant: putChatParticipantAction,
  updateUser: updateUserAction,
  setPutChatParticipantStatus: setPutChatParticipantStatusAction,
};

const mapStateToProps = (state: StateType) => {
  const orgDisplayName = state.jobs?.activeJob?.branding
    || state.auth?.user?.organization?.displayName
    || state.auth?.user?.organization?.name;

  return {
    mobileLookup: state.ui?.mobileLookup,
    participantForm: state.participants.participantForm,
    putChatParticipantStatus: state.participants.putChatParticipantStatus,
    usersList: state.users?.list,
    orgName: orgDisplayName || '',
    activeChatId: state.jobs?.activeChat?.id,
    activeJobPhoneNumber: state.jobs?.activeJob?.customer?.phoneNumber,
    chatParticipants: state.participants?.chatParticipants,
    jobId: state.jobs?.activeJob?.id,
    updateUserStatus: state.users?.updateUserStatus,
    lobSubType: state.jobs?.activeJob?.lineOfBusiness?.subType,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ParticipantFormContainerProps = PropsFromRedux & {
  onClose: () => void;
  initialParticipantData?: {
    phoneNumber: string;
    firstName: string;
    lastName: string;
    relationship: string;
    id: string;
    isPrimaryEndUser: boolean;
  };
};

const ParticipantFormContainer: React.FC<ParticipantFormContainerProps> = ({
  onClose,
  initialParticipantData = null,
  changePhoneNumber,
  changeFirstName,
  changeLastName,
  changeRelationship,
  checkUser,
  mobileLookup = { type: '', user: '' },
  participantForm = {
    phoneNumber: '', firstName: '', lastName: '', relationship: '',
  },
  clearMobileLookup,
  lazyLoad,
  usersList = [],
  orgName,
  putChatParticipant,
  putChatParticipantStatus = '',
  setPutChatParticipantStatus,
  activeChatId,
  activeJobPhoneNumber,
  updateUser,
  jobId,
  updateUserStatus = '',
  lobSubType,
}) => {
  const isExistingUser = usersList?.[0]?.phoneNumber === participantForm.phoneNumber;
  const [isVerifiedUser, setIsVerifiedUser] = useState(false);
  const isInEditMode = !!initialParticipantData;

  const relationshipOptions = [...relationships];
  if (initialParticipantData?.relationship) {
    const relationshipInOptionsList = relationships.find(
      (item) => item.value === initialParticipantData?.relationship,
    );

    if (!relationshipInOptionsList) {
      relationshipOptions.push({
        value: initialParticipantData.relationship,
        label: initialParticipantData?.relationship,
      });
    }
  }

  useEffect(() => {
    const [firstPerson] = usersList;
    if (firstPerson?.phoneNumber === participantForm.phoneNumber) {
      changeFirstName(firstPerson.firstName);
      changeLastName(firstPerson.lastName);

      const verifiedUser = firstPerson?.verified;
      if (verifiedUser) {
        setIsVerifiedUser(true);
      }
    }
  }, [changeFirstName, changeLastName, participantForm.phoneNumber, usersList]);

  useEffect(() => {
    if (initialParticipantData) {
      changePhoneNumber(initialParticipantData?.phoneNumber);
      changeFirstName(initialParticipantData?.firstName);
      changeLastName(initialParticipantData?.lastName);
      changeRelationship(initialParticipantData?.relationship);
    } else {
      changePhoneNumber('');
      changeFirstName('');
      changeLastName('');
      changeRelationship('');
    }

    return () => {
      clearMobileLookup();
      setPutChatParticipantStatus('');
    };
  }, []);

  useEffect(() => {
    if (putChatParticipantStatus === 'SUCCESS' || updateUserStatus === 'SUCCESS') {
      onClose();
    }
  }, [onClose, putChatParticipantStatus, updateUserStatus]);

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    // removing country code and non integers
    let cleanValue = value.replace('+1', '').replace(/\D/g, '');
    if (cleanValue.length > 10) {
      cleanValue = cleanValue.slice(0, 10);
    }

    changePhoneNumber(cleanValue);

    if (cleanValue.length !== 10) {
      clearMobileLookup();
    }

    if (cleanValue.length === 10) {
      checkUser(cleanValue);
      lazyLoad(
        userType,
        { role: 'enduser', phoneStartsWith: cleanValue, offset: 0 },
        'users',
      );
    }
  };

  let mobileLookupMessage: string | null = ['MOBILE', 'VOIP'].includes(mobileLookup?.type) ? 'SMS Supported' : '';
  // check if phone number entered is primary insured
  if (activeJobPhoneNumber === participantForm.phoneNumber) {
    mobileLookupMessage = 'This phone number is already active on the conversation';
  }
  if (isInEditMode) {
    mobileLookupMessage = null;
  }

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    changeFirstName(value.trimStart());
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    changeLastName(value.trimStart());
  };
  const handleRelationshipChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => changeRelationship(event.target.value);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (isInEditMode) {
      updateUser(
        {
          user: {
            profile: {
              firstName: participantForm?.firstName,
              lastName: participantForm?.lastName,
              phoneNumber: participantForm?.phoneNumber,
            },
            relationships: {
              [String(jobId)]: participantForm.relationship,
            },
          },
          _id: initialParticipantData.id,
        },
      );
    } else {
      const endUserId = usersList?.[0]?.id;
      putChatParticipant({ chatId: activeChatId, endUserId, ...participantForm });
    }
  };

  return (
    <ParticipantForm
      onClose={onClose}
      mobileLookupMessage={mobileLookupMessage}
      handlePhoneNumberChange={handlePhoneNumberChange}
      handleFirstNameChange={handleFirstNameChange}
      handleLastNameChange={handleLastNameChange}
      handleRelationshipChange={handleRelationshipChange}
      participantForm={participantForm}
      requestStatus={isInEditMode ? updateUserStatus : putChatParticipantStatus}
      onSubmit={handleSubmit}
      relationships={relationshipOptions}
      isExistingUser={isExistingUser}
      orgName={orgName}
      verifiedUser={isVerifiedUser}
      isInEditMode={isInEditMode}
      isPrimaryEndUser={initialParticipantData?.isPrimaryEndUser || false}
      lobSubType={lobSubType}
    />
  );
};

export default connector(ParticipantFormContainer);
