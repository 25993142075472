import React, { Component } from 'react';
import _ from 'lodash';
import './SearchOperators.less';
import PropTypes from 'prop-types';
import UserList from '../../Chats/RightBar/UserList/UserList';

class SearchOperators extends Component {
  constructor(props) {
    super(props);
    this.state = { };
    this.selectItem = this.selectItem.bind(this);
  }

  selectItem = (id, area = 'list') => {
    const {
      selectAction, auth, operatorList, dropdownLabelItems, recentlyAssignedOperators,
    } = this.props;
    const selectedItem = operatorList.find((i) => i.id === id) || _.concat(dropdownLabelItems, recentlyAssignedOperators).find((i) => i.id === id);
    if (selectedItem) {
    //   this.setState({ operatorId: selectedItem.id });
      const formattedSelectedItem = selectedItem.id === auth ? { id: selectedItem.id, label: selectedItem.label.replace('Assign to ', ''), email: selectedItem.email } : { id: selectedItem.id, label: selectedItem.name || selectedItem.label, email: selectedItem.email };
      selectAction(formattedSelectedItem, area);
    }
  };

  render() {
    const {
      inputElement, defaultView, operatorList, recentlyAssignedOperators, dropdownLabelItems,
    } = this.props;
    const filterClass = 'scroller filter-list-border filter-list-';
    const formatedOperatorList = operatorList.map((operator) => ({
      id: operator.id, label: operator.name, email: operator.email, title: operator.title, role: operator.role, imageUrl: operator.imageUrl,
    }));
    return (
      <div>
        <div className="always-list">
          {!inputElement?.length ? <UserList displayEmail className="operator-item" items={dropdownLabelItems} onToggle={this.selectItem} /> : null}
        </div>

        {
                    defaultView
                      ? (
                        <div className={`${filterClass}default-border`}>
                          <UserList displayEmail className="operator-item" items={recentlyAssignedOperators} onToggle={this.selectItem} />
                        </div>
                      ) : (
                        <div className={`${filterClass}filter-border`}>
                          {
                                formatedOperatorList.length > 0 && formatedOperatorList
                                  ? <UserList displayEmail className="operator-item" items={formatedOperatorList} onToggle={this.selectItem} />
                                  : <div className="search-options-none-found">{`Sorry! We couldn't find '${inputElement}'`}</div>
                            }
                        </div>
                      )
                }
      </div>
    );
  }
}

SearchOperators.propTypes = {
  inputElement: PropTypes.string,
  defaultView: PropTypes.bool,
  operatorList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    }),
  ),
  recentlyAssignedOperators: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    }),
  ),
  dropdownLabelItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    }),
  ),
  selectAction: PropTypes.func.isRequired,
  auth: PropTypes.number.isRequired,
};

SearchOperators.defaultProps = {
  inputElement: '',
  defaultView: false,
  operatorList: [],
  recentlyAssignedOperators: [],
  dropdownLabelItems: [],
};

export default SearchOperators;
