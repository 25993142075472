import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '../../../icons/AddIcon';
import CloseButtonIcon from '../../../icons/CloseButtonIcon';
import IconButton from '../../../elements/buttons/IconButton/IconButton';

const GroupListItem = ({ item, updateList, isSelected }) => {
  const memberCount = item.count;
  const countRead = !memberCount ? 'No Members' : `${memberCount} Members`

  return (
    <div className="item">
      <div className="item-column">
        <div className="name">{item?.name}</div>
        <div className="role-row">
          <div className="role">
            {isSelected ? 'Group' : countRead}
          </div>
        </div>
      </div>
      {isSelected ? (
        <IconButton
          className="remove-icon"
          data-test="remove-button"
          tooltip="Remove group"
          onClick={updateList}
          data-testid="removeButton"
        >
          <CloseButtonIcon className="close-button-icon" />
        </IconButton>
      ) : (
        <div className="button-column">
          <IconButton
            className="add-icon"
            tooltip="Add group"
            onClick={updateList}
            data-testid="addButton"
          >
            <AddIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

GroupListItem.propTypes = {
  updateList: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object),
  groupsMap: PropTypes.shape({}),
  isSelected: PropTypes.bool,
};

GroupListItem.defaultProps = {
  item: {},
  groupsMap: {},
  isSelected: false,
};

export default GroupListItem;
