import React from 'react'
import _ from 'lodash'
import Modal from '../../../modal/Modal/Modal'

// import EditGroup from './EditGroup/EditGroup'
import InfoPanel from './InfoPanel'
import SubscribersPanel from './SubscribersPanel'
import withModal from '../../../HigherOrderComponents/withModal'
import './Row.less'

const Row = ({ queue }) => {
  const subscribers = _.get(queue, 'subscribers', [])
  return (
    <div className="queue-row-container">
      <div className="queue-row" data-test="queue-row" style={{ display: 'flex', width: '100%', }}>
        <InfoPanel queue={queue} />
        <SubscribersPanel subscribers={subscribers} queueId={_.get(queue, 'id')} />
      </div>
    </div>
  )
}

const RowWithModal = withModal({
  Modal: (
    <Modal
      className="edit-group-modal"
      data-test="edit-queue-modal"
      title="Edit a Queue"
    >
      <div />
    </Modal>
  ),
})(Row)

export default RowWithModal
