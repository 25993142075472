export const CREATING_MESSAGES = 'CREATING_MESSAGES';
export const VALIDATION_COMPLETE = 'VALIDATION_COMPLETE';
export const PROCESSING = 'PROCESSING';
export const SENDING = 'SENDING';
export const SENT = 'SENT';
export const TRANSCRIPT_CREATED = 'TRANSCRIPT_CREATED';
export const FAILED = 'FAILED';
export const MAX_ENTRIES_SURPASSED = 'MAX_ENTRIES_SURPASSED';
export const NO_VALID_ENTRIES = 'NO_VALID_ENTRIES';
export const FAILED_TO_SEND = 'FAILED_TO_SEND';
export const PARTIALLY_FAILED = 'PARTIALLY_FAILED';
