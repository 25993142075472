import React from 'react';
import PropTypes from 'prop-types';
import UserPhoto2 from '../../../UserPhoto2/UserPhoto2';

import './UserRow.less';

const UserRow = ({
  children, active, user, right, onClick, className,
}) => {
  const isCustomer = (user.role !== 'operator');
  const nameCleanUp = user.name.replace('Assign to', '').replace('(me)', '').trim(); // removing the "Assign to" and "(me)" from the name
  return (
    <div
      className={`user-row ${active ? 'active' : ''} ${className || ''}`}
      role="link"
      tabIndex="0"
      onClick={onClick}
      onKeyDown={onClick}
      data-testid={`manage-user-row-${user.id}`}
    >
      <UserPhoto2
        type="thumb"
        id={user.id}
        imageUrl={user.imageUrl}
        name={nameCleanUp}
        isCustomer={isCustomer}
      />
      <div className="user-info">
        {children}
      </div>
      {right}
    </div>
  );
};

UserRow.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  right: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

UserRow.defaultProps = {
  active: false,
  right: null,
  onClick: () => {},
  className: '',
};
export default UserRow;
