import { formatSuccess, formatInit } from '../constants/endpoints'

export const initialState = {
  loading: {},
  completed: {},
}

const reducer = (types, state = initialState, action) => {
  const initAction = types.find(t => formatInit(t) === action.type)
  const successAction = types.find(t => formatSuccess(t) === action.type)
  if (initAction || successAction) {
    const isLoading = initAction !== undefined || successAction === undefined
    const isCompleted = initAction === undefined || successAction !== undefined

    const completed = {}
    completed[initAction || successAction] = isCompleted
    const loading = {}
    loading[initAction || successAction] = isLoading

    return {
      ...state,
      loading: {
        ...state.loading,
        ...loading,
      },
      completed: {
        ...state.completed,
        ...completed,
      },
    }
  } else {
    return state
  }
}

export default reducer
