import React, {
  useState, useEffect, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import {
  Button, Card, Divider, WithDot,
} from '@himarley/unity';
import NoteHeader from './NoteHeader';
import Reply from './Reply';
import NoteInput from './NoteInput';
import { TagItem } from '../../../../elements/TagItem/TagItem';
import { INTEGRATION_NOTE_TYPE, RELEASE_ISSUE_TAG_TEXT } from '../../../../../constants/notes';
import NoteBody from './NoteBody';
import RefreshIcon from '../../../../../../images/icons/refresh-arrow.svg';
import { handleCaseCacheItem } from '../../../../../cache/caseCache';
import { markCaseNoteRead } from './Note.helpers';

const Note = ({
  activeCase,
  note,
  setShowDeleteConfirmation,
  setActiveNoteIds,
  authId,
  addReply,
  readNoteMention,
  hasNotesReplies,
}) => {
  const componentRef = useRef(null);
  const [showCreateReply, setShowCreateReply] = useState(false);
  const store = useSelector((state) => state);
  const queryClient = useQueryClient();

  // auto set note to read after 3 seconds - clears blue dot/highlight from note
  useEffect(() => {
    let timer = null;
    if (note?.notReadMentionedUsers?.[authId]) {
      timer = setTimeout(() => {
        const caseItem = markCaseNoteRead(activeCase, authId);
        handleCaseCacheItem(caseItem, 'read-note', queryClient, store);
        readNoteMention(activeCase?.id, authId, note?._id);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [activeCase?.id, note, authId, readNoteMention]);

  const isNoteUnread = note?.notReadMentionedUsers?.[authId] || false;
  const isNoteDeleted = note?.archived && note?.replies?.length > 0;
  const isIntegrationType = note?.type === INTEGRATION_NOTE_TYPE;

  return (
    <div ref={componentRef}>
      <WithDot
        type="blue"
        show={isNoteUnread}
        position="top-left"
        className={`cardWithDot ${isNoteUnread ? 'noteCardWithDot' : ''}`}
      >
        <Card
          className={`${isNoteUnread ? 'marleyCardHighlight' : null} noteCard`}
        >
          <NoteHeader
            note={note}
            setShowDeleteConfirmation={setShowDeleteConfirmation}
            setActiveNoteIds={setActiveNoteIds}
            authId={authId}
            noteId={note?._id}
            isDeleted={isNoteDeleted}
          />
          {!isNoteDeleted && (
            <NoteBody
              mentionedUserIds={note?.mentionedUserIds}
              noteText={note?.noteText}
              noteType={note?.type}
              noteId={note?._id}
            />
          )}
          {isIntegrationType && <TagItem testId={`release-issue-badge-${note?._id}`} text={RELEASE_ISSUE_TAG_TEXT} icon={<RefreshIcon />} />}
          {hasNotesReplies === true && !isIntegrationType
            && (!note?.replies?.length ? (
              !showCreateReply && (
              <Button
                data-testid="new-note-button"
                data-test="new-note-button"
                className="replyButton"
                onClick={() => setShowCreateReply(true)}
                type="outline"
              >
                Reply
              </Button>
              )
            ) : (
              <>
                <Divider />
                <div className="marley-note-author-name">
                  {note?.replies?.length === 1
                    ? `${note?.replies?.length} Reply`
                    : `${note?.replies?.length} Replies`}
                </div>
                {note?.replies?.map((reply, index) => (
                  <>
                    <Reply
                      reply={reply}
                      key={`reply-${reply._id}`}
                      header={(
                        <NoteHeader
                          note={reply}
                          authId={authId}
                          isReply
                          noteId={note?._id}
                          replyId={reply?._id}
                          setShowDeleteConfirmation={setShowDeleteConfirmation}
                          setActiveNoteIds={setActiveNoteIds}
                          isDeleted={reply?.archived}
                        />
                      )}
                      isLastReply={note?.replies && note.replies.length - 1 === index}
                      authId={authId}
                      activeCase={activeCase}
                      setShowCreateNote={setShowCreateReply}
                      showCreateReply={showCreateReply}
                      noteId={note?._id}
                      readNoteMention={readNoteMention}
                    />
                    {note?.replies && note.replies.length - 1 !== index && <Divider />}
                  </>
                ))}
              </>
            ))}
          {showCreateReply && (
            <>
              <Divider />
              <NoteInput
                setShowCreateNote={setShowCreateReply}
                isReply
                addReply={addReply}
                note={note}
                activeCaseId={activeCase?.id}
              />
            </>
          )}
        </Card>
      </WithDot>
    </div>
  );
};

Note.propTypes = {
  note: PropTypes.shape({
    replies: PropTypes.shape([]),
    type: PropTypes.string.isRequired,
    noteText: PropTypes.string.isRequired,
    archived: PropTypes.bool.isRequired,
    notReadMentionedUsers: PropTypes.shape([]),
    _id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    mentionedUserIds: PropTypes.shape([]),
    plainText: PropTypes.string.isRequired,
  }),
  activeCase: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  setShowDeleteConfirmation: PropTypes.func.isRequired,
  setActiveNoteIds: PropTypes.func.isRequired,
  authId: PropTypes.string.isRequired,
  addReply: PropTypes.func,
  readNoteMention: PropTypes.func,
  hasNotesReplies: PropTypes.bool,
};

Note.defaultProps = {
  note: {},
  addReply: () => {},
  readNoteMention: () => {},
  hasNotesReplies: false,
  activeCase: {},
};

export default Note;
