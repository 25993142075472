/* eslint-disable no-console */
import _ from 'lodash';
import {
  AUTH_USER,
  DEAUTH_USER,
  AUTH_ERROR,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  GET_USER_BY_OKTA_USER_ID,
  SET_SOCKET,
} from '../constants/actions';
import {
  CHANGE_PASSWORD_ENDPOINT,
  MARK_OPERATOR_ACTIVE,
  GET_USER_BY_OKTA_USER_ID_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
} from '../constants/endpoints';
import { apiCall, doRequest } from './api';
import { toggleAutoAssign, getViewedChats } from './profile';
import { PUT, POST, GET } from '../constants/general';
import buildSocketClient from '../SocketClient';

export function logout() {
  return (dispatch, getState) => {
    const user = _.get(getState(), 'auth.user');
    const isAutoAssign = _.get(getState(), 'auth.user.isAutoAssign');
    if (isAutoAssign) {
      Promise.resolve(dispatch(toggleAutoAssign()))
        .then(() => dispatch({ type: DEAUTH_USER, payload: { user } }))
        .catch((err) => console.error(err));
    } else {
      dispatch({ type: DEAUTH_USER, payload: { user } });
    }
  };
}

/**
 * Builds and sets socket object into the react store.
 * Consider moving the global.socketBuildPromise into the store
 */
export const setupSocketClient = (topicHandler) => (dispatch, getState) => {
  global.socketBuildPromise = buildSocketClient('all', getState, dispatch)
    .then((socket) => {
      dispatch({
        type: SET_SOCKET,
        payload: {
          socket,
          topicHandler,
        },
      });
    })
    .catch((err) => {
      console.error(err);
      // TODO ADD ERROR TO SPLASH SCREEN ONCE POLLBOX IS STRIPPED
    });
};

export function getUserByOktaUserId(oktaUserId) {
  const userId = oktaUserId.toLowerCase();
  return (dispatch) => {
    const args = {
      endpoint: GET_USER_BY_OKTA_USER_ID_ENDPOINT(userId),
      method: GET,
      action: GET_USER_BY_OKTA_USER_ID,
      process: (({ body: userInfo }) => {
        const id = userInfo?._id || userInfo?.id;
        dispatch(getViewedChats(id));
        dispatch({ type: AUTH_USER, payload: { user: userInfo } });
        return userInfo;
      }),
    };
    dispatch(apiCall({ args }));
  };
}

export function setAuthError(error) {
  return { type: AUTH_ERROR, payload: error };
}

export function changePassword(data) {
  return (dispatch) => {
    try {
      const args = {
        endpoint: CHANGE_PASSWORD_ENDPOINT,
        method: PUT,
        action: CHANGE_PASSWORD,
        body: data,
      };
      dispatch(apiCall({ args }));
    } catch (e) {
      console.error(e);
    }
  };
}

export function resetPassword(email) {
  return (dispatch) => {
    try {
      const args = {
        endpoint: RESET_PASSWORD_ENDPOINT,
        method: POST,
        action: RESET_PASSWORD,
        body: { email },
        process: (t) => t,
      };
      dispatch(apiCall({ args }));
    } catch (e) {
      console.error(e);
    }
  };
}

export function setOperatorActive() {
  return (dispatch, getState) => {
    // eslint-disable-next-line no-underscore-dangle
    const opID = getState()?.auth?.user?._id ?? null;
    if (!opID) { return; }
    // eslint-disable-next-line consistent-return
    return doRequest({
      endpoint: MARK_OPERATOR_ACTIVE,
      method: 'PATCH',
      body: {
        operatorID: opID,
      },
    });
  };
}
