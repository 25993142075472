import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Checkbox,
  Button,
  TextAreaInput,
  ButtonRow,
  Badge,
} from '@himarley/unity';
import { formatDate } from '../../../helpers/datetime';
import InfoError from '../../../../images/icons/info-error.svg';
import './ReleaseIssuesModal.less';
import { useSelector } from 'react-redux';

const ReleaseIssuesModal = ({
  onSubmitReleaseIssues,
  show,
  setShow,
  releaseIssueData,
  selectedReleaseIssue,
}) => {
  const buildFormData = (existingData) => releaseIssueData.reduce((initialFormData, issue) => {
    const existingRecord = existingData && existingData[issue.id];
    const checked = issue.id === selectedReleaseIssue
      || (existingRecord && existingRecord.checked) || false;
    // eslint-disable-next-line no-param-reassign
    initialFormData[issue.id] = {
      checked,
      comment: (existingRecord && existingRecord.comment) || '',
    };
    return initialFormData;
  }, {});
  const { isSubmitting, markAsCompleteError } = useSelector(({ ui }) => ({
    isSubmitting: ui.releaseIssueModal.isSubmitting,
    markAsCompleteError: ui.releaseIssueModal.hasError,
  }));
  const initialState = buildFormData();
  const [formData, setFormData] = useState(initialState);
  const [releaseIssuesList, setReleaseIssuesList] = useState(releaseIssueData);
  const [errors, setErrors] = useState(false);
  const checkedItems = useMemo(() => Object.values(formData).filter(
    (value) => value.checked,
  ).length, [formData]);
  const displayErrors = errors || markAsCompleteError;
  const errorText = errors ? 'Please add a note for each release issue you are marking as complete.' : 'There was an error while marking release issues complete. Please try again.';

  const keyById = (checked) => releaseIssuesList.reduce((acc, issue) => {
    acc[issue.id] = {
      checked,
      comment: '',
    };
    return acc;
  }, {});
  const reorderReleaseList = (list, id) => {
    const index = list.findIndex((item) => item.id === id);
    if (index === -1) {
      return list;
    }
    const reorderedArray = [list[index], ...list.slice(0, index), ...list.slice(index + 1)];
    return reorderedArray;
  };

  useEffect(() => {
    const orderedReleaseIssues = selectedReleaseIssue
      ? reorderReleaseList(releaseIssueData, selectedReleaseIssue)
      : releaseIssueData;
    setReleaseIssuesList(orderedReleaseIssues);
    setFormData(buildFormData(formData));
  }, [selectedReleaseIssue, releaseIssueData]);

  const showSelectAll = checkedItems === releaseIssuesList.length;
  const handleSelectAll = () => {
    setFormData(keyById(!showSelectAll));
    setErrors(false);
  };

  const handleCheckedEvent = (id, event) => {
    const { target } = event;
    if (target.tagName === 'TEXTAREA') {
      event.stopPropagation();
      return;
    }
    setFormData({
      ...formData,
      [id]: {
        checked: !formData[id].checked,
        comment: formData[id]?.comment,
        ...(formData[id].error && { error: false }),
      },
    });
    setErrors(false);
  };

  const handleTextChangeEvent = (id, event) => {
    const comment = event?.target?.value || '';
    setFormData({
      ...formData,
      [id]: {
        checked: formData[id].checked,
        comment,
        ...(formData[id].error && { error: false }),
      },
    });
    setErrors(false);
  };

  const handleCancel = () => {
    setShow(false);
    setFormData(keyById(false));
    setErrors(false);
  };

  const handleSubmit = () => {
    const checkedEmptyComments = Object.entries(formData)
      .filter(([, item]) => item.checked && item.comment.trim() === '')
      .map(([id]) => id);
    if (checkedEmptyComments.length) {
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };
        checkedEmptyComments.forEach((id) => {
          updatedFormData[id] = {
            ...updatedFormData[id],
            error: true,
          };
        });
        return updatedFormData;
      });
      setErrors(true);
      return;
    }
    const filteredFormData = Object.keys(formData)
      .filter((key) => formData[key].checked)
      .map((key) => ({ id: key, comment: formData[key].comment }));
    setErrors(false);
    setFormData(buildFormData);
    onSubmitReleaseIssues({
      type: 'total_loss.release_issue.completed', // MAY be completed ??
      eventTime: new Date().toISOString(),
      params: {
        issues: filteredFormData,
      },
    });
  };

  return (
    <Modal
      show={show}
      title="Complete Release Issues"
      toggleModal={handleCancel}
    >
      <p>Select release issues below to mark as complete and submit notes to the yard.</p>
      {displayErrors && (
        <p
          className="release-issues-error"
        >
          <InfoError />
          <span>{errorText}</span>
        </p>
      )}
      <Button
        type="outline"
        onClick={handleSelectAll}
        className="release-issue-select-all"
        testId="release-issue-select-all"
      >
        { !showSelectAll ? ' Select All' : 'Deselect All'}
      </Button>
      <div
        className="release-issues-container"
      >
        {releaseIssuesList.map(({ id: issueId, description, timestamp }, index) => (
          <div
            key={issueId}
            className={
              `release-issue-row
              ${formData[issueId].checked ? 'release-issue-selected' : ''}
              ${index === releaseIssuesList.length - 1 ? 'release-issue-last' : ''}
              ${formData[issueId].error ? 'release-issue-row-error' : ''}
            `
            }
            onClick={(event) => handleCheckedEvent(issueId, event)}
            role="button"
            tabIndex={0}
            onKeyDown={(event) => handleCheckedEvent(issueId, event)}
          >
            <section
              className="release-modal-flex"
            >
              <Checkbox
                checked={formData[issueId].checked}
                onChange={(event) => handleCheckedEvent(issueId, event)}
                label={description}
                className="release-issue-checkbox"
                name={`release-issue-${issueId}`}
              />
              <p
                className="release-timestamp"
              >
                { formatDate(timestamp, 'date-time') }
              </p>
            </section>
            {formData[issueId].checked && (
              <>
                <TextAreaInput
                  testId={`release-issue-${issueId}-comment`}
                  className="release-issue-textarea"
                  placeholder="Add note..."
                  onChange={(e) => handleTextChangeEvent(issueId, e)}
                  maxLength={1054}
                  value={formData[issueId]?.comment}
                  showMaxLengthOnTopRight
                />
                { formData[issueId].error && (
                  <p data-testid={`${issueId}-release-issues-error`} className="release-issues-error">
                    <InfoError />
                    <span>A note is required.</span>
                  </p>
                )}
              </>
            )}
          </div>
        ))}
      </div>
      <ButtonRow>
        {checkedItems > 0 && (
          <div className="checked-issues-counter">
            <Badge
              className="checked-issues-badge"
              value={checkedItems}
            />
            <span>Selected Issues</span>
          </div>
        )}
        <Button
          type="outline"
          onClick={handleCancel}
          testId="release-issue-modal-cancel"
        >
          Cancel
        </Button>
        <Button
          type={checkedItems > 0 && !isSubmitting ? 'neutral' : 'disabled'}
          onClick={handleSubmit}
          testId="release-issue-modal-submit"
        >
          Mark as Complete
        </Button>
      </ButtonRow>
    </Modal>
  );
};

ReleaseIssuesModal.propTypes = {
  onSubmitReleaseIssues: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  releaseIssueData: PropTypes.shape([]),
  selectedReleaseIssue: PropTypes.string,
};
ReleaseIssuesModal.defaultProps = {
  releaseIssueData: [],
  selectedReleaseIssue: '',
};

export default ReleaseIssuesModal;
