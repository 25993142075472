/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import PropTypes from 'prop-types'
import ImportCSVFile from './ImportCSVFile';
import { Button } from '@chakra-ui/react';

import {
  downloadCSVTemplate,
} from '../../../actions/massNotifications'

const CreateMassNotificationInfo = ({
  createButtonEnabled,
  currentFilename,
  handleClickSelectFile,
  handleFileDrop,
  handleFileInputChange,
  handleFormInputChange,
  handleNextStep,
  fileInputRef,
  formData,
}) => (
  <>
    <div className="modal-container paddedInModal">
      <h3 className="modal-step-title">Step 1: Create Notification</h3>
      <div className="miniTitle">Import recipient information by CSV file</div>
      <ImportCSVFile
        downloadCSVTemplate={downloadCSVTemplate}
        handleFileDrop={handleFileDrop}
        currentFilename={currentFilename}
        handleClickSelectFile={handleClickSelectFile}
        fileInputRef={fileInputRef}
        handleFileInputChange={handleFileInputChange}
      />
    </div>
    <div className="miniTitle paddedInModal">Create Information</div>
    <div className="messageInfoContainer paddedInModal">
      <div className="notificationNameContainer">
        <div className="innerContainer">
          <div className="inputTitle">Notification Name*</div>
          <input
            maxLength="31"
            name="notificationTitle"
            onChange={handleFormInputChange}
            type="text"
            value={formData.notificationTitle}
          />
        </div>
      </div>
    </div>
    <div className="messageInputContainer paddedInModal">
      <textarea
        className="messageInput"
        placeholder="Write notification here"
        maxLength="1600"
        name="notificationMessage"
        onChange={handleFormInputChange}
        value={formData.notificationMessage}
      />
    </div>
    <div className="buttonRow">
      <Button
        type="button"
        onClick={handleNextStep}
        isDisabled={!createButtonEnabled}
      >
        Next
      </Button>
    </div>
  </>
);

CreateMassNotificationInfo.propTypes = {
  createButtonEnabled: PropTypes.bool,
  currentFilename: PropTypes.string.isRequired,
  handleClickSelectFile: PropTypes.func.isRequired,
  handleFileDrop: PropTypes.func.isRequired,
  handleFileInputChange: PropTypes.func.isRequired,
  handleFormInputChange: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  fileInputRef: PropTypes.shape({
    current: PropTypes.element,
  }).isRequired,
  formData: PropTypes.shape({
    notificationMessage: PropTypes.string,
    notificationTitle: PropTypes.string,
  }),
}

CreateMassNotificationInfo.defaultProps = {
  createButtonEnabled: undefined,
  formData: {},
}

export default CreateMassNotificationInfo
