export const PUT = 'PUT';
export const POST = 'POST';
export const GET = 'GET';

export const AI_MARLEY_CHAT_NAME = 'Marley';

export const ROLE_OPERATOR = 'operator';
export const ROLE_ENDUSER = 'enduser';

export const DEADLINE_OVERDUE_TEXT = 'Overdue';

export const HTTP_ERROR_TEXT = 'Error Occurred';

export const PASSWORD_MIN_LENGTH = 5;
export const CHANGE_PASSWORD_MESSAGE = 'Password successfully changed';

export const TIMEOUT_DURATION = 43200; // 12 hours in seconds

export const AUTO_ASSIGN_PERMISSIONS = ['INBOUND', 'TRIAGE_REP'];

export const DISPLAY_MESSAGE_STATUS_FEATURE_START_DATE = '2022-08-28';

export const PAGE_SIZE = 32;
export const LOWEST_PRIORITY_SCORE = 100;

export const SUCCESS = 'success';
export const FAILURE = 'failure';

export const BROADCAST_CHANNEL = 'app-data';

export const REDACTED_IMAGE = 'REDACTED_IMAGE';

export const CHANGE_UNIQUE_ID = 'changeUniqueId';
