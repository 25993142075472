import {
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  Box,
  MenuItem,
  Button,
  Icon,
  type As,
  type MenuButtonProps,
} from '@chakra-ui/react';
import { CaretUpIcon, CaretDownIcon } from '@himarley/unity';
import React from 'react';

interface MenuProps {
  id: string;
  selectedId?: string;
  defaultLabel?: string;
  options: {
    id: string
    label: string
    rightIcon?: As
    leftIcon?: As
    isDisabled?: boolean
    onClick?: () => void
  }[];
  isLazy?: boolean;
  handleSelect?: (e: unknown) => void;
  menuButtonProps?: {
    size?: string
    variant?: string
    textAlign?: MenuButtonProps['textAlign']
    as?: As,
    w?: string
    icon?: As
  };
  sx?: React.CSSProperties
}

const Menu: React.FC<MenuProps> = ({
  id,
  selectedId,
  defaultLabel,
  options,
  isLazy = true,
  menuButtonProps = {},
  handleSelect,
  sx,
}) => {
  const defaultMenuButtonProps = {
    size: 'sm',
    variant: 'outline',
    textAlign: 'left' as MenuButtonProps['textAlign'],
    as: Button,
  };

  const mergedMenuButtonProps = { ...defaultMenuButtonProps, ...menuButtonProps };

  const selectedItem = options?.length ? options.find((option) => option.id === selectedId) : null;

  return (
    <ChakraMenu isLazy={isLazy} data-testid={`${id}-menu`}>
      {({ isOpen }) => (
        <>
          <MenuButton
            data-testid={`${id}-menu-button`}
            size={mergedMenuButtonProps.size}
            variant={mergedMenuButtonProps.variant}
            textAlign={mergedMenuButtonProps.textAlign}
            as={mergedMenuButtonProps.as}
            w={mergedMenuButtonProps.w}
            icon={mergedMenuButtonProps.icon && <Icon as={mergedMenuButtonProps.icon} />}
            rightIcon={
              !mergedMenuButtonProps.icon
                ? <Icon as={isOpen ? CaretUpIcon : CaretDownIcon} />
                : undefined
            }
          >
            <Box isTruncated>{selectedItem ? selectedItem.label : defaultLabel}</Box>
          </MenuButton>
          <MenuList sx={{
            maxHeight: '300px',
            overflowY: 'auto',
            ...sx,
          }}
          >
            {options?.map((option) => (
              <MenuItem
                key={option.id}
                data-testid={`menu-item-${option.id}`}
                onClick={handleSelect ? () => handleSelect(option.id) : option.onClick}
                isDisabled={option.isDisabled}
                justifyContent="space-between"
                icon={option.leftIcon ? <Icon as={option.leftIcon} /> : undefined}
              >
                {option.label}
                {option.rightIcon && <Icon float="right" as={option.rightIcon} />}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </ChakraMenu>
  );
};

export default Menu;
