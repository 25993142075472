import { createSelector } from '@reduxjs/toolkit';
import { isAnyReleaseIssueOpen } from '../../../helpers/copart-utils';

const selectActiveJob = ({ jobs }) => jobs.activeJob || {};
const selectActiveChat = ({ jobs }) => jobs.activeChat || {};
const selectWelcomeEmailRequest = ({ jobs }) => jobs.welcomeEmailRequest;
const selectWelcomeEmailError = ({ jobs }) => jobs.welcomeEmailError;
const selectProfile = (state) => state.profile;
const selectUi = (state) => state.ui || {};
const selectAuth = (state) => state.auth;
const selectUsers = (state) => state.users;

const selector = (
  activeJob,
  activeChat,
  welcomeEmailRequest,
  welcomeEmailError,
  profile,
  ui,
  auth,
  users,
) => {
  const {
    id: chatId, isLastMessageInLog, jobId, messages,
  } = activeChat;
  const typingUserIds = ui.typingByChatId[chatId] || [];
  const {
    isOpen: isReleaseIssueModalOpen = false,
    selectedReleaseIssue = {},
    hasError: releaseIssueModalHasError,
  } = ui.releaseIssueModal || {};

  const jobNumber = activeJob.displayId;
  const {
    referenceId,
    isClosed,
    customer: recipient = {},
    releaseIssues = [],
    assignedOperator = {},
  } = activeJob;

  const isOpenIssuesRemaining = isAnyReleaseIssueOpen(releaseIssues);

  const {
    token, permissions, user, settings = {},
  } = auth;
  const { AICompanyName = 'Marley' } = settings;

  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const chatPdfLink = `/api/chat/${jobId}/pdf?tzone=${currentTimezone}&endpoint=${process.env.WEBSOCKET_ENDPOINT?.endpoint}`;
  const customerPdfLink = `/api/chat/${recipient.id}/transcript-splash-screen?tzone=${currentTimezone}&endpoint=${process.env.WEBSOCKET_ENDPOINT?.endpoint}&getCustomerTranscript=true`;

  const { id: assignedOperatorId } = assignedOperator;
  const assignedOperatorName = assignedOperatorId === profile._id
    ? 'you'
    : assignedOperator.name || '';
  const isLocked = recipient.role === 'enduser' && recipient.locked;
  const isBlocked = recipient.role === 'enduser' && recipient.blocked;
  const isNotVerified = recipient.role === 'enduser' && !recipient.verified;
  const { inChatBotMode: isInChatBotMode = false } = recipient;
  const isInFNOLFlow = !!activeJob.activeWorkflow;
  const changedMarleyNumber = activeJob.marleyNumberStatus === 'NEW_MARLEY_NUMBER';

  const { sendSurvey: surveyRequest } = ui.requests || {};

  const { organization } = user || {};
  const { lockingUserProgress } = users || {};

  return {
    releaseIssues,
    selectedReleaseIssue,
    isReleaseIssueModalOpen,
    releaseIssueModalHasError,
    isOpenIssuesRemaining,
    chatId,
    jobId,
    jobNumber,
    chat: activeChat,
    isLastMessageInLog,
    isClosed,
    typingUserIds,
    messages,
    token,
    recipient,
    chatPdfLink,
    customerPdfLink,
    assignedOperator: assignedOperatorName,
    isLocked,
    isBlocked,
    isNotActivated: isNotVerified || !recipient.activated,
    welcomeEmailRequest,
    welcomeEmailError,
    referenceId,
    AICompanyName,
    surveyRequest,
    isInChatBotMode,
    changedMarleyNumber,
    job: activeJob,
    organization,
    permissions,
    lockingUserProgress,
    isInFNOLFlow,
  };
};

export default createSelector(
  selectActiveJob,
  selectActiveChat,
  selectWelcomeEmailRequest,
  selectWelcomeEmailError,
  selectProfile,
  selectUi,
  selectAuth,
  selectUsers,
  selector,
);
