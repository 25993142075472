import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import LineDropdown from '../../elements/dropdowns/LineDropdown/LineDropdown';
import List from '../../elements/List/List';
import { typeToLine } from '../../Settings/CompanyHierarchy/helpers';
import './LinesOfBusinessList.less';

const LinesOfBusinessList = ({ listOfLob, onChange, selectedBrand, className }) => {
  const [lobData, setLobData] = useState({});
  const reset = [{ label: 'Choose Line', value: 'Reset' }];
  useEffect(() => {
    if (lobData?.branding !== selectedBrand) {
      setLobData({})
    }
  }, [selectedBrand]);

  const getItemData = useCallback((itemData) => {
    if (itemData[0].value === 'Reset') {
      setLobData({})
    } else {
      setLobData(itemData[0]);
      onChange(itemData)
    }
  }, [onChange])

  const lineOfBusinessLabel = Object.keys(listOfLob ?? {}).map((key) => (
    <>
      <span className='lob-list-label'>{`${typeToLine[key]}s`}</span>
      <List items={listOfLob[key]} getItemData={getItemData} customizedIcon capitalizeLabels />
    </>
  ));

  const renderLobList = useMemo(
    () => (
      <>
        { lobData.branding && (
          <>
            <List
              items={reset}
              getItemData={getItemData}
            />
          </>
        )}
        {lineOfBusinessLabel}
      </>
    ),
    [listOfLob, getItemData, reset],
  )

  return (
    <div data-testId='lob-list-container' className={`${className || ''}`}>
      <LineDropdown
        placeHolder={`${!listOfLob ? 'No Line of Business' : 'Choose Line'}`}
        className={
          `lob-list-container
          ${!listOfLob && 'lob-empty-list'}
          ${!lobData.label && 'lob-list-default'}`
        }
        isDisabled={!listOfLob}
        label={lobData.label}>
        {renderLobList}
      </LineDropdown>
    </div>
  );
};

LinesOfBusinessList.propTypes = {
  listOfLob: PropTypes.object,
  selectedBrand: PropTypes.string,
  onChange: () => {},
  className: PropTypes.string,
};

export default LinesOfBusinessList;
