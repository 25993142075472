/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import AssignUserDropdown from '../AssignDropdown/AssignUserDropdown';
import { handleFormInputChange, lazyLoad } from '../../actions/common';
import { userType } from '../../models/marleyTypes';
import Required from '../icons/Required';
import { pluralize } from '../../helpers/common';

// eslint-disable-next-line react/prefer-stateless-function
class PrimaryContactInput extends React.Component {
  render() {
    const operatorIdLocation = this.props.tableId === 'cases' ? 'operatorId' : 'primaryContact.id';
    const operatorIdKey = this.props.tableId === 'cases' ? 'operatorId' : 'primaryContactId';
    const { props } = this;
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div key="pc" className={`enter-${props.id}-row form-input`}>
        <div className="enter-form-primary-contact-dropdown">
          <FormGroup validationState={props.error ? 'error' : null}>
            <ControlLabel>
              Point of Contact
              {props.required && <Required />}
            </ControlLabel>
            <AssignUserDropdown
              id={props.tableId}
              defaultLabel={_.get(props.editObject, operatorIdLocation) === 'unassigned'
                ? 'Unassigned' : 'Choose Point of contact'}
              className={`enter-${props.id}-assign-dropdown`}
              selectedId={_.get(props.editObject, operatorIdLocation)
                  || _.get(props, 'editObject.primaryContact.id') || undefined}
              handleSelect={(value) => {
                const authUserId = props.authUser._id;
                let primaryContact;
                this.props.toggleDisableParentFormSubmit(false);
                switch (value.id) {
                  case 'unassigned':
                    primaryContact = 'unassigned';
                    break;
                  case 'me':
                    primaryContact = authUserId;
                    break;
                  default:
                    primaryContact = value.id;
                    break;
                }
                props.handleFormInputChange({
                  inputId: operatorIdKey,
                  type: props.type,
                  value: primaryContact,
                });
              }}
              options={_.union(this.props.operators, this.props.recentlyAssignedOperators)}
            />
            <HelpBlock><span className={props.error && 'helpblock-error'}>{props.error && props.errorMessage}</span></HelpBlock>
          </FormGroup>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const tableId = props.tableId === 'policy' ? pluralize(props.tableId) : props.tableId;
  return {
    editObject: props.editObject || _.get(state, `${props.type?.stateLocation()}.editObject`),
    authUser: _.get(state.auth, 'user', {}),
    error: _.get(state, `${props.type?.stateLocation()}.errors`, []).find((e) => e === 'primaryContactId'),
    operators: _.get(state, 'operators.list', []).map((operator) => ({
      id: operator.id, label: operator.name, email: operator.email, title: operator.title, role: operator.role,
    })),
    organizations: state.organizations && state.organizations.list,
    recentlyAssignedOperators: _.concat(_.get(state.auth, 'user', {}), _.get(state, `profile.properties.recentlyAssignedOperators.${tableId}`, []))
      .map((operator) => ({ id: operator.id || operator._id, label: operator.name || `${operator.firstName} ${operator.lastName}`, email: operator.email })),
  };
};

export { PrimaryContactInput };

export default connect(mapStateToProps, { handleFormInputChange, lazyLoad, userType })(PrimaryContactInput);
