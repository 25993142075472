import React from 'react';

import GroupIcon from '@images/icons/group2.svg';
import MoneyCashIcon from '@images/icons/moneyCash.svg';
import ProfileIcon from '@images/icons/profile.svg';
import UserIcon from '@images/icons/user2.svg';

import type { QuickFilterMenuItem } from './types';

export const mockUsers = [
  {
    id: '1',
    name: 'Marley Admin',
    archived: false,
    activated: false,
    verified: false,
    blocked: false,
    locked: false,
    inChatBotMode: false,
    isAutoAssign: false,
    email: 'xx@himarley.com',
    phoneNumber: '',
    displayPhoneNumber: '',
    organization: {
      id: '625767b8-8fb6-432b-aaf2-217ab1caa4c7',
      name: 'Hi Marley',
      createdAt: '2023-08-24T13:45:32.984Z',
      branding: ['Hi Marley'],
    },
    company: 'Hi Marley',
    role: 'operator',
    roles: ['ADMIN'],
    groups: [],
    inTranslationMode: false,
    languagePreference: 'en',
    relationships: [],
  },
  {
    id: '2',
    name: 'Test1 Testing1',
    lastActivity: '8 min. ago',
    archived: false,
    activated: false,
    verified: false,
    blocked: false,
    locked: false,
    inChatBotMode: false,
    isAutoAssign: false,
    email: 'xx.dd@himarley.com',
    phoneNumber: '',
    displayPhoneNumber: '',
    organization: {
      id: '625767b8-8fb6-432b-aaf2-217ab1caa4c7',
      name: 'Hi Marley',
      createdAt: '2023-08-24T13:45:32.984Z',
      branding: ['Hi Marley'],
    },
    company: 'Hi Marley',
    role: 'operator',
    roles: ['ADMIN', 'SYSTEM ADMIN'],
    groups: [],
    inTranslationMode: false,
    languagePreference: 'en',
    relationships: [],
  },
  {
    id: '3',
    name: 'Test2 Testing2',
    archived: false,
    activated: false,
    verified: false,
    blocked: false,
    locked: false,
    inChatBotMode: false,
    isAutoAssign: false,
    email: 'testuser@himarley.com',
    phoneNumber: '',
    displayPhoneNumber: '',
    organization: {
      id: '625767b8-8fb6-432b-aaf2-217ab1caa4c7',
      name: 'Hi Marley',
      createdAt: '2023-08-24T13:45:32.984Z',
      branding: ['Hi Marley'],
    },
    company: 'Hi Marley',
    role: 'operator',
    roles: ['ADMIN'],
    groups: [],
    inTranslationMode: false,
    languagePreference: 'en',
    relationships: [],
  },
];

export const QUICK_FILTER_MENU_IDS = {
  allChats: 'allChats',
  myChats: 'myChats',
  unassigned: 'unassigned',
  groups: 'groups',
  users: 'users',
  secondaryOperatorIds: 'secondaryOperatorIds',
  myCases: 'myCases',
};

export const QUICK_FILTER_MENU_LABELS = {
  secondaryOpsLabel: 'My Secondary Assignments',
  primaryOpLabel: 'My Cases',
};

const QUICK_FILTER_MENU: QuickFilterMenuItem[] = [
  {
    id: QUICK_FILTER_MENU_IDS.allChats,
    display: false,
    label: 'All Chats',
    additionalLabels: (
      <span
        style={{
          alignItems: 'center',
          color: '#5E7388',
          display: 'flex',
          fontWeight: 'normal',
          justifyContent: 'center',
          fontSize: '0.75rem',
        }}
      >
        &nbsp; (All Assignees)&nbsp;
      </span>
    ),
    avatar: <MoneyCashIcon />,
    clearSelectedSubItems: () => {},
  },
  {
    id: QUICK_FILTER_MENU_IDS.myChats,
    display: true,
    label: QUICK_FILTER_MENU_LABELS.primaryOpLabel,
    avatar: <ProfileIcon />,
    subItems: [
      {
        id: QUICK_FILTER_MENU_IDS.myChats,
        additionalLabels: (
          <span
            style={{
              alignItems: 'center',
              color: '#495A6A',
              display: 'flex',
              fontWeight: 'normal',
              justifyContent: 'center',
              fontSize: '0.75rem',
            }}
          >
            &nbsp; (Assigned to me)&nbsp;
          </span>
        ),
      },
      {
        id: QUICK_FILTER_MENU_IDS.secondaryOperatorIds,
        label: QUICK_FILTER_MENU_LABELS.secondaryOpsLabel,
      },
    ],
    selectedSubItemsMap: new Map(),
    onSelectSubItem: () => {},
    clearSelectedSubItems: () => {},
  },
  {
    id: QUICK_FILTER_MENU_IDS.unassigned,
    display: true,
    label: 'Unassigned',
    avatar: <ProfileIcon />,
    clearSelectedSubItems: () => {},
  },
  {
    id: QUICK_FILTER_MENU_IDS.groups,
    display: true,
    avatar: <GroupIcon />,
    clearSelectedSubItems: () => {},
    label: 'Choose Groups',
    onSearch: () => {},
    onSelectSubItem: () => {},
    searchPlaceholder: 'Search groups...',
    selectedSubItemsMap: new Map(),
    subItems: [],
  },
  {
    id: QUICK_FILTER_MENU_IDS.users,
    display: true,
    avatar: <UserIcon />,
    clearSelectedSubItems: () => {},
    label: 'Choose Users',
    onSearch: () => {},
    onSelectSubItem: () => {},
    searchPlaceholder: 'Search users...',
    selectedSubItemsMap: new Map(),
    subItems: [],
  },
];
export const QUICK_FILTER_MENU_ITEMS = QUICK_FILTER_MENU.filter(
  (q) => q.display === true,
);

export const ALL_CHATS_INDEX = QUICK_FILTER_MENU_ITEMS.findIndex(
  (q) => q.id === QUICK_FILTER_MENU_IDS.allChats,
);
export const MY_CHATS_INDEX = QUICK_FILTER_MENU_ITEMS.findIndex(
  (q) => q.id === QUICK_FILTER_MENU_IDS.myChats,
);
export const GROUPS_INDEX = QUICK_FILTER_MENU_ITEMS.findIndex(
  (q) => q.id === QUICK_FILTER_MENU_IDS.groups,
);
export const USERS_INDEX = QUICK_FILTER_MENU_ITEMS.findIndex(
  (q) => q.id === QUICK_FILTER_MENU_IDS.users,
);
export const UNASSIGNED_INDEX = QUICK_FILTER_MENU_ITEMS.findIndex(
  (q) => q.id === QUICK_FILTER_MENU_IDS.unassigned,
);

export const LOAD_LIMIT = 20;

export const DEFAULT_SELECTED = new Map();
DEFAULT_SELECTED.set(QUICK_FILTER_MENU_IDS.myChats, true);
DEFAULT_SELECTED.set(
  QUICK_FILTER_MENU_IDS.secondaryOperatorIds,
  QUICK_FILTER_MENU_LABELS.secondaryOpsLabel,
);
