import React from 'react'
import { Modal , Checkbox } from 'react-bootstrap'

import _ from 'lodash'
import { connect } from 'react-redux'

import withState from '../../HigherOrderComponents/withState'

import Button from '../../Form/Button/Button'
import CloseIcon from '../../../../images/icons/close.svg'
import { sendMassNotification } from '../../../actions/massNotifications'

import './MassNotificationVerificationModal.less'

class MassNotificationVerificationModal extends React.Component {
  state = {
    isConfirmed: false,
    numErrors: 4,
  }

  // componentWillReceiveProps = (nextProps) => {
  //   if (this.props.wasSuccessful !== nextProps.wasSuccessful && nextProps.wasSuccessful) {
  //     this.props.toggleShow()
  //   }
  // }

  toggleConfirmed = () => {
    this.setState({ isConfirmed: !this.state.isConfirmed })
  }


  handleHide = (toggleShow)=>{
    this.setState({ isConfirmed: false })
    toggleShow()

  } 

  render() {
    const { isConfirmed } = this.state
    const { show, toggleShow, sendMassNotification, activeMassNotification, isLoading } = this.props
    const realErrors = activeMassNotification && activeMassNotification.NumberOfInvalidEntries
    return (
      <Modal
        onHide={()=>this.handleHide(toggleShow)}
        keyboard
        className="mass-notification-modal"
        data-jest="mass-notification-modal"
        show={show}
      >
        { realErrors === 0 && 
          (
            <div
              role="link"
              tabIndex="0"
              onClick={()=>this.handleHide(toggleShow)}
              className="mass-notification-minimize-button"
            ><CloseIcon /></div>
          )
        }
        <Modal.Body>
          <div className="mass-notification-main-title">
            {realErrors >0 ? 
            `Some notifications won't be delivered` :
            "Have you reviewed all your information? "
          }
          </div>
          {/* <div className="mass-notification-secondary-title">All deliveries are permanent and can't be recalled.</div> */}
          {realErrors >0 && (
            <div className="mass-notification-warning first">We found errors that will result in {realErrors} undelivered notifications.
             In the meantime, you can send out the notification to the remaining recipients.</div>
          )}
      
          <div className={`mass-notification-warning ${realErrors >0 ? '' : 'alone'}`}>
            {realErrors >0 ? 'Get the error report from the actions dropdown for more information.' : 'All deliveries are permanent and cannot be recalled.'}
          </div>
       
         


          <div className="buttonContainer">
          { 
            realErrors >0 &&
            <Button type="outline" onClick={()=>this.handleHide(toggleShow)}>Do Not Send</Button>
          }
            <Button onClick={() =>{ 
              sendMassNotification(_.get(activeMassNotification, 'id', ''))
              this.handleHide(toggleShow)
            } }
            type="primary" disabled={!isConfirmed || isLoading}>
              {isLoading ? 'Sending' : 'Send'} Notification
            </Button>
          </div>
         <div className='mass-note-verification-section'>
          <Checkbox
            checked={isConfirmed}
            onChange={this.toggleConfirmed}
          >
          </Checkbox>
          { realErrors > 0 ? `I understand that ${realErrors} people won’t receive this notification` : 'Yes, I have reviewed the information'}
         </div>
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({})

const MassNotificiationVerificationModalWithState = withState(MassNotificationVerificationModal, {
  requestLocation: 'sentMassNotification'
})

export default connect(mapStateToProps, { sendMassNotification })(MassNotificiationVerificationModalWithState)
