import { defineStyleConfig } from '@chakra-ui/react';

export const formLabelTheme = defineStyleConfig({
  baseStyle: {
    color: 'gray.500',
    fontSize: '12px',
    fontWeight: 'normal',
  },
});

export default formLabelTheme;
