import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import _has from 'lodash/has';
import GeneralAutoReplyModal from '../GeneralAutoReplyModal/GeneralAutoReplyModal';
import ReplyMessage from '../../SharedComponents/ReplyRowMessage/ReplyMessage';
import ReplyActions from '../../SharedComponents/ReplyRowActions/ReplyActions';
import Modal from '../../../../modal/Modal/Modal';
import withModal from '../../../../HigherOrderComponents/withModal';
import './GeneralAutoReplyRow.less';
import { buildSendCadence } from '../../Utils';
import { formatTimeToAMPM, formatDateFromISO, formatTimeFromISO} from '../../../../../helpers/datetime';
import _ from "lodash";

const GeneralAutoReplyRow = ({ userId, reply, toggleModal }) => {
  const [sendCadence, setSendCadence] = useState('');

  // The empty array as the second argument tells react to load this useEffect once after the initial rendering
  useEffect(() => {
    if (_has(reply, 'sendCriteria.days.startDate')
    && _has(reply, 'sendCriteria.days.endDate')
    && reply.sendCriteria.timing === 'SPECIFIED') {
      const startDate = _get(reply, 'sendCriteria.days.startDate')
      const endDate = _get(reply, 'sendCriteria.days.endDate')
      setSendCadence(`Sends ${formatDateFromISO(startDate)} ${formatTimeToAMPM(formatTimeFromISO(startDate))} to ${formatDateFromISO(endDate)} ${formatTimeToAMPM(formatTimeFromISO(endDate))}`)
    } else if (_has(reply, 'sendCriteria.days')
      && reply.sendCriteria.timing === 'SPECIFIED') {
      const getSendCadence = buildSendCadence(reply.sendCriteria.days);
      setSendCadence(getSendCadence);
    } else {
      setSendCadence('Sends all the time');
    }
  }, [reply]);
  return (
    <div className="auto-reply-row">
      <ReplyActions openEditModal={toggleModal} userId={userId} reply={reply} />
      <ReplyMessage message={_.get(reply, 'message.during.value', '')} />
      <div className="auto-reply-send-cadence">
        <p>{sendCadence}</p>
      </div>
    </div>
  );
};

const GeneralAutoReplyWithModal = withModal({
  Modal: (
    <Modal
      className="create-auto-reply-modal"
      data-test="create-auto-reply-modal"
      title="Edit Away Message"
      backdrop="static"
    >
      <GeneralAutoReplyModal />
    </Modal>
  ),
})(GeneralAutoReplyRow);

export default GeneralAutoReplyWithModal;
