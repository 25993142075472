import filter from 'lodash/filter';
import {
  LOAD_COMPANIES,
  DEAUTH_USER,
  GET_ORGANIZATIONS_AUTOREPLIES,
  PUT_ORGANIZATIONS_AUTOREPLIES,
  POST_ORGANIZATIONS_AUTOREPLIES,
  GET_FULL_ORGANIZATION_DATA,
  UPDATE_ORGANIZATION_SETTING,
  UPDATE_LINE_OF_BUSINESS_ACTION,
  SET_IS_UPDATING_ORG_SETTING,
  IS_GETTING_FULL_ORGANIZATION_DATA,
  UPDATE_ORGANIZATION_BRANDING,
  SET_IS_UPDATING_BRANDING
} from '../constants/actions';
import { buildIdList } from './helpers'

export const initialState = {
  list: [],
  listById: {},
  orgAutoReply: {},
  organizationSettings: {},
  isUpdatingOrgSetting: null,
  isGettingFullOrganizationData: false,
  isUpdatingBranding: null,
};

const organization = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COMPANIES: {
      const list = action.payload;
      return { ...state, list, ...buildIdList(list) };
    }
    case DEAUTH_USER: {
      return { ...initialState };
    }
    case GET_ORGANIZATIONS_AUTOREPLIES: {
      const list = action.payload;
      const generalAutoRepliesList = filter(list, {
        type: 'GENERAL-AUTO-REPLY',
      });
      return {
        ...state,
        orgAutoReply: generalAutoRepliesList[0],
      };
    }
    case POST_ORGANIZATIONS_AUTOREPLIES:
    case PUT_ORGANIZATIONS_AUTOREPLIES: {
      return {
        ...state,
        orgAutoReply: action.payload,
      };
    }
    case UPDATE_LINE_OF_BUSINESS_ACTION: {
      const updatedLineOfBusiness = state.linesOfBusiness.map((lineOfBusiness) => {
        if (lineOfBusiness._id === action.payload._id) {
          return action.payload;
        }
        return lineOfBusiness;
      }).filter((lob) => !lob.archived);

      return {
        ...state,
        linesOfBusiness: updatedLineOfBusiness,
      };
    }

    case UPDATE_ORGANIZATION_SETTING:
    case GET_FULL_ORGANIZATION_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_IS_UPDATING_ORG_SETTING: {
      return {
        ...state,
        isUpdatingOrgSetting: action.payload,
      };
    }
    case SET_IS_UPDATING_BRANDING: {
      return {
        ...state,
        isUpdatingBranding: action.payload,
      };
    }
    case IS_GETTING_FULL_ORGANIZATION_DATA: {
      return {
        ...state,
        isGettingFullOrganizationData: action.payload,
      };
    }
    case UPDATE_ORGANIZATION_BRANDING: {
      const { branding, linesOfBusiness } = action.payload;
      return { ...state, branding, linesOfBusiness };
    }
    default:
      return { ...state };
  }
};

export default organization;
