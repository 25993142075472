import React, { useRef } from 'react';

import {
  Overlay, OverlayTrigger, Popover, Tooltip,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import InfoIcon from '@images/icons/info.svg';

import './TooltipInfo.less';

const TooltipInfo = ({
  message,
  isForcedDisplay,
  placement,
  icon,
  iconClass,
}) => {
  const target = useRef(null);

  return isForcedDisplay ? (
    <>
      <Overlay
        target={target.current}
        show
        placement={placement}
      >
        <Popover
          id="popover-contained"
          className="auto-popover"
        >
          {message}
        </Popover>
      </Overlay>
      <div className={iconClass} ref={target}>{icon}</div>
    </>
  ) : (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id="popover-contained">{message}</Tooltip>}
    >
      <div data-testid="info-icon" className={iconClass}>{icon}</div>
    </OverlayTrigger>
  );
};

TooltipInfo.propTypes = {
  message: PropTypes.string.isRequired,
  isForcedDisplay: PropTypes.bool,
  placement: PropTypes.string,
  icon: PropTypes.node,
  iconClass: PropTypes.string,
};

TooltipInfo.defaultProps = {
  isForcedDisplay: false,
  placement: 'top',
  icon: <InfoIcon className="infoSvg" />,
  iconClass: null,
};

export default TooltipInfo;
