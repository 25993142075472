import React, { useMemo } from 'react';

import { REDACTED_IMAGE } from '@app/constants/general';
import { formatDate } from '@app/helpers/datetimeWithoutMoment';
import AlertIcon from '@images/icons/alert.svg';

import styles from './RedactionViewLog.module.less';
import type { RedactionViewedEvent, RedactionData } from '../../types';

const DISPLAY_REDACTION_VIEW_TYPES: { [key: string]: string } = {
  SSN: 'Social Security Number',
  'BANK-ACCOUNT-NUMBER': 'Bank Account Number',
  'CREDIT-CARD-NUMBER': 'Credit Card Number',
  'DRIVER-LICENSE-NUMBER': 'Driver License Number',
};

interface RedactionViewLogProps {
  redactionViewedEvents: RedactionViewedEvent[];
  isImage?: boolean;
  redactionData?: RedactionData[];

}

const RedactionViewLog: React.FC<RedactionViewLogProps> = ({
  redactionViewedEvents,
  redactionData: redactedDataProp = [],
  isImage = false,
}) => {
  const redactedLabel = useMemo(() => {
    const redactionData = redactedDataProp?.filter(
      (value, index, self) => index === self.findIndex((t) => t.Type === value.Type),
    );
    if (redactionData?.length === 1) {
      return (
        <div className={styles.redactedLabel}>
          <strong>
            {DISPLAY_REDACTION_VIEW_TYPES[redactionData[0]?.Type]}
          </strong>
          &nbsp;has been redacted from this message
        </div>
      );
    }
    return (
      <div className={styles.redactedLabel} data-testid="textRedactionTitle">
        {redactionData?.map((data, i) => {
          const { Type } = data;
          const isLast = i === redactionData.length - 1;
          const shouldAddComma = redactionData?.length > 2 && !isLast;

          return (
            <span>
              {isLast ? ' and ' : ''}
              <strong>{DISPLAY_REDACTION_VIEW_TYPES[Type]}</strong>
              {shouldAddComma && ', '}
            </span>
          );
        })}
        <span> have been redacted from this message</span>
      </div>
    );
  }, [redactedDataProp]);

  return redactionViewedEvents.length || redactedDataProp.length ? (
    <section className={styles.redactedLog} data-testid={`${isImage ? 'redactedImageLog' : 'redactedTextLog'}`}>
      <AlertIcon />
      <ul className={styles.ulWrap}>
        {isImage ? (
          <strong className={styles.redactedLabel}>Image Redacted</strong>
        ) : (
          redactedLabel
        )}
        {!isImage && redactedDataProp?.length > 0 && (
          <li className={styles.text}>
            Redacted automatically
            {' '}
            {redactedDataProp[0]?.createdAt && `on ${formatDate(redactedDataProp[0]?.createdAt)?.toUpperCase()}`}
          </li>
        )}
        {redactionViewedEvents?.map((redactionViewEvent) => {
          const {
            eventTime,
            operatorName,
            Type,
          } = redactionViewEvent || {};

          return (
            <li key={eventTime} className={styles.text}>
              <span>
                {Type === REDACTED_IMAGE ? 'Redacted' : 'Viewed'}
                {' '}
                by&nbsp;
                {operatorName}
                {' '}
                on&nbsp;
                {formatDate(eventTime)?.toUpperCase()}
              </span>
            </li>
          );
        })}
      </ul>
    </section>
  ) : null;
};

export default RedactionViewLog;
