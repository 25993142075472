/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
// eslint-disable-next-line import/no-unresolved
import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
// https://github.com/okta/okta-signin-widget - explanation for importing above css file
import PropTypes from 'prop-types';

const OktaSignInWidget = ({ config, onSuccess, onError }) => {
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) { return false; }
    const widget = new OktaSignIn(config);

    widget.showSignInToGetTokens({
      el: widgetRef.current,
    }).then(onSuccess).catch(onError);

    return () => widget.remove();
  }, [config, onSuccess, onError]);

  return (
    <div id="okta-login-container" data-testId="okta-login-container">
      <div ref={widgetRef} />
    </div>
  );
};

OktaSignInWidget.propTypes = {
  config: PropTypes.instanceOf(Object).isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

OktaSignInWidget.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

export default OktaSignInWidget;
