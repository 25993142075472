import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeUserFromChat } from '@app/actions/chat';

import RemoveParticipant from './remove-participant';

interface RemoveParticipantContainerProps {
  participant: {
    id: string,
    imageUrl: string,
    name: string,
    role: string,
    relationship: string,
  },
  onClose: () => void,
}

const RemoveParticipantContainer: React.FC<RemoveParticipantContainerProps> = (
  { participant, onClose },
) => {
  const chatId = useSelector((state) => state?.jobs?.activeChat?.id);
  const removeChatParticipantStatus = useSelector(
    (state) => state?.participants?.removeChatParticipantStatus,
  );

  const dispatch = useDispatch();
  const { id } = participant;

  const handleClickRemoveParticipant = () => {
    dispatch(removeUserFromChat({ chatId, userId: id }));
  };

  useEffect(() => {
    if (removeChatParticipantStatus === 'SUCCESS') {
      onClose();
    }
  }, [onClose, removeChatParticipantStatus]);

  return (
    <RemoveParticipant
      participant={participant}
      onClickRemoveParticipant={handleClickRemoveParticipant}
      onClose={onClose}
      removeChatParticipantStatus={removeChatParticipantStatus}
    />
  );
};

export default RemoveParticipantContainer;
