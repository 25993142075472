import _ from 'lodash';
import isEmail from 'validator/lib/isEmail';
import toDate from 'validator/lib/toDate';

// TODO - Import errorMessage from lang file, group required messages and replace with label
const errorMessages = {
  required: 'You must enter a %%replace%%',
  Number: 'Please only submit numeric values for %%replace%%',
  calendarDay: 'You must enter  a valid Date',
  Email: 'You must enter a valid email',
  commonError: 'Something went wrong.',
}

// eslint-disable-next-line import/prefer-default-export
export const Validate = {
  formatToNumber(deductible) {
    return (
      deductible && deductible.toString().replace(',', '').replace('$', '')
    );
  },

  validateNumber(number) {
    const numberRegex = /^(([1-9]\d{0,2}(,\d{3})*)|\d+)(\.\d{2})?$/;
    return !!((number && numberRegex.test(number)) || number === '');
  },

  validateEmail(email) {
    // email is not required on case forms. So return true if null
    return email ? isEmail(email) : true
  },

  validateCalendarDay(date) {
    return !!toDate(date)
  },

  getErrorMessage(type, field) {
    return !_.isEmpty(errorMessages[type]) ? errorMessages[type].replace('%%replace%%', field) : errorMessages.commonError
  },

  getValue(formObject, fieldId) { 
    return _.get(formObject, fieldId) || _.has(formObject, 'metaData') && _.get(formObject.metaData, fieldId)
  },
  // would be nice to have data about the field EX: isRequired in the form data. 🤔
  validate({ formObject, formMeta, fieldId }) {
    const typeValidator = {
      Number: this.validateNumber,
      Email: this.validateEmail,
      calendarDay: this.validateCalendarDay,
    }

    const validation = {
      isValid: true,
      errorMessage: '',
      errorType: '',
    }
    const validationType = _.find(formMeta, ['id', fieldId]) || { dataType: 'Text', required: false }
    const value = this.getValue(formObject, fieldId)
    const validationMethod = typeValidator[validationType.dataType]
    const requiredError = validationType.required && (_.isEmpty(value))
    if (requiredError || (value && validationMethod && !validationMethod(value))) {
      validation.isValid = false;
      if (requiredError) {
        validation.errorMessage = this.getErrorMessage('required', validationType.name)
        validation.errorType = 'required'
      } else {
        validation.errorMessage = this.getErrorMessage(validationType.dataType, validationType.name)
        validation.errorType = 'dataType'
      }

      return validation;
    }

    return validation;
  },

  validateRequired({ formObject, formMeta }) {
    const validation = { submit: true }
    !_.isEmpty(formMeta) && formMeta.forEach((Obj) => {
      const item = this.getValue(formObject, Obj.id)
      if (Obj.required && (_.isNil(item) || !item)) {
        validation.submit = false;
      }
    })

    return validation;
  },
}
