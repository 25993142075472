import React from 'react'
import { Modal } from 'react-bootstrap'

import _ from 'lodash'
import { connect } from 'react-redux'

import withState from '../../HigherOrderComponents/withState'

import Button from '../../Form/Button/Button'
import CloseIcon from '../../../../images/icons/close.svg'
import { sendMassNotification } from '../../../actions/massNotifications'

import './MassNotificationDeleteVerifyModal.less'

class MassNotificationDeleteVerifyModal extends React.Component {


  handleHide = (toggleShow)=>{
    toggleShow()
  } 

  render() {
    const { show, toggleShow, deleteNotification, activeMassNotification} = this.props
    return (
      <Modal
        onHide={()=>this.handleHide(toggleShow)}
        keyboard
        className="mass-notification-modal mass-notification-delete-modal"
        data-jest="mass-notification-modal"
        show={show}
      >
        
        
        <div
            role="link"
            tabIndex="0"
            onClick={()=>this.handleHide(toggleShow)}
            className="mass-notification-minimize-button"
        ><CloseIcon /></div>
        
        <Modal.Body>
          <div className="mass-notification-main-title">
            { "Are you sure you want to delete this mass notification?"}
          </div>
       
          <div className="buttonContainer">
            <Button onClick={() =>{ 
              deleteNotification(_.get(activeMassNotification,'id'))
              this.handleHide(toggleShow)
            } }
            type="primary">
              Yes
            </Button>
          </div>
    
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({})

const MassNotificiationVerificationModalWithState = withState(MassNotificationDeleteVerifyModal, {
  requestLocation: 'sentMassNotification'
})

export default connect(mapStateToProps, { sendMassNotification })(MassNotificiationVerificationModalWithState)
