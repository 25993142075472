import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

const withState = (WrappedComponent, config) => {
  class ComponentWithStatus extends React.Component {
    render() {
      return (
        <WrappedComponent isLoading={this.props.isLoading} wasSuccessful={this.props.wasSuccessful} {...this.props}/>
      )
    }
  }

  const mapStateToProps = (state) => {
    const stateStatus = _.get(state, `ui.requests.${config.requestLocation}`, '')
    const { requesting, success } = stateStatus
    return { isLoading: requesting, wasSuccessful: success }
  }

  return connect(mapStateToProps, {})(ComponentWithStatus)
}

export default withState
