import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    future: 'now',
    past: '%s ago',
    s: 'just now',
    ss: '%d sec.',
    m: 'a min.',
    mm: '%d min.',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

const DATE_FORMATS = [
  {
    id: 'standard',
    format: 'MMM D YYYY, h:mma',
  },
  {
    id: 'just-date',
    format: 'MM/DD/YYYY',
  },
  {
    id: 'just-time',
    format: 'h:mm a',
  },
  {
    id: 'just-time-with-seconds',
    format: 'h:mm:ss a',
  },
  {
    id: 'date-time',
    format: 'MMM D h:mm a',
  },
  {
    id: 'date-time-with-seconds',
    format: 'MMM D h:mm:ss a',
  },
];

export const formatDate = (
  timestamp,
  format = 'standard',
  compareDate = new Date(),
) => {
  if (format === 'human') {
    const diff = moment(new Date(timestamp)).diff(moment(compareDate));
    const duration = moment.duration(diff);
    const asHours = Math.floor(Math.abs(duration.asHours()));
    const asMinutes = Math.floor(Math.abs(duration.asMinutes()));
    if (asHours > 0 && asMinutes < 1) {
      const hours = Math.floor(Math.abs(duration.hours()));
      const minutes = Math.floor(Math.abs(duration.minutes()));
      return `${hours === 1 ? 'an' : hours} hour${hours > 1 ? 's' : ''}
        and ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    const formatted = duration.humanize(true);
    if (formatted.includes('now')) {
      return 'just now';
    }
    return formatted;
  }
  const momentFormat = DATE_FORMATS.find((f) => f.id === format);
  const formattedItem = moment(new Date(timestamp)).format(
    momentFormat && momentFormat.format,
  );
  return format === 'just-date' ? formattedItem : formattedItem.toUpperCase();
};

export const formatISODateSlashandAMPM = (lastRun) => {
  let formattedLastRun;
  if (lastRun) {
    const lastRunAsDate = new Date(lastRun);
    formattedLastRun = lastRunAsDate.toLocaleDateString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  }
  return formattedLastRun;
};

export const forceTime = (t) => {
  let newTime = [];
  const timeMeridian = t.split(' ');
  const [time, meridian] = timeMeridian;
  const hourMin = time.split(':');
  const [hour, min] = hourMin;
  const hourInt = parseInt(hour, 10);
  const minInt = parseInt(min, 10);

  // handle hours
  if (hourInt <= 12 && hourInt > 0) {
    newTime.push(`${hour}:`);
  } else if (hourInt > 0) {
    // hour entered is 51, 35
    const m = hour.charAt(1);
    newTime.push(`${hour.charAt(0)}:${parseInt(m, 10) < 6 ? m : ''}`);
  }
  // handle minutes
  if (minInt >= 0 && parseInt(min.slice(0, 1), 10) < 6) {
    newTime.push(min.slice(0, 2));
    if (min && min.length === 2) newTime.push(' ');
  }
  // move the colon over 1 char if a user types a 4th digit unless
  // current hour isn't 1 or resulting hour would be greater than 12
  // cases:
  // '1:23 0' --> 0 is the meridian and is concatenated to the minute
  if (
    !!min
    && (!Number.isNaN(parseInt(meridian, 10)) || min.length === 3)
    && parseInt(min.charAt(0), 10) < 3
    && parseInt(min.charAt(1), 10) < 6
    && hourInt === 1
  ) {
    const newHour = `${hour}${min.charAt(0)}`;
    const newMinute = `${min.slice(1)}${meridian}`;
    newTime = [newHour, ':', newMinute];
  }

  if (!!meridian && (meridian.startsWith('a') || meridian.startsWith('p'))) {
    newTime.push(
      `${meridian.length === 2 ? `${meridian}` : `${meridian.charAt(0)}m`}`,
    );
  }

  return newTime.join('');
};

const filterTimeOptionsHelper = (searchtext, timeOptions) => timeOptions.filter((t) => (searchtext.length > 0 ? t.label.startsWith(searchtext) : true));

export const filterTimeOptions = (searchtext = '', timeOptions) => {
  const filteredOptionsList = filterTimeOptionsHelper(searchtext, timeOptions);
  return filteredOptionsList.length
    ? filteredOptionsList
    : filterTimeOptionsHelper(searchtext, options({ minuteStep: 1 }));
};

const amPM = (hour) => (hour >= 12 ? 'pm' : 'am');
const formattedHour = (hour) => (hour === 0 || hour > 12 ? Math.abs(hour - 12) : hour);

export const formatTimeToAMPM = (timeISOString) => {
  const [hour, minute] = timeISOString.split(':');
  const hourInt = parseInt(hour, 10);
  return `${formattedHour(hourInt)}:${minute} ${amPM(hourInt)}`;
};

export const formatTimeFromISO = (timeISOString) => {
  if (timeISOString === '') return timeISOString;
  const timeStr = timeISOString.split('T')[1];
  const timeComponents = timeStr.split(':');
  const [hour, minute] = timeComponents.slice(0, 2);
  return `${hour}:${minute}`;
};

export const formatDateFromISO = (ISOString) => {
  if (ISOString === '') return ISOString;
  const dateStr = ISOString.split('T')[0];
  const [year, month, day] = dateStr.split('-');
  return `${month}/${day}/${year}`;
};

export const options = ({ minuteStep, minTime, maxTime }) => {
  const minArr = (minTime || '00:00').split(':').map((o) => parseInt(o, 10));
  const [minHour, minMinute] = minArr;

  const maxArr = (maxTime || '23:59').split(':').map((o) => parseInt(o, 10));
  const [maxHour, maxMinute] = maxArr;

  const times = [];
  for (let hour = minHour; hour <= maxHour; hour++) {
    // if it's 9:30 for min time, it should be 30
    const minuteStart = hour === minHour ? minMinute : 0;
    // if it's 9:30 for max time, it should be 30 otherwise 59
    const minuteEnd = hour === maxHour ? maxMinute : 59;

    for (let minute = minuteStart; minute <= minuteEnd; minute += minuteStep) {
      const formattedMinute = minute < 10 ? `0${minute}` : minute;
      const formattedTime = `${formattedHour(hour)}:${formattedMinute} ${amPM(
        hour,
      )}`;
      times.push({
        id: `${hour < 10 ? '0' : ''}${hour}:${formattedMinute}`,
        label: formattedTime,
      });
    }
  }
  return times;
};

export const findTimeOption = (t) => options({ minuteStep: 1 }).find((o) => o.id === t || o.label === t);

export const DatetimeLocalRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/; // eg. '2021-05-07T12:30'

export const convertTimeToMilliseconds = (timeParam) => {
  const time = findTimeOption(timeParam)?.id;

  if (!time) {
    return 0;
  }
  const [hour, minute] = time.split(':');
  return ((parseInt(hour, 10) || 0) * 3600000) + ((parseInt(minute, 10) || 0) * 60000);
};

export const daysDifferenceFromDate = (dateString) => {
  const inputDate = new Date(dateString);
  const today = new Date();
  const timeDifference = today - inputDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference <= 0) {
    return 'Today';
  }
  if (daysDifference === 1) {
    return '1 Day';
  }
  return `${daysDifference} Days`;
};
