/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { FileDrop } from 'react-file-drop'
import FolderIcon from '../../icons/FolderIcon';
import SnackFail from '../../../../images/icons/snackfail.svg';

import '../MassNotificationModal/MassNotificationModal.less';

const ImportCSVModal = ({
  downloadCSVTemplate,
  handleFileDrop,
  currentFilename,
  handleClickSelectFile,
  fileInputRef,
  handleFileInputChange,
  error,
}) => {
  // allow for selecting of same file
  const onInputClick = (event) => {
    event.target.value = '';
  }

  return (
    <span className="mass-notification-view-modal">
      <div className="subText">
        Download a
        {' '}
        <span
          role="link"
          tabIndex="0"
          className="customLink"
          onClick={downloadCSVTemplate}
          onKeyDown={downloadCSVTemplate}
        >
          sample CSV template
        </span>
        {' '}
        to see an example of the format required.
      </div>
      <div
        className={`${
          error
            ? 'errorContainer fileUploadContainer flexCenter'
            : 'fileUploadContainer flexCenter'
        }`}
      >
        <div className="csvContainer flexCenter">
          <FileDrop onDrop={handleFileDrop} />
          <div className="csvUploadIconAndFileContainer">
            <div className="folderIcon flexCenter">
              <FolderIcon />
            </div>
            <div
              className={`csvFilename ${currentFilename ? '' : 'beforeDrop'}`}
            >
              {currentFilename || 'Drag and Drop'}
            </div>
          </div>
          <div className="fileStatusMessage green" />
        </div>
        <div className="uploadBtnContainer flexCenter">
          <div className="separator" />
          <div
            role="link"
            tabIndex="0"
            className="uploadFileBtn flexCenter"
            onClick={handleClickSelectFile}
            onKeyDown={handleClickSelectFile}
          >
            Select File
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        className="finalFileInput"
        onChange={handleFileInputChange}
        onClick={onInputClick}
      />
      {error && (
        <div className="csvErrorText">
          <SnackFail />
          <span>{error}</span>
        </div>
      )}
    </span>
  );
};

ImportCSVModal.propTypes = {
  downloadCSVTemplate: PropTypes.func.isRequired,
  handleFileDrop: PropTypes.func.isRequired,
  currentFilename: PropTypes.string.isRequired,
  handleClickSelectFile: PropTypes.func.isRequired,
  fileInputRef: PropTypes.func.isRequired,
  handleFileInputChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

ImportCSVModal.defaultProps = {
  error: '',
};

export default ImportCSVModal;
