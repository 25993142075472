/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './SubMessage.less';
import PropTypes from 'prop-types';

const SubMessage = ({ isOriginal, message }) => (
  <div className="sub-message-container">
    <div className="sub-message-header" data-test="translated-message-header">
      {isOriginal ? 'Original' : 'Translated'}
      {' '}
      message
    </div>
    <div className="sub-message" data-test="translated-message">
      {message}
    </div>
  </div>
);

SubMessage.propTypes = {
  isOriginal: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default SubMessage;
