/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import AssignDropdown from '../AssignDropdown/AssignDropdown';
import UserForm from '../Chats/RightBar/AddUser/AddUserForm/UserForm';
import PolicyForm from '../Policies/EnterPolicy/PolicyForm';
import ClaimsForm from '../EnterClaims/ClaimsForm';
import GeneralPurposeCaseForm from './EnterCaseForm/GeneralPurposeCaseForm';

/**
 * The import of LESS files below are in this unorthodox format as a replacement of
 * and unused component that was being imported here. The component code was deleted,
 * but they styling for the component is preserved here (in the same import order),
 * as removal of these caused the styling in the Create Case and Create Operator
 * Forms to change. This needs further clean up so there's only one styling import
 * here, but there is a high chance of affecting all forms by incorrectly removing
 * one of these LESS files.
 */
import '../FormInput/FormInput.less';
import '../AddForm/EnterForm.less';
import '../Chats/RightBar/AddUser/AddUser.less';
import './ExtraStyling.less';

import {
  policyType, claimType, userType, caseType,
} from '../../models/marleyTypes';
import { cleanPhone } from '../../helpers/format';
import { openCreateForm, closeForm } from '../../actions/ui';
import { clearAddFormErrors } from '../../actions/common';
import FullpageModal from '../modal/FullpageModal/FullpageModal';

import './EnterCase.less';
import CaseMatchAlert from '../elements/CaseMatchAlert/case-match-alert';

const CLAIM_ID = 'CLAIM_ID';
const POLICY_ID = 'POLICY_ID';
const CASE_ID = 'CASE_ID';
const EMPTY_VALUE = 'EMPTY_VALUE';

const TYPE_HASH = {
  CLAIM_ID: claimType,
  POLICY_ID: caseType,
  CASE_ID: caseType,
};

class EnterCase extends React.Component {
  constructor(props) {
    super(props);

    const value = EMPTY_VALUE;

    this.state = {
      value,
      isNewUser: false,
      submitIsDisabled: false,
      userCases: [],
      showCaseMatchAlert: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  // eslint-disable-next-line max-len
  // this code componentDidMount will automatically select the first option in the dropdown if there is only one option
  componentDidMount() {
    const { permissions } = this.props;
    const showClaimOption = _.findIndex(permissions, (p) => p === 'CREATE_CLAIMS_FROM_CASES_TAB') >= 0;
    const showPolicyOption = _.findIndex(permissions, (p) => p === 'CREATE_POLICY_FROM_CASES_TAB') >= 0;
    const options = [{ id: CASE_ID, label: 'General Case' }];
    if (showClaimOption) options.push({ id: CLAIM_ID, label: 'Claim' });
    if (showPolicyOption) options.push({ id: POLICY_ID, label: 'Policy' });
    if (options.length === 1) this.onChange(options[0].id);
  }

  onBack() {
    this.props.clearAddFormErrors(userType.reducerCase());
    this.setState({ value: EMPTY_VALUE });
    // eslint-disable-next-line max-len
    // We will eventually want to reset some more values here since all form data is now coming from jobs.
  }

  onChange(value) {
    const createType = TYPE_HASH[value];
    const closeType = TYPE_HASH[this.state.value];
    if (this.state.value !== EMPTY_VALUE) this.props.closeForm(closeType);

    if (value !== CASE_ID) this.props.openCreateForm(createType);
    this.props.clearAddFormErrors(userType.reducerCase());

    this.setState({
      value,
    });

    if (this.props.onChange) this.props.onChange(createType);
  }

  toggleDisableFormSubmit = (value) => this.setState({ submitIsDisabled: value });

  render() {
    const {
      value, isNewUser, submitIsDisabled, userCases,
    } = this.state;
    const {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      closeForm, permissions, caseIsEdit, users, userIsEdit, userEditObject,
    } = this.props;
    const isCompletePhoneNumber = cleanPhone(_.get(userEditObject, 'phoneNumber', '')).length === 10;
    const showClaimOption = _.findIndex(permissions, (p) => p === 'CREATE_CLAIMS_FROM_CASES_TAB') >= 0;
    const showPolicyOption = _.findIndex(permissions, (p) => p === 'CREATE_POLICY_FROM_CASES_TAB') >= 0;
    const options = [{ id: CASE_ID, label: 'General Case' }];
    if (showClaimOption) options.push({ id: CLAIM_ID, label: 'Claim' });
    if (showPolicyOption) options.push({ id: POLICY_ID, label: 'Policy' });
    const onBack = options.length === 1 ? null : this.onBack;

    return (
      <FullpageModal title="Create Case" view="case" onClose={() => [userType, caseType, policyType, claimType].forEach((t) => closeForm(t))}>
        <div className="enter-form create-case-modal">
          <div className="create-case-modal-toggle">
            <div className="enter-user-title enter-form-title">
              {isNewUser ? 'New ' : ''}
              User
            </div>
            <UserForm
              toggleDisableParentFormSubmit={this.toggleDisableFormSubmit}
              parentFormContext="jobs"
              parentIsEdit={caseIsEdit}
              view="case"
              handleParentJobs={(cases) => {
                this.setState({ userCases: cases, showCaseMatchAlert: cases.length > 0 });
              }}
            />
            { value === EMPTY_VALUE && isCompletePhoneNumber && (
              <div className="case-type-select">
                <AssignDropdown
                  id="type"
                  defaultLabel="Select case type"
                  selectedId={this.state.value}
                  handleSelect={(selectId) => {
                    this.onChange(selectId);
                    if (this.state.showCaseMatchAlert) this.setState({ showCaseMatchAlert: false });
                  }}
                  options={options}
                />
              </div>
            )}
            <CaseMatchAlert
              cases={userCases}
              defaultShowAlert={this.state.showCaseMatchAlert}
              onClose={() => this.setState({ showCaseMatchAlert: false })}
            />
          </div>
          {value === CLAIM_ID
            && (
            <ClaimsForm
              hideUserForm
              toggleDisableFormSubmit={this.toggleDisableFormSubmit}
              submitIsDisabled={submitIsDisabled}
              userEditObject={userEditObject}
              userIsEdit={userIsEdit}
              users={users}
              userCases={userCases}
              onBack={onBack}
              view="case"
            />
            )}
          {value === POLICY_ID
            && (
            <PolicyForm
              hideUserForm
              toggleDisableFormSubmit={this.toggleDisableFormSubmit}
              submitIsDisabled={submitIsDisabled}
              userEditObject={userEditObject}
              userIsEdit={userIsEdit}
              users={users}
              userCases={userCases}
              onBack={onBack}
              view="case"
            />
            )}
          {value === CASE_ID
            && (
            <GeneralPurposeCaseForm
              hideUserForm
              toggleDisableFormSubmit={this.toggleDisableFormSubmit}
              submitIsDisabled={submitIsDisabled}
              userEditObject={userEditObject}
              userIsEdit={userIsEdit}
              users={users}
              userCases={userCases}
              onBack={onBack}
            />
            )}
        </div>
      </FullpageModal>
    );
  }
}

EnterCase.propTypes = {
  closeForm: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  openCreateForm: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  permissions: PropTypes.array,
  caseIsEdit: PropTypes.bool,
  users: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  userIsEdit: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  userEditObject: PropTypes.object,
  clearAddFormErrors: PropTypes.func,
};

EnterCase.defaultProps = {
  permissions: [],
  onChange: () => {},
  caseIsEdit: false,
  users: [],
  userIsEdit: false,
  userEditObject: {},
  clearAddFormErrors: () => {},
};

const mapStateToProps = (state) => ({
  users: _.get(state, 'users', {}),
  userIsEdit: _.get(state, 'users.form.isEdit', false),
  userEditObject: _.get(state, 'users.form.editObject', {}),
  caseIsEdit: _.get(state, 'jobs.form.isEdit', false),
  permissions: _.get(state, 'auth.permissions'),
});

export default connect(
  mapStateToProps,
  {
    openCreateForm,
    closeForm,
    clearAddFormErrors,
  },
)(EnterCase);
