import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../../../../images/icons/home/star.svg';
import './StarIcon.less';

const StarIcon = ({ className }) => (
  <Icon className={classnames('star-icon', className)} />
);

StarIcon.propTypes = {
  className: PropTypes.string,
};
StarIcon.defaultProps = {
  className: '',
};

export default StarIcon;
