import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import FormField from "../../../../Form/FormField/FormField";
import Button from "../../../../Form/Button/Button";
import TimeDropdown from "../../SharedComponents/TimeDropdown/TimeDropdown";
import TimeOutDropdown from "../../SharedComponents/TimeOutDropdown/TimeOutDropdown";
import WeekdayCheckboxes from "../../SharedComponents/WeekdayCheckboxes/WeekdayCheckboxes";
import TimezoneDropdown from "../../SharedComponents/TimezoneDropdown/TimezoneDropdown";
import { makeApiRequest } from "../../../../../actions/api/api";
import { getActiveDay } from "../../Utils";
import "./GeneralAutoReplyModal.less";
import _ from "lodash";

const GeneralAutoReplyModal = ({ pageProps, toggleModal }) => {
  const { success, error, pendingRequest } = useSelector(
    (state) => ({
      success: state.profile.success,
      error: state.profile.error,
      pendingRequest: state.profile.pendingRequest,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const [checkAllTimes, setCheckAllTimes] = useState(
    !(_.has(pageProps, "reply.sendCriteria.timing") &&
      pageProps.reply.sendCriteria.timing === "SPECIFIED")
  );
  const [checkSpecificTimes, setCheckSpecificTimes] = useState(
    !!(_.has(pageProps, "reply.sendCriteria.timing") &&
      pageProps.reply.sendCriteria.timing === "SPECIFIED")
  );
  const [showTimeDropdown, setShowTimeDropdown] = useState(false);
  /** @constant {object} selectedDaysTime is the object that will store the selected begin and end times, which will then get applied to all active days on form submission*/
  const [selectedDaysTime, setSelectedDaysTime] = useState({
    begin: "12:0",
    end: "13:0",
  });
  const [allDay, setAllDay] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [reply, setReply] = useState(pageProps.reply);
  const [isEdit, setIsEdit] = useState(false);

  // The empty array as the second argument tells react to load this useEffect once after the initial rendering
  useEffect(() => {
    // edit mode
    if (_.has(pageProps, "reply.id")) {
      setIsEdit(true);
      // we need to set the specified time by getting it from one of the existing active days
      const activeDay = getActiveDay(pageProps.reply);
      if (
        _.has(activeDay, "allDay") &&
        _.has(activeDay, "beginTime") &&
        _.has(activeDay, "endTime")
      ) {
        setAllDay(activeDay.allDay);
        setSelectedDaysTime({
          begin: `${activeDay.beginTime.hour}:${activeDay.beginTime.minute}`,
          end: `${activeDay.endTime.hour}:${activeDay.beginTime.minute}`,
        });
      }
    }
  }, []);

  // Runs ONCE after initial rendering
  // And after every rendering ONLY IF `checkSpecificTimes` or `checkAllTimes` changes
  // If checkSpecificTimes or checkAllTimes changes, then set the values of the tick boxes correctly
  // and set the reply objects sendCriteria.timing property accordingly
  useEffect(() => {
    if (checkSpecificTimes) {
      setShowTimeDropdown(true);
    }
    if (checkAllTimes) {
      setShowTimeDropdown(false);
    }
    setReply({
      ...reply,
      sendCriteria: {
        ...reply.sendCriteria,
        timing: checkSpecificTimes ? "SPECIFIED" : "ALL",
      },
    });
  }, [checkSpecificTimes, checkAllTimes]);

  // if a success response is returned from an API call, then reset the request values and toggle the modal
  // if pendingRequest redux state property is true that means an API call is in progress, so disable the submit button,
  // if pendingRequest is false then invoke handleCanSubmit
  useEffect(() => {
    if (success && success.length > 0) {
      dispatch({ type: "RESET_PROFILE_REQUESTS" });
      toggleModal();
    }
    if (pendingRequest) {
      setCanSubmit(false);
    } else {
      handleCanSubmit();
    }
  }, [success, pendingRequest]);

  // loads each time reply changes
  // each time we update the reply object check if we are able to make a submission or not
  useEffect(() => {
    handleCanSubmit();
  }, [reply]);

  // if an API error gets returned then display for 2 seconds and then clear it from the redux store
  useEffect(() => {
    if (error && error.length > 0) {
      const timer = setTimeout(() => {
        dispatch({ type: "RESET_PROFILE_REQUESTS" });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  function handleSpecificTimes() {
    setCheckSpecificTimes(!checkSpecificTimes);
    setCheckAllTimes(checkSpecificTimes);
  }

  function handleCheckAllTimes() {
    setCheckAllTimes(!checkAllTimes);
    setCheckSpecificTimes(checkAllTimes);
  }

  // if there is some data in the 'title' property and the 'during' message then we can make a submission
  function handleCanSubmit() {
    if (reply?.title && reply.title.length > 0 && 
        reply?.message?.during?.value && reply.message.during.value.length > 0 && 
        !pendingRequest
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }

  function handleCancel() {
    dispatch({ type: "RESET_PROFILE_REQUESTS" });
    toggleModal();
  }

  async function handleSubmit(userId, reply) {
    // the below logic sets the time of each active day to the selectedDaysTime value if the timing property is set to SPECIFIED
    if (
      _.has(reply, "sendCriteria.timing") &&
      reply.sendCriteria.timing === "SPECIFIED"
    ) {
      const submitReply = { ...reply };
      const [beginHour, beginMinute] = selectedDaysTime.begin.split(":");
      const [endHour, endMinute] = selectedDaysTime.end.split(":");
      const days = submitReply.sendCriteria.days;
      // eslint-disable-next-line no-restricted-syntax
      for (const day in days) {
        if (
          days[day].active &&
          _.has(submitReply, `sendCriteria.days.${day}.beginTime.hour`)
        ) {
          submitReply.sendCriteria.days[day] = {
            active: true,
            allDay: allDay,
            beginTime: {
              hour: parseInt(beginHour),
              minute: parseInt(beginMinute),
            },
            endTime: {
              hour: parseInt(endHour),
              minute: parseInt(endMinute),
            },
          };
        }
      }
      await setReply({ ...submitReply });
    }
    if (isEdit) {
      await dispatch(
        makeApiRequest("PUT_USERS_AUTOREPLIES", [userId, reply.id], reply)
      );
    } else {
      await dispatch(makeApiRequest("POST_USERS_AUTOREPLIES", [userId], reply));
    }
  }

  return (
    <div>
      <FormField
        control={
          <textarea
            data-jest="auto-reply-title"
            className="auto-reply-title"
            value={reply.title}
            placeholder={`Auto reply title`}
            maxLength={2000}
            onChange={(e) => {
              e.persist();
              setReply({
                ...reply,
                title: e.target.value,
              });
            }}
          />
        }
      />

      <FormField
        control={
          <textarea
            data-jest="auto-reply-message-textarea"
            className="auto-reply-message-textarea"
            value={_.get(reply, "message.during.value", "")}
            placeholder={`Type a message`}
            onChange={(e) => {
              e.persist();
              setReply({
                ...reply,
                message: {
                  during: {
                    type: "STRING",
                    value: e.target.value,
                  },
                },
              });
            }}
          />
        }
      />
      <div className="ooo-timeout" style={{display: "none"}}>
        <p>Message will be sent when inactive for more than</p>
        <TimeOutDropdown reply={reply} setReply={setReply} />
      </div>
      <div className="auto-reply-explanation" style={{display: "none"}}> 
        <p>
          Choose to have this auto reply message be sent out at all times or on
          specific days and times.
        </p>
      </div>
      <div className="auto-reply-check-block" style={{display: "none"}}>
        <input
          className="auto-reply-checkbox all-times"
          name="all-times"
          type="checkbox"
          disabled="disabled"
          checked={checkAllTimes}
          value={checkAllTimes}
          onChange={() => handleCheckAllTimes()}
        ></input>
        <label htmlFor="all-times">Send at all times</label>
        <input
          className="auto-reply-checkbox specific-times"
          name="specific-times"
          type="checkbox"
          onChange={() => handleSpecificTimes()}
          checked={checkSpecificTimes}
          value={checkSpecificTimes}
        ></input>
        <label htmlFor="specific-times specific-times">
          Send at specific times
        </label>
      </div>
      {showTimeDropdown ? (
        <div>
          <div className="times-day-container">
            <WeekdayCheckboxes
              reply={reply}
              setReply={setReply}
              showFullDay={false}
            />
            <TimeDropdown
              reply={reply}
              setReply={setReply}
              allDay={allDay}
              setAllDay={setAllDay}
              selectedTime={selectedDaysTime}
              setSelectedTime={setSelectedDaysTime}
              showAllDayCheckBox={true}
            />
          </div>
          <div className="timezone-container">
            <h6>Time Zone:</h6>
            <TimezoneDropdown reply={reply} setReply={setReply} />
          </div>
        </div>
      ) : null}
      <div className="auto-replies-form-error">{error}</div>
      <div className="enter-general-auto-reply-row">
        <div className="button-row">
          <Button className={"cancel outline"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            data-jest="save-ooo-button"
            className={"save-ooo-button hymarley-button primary"}
            disabled={!canSubmit}
            onClick={() => handleSubmit(pageProps.userId, reply)}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GeneralAutoReplyModal;
