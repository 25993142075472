/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Modal from '../../../modal/Modal/Modal';
import Panel from '../../../Panel/Panel';
import { queueType } from '../../../../models/marleyTypes';
import withLazyLoad from '../../../withLazyLoad/withLazyLoad';
import withLoading from '../../../HigherOrderComponents/withLoading';
// import CreateGroup from './CreateGroup/CreateGroup'
import Button from '../../../elements/buttons/Button/Button';
import Row from './Row';
import SortTable from '../../../elements/table/SortTable/SortTable';
import withModal from '../../../HigherOrderComponents/withModal';
import SearchBar from '../../../SearchBarWrap/SearchBarWrap';

import './QueueMgmt.less';

const QueueMgmt = ({ queues, toggleModal }) => (
  <Panel
    className="queue-mgmt-page"
    header={(
      <div className="header">
        <Button
          type="positive-marley"
          onClick={toggleModal}
        >
          Create Queue +
        </Button>
        <SearchBar
          id="queue"
          label="a name or company"
        />
      </div>
      )}
    title="Queue Management"
  >
    <div className="table">
      <SortTable
        notInfinite
        type={queueType}
        rows={queues && queues.map((q) => (
          <Row
            key={q.id}
            queue={q}
          />
        ))}
        columns={[`${queues ? queues.length : 0} Queues`]}
      />
    </div>
  </Panel>
);

const mapStateToProps = (state) => ({
  queues: _.get(state, 'queues.list', []),
  filter: _.get(state, 'ui.filter.queue'),
});
const QueueMgmtWithLoading = withLoading(QueueMgmt, { type: queueType });

const GroupMgmtWithLoadingModal = withModal({
  Modal: (
    <Modal
      className="create-group-modal"
      data-test="create-group-modal"
      title="Create a Queue"
    >
      <div />
      {/* <CreateGroup /> */}
    </Modal>
  ),
})(QueueMgmtWithLoading);

const LoadedQueues = withLazyLoad(GroupMgmtWithLoadingModal, {
  type: queueType,
  listLocation: 'queues',
});

export default connect(mapStateToProps, null)(LoadedQueues);
