import {
  SUBSCRIBE_TO_TOPICS, UNSUBSCRIBE_FROM_TOPICS, USER_IS_TYPING, USER_IS_TYPING_TIMEOUT,
} from '../constants/actions';

export const subscribeToTopic = (topic, handler) => (dispatch) => {
  dispatch({ type: SUBSCRIBE_TO_TOPICS, payload: { topics: [topic], handler } });
};

export const subscribeToTopics = (topics, handler) => (dispatch) => {
  dispatch({
    type: SUBSCRIBE_TO_TOPICS,
    payload: { topics, handler },
  });
};

export const unsubscribeFromTopics = (topics) => (dispatch) => {
  dispatch({ type: UNSUBSCRIBE_FROM_TOPICS, payload: { topics } });
};

let clearUserIsTypingTimeout = null;
const userIsTypingConstantInMS = 5000;

export const handleUserIsTypingEvent = ({ userId, authorName, chatId }) => (
  (dispatch, getStore) => {
    const store = getStore();
    const { _id: activeUserId } = store.auth?.user || {};
    const { id: activeChatId } = store.jobs?.activeChat || {};
    // received an event for the same user, don't update the store
    if (userId === activeUserId) return;
    // received an event for a different chat, don't update the store
    if (activeChatId !== chatId) {
      return;
    }
    // set author name
    dispatch({
      type: USER_IS_TYPING,
      payload: {
        userId,
        chatId,
        authorName,
      },
    });

    // this will clear the existing timeout and
    // remove the typing UI element after USER_IS_TYPING_TIMEOUT has elapsed
    clearTimeout(clearUserIsTypingTimeout);
    clearUserIsTypingTimeout = setTimeout(
      () => {
        dispatch({
          type: USER_IS_TYPING_TIMEOUT,
        });
      },
      userIsTypingConstantInMS,
    );
  }
);
