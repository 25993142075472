import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import set from 'lodash/set';
import union from 'lodash/union';
import { lazyLoad } from '../../../../../actions/common';
import withLazyLoad from '../../../../withLazyLoad/withLazyLoad';
import withLoading from '../../../../HigherOrderComponents/withLoading';
import DetectBottom from '../../../../elements/table/DetectBottom';
import GroupListItem from '../GroupListItem';
import SearchBar from '../../../../SearchBar/SearchBar';
import Ghosts from '../../../../elements/Ghosts/Ghosts';
import { subGroupType } from '../../../../../models/marleyTypes';

const GroupList = ({
  increaseDataSet,
  groups,
  groupId,
  handleFormChange,
  modifyQuery,
  isLoading,
  subgroups,
  groupsMap,
}) => {
  const filteredGroups = useMemo(() => {
    // filter out circular subgroups
    const filtered = groups?.filter(
      (group) => group?.id !== groupId && !group?.subgroups?.some((el) => el?._id === groupId),
    );
    // filter out already selected groups
    return (filtered || []).filter(
      (group) => !union(subgroups)
        .map((subgroup) => subgroup?._id)
        .includes(group?.id),
    );
  }, [groupId, groups, subgroups]);

  const onSearchTextChange = (e) => {
    const searchText = e?.target?.value;
    modifyQuery({ searchText });
  };

  // needed in order to maintain same subgroup properties and form change working correctly
  const formatGroup = (group) => ({ _id: group?.id, name: group?.name })

  return (
    <>
      <SearchBar
        placeholder="Search groups to add"
        onChange={onSearchTextChange}
      />
      {isLoading ? (
        <Ghosts numItems={6} className="item" />
      ) : (
        <DetectBottom
          className="userList-container"
          onBottom={(b, callback) =>
            b && increaseDataSet && increaseDataSet(callback)
          }
        >
          {filteredGroups?.map((group) => (
            <GroupListItem
              key={group?.id}
              item={group}
              groupsMap={groupsMap}
              updateList={() => {
                const changes = set({}, 'subgroups', [
                  ...(subgroups || []),
                  formatGroup(group),
                ]);
                handleFormChange(changes);
              }}
            />
          ))}
        </DetectBottom>
      )}
    </>
  );
};

GroupList.propTypes = {
  increaseDataSet: PropTypes.func.isRequired,
  groups: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  groupId: PropTypes.string,
  handleFormChange: PropTypes.func.isRequired,
  modifyQuery: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  subgroups: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  groupsMap: PropTypes.shape({}).isRequired,
};

GroupList.defaultProps = {
  groupId: null,
  subgroups: null,
  groups: null,
};

export { GroupList };

const mapStateToProps = (state) => ({
  groups: state?.subgroups?.list?.sort((a, b) => a?.name?.localeCompare(b?.name)),
  isLoading: state?.subgroups?.loadingItem || false,
});

const GroupListWithFetch = withLazyLoad(GroupList, {
  type: subGroupType,
  listLocation: 'subgroups',
});

const GroupListFetchLOading = withLoading(GroupListWithFetch, {
  type: subGroupType,
});

export default connect(mapStateToProps, {
  lazyLoad,
})(GroupListFetchLOading);
