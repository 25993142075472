/* eslint-disable no-case-declarations */
/* TODO remove this and fix the linting at some point */

import _ from 'lodash';
import {
  SET_CREATE_BUTTON_STATUS,
  SET_MASSNOTIFICATION_FILENAME,
  GET_MASS_NOTIFICATIONS_ACTION,
  HIDE_MASSNOTIFICATION_MODAL,
  SET_MODAL_LOADING,
  SET_SELECTED_TYPE,
  MASS_NOTIFICATION_UPDATE,
  CREATED_MASS_NOTIFICATION,
  SET_SELECTED_FILE,
  SET_MESSAGE_COPIED,
  FAILED_TO_CREATE_NOTIFICATION,
  MASS_NOTIFICATION_PROGRESS_UPDATE,
  MASS_NOTIFICATION_CREATE_MESSAGE_UPDATE,
} from '../constants/actions';

const processNotifications = (notes = []) => notes.map((n) => ({ ...n, id: n.Id, operator: n.AssignedOperator || 'unassigned' }));

export const initialState = {
  massNotificationList: [],
  currentMassNotificiationId: null,
  currentFilename: null,
  createButtonEnabled: false,
  modalLoading: false,
  selectedType: 'ALERT',
  selectedFile: null,
  messageCopied: false,
  showCreateErrorMessage: false,
};

// Map describing the "priority" of status to handle out of order live-updates
const shouldUpdateStatus = (newStatus, oldStatus) => {
  const STATUS_PRIORITY_HASH = {
    'INVALID_CSV:INVALID_FORMAT': 100,
    'INVALID_CSV:MISSING_REQUIRED_FIELDS': 100,
    'VALIDATION_FAILED:FAILED_TO_VALIDATE_ENTRIES': 100,
    'VALIDATION_FAILED:FAILED_TO_WRITE_ERROR_REPORT': 100,
    'VALIDATION_FAILED:FAILED_TO_ADD_ERROR_REPORT_TO_ENTRY': 100,
    VALIDATION_COMPLETE: 2,
    VALIDATING: 1,
    CREATED: 0,
    PROCESSING: 3,
    FAILED: 100,
    NO_VALID_ENTRIES_TO_SEND: 100,
    TRANSCRIPT_CREATED: 100,
    SENT: 99,
    SENDING: 5,
    CREATING_MESSAGES: 4,
    NO_VALID_ENTRIES: 100,
    MAX_ENTRIES_SURPASSED: 100,
    FAILED_TO_QUERY_USERS_FOR_INSERT: 100,
    FAILED_TO_MASS_INSERT_USERS: 100,
    FAILED_TO_POST_USER_CREATION_TO_BUCKET: 100,

  };

  const oldPriority = _.get(STATUS_PRIORITY_HASH, oldStatus);
  const newPriority = _.get(STATUS_PRIORITY_HASH, newStatus);

  return newPriority > oldPriority;
};

const massNotification = (state = initialState, action) => {
  switch (action.type) {
    case GET_MASS_NOTIFICATIONS_ACTION:
    // sort by date
      action.payload.data.sort((a, b) => (new Date(b.Date)).getTime() - (new Date(a.Date)).getTime());
      return { ...state, massNotificationList: processNotifications(action.payload.data) }; // something wrong with this payload massNotificationList: [] = action.payload.data }
    case HIDE_MASSNOTIFICATION_MODAL:
      return {
        ...state,
        currentFilename: '',
        createButtonEnabled: false,
        selectedType: 'ALERT',
        modalLoading: false,
        selectedFile: null,
        showCreateErrorMessage: false,
      };
    case 'MASS_NOTIFICATION_SENT': {
      const updatedList = state.massNotificationList.map((curr) => {
        if (curr.Id === action.payload.id) { curr.Status = 'PROCESSING'; }
        return curr;
      });
      return { ...state, massNotificationList: processNotifications(updatedList) };
    }
    case 'REQUEST_MASS_NOTIFICATION_SENT_ERROR':
      const updatedList = state.massNotificationList.map((curr) => {
        if (curr.Id === action.payload.body.id) { curr.Status = 'FAILED_TO_SEND'; }
        return curr;
      });
      return { ...state, massNotificationList: processNotifications(updatedList) };
    case SET_MASSNOTIFICATION_FILENAME:
      const updatedFileName = action.payload.filename.length > 20 ? `${action.payload.filename.slice(0, 17)}...` : action.payload.filename;
      return { ...state, currentFilename: updatedFileName };
    case SET_CREATE_BUTTON_STATUS:
      return { ...state, createButtonEnabled: action.payload.status };
    case SET_MODAL_LOADING:
      return { ...state, modalLoading: action.payload.status };
    case SET_SELECTED_TYPE:
      return { ...state, selectedType: action.payload.type };
    case SET_SELECTED_FILE:
      return { ...state, selectedFile: action.payload.file };
    case CREATED_MASS_NOTIFICATION:
      return { ...state };
    case SET_MESSAGE_COPIED:
      return { ...state, messageCopied: action.payload.set };
    case MASS_NOTIFICATION_UPDATE: {
      const updatedList = state.massNotificationList.slice().map((curr) => {
        if (curr.Id === action.payload.Id && shouldUpdateStatus(action.payload.Status, curr.Status)) {
          return { ...action.payload };
        }
        return { ...curr };
      });
      return { ...state, massNotificationList: processNotifications(updatedList) };
    }
    case 'REQUEST_DELETE_MASS_NOTIFICATION_INIT':
      const listWithDelete = state.massNotificationList.slice().filter((curr) => curr.Id !== action.payload.id);
      return { ...state, massNotificationList: processNotifications(listWithDelete) };
    case 'REQUEST_MASS_NOTIFICATION_ASSIGNED_INIT':
      const listWithUpdatedOperator = state.massNotificationList.map((curr) => {
        if (curr.Id === action.payload.id) { curr.AssignedOperator = action.payload.operator; }
        return curr;
      });
      return { ...state, massNotificationList: processNotifications(listWithUpdatedOperator) };
    case FAILED_TO_CREATE_NOTIFICATION:
      return {
        ...state,
        selectedType: 'ALERT',
        modalLoading: false,
        showCreateErrorMessage: true,
        createButtonEnabled: true,
      };
    case MASS_NOTIFICATION_PROGRESS_UPDATE:
      const updatedProgressList = state.massNotificationList.slice().map((curr) => {
        if (curr.Id === action.payload.id) {
          return { ...curr, Progress: action.payload.progress };
        }
        return { ...curr };
      });
      return { ...state, massNotificationList: processNotifications(updatedProgressList) };
    case MASS_NOTIFICATION_CREATE_MESSAGE_UPDATE:
      const updatedProgressListBatches = state.massNotificationList.slice().map((curr) => {
        if (curr.Id === action.payload.id) {
          return { ...curr, BatchesLeft: action.payload.batchesLeft };
        }
        return { ...curr };
      });
      return { ...state, massNotificationList: processNotifications(updatedProgressListBatches) };
    default:
      return { ...state };
  }
};

export default massNotification;
