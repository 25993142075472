/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { PropTypes } from 'prop-types';
import LoadingIcon from '..././../../../../images/icons/loading.svg';

const ImportingProgress = ({ userNumber }) => (
  <div className="progressWrap">
    <div>
      Please don't leave this screen while the users are
      {' '}
      <br />
      importing or this operation will be cancelled.
    </div>
    <div className="loadingWrap">
      <div className="loading none-found-icon">
        <LoadingIcon />
      </div>
      <div className="importProgressText" data-testid="importProgress">
        Processing
        <br />
        {userNumber}
        {' '}
        Users
      </div>
    </div>
  </div>
);

ImportingProgress.propTypes = {
  userNumber: PropTypes.string.isRequired,
};

export default ImportingProgress;
