import { getAccessToken } from '../helpers/localStorage';
import lruCache from '../helpers/lru-request-cache';

const secureFileLoad = async (fileUrl) => fetch(fileUrl, {
  cache: 'no-cache',
  headers: {
    Authorization: (await getAccessToken()),
    'Cache-Control': 'no-cache',
  },
});

let cache;

const globalMediaCacheFactory = async () => {
  if (!cache) {
    cache = await lruCache('media', secureFileLoad);
  }
  return cache;
};

export default globalMediaCacheFactory;
