/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import CaretIcon from '../../../../images/icons/large-caret.svg';
import './CollapsibleSection.less';
import PropTypes from 'prop-types';

const CollapsibleSection = ({
  icon, disabled, show, onToggle, label, children, className, float, dataTestId, subHeader,
}) => {
  const [doShow, setDoShow] = useState(false);

  useEffect(() => {
    if (!disabled) {
      if (doShow !== show) setDoShow(show);
    }
  }, [show, disabled]);

  const toggleShow = () => {
    if (!disabled) {
      if (onToggle) onToggle(!doShow);
      setDoShow(!doShow);
    }
  };

  return (
    <div data-testid={dataTestId} className={`collapsible-section-${doShow ? 'open' : 'closed'} collapsible-section ${className}`}>
      <div data-testid="collapsible-section-header" className={`collapsible-section-header-${doShow ? 'open' : 'closed'}  ${disabled ? 'collapsible-section-header-disabled' : 'collapsible-section-header-enabled'} collapsible-section-header`} onClick={toggleShow} role="link" tabIndex="0">
        <div className="label-and-icon-container">
          {icon && <span className="collapsible-section-icon" data-testid="collapsible-icon">{icon}</span>}
          {label}
        </div>
        <div className="header-right-content">
          <span className="collapsible-section-subheader">{subHeader}</span>
          {!disabled && <span className={`collapsible-${doShow ? 'caret-open' : 'caret-closed'} dropdown-icon`}><CaretIcon /></span>}
        </div>
      </div>
      <div data-testid={`collapsible-section-body-${doShow ? 'open' : 'closed'}`} className={`collapsible-section-body-${doShow ? 'open' : 'closed'} collapsible-section-body ${float ? 'collapsible-section-body-float' : ''}`}>
        {children}
      </div>
    </div>
  );
};

CollapsibleSection.propTypes = {
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  onToggle: PropTypes.func,
  label: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  float: PropTypes.bool,
  dataTestId: PropTypes.string,
  subHeader: PropTypes.string,
};

CollapsibleSection.defaultProps = {
  icon: null,
  disabled: false,
  show: false,
  onToggle: null,
  label: null,
  children: null,
  className: '',
  float: false,
  dataTestId: null,
  subHeader: '',
};

export default CollapsibleSection;
