/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import PropTypes from 'prop-types'

const CreateMassNotificationThrottling = ({
  createButtonEnabled,
  handleBackStep,
  handleChangeThrottleEnabled,
  handleClickSubmit,
  handleFormInputChange,
  isScheduleEnabled,
  isThrottleEnabled,
  showErrorMessage,
  formData,
}) => (
  <>
    <div className="modal-container paddedInModal">
      <h3 className="modal-step-title">Step 3: Message Rate</h3>
      <p>
        You can set a rate at which messages are sent out hourly or you can
        send the notification in full.
      </p>
      <div className="radio-btn-group">
        <label className="radio-btn-field" htmlFor>
          <input
            checked={isThrottleEnabled}
            className="radio-btn-input"
            name="isThrottleEnabled"
            onChange={handleChangeThrottleEnabled}
            type="radio"
            value="yes"
          />
          <span className="radio-btn-label">Set an hourly message rate</span>
        </label>
        <label className="radio-btn-field" htmlFor>
          <input
            checked={!isThrottleEnabled}
            className="radio-btn-input"
            name="isThrottleEnabled"
            onChange={handleChangeThrottleEnabled}
            type="radio"
            value="no"
          />
          <span className="radio-btn-label">Send notification in full</span>
        </label>
      </div>
      {
        isThrottleEnabled ? (
          <>
            <h4 className="modal-section-title">Set Message Rate</h4>
            <p>Set the maximum number of messages to be sent out each hour.</p>
            <div className="notification-scheduled-container">
              <div className="innerContainer">
                <div className="inputTitle">Hourly Message Rate</div>
                <input
                  className="notification-throttled-number-input"
                  type="number"
                  min={1000}
                  name="throttleRate"
                  onChange={handleFormInputChange}
                  value={formData.throttleRate}
                />
              </div>
            </div>
            <h5 className="modal-section-subtitle">Active Sending Hours</h5>
            <p className="modal-section-disclaimer">
              Messages will only be sent between 9:00 AM and 8:00 PM in the destination timezone.
              Message sending will pause outside of these hours and will resume the following day.
            </p>
          </>
        ) : null
      }
    </div>
    <div className="buttonRow">
      {
        showErrorMessage
          ? <div className="warningText errorText">Failed to create Mass Notification, please try again later!</div>
          : null
      }
      <button
        type="button"
        onClick={handleBackStep}
        className="hymarley-button secondary"
      >
        Back
      </button>
      <button
        type="button"
        onClick={handleClickSubmit}
        className="next-btn hymarley-button primary"
        disabled={
          !createButtonEnabled
          || !isScheduleEnabled
          || (isThrottleEnabled && formData.throttleRate < 1000)
        }
      >
        Create Now
      </button>
    </div>
  </>
)

CreateMassNotificationThrottling.propTypes = {
  createButtonEnabled: PropTypes.bool,
  handleBackStep: PropTypes.func.isRequired,
  handleChangeThrottleEnabled: PropTypes.func.isRequired,
  handleClickSubmit: PropTypes.func.isRequired,
  handleFormInputChange: PropTypes.func.isRequired,
  isScheduleEnabled: PropTypes.bool.isRequired,
  isThrottleEnabled: PropTypes.bool.isRequired,
  showErrorMessage: PropTypes.bool,
  formData: PropTypes.shape({
    throttleRate: PropTypes.number,
  }).isRequired,
}

CreateMassNotificationThrottling.defaultProps = {
  createButtonEnabled: undefined,
  showErrorMessage: undefined,
}

export default CreateMassNotificationThrottling
