export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const typeToLine = {
  personal: 'Personal Line',
  commercial: 'Commercial Line',
  other: 'Other/Misc. Line',
};

export const LINES_OF_BUSINESS = {
  PERSONAL_AUTO: {
    displayName: 'Auto',
    type: 'personal',
    subType: 'auto',
    archived: false,
  },
  PERSONAL_HOME: {
    displayName: 'Home',
    type: 'personal',
    subType: 'home',
    archived: false,
  },
  PERSONAL_OTHER: {
    displayName: 'Other',
    type: 'personal',
    subType: 'other',
    archived: false,
  },
  COMMERCIAL_AUTO: {
    displayName: 'Auto',
    type: 'commercial',
    subType: 'auto',
    archived: false,
  },
  COMMERCIAL_PROPERTY: {
    displayName: 'Property',
    type: 'commercial',
    subType: 'property',
    archived: false,
  },
  COMMERCIAL_GENERAL_LIABILITY: {
    displayName: 'General Liability',
    type: 'commercial',
    subType: 'general-liability',
    archived: false,
  },
  COMMERCIAL_WORKERS_COMP: {
    displayName: 'Workers Comp.',
    type: 'commercial',
    subType: 'workers-comp',
    archived: false,
  },
  COMMERCIAL_OTHER: {
    displayName: 'Other',
    type: 'commercial',
    subType: 'other',
    archived: false,
  },
  OTHER_CROP: {
    displayName: 'Crop',
    type: 'other',
    subType: 'crop',
    archived: false,
  },
  OTHER_LIFE: {
    displayName: 'Life',
    type: 'other',
    subType: 'life',
    archived: false,
  },
  OTHER_OTHER: {
    displayName: 'Other',
    type: 'other',
    subType: 'other',
    archived: false,
  },
};
