import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import websocketMiddleware from './middlewares/websocket-middleware';
import inactivityMiddleware from './middlewares/inactivityMiddleware';
import notificationsMiddleware from './middlewares/notificationsMiddleware';
import rootReducer from './reducers';
import { assistApi } from './services/assist';

const loggerMiddleware = createLogger({ duration: true, diff: false });

const middleware = [
  thunkMiddleware,
  websocketMiddleware,
  inactivityMiddleware,
  notificationsMiddleware,
  process.env.REDUX_LOGGING_ENABLED && loggerMiddleware,
  assistApi.middleware,
].filter(Boolean);

const setupStore = (preloadedState) => configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware,
});

export default setupStore;
