import React from 'react';
import PropTypes from 'prop-types';
import Required from './assets/required.svg';
import NotAvailable from './assets/not-available.svg';
import AdminDisabled from './assets/admin-disabled.svg';
import TootipInfo from '../Tootip/TooltipInfo';
import './SettingsCheckBox.less';

const SettingsCheckBox = ({
  onChange, name, type, selected, dataTestId,
}) => {
  const handleChange = (event) => {
    const { checked } = event.target;
    onChange(checked);
  };
  switch (type) {
    case 'required':
      return (
        <div className="settings-checkbox-container" data-testid={`settings-checkbox-${type}`}>
          <TootipInfo icon={<Required title="required" className="infoSvg" />} message="Required by Admin" />
        </div>
      );
    case 'notAvailable':
      return (
        <div className="settings-checkbox-container" data-testid={`settings-checkbox-${type}`}>
          <TootipInfo icon={<NotAvailable title="notAvailable" className="infoSvg" />} message="Not available at this time" />
        </div>
      );
    case 'notConfigurable':
      return (
        <div className="settings-checkbox-container" data-testid={`settings-checkbox-${type}`}>
          <TootipInfo icon={<Required title="required" className="infoSvg" />} message="Required by System" />
        </div>
      );
    case 'disabled':
      return (
        <div className="settings-checkbox-container" data-testid={`settings-checkbox-${type}`}>
          <TootipInfo icon={<AdminDisabled title="disabled" className="infoSvg" />} message="Disabled by Admin" />
        </div>
      );
    case 'selected':
      return (
        <div className="settings-checkbox-container" data-testid={`settings-checkbox-${type}`}>
          <div className="settings-checkbox-container-wrapper">
            <input
              data-testid={dataTestId || 'settings-checkbox'}
              aria-label={name}
              name={name}
              type="checkbox"
              value={selected}
              defaultChecked={selected}
              onChange={handleChange}
            />
          </div>
        </div>
      )
    default:
      return (
        <div className="settings-checkbox-container" data-testid="settings-checkbox-default">
          <div className="settings-checkbox-container-wrapper">
            <input
              data-testid={dataTestId || 'settings-checkbox'}
              aria-label={name}
              name={name}
              type="checkbox"
              value={selected}
              defaultChecked={selected}
              onChange={handleChange}
            />
          </div>
        </div>
      );
  }
};

SettingsCheckBox.propTypes = {
  dataTestId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['', 'required', 'notAvailable', 'disabled', 'selected', 'unselected', 'optional', 'notConfigurable']),
};

SettingsCheckBox.defaultProps = {
  type: '',
  selected: false,
  dataTestId: '',
};

export default SettingsCheckBox;
