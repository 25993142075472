import {
  NOTIFICATION_SHOW,
  NOTIFICATION_CLOSE,
  SNACKBAR_SHOW,
  SNACKBAR_CLOSE,
} from '../constants/actions';
import { saveBrowserCheckCookie } from '../helpers/cookies';

/*
opts should contain those fields
text: string - notification text
type: string - one of bootstrap style: primary, secondary, success, danger, warning, info
default type - danger
*/
export function showNotification(opts) {
  return { type: NOTIFICATION_SHOW, payload: opts };
}

export function closeNotification(opts) {
  return { type: NOTIFICATION_CLOSE, payload: opts };
}

export function showSnackbar(opts) {
  return { type: SNACKBAR_SHOW, payload: opts };
}
export function closeSnackbar() {
  return { type: SNACKBAR_CLOSE };
}

export const checkedBrowser = (wasChecked) => {
  saveBrowserCheckCookie(true);
  return { type: 'BROWSER_CHECKED', payload: wasChecked };
};
