import { Button, Icon } from '@chakra-ui/react';
import { CaretDownIcon } from '@himarley/unity';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

import { jobPutRequest } from '@app/actions/job';
import AssignUserActionMenu from '@app/components/ActionMenu/assign-user-action-menu';
import TableCell from '@app/components/elements/table/TableCell/TableCell';
// eslint-disable-next-line import/no-named-as-default
import TableRow from '@app/components/elements/table/TableRow/TableRow';
import { useCheckForCaseAccess, handleUserAssignment } from '@app/helpers/cases';

interface ClaimRowProps {
  claim: {
    id: string;
    isOpen: boolean;
    assignedOperator: {
      name: string;
      id: string;
    };
    primaryContactId: string;
  };
  operators: {
    id: string;
    label: string;
  }[];
  actions: {
    id: string;
    label: string;
    icon: string;
    disabled: boolean;
    onSelect: (e: unknown) => void;
  }[];
  columns: {
    id: string;
    label: string;
    icon: string;
    disabled: boolean;
    onSelect: (e: unknown) => void;
  }[];
}

const ClaimRow: React.FC<ClaimRowProps> = ({
  claim,
  operators,
  actions,
  columns,
}) => {
  const operator = _.get(claim, 'assignedOperator.name');
  const dispatch = useDispatch();
  const hasAccessToCase = useCheckForCaseAccess(claim);
  const assignUser = handleUserAssignment(claim, jobPutRequest, dispatch);

  const assignUserDropdown = hasAccessToCase
    ? (
      <AssignUserActionMenu
        id="claims"
        defaultLabel="Unassigned"
        rowId={`assign-${claim.id}`}
        selectedId={_.get(claim, 'assignedOperator.id') || _.get(claim, 'primaryContactId') || 'unassigned'}
        handleSelect={(e: unknown) => assignUser(e)}
        options={_.unionBy(
          operators,
          [{ id: _.get(claim, 'assignedOperator.id'), label: _.get(claim, 'assignedOperator.name') }],
          'id',
        ).filter((i) => i.id)}
      />
    )
    : <span>{operator}</span>;

  return (
    <TableRow
      id="claim"
      className={!claim.isOpen ? 'archived-claim' : ''}
      key={claim.id}
      itemId={claim.id}
      item={claim}
      status="open"
      actions={actions}
      columns={columns.slice(0, columns.length - 2)}
      hasAccessToCase={hasAccessToCase}
      useNewActionMenu
    >
      <TableCell id="assigned">
        {
          !claim.isOpen
            ? (
              <Button
                width="162px"
                size="sm"
                variant="outline"
                isDisabled
                rightIcon={<Icon as={CaretDownIcon} />}
                justifyContent="space-between"
              >
                {_.get(claim, 'assignedOperator.name', 'Unassigned')}
              </Button>
            )
            : assignUserDropdown
        }
      </TableCell>
    </TableRow>
  );
};

export default ClaimRow;
