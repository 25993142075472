/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import './TokenSelector.less'

/**
 * TokenSelector
 * popup for selecting tokens, e.g. $claimNumber
 * @param {array} list available tokens
 * @param {function} pickItem action to pick item
 * @param {integer} selectedTokenIndex index of selected token (for highlighting)
 * */

const HelpLine = ({ helpText, boldText }) => (
  <div>
    <Translate value={helpText} />
    {' '}
    <strong>{boldText}</strong>
  </div>
)

const TokenSelector = ({
  list, selectedTokenIndex, pickItem,
}) => (
  <div className="message-editor-container" data-testid="tokenSelector">
    <div className="message-editor-popup">
      <div className="message-editor-popup-helps">
        <HelpLine helpText="messageTemplates.verticalNavigate" boldText="tab" />
        <HelpLine helpText="messageTemplates.selectWith" boldText="enter" />
        <HelpLine helpText="messageTemplates.dismissWith" boldText="esc" />
      </div>
      <div className="message-editor-popup-list">
        {(list || []).map((item, index) => (
          // eslint-disable-next-line react/jsx-key
          <div
            className={`message-editor-popup-item${index === selectedTokenIndex ? '-active' : ''}`}
            onClick={() => pickItem(index)}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  </div>
)

TokenSelector.propTypes = {
  list: PropTypes.arrayOf(Object).isRequired,
  selectedTokenIndex: PropTypes.number.isRequired,
  pickItem: PropTypes.func.isRequired,
}

export default TokenSelector
