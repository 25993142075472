import React from 'react'
import PropTypes from 'prop-types'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // Log error to console and FullStory
    console.error('[ErrorBoundary]', error, errorInfo)
  }

  render() {
    const { children, errorMessage } = this.props
    const { hasError } = this.state

    if (hasError) {
      return errorMessage
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.node,
}

ErrorBoundary.defaultProps = {
  children: undefined,
  errorMessage: 'Unexpected Error',
}
