import _ from 'lodash'

const selector = (state, ownProps) => {
  const chat = _.get(ownProps, 'chat', {})
  const chatId = _.get(chat, 'id', '')
  const jobId = _.get(ownProps, 'jobId') || _.get(chat, 'jobId')

  const userById = _.get(state, 'users.listById', {})
  const activeJob = _.get(state, 'jobs.activeJob', {})

  const customer = _.get(activeJob, 'customer', {})
  const recipient = userById[customer.id] || customer // userById should have more fresh data
  const verifiedUser = customer.role === 'enduser' && customer.verified

  const isAssignedJob = !!_.get(chat, 'operator', false) // could be moved to module

  const showTypeIndicator = state.ui && state.ui.typingByChatId[chatId]
    && state.ui.typingByChatId[chatId].length !== 0
  const typedUserName = showTypeIndicator
    ? _.get(state.users, `listById.${state.ui.typingByChatId[chatId][0]}.name`) : ''
  const operatorId = _.get(state.jobs, 'operatorId')
  const showInsurPay = _.get(state, 'profile.properties.permissionSettings.showInsurPay', false)

  const userInTranslationMode = _.get(customer, 'inTranslationMode', false)
  const jobLanguagePreference = _.get(activeJob, 'languagePreference', 'en')
  const jobInTranslationMode = _.get(activeJob, 'inTranslationMode', false)
  const languagePreference = jobLanguagePreference
  const inTranslationMode = (jobInTranslationMode || userInTranslationMode) && (languagePreference !== 'en')
  const typingUser = _.get(state, 'jobs.activeChat.authorTyping')
  const typingTick = _.get(state, 'jobs.activeChat.typingTickCount')

  const lineOfBusiness = activeJob?.lineOfBusiness;

  return {
    uploadFileIsInProgress: _.get(state.jobs, 'uploadFileIsInProgress'),
    scheduledMessages: _.get(state.jobs, 'scheduledMessages'),
    uploadFileError: _.get(state.jobs, 'uploadFileError'),
    uploadFileUrl: _.get(state.jobs, 'uploadFileUrl'),
    languagePreference,
    inTranslationMode,
    showTypeIndicator,
    typedUserName,
    chatId,
    activeChatRecipient: recipient,
    jobId,
    activeJob,
    verifiedUser,
    isAssignedJob,
    operatorId,
    showInsurPay,
    sendModalVisible: _.get(state.jobs, 'showSendFileModal', false),
    selectedFile: _.get(state.jobs, 'fileToSend', null),
    typingUser,
    typingTick,
    participants: _.get(state, `jobs.chats.${chatId}.participants`, []),
    authId: _.get(state, 'auth.user._id'),
    lineOfBusiness,
    templateTrayIsOpen: _.get(state.ui, 'templateTrayIsOpen', false),
  }
}

export default selector
