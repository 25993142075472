import {
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React, {
  useEffect, useState, Dispatch, SetStateAction,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { templatesSlice } from '@app/slices/templates';
import './ScheduleMessage.less';

const thisDate = new Date();
const MAX_DAYS = 90;
const MIN_DATE = thisDate.toISOString().slice(0, 16);
const MAX_DATE = (
  new Date(thisDate.setDate(thisDate.getDate() + MAX_DAYS))
).toISOString().slice(0, 16);

type ScheduleMessageProps = {
  chatId: string;
  jobId: string | number;
  queueMessage: (message: unknown) => void;
  show: boolean;
  toggleShow: (value: boolean) => void;
  scheduledMessageText: string;
  setMessageValue: Dispatch<SetStateAction<string>>;
};

const ScheduleMessage: React.FC<ScheduleMessageProps> = ({
  chatId,
  jobId,
  queueMessage,
  show,
  toggleShow,
  scheduledMessageText,
  setMessageValue,
}) => {
  const [scheduledDateTime, setScheduledDateTime] = useState('');
  const { clearLoadedTemplate } = templatesSlice.actions;
  const [scheduledMsgText, setScheduledMsgText] = useState(scheduledMessageText);

  // close this modal and reset date, time and message text
  const closeAndReset = () => {
    toggleShow(false);
    setScheduledDateTime('');
    setMessageValue('');
  };
  const dispatch = useDispatch();

  const isDisabled = !setScheduledDateTime || !scheduledMsgText;

  const handleSubmit = () => {
    const formObject = {
      message: scheduledMsgText,
      eventTime: new Date(scheduledDateTime).getTime(),
    };
    closeAndReset();
    queueMessage({
      ...formObject,
      chatId,
      jobId,
    });
  };

  const { context, loadedTemplateText } = useSelector(({ ui, templates }) => ({
    context: ui.context,
    loadedTemplateText: templates.loadedTemplateText,
  }));

  useEffect(() => {
    if (context === 'SCHEDULED_MESSAGE' && loadedTemplateText) {
      setScheduledMsgText(loadedTemplateText);
      dispatch(clearLoadedTemplate());
    }
  }, [loadedTemplateText, context, dispatch, clearLoadedTemplate]);

  return (
    <Modal isCentered isOpen={show} onClose={closeAndReset} data-testId="schedule-message-header">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          Schedule Message
        </ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Date</FormLabel>
            <Input
              min={MIN_DATE}
              max={MAX_DATE}
              type="datetime-local"
              value={scheduledDateTime}
              onChange={(e) => setScheduledDateTime(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="outline" onClick={closeAndReset}>
              Cancel
            </Button>
            <Button isDisabled={isDisabled} onClick={handleSubmit}>
              Schedule Message
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ScheduleMessage;
