import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '../../../../images/icons/infoV2.svg';
import InfoItem from './InfoItem';
import './MediaMetadata.less';
import { mediaItemPropTypes } from '../media-helpers';

const mapImageMetadata = (mediaInfo, selectedMediaIndex) => {
  const {
    exif,
    contentType,
    dateTaken,
  } = mediaInfo || {};
  const fileName = `IMAGE_${selectedMediaIndex}`;
  const fileType = contentType ? `${contentType}` : 'Unknown Type';
  const date = dateTaken ? new Date(dateTaken).toLocaleString() : 'Unknown Date';
  const device = exif?.Make && exif?.Model ? `${exif.Make} ${exif.Model}` : 'Unknown Device';
  return {
    fileName,
    fileType,
    date,
    device,
  };
};

const MediaMetadata = ({ mediaItem, selectedMediaIndex }) => {
  if (!mediaItem?.mediaInfo?.exif) return null;
  const metaData = mapImageMetadata(mediaItem.mediaInfo, selectedMediaIndex);
  return (
    <div className="media-metadata-container">
      <div className="media-metadata-header">
        <InfoIcon />
        <div className="media-metadata-title">File Information</div>
      </div>
      <div className="media-metadata-info-section">
        <InfoItem title="File Name" value={metaData?.fileName} />
        <InfoItem title="File Type" value={metaData?.fileType} />
        <InfoItem title="Date" value={metaData?.date} large />
        <InfoItem title="Device" value={metaData?.device} />
      </div>
    </div>
  );
};

MediaMetadata.propTypes = {
  mediaItem: mediaItemPropTypes.isRequired,
  selectedMediaIndex: PropTypes.number.isRequired,
};

export default MediaMetadata;
