import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const NoteBody = ({ noteText, mentionedUserIds }) => {
  const cleanedNoteText = useMemo(() => {
    // eslint-disable-next-line no-useless-escape
    const urlRegex = /(https?:\/\/[^\\\s]+)/gm;
    const newLineRegex = /\\n/g;
    const noteMentionRegex = /@\[.+\]\(\S+\)/g;
    const textWithLinks = noteText.replace(
      urlRegex,
      (url) => `<a href="${url}" class="note-link" target="_blank" rel="noreferrer" data-testid="note-link">${url}</a>`,
    );
    const textWithLineBreaks = textWithLinks.replace(newLineRegex, '<br />');
    const textWithMentionsStyles = mentionedUserIds?.length
      ? textWithLineBreaks.replace(noteMentionRegex, (text) => `<span class="mentioned-user" data-testid="mentioned-user">${text.replaceAll(/\[|\]/g, '').slice(0, -38)}</span>`)
      : textWithLineBreaks;
    return textWithMentionsStyles;
  }, [noteText, mentionedUserIds]);

  return (
    <div
      data-testid="note-body"
      // eslint-disable-next-line react/no-danger
      className="note-body"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(cleanedNoteText, { ADD_ATTR: ['target'] }),
      }}
    />
  );
};

NoteBody.propTypes = {
  noteText: PropTypes.string,
  mentionedUserIds: PropTypes.arrayOf(String),
};

NoteBody.defaultProps = {
  noteText: '',
  mentionedUserIds: [],
};

export default NoteBody;
