import React from 'react'
import PropTypes from 'prop-types'
import './FullpageModal.less'

const FullpageModal = props => (
  <div className={`fullpage-modal ${props.className}`}>
    <div className="fullpage-modal-title">
      {/* <h4 data-test="title">{props.title}</h4> */}
    </div>
    <div className="fullpage-modal-body">

      <div className="fullpage-modal-transparent-div" />
        <div className="header-modal">
          <div className="body-modal">
            <button data-testid="close-button" data-test="close-button" className="modal-close-button cls-btn" onClick={props.onClose} />
            {props.children}
          </div>
        </div>
    </div>
  </div>
)

FullpageModal.propTypes = {
  //onClose: PropTypes.string,
  onClose: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  title: PropTypes.string,
  children: PropTypes.object,
}

FullpageModal.defaultProps = {
  onClose: () => {},
  title: '',
}

export default FullpageModal
