/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Tab, TabbedNavBar,
} from '@himarley/unity';

import UserMgmt from './UserMgmt/UserMgmt';
import MessageTemplates from './MessageTemplates/MessageTemplates';
import GroupMgmt from './GroupMgmt/GroupMgmt';
import QueueMgmt from './QueueMgmt/QueueMgmt';
import AppIntegrations from './AppIntegrations/AppIntegrations';
import VendorPartners from './VendorPartners/VendorPartners';
import LinesOfBusinessContainer from './LinesOfBusiness/LinesOfBusinessContainer';
import PermissionVerifier from '../../PermissionVerifier/PermissionVerifier';

import {
  CASE_QUEUES, GROUP_MANAGEMENT, USER_MANAGEMENT, TEMPLATE_MANAGEMENT,
  USER_MANAGEMENT_TAB, SYSTEM_ADMIN, LINE_OF_BUSINESS_WELCOME_FLOWS_ALL,
} from '../../../constants/permissions';
import './UserMgmtRoot.less';

const UserMgmtRoot = ({ subNavId }) => {
  const currentPermissions = useSelector((state) => state?.auth?.permissions || []);
  const currentUserRoles = useSelector((state) => state?.auth?.user?.roles || []);
  const hasCaseQueues = currentPermissions.includes(CASE_QUEUES);
  const hasGroupMgmt = currentPermissions.includes(GROUP_MANAGEMENT);
  const hasUserMgmt = currentPermissions.includes(USER_MANAGEMENT);
  const hasTemplateMgmt = currentPermissions.includes(TEMPLATE_MANAGEMENT);
  const history = useHistory();

  const hasLinesOfBusiness = currentPermissions.includes(LINE_OF_BUSINESS_WELCOME_FLOWS_ALL)
    && (currentPermissions.includes(SYSTEM_ADMIN)
      || currentUserRoles.includes('ADMIN'));

  const tabList = [];
  if (hasUserMgmt) {
    tabList.push(
      <Tab eventKey="users" title="Users">
        <UserMgmt />
      </Tab>,
    );
  }
  if (hasGroupMgmt) {
    tabList.push(
      <Tab eventKey="groups" title="Groups">
        <GroupMgmt />
      </Tab>,
    );
  }
  if (hasLinesOfBusiness) {
    tabList.push(
      <Tab eventKey="linesOfBusiness" title="Lines of Business">
        <LinesOfBusinessContainer />
      </Tab>,
    );
  }

  if (hasTemplateMgmt) {
    tabList.push(
      <Tab eventKey="templates" title="Message Templates">
        <MessageTemplates />
      </Tab>,
    );
  }

  tabList.push(
    <Tab eventKey="partners" title="Vendor Partners">
      <VendorPartners />
    </Tab>,
  );

  tabList.push(
    <Tab eventKey="integrations" title="App Integrations">
      <AppIntegrations />
    </Tab>,
  );

  if (hasCaseQueues) {
    tabList.push(
      <Tab eventKey="queues" title="Queues">
        <QueueMgmt />
      </Tab>,
    );
  }

  const handleTabSelect = (tab) => {
    if (tab) {
      history?.push(`/usermgmt/${tab}`);
    }
  };

  return (
    <PermissionVerifier neededPermissions={[USER_MANAGEMENT_TAB]} redirect>
      <TabbedNavBar
        className="tabbedNavBar"
        title="Manage"
        type="navigation"
        defaultActiveKey={subNavId || 'users'}
        onSelect={handleTabSelect}
      >
        {tabList}
      </TabbedNavBar>
    </PermissionVerifier>
  );
};

UserMgmtRoot.propTypes = {
  subNavId: PropTypes.string,
};

UserMgmtRoot.defaultProps = {
  subNavId: null,
};

export default UserMgmtRoot;
