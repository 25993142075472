import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@himarley/unity';

const DeleteNoteModal = ({
  show,
  hideModal,
  isReply,
  handleDelete,
}) => {
  const handleHide = () => {
    hideModal();
  };

  return (
    <Modal
      show={show}
      showCloseButton
      title={`Delete this ${isReply ? 'reply' : 'note'}?`}
      className="confirmation-modal"
      toggleModal={handleHide}
      backdrop
      size="sm"
    >
      <>
        <div className="modal-body">
          {`Are you sure you want to permanently delete this ${
            isReply ? 'reply' : 'note'
          }?`}
        </div>
        <div className="confirmation-modal-footer">
          <div className="confirmation-modal-buttons">
            <Button
              data-testid="delete-note-button-confirm"
              data-test="delete-note-button-confirm"
              onClick={handleDelete}
              type="positive"
            >
              Delete
            </Button>
            &nbsp;
            <Button onClick={handleHide} type="outline">
              Cancel
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};

DeleteNoteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  isReply: PropTypes.bool,
  handleDelete: PropTypes.func,
};

DeleteNoteModal.defaultProps = {
  isReply: false,
  handleDelete: () => {},
};

export default DeleteNoteModal;
