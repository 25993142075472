import {
  useMutation,
  useQueryClient,
  UseMutationResult,
  QueryKey,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { showSnackbar } from '@app/actions/notification';
import secureRequest from '@app/helpers/secure-request';

export async function fetchData(endpoint: string) {
  const response = await secureRequest(endpoint, { method: 'GET' });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
}

export interface ApiCallOptions<T> {
  endpoint: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: T;
}

async function apiCall<T>(options: ApiCallOptions<T>) {
  const {
    endpoint,
    method,
    body,
  } = options;
  const response = await secureRequest(endpoint, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || 'Something went wrong');
  }
  return data;
}

export function useDynamicMutation<T>(
  dataKey: QueryKey,
  snackbarText?: {
    success: string,
    error: string
  },
  callbackFn?: () => void,
): UseMutationResult<T, Error, ApiCallOptions<T>, unknown> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const mutation = useMutation<T, Error, ApiCallOptions<T>>(apiCall<T>, {
    onSuccess: () => {
      queryClient.invalidateQueries(dataKey);
      dispatch(
        showSnackbar({
          text: snackbarText?.success || 'Sucessfully updated!',
          isError: false,
        }),
      );
      if (callbackFn) {
        callbackFn();
      }
    },
    onError: (error: Error) => {
      dispatch(
        showSnackbar({
          text: snackbarText?.error || error.message,
          isError: true,
        }),
      );
    },
  });

  return mutation;
}
