export const UP_ARROW_CODE = 38
export const DOWN_ARROW_CODE = 40
export const LEFT_ARROW_CODE = 37
export const RIGHT_ARROW_CODE = 39
export const ENTER_CODE = 13
export const TAB_CODE = 9
export const ESC_CODE = 27
export const USD_KEY_CODE = 52
export const PLUS_KEY_CODE = 187
export const COLON_CODE = 186

