/* eslint-disable import/prefer-default-export */
import {
  SYS_ADMIN,
  FOCUSED_OPERATOR,
  ADMIN,
  OPERATOR,
  SUPERVISOR,
} from '../constants/roles';
import { capitalizeEachWord } from './format';

const filterAdminOperatorSysAdmin = (roles = []) => roles.filter(
  (role) => role === ADMIN
      || role === OPERATOR
      || role === SUPERVISOR
      || role === SYS_ADMIN,
);

export const constructFocusOperatorRoleText = (currentRoles, newRole = '') => {
  let removedRoles = [];
  if (
    newRole === FOCUSED_OPERATOR
    && filterAdminOperatorSysAdmin(currentRoles).length > 0
  ) {
    removedRoles = filterAdminOperatorSysAdmin(currentRoles);
  } else if (
    (newRole === SYS_ADMIN
      || newRole === ADMIN
      || newRole === OPERATOR
      || newRole === SUPERVISOR)
    && currentRoles.includes(FOCUSED_OPERATOR)
  ) {
    removedRoles = [FOCUSED_OPERATOR];
  }
  return removedRoles.length > 0
    ? `Role set to ${capitalizeEachWord(
      newRole?.toLowerCase(),
    )}. ${capitalizeEachWord(removedRoles.join(', ')?.toLocaleLowerCase())} ${
      removedRoles.length === 1 ? 'role' : 'roles'
    } removed.`
    : '';
};
