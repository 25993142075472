import {
  Icon, Image, Spinner, IconButton, Box,
} from '@chakra-ui/react';
import { CircleXIcon } from '@himarley/unity';
import React from 'react';

import './FileUploadIndicator.less';

interface FileUploadIndicatorProps {
  previewFile: string;
  onCancel: () => void;
}

const FileUploadIndicator: React.FC<FileUploadIndicatorProps> = ({ previewFile, onCancel }) => (
  <Box className="file-upload-indicator" data-test="file-upload-indicator">
    {previewFile
      ? <Image src={previewFile} />
      : <Box className="generic-doc" />}
    <Box className="loading-overlay">
      <Box className="loading-veil" />
      <Spinner />
    </Box>
    <IconButton
      size="sm"
      variant="ghost"
      icon={<Icon as={CircleXIcon} />}
      className="cancel-overlay"
      onClick={onCancel}
      aria-label="cancel-icon-button"
    />
  </Box>
);

export default FileUploadIndicator;
