import React from 'react';
import { useSelector } from 'react-redux';
import styles from './SearchScreen.module.less';
import CancelSearchButton from '../ChatInbox/CancelSearchButton/CancelSearchButton';

const SearchScreen = () => {
  const { search, searchResultsCount } = useSelector((state) => ({
    search: state.jobs?.search,
    searchResultsCount: state.jobs?.searchResultsCount,
  }));
  let resultsMessage = `There are ${searchResultsCount} results for "${search}"`;
  if (searchResultsCount === 0) {
    resultsMessage = `There are no results for "${search}"`;
  }
  if (searchResultsCount === 1) {
    resultsMessage = `There is 1 result for "${search}"`;
  }
  return (
    <section className={styles.chatActive} data-testid="chat-active">
      <div className={styles.resultMessage}>{resultsMessage}</div>
      <CancelSearchButton testId="active-chat-container-cancel-search-button" />
    </section>
  );
};

export default SearchScreen;
