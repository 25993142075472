import _ from 'lodash'
import { AUTH_USER, DEAUTH_USER, CLOSE_INACTIVITY_ALERT } from '../constants/actions'
import { setOperatorActive, logout } from '../actions/auth'
import { openInactivityAlert } from '../actions/ui'
import { TIMEOUT_DURATION } from '../constants/general'

let reduxStore = null
let theLastActivityTimestamp = new Date().getTime()
let inactivityTimeout = TIMEOUT_DURATION
let inactivityCheckStarted = false

let checkedIn = false
function resetTimer() {
  // reset AI away response timer
  if (!checkedIn) {
    reduxStore.dispatch(setOperatorActive())
    checkedIn = true
    setTimeout(() => {
      checkedIn = false
    }, 5000)
  }
  theLastActivityTimestamp = new Date().getTime()
}
document.addEventListener('click', resetTimer)
document.addEventListener('keypress', resetTimer)

function inactivityLogout() {
  if (!inactivityCheckStarted) {
    reduxStore.dispatch(logout())
  }
}

setInterval(() => {
  if (inactivityCheckStarted) {
    const now = new Date().getTime()

    // Open inactivity alert
    // inactivityTimeout is stored in seconds - need to convert to milliseconds
    if (now - theLastActivityTimestamp > inactivityTimeout * 1000) {
      inactivityCheckStarted = false
      reduxStore.dispatch(openInactivityAlert())
      setTimeout(inactivityLogout, 11000)
    }
  }
}, 1000)

export default (store) => {
  reduxStore = store
  return (next) => (action) => {
    switch (action.type) {
      case AUTH_USER: {
        inactivityTimeout = _.get(action, 'payload.inactivityTimeout', inactivityTimeout)
        inactivityCheckStarted = true
        break
      }
      case DEAUTH_USER: {
        inactivityCheckStarted = false
        break
      }
      case CLOSE_INACTIVITY_ALERT: {
        if (!action.payload.shouldLogout) {
          inactivityCheckStarted = true;
        } else {
          inactivityCheckStarted = false;
          inactivityLogout();
        }
        break
      }
      default:
        break
    }
    return next(action)
  }
}
